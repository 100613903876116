import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import { type AuthStore } from 'core'
import { clsx, globalClassNames, withColor } from 'lib'
import { companyFields } from 'resources/company'
import { Box, BoxContainer, DataAvatar, Typography } from 'ui'

interface Props {
    short?: boolean
}

const AccountDetails: FC<Props> = inject('auth')(
    observer(({ short, auth }: Props & { auth: AuthStore }) => {
        const company = auth.currentCompany

        return (
            <BoxContainer
                gap="10px"
                width="100%"
                pb="12px"
            >
                <Box
                    flexGrow={1}
                    overflow="hidden"
                >
                    <Typography
                        variant="helperText"
                        color={withColor('text.secondary')}
                    >
                        Account {short ? '' : 'Settings'}
                    </Typography>
                    <Typography
                        variant="body2"
                        color={withColor('text.primary')}
                        className={clsx(short && globalClassNames.ellipsis)}
                    >
                        {company.name}
                    </Typography>
                </Box>
                <DataAvatar
                    defaultImage={<companyFields.avatar.Icon />}
                    imageSrc={company.logo}
                    sx={{
                        width: '32px',
                        height: '32px',
                    }}
                />
            </BoxContainer>
        )
    }),
)

export default AccountDetails
