import { createResource } from 'core'

import { type CK79Model } from './types'

export const ck79Resource = createResource({
    name: 'ck79',
    resource: 'vmrs/ck79',
})

export const displayCK79 = (record: CK79Model) => {
    return record ? `${record.sideAndOrientation} ${record.description}` : ''
}
