import { type ReactNode } from 'react'

import { useListContext } from 'react-admin'

import Icons from 'assets/icons'
import { Header, InnerHeader, ListTotalBadge } from 'components'
import { alpha } from 'lib'
import { type TelematicsUnitModel, type IntegrationType } from 'resources/telematics'
import { SvgIcon, BoxContainer, StatusText, Typography, AutoGrid } from 'ui'

import { useSyncedStateContext } from '../SyncedStateContext'
import { calculateSyncedUnits } from '../utils'

const StatusBadge = ({ children, synced }: { synced?: boolean; children: ReactNode }) => {
    return (
        <BoxContainer gap="4px">
            <StatusText
                statusColor={(theme) =>
                    synced ? theme.palette.primary.main : alpha(theme.palette.text.primary, 0.26)
                }
                sx={{
                    boxSizing: 'border-box',
                    width: '16px',
                    height: '16px',
                    padding: '1.5px !important',
                }}
            >
                <SvgIcon
                    sx={{ width: '13px', height: '13px' }}
                    component={synced ? Icons.Sync : Icons.SyncDisabled}
                />
            </StatusText>
            <Typography
                variant="body2"
                color="text.primary"
            >
                {children}
            </Typography>
        </BoxContainer>
    )
}

const IntegrationsUnitListDialogHeader = ({ integration }: { integration: IntegrationType }) => {
    const { synced } = useSyncedStateContext()
    const { data } = useListContext<TelematicsUnitModel>()

    if (!data) {
        return null
    }
    const syncedUnits = calculateSyncedUnits(synced, integration.vehiclesSynced)

    return (
        <InnerHeader
            isStatic
            backTo={false}
        >
            <Header.Info>
                <Header.Content>
                    <Header.Title>Units from {integration.providerName}</Header.Title>
                </Header.Content>
                <Header.Content mobilePlacement="bottom">
                    <AutoGrid.Row>
                        <AutoGrid.Column>
                            <ListTotalBadge renderContent={() => integration.vehiclesTotal} />
                        </AutoGrid.Column>
                        <AutoGrid.Column>
                            <StatusBadge synced>{syncedUnits}</StatusBadge>
                        </AutoGrid.Column>
                        <AutoGrid.Column>
                            <StatusBadge>{integration.vehiclesTotal - syncedUnits}</StatusBadge>
                        </AutoGrid.Column>
                    </AutoGrid.Row>
                </Header.Content>
            </Header.Info>
        </InnerHeader>
    )
}

export default IntegrationsUnitListDialogHeader
