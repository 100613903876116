import { type Identifier, type BaseModel } from 'appTypes'
import { type UserRole, type UserRoleName } from 'resources/roles'
import { type ShopModel } from 'resourcesBase/shops'

export interface MemberModel extends BaseModel {
    name: string
    email: string
    avatar: string
    created: string
    invitationStatus: MemberInvitationStatusKeys
    phone: string
    role: UserRoleName
    roleData: UserRole
    userId: Identifier
    shops: ShopModel[]
    lastLogin: string
    viewUnitsWithoutDomicile: boolean
}

export const enum MemberInvitationStatusKeys {
    ACCEPTED = 'ACCEPTED',
    SENT = 'INVITATION_SENT',
    EXPIRED = 'EXPIRED',
}
