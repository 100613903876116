import { useState } from 'react'

import { useInput } from 'react-admin'
import { useFormContext } from 'react-hook-form'

import {
    TextInput,
    TextareaInput,
    costMaskParams,
    inputQtyMaskParams,
    useUtilityDrawerContext,
} from 'components'
import { requiredValidation, validateMaxLength } from 'core'
import { PartInput, type PartModel } from 'resources/parts'
import { CK34Input, CK79Input, CK33Input } from 'resources/vmrs'
import { type JobPartModel } from 'resources/workOrders'
import { GridContainerColumns, GridItem, GridBreak, SectionTitleSmall } from 'ui'

import JobLaborPartFormTotal from '../Labor/JobLaborPartFormTotal'
import { type WorkOrderItemsExtra } from '../config/constants'

import { jobPartApiContext } from './constants'

const qtyAndCostValidator = [validateMaxLength(10, 'Invalid value'), requiredValidation]

export interface JobPartFormProps {
    hideInputs?: {
        upc?: boolean
        position?: boolean
    }
}
const JobPartForm = ({ hideInputs }: JobPartFormProps) => {
    const { setValue } = useFormContext()
    const [showPosition, setShowPosition] = useState(hideInputs?.position)
    const { extra } = useUtilityDrawerContext()
    const { disabledFields: disabled, job, workOrder } = extra as WorkOrderItemsExtra
    const isVendorWo = Boolean(workOrder?.purchaseOrderData)
    return (
        <>
            <SectionTitleSmall>Basic Details</SectionTitleSmall>
            <GridContainerColumns>
                <GridItem>
                    <PartInput
                        {...jobPartApiContext(job.id)}
                        type={isVendorWo ? 'vendor' : 'inventory'}
                        disabled={disabled}
                        onSelectedChange={({ selected }) => {
                            setValue('description', selected?.description || '')
                            setValue('price', selected?.cost ?? '', {
                                shouldTouch: true,
                                shouldDirty: true,
                            })
                            setValue('unitOfMeasure', selected?.unitOfMeasure || '')
                            setValue('manufacturer', selected?.manufacturer || '')
                            setValue('component', selected?.component || '')
                            if (!selected?.positionApplicable) {
                                setValue('position', null)
                            }
                            setValue(
                                'universalProductCode',
                                (selected as PartModel)?.universalProductCode || '',
                            )
                            setShowPosition(Boolean(selected?.positionApplicable))
                        }}
                    />
                </GridItem>

                <GridItem>
                    <TextareaInput<JobPartModel>
                        source="description"
                        label="Description"
                        disabled
                    />
                </GridItem>
                <GridItem>
                    <CK33Input
                        source="component"
                        disabled
                    />
                </GridItem>
                <GridItem>
                    <CK34Input
                        label="Manufacturer"
                        disabled
                        source="manufacturer"
                    />
                </GridItem>
                <GridItem>
                    <TextInput
                        source="unitOfMeasure"
                        label="UOM"
                        disabled
                    />
                </GridItem>
                {hideInputs?.upc ? null : (
                    <GridItem>
                        <TextInput
                            source="universalProductCode"
                            label="UPC"
                            disabled
                        />
                    </GridItem>
                )}

                <GridBreak />

                <GridItem xs={6}>
                    <TextInput
                        source="quantity"
                        label="Quantity"
                        validate={qtyAndCostValidator}
                        {...inputQtyMaskParams}
                        disabled={disabled}
                    />
                </GridItem>

                <GridItem xs={6}>
                    <TextInput
                        source="price"
                        label="Cost"
                        validate={qtyAndCostValidator}
                        {...costMaskParams}
                        disabled={disabled || workOrder.inventoryMode}
                    />
                </GridItem>

                <JobLaborPartFormTotal
                    title="Part Total"
                    inputOne="quantity"
                    inputTwo="price"
                />
                {showPosition ? (
                    <GridItem>
                        <SectionTitleSmall>Position</SectionTitleSmall>
                        <CK79Input disabled={disabled} />
                    </GridItem>
                ) : null}
            </GridContainerColumns>

            <FormConfig />
        </>
    )
}

export default JobPartForm

const FormConfig = () => {
    const { extra } = useUtilityDrawerContext()
    const { workOrder } = extra as WorkOrderItemsExtra

    useInput({
        source: 'formConfig',
        defaultValue: {
            inventoryMode: workOrder.inventoryMode,
        },
    })

    return null
}
