import { type FC } from 'react'

import Icons from 'assets/icons'
import { DistanceLabel, integerNonNegativeSpacedMaskResolver } from 'components'
import { alpha, styled, useTheme } from 'lib'
import { Spacer, BoxContainer } from 'ui'

export interface SinceLastServiceTrackWidgetLabelProps {
    distanceSinceLastService: number | null
    timeSinceLastService: number | null
}
const Label: FC<SinceLastServiceTrackWidgetLabelProps> = ({
    distanceSinceLastService,
    timeSinceLastService,
}) => {
    const theme = useTheme()

    return (
        <Spacer variant="md">
            <BoxContainer gap="6px">
                <StyledSpan tint={theme.palette.charts.greenBody}>
                    <Icons.CalendarToday
                        sx={{
                            width: '14px',
                            height: '14px',
                        }}
                    />
                </StyledSpan>
                {Math.floor(timeSinceLastService)} Days
            </BoxContainer>
            <BoxContainer gap="6px">
                <StyledSpan tint={theme.palette.charts.purple}>
                    <Icons.Odometer
                        sx={{
                            width: '14px',
                            height: '14px',
                        }}
                    />
                </StyledSpan>
                {distanceSinceLastService ? (
                    <>
                        {integerNonNegativeSpacedMaskResolver(distanceSinceLastService)}{' '}
                        <DistanceLabel variant="abbr" />
                    </>
                ) : null}
            </BoxContainer>
        </Spacer>
    )
}

export default Label

const StyledSpan = styled('span', {
    shouldForwardProp: (prop) => prop !== 'tint',
})<{ tint?: string }>(({ tint }) => ({
    color: tint,
    padding: '5px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'cennter',
    borderRadius: '4px',
    background: alpha(tint, 0.15),
}))
