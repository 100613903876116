import { useShowContext } from 'react-admin'

import { tabRoutes } from 'components'
import { Show } from 'components/resource'
import { Routes } from 'lib'
import { useIsVenderWo, type WorkOrderModel } from 'resources/workOrders'

import WorkOrderInvoice from '../Invoice'
import WorkOrderJobs from '../Jobs'
import WorkOrderOverview from '../Overview'

import { WoShowHeader } from './components'

const jobsTabsName = 'jobs'
const invoiceTabName = 'invoice'
const WorkOrderShowBase = () => {
    const { record: workOrder } = useShowContext<WorkOrderModel>()
    const isVendorWorkOrder = useIsVenderWo()

    const isStatusCancel = workOrder?.status === 'CANCELED'

    const { routes, tabs } = tabRoutes(
        [
            {
                value: '',
                label: 'Overview',
                element: <WorkOrderOverview />,
                tabHidden: isStatusCancel,
            },
            ...(isStatusCancel
                ? []
                : [
                      {
                          value: jobsTabsName,
                          label: 'Jobs',
                          element: <WorkOrderJobs />,
                      },
                      isVendorWorkOrder
                          ? {
                                value: invoiceTabName,
                                label: 'PO/invoice',
                                element: <WorkOrderInvoice />,
                            }
                          : null,
                  ]),
        ],
        {
            loading: !workOrder?.id,
        },
    )
    return (
        <>
            <WoShowHeader tabs={tabs} />
            <Routes>{routes}</Routes>
        </>
    )
}
const WorkOrderShow = () => {
    return (
        <Show>
            <WorkOrderShowBase />
        </Show>
    )
}

export default WorkOrderShow
