import { type Identifier } from 'appTypes'

export const reasonForQTYAdjustmentChoices = [
    { id: 'RECEIVED', name: 'Received' },
    { id: 'SOLD', name: 'Sold' },
    { id: 'CHECKED', name: 'Checked Inventory' },
    { id: 'LOST', name: 'Lost' },
    { id: 'FOUND', name: 'Found' },
    { id: 'BROKEN', name: 'Broken' },
    { id: 'EXPIRED', name: 'Expired' },
    { id: 'USED', name: 'Used' },
    { id: 'OTHER', name: 'Other' },
]

export const getReasonForQTYAdjustmentLabel = (reasonId: Identifier) => {
    if (!reasonId) {
        return reasonId
    }
    return reasonForQTYAdjustmentChoices.find((reason) => reason.id === reasonId).name
}
