import { type ReactElement } from 'react'

import { useGetOne } from 'react-admin'

import { type DataRecord, type Identifier } from 'appTypes'
import { type ResourceType } from 'core/resource'

import { ConfirmContentWhileRequest } from './ConfirmContentWhileRequest'

interface Props<RecordType extends DataRecord = any> {
    resource: ResourceType
    id: Identifier
    render: (data: RecordType) => ReactElement
}

export const ConfirmContentWithFetchOne = <RecordType extends DataRecord = any>({
    resource,
    id,
    render,
}: Props<RecordType>) => {
    const { data, isFetching, error } = useGetOne(resource.resource, { id })

    if (isFetching) {
        return <ConfirmContentWhileRequest />
    }
    if (error) {
        return null
    }
    return render(data)
}
