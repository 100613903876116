import { type TypographyProps, type BoxProps, Typography } from 'ui'

const WidgetListDescription = (props: TypographyProps & BoxProps) => {
    return (
        <Typography
            height="100%"
            color="text.secondary"
            variant="chartListDescription"
            {...props}
        />
    )
}

export default WidgetListDescription
