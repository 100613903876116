import Icons from 'assets/icons'
import { type CompanyModel } from 'resourcesBase'

const source = 'logo' as const satisfies keyof CompanyModel

const getValue = (data: Pick<CompanyModel, 'logo'>) => data?.logo

const avatar = {
    source,
    Icon: Icons.Company,
    getValue,
}

export default avatar
