import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import { type Size } from 'appTypes'
import { type AuthStore } from 'core/auth'
import { alpha } from 'lib'
import { UserIcon } from 'resources/user'
import { DataAvatar } from 'ui'

interface UserAvatarProps {
    size: Size
}

const UserAvatar: FC<UserAvatarProps> = inject('auth')(
    observer(({ auth, size }: UserAvatarProps & { auth: AuthStore }) => {
        return (
            <DataAvatar
                imageSrc={auth.user.avatar}
                defaultImage={<UserIcon fontSize="small" />}
                sx={{
                    width: size,
                    height: size,
                    backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
                    '& svg': {
                        color: (theme) => theme.palette.primary.main,
                    },
                }}
            />
        )
    }),
)

export default UserAvatar
