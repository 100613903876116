import { type ReactNode } from 'react'

import { Stack } from 'ui'

interface CardCollapsibleContentProps {
    content: {
        iconHolder: ReactNode
        component: ReactNode
    }[]
}
const CardCollapsibleContent = ({ content }: CardCollapsibleContentProps) => {
    return (
        <>
            <Stack
                direction="row"
                spacing="10px"
            >
                {content.map((item) => {
                    return item.iconHolder
                })}
            </Stack>
            {content.map((item) => {
                return item.component
            })}
        </>
    )
}
export default CardCollapsibleContent
