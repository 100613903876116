import { type FC } from 'react'

import { CombinedInputs } from 'components'

import { type PMIntervalIntersection } from '../../types'

import useInputSource from './useInputSource'

interface TriggerThresholdValidationProps {
    extraSource?: (keyof PMIntervalIntersection)[]
    triggerExtraSource?: (keyof PMIntervalIntersection)[]
}

const TriggerThresholdValidation: FC<TriggerThresholdValidationProps> = ({
    extraSource,
    triggerExtraSource,
}) => {
    const source = useInputSource()

    return (
        <CombinedInputs
            track={[source('value'), ...(extraSource || []).map((s) => source(s))]}
            sources={[source('threshold'), ...(triggerExtraSource || []).map((s) => source(s))]}
        />
    )
}

export default TriggerThresholdValidation
