import { useListContext, useShowContext } from 'react-admin'

import Icons from 'assets/icons'
import { type InPageTabProps, InPageTabs } from 'components'
import { useCreateResourcePath } from 'core'
import { poResource, type ReceiptModel, type PurchaseOrderModel } from 'resources/purchaseOrders'
import { Status, IconElement } from 'ui'

const ReceiptToggle = () => {
    const createPath = useCreateResourcePath()
    const { record } = useShowContext<PurchaseOrderModel>()
    const { data, isLoading } = useListContext<ReceiptModel>()

    if (isLoading) {
        return null
    }

    const basePath =
        createPath({
            resource: poResource.resource,
            id: record.id,
            type: 'edit',
        }) + '/line-items'

    const dataTabs: InPageTabProps[] = data.map((item) => ({
        value: item.id as string,
        label: (
            <>
                <IconElement
                    component={Icons.Check}
                    color={(theme) => theme.palette.success.main}
                />
                {`Receipt ${item.number}`}
            </>
        ),
    }))

    const tabs: InPageTabProps[] = record.status === 'CLOSED' ? dataTabs : [onOrderTab, ...dataTabs]

    return (
        <InPageTabs
            tabs={tabs}
            tabMinWidth="110px"
            basePath={basePath}
        />
    )
}

export default ReceiptToggle

const onOrderTab: InPageTabProps = {
    value: '',
    label: (
        <>
            <Status
                size="8px"
                iconColor={(theme) => theme.palette.primary.main}
                sx={{ mx: 0 }}
            />
            On Order
        </>
    ),
}
