import { type FC, type PropsWithChildren, type ReactElement } from 'react'

import { Typography } from '@mui/material'
import {
    ErrorBoundary as SentryErrorBoundary,
    withErrorBoundary as withSentryErrorBoundary,
} from '@sentry/react'

const fallback = <Typography p="5px">Something went wrong!</Typography>

export const ErrorBoundary: FC<PropsWithChildren & { fallback?: ReactElement }> = ({
    children,
    fallback,
}) => {
    return <SentryErrorBoundary fallback={fallback}>{children}</SentryErrorBoundary>
}

export const withErrorBoundary: typeof withSentryErrorBoundary = (Component) =>
    withSentryErrorBoundary(Component, { fallback })
