import { type GridRenderCellParams } from '@mui/x-data-grid'

import { useCreateResourcePath } from 'core/resource'
import { Link } from 'lib'
import { Anchor } from 'ui'

const DatagridLink = (props: GridRenderCellParams & { resource?: string }) => {
    const createPath = useCreateResourcePath()

    const to = createPath({
        resource: props.resource,
        id: props.row.id,
        type: 'edit',
    })

    return (
        <Anchor
            component={Link}
            to={to}
        >
            {props.value}
        </Anchor>
    )
}

export default DatagridLink
