import { type FC, type ReactElement } from 'react'

import { type Identifier } from 'appTypes'
import { useOpenUtilityDrawer, UtilityDrawerEditor } from 'components'
import { type Serializer } from 'core'
import {
    type InvoiceLabor,
    type InvoiceModel,
    InvoiceStatusKeys,
    InvoiceTypeKeys,
} from 'resources/invoices'

import { deleteInvoiceItemActionParams, getResource } from '../utils'

import InvoiceLaborForm, { type InvoiceLaborFormProps } from './InvoiceLaborForm'

interface InvoiceLaborDrawerProps extends Pick<InvoiceLaborFormProps, 'isDisabled'> {
    children: (open: () => void) => ReactElement
    invoice: InvoiceModel
    id?: Identifier
    type?: InvoiceTypeKeys
}

const InvoiceLaborDrawer = ({
    id,
    children,
    type = InvoiceTypeKeys.STANDARD,
    isDisabled,
    invoice,
}: InvoiceLaborDrawerProps) => {
    const open = useOpenUtilityDrawer()
    const resource = getResource(invoice.id, id ? undefined : 'add_labor')

    const extra: InvoiceLaborExtra = {
        invoice,
    }

    return children(() => {
        open({
            extraArgs: {
                resource,
                id,
            },
            drawerArgs: {
                title: id ? <EditTitle record={invoice} /> : 'Create Labor',
                renderWrapper: (props) => (
                    <UtilityDrawerEditor<InvoiceLabor & { laborRate: number }>
                        serializer={serializer}
                        defaultValues={{ laborRate: invoice.shopData.laborHourlyRate }}
                        {...props}
                    />
                ),
                renderContent: () => (
                    <FormContent
                        isDisabled={isDisabled}
                        id={id}
                        type={type}
                        record={invoice}
                    />
                ),
                renderTopRight:
                    type === InvoiceTypeKeys.WORK_ORDER
                        ? null
                        : (render) => render(deleteInvoiceItemActionParams),
            },
            extra,
        })
    })
}

export default InvoiceLaborDrawer

export interface InvoiceLaborExtra {
    invoice: InvoiceModel
}
const EditTitle: FC<{ record: InvoiceModel }> = ({ record }) => {
    if (record.status !== InvoiceStatusKeys.OPEN) {
        return <>View Labor</>
    }

    return <>Edit Labor</>
}

interface FormContentProps extends Pick<InvoiceLaborFormProps, 'isDisabled'> {
    id?: Identifier
    type: InvoiceTypeKeys
    record: InvoiceModel
}

const FormContent: FC<FormContentProps> = ({ isDisabled, id, type, record }) => {
    return (
        <InvoiceLaborForm
            isHidden={(source) => {
                if (type === InvoiceTypeKeys.STANDARD) {
                    return (
                        source === 'component' ||
                        source === 'cause' ||
                        source === 'correction' ||
                        source === 'orderQuantity'
                    )
                }
            }}
            isDisabled={(source) => {
                if (!id) {
                    return isDisabled?.(source)
                }

                if (record.status !== InvoiceStatusKeys.OPEN) {
                    return true
                }

                if (type === InvoiceTypeKeys.WORK_ORDER) {
                    return source === 'description'
                }
            }}
        />
    )
}

const serializer: Serializer<InvoiceLabor> = ['description', 'quantity', 'price']
