import { type FC } from 'react'

import { formatMoney, UtilityDrawerAboveFooter } from 'components'
import { BoxContainer } from 'ui'

import { useDrilldownContext } from './context'

const TotalCost: FC = () => {
    const { total } = useDrilldownContext()

    return (
        <UtilityDrawerAboveFooter>
            <BoxContainer
                justifyContent="space-between"
                pr="16px"
            >
                <span>Total Costs</span>
                <span>{formatMoney(total || 0)}</span>
            </BoxContainer>
        </UtilityDrawerAboveFooter>
    )
}

export default TotalCost
