import { type ReactElement } from 'react'

import { TextWithIcon } from 'ui'

const createConfig = <Option extends { id: string }>(options: Option[]) => {
    const config = options.reduce((acc, current) => {
        acc[current.id as string] = current
        return acc
    }, {})

    const getConfig = (id: Option['id']): Option => {
        return config[id as string]
    }

    return getConfig
}

const createIdChoices = <Option extends { id: string }>(options: Option[]) => {
    return options.map((option) => ({ id: option.id })) as { id: Option['id'] }[]
}

const makeIconValue = <
    Option extends { id: string; icon: ReactElement; text: ReactElement | string },
    Id extends Option['id'],
>(
    getConfig: (id: Id) => Option,
) => {
    return function (id: Id) {
        const config = getConfig(id)

        if (!config) {
            return null
        }

        return <TextWithIcon icon={config.icon}>{config.text}</TextWithIcon>
    }
}

const makeIdValue = <
    Option extends { id: string; icon: ReactElement; text: ReactElement | string },
    Id extends Option['id'],
>(
    value: (id: Id) => ReactElement,
) => {
    return ({ id }: { id: Id }) => value(id)
}

export const options = {
    createConfig,
    createIdChoices,
    makeIdValue,
    makeIconValue,
}
