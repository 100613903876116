import { SkeletonSliderCardContainer, InfoCard, InfoCardDetails, InfoCardHeader } from 'components'
import { urls } from 'configs'
import { globalClassNames, Link } from 'lib'
import { addressFields } from 'resources/address'
import { stateFields } from 'resources/state'
import { Skeleton, DataAvatar, Typography } from 'ui'
import { pathJoin } from 'utils'

import customerFields from '../dataConfig'
import { type CustomerModel } from '../types'

export const CustomerCard = ({ customer }: { customer: CustomerModel }) => {
    if (!customer) {
        return <CardSkeleton />
    }
    const path = pathJoin(urls.customers, customer.id)

    return (
        <InfoCard>
            <InfoCardHeader
                avatar={
                    <Link to={path}>
                        <DataAvatar
                            imageSrc={customer.logo}
                            defaultImage={<customerFields.avatar.Icon />}
                        />
                    </Link>
                }
                title={
                    <Typography
                        variant="avatar-initials"
                        color="primary.main"
                        to={path}
                        fontWeight="500"
                        component={Link}
                        className={globalClassNames.ellipsis}
                    >
                        <customerFields.name.Value record={customer} />{' '}
                    </Typography>
                }
            />
            <InfoCardDetails
                propRecord={customer}
                details={[
                    addressFields.address1.dataCardRow({}),
                    addressFields.address2.dataCardRow({}),
                    {
                        label: stateFields.self.label,
                        source: (record) => stateFields.self.value(record.stateData),
                    },
                    addressFields.city.dataCardRow({}),
                    addressFields.zipCode.dataCardRow({}),
                    {
                        label: customerFields.taxId.label,
                        source: (record) => <customerFields.taxId.Value record={record} />,
                    },
                    {
                        label: customerFields.dotNumber.label,
                        source: (record) => <customerFields.dotNumber.Value record={record} />,
                    },
                    {
                        label: customerFields.units.label,
                        source: (record) => <customerFields.units.Value record={record} />,
                    },
                ]}
            />
        </InfoCard>
    )
}

const CardSkeleton = () => {
    return (
        <SkeletonSliderCardContainer
            sx={{
                height: '265px',
                gap: '10px',
            }}
        >
            <Skeleton
                height="8px"
                width="120px"
                sx={{
                    my: '20px',
                }}
            />
            <Skeleton />
        </SkeletonSliderCardContainer>
    )
}
