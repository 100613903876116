import { generateUtilityClasses, type GlobalStateSlot } from '@mui/material'

const slots: GlobalStateSlot[] = [
    'active',
    'checked',
    'completed',
    'disabled',
    'error',
    'expanded',
    'focused',
    'focusVisible',
    'required',
    'selected',
]

export const classes = generateUtilityClasses('', slots)
