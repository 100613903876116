import { type DataRecord } from 'appTypes'
import { useDataProviderContext } from 'core'
import { serialize, type Serializer } from 'core/form'
import { type ResourceType } from 'core/resource'
import { type UseMutationOptions } from 'lib'

export interface makeSubmitConfig<RecordType extends DataRecord = any> {
    onSuccess?: (newRecord) => void
    resource?: ResourceType
    serializer?: ((formData: RecordType) => Serializer<RecordType>) | Serializer<RecordType>
    meta?: any
    mutationOptions?: Omit<UseMutationOptions<RecordType>, 'returnPromise'>
}

// TODO: add better types
export const makeSubmit = <RecordType extends DataRecord = any>(
    mutateFunc,
    { resource, onSuccess, serializer, meta, mutationOptions }: makeSubmitConfig<RecordType> = {},
) => {
    const save = async (values, dryRun = false) => {
        const finalData = serialize<RecordType>(
            values,
            typeof serializer === 'function' ? serializer(values) : serializer,
            dryRun,
        )

        const id = values.id

        return mutateFunc(
            resource.resource,
            {
                data: finalData,
                meta: dryRun ? { ...meta, query: { ...meta?.query, dryRun } } : meta,
                ...(id && { id }),
            },
            {
                returnPromise: true,
                ...mutationOptions,
            },
        )
    }

    const submit = async (values) => {
        const record = await save(values)
        onSuccess?.(record)

        return record
    }

    return submit
}

export type SubmitValidate = <Error = any, Data = any>(values: any) => Promise<[Error, Data]>

export const useSubmitValidate = (
    resource: string,
    method: 'create' | 'update',
    { params, meta }: { params?: any; meta?: any },
) => {
    const dataProvider = useDataProviderContext()

    return (values) => {
        return dataProvider[method](resource, {
            data: values,
            meta: { ...meta, query: { ...meta?.query, dryRun: true } },
            ...params,
            previousData: null,
        })
            .then((data) => [undefined, data.data])
            .catch((error) => [error, undefined] as any)
    }
}
