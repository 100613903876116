import { type ReactElement, type FC, type ReactNode } from 'react'

import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import {
    InfoCard,
    InfoCardDetails,
    InfoCardHeader,
    ViewHeader,
    ArrayControllerContextProvider,
    UtilityDrawerForm,
    formatMoney,
} from 'components'
import { downloadOneAction } from 'core'
import { Link } from 'lib'
import { SendToContactDrawer } from 'resources/contacts'
import { poResource, type PurchaseOrderModel } from 'resources/purchaseOrders'
import { vendorsResource } from 'resources/vendors'
import { type WorkOrderModel } from 'resources/workOrders'
import { GridContainerColumns, GridItemLayout, IconBox, Section, Spacer } from 'ui'

interface PoFormWrapperType {
    children: ReactNode
    [x: string]: any
}
export const PoFormWrapper = ({ children, ...rest }: PoFormWrapperType) => {
    return (
        <UtilityDrawerForm {...rest}>
            <ArrayControllerContextProvider>{children}</ArrayControllerContextProvider>
        </UtilityDrawerForm>
    )
}

interface SendPoTogglerProps {
    children: (open: () => void) => ReactElement
    purchaseOrder?: PurchaseOrderModel
}

export const SendPoActionToggler = ({ children, purchaseOrder }: SendPoTogglerProps) => {
    const { purchaseOrderData } = useRecordContext<WorkOrderModel>()
    const finalPurchaseOrder = purchaseOrder || purchaseOrderData

    return (
        <SendToContactDrawer
            title="Send PO"
            resource={poResource.resource}
            record={finalPurchaseOrder}
            sendToFormConfig={{
                resource: vendorsResource.resource,
                resourceId: finalPurchaseOrder.vendorData.id,
                alertText: ' Purchase Orders can be sent only to contacts with an email address.',
            }}
        >
            {children}
        </SendToContactDrawer>
    )
}
export const SendPoActionTogglerButton: FC<SendPoTogglerProps> = ({ children, purchaseOrder }) => {
    return <SendPoActionToggler purchaseOrder={purchaseOrder}>{children}</SendPoActionToggler>
}
export const SendPoAction = () => {
    return (
        <SendPoActionTogglerButton>
            {(open) => (
                <IconBox
                    title="Send PO"
                    onClick={open}
                >
                    <Icons.SendOutlined />
                </IconBox>
            )}
        </SendPoActionTogglerButton>
    )
}
export const CanceledWorkOrderPurchaseInvoice = () => {
    const record = useRecordContext()

    if (record.purchaseOrderData) {
        return (
            <InfoCard>
                <InfoCardHeader title="Purchase Order" />
                <InfoCardDetails
                    details={[
                        {
                            label: 'Purchase Order Number',
                            source: ({ purchaseOrderData }) => purchaseOrderData.number,
                        },
                    ]}
                />
            </InfoCard>
        )
    }
    return null
}

export const PurchaseOrderExportAction = () => {
    const { purchaseOrderData } = useRecordContext<WorkOrderModel>()
    return downloadOneAction({
        children: ({ onClick }) => (
            <IconBox
                title="Export PO"
                onClick={onClick}
            >
                <Icons.Pdf />
            </IconBox>
        ),
        resource: poResource,
        id: purchaseOrderData.id,
        filename: purchaseOrderData.number,
        type: 'pdf',
    })
}

const WorkOrderPurchaseInvoice = () => {
    const { purchaseOrderData } = useRecordContext<WorkOrderModel>()

    return (
        <Section>
            <ViewHeader title="Purchase Order/Invoice">
                <ViewHeader.Content placement="rightMobile">
                    <Link to="invoice">
                        <IconBox title="See all">
                            <Icons.GridViewOutlined />
                        </IconBox>
                    </Link>
                </ViewHeader.Content>
            </ViewHeader>
            <GridContainerColumns>
                <GridItemLayout>
                    <InfoCard>
                        <InfoCardHeader
                            action={
                                <Spacer>
                                    <SendPoAction />
                                    <PurchaseOrderExportAction />
                                </Spacer>
                            }
                            title={purchaseOrderData.number}
                            titleTypographyProps={{
                                variant: 'subtitle2',
                            }}
                        />
                        <InfoCardDetails<WorkOrderModel>
                            details={[
                                {
                                    label: 'Purchase Order Total',
                                    source: ({ purchaseOrderData }) =>
                                        formatMoney(purchaseOrderData.total),
                                },
                                {
                                    label: 'Invoices Total',
                                    source: ({ purchaseOrderData }) =>
                                        formatMoney(purchaseOrderData.totalInvoices),
                                },
                                {
                                    label: 'Payments Total',
                                    source: ({ purchaseOrderData }) =>
                                        formatMoney(purchaseOrderData.totalPayments),
                                },
                            ]}
                        />
                    </InfoCard>
                </GridItemLayout>
            </GridContainerColumns>
        </Section>
    )
}
export default WorkOrderPurchaseInvoice
