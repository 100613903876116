import { type FC } from 'react'

import { type Identifier } from 'appTypes'
import { SelectInput } from 'components'
import { ReferenceInput } from 'core'

import { paymentTermResource } from '../utils'

interface Props {
    source?: string
    label?: string
    disabled?: boolean
    defaultValue?: Identifier
}

export const PaymentTermInput: FC<Props> = ({
    source = 'paymentTerm',
    label = 'Payment Term',
    disabled,
    defaultValue,
}) => {
    return (
        <ReferenceInput
            source={source}
            reference={paymentTermResource.resource}
            perPage={50}
            disabled={disabled}
        >
            <SelectInput
                label={label}
                optionText="name"
                disabled={disabled}
                defaultValue={defaultValue}
                disableEmptyValue
            />
        </ReferenceInput>
    )
}
