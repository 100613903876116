import { type Serializer, parseNumber } from 'core'
import { type DeleteOneParams } from 'core/actions'
import { type InventoryItem } from 'resources/inventory'

const parseQuantity = (value, data) => (data.trackInventory ? parseNumber(value) : null)

export const inventorySerializer: Serializer<InventoryItem> = [
    'part',
    'shop',
    { name: 'minQuantity', parse: parseQuantity },
    { name: 'maxQuantity', parse: parseQuantity },
    'binLocationId',
    'binDescription',
]

export const deleteInventoryAction: DeleteOneParams = {
    confirmConfig: {
        title: 'Are you sure you want to delete the selected part from the inventory?',
    },
}
