import { useListContext, useShowContext } from 'react-admin'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import { type DatagridActionType } from 'components'
import { type GridActionViewProps } from 'components/Datagrid/DatagridAction'
import { type ActionChildren, deleteOneAction, type ResourceType } from 'core'
import { type LineItemsModel, type PurchaseOrderModel } from 'resources/purchaseOrders'

import LineItemsDrawerToggler from './LineItemsDrawerToggler'

type DeletePOActionProps = {
    id: LineItemsModel['id']
    children: ActionChildren<GridActionViewProps>
}

const DeleteItemAction = ({ id, children }: DeletePOActionProps) => {
    const { total } = useListContext()
    const { record } = useShowContext<PurchaseOrderModel>()

    const isLastItem = total === 1 && record.dateLastReceived

    return deleteOneAction({
        children,
        id,
        ...(isLastItem ? { notifyMessage: 'Successfully closed' } : {}),
        confirmConfig: {
            confirmType: isLastItem ? 'INFO' : 'DELETE',

            confirmButtonProps: isLastItem ? { children: 'CONFIRM, CLOSE PO' } : undefined,
            title: `Are you sure you want to delete ${isLastItem ? 'the last' : 'this line'} item?`,
            content: isLastItem
                ? 'By deleting the last line item on the receipt, the PO will be closed automatically.'
                : undefined,
        },
    })
}

const LineItemsEditAction = ({
    children,
    resource,
    id,
}: {
    children: ActionChildren<GridActionViewProps>
    resource: ResourceType
    id: Identifier
}) => {
    const { record } = useShowContext()
    return (
        <LineItemsDrawerToggler
            poRecord={record}
            resource={resource.resource}
            id={id}
        >
            {({ onClick }) =>
                children({
                    title: 'View/Edit',
                    Icon: Icons.Edit,
                    onClick,
                })
            }
        </LineItemsDrawerToggler>
    )
}

export const lineItemsActions: DatagridActionType<LineItemsModel> = (
    record,
    { children, resource },
) => [
    <LineItemsEditAction
        id={record.id}
        resource={resource}
        key="edit"
    >
        {children}
    </LineItemsEditAction>,
    <DeleteItemAction
        key="delete"
        id={record.id}
        children={children}
    />,
]
