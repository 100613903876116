import { memo, type FC } from 'react'

import { globalClassNames } from 'lib'
import { Chip, Box, OverflowElements, type OverflowElementsProps } from 'ui'

import { type TagModel } from '../types'

import { TagField } from './TagField'

interface Props {
    tags: TagModel[] | null
}

export const TagsField: FC<Props> = memo(({ tags }: Props) => {
    const tagsCount = tags?.length

    if (!tagsCount) {
        return null
    }

    return (
        <OverflowElements
            data={tags}
            renderContainer={renderContainer}
            renderOverflow={renderOverflow}
            renderItem={renderItem}
        />
    )
})

const renderContainer: OverflowElementsProps<TagModel>['renderContainer'] = (children) => (
    <Box
        display="flex"
        alignItems="center"
        gap="4px"
        className={globalClassNames.ellipsis}
        children={children}
    />
)

const renderOverflow: OverflowElementsProps<TagModel>['renderOverflow'] = (itemsCount) => (
    <Chip
        size="small"
        sx={{ ml: '4px' }}
        label={`+${itemsCount}`}
    />
)

const renderItem: OverflowElementsProps<TagModel>['renderItem'] = (tag) => (
    <TagField
        tag={tag}
        key={tag.id}
    />
)
