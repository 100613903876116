import { authStore } from 'core/auth'

import { type Condition } from './types'
import { useSync } from './useSync'

export const useUserTaskSync = (condition: Condition = true): boolean => {
    const activeTasks = authStore.user.activeTasks

    const syncing =
        Boolean(activeTasks.length) &&
        (typeof condition === 'function' ? condition(activeTasks) : condition)

    useSync(syncing)

    return syncing
}
