import { useListContext, useShowContext } from 'react-admin'

import Icons from 'assets/icons'
import { ListTotalBadge, SliderView, ViewHeader, formatMoney } from 'components'
import { type PurchaseInvoiceModel, PurchaseInvoiceCard } from 'resources/purchaseInvoices'
import { useWoActionIsDisabled, type WorkOrderModel } from 'resources/workOrders'
import { IconBox, Section } from 'ui'

import CreateInvoice from '../CreateInvoice'

import InvoicesSectionEmpty from './InvoicesSectionEmpty'

const InvoicesSection = () => {
    const { isLoading, data, total } = useListContext<PurchaseInvoiceModel>()
    const { record } = useShowContext<WorkOrderModel>()
    const disabled = useWoActionIsDisabled()

    let content

    if (isLoading) {
        return null
    }
    if (total) {
        const items = data.map((invoice) => (
            <PurchaseInvoiceCard
                disableActions={disabled}
                invoice={invoice}
            />
        ))
        content = (
            <SliderView
                height="204px"
                items={items}
            />
        )
    } else {
        content = <InvoicesSectionEmpty />
    }
    return (
        <Section>
            <ViewHeader title="Invoices">
                <ViewHeader.Content>
                    <ListTotalBadge
                        renderContent={() => {
                            const v = formatMoney(record.purchaseOrderData.totalInvoices)
                            return total ? (
                                <>
                                    {total} • {v} Total
                                </>
                            ) : (
                                '0'
                            )
                        }}
                    />
                </ViewHeader.Content>
                {disabled ? null : (
                    <ViewHeader.Content placement="rightMobile">
                        <CreateInvoice>
                            {(open) => (
                                <IconBox
                                    title="Add Invoice"
                                    onClick={open}
                                >
                                    <Icons.Add />
                                </IconBox>
                            )}
                        </CreateInvoice>
                    </ViewHeader.Content>
                )}
            </ViewHeader>
            {content}
        </Section>
    )
}
export default InvoicesSection
