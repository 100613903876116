import { type FC } from 'react'

import { Form, EmailInput } from 'components'
import { urls } from 'configs'
import { useAuthProvider, useSubmit } from 'core'
import { useNavigate } from 'lib'
import { Typography } from 'ui'

import { AuthBody, AuthSubmitButton, ErrorResetMessage, NoAccountText } from '../components'

import { type ResetPasswordState } from './ResetPasswordDone'

const ResetPassword: FC = () => {
    const authProvider = useAuthProvider()

    const navigate = useNavigate()

    const handleSubmit = useSubmit<{ email: string }>(async ({ email }) => {
        await authProvider.resetPassword(email)

        const state: ResetPasswordState = { validEmail: email }
        navigate(urls.passwordResetDone, {
            state,
        })
    })

    return (
        <AuthBody>
            <Typography
                variant="h6"
                mb="21px"
            >
                Forgot Password
            </Typography>

            <Form
                onSubmit={handleSubmit}
                warnWhenUnsavedChanges={false}
            >
                <ErrorResetMessage />

                <EmailInput
                    required
                    label="Email Address"
                />

                <AuthSubmitButton label="Reset Password" />
            </Form>

            <NoAccountText mt="23px" />
        </AuthBody>
    )
}

export default ResetPassword
