import { useFormContext } from 'react-hook-form'

import { formErrors, formErrorToString } from 'core/form'
import { Alert, Typography } from 'ui'

const FormNonFieldErrors = () => {
    const { formState } = useFormContext()
    const error = formErrors(formState.errors).nonFieldErrors

    if (!error) {
        return null
    }

    return (
        <Alert
            variant="standard"
            severity="error"
            sx={{
                mb: '24px',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Typography
                variant="body2"
                color="inherit"
            >
                {formErrorToString(error)}
            </Typography>
        </Alert>
    )
}

export default FormNonFieldErrors
