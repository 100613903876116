import { type FC, type ReactNode } from 'react'

import { useFieldArray, useFormContext } from 'react-hook-form'

import { ArrayControllerContextProviderBase } from './ArrayControllerBase'

interface Props {
    children: ReactNode
    source?: string
}

const ArrayControllerForm: FC<Props> = ({ children, source = 'data' }) => {
    const { control } = useFormContext()

    const fields = useFieldArray({
        control,
        name: source,
    })

    return (
        <ArrayControllerContextProviderBase
            value={{
                array: fields.fields,
                append: (item) => {
                    fields.append(item, { shouldFocus: false })
                    return item
                },
                remove: ({ index }) => {
                    fields.remove(index)
                },
                setArray: () => {
                    //
                },
                limit: 10,
            }}
        >
            {children}
        </ArrayControllerContextProviderBase>
    )
}

export default ArrayControllerForm
