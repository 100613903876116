import { computed, observable, action, makeObservable, runInAction } from 'mobx'

import { type Identifier, type RecursivePartial } from 'appTypes'
import { config, urls } from 'configs'
import api, { type CApi, type LoginWithGoogleData, type ApiClientConfig } from 'core/api'
import { type Flags } from 'lib'
import {
    type CompanyModel,
    type ShopModel,
    type BillingModel,
    type UserPreferences,
    type UserModel,
    type PermissionsModel,
    getStoredShop,
} from 'resourcesBase'
import { debounce, makeid, merge } from 'utils'

export interface AuthStoreLogoutParams {
    redirectUrl?: string
}

type UserOnFetch = (user: UserModel | null) => Promise<void>

export class AuthStore {
    initialized: boolean = false

    user: UserModel = null

    currentCompanyId: Identifier = null

    companySettings: CompanyModel['settings'] = {} as CompanyModel['settings']

    companyPreferences: CompanyModel['preferences'] = {} as CompanyModel['preferences']

    redirectUrl: string = null

    public shop: ShopModel | null

    public api: CApi

    public apiToken: string

    public preferences: UserPreferences

    public flags: Flags

    private onInitCallbacks: UserOnFetch[] = []

    constructor(api) {
        this.api = api

        makeObservable(this, {
            user: observable,
            currentCompanyId: observable,
            companySettings: observable,
            companyPreferences: observable,
            shop: observable,
            redirectUrl: observable,
            initialized: observable,
            flags: observable,
            login: action.bound,
            logout: action.bound,
            setInitialized: action.bound,
            setBilling: action.bound,
            setRedirectUrl: action.bound,
            setCurrentCompanyId: action.bound,
            setCompanySettings: action.bound,
            setCompanyPreferences: action.bound,
            setShop: action.bound,
            updateUserPreferences: action.bound,
            setUser: action.bound,
            setApiToken: action.bound,
            acceptInvite: action.bound,
            checkInvite: action.bound,
            currentCompany: computed,
            shopId: computed,
            billing: computed,
            startAsyncTask: action.bound,
            action: action.bound,
            updateFlags: action.bound,
            // preferences: observable,   // if observable 'updatePreferences' calls infinite times
            // updatePreferences: action.bound,
        })
    }

    init() {
        const token = localStorage.getItem(config.API_TOKEN_KEY)
        if (token) {
            this.tryToken(token).finally(() => {
                this.setInitialized(true)
            })
        } else {
            this.onInitCallMethods(null)
            this.setInitialized(true)
        }
    }

    onInit(cb: UserOnFetch) {
        this.onInitCallbacks.push(cb)
    }

    private async onInitCallMethods(user: UserModel | null) {
        // TODO: what if some of those promises give error?
        await Promise.all(this.onInitCallbacks.map((cb) => cb?.(user)))
    }

    updateFlags(flags: Flags) {
        this.flags = flags
    }

    setInitialized(value: boolean) {
        this.initialized = value
    }

    setUser(user: UserModel | null) {
        this.user = user
        const m = this.getMembership()
        this.setCurrentCompanyId(m ? m.company.id : null)
        this.setCompanySettings(m ? m.company.settings : ({} as CompanyModel['settings']))
        this.setCompanyPreferences(m ? m.company.preferences : ({} as CompanyModel['preferences']))
        this.setShop((user && getStoredShop()) || null)
    }

    async tryToken(token: string, rememberMe = false) {
        this._setApiToken(token)
        return this.fetchUser(true)
            .then((user) => {
                this.setApiToken(token, rememberMe)
                return user
            })
            .catch((error) => {
                console.error(error)
                this.setApiToken(null)
            })
    }

    fetchUser(withExtras = false) {
        return this.api.getCurrentUser().then(async (user) => {
            if (withExtras) {
                await this.onInitCallMethods(user)
            }
            if (!user.preferences) {
                user.preferences = { resources: {} }
            }
            if (!user.preferences.resources) {
                user.preferences.resources = {}
            }
            this.preferences = user.preferences
            this.setUser(user)
            return user
        })
    }

    async fetchBilling() {
        if (!this.billing) {
            return
        }
        await this.api.get('/billing/info').then((billing: BillingModel) => {
            this.setBilling(billing)
        })
    }

    setBilling(billing: BillingModel) {
        this.user.membership.billing = billing
    }

    async setPaymentLimit(amount: number) {
        if (!this.billing) {
            return
        }
        return this.api.post('/billing/payment-limit', {
            amount,
        })
    }

    async upgradeBillingToProPlan(paymentMethodId: string) {
        if (!this.billing) {
            return
        }
        return this.api.post('/billing/upgrade', {
            paymentMethodId,
        })
    }

    async downgradeSubscription() {
        if (!this.billing) {
            return
        }
        return this.api.post('/billing/downgrade')
    }

    async changePaymentMethod(paymentMethodId: string) {
        if (!this.billing) {
            return
        }
        return this.api.post('/billing/payment-method', {
            paymentMethodId,
        })
    }

    async loginWithGoogle(values: LoginWithGoogleData) {
        const data: AuthResponse = await this.api.loginWithGoogle(values)
        if (data.token) {
            await this.tryToken(data.token, true)
        }
        return data
    }

    login(email: string, password: string, rememberMe = true) {
        return this.api.login(email, password).then(async (data: AuthResponse) => {
            await this.tryToken(data.token, rememberMe)
        })
    }

    async logout({ redirectUrl = urls.login }: AuthStoreLogoutParams = {}) {
        this.setRedirectUrl(redirectUrl)
        return this.api.logout().finally(() => {
            this.setApiToken(null)
        })
    }

    changePassword(oldPassword: string, newPassword: string) {
        return this.api.changePassword(oldPassword, newPassword)
    }

    resetPassword(email: string) {
        return this.api.resetPassword(email)
    }

    async resetPasswordConfirm(password, uid, token) {
        const data: AuthResponse = await this.api.resetPasswordConfirm(password, uid, token)
        await this.tryToken(data.token, true)
        return data
    }

    updateUser(params: { data: Partial<UserModel> }): Promise<UserModel> {
        return this.api.patch('/auth/user', params.data).then((data) => {
            this.setUser(data)
            return data
        })
    }

    saveCompany(params: { data: Partial<CompanyModel> }): Promise<CompanyModel> {
        return this.api.patch('/company', params.data).then((resp) => {
            this.updateMembership(resp)
            return resp
        })
    }

    setCurrentCompanyId(id: Identifier): void {
        this.currentCompanyId = id
    }

    setCompanySettings(settings: CompanyModel['settings']): void {
        this.companySettings = settings
    }

    setCompanyPreferences(preferences: CompanyModel['preferences']): void {
        this.companyPreferences = preferences
    }

    setShop(shopId: Identifier | null): void {
        const shops = this.user?.membership.shops || []

        if (shops.length === 1) {
            this.shop = shops[0]
            return
        }

        if (!shopId) {
            this.shop = null
            return
        }
        const shop = shops.find((shop) => shop.id === shopId)

        if (shop) {
            this.shop = shop
            return
        }
        this.shop = null
    }

    setRedirectUrl(url: string): void {
        this.redirectUrl = url
    }

    updateMembership(company: CompanyModel) {
        const membership = this.user.membership

        runInAction(() => {
            membership.company = company
        })
    }

    get permissions(): PermissionsModel {
        return this.getMembership?.().permissions
    }

    getMembership(): UserModel['membership'] {
        return this.user?.membership
    }

    get currentCompany(): CompanyModel {
        return this.getMembership()?.company
    }

    get billing(): BillingModel {
        if (!this.flags.useBilling) {
            return null
        }
        return this.getMembership()?.billing
    }

    get userEmail(): string {
        return this.user?.email
    }

    get userPreferences(): UserPreferences {
        return this.user?.preferences
    }

    get userPhoneNumber(): string {
        return this.user?.phone
    }

    get userFullName(): string {
        const u = this.user
        if (!u) {
            return ''
        }
        return u.name || '' || this.userEmail
    }

    get shopId(): Identifier | null {
        return this.shop?.id || null
    }

    setApiToken(apiToken: string, rememberMe = true) {
        if (apiToken && this.apiToken === apiToken) {
            return
        }
        this.apiToken = apiToken
        if (!apiToken || rememberMe) {
            localStorage.setItem(config.API_TOKEN_KEY, apiToken)
        }
        this._setApiToken(apiToken)
    }

    _setApiToken(apiToken: string) {
        if (!apiToken) {
            localStorage.removeItem(config.API_TOKEN_KEY)
            this.api.clearAuthKey()
            this.api.setUnauthenticatedHandler(null)
            this.setUser(null)
            return
        }
        this.api.setAuthKey(apiToken)
        this.api.setUnauthenticatedHandler(() => {
            this.api.cancelPendingRequest()
            this.setApiToken(null)
        })
    }

    checkInvite(token: string) {
        return this.api.checkInvite(token)
    }

    acceptInvite(token: string, password: string, name: string) {
        return this.api.acceptInvite(token, password, name).then((data) => {
            this.tryToken(data.token, true)
            return data.token
        })
    }

    deactivateCompany() {
        return this.api.delete('/company')
    }

    updateUserPreferences(preferences: UserPreferences) {
        this.preferences = preferences
    }

    async updatePreferences(data: RecursivePartial<UserPreferences>) {
        const preferences = merge(this.preferences, data)

        this.updateUserPreferences(preferences)
        updatePreferences(preferences)
    }

    getUserPreferencesByResource(resource: string) {
        if (!this.preferences.resources[resource]) {
            this.preferences.resources[resource] = {}
        }

        return this.preferences.resources[resource]
    }

    updateLocalUserPreferencesByResource<
        ResourceName extends keyof UserPreferences['resources'],
        KeyName extends keyof UserPreferences['resources'][ResourceName],
        Value extends UserPreferences['resources'][ResourceName][KeyName],
    >(resource: ResourceName, key: KeyName, value: Value) {
        if (!this.preferences.resources[resource]) {
            this.preferences.resources[resource] = {}
        }

        this.preferences.resources[resource][key] = value
    }

    async syncPreferences() {
        updatePreferences(this.preferences)
    }

    startAsyncTask(name = makeid()) {
        this.user.activeTasks.push(name)
        this.currentCompany.activeTasks.push(name)
    }

    action(cb: () => void) {
        cb()
    }
}

export default new AuthStore(api)

export type AuthResponse = { token: string }

const updatePreferences = debounce((preferences: UserPreferences): void => {
    const clientConfig: ApiClientConfig = { preventCacheReset: true }

    api.put(
        '/auth/user',
        {
            preferences,
        },
        { params: { clientConfig } },
    )
}, 1000)
