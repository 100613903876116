import { type MemberModel } from 'resourcesBase'
import { displayBooleanValue } from 'utils'

interface Data extends Pick<MemberModel, 'viewUnitsWithoutDomicile'> {}

const value = (data: Data) => {
    return displayBooleanValue(data.viewUnitsWithoutDomicile)
}

const source = 'viewUnitsWithoutDomicile' as const satisfies keyof MemberModel

const viewUnitsWithoutDomicile = {
    source,
    label: 'View Units Without Domicile',
    value,
}

export default viewUnitsWithoutDomicile
