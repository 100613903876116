import images from 'assets/images'
import { PasswordInput, TextInput } from 'components'
import { parseString, requiredValidation } from 'core'

import ConnectTopSection from './ConnectTopSection'

const GeotabConnect = () => {
    return (
        <>
            <ConnectTopSection
                logo={images.geotabLogo}
                title="Geotab Authentication"
                alertText="In order to connect to Geotab we would need your Geotab credentials"
            />

            <TextInput
                label="Geotab Username"
                source="username"
                validate={requiredValidation}
            />

            <PasswordInput
                label="Geotab Password"
                validate={requiredValidation}
                autoComplete="new-password"
            />

            <TextInput
                label="Geotab Database"
                source="database"
                parse={parseString}
            />
        </>
    )
}

export default GeotabConnect
