import { createResource } from 'core'
import { authStore } from 'core/auth'

export const divisionResource = createResource({
    name: 'divisions',
    resource: 'divisions',
})

export const divisionFilter = ({ id, label }: { id?: string; label?: string } = {}) =>
    authStore.companySettings.hasDivisions
        ? ({
              id: id || ('division' as any),
              label: label || 'Division',
          } as const)
        : null

export const divisionSort = ({ id, label }: { id?: string; label?: string } = {}) =>
    authStore.companySettings.hasDivisions
        ? ({
              id: id || ('division' as any),
              label: label || 'Division',
          } as const)
        : null
