import { formatMoney } from 'components'

import { type PtLaborModel } from '../types'

const source: keyof PtLaborModel = 'price'

const label = 'Rate'

const value = (record: Pick<PtLaborModel, 'price'>) => {
    return formatMoney(record.price)
}
export const rate = {
    source,
    label,
    value,
}
