import {
    InfoCard,
    InfoCardDetails,
    InfoCardHeader as CardHeader,
    phoneMaskResolver,
} from 'components'
import { globalClassNames, styled } from 'lib'
import { MemberIcon } from 'resources/members'
import { Anchor, cardHeaderClasses, DataAvatar, Typography } from 'ui'

import { type ContactModel } from '../types'
import { contactActions } from '../utils'

import { ContactDrawerToggler } from './ContactDrawerToggler'

interface ContactCardProps {
    contact: ContactModel
}

const InfoCardHeader = styled(CardHeader)`
    .${cardHeaderClasses.content} {
        overflow: hidden;
    }
`
export const ContactCard = ({ contact }: ContactCardProps) => {
    return (
        <InfoCard>
            <InfoCardHeader
                record={contact}
                avatar={
                    <DataAvatar
                        sx={{ cursor: 'pointer' }}
                        defaultImage={<MemberIcon record={contact} />}
                    />
                }
                actions={contactActions}
                title={
                    <Typography
                        sx={{
                            fontSize: 20,
                            fontWeight: 500,
                        }}
                        className={globalClassNames.ellipsis}
                    >
                        <ContactDrawerToggler id={contact.id}>
                            {({ onClick }) => (
                                <Anchor onClick={onClick}>{contact.name || '-'}</Anchor>
                            )}
                        </ContactDrawerToggler>
                    </Typography>
                }
            />
            <InfoCardDetails<ContactModel>
                details={[
                    {
                        label: 'Email Address',
                        source: 'email',
                    },
                    {
                        label: 'Phone Number',
                        source: ({ phone }) => phoneMaskResolver(phone),
                    },
                ]}
                propRecord={contact}
            />
        </InfoCard>
    )
}
