import { UtilityDrawerForm, type UtilityDrawerFormProps, useUtilityDrawerContext } from 'components'
import { serialize, type Serializer, useSubmit } from 'core'
import api from 'core/api'
import { useFinalErrorHandler } from 'core/errors'
import { useQueryClient } from 'lib'
import { startProviderTask, type Telematics } from 'resources/telematics'

import IntegrationDrawerCloseButton from './IntegrationDrawerCloseButton'
import IntegrationSuccessContent from './IntegrationSuccessContent'
import { type IntegrationDrawerTogglerExtraProps } from './types'

interface propsType extends Pick<UtilityDrawerFormProps, 'defaultValues' | 'children'> {}

const IntegrationDrawerWrapper = (props: propsType) => {
    const { updateState, state, extra, forceClose } = useUtilityDrawerContext()
    const errorHandler = useFinalErrorHandler()
    const { integration }: IntegrationDrawerTogglerExtraProps = extra
    const queryClient = useQueryClient()

    const handleSubmit = useSubmit(
        async ({ formDirtyTriggerField, ...formData }) => {
            try {
                const serializedData = serialize(
                    formData,
                    integrationSerializer(formData.vehiclesSyncStatus),
                )
                if (integration.isActive) {
                    await api.patch('telematics/' + integration.provider, serializedData)
                } else {
                    await api.post(
                        'telematics/' + integration.provider + '/activate',
                        serializedData,
                    )
                }
                startProviderTask(integration.provider)
            } catch (err) {
                errorHandler(err, {
                    fallbackError: 'Connection unsuccessful',
                })
                forceClose()
                return
            }
            queryClient.invalidateQueries('telematics')
            integration.isActive
                ? forceClose()
                : updateState({
                      ...state,
                      drawerArgs: {
                          ...state.drawerArgs,
                          renderContent: () => <IntegrationSuccessContent />,
                          renderBottomLeft: () => null,
                          renderBottomRight: () => <IntegrationDrawerCloseButton />,
                      },
                  })
        },
        {
            successMessage: integration.isActive ? 'Changes updated successfully' : null,
        },
    )
    return (
        <UtilityDrawerForm
            onSubmit={handleSubmit}
            disableCloseOnSubmit
            {...props}
        />
    )
}

export default IntegrationDrawerWrapper

const baseIntegrationSerializer: Serializer<Telematics> = [
    { name: 'autosaveNewVehicles', parse: 'boolean' },
    { name: 'syncDvirs', parse: 'boolean' },
    { name: 'syncMeters', parse: 'boolean' },
]

const integrationSerializer: (
    vehiclesSyncStatus: object,
) => Serializer<Telematics & { vehiclesSyncStatus: object }> = (vehiclesSyncStatus) => {
    if (!vehiclesSyncStatus) {
        return baseIntegrationSerializer
    }
    return [{ name: 'vehiclesSyncStatus', parse: 'emptyToNull' }, ...baseIntegrationSerializer]
}
