import { type PropsWithChildren } from 'react'

import { useShowContext } from 'react-admin'

import { type IntervalsType } from 'resources/pm'
import { type UnitModel, isUnitArchived } from 'resources/units'
import { Badge, badgeClasses } from 'ui'

export const PMBadge = ({
    children,
    unitPmRecord,
    disabled,
}: PropsWithChildren & { unitPmRecord: IntervalsType; disabled?: boolean }) => {
    const { record: unit } = useShowContext<UnitModel>()
    const isArchived = isUnitArchived(unit)

    if (unitPmRecord.status === 'PLANNED' || isArchived || disabled) {
        return <>{children}</>
    }
    if (unitPmRecord.status === 'OVERDUE') {
        return (
            <Badge
                variant="dot"
                color="error"
            >
                {children}
            </Badge>
        )
    }
    return (
        <Badge
            variant="dot"
            sx={(theme) => ({
                [`& .${badgeClasses.badge}`]: {
                    backgroundColor: `${theme.palette.charts.orange} !important`,
                },
            })}
        >
            {children}
        </Badge>
    )
}
