import { type FC } from 'react'

import { useFormContext } from 'react-hook-form'

import { type DialogSelectorProps } from 'components'
import { emptyOptionValue } from 'core'
import { UnitInput } from 'resources/units'
import { woResource } from 'resources/workOrders'

export interface WorkOrderUnitInputProps extends Pick<DialogSelectorProps, 'defaultFilter'> {
    disabled?: boolean
}
export const WorkOrderUnitInput: FC<WorkOrderUnitInputProps> = ({ defaultFilter, ...props }) => {
    const { setValue, getValues, watch } = useFormContext()
    const shopId = getValues('shop') || watch('shop')

    return (
        <UnitInput
            resettable
            source="unit"
            label="Unit Number"
            required
            contextType={woResource.resource}
            onSelectedChange={({ selected }) => {
                setValue('customer', selected?.customer)
                setValue('domicile', selected?.domicile)
                if (selected?.domicile) {
                    setValue('shop', selected?.domicile, {
                        shouldValidate: true,
                        shouldDirty: true,
                        shouldTouch: true,
                    })
                }
            }}
            defaultFilter={defaultFilter}
            referenceFilter={shopId ? { domicileId: [shopId, emptyOptionValue] } : undefined}
            {...props}
        />
    )
}
