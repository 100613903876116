import api from 'core/api'
import { useQuery } from 'lib'

import { type TelematicsQueryResult, type Telematics, type TelematicsType } from '../types'
import { integrationRedirectUri, telematicsExtras } from '../utils'

export const useTelematics = () => {
    const list = useQuery<TelematicsQueryResult>(
        'telematics',
        async () => {
            const response: TelematicsQueryResult = await api.get('telematics')
            return {
                results: response.results,
            }
        },
        {
            meta: {
                refetchState: true,
            },
        },
    )

    const data = list.data?.results || ([] as Telematics[])

    let errorCount = 0
    let runningCount = 0
    let hasUnseenVehicles = false
    let hasConnection = false
    const telematics = { ...telematicsExtras } as TelematicsType

    data.forEach((item) => {
        telematics[item.provider] = {
            ...item,
            ...telematics[item.provider],
        }
        if (item.error) {
            errorCount += 1
        } else if (item.isActive) {
            runningCount += 1
        }

        if (item.hasUnseenVehicles) {
            hasUnseenVehicles = true
        }

        if (item.isActive) {
            hasConnection = true
        }
    })

    return {
        hasConnection,
        errorCount,
        runningCount,
        list,
        data,
        telematics,
        redirectUri: integrationRedirectUri,
        hasUnseenVehicles,
    }
}
