import { type ComponentProps, type FC } from 'react'

import { styled } from 'lib'

import Typography, { type TypographyProps } from './Typography'

interface SectionTypographyProps {
    frontLine?: boolean
}

export const SectionTypography = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'frontLine',
})<SectionTypographyProps>`
    display: flex;
    align-items: center;

    ${({ frontLine, theme }) =>
        frontLine
            ? `
        &::before {
            content: "";
            display: inline-block;
            margin-right: 12px;
            width: 12px;
            background-color: ${theme.palette.text.primary};
            height: 2px;
        }
    `
            : ''}
`

SectionTypography.defaultProps = {
    color: 'text.primary',
    frontLine: false,
    mb: '16px',
}

interface SectionTitleProps
    extends SectionTypographyProps,
        ComponentProps<typeof SectionTypography> {
    smallVariant?: TypographyProps['variant']
    largeVariant?: TypographyProps['variant']
}

const smallVariant: TypographyProps['variant'] = 'subtitle1'
const largeVariant: TypographyProps['variant'] = 'h6'
const fontWeight: number = 500

export const SectionTitle: FC<SectionTitleProps> = ({
    smallVariant: smallVariantProp,
    largeVariant: largeVariantProp,
    ...rest
}) => {
    return (
        <SectionTypography
            typography={{
                xs: smallVariantProp || smallVariant,
                sm: largeVariantProp || largeVariant,
            }}
            fontWeight={fontWeight}
            {...rest}
        />
    )
}

interface SectionTitleSmallProps extends ComponentProps<typeof SectionTypography> {}

export const SectionTitleSmall: FC<SectionTitleSmallProps> = (props) => {
    return (
        <SectionTypography
            variant={smallVariant}
            fontWeight={fontWeight}
            {...props}
        />
    )
}
