import { createResource } from 'core'

export const membersPermissions = {
    resendInvitation: 'resendInvitation',
}

export const membersResource = createResource({
    resource: 'members',
    name: 'members',
})
