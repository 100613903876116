import { formatDate } from 'lib'

import { InvoiceStatusKeys, type InvoiceModel } from '../types'

const dateSource = 'statusUpdated' as const satisfies keyof InvoiceModel
const dateLabel = (record: InvoiceModel) =>
    record.status === InvoiceStatusKeys.CLOSED ? 'Closed on' : 'Canceled on'
const dateValue = (record: Pick<InvoiceModel, 'statusUpdated'>): string =>
    formatDate(record.statusUpdated, (format) => format.shortenedDateTime)

const bySource = 'statusUpdatedBy' as const satisfies keyof InvoiceModel
const byLabel = (record: InvoiceModel) =>
    record.status === InvoiceStatusKeys.CLOSED ? 'Closed by' : 'Canceled by'
const byValue = (record: Pick<InvoiceModel, 'statusUpdatedBy'>): string =>
    record.statusUpdatedBy.name || record.statusUpdatedBy.email

const statusChanged = {
    dateSource,
    dateLabel,
    dateValue,
    bySource,
    byLabel,
    byValue,
}

export default statusChanged
