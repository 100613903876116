import { type ReactNode, useCallback, useRef } from 'react'

import { useChoicesContext } from 'react-admin'

import { type DataRecord } from 'appTypes'
import { Checkbox } from 'ui'

import SelectInputBase, { type SelectInputPropsExtended } from './SelectInputBase'

const SelectArrayInput = (props: SelectInputPropsExtended) => {
    const { selectedChoices: referenceInputSelectedChoices } = useChoicesContext()

    const selectedOrAllChoicesRef = useRef<{ [key: string]: DataRecord }>()

    if (referenceInputSelectedChoices) {
        selectedOrAllChoicesRef.current = referenceInputSelectedChoices.reduce((acc, choice) => {
            acc[choice.id] = choice
            return acc
        }, {})
    } else {
        selectedOrAllChoicesRef.current = props.choices.reduce((acc, choice) => {
            if (props.inputField.field.value.includes(choice.id)) {
                acc[choice.id] = choice
            }
            return acc
        }, {})
    }

    const optionText = useCallback(
        (choice: DataRecord, inOption = true) => {
            let text: ReactNode = ''
            if (!props.optionText) {
                text = choice.name
            } else if (typeof props.optionText === 'function') {
                text = props.optionText(choice)
            } else {
                text = choice[props.optionText]
            }
            if (inOption) {
                return (
                    <>
                        <Checkbox
                            size="medium"
                            checked={!!selectedOrAllChoicesRef.current[choice.id]}
                            sx={{ p: '5px' }}
                        />
                        {text}
                    </>
                )
            }
            return text
        },
        [props.optionText],
    )

    return (
        <SelectInputBase
            {...props}
            multiple
            optionText={optionText}
            SelectProps={{
                renderValue: (selected: string[]) =>
                    selected
                        .map((choice) => optionText(selectedOrAllChoicesRef.current[choice], false))
                        .join(', '),
                multiple: true,
                MenuProps: {
                    MenuListProps: {
                        sx: {
                            '& .MuiMenuItem-root.Mui-selected:not(:hover)': {
                                backgroundColor: 'unset',
                            },
                        },
                    },
                },
            }}
        />
    )
}

export default SelectArrayInput
