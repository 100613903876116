import {
    createContext,
    type FC,
    type ReactNode,
    useContext,
    useEffect,
    useLayoutEffect,
    useMemo,
} from 'react'

import { useEventCallback } from '@mui/material'
import { observer } from 'mobx-react'
import { proxy, useSnapshot } from 'valtio'

import { type WithChildrenNode } from 'appTypes'
import { useDidUpdate } from 'hooks'
import { useLocation, useMediaQuery } from 'lib'

import { type NavItemConfig } from './types'
import { useIsActive } from './useIsActive'
import { useMenu } from './useMenu'

type Render = () => ReactNode
type ContentType = Render | boolean

interface State {
    content: ContentType
    menu: NavItemConfig[]
    activeElements: { [key: string]: boolean }
    lastNavigated: number
}

interface Functions {
    close: () => void
    open: (render?: ContentType) => void
}

interface Props extends WithChildrenNode {
    menu: NavItemConfig[]
}

export const SidebarController: FC<Props> = observer(({ children, menu: menuProp }: Props) => {
    const menu = useMenu(menuProp)
    const activeElements = useIsActive(menu)

    const { pathname, search, hash } = useLocation()

    const match = useMediaQuery((theme) => theme.breakpoints.up(theme.props.mobileViewBreakpoint))

    const state = useMemo<State>(() => {
        return proxy<State>({
            content: null,
            menu,
            activeElements,
            lastNavigated: Date.now(),
        })
    }, [])

    useEffect(() => {
        state.menu = menu
        state.activeElements = activeElements
    }, [menu, activeElements])

    const close: Functions['close'] = useEventCallback(() => {
        state.content = null
    })

    const open: Functions['open'] = useEventCallback((render = true) => {
        state.content = render
    })

    useDidUpdate(() => {
        state.lastNavigated = Date.now()
        close()
    }, [pathname, search, hash])

    useLayoutEffect(() => {
        close()
    }, [match])

    const value = useMemo(() => {
        return {
            state,
            functions: {
                close,
                open,
            },
        }
    }, [state, close, open])

    return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
})

export const useSidebarContext = () => {
    const context = useContext(SidebarContext)

    if (!context) {
        throw new Error('useSidebar must be used within a SidebarContextProvider')
    }

    return context
}

export const useSidebarFunctions = (): Functions => {
    const context = useSidebarContext()

    return context.functions
}

export const useSidebar = (): [Readonly<State>, Functions] => {
    const context = useSidebarContext()

    const snap = useSnapshot(context.state)

    return [snap as Readonly<State>, context.functions]
}

const SidebarContext = createContext<{
    state: State
    functions: Functions
}>(null)
