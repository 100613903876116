import { formatMoney } from 'components'

import { type PtFeeModel } from '../types'

const source: keyof PtFeeModel = 'minValue'

const label = 'Min Amount'

const value = (record: Pick<PtFeeModel, 'minValue'>) => {
    return <>{formatMoney(record.minValue)}</>
}
export const minAmount = {
    source,
    label,
    value,
}
