import { ListTotalBadge, Header, MainHeader, CreateButtonView } from 'components'
import { CustomersDrawerToggler } from 'resources/customers'

const ListHeader = () => {
    return (
        <MainHeader
            mainAction={
                <CustomersDrawerToggler>
                    {(open) => <CreateButtonView onClick={open}>Create Customer</CreateButtonView>}
                </CustomersDrawerToggler>
            }
        >
            <Header.Info>
                <Header.Content>
                    <Header.Title>Customers</Header.Title>
                </Header.Content>
                <Header.Content mobilePlacement="bottom">
                    <ListTotalBadge />
                </Header.Content>
            </Header.Info>
        </MainHeader>
    )
}

export default ListHeader
