import { useMemo, useRef, type FC } from 'react'

import { ListBase } from 'components'
import { urls } from 'configs'
import { useArchivedContext, ArchivedContext } from 'context'
import { removeKey, type SortPayload } from 'core'
import { useLocation, useNavigate } from 'lib'
import { type UnitModel, UnitsListBase, unitAspects, unitResource } from 'resources/units'
import { PageContent } from 'ui'
import { displayBooleanValue } from 'utils'

import { UnitsListHeader } from './components'

const defaultUnitSort: SortPayload<UnitModel> = {
    field: 'created',
    order: 'DESC',
}

const defaultArchivedUnitSort: SortPayload<UnitModel> = {
    field: 'archived',
    order: 'DESC',
}

const UnitsListPage: FC = () => {
    const { isArchived } = useArchivedContext()

    // Remove the stored list config when navigating (switch or sidebar) between archived and non-archived units
    // TODO: Find better way to handle this
    const prevIsArchived = useRef(isArchived)
    if (prevIsArchived.current !== isArchived) {
        removeKey(unitResource.resource)
        prevIsArchived.current = isArchived
    }

    return (
        <ListBase
            sort={isArchived ? defaultArchivedUnitSort : defaultUnitSort}
            key={String(isArchived)}
            filter={{
                withAspects: [unitAspects.costPerMeter, unitAspects.totals],
                archived: displayBooleanValue(isArchived),
            }}
            preferencesName={getPreferencesName(isArchived)}
        >
            <UnitsListHeader isArchived={isArchived} />
            <PageContent>
                <UnitsListBase />
            </PageContent>
        </ListBase>
    )
}

const getPreferencesName = (isArchived: boolean): string => `units${isArchived ? '-archived' : ''}`

const content = <UnitsListPage />

const UnitsList: FC = () => {
    const { pathname } = useLocation()
    const navigate = useNavigate()

    return (
        <ArchivedContext.Provider
            value={useMemo(
                () => ({
                    isArchived: pathname === urls.unitsArchived,
                    setIsArchived: (toggle: boolean) => {
                        navigate(toggle ? urls.unitsArchived : urls.units)
                    },
                }),
                [pathname, navigate],
            )}
        >
            {content}
        </ArchivedContext.Provider>
    )
}

export default UnitsList
