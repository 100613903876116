import { type FC } from 'react'

import { useFormContext } from 'react-hook-form'

import { SelectInput } from 'components'
import { perPageLimit, ReferenceInput } from 'core'
import { stateFields } from 'resources/state'

import countryFields from '../fields'

interface Props {
    disabled?: boolean
    source?: string
    stateSource?: string
}

export const CountryInput: FC<Props> = ({
    disabled,
    source = countryFields.self.source,
    stateSource = stateFields.self.source,
}) => {
    const { setValue } = useFormContext()

    return (
        <ReferenceInput
            reference="countries"
            source={source}
            perPage={perPageLimit}
            disabled={disabled}
        >
            <SelectInput
                label={countryFields.self.label}
                source={source}
                disableEmptyValue
                disabled={disabled}
                onChange={() => {
                    setValue(stateSource, null)
                }}
            />
        </ReferenceInput>
    )
}
