import { type FC } from 'react'

import { useShowContext } from 'react-admin'

import { SliderView } from 'components'
import { type InvoiceModel } from 'resources/invoices'
import { SmallSection } from 'ui'

import WoItemsCard from './WoItemsCard'
const WoItemsSection: FC = () => {
    const { record, isLoading } = useShowContext<InvoiceModel>()
    if (isLoading || !record.jobs.length) {
        return null
    }
    const items = record.jobs.map((job) => (
        <WoItemsCard
            job={job}
            key={job.id}
        />
    ))
    return (
        <SmallSection sx={{ zIndex: 2 }}>
            <SliderView
                height="203px"
                items={items}
            />
        </SmallSection>
    )
}

export default WoItemsSection
