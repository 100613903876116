import { createContext, type FC, useContext, useState, type ReactNode } from 'react'

import { useResourcePreferences } from 'core/context'
import { useMediaQuery } from 'lib'
export enum ListViewMode {
    list,
    grid,
}

interface ListViewContextValue {
    showColumns: boolean
    toggleView: (view: ListViewMode) => void
    setShowColumns: (toggler: boolean | ((open: boolean) => boolean)) => void
    viewMode: ListViewMode
}

const ListViewContext = createContext<ListViewContextValue>({} as ListViewContextValue)

export const useListView = (): ListViewContextValue => useContext(ListViewContext)

interface ListViewProviderProps {
    children: ReactNode
}

export const ListViewProvider: FC<ListViewProviderProps> = ({ children }) => {
    const preferences = useResourcePreferences()
    const isColumns = preferences.value.showColumns
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'))
    const [showColumns, setShowColumns] = useState(Boolean(isColumns ?? true))
    const viewMode = showColumns && !isSmall ? ListViewMode.list : ListViewMode.grid

    const toggleView = (view: ListViewMode) => {
        const newView = ListViewMode.list === view

        if (newView !== showColumns) {
            preferences.update({
                showColumns: newView,
            })
            setShowColumns(newView)
        }
    }

    return (
        <ListViewContext.Provider
            value={{
                showColumns,
                toggleView,
                setShowColumns,
                viewMode,
            }}
        >
            {children}
        </ListViewContext.Provider>
    )
}

export default ListViewProvider
