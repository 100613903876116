import { type FC } from 'react'

import Icons from 'assets/icons'
import { DataAvatar } from 'ui'

import { type VendorPartModel } from '../types'

interface Props {
    record: Pick<VendorPartModel, 'image' | 'vendorData'>
}

const MainIcon = Icons.BuildCircleOutlined

const Avatar: FC<Props> = ({ record }) => (
    <DataAvatar
        imageSrc={record.image}
        defaultImage={record.vendorData ? <Icons.VendorParts /> : <MainIcon />}
    />
)

const avatar = {
    Vendor: Icons.VendorParts,
    Icon: MainIcon,
    ActiveIcon: Icons.BuildCircle,
    Value: Avatar,
}

export default avatar
