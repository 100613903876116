import { type FC, type ReactNode } from 'react'

import { type Identifier } from 'appTypes'
import { settingsUrls } from 'configs'
import { LinkButton } from 'ui'
import { pathJoin } from 'utils'

interface Props {
    id: Identifier
    children: ReactNode
}

export const ShopLink: FC<Props> = ({ id, children }) => {
    return <LinkButton to={pathJoin(settingsUrls.repairShops, id)}>{children}</LinkButton>
}
