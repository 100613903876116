import { type FC } from 'react'

import { Box } from 'ui'

import { type TagModel } from '../types'

import { TagField } from './TagField'

interface Props {
    tags: TagModel[] | undefined
}

const TagsInSelector: FC<Props> = ({ tags }) => {
    if (!tags?.length) {
        return null
    }

    return (
        <Box
            display="flex"
            flexWrap="wrap"
            gap="10px"
        >
            {tags.map((tag) => (
                <TagField
                    key={tag.id}
                    tag={tag}
                    size="extraSmall"
                />
            ))}
        </Box>
    )
}

export default TagsInSelector
