import { InfoCardDetails, InfoCardHeader, InfoCard } from 'components'
import { displayPaymentMethod } from 'resources/paymentMethods'
import { displayPaymentTerm } from 'resources/paymentTerms'
import { type VendorModel } from 'resources/vendors'

const FinancialInformation = () => {
    return (
        <InfoCard>
            <InfoCardHeader title="Financial Information" />
            <InfoCardDetails<VendorModel>
                details={[
                    {
                        label: 'Tax ID',
                        source: 'taxId',
                    },
                    {
                        label: 'Default Payment Method',
                        source: ({ paymentMethod }) => displayPaymentMethod(paymentMethod),
                    },
                    {
                        label: 'Default Payment Term',
                        source: ({ paymentTermData }) => displayPaymentTerm(paymentTermData),
                    },
                    {
                        label: 'National Account',
                        source: 'nationalAccount',
                    },
                ]}
            />
        </InfoCard>
    )
}

export default FinancialInformation
