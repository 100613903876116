import { type Identifier } from 'appTypes'
import { type UseFileDownloadParams, useFileDownload } from 'core/files'
import { renderOnPermission } from 'core/permissions'

import { type ActionChildren } from '../Actions'

import { downloadActionBaseOptions } from './downloadActionCommon'

export interface DownloadOneActionProps extends Omit<UseFileDownloadParams, 'id'> {
    children: ActionChildren
    id: Identifier
}

export const DownloadOneAction = renderOnPermission(
    ({ children, ...rest }: DownloadOneActionProps) => {
        const downloadOne = useFileDownload()

        return children({
            ...downloadActionBaseOptions[rest.type],
            onClick: () => {
                downloadOne(rest)
            },
        })
    },
    ({ props, permissions }) => permissions?.[props.type],
)

export const downloadOneAction = (params: DownloadOneActionProps & { key?: string | number }) => (
    <DownloadOneAction
        key={'download-one-' + (params.filename || '') + '-' + params.type}
        {...params}
    />
)
