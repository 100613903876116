import { type IssueModel } from '../types'

const source = 'description' as const satisfies keyof IssueModel

interface Data extends Pick<IssueModel, 'description'> {}

const value = (data: Data) => {
    if (!data) {
        return ''
    }
    return data.description
}

const description = {
    source,
    label: 'Description',
    value,
}

export default description
