import { type FC, type ReactNode } from 'react'

import { GridContainerColumns, GridItemLayout, PageContent } from 'ui'

interface Props {
    children: ReactNode
}

const OverviewContainer: FC<Props> = ({ children }) => {
    return (
        <PageContent>
            <GridContainerColumns>
                <GridItemLayout>{children}</GridItemLayout>
            </GridContainerColumns>
        </PageContent>
    )
}

export default OverviewContainer
