import { InfoCard, InfoCardDetails, InfoCardHeader } from 'components'
import { type UnitModel } from 'resources/units'
import { ck34Fields } from 'resources/vmrs'

const Transmission = () => {
    return (
        <InfoCard>
            <InfoCardHeader title="Transmission" />
            <InfoCardDetails<UnitModel>
                details={[
                    {
                        label: 'Manufacturer/Make',
                        source: ({ transmissionVmrsManufacturerData }) =>
                            ck34Fields.self.value(transmissionVmrsManufacturerData),
                    },
                    {
                        label: 'Model',
                        source: 'transmissionModel',
                    },
                    {
                        label: 'Gears',
                        source: 'transmissionGears',
                    },
                ]}
            />
        </InfoCard>
    )
}

export default Transmission
