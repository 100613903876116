import { ResourceWithClearEffect } from 'core'
import { partsResource } from 'resources/parts'

import PartsList from './List'
import PartShow from './Show'

const Parts = () => {
    return (
        <ResourceWithClearEffect
            name={partsResource}
            list={PartsList}
            edit={<PartShow />}
        />
    )
}

export default Parts
