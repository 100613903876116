import { type FC, type ReactNode } from 'react'

import { Typography } from 'ui'

import TitleTooltip from './TitleTooltip'
import WidgetsContainer from './WidgetsContainer'

const WidgetsNoData: FC<{ title: ReactNode; text: string }> = ({ title, text }) => {
    return (
        <WidgetsContainer>
            <Typography
                variant="chartTitle"
                color="text.secondary"
            >
                {title}
            </Typography>
            <Typography
                variant="h5"
                color="text.primary"
            >
                No Data <TitleTooltip tooltipText={text} />
            </Typography>
            <Typography variant="inputLabel">for the selected period</Typography>
            <Typography variant="inputLabel">{text}</Typography>
        </WidgetsContainer>
    )
}

export default WidgetsNoData
