import { type ShopModel } from 'resourcesBase'

const source = 'useLaborHourlyRate' as const satisfies keyof ShopModel

const shopUseLaborHourlyRate = {
    source,
    label: 'Use Shop Rate for this repair shop',
}

export default shopUseLaborHourlyRate
