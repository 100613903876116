import { type FC } from 'react'

import images from 'assets/images'
import { NoResultsCard } from 'components'
import { useInventoryContext } from 'resources/inventory'
import { Typography, NonDisplayedInput } from 'ui'

const NoPhotosCard: FC<{ upload: (event: any) => Promise<void> }> = ({ upload }) => {
    const inventoryView = useInventoryContext()

    return (
        <NoResultsCard
            title="No Photos Added"
            direction="row"
            height="180px"
            imageSrc={images.noPhotos}
            imageProps={{
                alt: 'No Notes',
                width: '106px',
                mt: '20px',
                mr: '28px',
            }}
            action={
                inventoryView ? null : (
                    <label>
                        <NonDisplayedInput
                            type="file"
                            onChange={upload}
                            accept="image/*"
                        />
                        <Typography
                            variant="body1"
                            color="primary"
                            sx={{ cursor: 'pointer' }}
                        >
                            Add Photo
                        </Typography>
                    </label>
                )
            }
        />
    )
}

export default NoPhotosCard
