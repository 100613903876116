import { type FC, type ReactElement } from 'react'

import { Portal } from 'ui'

interface FixedPortalProps {
    order?: number
    children: ReactElement
}

const FixedTopPortal: FC<FixedPortalProps> = ({ children, order }) => {
    return (
        <Portal
            containerId="additional-fixed-parts"
            mutateElement={(el) => {
                el.style.order = (order || 1) as unknown as string
            }}
        >
            {children}
        </Portal>
    )
}

export default FixedTopPortal
