import { type DataRecord } from 'appTypes'
import { CardCollapsibleScrollbar, CollapsibleContent } from 'components/Collapsible'
import Gallery, {
    GalleryItemGrid,
    GalleryItem,
    getRecordPhotos,
    type Photos,
    photosToArray,
} from 'components/Gallery'
import { Divider, Stack, Typography, InfoBadge, BoxContainer } from 'ui'

export interface PhotosCollapsibleContentProps {
    record: DataRecord & Photos
}
const PhotosCollapsibleContent = ({ record }: PhotosCollapsibleContentProps) => {
    const photos = getRecordPhotos(record)
    const files = photosToArray(photos.files)
    return (
        <CollapsibleContent name="photos">
            <Stack mt="15px">
                <Typography
                    gap="8px"
                    component={BoxContainer}
                    variant="chartTitle"
                    mb="10px"
                >
                    Photos
                    <InfoBadge badgeContent={String(photos.count)} />
                </Typography>
                <Divider />
                <Gallery>
                    <CardCollapsibleScrollbar>
                        <GalleryItemGrid
                            gridHeight="73px"
                            height="73px"
                            gap="16px"
                        >
                            {files.map((file) => {
                                return (
                                    <GalleryItem
                                        id={String(file.id)}
                                        file={file.file}
                                        key={file.id}
                                        sx={{
                                            height: 'inherit',
                                            width: '100%',
                                        }}
                                    />
                                )
                            })}
                        </GalleryItemGrid>
                    </CardCollapsibleScrollbar>
                </Gallery>
            </Stack>
        </CollapsibleContent>
    )
}
export default PhotosCollapsibleContent
