import { type FC } from 'react'

import { useFormState, useFormContext } from 'react-hook-form'

import Icons from 'assets/icons'
import { useToggleFullPageLoader } from 'components/FullPageLoader'
import { LoadingButton, type LoadingButtonProps, type ButtonProps } from 'ui'

interface Props extends LoadingButtonProps {
    fullPageLoader?: boolean
}

export const ConfirmButton: FC<Props> = ({ fullPageLoader, ...props }) => {
    const { isSubmitting, isValid } = useFormState()
    const { clearErrors } = useFormContext()

    const onClick: ButtonProps['onClick'] = (event) => {
        props.onClick?.(event)
        if (props.type === 'submit') {
            clearErrors()
        }
    }

    useToggleFullPageLoader({ isOpen: Boolean(fullPageLoader && isSubmitting) })

    return (
        <LoadingButton
            disabled={!isValid}
            loadingPosition={isSubmitting ? 'start' : undefined}
            loading={isSubmitting}
            // mui requires startIcon when loadingPosition = "start" is used. Hack
            startIcon={isSubmitting ? <Icons.Close sx={{ opacity: '0 !important' }} /> : undefined}
            size="large"
            children="CONFIRM"
            onClick={onClick}
            {...props}
        />
    )
}
