import { type NoInfer } from 'appTypes'
import { type Column, type SortBy } from 'components'

import { type PartModel } from '../types'

import { partLongSource } from './utils'

const source = 'manufacturerPartNumber' satisfies keyof PartModel
const longSource = partLongSource(source)

const label = 'Manufacturer Part Number'

const sort = <Source extends string = typeof source>({ id }: { id?: Source } = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label,
    }) as const satisfies SortBy

const tableColumn = <Source extends string = typeof source>({
    id,
    label: labelProp,
    dataToValue,
}: {
    id?: Source
    label?: string
    dataToValue: (data) => PartModel['manufacturerPartNumber']
}) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: labelProp || label,
        renderCell: ({ row }) => dataToValue(row),
    }) as const satisfies Column

export const manufacturerPartNumber = {
    source,
    longSource,
    label,
    sort,
    tableColumn,
}
