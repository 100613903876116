import { forwardRef, useEffect } from 'react'

import { type ListControllerResult, ListFilterContext, useChoicesContext } from 'react-admin'
import { useFormContext } from 'react-hook-form'

import Icons from 'assets/icons'
import { filterSearchText } from 'core/data'
import { type TextFieldProps, InputAdornment } from 'ui'

import FilterLiveSearch from '../FilterListSearch'

const DialogSelectorSearch = (props: TextFieldProps) => {
    const { setFilters, filterValues, displayedFilters, hideFilter, resource, showFilter } =
        useChoicesContext()

    const setFiltersModify: ListControllerResult['setFilters'] = (filters) => {
        setFilters(filters, displayedFilters)
    }

    return (
        <ListFilterContext.Provider
            value={{
                filterValues,
                setFilters: setFiltersModify,
                displayedFilters,
                hideFilter,
                resource,
                showFilter,
            }}
        >
            <FilterLiveSearch
                {...props}
                margin="none"
                InputProps={{
                    // copied from RA FilterLiveSearch
                    endAdornment: (
                        <InputAdornment position="end">
                            <Icons.SearchOutlined color="disabled" />
                        </InputAdornment>
                    ),
                    inputComponent: Input,
                }}
            />
        </ListFilterContext.Provider>
    )
}

export default DialogSelectorSearch

// only FilterLiveSearch can change its value, this is why we need to track its filter value
const Input = forwardRef<any, any>((props, ref) => {
    const { filterValues } = useChoicesContext()
    const { setValue } = useFormContext()

    const value = filterValues[filterSearchText]

    useEffect(() => {
        setValue(filterSearchText, value)
    }, [value])

    return (
        <input
            {...props}
            ref={ref}
        />
    )
})
