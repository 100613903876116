import { useConfirm, type ServerError } from 'core'
import { formatInvoiceAmount } from 'resources/purchaseInvoices'
import { formatPOTotal } from 'resources/purchaseOrders'
import { Alert, Box, Typography } from 'ui'

interface Params {
    objectTotal: number
    invoiceTotal: number
    objectName: string
}

const useIsMismatch = () => {
    const confirm = useConfirm()

    return {
        hasError: (error: ServerError) => error.code === 'invoice_amount_mismatch',
        action: ({ objectTotal, invoiceTotal, objectName }: Params) => {
            confirm({
                title: `${objectName} can't be closed.`,
                content: (
                    <Box>
                        <Alert
                            severity="warning"
                            sx={{ mb: '22px' }}
                        >
                            The Invoice Amount and the {objectName} Total should match.
                        </Alert>
                        <Typography
                            variant="body2"
                            mb="24px"
                            color="text.primary"
                        >
                            {objectName} Total {formatPOTotal(objectTotal, false)}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="text.primary"
                        >
                            Invoice Amount {formatInvoiceAmount(invoiceTotal)}
                        </Typography>
                    </Box>
                ),
                confirmButtonProps: null,
                cancelButtonProps: {
                    children: 'Close',
                },
            })
        },
    }
}

export default useIsMismatch
