import { ViewHeader } from 'components'
import { PurchaseInvoiceCard } from 'resources/purchaseInvoices'
import { type ReceiptModel } from 'resources/purchaseOrders'
import { Stack, FlexReverse, GridContainerColumns, GridItemLayout, PageContent } from 'ui'

import { PurchaseOrderCardSkeleton } from '../../components'

import ClosedPOReceiptHeader from './ClosedPOReceiptHeader'
import FinancialSummaryCard from './FinancialSummaryCard'

export const ReceiptSectionSkeleton = () => {
    return (
        <PageContent>
            <FlexReverse
                gap="28px"
                pb="30px"
            >
                <ViewHeader
                    title="Receipt Skeleton"
                    loading
                />
                <GridContainerColumns>
                    <GridItemLayout>
                        <PurchaseOrderCardSkeleton height="240px" />
                    </GridItemLayout>
                </GridContainerColumns>
                <GridContainerColumns>
                    <GridItemLayout>
                        <PurchaseOrderCardSkeleton height="161px" />
                    </GridItemLayout>
                </GridContainerColumns>
            </FlexReverse>
        </PageContent>
    )
}
const ReceiptSection = ({ receiptData }: { receiptData: ReceiptModel }) => {
    return (
        <PageContent flexGrow="0 !important">
            <ClosedPOReceiptHeader receiptData={receiptData} />
            <Stack gap={{ xs: '12px', sm: '28px' }}>
                <FinancialSummaryCard receiptData={receiptData} />
                <GridContainerColumns>
                    <GridItemLayout sx={{ height: '204px', zIndex: '2' }}>
                        <PurchaseInvoiceCard
                            disablePreview
                            invoice={receiptData.invoice}
                            removeActions
                        />
                    </GridItemLayout>
                </GridContainerColumns>
            </Stack>
        </PageContent>
    )
}

export default ReceiptSection
