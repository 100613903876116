import { formatPercent } from 'components'

import { type PtTaxModel } from '../types'

const source = 'markupPercent' as const satisfies keyof PtTaxModel

const label = 'Percentage on Total'

const value = (record: Pick<PtTaxModel, 'markupPercent'>) => {
    return formatPercent(record.markupPercent, undefined, true)
}

export const percentageOnTotal = {
    source,
    label,
    value,
}
