import { type ReactElement } from 'react'

import { MembersField } from 'resources/members'
import { type ShopModel } from 'resourcesBase'

const source = 'membersCount' as const satisfies keyof ShopModel
const label = 'Users'

interface Data extends Pick<ShopModel, 'members'> {}

const value = (params: Data): ReactElement => {
    if (!params) {
        return null
    }
    return <MembersField members={params.members} />
}

const members = {
    source,
    label,
    value,
}

export default members
