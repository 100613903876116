import { useShowContext } from 'react-admin'

import { ViewHeader } from 'components'
import { PurchaseOrderCardSkeleton } from 'pages/PurchaseOrders/components'
import { type PurchaseOrderModel } from 'resources/purchaseOrders'
import { GridContainerColumns, GridItemLayout, Section } from 'ui'

import TotalPurchaseCard from './TotalPurchaseCard'
const TotalPurchaseSection = () => {
    const { record } = useShowContext<PurchaseOrderModel>()
    if (!record?.dateLastReceived) {
        return null
    }
    return (
        <Section>
            <ViewHeader
                title="Total Purchase"
                loading={!record}
            />
            <GridContainerColumns>
                <GridItemLayout>
                    {record ? (
                        <TotalPurchaseCard record={record} />
                    ) : (
                        <PurchaseOrderCardSkeleton height="221px" />
                    )}
                </GridItemLayout>
            </GridContainerColumns>
        </Section>
    )
}

export default TotalPurchaseSection
