import { type ReactElement, useState } from 'react'

import { type ImageInputProps, useInput } from 'react-admin'

import Icons from 'assets/icons'
import ImageCropper from 'components/ImageCropper'
import { useCreateInputId } from 'core/form'
import { useNotify } from 'core/notifications'
import { alpha, styled } from 'lib'
import { NonDisplayedInput, DataAvatar, Box, Button, IconButton } from 'ui'
import { WindowURL } from 'utils'

interface UploadImageProps extends ImageInputProps {
    source: string
    defaultIcon: ReactElement
    id?: string
    buttonUploadText?: string
    buttonChangeText?: string
}

const UploadImageWrapper = styled(Box)`
    width: 100%;
    display: flex;
    background-color: ${({ theme }) => alpha(theme.palette.grey[900], 0.04)};
    height: 100px;
    border-radius: 4px;
    margin-bottom: 39px;
    align-items: center;
`
const UploadImage = ({
    defaultIcon,
    source,
    id: idProp,
    buttonUploadText = 'Upload image',
    buttonChangeText = 'Change image',
}: UploadImageProps) => {
    const accept = 'image/*'
    const [droppedFile, setDroppedFile] = useState({
        name: '',
        type: '',
        blob: '',
    })
    const [cropping, setCropping] = useState<boolean>(false)
    const notify = useNotify()
    const transformFile = (file) => {
        if (!(file instanceof File)) {
            return file
        }

        const preview = WindowURL.createObjectURL(file)
        const transformedFile = {
            rawFile: file,
            [source]: preview,
        }

        return transformedFile
    }

    const transformFiles = (files: File | File[]) => {
        if (!files) {
            return null
        }

        if (Array.isArray(files)) {
            return files.map(transformFile)
        }

        return transformFile(files)
    }

    const createId = useCreateInputId()

    const {
        id,
        field: { onChange, value },
    } = useInput({
        format: transformFiles,
        parse: transformFiles,
        source,
        id: createId({ source, id: idProp }),
    })

    const onDropAccepted = (event) => {
        const file: File = event.target.files?.[0]

        if (!file) {
            return null
        }

        const type = file.type
        if (!type.startsWith('image') || type.includes('svg')) {
            notify({
                title: 'Invalid file format',
                type: 'error',
            })
            event.target.value = null

            return
        }
        const newImageData = {
            name: file.name,
            type: file.type,
            blob: WindowURL.createObjectURL(file),
        }

        event.target.value = null
        // allows to set the same file multiple times in a row
        setDroppedFile(newImageData)
        setCropping(true)
    }

    const imageRemove = async (event) => {
        event.stopPropagation()
        onChange(null)
    }

    return (
        <UploadImageWrapper id={id}>
            <DataAvatar
                sx={{
                    width: '56px',
                    height: '56px',
                    margin: '22px 0 22px 20px',
                }}
                defaultImage={defaultIcon}
                imageSrc={value && typeof value === 'object' ? value[source] : value}
            />
            <label>
                <NonDisplayedInput
                    accept={accept}
                    type="file"
                    onChange={onDropAccepted}
                />
                <Button
                    sx={{ ml: '12px' }}
                    variant="contained"
                    component="span"
                    size="small"
                >
                    {value ? buttonChangeText : buttonUploadText}
                </Button>
            </label>
            <IconButton
                aria-label="Remove Image"
                sx={{ marginLeft: '12px' }}
                color="secondary"
                disabled={!value}
                onClick={imageRemove}
            >
                <Icons.Delete />
            </IconButton>
            {cropping ? (
                <ImageCropper
                    image={droppedFile}
                    onClose={() => setCropping(false)}
                    onCropped={onChange}
                />
            ) : null}
        </UploadImageWrapper>
    )
}

export default UploadImage
