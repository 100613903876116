import { styled } from 'lib'
import { inputBaseClasses } from 'ui'

import TextInput, { type TextInputProps } from './TextInput'

const TextareaInput = styled((props: TextInputProps) => (
    <TextInput
        rows={3}
        multiline
        {...props}
    />
))`
    .${inputBaseClasses.root} {
        height: 112px;
    }
` as typeof TextInput

export default TextareaInput
