import { type FC } from 'react'

import { formatPercent, formatMoney } from 'components'
import { styled, alpha, useTheme, type Theme } from 'lib'
import { WidgetTooltip } from 'pages/Dashboard/components'
import { AcrossAllItemsTooltip } from 'pages/WorkOrders/components'
import { LineItemTypeKeys } from 'resources/lineItems'
import { useIsVenderWo } from 'resources/workOrders'
import { BoxContainer, Spacer, StyledElement, Typography, Stack } from 'ui'

import {
    type clarificationMapType,
    type JobItemsCalculations,
    unitClarificationMap,
} from '../../components/types'

const StyledSpan = styled('span', {
    shouldForwardProp: (prop) => prop !== 'tint',
})<{ tint?: string }>(({ tint }) => ({
    color: tint,
    padding: '2px 4px 1px 4px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 2,
    background: alpha(tint, 0.1),
}))
interface ClarificationProps extends JobItemsCalculations {
    validData: boolean
    showTooltip?: boolean
}

interface ClarificationItemProps extends ClarificationProps {
    map: clarificationMapType
    theme: Theme
}
const ClarificationItems: FC<ClarificationItemProps> = ({ map, ...rest }) => {
    const { validData, theme, ...ratioData } = rest
    if (!validData) {
        return null
    }
    return (
        <>
            {map.map((item, index) => {
                if (ratioData[item.total] == null) {
                    return null
                }
                const validTotalPercent =
                    ratioData.total > 0 &&
                    ratioData[item.total] > 0 &&
                    ratioData[item.totalPercent] <= 100 &&
                    ratioData[item.totalPercent] >= 0

                return (
                    <WidgetTooltip
                        key={item.id}
                        title={[item.label, formatMoney(ratioData[item.total])]}
                        arrow
                    >
                        <BoxContainer gap="6px">
                            <Typography
                                tint={theme.palette.charts[item.tint]}
                                variant="chartLabel"
                                component={StyledSpan}
                            >
                                {validTotalPercent
                                    ? formatPercent(ratioData[item.totalPercent])
                                    : '%'}
                            </Typography>
                            <Typography
                                component="span"
                                variant="chartListItem"
                            >
                                {item.label}
                            </Typography>
                        </BoxContainer>
                    </WidgetTooltip>
                )
            })}
        </>
    )
}
const Clarification = ({ validData, showTooltip, ...ratioData }: ClarificationProps) => {
    const theme = useTheme()
    const clarificationMapExtras: Omit<ClarificationItemProps, 'map'> = {
        theme,
        validData,
        ...ratioData,
    }
    const isVendorWo = useIsVenderWo()

    return (
        <Stack
            alignItems="flex-start"
            marginTop="7px"
            spacing="7px"
        >
            {showTooltip ? (
                <StyledElement sx={{ mt: '10px' }}>
                    <AcrossAllItemsTooltip tooltipData={ratioData} />
                </StyledElement>
            ) : (
                <>
                    <Spacer variant="md">
                        <ClarificationItems
                            {...clarificationMapExtras}
                            map={
                                isVendorWo === false
                                    ? unitClarificationMap
                                          .filter(
                                              ({ id }) =>
                                                  id !== LineItemTypeKeys.TAX &&
                                                  id !== LineItemTypeKeys.FEE,
                                          )
                                          .slice(0, 3)
                                    : unitClarificationMap.slice(0, 3)
                            }
                        />
                    </Spacer>
                    {isVendorWo !== false && (
                        <Spacer variant="md">
                            <ClarificationItems
                                {...clarificationMapExtras}
                                map={unitClarificationMap.slice(3)}
                            />
                        </Spacer>
                    )}
                </>
            )}
        </Stack>
    )
}
export default Clarification
