import { useMemo, type FC, type ReactElement } from 'react'

import { inject, observer } from 'mobx-react'

import { tabRoutes } from 'components'
import { Show, ShowProvider, useShowController } from 'components/resource'
import { urls } from 'configs'
import { type AuthStore, ResourceContextProviderWithClearEffect } from 'core'
import { useFlags, Routes } from 'lib'
import { InventoryHistory } from 'pages/Inventory/InventoryHistory'
import InventoryPricing from 'pages/Inventory/InventoryPricing/InventoryPricing'
import {
    type PartInInventoryModel,
    type InventoryItem,
    useInventoryContext,
} from 'resources/inventory'
import { partsResource } from 'resources/parts'

import CrossReference from '../CrossReference'
import { PartNotes } from '../Notes'
import { PartOverview } from '../Overview'
import { PartPhotos } from '../Photos'
import { PurchaseHistory } from '../PurchaseHistory'

import { PartShowHeader } from './components'

const PartShow: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const inventoryView = useInventoryContext()
        const inventoryEnabled = auth.companySettings.hasInventory

        const { flag3976PartPurchaseHistory } = useFlags()
        const { tabs, routes } = tabRoutes([
            {
                value: '',
                label: 'Overview',
                element: <PartOverview />,
            },
            inventoryView &&
                auth.companySettings.hasInvoicing && {
                    value: 'pricing',
                    label: 'Pricing',
                    element: <InventoryPricing />,
                },
            inventoryView && {
                value: 'inventory-history',
                label: 'Inventory History',
                element: <InventoryHistory />,
            },
            flag3976PartPurchaseHistory &&
                !inventoryEnabled && {
                    value: 'purchase-history',
                    label: 'Purchase History',
                    element: <PurchaseHistory />,
                },
            {
                value: 'cross-reference',
                label: 'Cross-Reference',
                element: <CrossReference />,
            },
            {
                value: 'notes',
                label: 'Notes',
                element: <PartNotes />,
            },
            {
                value: 'photos',
                label: 'Photos',
                element: <PartPhotos />,
            },
        ])

        return (
            <PartShowBase>
                <>
                    <PartShowHeader tabs={tabs} />
                    <Routes>{routes}</Routes>
                </>
            </PartShowBase>
        )
    }),
)

export default PartShow

const PartShowBase: FC<{ children: ReactElement }> = ({ children }) => {
    const inventoryView = useInventoryContext()

    if (inventoryView) {
        return <InventoryShow>{children}</InventoryShow>
    }

    return <Show>{children}</Show>
}

const InventoryShow: FC<{ children: ReactElement }> = ({ children }) => {
    const showController = useShowController<InventoryItem>({
        navigateOnError: { resource: urls.inventory },
    })
    const record = showController.record
    const newRecord = useMemo(() => {
        if (!record) {
            return record
        }
        const { partData: part, ...inventoryItem } = record
        ;(part as PartInInventoryModel).inventoryItem = inventoryItem
        return part as PartInInventoryModel
    }, [record])

    return (
        <ResourceContextProviderWithClearEffect value={partsResource}>
            <ShowProvider value={{ ...showController, record: newRecord }}>{children}</ShowProvider>
        </ResourceContextProviderWithClearEffect>
    )
}
