import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import images from 'assets/images'
import {
    type CardListConfig,
    type DatagridColumnsProps,
    type FilterConfig,
    LinkArrowButton,
    List,
    ListBase,
    ListFilterDateRangeValueInput,
    ListFilterValueInput,
    type ListSortContentProps,
    PhotoButton,
    ResourceLinkButton,
} from 'components'
import { urls } from 'configs'
import { ResourceContextProviderWithClearEffect, type SortPayload } from 'core'
import { type AuthStore } from 'core/auth'
import { formatDate } from 'lib'
import { deffectResource, type DefectModel } from 'resources/defects'
import {
    DVIRNoConnection,
    DVIRStatus,
    formatDvirInspectionType,
    getDvirPage,
    type DvirStatusType,
} from 'resources/dvir'
import { tagFields } from 'resources/tags'
import {
    Capability,
    type Telematics,
    type TelematicsType,
    useTelematics,
} from 'resources/telematics'
import { unitFields } from 'resources/units'
import { ck33Fields } from 'resources/vmrs'
import { woFields } from 'resources/workOrders'
import { PageContent } from 'ui'
import { displayBooleanValue } from 'utils'

import { ReportsHeader } from '../components'

const defaultSort: SortPayload<DefectModel & AdditionalClientTypes> = {
    field: 'inProgress',
    order: 'ASC',
}

interface AdditionalClientTypes {
    domicile: string
    workOrder: string
    dvirStatus: string
    dvirTimestamp: string
    dvirType: string
    inProgress: string
    workOrdersAttached: string
    dvirDate: string
    inspectionType: string
}
export const unitSortCfg: ListSortContentProps<DefectModel & AdditionalClientTypes> = {
    sortBy: [
        { id: 'name', label: 'Name' },
        { id: 'unit', label: 'Unit Number' },
        { id: 'detectedOn', label: 'Detected on' },
        { id: 'component', label: 'Component' },
        {
            id: 'workOrder',
            label: 'WOs Attached',
        },
        { id: 'photo', label: 'Photos' },
        { id: 'dvirStatus', label: 'DVIR Status' },
        {
            id: 'dvirTimestamp',
            label: 'DVIR Date',
        },
        { id: 'dvirType', label: 'Inspection Type' },
        { id: 'inProgress', label: 'In Progress' },
    ],
}

const isConnected = (telematics: TelematicsType) => {
    for (const key in telematics) {
        if (telematics[key].isActive) {
            return true
        }
    }
    return false
}

const recordToLink = (record: DefectModel) => `${urls.units}/${record.unit}/dvir`

const Dvirs: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const { telematics, data, list } = useTelematics()
        if (list.isLoading) {
            return null
        }
        if (!isConnected(telematics)) {
            return <DVIRNoConnection />
        }

        if (!isSupported(data)) {
            const integration = telematics[data[0].provider]
            return getDvirPage(integration.capabilities.dvirs, integration.providerName)
        }

        const cardsCfg: CardListConfig<DefectModel & AdditionalClientTypes> = {
            titleSource: (record) => record.name || '-',
            titleLink: (record) => recordToLink(record),
            defaultImage: null,
            details: [
                {
                    source: 'unit',
                    label: 'Unit Number',
                    render: (value, record) => (
                        <ResourceLinkButton to={`${urls.units}/${value}`}>
                            {record.unitData.number}
                        </ResourceLinkButton>
                    ),
                },
                {
                    source: 'detectedOn',
                    label: 'Detected on',
                    render: (value) =>
                        formatDate(value, (dateFormats) => dateFormats.shortenedDateTime),
                },
                {
                    source: 'component',
                    label: 'Component',
                    render: (value, { componentData }) => ck33Fields.self.value(componentData),
                },
                {
                    source: 'workOrdersAttached',
                    label: 'WOs Attached',
                    render: (_, record) => woFields.self.linkValue(record.workOrderData),
                },
                {
                    source: 'driverComment',
                    label: 'Comments',
                },
                {
                    source: 'photo',
                    label: 'Photos',
                    render: (value) => (value ? <PhotoButton files={[value]} /> : null),
                },
                {
                    source: 'dvirStatus',
                    label: 'DVIR Status',
                    render: (value, { dvirsData }) => (
                        <DVIRStatus status={dvirsData[dvirsData.length - 1]?.status} />
                    ),
                },
                {
                    source: 'dvirDate',
                    label: 'DVIR Date',
                    render: (value, { dvirsData }) =>
                        formatDate(
                            new Date(dvirsData[dvirsData.length - 1]?.timestamp),
                            (dateFormats) => dateFormats.shortenedDateTime,
                        ),
                },
                {
                    source: 'inspectionType',
                    label: 'Inspection Type',
                    render: (value, { dvirsData }) =>
                        formatDvirInspectionType(dvirsData[dvirsData.length - 1].type),
                },
                {
                    source: 'inProgress',
                    label: 'In Progress',
                    render: (v, record) => displayBooleanValue(Boolean(record.workOrderData)),
                },
            ],
            action: (record) => <LinkArrowButton path={recordToLink(record)} />,
        }

        const columnsCfg: DatagridColumnsProps<DefectModel & AdditionalClientTypes> = {
            checkboxSelection: false,
            mainField: 'name',
            resetColumns: { domicile: false },
            columns: [
                {
                    field: 'name',
                    headerName: 'Name',
                    renderCell: ({ row, value }) => (
                        <ResourceLinkButton to={`${urls.units}/${row.unit}/dvir`}>
                            {value}
                        </ResourceLinkButton>
                    ),
                },
                {
                    field: 'unit',
                    headerName: 'Unit Number',
                    renderCell: ({ row }) => (
                        <ResourceLinkButton to={`${urls.units}/${row.unit}`}>
                            {row.unitData.number}
                        </ResourceLinkButton>
                    ),
                },
                unitFields.domicile.tableColumn({
                    label: 'Unit Domicile',
                    auth,
                    dataToValue: (row: DefectModel) => row.unitData?.domicileData?.name,
                }),
                {
                    field: 'detectedOn',
                    headerName: 'Detected on',
                    valueGetter: ({ value }) =>
                        formatDate(value, (dateFormats) => dateFormats.shortenedDateTime),
                },
                {
                    field: 'component',
                    headerName: 'Component',
                    valueGetter: ({ row }) => ck33Fields.self.value(row.componentData),
                },
                {
                    field: 'workOrdersAttached',
                    headerName: 'WOs Attached',
                    renderCell: ({ row }) => woFields.self.linkValue(row.workOrderData),
                },
                {
                    field: 'driverComment',
                    headerName: 'Comments',
                },
                {
                    field: 'photo',
                    headerName: 'Photos',
                    renderCell: ({ value }) => (value ? <PhotoButton files={[value]} /> : null),
                },
                {
                    field: 'dvirStatus',
                    headerName: 'DVIR Status',
                    renderCell: ({ row }) => (
                        <DVIRStatus status={row.dvirsData[row.dvirsData.length - 1]?.status} />
                    ),
                },
                {
                    field: 'dvirDate',
                    headerName: 'DVIR Date',
                    valueGetter: ({ row }) =>
                        formatDate(
                            row.dvirsData[row.dvirsData.length - 1]?.timestamp,
                            (dateFormats) => dateFormats.shortenedDateTime,
                        ),
                },
                {
                    field: 'inspectionType',
                    headerName: 'Inspection Type',
                    valueGetter: ({ row }) =>
                        formatDvirInspectionType(row.dvirsData[row.dvirsData.length - 1].type),
                },
                {
                    field: 'inProgress',
                    headerName: 'In Progress',
                    valueGetter: ({ row }) => displayBooleanValue(Boolean(row.workOrderData)),
                },
            ],
            actions: null,
        }

        const filtersCfg: FilterConfig<DefectModel & AdditionalClientTypes> = {
            filters: [
                { id: 'name', label: 'Name' },
                { id: 'unit', label: 'Unit Number' },
                tagFields.self.filter({ id: 'unitTags' as keyof DefectModel }),
                unitFields.domicile.filter({
                    label: 'Unit Domicile',
                    auth,
                }),
                {
                    id: 'detectedOn',
                    label: 'Detected on',
                    filterType: 'range',
                    renderComponent: (props) => <ListFilterDateRangeValueInput {...props} />,
                },
                ck33Fields.self.filter<DefectModel>({
                    id: 'component',
                }),
                {
                    id: 'workOrder',
                    label: 'WOs Attached',
                },

                { id: 'photo', label: 'Photos' },
                {
                    id: 'dvirStatus',
                    label: 'DVIR Status',
                    renderComponent: (props) => (
                        <ListFilterValueInput
                            {...props}
                            inputText={(option) => option.id}
                            makeItemLabel={(record) => (
                                <DVIRStatus status={record.id as DvirStatusType} />
                            )}
                        />
                    ),
                },
                {
                    id: 'dvirTimestamp',
                    label: 'DVIR Date',
                    filterType: 'range',
                    renderComponent: (props) => <ListFilterDateRangeValueInput {...props} />,
                },
                {
                    id: 'dvirType',
                    label: 'Inspection Type',
                    renderComponent: (props) => (
                        <ListFilterValueInput
                            {...props}
                            inputText={(option) => option.id}
                            makeItemLabel={(record) =>
                                formatDvirInspectionType(record.id as string)
                            }
                        />
                    ),
                },
                {
                    id: 'inProgress',
                    label: 'In Progress',
                },
            ],
        }

        return (
            <ResourceContextProviderWithClearEffect value={deffectResource}>
                <ListBase sort={defaultSort}>
                    <ReportsHeader>Unresolved Defects</ReportsHeader>
                    <PageContent>
                        <List
                            listFTUProps={{
                                imageSrc: images.image3,
                                title: 'Everything Looks Good!',
                                secondaryTitle: (
                                    <>
                                        No defects reported at this time.
                                        <br />
                                        We'll keep you informed if that changes.
                                    </>
                                ),
                                linkText: null,
                            }}
                            exportFileName="defects-report"
                            filtersCfg={filtersCfg}
                            sortCfg={unitSortCfg}
                            columnsCfg={columnsCfg}
                            cardsCfg={cardsCfg}
                        />
                    </PageContent>
                </ListBase>
            </ResourceContextProviderWithClearEffect>
        )
    }),
)

export default Dvirs

const isSupported = (telematics: Telematics[]) =>
    telematics.some((integration) => integration.capabilities.dvirs === Capability.SUPPORTED)
