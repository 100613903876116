import { type FC, type ReactNode } from 'react'

import { Collapse } from 'ui'

import { useCollapsibleController } from './CollapsibleContext'

interface CollapsibleContentProps {
    children: ReactNode
    name?: string
}

const CollapsibleContent: FC<CollapsibleContentProps> = ({ children, name }) => {
    const { isExpanded } = useCollapsibleController(name)

    return (
        <Collapse
            in={isExpanded}
            timeout="auto"
            unmountOnExit
        >
            {children}
        </Collapse>
    )
}

export default CollapsibleContent
