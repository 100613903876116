import { type FC } from 'react'

import Icons from 'assets/icons'
import { Link, type To } from 'lib'
import { IconBox } from 'ui'

const ArchivedAction: FC<{ to: To }> = ({ to }) => {
    return (
        <Link to={to}>
            <IconBox title="See All">
                <Icons.GridViewOutlined />
            </IconBox>
        </Link>
    )
}

export default ArchivedAction
