import { type MouseEventHandler } from 'react'

import Icons from 'assets/icons'
import { IconBox } from 'ui'

const DeleteCardButton = ({ onClick }: { onClick: MouseEventHandler<HTMLDivElement> }) => {
    return (
        <IconBox
            onClick={onClick}
            title="Remove Cross-reference"
        >
            <Icons.DeleteCrossReference />
        </IconBox>
    )
}
export default DeleteCardButton
