import { createContext, type Provider, useContext } from 'react'

const PageContext = createContext<any>(null)

const usePageContext = <ReturnType,>() => useContext<ReturnType>(PageContext)

export const getPageContext = <ReturnType,>() => {
    return {
        useContext: usePageContext<ReturnType>,
        Provider: PageContext.Provider as Provider<ReturnType>,
    }
}
