import {
    type FC,
    type Key,
    type ReactNode,
    useEffect,
    useRef,
    type ComponentProps,
    type ReactElement,
} from 'react'

import SlickSlider, { type Settings } from 'react-slick'

import Icons from 'assets/icons'
import { useOptimizedRef } from 'hooks'
import { withErrorBoundary, type Breakpoint, cssVariables, styled } from 'lib'
import { Box, StyledElement } from 'ui'

import Arrow from './Arrow'
import useSliderFreeze from './useSliderFreeze'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const TheFix = styled('button')`
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    z-index: -1;
    opacity: 0;
    border: 0;
`
export interface SliderProps extends Settings {
    className?: string
    items?: ReactNode[]
    ItemWrapper?: FC<{ key: Key }>
    height?: string
    gap?: { [key in Breakpoint]?: string }
}
interface SliderItemProps extends ComponentProps<typeof StyledElement> {
    parentRef: ReturnType<typeof useRef<SlickSlider>>
    children: ReactElement
}
const Item = ({ parentRef, children, ...props }: SliderItemProps) => {
    const ref = useRef<HTMLDivElement>()
    let allowFocus = false

    useEffect(() => {
        if (ref.current) {
            ref.current.addEventListener(
                'focus',
                (event) => {
                    if (!allowFocus) {
                        allowFocus = true
                        return
                    }
                    const responsiveCfg = parentRef.current.props.responsive
                    const slideState = parentRef.current.state as { breakpoint: number }
                    const slidesToShow = slideState.breakpoint
                        ? (
                              responsiveCfg.find((v) => v.breakpoint === slideState.breakpoint)
                                  .settings as Settings
                          ).slidesToShow
                        : parentRef.current.props.slidesToShow
                    const target = event.target as HTMLDivElement

                    if (target.tagName !== 'A' && target.tagName !== 'BUTTON') {
                        return
                    }
                    const allSlidesCount = (parentRef.current.props.children as ReactNode[]).length
                    const currKey = Number(children.key)
                    const iterations = allSlidesCount - slidesToShow
                    if (allSlidesCount - currKey >= slidesToShow) {
                        parentRef.current.slickGoTo(currKey, true)
                    } else {
                        parentRef.current.slickGoTo(iterations, true)
                    }
                },
                true,
            )
            ref.current.addEventListener('mousedown', (e) => {
                allowFocus = false
            })
            ref.current.addEventListener('mouseup', (e) => {
                allowFocus = true
            })
            // Mobile related
            ref.current.addEventListener('touchstart', (e) => {
                allowFocus = false
            })
            ref.current.addEventListener('touchend', (e) => {
                allowFocus = true
            })
        }
    }, [])
    return (
        <StyledElement
            {...props}
            ref={ref}
        >
            {children}
        </StyledElement>
    )
}
const Slider = styled(
    ({ items, className, ItemWrapper, gap, responsive, ...props }: SliderProps) => {
        const ref = useRef<SlickSlider>()
        const wrapperRef = useRef<HTMLDivElement>()
        const freeze = useSliderFreeze(wrapperRef)
        const freezedResponsive = useOptimizedRef(() =>
            responsive.map((obj) => {
                return {
                    ...obj,
                    settings: {
                        ...(obj.settings as Object),
                        swipe: false,
                    },
                }
            }),
        )

        return (
            <div ref={wrapperRef}>
                <SlickSlider
                    ref={ref}
                    nextArrow={
                        <Arrow aria-label="Next Slide">
                            <Icons.KeyboardArrowRight />
                        </Arrow>
                    }
                    prevArrow={
                        <Arrow aria-label="Previous Slide">
                            <Icons.KeyboardArrowLeft />
                        </Arrow>
                    }
                    infinite={false}
                    className={className}
                    swipe={false}
                    responsive={freeze ? freezedResponsive.current : responsive}
                    {...props}
                >
                    {items.map((item, i) => {
                        return (
                            <Item
                                parentRef={ref}
                                sx={{ height: '100%' }}
                                key={i}
                            >
                                <Box
                                    key={i}
                                    m="0 auto"
                                    pl={gap}
                                    sx={{
                                        height: '100%',
                                        position: 'relative',
                                        '& > *': {
                                            minHeight: '100%',
                                        },
                                    }}
                                >
                                    <TheFix
                                        tabIndex={0}
                                        type="button"
                                        aria-label={`Slide ${i}`}
                                    />
                                    {item}
                                </Box>
                            </Item>
                        )
                    })}
                </SlickSlider>
            </div>
        )
    },
)<SliderProps>`
    margin: -10px calc(var(${cssVariables.pageContentPadding}) * -1);
    padding: 10px var(${cssVariables.pageContentPadding});
    user-select: unset;

    .slick-list {
        display: flex;
        overflow: visible;

        ${({ height }) => (height ? `height: ${height};` : '')}

        ${({ gap, theme }) => {
            return gap
                ? Object.keys(gap).map(
                      (breakpointGap) => `
                    ${theme.breakpoints.up(breakpointGap as Breakpoint)} {
                        margin-left: -${gap[breakpointGap]};
                    }
                `,
                  )
                : ''
        }}
    }

    .slick-track {
        display: flex;
        margin-left: 0px;
    }
    .slick-slide {
        & > * {
            height: 100%;
        }
    }
`

Slider.defaultProps = {
    gap: { xs: '20px', sm: '28px' },
}

export default withErrorBoundary(Slider, {})
