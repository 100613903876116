import { type FC } from 'react'

import { useRecordContext } from 'react-admin'

import { CustomerSection } from 'resources/customers'
import { type InvoiceModel } from 'resources/invoices'

import { OverviewContainer } from './components'
import { TotalSection, CustomerPriceTiersSection, DetailsSection } from './sections'

const InvoiceOverview: FC = () => {
    const record = useRecordContext<InvoiceModel>()

    return (
        <OverviewContainer>
            <TotalSection />
            <DetailsSection />
            <CustomerSection customer={record?.customerData} />
            <CustomerPriceTiersSection />
        </OverviewContainer>
    )
}

export default InvoiceOverview
