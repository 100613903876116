import { useContext, type FC, useState } from 'react'

import { useOptimizedRef } from 'hooks'

import {
    ArrayControllerContext,
    ArrayControllerContextProviderBase,
    type ArrayControllerContextProviderPropsBase,
    type ArrayControllerResult,
} from './ArrayControllerBase'

export const useArrayControllerContext = <
    ValueType = number,
>(): ArrayControllerResult<ValueType> => useContext(ArrayControllerContext)

type ArrayControllerContextProviderProps = ArrayControllerContextProviderPropsBase<number>

export const ArrayControllerContextProvider: FC<ArrayControllerContextProviderProps> = ({
    children,
    initialArray,
    limit,
    onAppend,
    onRemove,
    extra,
}) => {
    const [array, setArray] = useState<number[]>(initialArray || [0])
    const freeKeys = useOptimizedRef<number[]>(() => {
        let maxValue = 0
        const keys = array.reduce(
            (obj, key) => {
                obj[key] = true
                if (maxValue < key) {
                    maxValue = key
                }
                return obj
            },
            {} as { [key: string]: true },
        )
        const freeKeysFromArray: number[] = []
        for (let index = 0; index < maxValue; index += 1) {
            if (!keys[index]) {
                freeKeysFromArray.push(index)
            }
        }
        return freeKeysFromArray
    })

    const append = (appendKey?: number) => {
        const key: number =
            appendKey ?? freeKeys.current.length
                ? freeKeys.current.shift()!
                : array.length
                  ? Math.max(...array) + 1
                  : 0
        setArray((oldArray) => [...oldArray, key])
        onAppend?.(key)
        return key
    }

    const remove = ({ item }: { item: number }) => {
        setArray((oldArray) => oldArray.filter((currentKey) => currentKey !== item))
        freeKeys.current.push(item)
        onRemove?.(item)
    }

    return (
        <ArrayControllerContextProviderBase<number>
            value={{
                array,
                append,
                remove,
                setArray,
                limit: limit === null ? Infinity : 10,
                extra,
            }}
        >
            {children}
        </ArrayControllerContextProviderBase>
    )
}
