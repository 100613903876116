import { type FC } from 'react'

import { useShowContext } from 'react-admin'

import Icons from 'assets/icons'
import { type PurchaseOrderModel } from 'resources/purchaseOrders'
import { PrimaryButton } from 'ui'

import { useReceiveItems } from '../../actions'

const ReceiveItemsButton: FC = () => {
    const { record } = useShowContext<PurchaseOrderModel>()
    const receiveItems = useReceiveItems()
    const draftMode = record?.status === 'DRAFT'

    return (
        <PrimaryButton
            startIcon={<Icons.AddLineItem />}
            onClick={receiveItems}
            disabled={
                !record?.items || record?.status === 'CANCELED' || record?.status === 'CLOSED'
            }
        >
            {draftMode ? 'Continue receiving' : 'Receive Items'}
        </PrimaryButton>
    )
}

export default ReceiveItemsButton
