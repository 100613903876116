import { type FC, type ReactElement, useLayoutEffect, useState } from 'react'

import { Fade } from '@mui/material'

interface Props {
    value: any
    children: ReactElement
}

const FadeByValue: FC<Props> = ({ value, children }) => {
    const [fade, setFade] = useState(false)

    useLayoutEffect(() => {
        setFade(false)
        const timer = setTimeout(() => setFade(true), 100)
        return () => clearTimeout(timer)
    }, [value])

    return (
        <Fade
            in={fade}
            // children changes before the exist animation to finish
            // remove the exit animation
            timeout={{ exit: 0, enter: 300 }}
        >
            {children}
        </Fade>
    )
}

export default FadeByValue
