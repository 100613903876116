import {
    type CardListConfig,
    type DatagridColumnsProps,
    type FilterConfig,
    type ListSortContentProps,
    formatMoney,
} from 'components'
import { ResourceContextProviderWithClearEffect, type SortPayload } from 'core'
import { getListData } from 'core/data'
import { LineItemTypeKeys } from 'resources/lineItems'
import { type UnitModel } from 'resources/units'
import {
    type CK2Model,
    type CK2Aggregates,
    ck2Aspects,
    type CK2Aspects,
    ck2Resource,
} from 'resources/vmrs'
import { woFields } from 'resources/workOrders'
import { PageContent } from 'ui'

import { ListWithPeriodSelector, ReportsHeader, ListBaseWithPeriod } from '../components'

const aspects = [ck2Aspects.totals] satisfies CK2Aspects[]

interface CK2ReportModel extends CK2Model<typeof aspects> {}

const defaultSort: SortPayload<UnitModel> = {
    field: 'total',
    order: 'DESC',
}
const sortCfg: ListSortContentProps<CK2ReportModel> = {
    sortBy: [
        { id: 'description', label: 'Equipment Category' },
        { id: 'code', label: 'Code' },
        woFields.total.sort(LineItemTypeKeys.PART),
        woFields.total.sort(LineItemTypeKeys.LABOR),
        woFields.total.sort(LineItemTypeKeys.SERVICE),
        woFields.total.sort(LineItemTypeKeys.FEE),
        woFields.total.sort(LineItemTypeKeys.TAX),
        woFields.total.sort(),
    ],
}
const cardsCfg: CardListConfig<CK2ReportModel> = {
    titleSource: 'description',
    defaultImage: null,
    disableTitleLink: true,
    details: [
        { source: 'code', label: 'Code' },
        woFields.total.dataCardRow(LineItemTypeKeys.PART),
        woFields.total.dataCardRow(LineItemTypeKeys.LABOR),
        woFields.total.dataCardRow(LineItemTypeKeys.SERVICE),
        woFields.total.dataCardRow(LineItemTypeKeys.FEE),
        woFields.total.dataCardRow(LineItemTypeKeys.TAX),
        woFields.total.dataCardRow(),
    ],
}

const columnsCfg: DatagridColumnsProps<CK2ReportModel> = {
    checkboxSelection: false,
    constantColumns: {
        code: true,
        [woFields.total.getConfig().source]: true,
    },
    mainField: 'description',
    pinnedColumns: {
        left: ['code'],
        right: [woFields.total.getConfig().source],
    },
    columns: [
        {
            field: 'description',
            headerName: 'Equipment Category',
        },
        { field: 'code', headerName: 'Code' },
        woFields.total.tableColumn(LineItemTypeKeys.PART),
        woFields.total.tableColumn(LineItemTypeKeys.LABOR),
        woFields.total.tableColumn(LineItemTypeKeys.SERVICE),
        woFields.total.tableColumn(LineItemTypeKeys.FEE),
        woFields.total.tableColumn(LineItemTypeKeys.TAX),
        woFields.total.tableColumn(),
    ],
    actions: null,
}
const filtersCfg: FilterConfig<CK2ReportModel> = {
    filters: [
        { id: 'description', label: 'Equipment Category' },
        { id: 'code', label: 'Code' },
        woFields.total.filter(LineItemTypeKeys.PART),
        woFields.total.filter(LineItemTypeKeys.LABOR),
        woFields.total.filter(LineItemTypeKeys.SERVICE),
        woFields.total.filter(LineItemTypeKeys.FEE),
        woFields.total.filter(LineItemTypeKeys.TAX),
        woFields.total.filter(),
    ],
}
const CostPerEquipmentCategoryCK2 = () => {
    return (
        <ResourceContextProviderWithClearEffect value={ck2Resource}>
            <ListBaseWithPeriod
                sort={defaultSort}
                filter={{ withAspects: aspects }}
            >
                <ReportsHeader<CK2ReportModel>
                    renderTotal={(list) => {
                        const listData = getListData<CK2Aggregates>(list)
                        return (
                            <>
                                {list.total} • {formatMoney(listData.total || 0)} total |
                                Uncategorized • {formatMoney(listData.uncategorized || 0)}
                            </>
                        )
                    }}
                >
                    Cost per Equipment Category
                </ReportsHeader>
                <PageContent>
                    <ListWithPeriodSelector
                        exportFileName="cost-per-equipment-category"
                        filtersCfg={filtersCfg}
                        sortCfg={sortCfg}
                        columnsCfg={columnsCfg}
                        cardsCfg={cardsCfg}
                    />
                </PageContent>
            </ListBaseWithPeriod>
        </ResourceContextProviderWithClearEffect>
    )
}

export default CostPerEquipmentCategoryCK2
