import { SliderView, ViewHeader } from 'components'
import { Section } from 'ui'

import { UnitSliderSkeleton } from '../../components'

const UnitMetersFallback = () => (
    <Section>
        <ViewHeader
            title={null}
            loading
        />
        <SliderView items={metersSkeletonSlides} />
    </Section>
)

export default UnitMetersFallback

const metersSkeletonSlides = new Array(4).fill(<UnitSliderSkeleton height="121px" />)
