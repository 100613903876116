import { type Identifier } from 'appTypes'
import { ConfirmContentWithFetchOne, type DeleteOneParams, type ResourceType } from 'core'

import { type JobModel } from '../types'

export const deleteJobActionParams = (id: Identifier, resource: ResourceType): DeleteOneParams => {
    return {
        confirmConfig: {
            title: 'Are you sure you want to delete this Job?',
            content: (
                <ConfirmContentWithFetchOne<JobModel>
                    id={id}
                    resource={resource}
                    render={({ itemsCount }) => (
                        <>
                            This Job contains {itemsCount} line items.
                            <br />
                            Deleting the Job will remove its connections to any PM/DVIR/Issues and
                            will return them to their initial state.
                        </>
                    )}
                />
            ),
        },
    }
}
