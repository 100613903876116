import { generateUtilityClasses } from 'lib'

const headerClasses = generateUtilityClasses('AppPageHeader', [
    'root',
    'title',
    'main',
    'block',
    'mainContainer',
    'avatarContainer',
    'contentLeft',
    'contentRight',
    'contentBottom',
])

export default headerClasses

export const headerCssVariables = {
    offsetX: '--offset-x',
    height: '--height',
    primaryGap: '--primary-gap',
    avatarSize: '--avatar-size',
    iconSize: '--icon-size',
}
