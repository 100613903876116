import { type ReactElement } from 'react'

import { type ListControllerResult, useListContext } from 'react-admin'

import { type DataRecord } from 'appTypes'
import { InfoBadge } from 'ui'

export interface ListTotalBadgeProps<RecordType extends DataRecord = DataRecord> {
    renderContent?: (
        listContent: ListControllerResult<RecordType>,
    ) => ReactElement | string | number
}

const ListTotalBadge = <RecordType extends DataRecord = DataRecord>({
    renderContent,
}: ListTotalBadgeProps<RecordType>) => {
    const listContext = useListContext()
    const { total, isLoading } = listContext

    if (isLoading || typeof total !== 'number') {
        return null
    }

    return <InfoBadge badgeContent={renderContent ? renderContent(listContext) : String(total)} />
}

export default ListTotalBadge
