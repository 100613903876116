import { type FC } from 'react'

import {
    type CardListConfig,
    type DatagridColumnsProps,
    integerNonNegativeSpacedMaskResolver,
    integerSpacedMaskResolver,
    LinkArrowButton,
} from 'components'
import { DatagridLink } from 'components/Datagrid'
import { type ListSortContentProps } from 'components/list/ListSortDrawerContent'
import { type FilterConfig } from 'components/list/filter/ListFilterForm'
import ListFilterRangeInput from 'components/list/filter/ListFilterRangeInput'
import { urls } from 'configs'
import { ResourceContextProviderWithClearEffect, type SortPayload } from 'core'
import { getListData } from 'core/data'
import { globalClassNames } from 'lib'
import { tagFields } from 'resources/tags'
import {
    type UnitModel,
    unitResource,
    type UnitAggregates,
    ArchivedUnitBadge,
} from 'resources/units'
import unitFields from 'resources/units/fields'
import { ck2Fields, ck34Fields } from 'resources/vmrs'
import { PageContent, BoxContainer, StyledElement } from 'ui'

import { UnitCardTitleLink } from '../Downtime/components'
import { ListBaseWithPeriod, ListWithPeriodSelector, ReportsHeader } from '../components'

export interface MTBFModel extends UnitModel {
    distance: number
    engineHours: number
    costPerMeterTotal: number
    mtbf: number
    mtbfFailures: number
    mtbfEngineHours: number
}
const defaultSort: SortPayload<MTBFModel> = {
    field: 'mtbf',
    order: 'ASC',
}

const columnsCfg: DatagridColumnsProps<MTBFModel> = {
    resetColumns: {
        licensePlate: false,
        vin: false,
        vmrsEquipmentCategory: false,
        model: false,
        modelYear: false,
        color: false,
        status: false,
    },
    mainField: unitFields.number.source,
    pinnedColumns: {
        right: ['mtbf'],
    },
    constantColumns: {
        mtbf: true,
    },
    checkboxSelection: false,
    columns: [
        {
            field: unitFields.number.source,
            headerName: unitFields.number.label,
            renderCell: (params) => {
                const { value, ...restParams } = params
                return (
                    <DatagridLink
                        {...restParams}
                        resource={unitResource.resource}
                        value={
                            <BoxContainer>
                                <StyledElement className={globalClassNames.ellipsis}>
                                    {value}
                                </StyledElement>
                                {params.row.archived && <ArchivedUnitBadge />}
                            </BoxContainer>
                        }
                    />
                )
            },
        },
        unitFields.name.tableColumn({
            headerName: unitFields.name.longLabel,
            dataToValue: (record) => record.name,
        }),
        {
            field: 'mtbfEngineHours',
            headerName: 'Engine Hours',
            valueFormatter: ({ value }) => integerNonNegativeSpacedMaskResolver(value),
        },
        {
            field: 'mtbfFailures',
            headerName: 'Failures',
            valueFormatter: ({ value }) => integerSpacedMaskResolver(value),
        },
        {
            field: 'mtbf',
            headerName: 'MTBF (Engine Hours)',
            valueFormatter: ({ value }) => integerSpacedMaskResolver(value),
        },
        unitFields.licensePlate.tableColumn({
            dataToValue: (record) => record.licensePlate,
        }),
        unitFields.vin.tableColumn({
            dataToValue: (record) => record.vin,
        }),
        {
            field: 'vmrsEquipmentCategory',
            headerName: 'Equipment Category',
            renderCell: ({ row }) => row.vmrsEquipmentCategoryData?.description,
        },
        unitFields.model.tableColumn({
            dataToValue: (record) => record.model,
        }),
        unitFields.modelYear.tableColumn({
            dataToValue: (record) => record.modelYear,
        }),
        unitFields.color.tableColumn({
            dataToValue: (record) => record.color,
        }),
        unitFields.status.tableColumn({ label: unitFields.status.longLabel }),
    ],
    actions: null,
}
export const navigateToUnit = ({ id }: Pick<UnitModel, 'id'>) => `${urls.units}/${id}`

const cardsCfg: CardListConfig<MTBFModel> = {
    titleSource: (record) => <UnitCardTitleLink record={record} />,
    disableTitleLink: true,
    defaultImage: null,
    details: [
        unitFields.name.dataCardRow({
            headerName: unitFields.name.longLabel,
            dataToValue: (record) => record.name,
        }),
        {
            source: 'mtbfEngineHours',
            label: 'Engine Hours',
            render: integerSpacedMaskResolver,
        },
        {
            source: 'mtbfFailures',
            label: 'Failures',
            render: integerSpacedMaskResolver,
        },
        {
            source: 'mtbf',
            label: 'MTBF (Engine Hours)',
            render: integerSpacedMaskResolver,
        },
    ],
    action: (record) => <LinkArrowButton path={navigateToUnit(record)} />,
}
const sortCfg: ListSortContentProps<MTBFModel> = {
    sortBy: [
        {
            id: 'mtbfEngineHours',
            label: 'Engine Hours',
        },
        {
            id: 'mtbfFailures',
            label: 'Failures',
        },
        {
            id: 'mtbf',
            label: 'MTBF (Engine Hours)',
        },
        unitFields.number.sort(),
        unitFields.name.sort({ label: unitFields.name.longLabel }),
        unitFields.licensePlate.sort(),
        unitFields.vin.sort(),
        ck2Fields.self.sort({ id: 'vmrsEquipmentCategory' }),
        unitFields.model.sort(),
        unitFields.modelYear.sort(),
        unitFields.color.sort(),
        unitFields.status.sort({ label: unitFields.status.longLabel }),
    ],
}

const MTBF: FC = () => {
    const filtersCfg: FilterConfig<MTBFModel> = {
        filters: [
            {
                id: 'mtbfEngineHours',
                label: 'Engine Hours',
                filterType: 'range',
                renderComponent: (props) => (
                    <ListFilterRangeInput
                        integerValuesInput
                        {...props}
                    />
                ),
            },
            {
                id: 'mtbfFailures',
                label: 'Failures',
                filterType: 'range',
                renderComponent: (props) => (
                    <ListFilterRangeInput
                        integerValuesInput
                        {...props}
                    />
                ),
            },
            {
                id: 'mtbf',
                label: 'MTBF (Engine Hours)',
                filterType: 'range',
                renderComponent: (props) => (
                    <ListFilterRangeInput
                        integerValuesInput
                        {...props}
                    />
                ),
            },
            unitFields.number.filter(),
            unitFields.name.filter({ label: unitFields.name.longLabel }),
            unitFields.vin.filter(),
            unitFields.licensePlate.filter(),
            unitFields.status.filter({ label: unitFields.status.longLabel }),
            ck2Fields.self.filter({ id: 'vmrsEquipmentCategory', label: 'Equipment Category' }),
            ck34Fields.self.filter({ id: 'vmrsManufacturer', label: 'Manufacturer/Make' }),
            unitFields.model.filter(),
            unitFields.modelYear.filter(),
            ck34Fields.self.filter({ id: 'engineVmrsManufacturer', label: 'Engine Make' }),
            unitFields.engineModel.filter(),
            unitFields.engineHp.filter(),
            ck34Fields.self.filter({
                id: 'transmissionVmrsManufacturer',
                label: 'Transmission Make',
            }),
            unitFields.transmissionModel.filter(),
            unitFields.transmissionGears.filter(),
            unitFields.color.filter(),
            unitFields.tireSize.filter(),
            tagFields.self.filter(),
            { id: 'archived', label: 'Archived Unit' },
        ],
    }

    return (
        <ResourceContextProviderWithClearEffect value={unitResource}>
            <ListBaseWithPeriod
                preferencesName="mean-time-between-failures"
                sort={defaultSort}
                filter={{ withAspects: ['mtbf'] }}
            >
                <ReportsHeader<UnitModel>
                    renderTotal={(list) => {
                        const listData = getListData<UnitAggregates>(list)
                        return `${integerSpacedMaskResolver(
                            list.total,
                        )} | ${integerSpacedMaskResolver(listData.mtbf || 0)} h`
                    }}
                >
                    Mean Time Between Failures
                </ReportsHeader>
                <PageContent>
                    <ListWithPeriodSelector
                        exportFileName="mean-time-between-failures"
                        filtersCfg={filtersCfg}
                        sortCfg={sortCfg}
                        columnsCfg={columnsCfg}
                        cardsCfg={cardsCfg}
                    />
                </PageContent>
            </ListBaseWithPeriod>
        </ResourceContextProviderWithClearEffect>
    )
}

export default MTBF
