import { type FC } from 'react'

import { useRecordContext } from 'react-admin'

import { type IssueModel } from 'resources/issues'
import { woFields } from 'resources/workOrders'
import { PrimaryButton, Tooltip } from 'ui'

import AddToWO from './AddToWO'

const AddToWOButton: FC = () => {
    const record = useRecordContext<IssueModel>()
    if (!record) {
        return null
    }

    return (
        <AddToWO
            renderToggler={({ titleOnDisabled, ...params }) => {
                const btn = (
                    <PrimaryButton
                        startIcon={<woFields.avatar.Icon />}
                        {...params}
                    >
                        Add to Work Order
                    </PrimaryButton>
                )

                if (params.disabled) {
                    return (
                        <Tooltip title={titleOnDisabled}>
                            <span>{btn}</span>
                        </Tooltip>
                    )
                }

                return btn
            }}
        />
    )
}

export default AddToWOButton
