import { Gallery, GalleryItem, type Photos } from 'components'
import { alpha, styled } from 'lib'
import { useInventoryContext } from 'resources/inventory'

interface PartPhotoGalleryProps {
    photos: Photos
    deletePhoto: (id: string) => Promise<void>
}
const PictureGrid = styled('ul')`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
    list-style-type: none;
    width: 100%;
    padding: 10px;
`

const PartPhotoGallery = ({ photos, deletePhoto }: PartPhotoGalleryProps) => {
    const inInventory = useInventoryContext()
    return (
        <Gallery
            onImageDelete={
                inInventory
                    ? null
                    : (galleryCtx) => {
                          const item = galleryCtx.getItemData(galleryCtx.currIndex)
                          deletePhoto(item.element.id)
                      }
            }
        >
            <PictureGrid>
                {Object.keys(photos)
                    .filter((photoIndex) => photos[photoIndex])
                    .map((photoIndex) => {
                        const file = photos[photoIndex]
                        return (
                            <GalleryItem
                                id={photoIndex}
                                key={photoIndex}
                                file={file}
                                sx={({ palette }) => ({
                                    height: '200px',
                                    background: palette.white,
                                    border: `1px solid ${alpha(palette.text.primary, 0.23)}`,
                                })}
                            />
                        )
                    })}
            </PictureGrid>
        </Gallery>
    )
}

export default PartPhotoGallery
