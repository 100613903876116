import address1 from './address1'
import address2 from './address2'
import city from './city'
import self from './self'
import zipCode from './zipCode'

export const addressFields = {
    address1,
    address2,
    city,
    zipCode,
    self,
}
