import { type AuthProvider as RAAuthProvider } from 'react-admin'

import { type NoStringIndex } from 'appTypes'
import { type PermissionsModel, type UserPreferences } from 'resourcesBase'

import { type AuthStore, type AuthStoreLogoutParams } from './authStore'

export interface AuthProvider
    extends NoStringIndex<RAAuthProvider>,
        Pick<
            AuthStore,
            | 'resetPassword'
            | 'resetPasswordConfirm'
            | 'changePassword'
            | 'loginWithGoogle'
            | 'updateUser'
            | 'deactivateCompany'
            | 'setPaymentLimit'
            | 'fetchBilling'
            | 'updatePreferences'
        > {
    permissions: () => PermissionsModel
    preferences: () => UserPreferences
}

function authProvider(authStore: AuthStore): AuthProvider {
    return {
        login: ({
            email,
            password,
            rememberMe,
        }: {
            email: string
            password: string
            rememberMe?: boolean
        }) => {
            return authStore.login(email, password, rememberMe)
        },
        logout: async (params: AuthStoreLogoutParams) => {
            await authStore.logout(params)
            return false // error occurs if false is not returned: smth .split is not a fn
        },
        resetPassword: (email) => {
            return authStore.resetPassword(email)
        },
        resetPasswordConfirm(password, uid, token) {
            return authStore.resetPasswordConfirm(password, uid, token)
        },
        async changePassword(oldPassword, newPassword) {
            return authStore.changePassword(oldPassword, newPassword)
        },
        checkAuth: () => {
            if (authStore.user) {
                return Promise.resolve()
            }
            return Promise.reject()
        },
        checkError: (error) => {
            const status = error.status
            if (status === 401) {
                return Promise.reject()
            }
            return Promise.resolve()
        },
        getPermissions: async () => {
            return authStore.permissions
        },
        permissions: () => {
            return authStore.permissions
        },
        async loginWithGoogle(...args) {
            return authStore.loginWithGoogle(...args)
        },
        async updateUser(values) {
            return authStore.updateUser(values)
        },
        async deactivateCompany() {
            return authStore.deactivateCompany()
        },
        async setPaymentLimit(amount) {
            return authStore.setPaymentLimit(amount)
        },
        async fetchBilling() {
            return authStore.fetchBilling()
        },
        async updatePreferences(data) {
            return authStore.updatePreferences(data)
        },
        preferences: () => {
            return authStore.userPreferences
        },
    }
}

export default authProvider
