import { Anchor } from 'ui'

import { WithCollapsibleController } from './WithCollapsible'

const CollapsibleToggleButton = () => {
    return (
        <WithCollapsibleController>
            {({ toggleExpand, isExpanded }) => (
                <Anchor
                    variant="inherit"
                    onClick={toggleExpand}
                >
                    {isExpanded ? 'SEE LESS' : 'SEE MORE'}
                </Anchor>
            )}
        </WithCollapsibleController>
    )
}

export default CollapsibleToggleButton
