import { type ReactElement, type FC } from 'react'

import { useRecordContext } from 'react-admin'
import { useWatch } from 'react-hook-form'

import {
    useUtilityDrawerContext,
    TextInput,
    useArrayControllerElementContext,
    inputIntegerNonNegativeSpacedMask,
} from 'components'
import { requiredValidation } from 'core'
import { UnderInputTooltip } from 'ui'
import { getProperty } from 'utils'

import { type PmModel } from '../../types'

import LastDoneHelperTextBase from './LastDoneHelperTextBase'
import TriggerThresholdValidation from './TriggerValidation'
import UnitPMMeterLine from './UnitPMMeterLine'
import UnitPMMeterValueInput from './UnitPMMeterValueInput'
import UnitPMFMeterCalcThreshold from './UnitPmMeterCalcThreshold'
import { type UnitPMDrawerEditorExtraState } from './types'
import useInputSource from './useInputSource'

interface UnitPMMeterBaseProps {
    endAdornment?: ReactElement | string
}
const UnitPMMeterBase: FC<UnitPMMeterBaseProps> = ({ endAdornment }) => {
    const { extra } = useUtilityDrawerContext()
    const { isArchived } = extra as UnitPMDrawerEditorExtraState
    const source = useInputSource()
    const { index } = useArrayControllerElementContext()
    const { intervals } = useRecordContext<PmModel>() || {}

    const lastDoneDisabled = intervals?.length > index

    return (
        <>
            <UnitPMMeterValueInput InputProps={{ endAdornment }} />

            <UnderInputTooltip
                title={
                    lastDoneDisabled && !isArchived
                        ? 'Use interval Reset action to edit this value'
                        : ''
                }
            >
                <div>
                    <TextInput
                        disabled={lastDoneDisabled || isArchived}
                        helperText={<LastDoneHelperText />}
                        source={source('lastDoneMeterValue')}
                        label="Reading at Last Done Date"
                        validate={requiredValidation}
                        {...inputIntegerNonNegativeSpacedMask({
                            InputProps: {
                                endAdornment,
                            },
                        })}
                    />
                </div>
            </UnderInputTooltip>

            <TextInput
                disabled={isArchived}
                source={source('threshold')}
                {...inputIntegerNonNegativeSpacedMask({
                    InputProps: {
                        endAdornment: <>{endAdornment} left</>,
                    },
                })}
                validate={(thresholdValue, data) => {
                    const value = Number(getProperty(data, source('value'))) || 0

                    if (value < thresholdValue) {
                        return 'The Threshold cannot be greater than the Meter interval'
                    }
                }}
                label="Change to “Due Soon” when"
            />
            <UnitPMMeterLine />
            <TriggerThresholdValidation />
            <UnitPMFMeterCalcThreshold />
        </>
    )
}

export default UnitPMMeterBase

const LastDoneHelperText = () => {
    const source = useInputSource()
    const [value, lastDone, type] = useWatch({
        name: [source('value'), source('lastDoneMeterValue'), source('type')],
    })

    return (
        <LastDoneHelperTextBase
            lastDone={lastDone}
            meterType={type}
            value={value}
        />
    )
}
