import { type ReactNode } from 'react'

import { type ListControllerProps } from 'react-admin'

import { type DataRecord } from 'appTypes'
import { ResourcePreferencesProvider } from 'core/context'
import { type SortPayload } from 'core/types'

import ListBaseViewMode from './ListBaseViewMode'
import ListSortDrawerProvider from './ListSortDrawerContext'
import ListViewProvider from './ListViewContext'
import ListFilterDrawerProvider from './filter/ListFilterDrawerContext'

export interface ListBaseProps<RecordType extends DataRecord = any> extends ListControllerProps {
    sort: SortPayload<RecordType>
    children: ReactNode
    isLoading?: boolean
    preferencesName?: string
}

const ListBase = <RecordType extends DataRecord = any>({
    children,
    sort,
    isLoading,
    resource,
    preferencesName,
    ...rest
}: ListBaseProps<RecordType>) => {
    return (
        <ResourcePreferencesProvider resource={preferencesName || resource}>
            <ListViewProvider>
                <ListBaseViewMode
                    {...rest}
                    resource={resource}
                    sort={sort}
                    isLoading={isLoading}
                >
                    <ListFilterDrawerProvider>
                        <ListSortDrawerProvider defaultSort={sort}>
                            {children}
                        </ListSortDrawerProvider>
                    </ListFilterDrawerProvider>
                </ListBaseViewMode>
            </ListViewProvider>
        </ResourcePreferencesProvider>
    )
}

export default ListBase
