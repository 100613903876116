import { type AnyMaskedOptions } from 'imask'

import { type IMaskInputProps } from './IMaskInput'

export type MaskType = AnyMaskedOptions &
    Pick<IMaskInputProps, 'changeValueOnBlur' | 'calculatedProps'>

export const textMask = {
    mask: String,
}

export const numberMask: MaskType = {
    mask: Number,
    signed: true,
    thousandsSeparator: ',',
    radix: '.',
    max: Number('9'.repeat(15)),
}
export const integerMask: MaskType = {
    ...numberMask,
    scale: 0,
}
export const integerPositiveMask: MaskType = {
    ...integerMask,
    signed: false,
    validate: (value: string) => {
        return value !== '0'
    },
}
export const integerNonNegativeMask: MaskType = {
    ...integerMask,
    signed: false,
}

export const integerNonNegativeSpacedMask: MaskType = {
    ...integerNonNegativeMask,
    thousandsSeparator: ' ',
}
export const integerSpacedMask: MaskType = {
    ...integerMask,
    thousandsSeparator: ' ',
}
export const doubleMask: MaskType = {
    ...numberMask,
    changeValueOnBlur: (value) => {
        if (value === '-0') {
            return ''
        }
        return undefined
    },
}

export const floatMask: MaskType = {
    ...doubleMask,
    scale: 3,
}
export const floatPositiveMask: MaskType = {
    ...floatMask,
    signed: false,
}
export const floatNonNegativeMask: MaskType = {
    ...floatMask,
    signed: false,
}

export const decimalMask: MaskType = {
    ...doubleMask,
    scale: 2,
}

export const priceMask: MaskType = { ...floatMask, normalizeZeros: false }

export const quantityMask: MaskType = floatMask
export const quantityPositiveMask: MaskType = {
    ...floatMask,
    validate: (value: string) => {
        return value !== '0'
    },
    signed: false,
}
export const priceNonNegativeMask: MaskType = {
    ...priceMask,
    signed: false,
}

export const paymentCheckMask: MaskType = {
    mask: '000000000000000000000000000000',
    eager: true,
    calculatedProps: ({ value }) => ({
        eager: Boolean(value),
    }),
}

// format masks

export const upperCaseMask: MaskType = {
    mask: /(.*?)/,
    prepare(str) {
        return str.toUpperCase()
    },
}
