import { type FC, type ReactElement } from 'react'

import { useFormContext } from 'react-hook-form'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import {
    ArrayControllerBox,
    ArrayControllerContextProvider,
    ArrayControllerElements,
    useOpenUtilityDrawer,
    UtilityDrawerAddNewButton,
    UtilityDrawerForm,
} from 'components'
import { useDataProvider, useDataProviderConfig, useSubmit } from 'core'
import { Alert, Typography } from 'ui'
import { sanitizeEmails } from 'utils'

import EmailField from './EmailField'

interface SendToContactDrawerProps extends SendToContactFormContentProps {
    children: (open: () => void) => ReactElement
    record: { id: Identifier; number: string }
    resource: string
    title: string
}

const SendToContactDrawer: FC<SendToContactDrawerProps> = ({
    children,
    record,
    resource,
    title,
    sendToFormConfig,
}) => {
    const open = useOpenUtilityDrawer()
    const dataProvider = useDataProvider()
    const submitHandler = useSubmit(
        async (formData) => {
            const data = sanitizeEmails(formData)

            await dataProvider.createMany(resource + `/${record.id}/send_pdf`, {
                data,
            })
        },
        {
            successMessage: ({ formData }) => {
                const data = sanitizeEmails(formData)

                return {
                    title: record.number + ' sent to',
                    message: Object.values(data).join(', '),
                }
            },
        },
    )

    return children(() => {
        open({
            drawerArgs: {
                title,
                renderWrapper: ({ children, ...params }) => (
                    <UtilityDrawerForm
                        {...params}
                        onSubmit={submitHandler}
                    >
                        <ArrayControllerContextProvider>{children}</ArrayControllerContextProvider>
                    </UtilityDrawerForm>
                ),
                renderTopRight: () => <UtilityDrawerAddNewButton children="Add recipient" />,
                renderContent: () => (
                    <SendToContactFormContent sendToFormConfig={sendToFormConfig} />
                ),
                renderBottomRight: (render) =>
                    render({ label: 'SEND', icon: <Icons.SendOutlined /> }),
            },
        })
    })
}

export default SendToContactDrawer

interface SendToContactFormContentProps {
    sendToFormConfig: {
        resource: string
        resourceId: Identifier
        alertText: string
    }
}
const SendToContactFormContent: FC<SendToContactFormContentProps> = ({ sendToFormConfig }) => {
    const { unregister } = useFormContext()
    useDataProviderConfig(`${sendToFormConfig.resource}/${sendToFormConfig.resourceId}/contacts`, {
        getMany: {
            shouldStopRequest: true,
        },
    })
    return (
        <>
            <Alert
                severity="info"
                sx={{ mb: '20px', alignItems: 'center' }}
            >
                <Typography
                    color="inherit"
                    variant="body2"
                >
                    {sendToFormConfig.alertText}
                </Typography>
            </Alert>
            <ArrayControllerElements
                onDelete={({ item }) => {
                    unregister(`email${item}`)
                }}
            >
                <ArrayControllerBox title="Recipient">
                    <EmailField
                        resource={sendToFormConfig.resource}
                        id={sendToFormConfig.resourceId}
                    />
                </ArrayControllerBox>
            </ArrayControllerElements>
        </>
    )
}
