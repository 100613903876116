import { type Palette } from 'lib'

import { type UncategorizedJobItems } from '../CostPerCategoryWidget/includeUncategorized'
import { type JobItemsCalculations, type JobItemTotals } from '../components/types'

export interface maxTotalEquipmentsType extends JobItemTotals {
    id: string
    code: string
    description: string
    total: number | null
    totalPercent: number | null
}
export interface ResponseType extends JobItemsCalculations, UncategorizedJobItems {
    maxTotalEquipmentCategories: maxTotalEquipmentsType[]
}
export interface QueryResponse extends Omit<ResponseType, 'maxTotalEquipmentCategories'> {
    list: listType
}
export interface listType {
    data: maxTotalEquipmentsType[]
    top3Percentage: number
    top3Total: number
    sumOfExcludedEquipment: number
    uncategorizedTotal: number
    chartTotal: number
}

type Top3EquipmentsColorProps = keyof Palette['charts']
export const statusColors: Top3EquipmentsColorProps[] = ['orange', 'yellowBody', 'blue']
