import Icons from 'assets/icons'
import { IconButton } from 'ui'

import { useSidebarFunctions } from '../Sidebar'

const MenuButton = () => {
    const { open } = useSidebarFunctions()

    return (
        <IconButton
            size="small"
            onClick={() => open()}
        >
            <Icons.MenuOutlined
                fontSize="inherit"
                sx={{ color: (theme) => theme.palette.text.primary, ml: 'auto' }}
            />
        </IconButton>
    )
}

export default MenuButton
