import { type FC } from 'react'

import Icons from 'assets/icons'
import {
    InfoCardRowBase,
    InfoCardRows,
    InfoCardTitle,
    InfoCardTitleContainer,
    InfoCardTitleIcon,
    MoneyField,
} from 'components'
import { type SxProps } from 'lib'
import { Divider } from 'ui'

interface Props {
    total: number
    sx?: SxProps
}

const ItemsTotal: FC<Props> = ({ sx, total }) => {
    return (
        <>
            <Divider />
            <InfoCardRows sx={sx}>
                <InfoCardRowBase
                    label={
                        <InfoCardTitleContainer>
                            <InfoCardTitleIcon Icon={Icons.MonetizationOnOutlined} />
                            <InfoCardTitle>Total</InfoCardTitle>
                        </InfoCardTitleContainer>
                    }
                >
                    <MoneyField value={total} />
                </InfoCardRowBase>
            </InfoCardRows>
        </>
    )
}

export default ItemsTotal
