import { type FC } from 'react'

import { PtTitle } from '../../../components'
import { PTFeeDrawerToggler } from '../../components'
import { type PtFeeModel } from '../../types'

interface Props {
    record: Pick<PtFeeModel, 'name' | 'id'>
}

const Value: FC<Props> = ({ record }) => {
    return (
        <PTFeeDrawerToggler id={record.id}>
            {(open) => <PtTitle onClick={open}>{record.name}</PtTitle>}
        </PTFeeDrawerToggler>
    )
}

export default Value
