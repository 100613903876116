import { type FC, type ReactElement } from 'react'

import { type To, Link } from 'lib'
import { Anchor } from 'ui'

interface NavigateProps {
    to: To
    children: ReactElement | string
}

const Navigate: FC<NavigateProps> = ({ to, children }) => {
    return (
        <Anchor
            fontSize="inherit"
            variant="inherit"
            component={Link}
            to={to}
        >
            Go to {children}
        </Anchor>
    )
}

export const NotificationMessage = {
    Navigate,
}
