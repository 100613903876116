import { type FC } from 'react'

import { ArchivedSwitcher, CreateButtonView, Header, ListTotalBadge, MainHeader } from 'components'
import { UnitDrawerToggler } from 'resources/units'

const UnitsListHeader: FC<{ isArchived: boolean }> = ({ isArchived }) => {
    return (
        <MainHeader
            mainAction={
                !isArchived && (
                    <UnitDrawerToggler>
                        {({ onClick }) => (
                            <CreateButtonView onClick={onClick}>Create Unit</CreateButtonView>
                        )}
                    </UnitDrawerToggler>
                )
            }
        >
            <Header.Info>
                <Header.Content>
                    <Header.Title>Units</Header.Title>
                </Header.Content>

                <Header.Content mobilePlacement="bottom">
                    <ListTotalBadge />
                </Header.Content>

                <Header.Content mobilePlacement="right">
                    <ArchivedSwitcher />
                </Header.Content>
            </Header.Info>
        </MainHeader>
    )
}

export default UnitsListHeader
