import { type FC } from 'react'

import { Alert, AlertTitle, Typography } from 'ui'

import { NoAccountText } from '../components'

import { type TokenType } from './types'

interface Props {
    tokenCode: TokenType
}

const ExpiredInvitation: FC<Props> = ({ tokenCode }) => {
    const config = tokenMap[tokenCode]
    return (
        <>
            <Alert
                variant="standard"
                severity="warning"
                sx={{ mb: '24px' }}
            >
                <AlertTitle>{config.title}</AlertTitle>
                <Typography
                    variant="body2"
                    color="inherit"
                >
                    {config.text}
                </Typography>
                <Typography
                    variant="body2"
                    color="inherit"
                >
                    Please, contact your administrator.
                </Typography>
            </Alert>
            <NoAccountText />
        </>
    )
}

export default ExpiredInvitation

type TokenMapType = Record<
    Exclude<TokenType, 'active' | 'invalid'>,
    { title: string; text: string }
>

const tokenMap: TokenMapType = {
    expired: {
        title: 'Link Expired',
        text: 'Your Invitation has expired.',
    },
    deactivated: {
        title: 'Access Denied',
        text: 'This Company has been deactivated.',
    },
}
