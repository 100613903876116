import { type TelematicsUnitModel } from 'resources/telematics'
import { ConnectedUnitStatus } from 'resources/units'
import { BoxContainer, StatusText } from 'ui'

const FleetpalStatus = ({
    status,
    hideTooltip,
}: {
    status: TelematicsUnitModel['connectionStatus']
    hideTooltip?: boolean
}) => {
    return (
        <BoxContainer
            gap="4px"
            component="span"
        >
            <ConnectedUnitStatus
                hideTooltip={hideTooltip}
                type={
                    status === 'CONNECTED' ? 'sync' : status === 'ERROR' ? 'error' : 'notImported'
                }
            >
                {status === 'CONNECTED'
                    ? 'Connected'
                    : status === 'ERROR'
                      ? 'Sync Error'
                      : 'Unsynced'}
            </ConnectedUnitStatus>
            {status === 'NEW' ? (
                <StatusText
                    color={(theme) => theme.palette.primary.main}
                    bgcolor={(theme) => theme.palette.primary.main}
                >
                    NEW
                </StatusText>
            ) : null}
        </BoxContainer>
    )
}
export default FleetpalStatus
