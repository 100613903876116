import Icons from 'assets/icons'
import { SvgIcon, Tooltip, StatusText } from 'ui'
import { capitalize } from 'utils'

export const UnitDeactivatedBadge = ({ provider }: { provider: string }) => {
    return (
        <Tooltip
            title={`Connection is established, but the unit is deactivated in ${capitalize(
                provider,
            )}.`}
        >
            <StatusText
                statusColor={(theme) => theme.palette.text.disabled}
                sx={{
                    boxSizing: 'border-box',
                    width: '16px',
                    height: '16px',
                    padding: '1.5px !important',
                }}
            >
                {' '}
                <SvgIcon
                    sx={{ width: '13px', height: '13px' }}
                    component={Icons.TruckRemoved}
                />
            </StatusText>
        </Tooltip>
    )
}
