import { styled } from 'lib'

const MultiFormatScannerMenu = styled('div')`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
`

export default MultiFormatScannerMenu
