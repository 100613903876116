import {
    type EditRedirectInListActionProps,
    editRedirectInListAction,
    type DownloadOneActionProps,
    downloadOneAction,
} from 'core/actions'
import { pdfOneWOArgs, type WorkOrderModel } from 'resources/workOrders'

interface PdfOneWOActionParams {
    record: WorkOrderModel
    children: DownloadOneActionProps['children']
}

export const pdfOneWOFromListAction = ({ record, children }: PdfOneWOActionParams) => {
    return downloadOneAction({
        ...pdfOneWOArgs(record),
        children,
    })
}

export const editRedirectWOFromListAction = (params: EditRedirectInListActionProps) => {
    return editRedirectInListAction(params)
}
