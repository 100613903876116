import { type Identifier } from 'appTypes'
import { type ResourceType } from 'core'

import { getPricingTierResource, PricingTierTypes } from '../utils'

export const ptServicesResourceName = 'pricing-tiers-services'

export const getPtServicesResource = (id: Identifier): ResourceType => ({
    resource: getPricingTierResource(id, PricingTierTypes.SERVICES),
    name: ptServicesResourceName,
})
