import { type FC, type ReactElement } from 'react'

import { useCreatePath } from 'react-admin'

import { useOpenUtilityDrawer, UtilityDrawerEditor } from 'components'
import { type UtilityDrawerEditorProps } from 'components/Drawer/UtilityDrawerEditor'
import { type Serializer, NotificationMessage } from 'core'

import { type VendorModel } from '../types'
import { vendorsResource } from '../utils'

import { VendorForm, type VendorFormProps } from './VendorForm'

interface Props extends Pick<UtilityDrawerEditorProps, 'onSuccess'> {
    children: (toggle: () => void) => ReactElement
    shortSuccessMessage?: boolean
    vendorFormProps?: VendorFormProps
    record?: VendorModel
}

export const VendorDrawerToggler: FC<Props> = ({
    children,
    onSuccess,
    shortSuccessMessage,
    vendorFormProps,
    record,
}) => {
    const open = useOpenUtilityDrawer()
    const createPath = useCreatePath()

    return children(() =>
        open({
            drawerArgs: {
                title: record?.id ? 'Edit Details' : 'Create Vendor',
                renderContent: () => <VendorForm {...vendorFormProps} />,
                renderWrapper: (params) => (
                    <UtilityDrawerEditor
                        {...params}
                        onSuccess={onSuccess}
                        serializer={vendorsSerializer}
                        successMessage={({ defaultMessages, response }) => {
                            if (record?.id) {
                                return defaultMessages.updated
                            }
                            if (shortSuccessMessage) {
                                return defaultMessages.created
                            }

                            const vendor = response as VendorModel

                            return {
                                title: defaultMessages.created,
                                message: (
                                    <NotificationMessage.Navigate
                                        to={createPath({
                                            resource: vendorsResource.resource,
                                            type: 'edit',
                                            id: vendor.id,
                                        })}
                                    >
                                        {vendor.name}
                                    </NotificationMessage.Navigate>
                                ),
                            }
                        }}
                    />
                ),
            },
            extraArgs: {
                id: record?.id,
                resource: vendorsResource,
            },
        }),
    )
}

export const vendorsSerializer: Serializer<VendorModel> = [
    'name',
    { name: 'type', parse: 'selfValue' },
    {
        name: 'logo',
        parse: 'file',
    },
    'phone',
    { name: 'email', parse: 'email' },
    { name: 'website', parse: 'lowerCase' },
    'address',
    'address2',
    { name: 'country', parse: 'emptyToNull' },
    'city',
    'state',
    'zipCode',
    'taxId',
    'nationalAccount',
    {
        name: 'paymentMethod',
        parse: 'emptyToNull',
    },
    {
        name: 'paymentTerm',
        parse: 'emptyToNull',
    },
    { name: 'tags', parse: 'emptyToNull' },
]
