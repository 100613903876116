import { useRef } from 'react'

const useOptimizedRef = <T>(cb: () => T) => {
    const ref = useRef<T>()

    if (!ref.current) {
        ref.current = cb()
    }

    return ref as React.MutableRefObject<T>
}

export default useOptimizedRef
