import { useChoicesContext, useInput } from 'react-admin'

import { type DataRecord } from 'appTypes'
import { useInputState } from 'core/inputs'

import SelectArrayInput from './SelectArrayInput'
import SelectInputBase, { type SelectInputProps } from './SelectInputBase'

const SelectInput = <FormType extends DataRecord = DataRecord>(
    props: SelectInputProps<FormType>,
) => {
    const { source: choicesSource } = useChoicesContext()
    const { source, ...state } = useInputState(props.source || choicesSource || '', {
        disabled: props.disabled,
    })
    const finalSource = choicesSource || source

    const defaultValue = props.defaultValue ?? (props.multiple ? [] : '')

    const inputField = useInput({
        source: finalSource,
        validate: props.validate,
        defaultValue,
        onChange: props.onChange,
    })

    const Comp = props.multiple ? SelectArrayInput : SelectInputBase

    return (
        <Comp
            defaultValue={defaultValue}
            {...props}
            {...state}
            inputField={inputField}
        />
    )
}

SelectInput.defaultProps = {
    source: '',
    optionText: 'name',
}

export default SelectInput
