import { type PropsWithChildren } from 'react'

import { BoxContainer } from 'ui'

const MonitorBoxContainer = ({ children }: PropsWithChildren) => {
    return (
        <BoxContainer
            gap="4px"
            sx={{ alignItems: 'end' }}
        >
            {children}
        </BoxContainer>
    )
}
export default MonitorBoxContainer
