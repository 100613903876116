import { type FC } from 'react'

import { type CustomerModel } from '../types'

interface Props {
    record: Pick<CustomerModel, 'unitsCount'>
}

const Value: FC<Props> = ({ record }) => {
    return <>{record.unitsCount}</>
}

const source: keyof CustomerModel = 'unitsCount'

const Units = {
    source,
    label: 'Units',
    Value,
}

export default Units
