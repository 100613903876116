import { type SxProps, styled } from 'lib'
import { StyledElement, Img } from 'ui'

const Circle = styled(StyledElement)`
    z-index: -1;
    width: 86px;
    height: 86px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
`

const SuccessCircle = ({ sx, icon }: { sx: SxProps; icon: string }) => {
    return (
        <Circle sx={sx}>
            <Img
                src={icon}
                sx={{
                    width: '36px',
                    height: '36px',
                }}
            />
        </Circle>
    )
}

export default SuccessCircle
