import images from 'assets/images'
import { NoResultsCard } from 'components'
import { useWoActionIsDisabled } from 'resources/workOrders'
import { Box, Typography } from 'ui'

import CreateInvoice from '../CreateInvoice'

const InvoicesSectionEmpty = () => {
    const disabled = useWoActionIsDisabled()

    return (
        <NoResultsCard
            title="No Items"
            direction="row"
            height="206px"
            heightsm="280px"
            directionsm="column"
            imageSrc={images.noInvoices}
            imageProps={{
                alt: 'No Items',
                width: {
                    xs: '122px',
                    sm: '107px',
                },
                mr: {
                    xs: 0,
                    sm: '16px',
                },
            }}
            action={
                <CreateInvoice>
                    {(open) =>
                        disabled ? null : (
                            <Typography
                                component={Box}
                                sx={{
                                    cursor: 'pointer',
                                }}
                                onClick={open}
                                color="primary.main"
                                variant="body1"
                            >
                                Add Invoice
                            </Typography>
                        )
                    }
                </CreateInvoice>
            }
        />
    )
}

export default InvoicesSectionEmpty
