import Icons from 'assets/icons'
import { jobSourceMap, type JobModel } from 'resources/workOrders'
import { StatusText, SvgIcon, Tooltip, tooltipClasses } from 'ui'

export { default as WorkOrderJobCardItems } from './WorkOrderJobCardItems'
export { default as VendorWorkOrderExtraActionsDrawerToggler } from './VendorWorkOrderExtraActionsDrawerToggler'
export { default as useJobItemsTooltipData } from './useJobItemsTooltipData'
export { LineItemsMap } from './useJobItemsTooltipData'
export * from './JobPhotos'

interface JobSourceLabelProps {
    source: JobModel['source']
    sourceData: { name: string } | null
}

const label: { [key in JobModel['source']]?: string } = {
    PM_SCHEDULE: 'PM Interval',
    DEFECT: 'DVIR Defect',
    ISSUE: 'Issue',
}

const JobSourceLabel = ({ source, sourceData }: JobSourceLabelProps) => {
    const isSourceDeleted = !sourceData

    const content = (
        <StatusText
            gap="3px"
            alignItems="center"
            display="flex"
            statusColor={(theme) =>
                isSourceDeleted ? theme.palette.text.primary : theme.palette.primary.main
            }
        >
            <SvgIcon
                component={isSourceDeleted ? Icons.LinkOffOutlined : Icons.LinkOutlined}
                sx={{
                    width: '14px',
                    height: '14px',
                }}
            />
            {jobSourceMap[source]}
        </StatusText>
    )

    if (isSourceDeleted) {
        return (
            <Tooltip title={'This job was previously linked to ' + label[source]}>
                {content}
            </Tooltip>
        )
    }

    if (sourceData.name) {
        return (
            <Tooltip
                title={sourceData.name}
                PopperProps={{
                    sx: {
                        [`.${tooltipClasses.tooltip}`]: {
                            marginTop: '4px !important',
                        },
                    },
                }}
            >
                {content}
            </Tooltip>
        )
    }

    return content
}

export default JobSourceLabel
