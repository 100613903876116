import axios, { type AxiosInstance, type AxiosError, type RawAxiosRequestConfig } from 'axios'

import { config } from 'configs'
import { authStore } from 'core/auth'
import { storeProvider } from 'core/store'
import { type UserModel } from 'resourcesBase'

import { dataAndFiles } from './utils'

export const httpClient: AxiosInstance = axios.create({
    baseURL: config.API_ENDPOINT,
    headers: {
        'Camel-Case': true,
    },
})

httpClient.interceptors.request.use(async (reqConfig) => {
    if (config.DEBUG) {
        console.debug('REQUEST', reqConfig.method, reqConfig.url, reqConfig.data)
    }
    const clientConfig = reqConfig.params?.clientConfig
    if (clientConfig) {
        delete reqConfig.params.clientConfig
        // @ts-ignore
        reqConfig.clientConfig = clientConfig
    }
    if (authStore.shopId) {
        // reqConfig.params = reqConfig.params || {}
        // reqConfig.params.shopContext = authStore.shopId
        reqConfig.headers['X-SHOP-CONTEXT'] = authStore.shopId
    }
    // const date = new Date()
    // reqConfig.headers['time-zone'] = date.getTimezoneOffset()
    // if (reqConfig.method === 'patch') {
    //     delete reqConfig.headers['Camel-Case']
    // }
    return reqConfig
})

httpClient.interceptors.response.use(
    (resp) => {
        config.DEBUG && console.debug('RESPONSE', resp.data)
        const companyVersion = resp.headers['x-company-state-version']
        if (companyVersion) {
            storeProvider.setIfDifferent('companyVersion', companyVersion)
            const method = resp.config.method.toLowerCase()
            if (
                !resp.config.params?.dryRun &&
                !((resp.config as any)?.clientConfig as ApiClientConfig)?.preventCacheReset &&
                ['delete', 'post', 'put', 'patch'].includes(method) &&
                !resp.config.url.includes('dryRun')
            ) {
                storeProvider.setItem('lastMutationDate', Date.now())
            }
        }
        return resp
    },
    (error) => {
        if (error.response) {
            config.DEBUG && console.debug('RESPONSE', error.response)
            return Promise.reject(error.response)
        }
        config.DEBUG && console.debug('RESPONSE ERROR', error)
        return Promise.reject(error)
    },
)

export type LoginWithGoogleData = {
    accessToken: string
    password: string
}

export interface ApiClientConfig {
    preventCacheReset?: boolean
}

export class CApi {
    public cancelled: boolean

    public httpClient: AxiosInstance

    public middleware: any

    constructor(httpClient: AxiosInstance) {
        this.cancelled = false
        this.httpClient = httpClient
        this.middleware = {}
    }

    login = (email: string, password: string) => this.post('auth/login', { email, password })

    loginWithGoogle = (data: LoginWithGoogleData, config?: RawAxiosRequestConfig) =>
        this.post('auth/login/google', data, config)

    logout = () => this.post('auth/logout')

    getCurrentUser = (): Promise<UserModel> => this.get('auth/user')

    changePassword = (oldPassword: string, newPassword: string) =>
        this.post('auth/password/change', {
            currentPassword: oldPassword,
            newPassword,
        })

    resetPassword = (email: string) => this.post('auth/password/reset', { email })

    resetPasswordConfirm = (newPassword: string, uid: string, token: string) =>
        this.post('auth/password/reset/confirm', {
            newPassword,
            uid,
            token,
        })

    checkInvite = (token: string) => this.get(`invitations/accept/${token}`)

    acceptInvite = (token: string, password: string, name: string) =>
        this.post(`invitations/accept/${token}`, { password, name })

    webpushSubscribe = (subscription: any) => {
        const subData = subscription.toJSON().keys
        const navigators = navigator.userAgent.match(/(firefox|msie|chrome|safari|trident)/gi)
        const data = {
            endpoint: subscription.endpoint,
            p256dh: subData.p256dh,
            auth: subData.auth,
            browser: navigators?.length ? navigators[0].toLowerCase() : '',
        }
        return this.post('webpush-subscription', data)
    }

    webpushUnsubscribe = (args: any) => {
        // TODO add support for multiple subscriptions
        return this.sendRequest('delete', 'webpush-subscription')
    }

    get = (urlpath: string, data?: any, extraConfig?: RawAxiosRequestConfig) =>
        this.sendRequest('get', urlpath, data, extraConfig)

    post = (urlpath: string, data?: any, extraConfig?: RawAxiosRequestConfig) =>
        this.sendRequest('post', urlpath, data, extraConfig)

    put = (urlpath: string, data?: any, extraConfig?: RawAxiosRequestConfig) =>
        this.sendRequest('put', urlpath, data, extraConfig)

    delete = (urlpath: string, data?: any, extraConfig?: RawAxiosRequestConfig) =>
        this.sendRequest('delete', urlpath, data, extraConfig)

    patch = (urlpath: string, data?: any, extraConfig?: RawAxiosRequestConfig) =>
        this.sendRequest('patch', urlpath, data, extraConfig)

    sendRequest = async (
        method: 'get' | 'post' | 'put' | 'delete' | 'patch',
        urlpath: string,
        data?: any,
        extraConfig?: RawAxiosRequestConfig,
    ) => {
        this.cancelled = false
        const config: RawAxiosRequestConfig = {
            // `withCredentials` indicates whether or not cross-site Access-Control requests
            // should be made using credentials
            withCredentials: true,
            method,
            // cancelToken: cancel_token_source.token,
            ...(extraConfig || {}),
        }
        if (data) {
            config[method === 'get' ? 'params' : 'data'] = data
        }
        if (config.data) {
            config.data = await dataAndFiles(config.data)
        }
        return this.httpClient(urlpath, config)
            .then((resp) => resp.data)
            .catch((response) => {
                if (response.status === 400) {
                    return Promise.reject(response.data) // let others deal with validation error data
                }
                return Promise.reject(response)
            })
    }

    cancelPendingRequest() {
        this.cancelled = true
        // cancel_token_source.cancel()
    }

    setAuthKey(key: string) {
        this.httpClient.defaults.headers.common.Authorization = `Token ${key}`
    }

    clearAuthKey() {
        delete this.httpClient.defaults.headers.common.Authorization
    }

    setServerErrorHandler(callback) {
        this.setErrorCallback('server_error', (error: any) => error.status === 500, callback)
    }

    setUnauthenticatedHandler(callback) {
        this.setErrorCallback(
            'unauthenticated_error',
            (error: AxiosError) => error.status === 401,
            callback,
        )
    }

    setNetworkErrorHandler(callback) {
        this.setErrorCallback(
            'network_error',
            (error: AxiosError) => error.message === 'Network Error',
            callback,
        )
    }

    setSuccessCallback(name: string, callback) {
        if (!callback) {
            this.removeMiddleware(name)
            return
        }
        const handler = (resp) => {
            callback(resp)
            return resp
        }
        this.addMiddleware('response', name, handler)
    }

    setErrorCallback(
        name: string,
        conditionalFunction: (error: AxiosError) => boolean,
        callback: (error: AxiosError) => void,
    ) {
        if (!callback) {
            this.removeMiddleware(name)
            return
        }
        const handler = (error: AxiosError) => {
            if (conditionalFunction(error)) {
                callback(error)
            }
            return Promise.reject(error)
        }
        this.addMiddleware('response', name, (resp) => resp, handler)
    }

    addMiddleware(type, name, successHandler, errorHandler?: any) {
        const interceptors = this.httpClient.interceptors[type]
        this.middleware[name] = {
            type,
            func: interceptors.use(successHandler, errorHandler),
        }
    }

    removeMiddleware(name: string) {
        const handler = this.middleware[name]
        if (handler) {
            const interceptors = this.httpClient.interceptors[handler.type]
            delete this.middleware[name]
            interceptors.eject(handler.func)
        }
    }
}

const api = new CApi(httpClient)

export default api

if (process.env.NODE_ENV === 'development') {
    import('./customErrorResponse').then((module) => module.default(api.httpClient))
}
