import { useListContext, useShowContext } from 'react-admin'

import { type Identifier } from 'appTypes'
import { useOpenUtilityDrawer, UtilityDrawerEditor } from 'components'
import { useResource } from 'core'
import { isUnitArchived, type UnitModel } from 'resources/units'

import { deleteUnitPMIntervalConfig, unitPMIntervalSerializator } from '../../utils'
import { PMLastMetersContextProvider } from '../PMLastMeters'

import UnitPMForm from './UnitPMForm'
import { type UnitPMDrawerEditorExtraState } from './types'

interface UnitPMDrawerTogglerParams {
    id?: Identifier
    type: 'dependent' | 'independent'
}

const useUnitPMDrawerEditor = () => {
    const open = useOpenUtilityDrawer()
    const listContext = useListContext()
    const resource = useResource()
    const { record } = useShowContext<UnitModel>()
    const isArchived = isUnitArchived(record)

    return ({ id, type }: UnitPMDrawerTogglerParams) => {
        open({
            drawerArgs: {
                title: id
                    ? isArchived
                        ? 'View Interval'
                        : 'Edit Interval'
                    : `Create Interval (${type === 'independent' ? 'Independent' : 'Dependent'})`,
                renderWrapper: (props) => (
                    <PMLastMetersContextProvider>
                        <UtilityDrawerEditor
                            serializer={unitPMIntervalSerializator}
                            {...props}
                        />
                    </PMLastMetersContextProvider>
                ),
                renderContent: () => <UnitPMForm />,
                renderBottomRight: isArchived ? () => null : (renderButton) => renderButton(),
                renderBottomLeft: isArchived
                    ? (render) => render({ children: 'Close' })
                    : undefined,

                renderTopRight: (renderButton) =>
                    id
                        ? renderButton({ disabled: isArchived, ...deleteUnitPMIntervalConfig })
                        : null,
            },
            extraArgs: {
                resource: { ...resource, resource: resource.resource + (id ? '' : '/' + type) },
                id,
                listContext,
            },
            extra: {
                unit: record,
                isArchived,
            } satisfies UnitPMDrawerEditorExtraState,
        })
    }
}

export default useUnitPMDrawerEditor
