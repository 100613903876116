import { ResourceWithClearEffect } from 'core'
import { issuesResource } from 'resources/issues'

import IssuesList from './List'
import IssuesShow from './Show'

const Issues = () => {
    return (
        <ResourceWithClearEffect
            name={issuesResource}
            list={IssuesList}
            edit={IssuesShow}
        />
    )
}
export default Issues
