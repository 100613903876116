import { formatDate } from 'lib'

import { type IssueModel } from '../types'

const source = 'reported' as const satisfies keyof IssueModel

interface Data extends Pick<IssueModel, 'reported'> {}

const value = (data: Data) => {
    if (!data) {
        return ''
    }

    return formatDate(data.reported, (formatAs) => formatAs.shortenedDateTime)
}

const reportedDate = {
    source,
    label: 'Reported Date & Time',
    value,
}

export default reportedDate
