import { type FC } from 'react'

import { useRecordContext } from 'react-admin'

import { Header, InnerHeader, ShowHeaderSkeleton, type TabType } from 'components'
import { type Action, deleteOneAction } from 'core'
import { formatVendorTypes, vendorFields, type VendorModel } from 'resources/vendors'

import { deleteVendorParams } from '../../constants'

const VendorShowHeader: FC<{ tabs: TabType[] }> = ({ tabs }) => {
    const record = useRecordContext<VendorModel>()

    if (!record) {
        return <ShowHeaderSkeleton />
    }

    return (
        <InnerHeader
            tabs={tabs}
            tabWidth="186px"
            actions={actions}
        >
            <Header.Info
                avatar={
                    <Header.Avatar
                        imageSrc={record.logo}
                        defaultImage={<vendorFields.avatar.Icon />}
                    />
                }
            >
                <Header.Content>
                    <Header.Title>{record.name}</Header.Title>
                </Header.Content>
                <Header.Content mobilePlacement="bottom">
                    {formatVendorTypes(record.type)}
                </Header.Content>
            </Header.Info>
        </InnerHeader>
    )
}

export default VendorShowHeader

const actions: Action<VendorModel> = (record, { children }) => {
    return [
        deleteOneAction({
            id: record.id,
            ...deleteVendorParams,
            children,
        }),
    ]
}
