import { type FC } from 'react'

import { urls } from 'configs'
import { blockerForceRedirectState } from 'core/blocker'
import { type IssueModel } from 'resources/issues'
import { LinkButton } from 'ui'

interface Props {
    record: IssueModel
    action: string
}

const ActionWhileInProgressText: FC<Props> = ({ record, action }) => {
    if (!record.workOrderData) {
        return null
    }

    return (
        <div>
            Once {action}, its corresponding{' '}
            <LinkButton
                to={urls.workOrders + '/' + record.workOrderData.id}
                state={blockerForceRedirectState}
            >
                jobs ({record.workOrderData.number})
            </LinkButton>{' '}
            will be disconnected, but not deleted.
        </div>
    )
}

export default ActionWhileInProgressText
