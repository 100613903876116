import { CheckboxInput } from 'components'
import { Alert } from 'ui'

const DefaultFormInput = ({ withAlert = true }: { withAlert?: boolean }) => {
    return (
        <>
            <CheckboxInput
                source="isDefault"
                label="Set as Default"
            />
            {withAlert && (
                <Alert
                    severity="info"
                    sx={{ mt: '7px' }}
                >
                    The "Default" setting is your shop's standard pricing for repairs, used whenever
                    a customer doesn't have a specific pricing plan in place.
                </Alert>
            )}
        </>
    )
}

export default DefaultFormInput
