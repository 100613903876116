import { type FC } from 'react'

import { formatMoney } from 'components'
import { Alert, Box, Typography } from 'ui'

import { type WorkOrderModel } from '../../types'

interface Props {
    data: WorkOrderModel
}

const CloseDialogUnsuccessful: FC<Props> = ({ data }) => {
    return (
        <Box>
            <Alert
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
                variant="standard"
                severity="warning"
            >
                The amount of Purchase Order and Invoices Total should match.
            </Alert>
            <Typography
                color="text.primary"
                padding="12px 0px"
                variant="body2"
            >
                Purchase Order Total {formatMoney(data.purchaseOrderData.total || 0)}
            </Typography>
            <Typography
                color="text.primary"
                padding="12px 0px"
                variant="body2"
            >
                Invoices Total {formatMoney(data.purchaseOrderData.totalInvoices || 0)}
            </Typography>
        </Box>
    )
}

export default CloseDialogUnsuccessful
