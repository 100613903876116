import Icons from 'assets/icons'

import { type ActionChildren } from './Actions'

interface ExportActionProps {
    children: ActionChildren
}

export const ExportAction = ({ children }: ExportActionProps) => {
    return children({
        disabled: true,
        Icon: Icons.Export,
        title: 'Export (Coming Soon)',
        onClick: () => {
            //
        },
    })
}

export const exportAction = (params: ExportActionProps) => (
    <ExportAction
        {...params}
        key="export"
    />
)
