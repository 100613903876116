import { useUtilityDrawerContext } from 'components'
import { Stack, Typography } from 'ui'

import SuccessImage from './SuccessImage'
import { type IntegrationDrawerTogglerExtraProps } from './types'

const IntegrationSuccessContent = () => {
    const { extra }: { extra: IntegrationDrawerTogglerExtraProps } = useUtilityDrawerContext()

    return (
        <Stack
            spacing="15px"
            height="100%"
            justifyContent="center"
            alignItems="center"
        >
            <SuccessImage image={extra.integration.logo} />
            <Typography
                variant="h5"
                textAlign="center"
            >
                Connection Completed Successfully
            </Typography>
        </Stack>
    )
}

export default IntegrationSuccessContent
