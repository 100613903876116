import { type Identifier } from 'appTypes'
import { type ResourceType } from 'core'

import { getPricingTierResource, PricingTierTypes } from '../utils'

export const ptPartResourceName = 'pricing-tiers-parts'

export const getPtPartResource = (id: Identifier): ResourceType => ({
    resource: getPricingTierResource(id, PricingTierTypes.PARTS),
    name: ptPartResourceName,
})
