import { useState, useEffect, useRef } from 'react'

import { useListContext } from 'react-admin'
import { FormProvider, useForm } from 'react-hook-form'

import Icons from 'assets/icons'
import { type SxProps } from 'lib'
import { Button } from 'ui'
import { omit } from 'utils'

import { ListUtilityDrawer } from '../ListUtilityDrawer'

import { useListFilterDrawerContext } from './ListFilterDrawerContext'
import ListFilterForm, {
    type ListFilterFormProps,
    getSourceFilterCfg,
    couldSourceBeRange,
    getSourceNoRangeSfx,
    type FilterConfig,
} from './ListFilterForm'
import { filterOperator } from './utils'

function addSourceIfRange(result, source, key, filtersCfg) {
    const sourceNoSfx = getSourceNoRangeSfx(source)
    const currentFilterCfg = getSourceFilterCfg(filtersCfg, sourceNoSfx)
    if (
        currentFilterCfg.filterType === ('range' as const) &&
        !Object.values(result).includes(sourceNoSfx)
    ) {
        result[key] = sourceNoSfx
    }
    return result
}

function makeFilterKey2source(filterValues, filtersCfg) {
    return Object.entries(filterValues).reduce((result, item, ix) => {
        const source = filterOperator.getSourceFromNegative(item[0])
        if (couldSourceBeRange(source)) {
            result = addSourceIfRange(result, source, ix, filtersCfg)
        } else {
            result[ix] = source
        }
        return result
    }, {})
}

const makeFilterKey2sourceEmptyValue = (max) => ({ [max + 1]: undefined })

/*
data lifecycle high level overview

filterValues                :      persistent
filterKey2source, form data :  non persistent
when filter drawer is open:
    - initial filterKey2source, form data must be created from filterValues.
    - filterKey2source, form data become truth
    - non persistent changes happen
when exiting filter drawer :
    - filterKey2source, form data - non persistent changes, become persistent in filterValues.
    - filterValues becomes truth

sorting of filters is dependant on filterValues     input order : persistent
    - filterValues     is an object, so not a good practice,
      but it is the only persistent element
sorting of filters is dependant on filterKey2source input order : non persistent
    - filterKey2source is an object, so not a good practice,
      but when removing/clearing field/s, count must start from a different number,
      otherwise react considers field/s the same cuz key = 0 (Array.firstItem is always)
*/

export interface ListFilterDrawerProps {
    filtersCfg?: ListFilterFormProps['filtersCfg']
    excludeFields?: string[]
}

const ListFilterDrawer = ({ filtersCfg: filtersCfgProp, excludeFields }: ListFilterDrawerProps) => {
    const { isOpen, setOpen, close } = useListFilterDrawerContext()
    const [justAddedFilter, setJustAddedFilter] = useState(false)
    const scrollRef = useRef<null | HTMLDivElement>(null)
    const filtersCfg: FilterConfig = {
        ...filtersCfgProp,
        filters: filtersCfgProp.filters.filter(Boolean),
    }

    const { setFilters, filterValues, displayedFilters } = useListContext()
    const filterValuesCleaned = omit(filterValues, excludeFields)

    const [filterKey2source, setFilterKey2source] = useState<
        ListFilterFormProps['filterKey2source']
    >((): ListFilterFormProps['filterKey2source'] =>
        makeFilterKey2source(filterValuesCleaned, filtersCfg),
    )

    useEffect(() => {
        if (justAddedFilter) {
            setJustAddedFilter(false)
        } else {
            scrollRef.current?.scrollIntoView({ behavior: 'smooth' })
        }
    }, [justAddedFilter])

    const filterKeyKeys = Object.keys(filterKey2source)
    const filterKeyValues = Object.values(filterKey2source)
    const filterKey2sourceLength = filterKeyKeys.length
    const isFormEmpty = filterKey2sourceLength === 1 && filterKeyValues[0] === undefined

    const filterKeysNumbers = filterKeyKeys.map((v) => +v)
    const filterKey2sourceMaxKey = Math.max(...(filterKeysNumbers.length ? filterKeysNumbers : [0]))
    // because of useForm.defaultValues, defaultValues have to be computed in the first render
    // if workaround is found - put this in useEffect/useWHATEVER
    let defaultValues = {}
    let dvCount = 0
    for (const [_source, value] of Object.entries(filterValuesCleaned)) {
        if (value !== undefined) {
            const source = filterOperator.getSourceFromNegative(_source)

            if (couldSourceBeRange(source)) {
                defaultValues = addSourceIfRange(
                    defaultValues,
                    source,
                    dvCount + '_source',
                    filtersCfg,
                )
            } else {
                defaultValues[dvCount + '_source'] = source // for selectInput = source
            }
            defaultValues[source] = value // for reference
            defaultValues[filterOperator.getSource(source)] = filterOperator.sourceToValue(_source) // for operator
            dvCount += 1
        }
    }

    const form = useForm({ defaultValues })

    useEffect(() => {
        if (!filterKey2sourceLength) {
            setFilterKey2source(makeFilterKey2sourceEmptyValue(filterKey2sourceMaxKey))
        }
    }, [!filterKey2sourceLength])

    useEffect(() => {
        const newFilterKey2source = Object.keys(filterValuesCleaned).length
            ? makeFilterKey2source(filterValuesCleaned, filtersCfg)
            : makeFilterKey2sourceEmptyValue(filterKey2sourceMaxKey)
        if (!isOpen) {
            form.reset({})
            setFilterKey2source({})
        }
        if (isOpen) {
            // XXX HACK, do this in filter bar - clear button
            form.reset(defaultValues)
            setFilterKey2source(newFilterKey2source)
        }
    }, [isOpen])

    useEffect(() => {
        const newFilterKey2source = Object.keys(filterValuesCleaned).length
            ? makeFilterKey2source(filterValuesCleaned, filtersCfg)
            : makeFilterKey2sourceEmptyValue(filterKey2sourceMaxKey)
        setFilterKey2source(newFilterKey2source)
    }, [])

    const outlinedButtonSx: SxProps = {
        border: '0 !important', // important used for disabled state (could be done better probably)
        '&:hover': { border: 0 },
        letterSpacing: '0.46px',
        lineHeight: '26px',
        padding: '8px 11px',
    }

    if (!filtersCfg) {
        return null
    }

    return (
        <FormProvider {...form}>
            <ListUtilityDrawer
                onClose={close}
                open={isOpen}
                setOpen={setOpen}
                title="Filters"
                anchorBottomHeightSize="big"
                renderContent={() =>
                    isOpen ? (
                        <>
                            <ListFilterForm
                                filtersCfg={filtersCfg}
                                filterKey2source={filterKey2source}
                                setFilterKey2source={setFilterKey2source}
                                isFormEmpty={isFormEmpty}
                            />
                            <div ref={scrollRef} />
                        </>
                    ) : null
                }
                renderTopRight={() => {
                    return (
                        <Button
                            size="large"
                            variant="outlined"
                            startIcon={<Icons.Add fontSize="large" />}
                            sx={outlinedButtonSx}
                            onClick={() => {
                                setFilterKey2source({
                                    ...filterKey2source,
                                    ...makeFilterKey2sourceEmptyValue(filterKey2sourceMaxKey),
                                })
                                setJustAddedFilter(true)
                            }}
                        >
                            Add Filter
                        </Button>
                    )
                }}
                renderBottomLeft={() => {
                    return (
                        <Button
                            size="large"
                            variant="outlined"
                            color="error"
                            disabled={isFormEmpty}
                            startIcon={<Icons.DeleteOutlineOutlined fontSize="large" />}
                            sx={outlinedButtonSx}
                            onClick={() => {
                                form.reset()
                                setFilterKey2source(
                                    makeFilterKey2sourceEmptyValue(filterKey2sourceMaxKey),
                                )
                            }}
                        >
                            CLEAR ALL
                        </Button>
                    )
                }}
                renderBottomRight={() => {
                    return (
                        <Button
                            size="large"
                            variant="contained"
                            sx={{ letterSpacing: '0.46px', lineHeight: '26px' }}
                            onClick={() => {
                                // displayedFilters is not needed since we are not using react-admin.FilterForm
                                const filterValuesNew = {}
                                for (const excluded of excludeFields) {
                                    if (filterValues[excluded]) {
                                        filterValuesNew[excluded] = filterValues[excluded]
                                    }
                                }
                                Object.values(filterKey2source).forEach((source) => {
                                    if (!source) {
                                        return
                                    }
                                    const values = form.getValues()
                                    const currentFilterCfg = getSourceFilterCfg(filtersCfg, source)

                                    if (currentFilterCfg.filterType === 'range') {
                                        for (const sfx of ['Min', 'Max']) {
                                            const sourceSfx = source + sfx
                                            const valueSfx = values[sourceSfx]
                                            if (valueSfx || valueSfx === 0) {
                                                filterValuesNew[sourceSfx] = valueSfx
                                            }
                                        }
                                    } else {
                                        const value = values[source]
                                        if (Array.isArray(value) ? value.length : value) {
                                            filterValuesNew[
                                                filterOperator.valueToSource(
                                                    values[filterOperator.getSource(source)],
                                                    source,
                                                )
                                            ] = value
                                        }
                                    }
                                })
                                setFilters(filterValuesNew, displayedFilters)
                                setOpen(false)
                            }}
                        >
                            APPLY
                        </Button>
                    )
                }}
            />
        </FormProvider>
    )
}

export default ListFilterDrawer
