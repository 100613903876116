import { type FC } from 'react'

import {
    RaResourceContextProvider,
    type ResourceContextProviderProps,
} from './RaResourceContextProvider'
import { useClearResource } from './useClearResource'

export const ResourceContextProviderWithClearEffect: FC<ResourceContextProviderProps> = ({
    value,
    children,
}) => {
    useClearResource(value.resource)

    return <RaResourceContextProvider value={value}>{children}</RaResourceContextProvider>
}
