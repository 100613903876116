import Icons from 'assets/icons'
import { CardCollapsibleScrollbar, CollapsibleContent, CollapsibleIconHolder } from 'components'
import { Divider, Stack, InfoBadge, Typography, BoxContainer } from 'ui'

import { type TagModel } from '../types'

import { TagField } from './TagField'

interface TagsCollapsibleContentProps {
    tags: TagModel[]
}
export const TagsCollapsibleIconHolder = ({ tags }: TagsCollapsibleContentProps) => {
    return (
        <CollapsibleIconHolder
            name="tags"
            title={`Tags (${tags?.length})`}
            disabled={!tags?.length}
            titleOnDisabled="No Tags Added"
        >
            <Icons.SellOutlined fontSize="small" />
        </CollapsibleIconHolder>
    )
}
const TagsCollapsibleContent = ({ tags }: TagsCollapsibleContentProps) => {
    if (!tags) {
        return null
    }
    return (
        <CollapsibleContent name="tags">
            <Stack mt="15px">
                <Typography
                    gap="8px"
                    mb="10px"
                    component={BoxContainer}
                    variant="chartTitle"
                >
                    Tags <InfoBadge badgeContent={String(tags.length)} />
                </Typography>
                <Divider />
                <CardCollapsibleScrollbar>
                    <BoxContainer
                        gap="4px"
                        flexWrap="wrap"
                    >
                        {tags.map((tag) => (
                            <TagField
                                tag={tag}
                                key={tag.id}
                            />
                        ))}
                    </BoxContainer>
                </CardCollapsibleScrollbar>
            </Stack>
        </CollapsibleContent>
    )
}
export default TagsCollapsibleContent
