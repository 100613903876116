import Icons from 'assets/icons'
import { formatMoney, InfoCard, InfoCardDetails, InfoCardHeader } from 'components'
import { Link, formatDate } from 'lib'
import { type ReceiptModel } from 'resources/purchaseOrders'
import { Divider, SvgIcon, Typography, BoxContainer } from 'ui'

const ReceiptCard = ({ receipt }: { receipt: ReceiptModel }) => {
    return (
        <InfoCard>
            <InfoCardHeader
                title={
                    <BoxContainer gap="4px">
                        <SvgIcon
                            component={Icons.Check}
                            color="success"
                            sx={{ width: '16px', height: '16px' }}
                        />
                        <Typography
                            color="primary.main"
                            variant="subtitle2"
                            component={Link}
                            to={`line-items/${receipt.id}`}
                        >
                            Receipt {receipt.number}
                        </Typography>
                    </BoxContainer>
                }
                action={
                    <Typography
                        variant="body2"
                        color="text.primary"
                    >
                        {formatDate(receipt.created, () => 'MMM dd yyyy h:mm aaa')}
                    </Typography>
                }
            />
            <InfoCardDetails
                propRecord={receipt}
                details={[
                    {
                        label: 'Line Items total',
                        source: ({ partsTotal }) => formatMoney(partsTotal),
                    },
                    {
                        label: 'Shipping',
                        source: ({ shippingTax }) => formatMoney(shippingTax),
                    },
                    {
                        label: 'Miscellaneous',
                        source: ({ miscellaneousTax }) => formatMoney(miscellaneousTax),
                    },
                    {
                        label: 'Discount',
                        source: ({ discount }) => formatMoney(discount),
                    },
                    {
                        label: 'Sales Tax',
                        source: ({ salesTax }) => formatMoney(salesTax),
                    },
                ]}
            />
            <Divider sx={{ my: '12px' }} />
            <BoxContainer justifyContent="space-between">
                <Typography variant="subtitle2">Total</Typography>
                <Typography variant="subtitle2">{formatMoney(receipt.total)}</Typography>
            </BoxContainer>
        </InfoCard>
    )
}
export default ReceiptCard
