import { type FC, type ReactElement } from 'react'

import { type Identifier } from 'appTypes'
import { type UseCreateResourcePathParams, useCreateResourcePath } from 'core/resource'
import { Link, type LinkProps } from 'lib'
import { DataAvatar, type DataAvatarProps } from 'ui'

interface ListAvatarProps extends Omit<DataAvatarProps, 'id'>, ListAvatarLinkProps {
    disableLink?: boolean
}

const ListAvatar: FC<ListAvatarProps> = ({
    id,
    linkProps,
    customPath,
    resource,
    disableLink,
    ...props
}) => {
    const avatar = <DataAvatar {...props} />

    if (disableLink) {
        return avatar
    }

    return (
        <ListAvatarLink
            id={id}
            linkProps={linkProps}
            customPath={customPath}
            resource={resource}
        >
            {avatar}
        </ListAvatarLink>
    )
}

export default ListAvatar

interface ListAvatarLinkProps {
    id: Identifier
    linkProps?: Omit<LinkProps, 'to'>
    customPath?: UseCreateResourcePathParams | string
    resource?: string
}

const ListAvatarLink: FC<ListAvatarLinkProps & { children: ReactElement }> = ({
    children,
    id,
    linkProps,
    resource,
    customPath,
}) => {
    const createPath = useCreateResourcePath()

    const to: UseCreateResourcePathParams = {
        id,
        type: 'edit',
        resource,
    }

    return (
        <Link
            to={typeof customPath === 'string' ? customPath : createPath(customPath || to)}
            aria-label="View Record"
            {...linkProps}
        >
            {children}
        </Link>
    )
}
