import { inject, observer } from 'mobx-react'
import { useFormContext } from 'react-hook-form'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import { SelectorOption, type DialogSelectorProps, DialogSelector, formatMoney } from 'components'
import { type AuthStore } from 'core/auth'
import { inventoryFields, type PartWithInventoryModel } from 'resources/inventory'
import { TagsInSelector } from 'resources/tags'
import { uomFields } from 'resources/unitsOfMeasure'
import { ck33Fields, ck34Fields } from 'resources/vmrs'
import { Button } from 'ui'

import partFields from '../fields'
import { type VendorPartModel, type PartModel } from '../types'
import { partsResource } from '../utils'

import { PartDrawerToggler } from './PartDrawerToggler'
import { VendorPartDrawerToggler } from './VendorPartDrawerToggler'

type Record = VendorPartModel | PartModel | PartWithInventoryModel

interface Props<RecordType extends Record = Record>
    extends Pick<
        DialogSelectorProps<RecordType>,
        | 'refetchOnOpen'
        | 'disabled'
        | 'onSelectedChange'
        | 'referenceFilter'
        | 'required'
        | 'resettable'
        | 'renderToggler'
        | 'onChange'
        | 'defaultFilter'
    > {
    successMessage?: string
    disableCreateOption?: boolean
    inputTitle?: string
    contextType: string
    contextId?: Identifier
    type?: PartType
    source?: string
    reference?: string
}

type PartType = 'vendor' | 'inventory' | 'internal'

export const PartInput = inject('auth')(
    observer(
        ({
            reference = partsResource.resource,
            successMessage,
            disableCreateOption,
            inputTitle,
            auth,
            contextType,
            contextId,
            type: typeProp = 'internal',
            required = true,
            ...props
        }: Props & { auth: AuthStore }) => {
            const { getValues } = useFormContext()

            const type =
                typeProp === 'inventory'
                    ? auth.companySettings.hasInventory
                        ? 'inventory'
                        : 'internal'
                    : typeProp

            const isInventory = type === 'inventory'

            const title = getTitle(type)

            return (
                <DialogSelector<Record>
                    source="part"
                    queryOptions={
                        contextType
                            ? () => ({ meta: { query: { contextType, contextId } } })
                            : undefined
                    }
                    titleSource={() => title}
                    reference={reference}
                    defaultSelectorProps={{
                        label: inputTitle || 'Part No',
                    }}
                    required={required}
                    renderItem={(record) => renderOption(record, type)}
                    noResults={({ searchValue }) => {
                        if (!searchValue) {
                            return {
                                title: 'No Parts Added',
                                text: '',
                                image: (images) => images.listEmpty,
                                imageWidth: '180px',
                            }
                        }
                    }}
                    renderNoResults={({ filterValues }) =>
                        filterValues.recentlyUsed ? null : undefined
                    }
                    renderNextToResultCount={
                        isInventory
                            ? null
                            : ({ onSelect, control }) =>
                                  type === 'vendor' ? (
                                      <VendorPartDrawerToggler
                                          shortSuccessMessage
                                          onSuccess={(record) => {
                                              onSelect(record.id)
                                              control.refetch()
                                          }}
                                          children={({ onClick }) =>
                                              control.isFetching ? null : (
                                                  <Button
                                                      startIcon={<Icons.Add />}
                                                      variant="contained"
                                                      onClick={onClick}
                                                      sx={{ mt: '20px' }}
                                                  >
                                                      Create New Part
                                                  </Button>
                                              )
                                          }
                                      />
                                  ) : (
                                      <PartDrawerToggler
                                          shortSuccessMessage
                                          onSuccess={(record) => {
                                              onSelect(record.id)
                                              control.refetch()
                                          }}
                                          children={({ onClick }) =>
                                              control.isFetching ? null : (
                                                  <Button
                                                      startIcon={<Icons.Add />}
                                                      variant="contained"
                                                      onClick={onClick}
                                                      sx={{ mt: '20px' }}
                                                  >
                                                      Create New Part
                                                  </Button>
                                              )
                                          }
                                      />
                                  )
                    }
                    defaultSelectorValueSource={({ selected, value }) => {
                        const id = getValues('id')
                        if (id && !value) {
                            return '(Deleted) ' + getValues('partNumber')
                        }

                        return selected?.number
                    }}
                    defaultSelectorHelperText={({ value }) => {
                        const id = getValues('id')
                        if (id && !value) {
                            return 'This part has been deleted from the catalog.'
                        }

                        return null
                    }}
                    {...props}
                />
            )
        },
    ),
) as <RecordType extends Record = Record>(props: Props<RecordType>) => JSX.Element

const getTitle = (type: PartType) => {
    if (type === 'vendor') {
        return 'Vendor Parts'
    }
    if (type === 'inventory') {
        return 'Part from Inventory'
    }
    return 'Parts Catalog'
}

const renderOption = (record: Record, type: PartType) => {
    const inventoryItem =
        type === 'inventory' ? (record as PartWithInventoryModel).inventoryItems[0] : null

    const availableQty = type === 'inventory' ? inventoryItem.availableQuantity : null

    const upc = type === 'vendor' ? null : (record as PartModel).universalProductCode

    const binLocationId = type === 'inventory' ? inventoryItem.binLocationId : null

    const averateCost = type === 'inventory' ? inventoryItem.acquisitionCost : null

    return (
        <SelectorOption>
            <SelectorOption.Title
                avatar={<partFields.avatar.Value record={record as VendorPartModel} />}
            >
                {record.number}
            </SelectorOption.Title>

            <SelectorOption.Details>
                {typeof availableQty === 'number' ? (
                    <SelectorOption.Row
                        label="Available QTY"
                        Icon={inventoryFields.avatar.Icon}
                    >
                        {availableQty}
                    </SelectorOption.Row>
                ) : null}

                {record.description ? (
                    <SelectorOption.Row
                        label={partFields.description.label}
                        Icon={Icons.DescriptionOutlined}
                    >
                        {record.description}
                    </SelectorOption.Row>
                ) : null}

                <SelectorOption.Row
                    label="Component Code"
                    Icon={Icons.Code}
                >
                    {ck33Fields.self.formatCode(
                        record.componentData.code,
                        record.componentData.level,
                    )}
                </SelectorOption.Row>

                <SelectorOption.Row
                    label="Component Description"
                    Icon={Icons.ComponentDescription}
                >
                    {record.componentData.text}
                </SelectorOption.Row>

                {record.manufacturer ? (
                    <SelectorOption.Row
                        label={ck34Fields.self.label}
                        Icon={Icons.FactoryOutlined}
                    >
                        {ck34Fields.self.value(record.manufacturerData)}
                    </SelectorOption.Row>
                ) : null}

                {upc ? (
                    <SelectorOption.Row
                        label={partFields.upc.label}
                        Icon={Icons.Barcode}
                    >
                        {upc}
                    </SelectorOption.Row>
                ) : null}

                {record.unitOfMeasure ? (
                    <SelectorOption.Row
                        label={uomFields.self.label}
                        Icon={Icons.Straighten}
                    >
                        {uomFields.self.value(record.unitOfMeasureData)}
                    </SelectorOption.Row>
                ) : null}

                {binLocationId ? (
                    <SelectorOption.Row
                        label="Bin Location ID"
                        Icon={Icons.WarehouseOutlined}
                    >
                        {binLocationId}
                    </SelectorOption.Row>
                ) : null}

                {typeof averateCost === 'number' ? (
                    <SelectorOption.Row
                        label="Average Cost"
                        Icon={Icons.TrendingUp}
                    >
                        {formatMoney(averateCost)}
                    </SelectorOption.Row>
                ) : null}
            </SelectorOption.Details>

            <TagsInSelector tags={record.tagsData} />
        </SelectorOption>
    )
}
