import { type FC } from 'react'

import { AutocompleteInput, type AutocompleteInputProps } from 'components'
import { ReferenceInput } from 'core'

import { ck15Resource, displayCK15 } from '../utils'

interface Props extends Pick<AutocompleteInputProps, 'disabled'> {
    label?: string
    source?: string
}

export const CK15Input: FC<Props> = ({ source = 'correction', disabled, label = 'Correction' }) => {
    return (
        <ReferenceInput
            source={source}
            reference={ck15Resource.resource}
            disabled={disabled}
            perPage={100}
        >
            <AutocompleteInput
                optionText={displayCK15}
                label={label}
                disabled={disabled}
            />
        </ReferenceInput>
    )
}
