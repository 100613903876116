import { type FC } from 'react'

import { SelectInput } from 'components'
import { ReferenceInput, requiredValidation } from 'core'

import { rolesResource } from '../utils'

interface Props {
    required?: boolean
}

export const RoleInput: FC<Props> = ({ required }) => {
    return (
        <ReferenceInput
            reference={rolesResource.resource}
            source="role"
        >
            <SelectInput
                optionText="label"
                validate={required ? requiredValidation : undefined}
                label="Role"
                disableEmptyValue
            />
        </ReferenceInput>
    )
}
