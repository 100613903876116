import { styled } from 'lib'
import { SimpleScrollbar } from 'ui'

const CardCollapsibleScrollbar = styled(SimpleScrollbar)`
    padding-bottom: 16px;
    padding-top: 10px;
    max-height: 120px;
    margin-bottom: calc(16px * -1);
`

export default CardCollapsibleScrollbar
