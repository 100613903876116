import { forwardRef, type ReactElement } from 'react'

import { alpha, styled, generateNotForwardedProps, themeColor, type ThemeColorType } from 'lib'

import Avatar, { type AvatarProps } from './Avatar'

export interface DataAvatarProps extends Omit<AvatarProps, 'color'> {
    color?: ThemeColorType
    defaultImage?: ReactElement | string
    imageSrc?: string
    avatarOpacity?: number
    className?: string
}

const DataAvatar = forwardRef<any, DataAvatarProps>(
    ({ imageSrc, defaultImage = null, color, ...rest }, ref) => {
        return (
            <Avatar
                ref={ref}
                src={imageSrc}
                alt="Avatar"
                {...rest}
            >
                {imageSrc ? null : defaultImage}
            </Avatar>
        )
    },
)

const shouldForwardProp = generateNotForwardedProps<DataAvatarProps>(['avatarOpacity'])

export default styled(DataAvatar, {
    shouldForwardProp,
})`
    ${({ imageSrc, color, avatarOpacity = 0.08, theme }) =>
        imageSrc
            ? ''
            : `
        border-radius: 50%;
        background: ${alpha(themeColor(color || theme.palette.primary.main, theme), avatarOpacity)};
        display: flex;
        justify-content: center;
        overflow: visible;
        align-items: center;
        & svg {
            color: ${themeColor(color || theme.palette.primary.main, theme)};
        }
`}
`
