import { type FC } from 'react'

import { type WithChildrenNode } from 'appTypes'
import Icons from 'assets/icons'
import { Box, Drawer, IconButton } from 'ui'

import { useSidebar } from '../SidebarContext'
import { FadeByValue } from '../common'

const MobileSidebarContainer: FC<WithChildrenNode> = ({ children }) => {
    const [snap, functions] = useSidebar()

    return (
        <Drawer
            open={Boolean(snap.content)}
            PaperProps={{
                sx: {
                    width: 355,
                    padding: '14px 20px',
                    boxSizing: 'border-box',
                    borderRadius: '0 8px 8px 0',
                },
            }}
            onClose={functions.close}
        >
            <Box
                display="flex"
                justifyContent="flex-end"
            >
                <IconButton
                    onClick={functions.close}
                    size="small"
                >
                    <Icons.MenuOpenOutlined
                        fontSize="inherit"
                        sx={{ color: (theme) => theme.palette.text.primary }}
                    />
                </IconButton>
            </Box>
            <FadeByValue value={snap.content}>
                <Box flexGrow={1}>{children}</Box>
            </FadeByValue>
        </Drawer>
    )
}

export default MobileSidebarContainer
