import { type FC } from 'react'

import { type BaseActionRenderParams } from 'core'
import { Link } from 'lib'
import { IconButton, SvgIcon, Tooltip } from 'ui'

const HeaderDesktopAction: FC<BaseActionRenderParams> = ({
    Icon,
    title = '',
    to,
    onClick,
    disabled,
    titleOnDisabled,
}) => {
    const content = (
        <Tooltip title={disabled && titleOnDisabled ? titleOnDisabled : title}>
            <span role="none">
                <IconButton
                    aria-label={typeof title === 'string' ? title : 'Action'}
                    size="small"
                    disabled={disabled}
                    onClick={disabled ? undefined : onClick}
                    sx={{ color: (theme) => theme.palette.text.primary }}
                >
                    <SvgIcon
                        inheritViewBox
                        component={Icon}
                        sx={{ width: '20px', height: '20px' }}
                    />
                </IconButton>
            </span>
        </Tooltip>
    )

    if (to && !disabled) {
        return <Link to={to}>{content}</Link>
    }

    return content
}

export default HeaderDesktopAction
