import { type ReactElement } from 'react'

import { type Identifier } from 'appTypes'
import { useOpenUtilityDrawer } from 'components'
import UtilityDrawerEditor from 'components/Drawer/UtilityDrawerEditor'
import { parseNumber, type Serializer } from 'core'
import { type LineItemsModel, type PurchaseOrderModel } from 'resources/purchaseOrders'

import LineItemsForm from './LineItemsForm'

interface LineItemsDrawerTogglerProps {
    children: (params: { onClick: () => void }) => ReactElement
    id?: Identifier
    resource: string
    poRecord: PurchaseOrderModel
}
export interface LineItemsDrawerTogglerExtra {
    poRecord: PurchaseOrderModel
}

const serializer: Serializer<LineItemsModel & { poType: PurchaseOrderModel['type'] }> = [
    { name: 'part', parse: ['emptyToNull', 'disableOnEdit'] },
    { name: 'price', parse: 'number' },
    {
        name: 'quantity',
        parse: (value, data) => {
            const _value = parseNumber(value)
            if (data.poType === 'CREDIT' && typeof _value === 'number' && _value > 0) {
                return _value * -1
            }
            return _value
        },
    },
]

const LineItemsDrawerToggler = ({
    children,
    id,
    resource,
    poRecord,
}: LineItemsDrawerTogglerProps) => {
    const open = useOpenUtilityDrawer()

    return children({
        onClick: () => {
            open({
                extraArgs: {
                    id,
                    resource: {
                        resource,
                        name: 'line-items',
                    },
                },
                extra: { poRecord } as LineItemsDrawerTogglerExtra,
                drawerArgs: {
                    title: id ? 'Edit Item' : 'Add Item',
                    renderWrapper: (params) => (
                        <UtilityDrawerEditor
                            {...params}
                            successMessage={id ? undefined : 'Line Item successfully added'}
                            serializer={serializer}
                        />
                    ),
                    renderContent: () => <LineItemsForm />,
                    renderBottomRight: (render) => render({ label: id ? undefined : 'ADD ITEM' }),
                },
            })
        },
    })
}
export default LineItemsDrawerToggler
