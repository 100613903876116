import { defaultStatuses, options, type StatusConfig } from 'resources/common'

import { type ReceiptItemModel, ReceiptItemStatusKeys } from '../types'

const source = 'type' as const satisfies keyof ReceiptItemModel

const getConfig = options.createConfig<StatusConfig<ReceiptItemStatusKeys>>([
    { ...defaultStatuses.closed, id: ReceiptItemStatusKeys.RECEIVE },
    { ...defaultStatuses.canceled, id: ReceiptItemStatusKeys.CANCEL },
])

const value = options.makeIconValue(getConfig)

const status = {
    source,
    label: 'Status',
    getConfig,
    value: (data: { type: ReceiptItemStatusKeys }) =>
        value(data?.type as ReceiptItemStatusKeys.RECEIVE | ReceiptItemStatusKeys.CANCEL),
}

export default status
