import Icons from 'assets/icons'
import { useTheme } from 'lib'
import { type SvgIcon } from 'ui'

import { WidgetPieChartBase } from '../../components'
import { type JobItemsCalculations, unitClarificationMap } from '../../components/types'

interface CostPerUnitChartProps extends JobItemsCalculations {
    disabled: boolean
    PieIcon?: typeof SvgIcon
}
const CostPerUnitChart = ({
    positiveTotal,
    PieIcon = Icons.AttachMoney,
    disabled,
    ...ratioData
}: CostPerUnitChartProps) => {
    const { palette } = useTheme()
    const total = positiveTotal

    return (
        <WidgetPieChartBase
            disabled={disabled}
            icon={PieIcon}
            data={unitClarificationMap.map((jobItem) => ({
                id: jobItem.label,
                value: ratioData[jobItem.total],
                color: palette.charts[jobItem.tint],
                total,
            }))}
        />
    )
}
export default CostPerUnitChart
