import { type ReactElement } from 'react'

import { useRecordContext } from 'react-admin'

import { type Identifier } from 'appTypes'
import { useOpenUtilityDrawer, UtilityDrawerEditor } from 'components'
import { type Serializer } from 'core'
import { shopFields } from 'resources/shops'
import { type ShopModel } from 'resourcesBase'

import { type PtLaborModel } from '../types'
import { ptLaborPreferencesResource } from '../utils'

import { PTLaborForm } from './PTLaborForm'

export interface PTLaborDrawerTogglerProps {
    children: (open: () => void) => ReactElement
    resource: string
    id?: Identifier
}

const PTLaborDrawerToggler = ({ children, id, resource }: PTLaborDrawerTogglerProps) => {
    const open = useOpenUtilityDrawer()
    const record = useRecordContext<ShopModel>()

    return children(() => {
        open({
            drawerArgs: {
                title: id ? 'Edit Labor Sale Rate' : 'Create Labor Sale Rate',
                renderWrapper: (params) => (
                    <UtilityDrawerEditor<
                        PtLaborModel & { [shopFields.hourlyLaborRate.source]: number }
                    >
                        {...params}
                        serializer={ptLaborSerializer}
                        defaultValues={{
                            [shopFields.hourlyLaborRate.source]: record.laborHourlyRate,
                        }}
                    />
                ),
                renderContent: () => <PTLaborForm />,
            },
            extraArgs: {
                id,
                resource: {
                    resource,
                    name: ptLaborPreferencesResource.name,
                },
            },
        })
    })
}

export default PTLaborDrawerToggler

const ptLaborSerializer: Serializer<PtLaborModel> = [
    'name',
    { name: 'price', parse: 'number' },
    { name: 'isDefault', parse: 'boolean' },
]
