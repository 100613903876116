import { type ReactElement } from 'react'

import { type Identifier } from 'appTypes'
import { UtilityDrawerEditor, useOpenUtilityDrawer } from 'components'
import { type Serializer } from 'core'

import { type PtServiceModel } from '../types'
import { ptServicesResourceName } from '../utils'

import PtServiceForm from './PtServiceForm'

export interface ServiceProps {
    children: (open: () => void) => ReactElement
    resource: string
    id?: Identifier
}

const PtServiceDrawerToggler = ({ children, id, resource }: ServiceProps) => {
    const open = useOpenUtilityDrawer()

    return children(() => {
        open({
            drawerArgs: {
                title: id ? 'Edit Service Tier' : 'Create Service Tier',
                renderWrapper: (params) => (
                    <UtilityDrawerEditor
                        {...params}
                        serializer={ptServicesSerializer}
                    />
                ),
                renderContent: () => <PtServiceForm />,
            },
            extraArgs: {
                id,
                resource: {
                    resource,
                    name: ptServicesResourceName,
                },
            },
        })
    })
}

export default PtServiceDrawerToggler

const ptServicesSerializer: Serializer<PtServiceModel> = [
    'name',
    { name: 'markupPercent', parse: 'number' },
    { name: 'isDefault', parse: 'boolean' },
]
