import { type FC, type ReactElement } from 'react'

import { type Identifier } from 'appTypes'
import { UtilityDrawerEditor, useOpenUtilityDrawer } from 'components'
import { NotificationMessage, type Serializer } from 'core'
import { useCreateResourcePath } from 'core/resource'

import { type CustomerModel } from '../types'
import { customersResource } from '../utils'

import { CustomersForm } from './CustomersForm'

interface CustomersDrawerTogglerProps {
    children: (open: () => void) => ReactElement
    id?: Identifier
    onSuccess?: (record: CustomerModel) => void
    shortSuccessMessage?: boolean
}
const customerSerializer: Serializer<CustomerModel> = [
    'name',
    {
        name: 'logo',
        parse: 'file',
    },
    'phone',
    { name: 'email', parse: 'email' },
    { name: 'website', parse: 'lowerCase' },
    'address',
    'address2',
    { name: 'country', parse: 'emptyToNull' },
    'city',
    'state',
    'zipCode',
    'taxId',
    'nationalAccount',
    'dotNumber',
    {
        name: 'paymentMethod',
        parse: 'emptyToNull',
    },
    {
        name: 'paymentTerm',
        parse: 'emptyToNull',
    },
    { name: 'tags', parse: 'emptyToNull' },
]
export const CustomersDrawerToggler: FC<CustomersDrawerTogglerProps> = ({
    children,
    id,
    onSuccess,
    shortSuccessMessage,
}) => {
    const open = useOpenUtilityDrawer()
    const createPath = useCreateResourcePath()
    return children(() => {
        open({
            drawerArgs: {
                title: id ? 'Edit Customer' : 'Create Customer',
                renderWrapper: (params) => (
                    <UtilityDrawerEditor
                        {...params}
                        onSuccess={onSuccess}
                        serializer={customerSerializer}
                        successMessage={({ defaultMessages, response }) => {
                            if (id) {
                                return defaultMessages.updated
                            }

                            if (shortSuccessMessage) {
                                return defaultMessages.created
                            }

                            const customer = response as CustomerModel

                            return {
                                title: defaultMessages.created,
                                message: (
                                    <NotificationMessage.Navigate
                                        to={createPath({
                                            resource: customersResource.resource,
                                            type: 'edit',
                                            id: customer.id,
                                        })}
                                    >
                                        {customer.name}
                                    </NotificationMessage.Navigate>
                                ),
                            }
                        }}
                    />
                ),
                renderContent: () => <CustomersForm />,
            },
            extraArgs: {
                id,
                resource: customersResource,
            },
        })
    })
}
