import images from 'assets/images'

import DVIRPageBase from './DVIRPageBase'

const DVIRSupported = () => {
    return (
        <DVIRPageBase
            image={images.image3}
            title="Everything Looks Good!"
            subtitle={
                <>
                    No defects reported at this time.
                    <br />
                    We'll keep you informed if that changes.
                </>
            }
        />
    )
}

export default DVIRSupported
