import { defaultPriorities, options, type PriorityConfig } from 'resources/common'

import { IssuePriorityKeys, type IssueModel } from '../types'

const priorities = [
    { ...defaultPriorities.HIGH, id: IssuePriorityKeys.HIGH },
    { ...defaultPriorities.MEDIUM, id: IssuePriorityKeys.MEDIUM },
    { ...defaultPriorities.LOW, id: IssuePriorityKeys.LOW },
] satisfies PriorityConfig<IssuePriorityKeys>[]

const getConfig = options.createConfig<PriorityConfig<IssuePriorityKeys>>(priorities)

const value = options.makeIconValue(getConfig)

const source: keyof IssueModel = 'priority'

const priority = {
    source,
    getConfig,
    label: 'Priority',
    choices: options.createIdChoices(priorities),
    value: (record: Pick<IssueModel, 'priority'>) => value(record.priority),
    inputValue: options.makeIdValue(value),
}

export default priority
