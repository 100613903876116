import { type NoInfer } from 'appTypes'
import { type Column, type ListFilterChoice, type DataListDetails } from 'components'
import { uppercaseFormat } from 'utils'

import { type UnitModel } from '../types'

const source = 'licensePlate' satisfies keyof UnitModel

const label = 'License Plate'

const tableColumn = <Source extends string = typeof source>({
    id,
    dataToValue,
}: {
    id?: Source
    dataToValue: (data) => UnitModel[typeof source]
}) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: label,
        renderCell: ({ row }) => uppercaseFormat(dataToValue(row)),
    }) as const satisfies Column

const dataCardRow = <Source extends string = typeof source>({
    id,
    dataToValue,
}: {
    id?: Source
    dataToValue: (data) => UnitModel[typeof source]
}) =>
    ({
        source: (id || source) as NoInfer<Source>,
        label,
        render: (_, data) => uppercaseFormat(dataToValue(data)),
    }) as const satisfies DataListDetails<any, any>

const sort = <Source extends string = typeof source>() =>
    ({
        id: source as NoInfer<Source>,
        label,
    }) as const

const filter = <Source extends string = typeof source>({ id }: { id?: Source } = {}) =>
    ({
        id: id || source,
        label,
    }) as const satisfies ListFilterChoice

export const licensePlate = {
    source,
    label,
    tableColumn,
    dataCardRow,
    sort,
    filter,
}
