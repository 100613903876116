import { type ListControllerProps } from 'react-admin'
import { useFormContext } from 'react-hook-form'

import ListFilterItem from './ListFilterItem'
import { type ListFilterChoices } from './ListFilterSourceInput'

export interface FilterConfig<RecordType = any> {
    defaultValues?: RecordType
    filters: ListFilterChoices<RecordType>
    excludeFields?: string[]
}

export interface ListFilterFormProps extends ListControllerProps {
    filtersCfg?: FilterConfig
    filterKey2source: {
        [key: string]: string
    }
    setFilterKey2source: any
    isFormEmpty: boolean
}

export const couldSourceBeRange = (source) => source.endsWith('Min') || source.endsWith('Max')
export const getSourceNoRangeSfx = (source) => source.slice(0, -3)

export const getSourceFilterCfg = (filtersCfg, source) => {
    let currentFilterCfg
    for (const filter of filtersCfg.filters) {
        if (filter?.id === source) {
            currentFilterCfg = { ...filter }
            break
        }
    }
    return currentFilterCfg || {}
}

const ListFilterForm = ({
    filtersCfg,
    filterKey2source,
    setFilterKey2source,
    isFormEmpty,
}: ListFilterFormProps) => {
    const { unregister, getValues, setValue } = useFormContext()

    const filters = Object.entries(filterKey2source).map((keySource) => {
        const [key, source] = keySource

        const currentFilterCfg = getSourceFilterCfg(filtersCfg, source)

        delete currentFilterCfg.id

        const registerFilterItem = (currentSource, currentSourceKey) => {
            filterKey2source[key] = currentSource
            setFilterKey2source({ ...filterKey2source })

            // clean orphaned form fields/values
            // this is a problem in currentSource input options and when applying filter
            const formValues = getValues()
            const allSources = Object.values(formValues).filter(
                (v) => typeof v === 'string' && v !== formValues[currentSourceKey],
            ) // others are arrays
            const entries = Object.entries(formValues).filter(
                ([inputKey, inputValue]) => !['string', 'number'].includes(typeof inputValue),
            )
            for (const [inputKey] of entries) {
                if (!allSources.includes(inputKey)) {
                    setValue(inputKey, undefined)
                }
            }
        }
        const removeFilterItem = () => {
            delete filterKey2source[key]
            setFilterKey2source({ ...filterKey2source })

            const formValues = getValues()
            if (formValues[key + '_source'] && formValues[source] && formValues[source.length]) {
                // when source.value is chosen, and then unregistered, Autocomplete has an error.
                unregister([key + '_source', source])
            } else if (formValues[key + '_source']) {
                setValue(key + '_source', undefined)
                setValue(source, undefined)
            }
        }

        return (
            <ListFilterItem
                key={key}
                filterKey2source={filterKey2source}
                inputKey={key}
                {...currentFilterCfg}
                source={source}
                registerFilterItem={registerFilterItem}
                removeFilterItem={removeFilterItem}
                sourceChoices={filtersCfg.filters}
                isFormEmpty={isFormEmpty}
            />
        )
    })
    return <>{filters}</>
}

export default ListFilterForm
