const colors = {
    gray: '#f6f8fb',

    white: '#ffffff',

    grey: {
        50: '#e9ecf2',
        100: '#c9d0de',
        200: '#a5b1c8',
        300: '#8191b1',
        400: '#667aa1',
        500: '#4b6290',
        600: '#445a88',
        700: '#3b507d',
        800: '#334673',
        900: '#233461',
    },

    blue: {
        50: '#e5efff',
        100: '#bfd7ff',
        200: '#94bcff',
        300: '#69a1ff',
        400: '#498dff',
        500: '#2979ff',
        600: '#2471ff',
        700: '#1f66ff',
        800: '#195cff',
        900: '#0f49ff',
    },

    red: {
        50: '#fde8e8',
        100: '#fbc5c5',
        200: '#f99f9f',
        300: '#f67979',
        400: '#f45c5c',
        500: '#f23f3f',
        700: '#ee3131',
        600: '#f03939',
        800: '#ec2929',
        900: '#e81b1b',
    },

    lightGreen: {
        50: '#e4f9f1',
        100: '#bcf0dd',
        200: '#8fe6c6',
        300: '#62dcaf',
        400: '#40d59d',
        500: '#1ecd8c',
        600: '#1ac884',
        700: '#16c179',
        800: '#12ba6f',
        900: '#0aae5c',
    },

    deepRed: {
        50: '#f6e7e7',
        100: '#e8c3c3',
        200: '#d99b9b',
        300: '#ca7272',
        400: '#be5454',
        500: '#b33636',
        600: '#ac3030',
        700: '#a32929',
        800: '#9a2222',
        900: '#8b1616',
        A50: '#ffdfdf',
        A100: '#ffc0c0',
        A400: '#ff5a5a',
        A700: '#ff4141',
    },

    orange: {
        50: '#fff1e0',
        100: '#feddb3',
        200: '#fdc680',
        300: '#fcaf4d',
        400: '#fc9d26',
        500: '#fb8c00',
        600: '#fa8400',
        700: '#fa7900',
        800: '#f96f00',
        900: '#f85c00',
    },

    soldMain: '#9429ff',
    lostMain: '#f23fa0',
    emptyMain: 'rgba(75, 98, 144, 0.3)',
}

export default colors
