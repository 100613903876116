import { forwardRef } from 'react'

import { Link, type LinkProps } from '@mui/material'

const Anchor: typeof Link = forwardRef((props: LinkProps, ref) => (
    <Link
        role="button"
        variant="inherit"
        component={props.href ? undefined : 'span'}
        ref={ref}
        {...props}
    />
))

export default Anchor
export { type LinkProps as AnchorProps } from '@mui/material'
