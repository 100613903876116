import { type FC, type ReactElement } from 'react'

import { customInputIntegerNonNegativeSpacedMask, TextInput } from 'components/inputs'
import { Stack, formHelperTextClasses, Grid, GridContainerColumns } from 'ui'

import { type ListFilterValueInputProps } from './ListFilterValueInput'

interface PeriodInputProps {
    source: string
    label: string
    defaultValue?: string
    maxValueLength?: number
}

const PeriodInput: FC<PeriodInputProps> = ({
    source,
    label,
    defaultValue,
    maxValueLength: maxValueLenght = 15,
}) => {
    return (
        <Grid
            item
            xs={6}
        >
            <TextInput
                source={source}
                {...customInputIntegerNonNegativeSpacedMask(maxValueLenght)}
                label={label}
                helperText={false}
                defaultValue={defaultValue}
                sx={{
                    [`& .${formHelperTextClasses.root}`]: {
                        display: 'none !important',
                    },
                }}
            />
        </Grid>
    )
}

export interface PeriodRangeFilterProps extends ListFilterValueInputProps {
    bottomContent?: ReactElement
    inputMinProps?: Partial<PeriodInputProps>
    inputMaxProps?: Partial<PeriodInputProps>
}

const PeriodRangeFilter: FC<PeriodRangeFilterProps> = ({
    source,
    bottomContent,
    inputMaxProps,
    inputMinProps,
}) => {
    return (
        <Stack
            gap="16px"
            spacing={0}
        >
            <GridContainerColumns>
                <PeriodInput
                    source={source + 'Min'}
                    label="From"
                    {...inputMinProps}
                />
                <PeriodInput
                    source={source + 'Max'}
                    label="To"
                    {...inputMaxProps}
                />
            </GridContainerColumns>
            {bottomContent}
        </Stack>
    )
}

export default PeriodRangeFilter
