import { formatDate } from 'lib'
import { type MemberModel } from 'resourcesBase'

interface Data extends Pick<MemberModel, 'created'> {}

const value = (data: Data) => {
    return formatDate(data.created, (dateFormats) => dateFormats.shortenedDateTime)
}

const source = 'created' as const satisfies keyof MemberModel

const invitationCreated = {
    source,
    label: 'Invitation Created',
    value,
}

export default invitationCreated
