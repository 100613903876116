import images from 'assets/images'
import { landingPageUrl, supportEmail } from 'configs'
import { BoxContainer, CenterText, Typography, Button, Anchor } from 'ui'

import { AuthBody } from '../components'

const Deactivated = () => {
    return (
        <AuthBody>
            <CenterText>
                <Typography
                    variant="h6"
                    mb="16px"
                >
                    Deactivated Company
                </Typography>
                <Typography variant="body1">
                    To reactivate your company account, <br /> please contact us at
                    <Anchor href={`mailto:${supportEmail}`}> {supportEmail}</Anchor>.
                </Typography>
                <BoxContainer
                    justifyContent="center"
                    mt="30px"
                    mb="40px"
                >
                    <img
                        src={images.deactivated}
                        alt="deactivated"
                    />
                </BoxContainer>
                <Button
                    variant="text"
                    size="large"
                    href={landingPageUrl.root}
                >
                    Back to website
                </Button>
            </CenterText>
        </AuthBody>
    )
}

export default Deactivated
