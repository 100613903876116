import { useMediaQuery } from 'lib'

import { DesktopSidebar } from './desktop'
import { MobileSidebar } from './mobile'

const Sidebar = () => {
    const match = useMediaQuery((theme) => theme.breakpoints.up(theme.props.mobileViewBreakpoint))

    if (match) {
        return <DesktopSidebar />
    }

    return <MobileSidebar />
}

export default Sidebar
