import { type NoInfer } from 'appTypes'
import {
    costMaskParams,
    ListFilterRangeInput,
    type Column,
    type ListFilterChoice,
} from 'components'
import { formatDuration } from 'lib'

import { type WorkOrderModel } from '../types'

const source = 'duration' as keyof WorkOrderModel

const label = 'WO Duration'

const tableColumn = <Source extends string = typeof source>({ id }: { id?: Source }) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: label,
        renderCell: ({ row }) => formatDuration(row.durationSeconds, true),
    }) as const satisfies Column

const sort = <Source extends string = typeof source>() =>
    ({
        id: source as NoInfer<Source>,
        label,
    }) as const

const filter = () =>
    ({
        id: source,
        label,
        filterType: 'range',
        renderComponent: (props) => (
            <ListFilterRangeInput
                inputProps={costMaskParams}
                {...props}
            />
        ),
    }) as const satisfies ListFilterChoice

export const duration = {
    source,
    label,
    tableColumn,
    sort,
    filter,
}
