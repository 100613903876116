import { styled } from 'lib'
import { BoxContainer } from 'ui'

export const WidgetHeaderContainer = styled(BoxContainer)`
    height: 153px;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    position: relative;
    width: 100%;
`
