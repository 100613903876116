import { type FC } from 'react'

import { useShowContext, useTranslate } from 'react-admin'

import { InfoCard, InfoCardDetails, InfoCardHeader, resolveIntegerSpacedMask } from 'components'
import { formatDate, globalClassNames } from 'lib'
import { type UnitModel, type MeterModel, isUnitArchived, meterTypesConfig } from 'resources/units'
import { SvgIcon, BoxContainer, StyledElement, SectionTypography } from 'ui'

import { unitMeterActions } from '../actions'

import MeterSource from './MeterSource'

interface UnitMetersSectionCardProps {
    meter: MeterModel
}

const UnitMetersCard: FC<UnitMetersSectionCardProps> = ({ meter }) => {
    const { record: unit } = useShowContext<UnitModel>()
    const isArchived = isUnitArchived(unit)

    const translate = useTranslate()
    const MeterIcon = meterTypesConfig[meter.type].Icon
    return (
        <InfoCard>
            <InfoCardHeader
                record={meter}
                actionsDisabled={meter.source === 'WORK_ORDER'}
                actions={isArchived ? undefined : unitMeterActions}
                title={
                    <StyledElement sx={{ display: 'flex', width: '100%' }}>
                        <div>
                            <SectionTypography
                                variant="subtitle2"
                                mb="5px"
                                color="text"
                            >
                                <StyledElement as="span">
                                    {formatDate(meter.timestamp, 'MMM dd yyyy hh:mm a')}
                                </StyledElement>
                            </SectionTypography>
                            <SectionTypography
                                variant="tooltip"
                                mb="0"
                            >
                                <MeterSource meter={meter} />
                            </SectionTypography>
                        </div>
                    </StyledElement>
                }
            />
            <InfoCardDetails<MeterModel>
                details={[
                    {
                        label: (
                            <BoxContainer>
                                <SvgIcon sx={{ width: '14px !important', height: '14px' }}>
                                    {MeterIcon && <MeterIcon />}
                                </SvgIcon>
                                <div className={globalClassNames.ellipsis}>
                                    {translate(meter.type)}
                                </div>
                            </BoxContainer>
                        ),
                        source: ({ value }) => resolveIntegerSpacedMask(value),
                    },
                ]}
                propRecord={meter}
            />
        </InfoCard>
    )
}
export default UnitMetersCard
