import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import { type AuthStore, ResourceWithClearEffect } from 'core'
import { poResource } from 'resources/purchaseOrders'

import { PurchaseOrdersList, VendorPoList } from './List'
import { PurchaseOrderShow } from './Show'

const PurchaseOrders: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        return (
            <ResourceWithClearEffect
                name={poResource}
                list={auth.companySettings.hasInventory ? PurchaseOrdersList : VendorPoList}
                edit={PurchaseOrderShow}
            />
        )
    }),
)
export default PurchaseOrders
