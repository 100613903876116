import { Routes, Route, Navigate } from 'lib'

import AllIntegrations from './AllIntegrations'
import IntegrationsUnitList from './IntegrationsUnitList'

const Integrations = () => {
    return (
        <Routes>
            <Route
                index
                element={<AllIntegrations />}
            />

            <Route
                path=":provider"
                element={<IntegrationsUnitList />}
            />

            <Route
                path="*"
                element={<Navigate to="" />}
            />
        </Routes>
    )
}
export default Integrations
