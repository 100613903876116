import { type NoInfer } from 'appTypes'
import { type Column } from 'components'
import { urls } from 'configs'
import { LinkButton } from 'ui'
import { pathJoin } from 'utils'

import { type InvoiceModel } from '../types'

const source = 'number' as const satisfies keyof InvoiceModel
const label = 'Invoice Number'

interface Data extends Pick<InvoiceModel, 'number' | 'id'> {}

export const linkValue = (record: Data) => (
    <LinkButton to={pathJoin(urls.invoices, record.id)}>{record.number}</LinkButton>
)
const sort = <Source extends string = typeof source>() =>
    ({
        id: source as NoInfer<Source>,
        label,
    }) as const

const tableColumn = <Source extends string = typeof source>() =>
    ({
        field: source as NoInfer<Source>,
        headerName: label,
        renderCell: (cell) => linkValue(cell.row),
    }) as const satisfies Column

const invoiceNumber = {
    source,
    label,
    sort,
    tableColumn,
}

export default invoiceNumber
