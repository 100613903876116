import { type FC, type ReactElement } from 'react'

import { type Identifier } from 'appTypes'
import {
    UtilityDrawerEditor,
    type UtilityDrawerEditorProps,
    useOpenUtilityDrawer,
} from 'components'
import { urls } from 'configs'
import { type Serializer, NotificationMessage } from 'core'
import { pathJoin } from 'utils'

import { type VendorPartModel } from '../types'
import { partsResource } from '../utils'

import { VendorPartForm } from './VendorPartForm'

interface Props extends Pick<UtilityDrawerEditorProps, 'onSuccess'> {
    children: (params: { onClick: () => void }) => ReactElement
    id?: Identifier
    title?: string
    shortSuccessMessage?: boolean
}

export const VendorPartDrawerToggler: FC<Props> = ({
    children,
    id,
    onSuccess,
    shortSuccessMessage,
}) => {
    const open = useOpenUtilityDrawer()

    return children({
        onClick: () => {
            open({
                extraArgs: {
                    id,
                    resource: id
                        ? partsResource
                        : {
                              name: partsResource.name,
                              resource: partsResource.resource + '/vendor',
                          },
                },
                drawerArgs: {
                    title: id ? 'Edit Details' : 'Create Vendor Part',
                    renderWrapper: (params) => (
                        <UtilityDrawerEditor
                            {...params}
                            onSuccess={onSuccess}
                            serializer={vendorPartSerializer}
                            successMessage={({ defaultMessages, response }) => {
                                if (id) {
                                    return defaultMessages.updated
                                }
                                if (shortSuccessMessage) {
                                    return defaultMessages.created
                                }
                                const vendorPart = response as VendorPartModel
                                return {
                                    title: defaultMessages.created,
                                    message: (
                                        <NotificationMessage.Navigate
                                            to={pathJoin(urls.vendorParts, vendorPart.id)}
                                        >
                                            {vendorPart.number}
                                        </NotificationMessage.Navigate>
                                    ),
                                }
                            }}
                        />
                    ),
                    renderContent: () => <VendorPartForm id={id} />,
                },
            })
        },
    })
}

export const vendorPartSerializer: Serializer<VendorPartModel> = [
    'number',
    'description',
    { name: 'image', parse: 'file' },
    'unitOfMeasure',
    'positionApplicable',
    'serializedPart',
    'manufacturerPartNumber',
    { name: 'component', parse: 'emptyToNull' },
    { name: 'manufacturer', parse: 'emptyToNull' },
    { name: 'tags', parse: 'emptyToNull' },
]
