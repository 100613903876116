import { type ReactElement, type FC } from 'react'

import {
    type SxProps,
    styled,
    type ThemeColorType,
    generateNotForwardedProps,
    themeColor,
} from 'lib'
import { BoxContainer, Tooltip, StyledElement } from 'ui'

interface BarArrowTooltipProps {
    children: ReactElement
    title: string | ReactElement
}
const BarArrowTooltip: FC<BarArrowTooltipProps> = ({ children, title }) => {
    return (
        <Tooltip
            title={title}
            arrow
            PopperProps={{
                sx: {
                    '& .MuiTooltip-tooltip': {
                        left: '50%',
                    },
                    '& .MuiTooltip-arrow': {
                        left: '50% !important',
                        transform: 'translateX(-50%) !important',
                    },
                },
            }}
        >
            {children}
        </Tooltip>
    )
}

interface LineProps {
    opacity?: number
    width?: number
    bgColor: ThemeColorType
    className?: string
    tooltip?: string | ReactElement
}

const Line = styled(
    ({ className, tooltip }: LineProps) => {
        const content = <div className={className} />

        if (tooltip) {
            return <BarArrowTooltip title={tooltip}>{content}</BarArrowTooltip>
        }

        return content
    },
    {
        shouldForwardProp: generateNotForwardedProps<LineProps>(['bgColor', 'opacity', 'width']),
    },
)<LineProps>(({ theme, bgColor, opacity = 1, width = 0 }) => ({
    backgroundColor: themeColor(bgColor, theme),
    opacity,
    height: '4px',
    position: 'absolute',
    left: '0%',
    top: '50%',
    transform: 'translateY(-50%)',
    width: `${width}%`,
}))

interface BarContent2Props {
    bgColor?: ThemeColorType
    lines?: LineProps[]
    value: number
    width?: number
    pointerReflectColor?: ThemeColorType
    sx?: SxProps
    tooltip?: string
    barOpacity?: number
}

const defaultBgColor: ThemeColorType = (theme) => theme.palette.action.disabled

export const LineSlider: FC<BarContent2Props> = ({
    bgColor = defaultBgColor,
    lines,
    value,
    width = 100,
    tooltip = null,
    sx,
    barOpacity = 0.15,
}) => {
    return (
        <BoxContainer
            width="100%"
            height="12px"
            position="relative"
            sx={sx}
        >
            <Line
                bgColor={bgColor}
                opacity={barOpacity}
                width={width}
                tooltip={tooltip}
            />
            {lines?.map((line, index) => (
                <Line
                    {...line}
                    key={index}
                />
            ))}
            <StyledElement
                sx={{
                    height: '12px',
                    minWidth: '2px',
                    position: 'absolute',
                    background: (theme) =>
                        `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), ${themeColor(
                            bgColor,
                            theme,
                        )}`,
                    left: `${isNaN(value) || value < 0 ? 0 : value}%`,
                }}
            />
        </BoxContainer>
    )
}
