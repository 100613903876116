import { type FC, Fragment, type ReactElement } from 'react'

import { useListContext } from 'react-admin'

import Icons from 'assets/icons'
import { ViewHeader, type InfoCardHeaderProps, ListTotalBadge } from 'components'
import { useResource } from 'core'
import { DeleteOneAction } from 'core/actions'
import { LineItemTypeKeys } from 'resources/lineItems'
import { partFields } from 'resources/parts'
import {
    deleteJobActionParams,
    useIsVenderWo,
    WoJobDrawerToggler,
    type JobModel,
} from 'resources/workOrders'
import {
    BoxContainer,
    GridContainer,
    type GridItemLayoutProps,
    GridItemLayout,
    GridBreak,
} from 'ui'

import WorkOrderJobCard, {
    type WorkOrderJobCardActions,
    type WorkOrderJobCardItemsActions,
} from './WorkOrderJobCard'
import {
    LaborCreateDrawerToggler,
    PartCreateDrawerToggler,
    VendorWorkOrderExtraActionsDrawerToggler,
} from './WorkOrderJobCard/components'
import WorkOrderJobsEmpty from './WorkOrderJobsEmpty'
import { AddVendorPartMenu } from './components'

const actions: WorkOrderJobCardActions = (job, { children, resource }) => [
    <WoJobDrawerToggler
        id={job.id}
        key="edit"
        children={(open) => children({ title: 'Edit', Icon: Icons.Edit, onClick: open })}
    />,
    <DeleteOneAction
        {...deleteJobActionParams(job.id, resource)}
        key="delete"
        id={job.id}
        resource={resource}
        children={children}
    />,
]

interface WorkOrderJobsSectionProps
    extends InfoCardHeaderProps,
        Pick<GridItemLayoutProps, 'gridColumns'> {
    action: ReactElement
}

const WorkOrderJobsSection: FC<WorkOrderJobsSectionProps> = ({ action, gridColumns }) => {
    const jobsListContext = useListContext<JobModel>()
    const isVendorWorkOrder = useIsVenderWo()

    const itemActions: WorkOrderJobCardItemsActions = (
        job,
        { children, resource, listContext },
    ) => {
        return [
            isVendorWorkOrder ? (
                <AddVendorPartMenu
                    resource={resource}
                    listContext={listContext}
                    key={LineItemTypeKeys.PART}
                    renderToggler={(open) =>
                        children({
                            title: (
                                <BoxContainer
                                    justifyContent="space-between"
                                    component="span"
                                    width="140px"
                                >
                                    Add Part <Icons.ArrowRightOutlined fontSize="small" />
                                </BoxContainer>
                            ),
                            Icon: partFields.avatar.Icon,
                            onClick: open,
                            disableCloseOnClick: true,
                        })
                    }
                />
            ) : (
                <PartCreateDrawerToggler
                    key={LineItemTypeKeys.PART}
                    resource={resource}
                    listContext={listContext}
                    children={({ onClick }) =>
                        children({ title: 'Add Part', Icon: partFields.avatar.Icon, onClick })
                    }
                />
            ),
            <LaborCreateDrawerToggler
                key={LineItemTypeKeys.LABOR}
                resource={resource}
                listContext={listContext}
                job={job}
                children={({ onClick }) =>
                    children({
                        title: 'Add Labor',
                        Icon: Icons.ScheduleOutlined,
                        onClick,
                    })
                }
            />,
            <VendorWorkOrderExtraActionsDrawerToggler
                key={LineItemTypeKeys.SERVICE}
                resource={resource}
                listContext={listContext}
                title="Add Services"
                itemType={LineItemTypeKeys.SERVICE}
                children={({ onClick }) =>
                    children({
                        title: 'Add Services',
                        Icon: Icons.WorkOutlineOutlined,
                        onClick,
                    })
                }
            />,
            ...(isVendorWorkOrder
                ? [
                      <VendorWorkOrderExtraActionsDrawerToggler
                          key={LineItemTypeKeys.FEE}
                          resource={resource}
                          listContext={listContext}
                          title="Add Fee"
                          itemType={LineItemTypeKeys.FEE}
                          children={({ onClick }) =>
                              children({
                                  title: 'Add Fee',
                                  Icon: Icons.Fee,
                                  onClick,
                              })
                          }
                      />,
                      <VendorWorkOrderExtraActionsDrawerToggler
                          key={LineItemTypeKeys.TAX}
                          resource={resource}
                          listContext={listContext}
                          title="Add Tax"
                          itemType={LineItemTypeKeys.TAX}
                          children={({ onClick }) =>
                              children({
                                  title: 'Add Tax',
                                  Icon: Icons.PercentOutlined,
                                  onClick,
                              })
                          }
                      />,
                  ]
                : []),
        ]
    }
    const { data = [], total, isLoading } = jobsListContext

    const resource = useResource()

    if (isLoading) {
        return null
    }

    return (
        <div>
            <ViewHeader title="Jobs">
                <ViewHeader.Content>
                    <ListTotalBadge />
                </ViewHeader.Content>
                <ViewHeader.Content placement="rightMobile">{action}</ViewHeader.Content>
            </ViewHeader>
            {total ? (
                <GridContainer spacing={{ xs: 3, sm: 6 }}>
                    {data.map((job) => (
                        <Fragment key={job.id}>
                            <GridItemLayout>
                                <WorkOrderJobCard
                                    job={job}
                                    jobsListContext={jobsListContext}
                                    resource={resource}
                                    actions={actions}
                                    itemActions={itemActions}
                                />
                            </GridItemLayout>
                            <GridBreak />
                        </Fragment>
                    ))}
                </GridContainer>
            ) : (
                <WorkOrderJobsEmpty />
            )}
        </div>
    )
}

export default WorkOrderJobsSection
