import { type FC } from 'react'

import { formatMoney } from 'components'
import { ButtonBadge, InfoBadge, Stack } from 'ui'

import { type ComponentDate, useCostReportContext } from '../../../CostReportContext'
import { useComponentDrilldown, useCrossDrilldown, usePeriodDrilldown } from '../../../components'

import DataRow from './DataRow'

const CardView = () => {
    const { data, isLoading, selectedDate } = useCostReportContext()
    const crossDrilldown = useCrossDrilldown()
    const componentDrilldown = useComponentDrilldown()

    if (isLoading) {
        return null
    }

    return (
        <Stack gap="24px">
            <DataRow
                title="Total by Component"
                onComponentClick={(component) =>
                    componentDrilldown({ component, dateCompleted: selectedDate })
                }
                total={data.grandTotal}
            />
            {data.datesTillNow.map((date) => {
                return (
                    <DataRow
                        key={date.source}
                        title={date.date}
                        total={data.dateTotals[date.source]}
                        date={date}
                        onComponentClick={(component) =>
                            crossDrilldown({ component, dateCompleted: date.source })
                        }
                        renderTotal={renderTotal}
                    />
                )
            })}
        </Stack>
    )
}

export default CardView

const renderTotal = (total: number, date: ComponentDate) => {
    if (total == null) {
        return <InfoBadge badgeContent={<>{formatMoney(0)} Total</>} />
    }
    return (
        <MonthTotal
            total={total}
            date={date}
        />
    )
}

const MonthTotal: FC<{ total: number; date: ComponentDate }> = ({ total, date }) => {
    const periodDrilldown = usePeriodDrilldown()

    return (
        <ButtonBadge
            badgeContent={<>{formatMoney(total)} Total</>}
            onClick={() => periodDrilldown(date.source)}
        />
    )
}
