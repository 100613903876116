import { Pie } from '@nivo/pie'
import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import { InfoCard } from 'components'
import { useTheme } from 'lib'
import { SliderWidgetSkeleton } from 'pages/Dashboard/components/WidgetBase/styled'
import { UnitDrawerToggler, isUnitArchived, unitSerializer, type UnitModel } from 'resources/units'
import { Anchor, Stack, ChartContainer, ChartCenterContent, Typography } from 'ui'

const allFields = unitSerializer.map((serializer) =>
    typeof serializer === 'object' ? serializer.name : serializer,
)
const fieldsLength = 19

const UnitProgress = () => {
    const record = useRecordContext<UnitModel>()
    const { palette } = useTheme()
    const isArchived = isUnitArchived(record)

    if (!record) {
        return <SliderWidgetSkeleton />
    }

    const filledFields = allFields.reduce((total, field) => {
        // no count for body
        if (field === 'body') {
            return total
        }
        if (record[field]) {
            return total + 1
        }
        return total
    }, 0)

    const allDone = fieldsLength === filledFields
    const emptyFieldsCount = fieldsLength - filledFields
    const clickableChart = isArchived || allDone

    const data = [
        {
            id: 'filled',
            value: filledFields,
            color: palette.success.main,
        },
        {
            id: 'empty',
            value: emptyFieldsCount,
            color: palette.charts.disable,
        },
    ]

    return (
        <InfoCard sx={{ height: '140px', padding: '20px' }}>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <div>
                    <Typography
                        variant="chartTitle"
                        color="text.secondary"
                        mb="22px"
                        display="block"
                    >
                        Unit Details Added
                    </Typography>
                    <Typography
                        variant="overline"
                        color="textPrimary"
                        component="div"
                        mb="8px"
                    >
                        <Typography
                            variant="h5"
                            component="span"
                        >
                            {filledFields}
                        </Typography>
                        <span>/{fieldsLength}</span>
                    </Typography>
                    {isArchived ? undefined : (
                        <UnitDrawerToggler
                            id={record?.id}
                            children={({ onClick }) => (
                                <Anchor
                                    variant="button"
                                    onClick={onClick}
                                >
                                    {allDone ? 'Edit' : 'Add More'}
                                </Anchor>
                            )}
                        />
                    )}
                </div>
                <UnitDrawerToggler
                    id={record?.id}
                    children={({ onClick }) => (
                        <ChartContainer
                            onClick={clickableChart ? null : onClick}
                            sx={{ cursor: clickableChart ? 'auto' : 'pointer' }}
                        >
                            <Pie
                                colors={{ datum: 'data.color' }}
                                width={80}
                                height={80}
                                data={data}
                                innerRadius={0.8}
                                padAngle={0}
                                cornerRadius={0}
                                borderWidth={0}
                                isInteractive={false}
                                enableArcLinkLabels={false}
                                enableArcLabels={false}
                                animate={false}
                                // layers={[ 'arcs', CustomLayerComponent ]}
                            />
                            <ChartCenterContent>
                                {allDone ? (
                                    <Icons.DoneAll
                                        width="24px"
                                        height="24px"
                                        color="success"
                                    />
                                ) : null}
                                <Typography
                                    variant="tooltip"
                                    display="block"
                                >
                                    {allDone ? (
                                        'All Done'
                                    ) : (
                                        <>
                                            {emptyFieldsCount} More
                                            <br />
                                            to Go
                                        </>
                                    )}
                                </Typography>
                            </ChartCenterContent>
                        </ChartContainer>
                    )}
                />
            </Stack>
        </InfoCard>
    )
}

export default UnitProgress

// const StyledText = styled('text')(({ theme }) => ({
//     ...theme.typography.tooltip,
// }))

// const CustomLayerComponent = (layerProps) => {
//     const { centerX, centerY } = layerProps;
//     console.log(layerProps)

//     return (
//         <>
//             <DoneAllIcon width="24px" height="24px" x={centerX} />
//             <StyledText
//                 x={centerX}
//                 y={centerY}
//                 textAnchor="middle"
//                 dominantBaseline="central"
//             >
//                 <tspan x={centerX} dy={-5}>tspan line 1</tspan>
//                 <tspan x={centerX} dy={10}>tspan line 2</tspan>
//             </StyledText>
//         </>
//     );
// };
