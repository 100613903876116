import { type ReactElement } from 'react'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import { basePermissions, renderOnPermission } from 'core/permissions'
import { useCreateResourcePath } from 'core/resource'
import { Link } from 'lib'

import { type BaseActionRenderParams } from '../Actions'

export interface EditRedirectInListActionProps {
    id: Identifier
    children: (params: Omit<BaseActionRenderParams, 'onClick'>) => ReactElement
    resource?: string
}

export const EditRedirectInListAction = renderOnPermission(
    ({ children, id, resource }: EditRedirectInListActionProps) => {
        const createPath = useCreateResourcePath()

        const content = children({
            Icon: Icons.Edit,
            title: 'View/Edit',
        })

        return <Link to={createPath({ type: 'edit', id, resource })}>{content}</Link>
    },
    basePermissions.retrieve,
)

export const editRedirectInListAction = (params: EditRedirectInListActionProps) => (
    <EditRedirectInListAction
        key="edit-redirect-action"
        {...params}
    />
)
