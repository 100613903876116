import { type ReactElement } from 'react'

import { type Identifier } from 'appTypes'
import { UtilityDrawerEditor, useOpenUtilityDrawer } from 'components'
import { type Serializer } from 'core'

import { type PtPartModel } from '../types'
import { ptPartResourceName } from '../utils'

import PtPartForm from './PtPartForm'

export interface PTPartDrawerTogglerProps {
    children: (open: () => void) => ReactElement
    resource: string
    id?: Identifier
}
const PTPartDrawerToggler = ({ children, id, resource }: PTPartDrawerTogglerProps) => {
    const open = useOpenUtilityDrawer()

    return children(() => {
        open({
            drawerArgs: {
                title: id ? 'Edit Parts Tier' : 'Create Parts Tier',
                renderWrapper: (params) => (
                    <UtilityDrawerEditor
                        {...params}
                        serializer={ptPartSerializer}
                    />
                ),
                renderContent: () => <PtPartForm />,
            },
            extraArgs: {
                id,
                resource: {
                    resource,
                    name: ptPartResourceName,
                },
            },
        })
    })
}
export default PTPartDrawerToggler

const ptPartSerializer: Serializer<PtPartModel> = ['name', { name: 'isDefault', parse: 'boolean' }]
