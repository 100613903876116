import { DistanceLabel } from 'components'

export const formatValue = (value: number) => {
    return (
        <DistanceLabel
            variant="abbr"
            value={value ? Math.floor(value) : 0}
        />
    )
}
