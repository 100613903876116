import { type FC } from 'react'

import Icons from 'assets/icons'
import { IconElement, Tooltip } from 'ui'

const TitleTooltip: FC<{ tooltipText: string }> = ({ tooltipText }) => {
    return (
        <Tooltip title={tooltipText}>
            <IconElement
                size="16px"
                color={(theme) => theme.palette.text.disabled}
                component={Icons.InfoOutlined}
            />
        </Tooltip>
    )
}

export default TitleTooltip
