import { styled } from 'lib'
import { Box } from 'ui/layouts'

const centerContentClassName = 'chart-content'

export const ChartContainer = styled(Box)`
    position: relative;
    z-index: 1;
    & > div:not(.${centerContentClassName}) > svg {
        display: block;
    }
`

export const ChartCenterContent = styled(Box)`
    position: absolute;
    left: 50%;
    top: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
`

ChartCenterContent.defaultProps = {
    className: centerContentClassName,
}
