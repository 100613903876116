import { type PropsWithChildren } from 'react'

import { Responsive, WidthProvider } from 'react-grid-layout'

import { type Breakpoint, styled } from 'lib'

import { useWidgetLayoutContext } from '../WidgetsLayoutContext'

const GridLayout = WidthProvider(Responsive)

export const gridBreakpoints: { [key in Breakpoint]?: number } = {
    xs: 0,
    sm: 599,
    md: 918,
    lg: 1319,
}
// export const gridWidgetMinWidth: { [key in Breakpoint]?: number } = {
//     xs: 0,
//     sm: 599,
//     md: 918,
//     lg: 1319,
// }
export const gridColumns: { [key in keyof typeof gridBreakpoints]?: number } = {
    xs: 2,
    sm: 4,
    md: 6,
    lg: 8,
}

const StyledGridLayout = styled(GridLayout)`
    .react-grid-layout {
        position: relative;
    }
    .react-grid-item:hover {
        & .WidgetDrag {
            display: block;
        }
        & .WidgetResize {
            display: block;
        }
    }
    .react-grid-item.react-grid-placeholder {
        background-color: #8191b1;
        border-radius: 4px;
    }
    .react-resizable-handle.react-resizable-handle-se {
        opacity: 0;
        z-index: 3;
    }
`

const DashboardGridLayout = ({ children }: PropsWithChildren) => {
    const { watch, layouts } = useWidgetLayoutContext()

    return (
        <StyledGridLayout
            className="layout"
            layouts={layouts}
            onLayoutChange={watch}
            breakpoints={gridBreakpoints}
            draggableHandle=".WidgetDrag"
            containerPadding={[0, 0]}
            onResize={(layout, oldLayoutItem, layoutItem, placeholder) => {
                if (layoutItem.h === 2 && layoutItem.w === 1) {
                    layoutItem.w = 2
                    placeholder.w = 2
                }
            }}
            cols={gridColumns}
            rowHeight={147}
        >
            {children}
        </StyledGridLayout>
    )
}
export default DashboardGridLayout
