import { type FC, type ReactNode, useState } from 'react'

import { isObjectEmpty } from 'utils'

import { CollapsibleContext } from './CollapsibleContext'

export interface CollapsibleContextProviderProps {
    defaultOpen?: { [key: string]: boolean } | boolean
    sameParent?: boolean
    children: ReactNode
    disabled?: boolean
}

const defaultName = 'default'

const CollapsibleContextProvider: FC<CollapsibleContextProviderProps> = ({
    children,
    defaultOpen,
    sameParent,
    disabled,
}) => {
    const [expanded, setExpanded] = useState<{ [key: string]: boolean }>(() => {
        if (!defaultOpen) {
            return {}
        }

        if (typeof defaultOpen === 'boolean') {
            return { [defaultName]: true }
        }

        return defaultOpen
    })

    const toggleExpand = (name: string = defaultName) => {
        if (sameParent) {
            setExpanded((oldExpand) => {
                if (oldExpand[name]) {
                    return {}
                }
                return { [name]: true }
            })
            return
        }
        setExpanded(({ [name]: isOpen, ...oldExpanded }) => {
            if (isOpen) {
                return oldExpanded
            }
            return {
                ...oldExpanded,
                [name]: true,
            }
        })
    }

    const close = () => setExpanded({})

    return (
        <CollapsibleContext.Provider
            value={{
                isExpanded: (name: string = defaultName) => Boolean(expanded[name]),
                toggleExpand,
                close,
                isEmpty: isObjectEmpty(expanded),
                disabled,
            }}
        >
            {children}
        </CollapsibleContext.Provider>
    )
}
export default CollapsibleContextProvider
