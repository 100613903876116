import { type ReactNode } from 'react'

import { styled } from 'lib'

interface Props {
    offsetTop?: string
    offsetBottom?: string
    children: ReactNode
}

const InfoCardRows = styled('div', {
    shouldForwardProp: (prop: keyof Props) => prop !== 'offsetTop' && prop !== 'offsetBottom',
})<Props>`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: ${({ offsetTop }) => offsetTop ?? '10px'};
    margin-bottom: ${({ offsetBottom }) => offsetBottom ?? '10px'};
`

export default InfoCardRows
