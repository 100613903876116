import { type FC, useRef, useState } from 'react'

import { useListContext } from 'react-admin'

import Icons from 'assets/icons'
import { ClickAwayListener, Popper, Button } from 'ui'

import GridColumnsPanel from './DatagridColumnsPanel'

export const columnsButtonId = 'listColumnsButton'

const ColumnsButton: FC = () => {
    const anchorEl = useRef()
    const [open, setOpen] = useState(false)
    const { data } = useListContext()

    return (
        <>
            <Button
                variant="text"
                disabled={!data || !data.length}
                id={columnsButtonId}
                ref={anchorEl}
                startIcon={<Icons.ViewWeekOutlined />}
                onClick={() => {
                    setOpen(!open)
                }}
                children="Manage Columns"
                color="secondary"
                sx={{ display: 'flex', marginLeft: '8px', marginRight: 'auto' }}
            />
            <Popper
                anchorEl={anchorEl.current}
                open={open}
                sx={{ zIndex: 1300 }}
            >
                <ClickAwayListener
                    onClickAway={() => {
                        setOpen(false)
                    }}
                >
                    <GridColumnsPanel />
                </ClickAwayListener>
            </Popper>
        </>
    )
}

export default ColumnsButton
