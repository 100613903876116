import { type FC } from 'react'

import {
    GridColumnMenuContainer,
    type GridColumnMenuProps,
    GridColumnPinningMenuItems,
    useGridApiContext,
} from '@mui/x-data-grid-premium'

import { MenuItem } from 'ui'

const ColumnMenu: FC<GridColumnMenuProps> = (props) => {
    const { hideMenu, currentColumn, open } = props
    const apiRef = useGridApiContext()

    const handleHideColumn = (e) => {
        apiRef.current.updateColumns([{ field: currentColumn.field, hide: true }])
        hideMenu(e)
    }

    return (
        <GridColumnMenuContainer
            hideMenu={hideMenu}
            currentColumn={currentColumn}
            open={open}
        >
            {currentColumn.hideable ? (
                // if HideGridColMenuItem is used, it becomes disabled when only 1 hideable visible column remains
                // <HideGridColMenuItem
                //     column={currentColumn}
                //     onClick={hideMenu}
                // />
                <MenuItem onClick={handleHideColumn}>Hide</MenuItem>
            ) : null}
            {currentColumn.pinnable ? (
                <GridColumnPinningMenuItems
                    column={currentColumn}
                    onClick={hideMenu}
                />
            ) : null}
        </GridColumnMenuContainer>
    )
}

export default ColumnMenu
