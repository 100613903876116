import images from 'assets/images'
import { alpha } from 'lib'
import { BoxContainer } from 'ui'

import SuccessCircle from './SuccessCircle'

interface SuccessImageprops {
    image: string
}

const SuccessImage = ({ image }: SuccessImageprops) => {
    return (
        <BoxContainer
            position="relative"
            height="86px"
            width="156px"
        >
            <SuccessCircle
                sx={{
                    background: (theme) => alpha(theme.palette.text.primary, 0.05),
                    left: '0',
                }}
                icon={image}
            />
            <SuccessCircle
                sx={{
                    background: (theme) => alpha(theme.palette.primary.main, 0.1),
                    right: '0',
                }}
                icon={images.fleetpalSmall}
            />
        </BoxContainer>
    )
}

export default SuccessImage
