import { type ShopModel } from 'resourcesBase'
import { Typography } from 'ui'

const ShopLabel = ({ shop }: { shop: ShopModel }) => {
    return (
        <Typography
            component="span"
            variant="body1"
        >
            {shop.name}{' '}
            <Typography
                variant="inherit"
                component="span"
                color="text.secondary"
            >
                {[shop.address, shop.address2].filter(Boolean).join(', ')}
            </Typography>
        </Typography>
    )
}

export default ShopLabel
