import { useDeferredValue, type FC } from 'react'

import { inject } from 'mobx-react'
import { useStore } from 'react-admin'

import { type AuthStore } from 'core/auth'
import { useDidUpdate } from 'hooks'
import { useQueryClient, type UseQueryMeta } from 'lib'

const CompanyStateUpdate: FC = inject('auth')(({ auth }: { auth: AuthStore }) => {
    const [companyVersion] = useStore('companyVersion', 0)
    const version = useDeferredValue(companyVersion)

    const query = useQueryClient()

    useDidUpdate(() => {
        auth.fetchUser()
        query.resetQueries({
            active: false,
            predicate: (query) => {
                const queryKey = String(
                    Array.isArray(query.queryKey) ? query.queryKey[0] : query.queryKey,
                )
                if (queryKey.startsWith('telematics') && queryKey.endsWith('vehicles')) {
                    return true
                }
                return Boolean((query.meta as UseQueryMeta)?.refetchState)
            },
        })
    }, [version])

    return null
})

export default CompanyStateUpdate
