import { useEffect } from 'react'

import { useListContext } from 'react-admin'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'

import Icons from 'assets/icons'
import { Button } from 'ui'
import { isEqualWith } from 'utils'

import ListSortDrawerContent, { type ListSortContentProps } from './ListSortDrawerContent'
import { useListSortDrawerContext } from './ListSortDrawerContext'
import { ListUtilityDrawer } from './ListUtilityDrawer'

import type { RaSortPayload, SortPayload } from 'core/types'

const ResetButton = () => {
    const { checkIsDefaultSorted, defaultSort } = useListSortDrawerContext()
    const { reset, watch } = useFormContext()
    const formValues = watch()

    return (
        <Button
            size="large"
            variant="text"
            color="error"
            disabled={checkIsDefaultSorted(formValues as unknown as SortPayload)}
            startIcon={<Icons.Refresh fontSize="large" />}
            onClick={() => {
                reset(defaultSort)
            }}
        >
            RESET
        </Button>
    )
}

const ApplyButton = () => {
    const { close } = useListSortDrawerContext()
    const { setSort, sort } = useListContext()
    const { getValues } = useFormContext()

    return (
        <Button
            size="large"
            variant="contained"
            onClick={() => {
                const value = getValues() as unknown as SortPayload
                if (!isEqualWith(sort, value)) {
                    setSort(value)
                }
                close()
            }}
        >
            APPLY
        </Button>
    )
}

const ListSortDrawer = ({ sortBy }: ListSortContentProps) => {
    const { isOpen, setOpen, close } = useListSortDrawerContext()
    const { sort } = useListContext()

    const form = useForm<RaSortPayload>({ defaultValues: sort })

    useEffect(() => {
        if (isOpen) {
            form.reset(sort)
        }
    }, [isOpen])

    return (
        <FormProvider<RaSortPayload> {...form}>
            <ListUtilityDrawer
                onClose={close}
                open={isOpen}
                setOpen={setOpen}
                title="Sort by"
                anchorBottomHeightSize="small"
                renderContent={() => <ListSortDrawerContent sortBy={sortBy} />}
                renderBottomLeft={() => {
                    return <ResetButton />
                }}
                renderBottomRight={() => {
                    return <ApplyButton />
                }}
            />
        </FormProvider>
    )
}

export default ListSortDrawer
