import { formatMoney } from 'components'
import { reportsUrls, createReportResource } from 'resources/reports'

import WidgetBase, { WidgetSkeleton } from '../../WidgetBase/WidgetBase'
import { useCreateDashboardWidgetLink, useDashboardWidget } from '../../WidgetsForm'
import { includeUncategorized } from '../CostPerCategoryWidget'
import { CostPerCategoryClarification } from '../CostPerCategoryWidget/components'
import { roundTop3WidgetData } from '../utils'

import { CostPerEquipmentChart, CostPerEquipmentList } from './components'
import {
    type listType,
    type maxTotalEquipmentsType,
    type QueryResponse,
    type ResponseType,
} from './types'

const description = `Maintenance costs for the reporting period are broken down by the Unit's equipment 
category VMRS classification, and the top 3 are listed in descending order.`

let hasData = false

const CostPerEquipmentWidget = () => {
    const createLink = useCreateDashboardWidgetLink()
    const data = useDashboardWidget<QueryResponse>({
        resource: createReportResource('cost-per-equipment-category'),
        sideEffect: (data) => {
            const { maxTotalEquipmentCategories, ...ratioData } = data as unknown as ResponseType

            const listWithUncategorized = includeUncategorized<maxTotalEquipmentsType>(
                ratioData,
                maxTotalEquipmentCategories,
            )
            const { listData, positiveDataTotal, ...listExtras } =
                roundTop3WidgetData(listWithUncategorized)

            const uncategorizedTotal = listExtras.uncategorizedInTop3
                ? null
                : ratioData.uncategorized

            hasData = typeof ratioData.total === 'number'
            const sumOfExcludedEquipment = uncategorizedTotal
                ? positiveDataTotal - listExtras.top3Total - uncategorizedTotal
                : positiveDataTotal - listExtras.top3Total

            const list: listType = {
                data: listData,
                ...listExtras,
                sumOfExcludedEquipment,
                uncategorizedTotal,
                chartTotal: positiveDataTotal,
            }

            return {
                ...ratioData,
                list,
            }
        },
    })
    if (!data) {
        return <WidgetSkeleton />
    }
    const { list, ...ratioData } = data

    return (
        <WidgetBase
            headerProps={{
                title: 'Cost per Equipment Categories',
                label: hasData ? formatMoney(ratioData.total) : null,
                tooltipText: description,
                clarification: (
                    <CostPerCategoryClarification>
                        including Uncategorized Equipment
                    </CostPerCategoryClarification>
                ),
                chart: (
                    <CostPerEquipmentChart
                        disabled={!hasData || ratioData.total <= 0}
                        list={list}
                        total={list.chartTotal}
                    />
                ),
            }}
            infoProps={{
                content: (
                    <CostPerEquipmentList
                        list={list}
                        description={description}
                    />
                ),
            }}
            separator="Most Expensive"
            link={createLink(reportsUrls.costPerEquipmentCategory)}
        />
    )
}
export default CostPerEquipmentWidget
