import { type FC } from 'react'

import { type WithChildrenNode } from 'appTypes'
import {
    CollapsibleContent,
    CollapsibleContextProvider,
    CollapsibleTogglerIconAction,
    useCollapsibleController,
} from 'components/Collapsible'
import { clsx, generateUtilityClasses, globalClassNames, styled, withColor } from 'lib'
import { Box, Stack, Typography } from 'ui'

const classNames = generateUtilityClasses('ExpandableSection', [
    'root',
    'header',
    'headerContent',
    'title',
])

const ExpandableSection: FC<WithChildrenNode & { disabled?: boolean }> = ({
    children,
    disabled,
}) => {
    return (
        <CollapsibleContextProvider disabled={disabled}>
            <Root>{children}</Root>
        </CollapsibleContextProvider>
    )
}

const Header: FC<WithChildrenNode> = ({ children }) => {
    const { isExpanded } = useCollapsibleController()

    return (
        <Box
            className={clsx(classNames.header, globalClassNames.ellipsis)}
            bgcolor={isExpanded ? (theme) => theme.palette.action.selected : 'transparent'}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderRadius="4px"
            gap="8px"
            p="15px"
            border={(theme) => `1px solid ${theme.palette.other.divider}`}
        >
            <Box
                className={clsx(classNames.headerContent, globalClassNames.ellipsis)}
                display="flex"
                alignItems="center"
                gap="8px"
            >
                {children}
            </Box>
            <CollapsibleTogglerIconAction />
        </Box>
    )
}

const Title: FC<WithChildrenNode> = ({ children }) => {
    return (
        <Typography
            className={clsx(classNames.title, globalClassNames.ellipsis)}
            variant="subtitle1"
            color={withColor('text.primary')}
        >
            {children}
        </Typography>
    )
}

export default Object.assign(ExpandableSection, { Header, Title, Content: CollapsibleContent })

const Root = styled(Stack, {
    slot: classNames.root,
})`
    gap: 16px;
`
