import BlankMonitor from './BlankMonitor'
import ColumnsContainer from './ColumnsContainer'
import MonitorArrowButton from './MonitorArrowButton'
import Monitor from './MonitorBase'
import MonitorBoxContainer from './MonitorBoxContainer'
import MonitorButton from './MonitorButton'
import MonitorCounter from './MonitorCounter'
import MonitorSkeleton from './MonitorSkeleton'
import MonitorTitle from './MonitorTitle'
import StatusBadge from './StatusBadge'
import ValueText from './ValueText'

export default Object.assign(Monitor, {
    Title: MonitorTitle,
    ArrowButton: MonitorArrowButton,
    Container: MonitorBoxContainer,
    Button: MonitorButton,
    StatusBadge,
    ValueText,
    Blank: BlankMonitor,
    Counter: MonitorCounter,
    Skeleton: MonitorSkeleton,
    ColumnsContainer,
})
