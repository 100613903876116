import { type ReactNode, type FC, type ReactElement } from 'react'

import { type Identifier } from 'appTypes'
import { ExpandableSection } from 'components'
import { GridContainerColumns, GridItemLayout } from 'ui'

import { type ComponentDate } from '../../../CostReportContext'

import ComponentsSlider from './ComponentsSlider'

interface DataRowProps {
    title: ReactNode
    total: number | undefined
    date?: ComponentDate
    onComponentClick: (component: Identifier) => void
    renderTotal?: (total: number, date: ComponentDate) => ReactElement
}

const DataRow: FC<DataRowProps> = ({ title, total, date, onComponentClick, renderTotal }) => {
    return (
        <ExpandableSection disabled={total == null}>
            <GridContainerColumns>
                <GridItemLayout>
                    <ExpandableSection.Header>
                        <ExpandableSection.Title>{title}</ExpandableSection.Title>
                        {renderTotal?.(total, date)}
                    </ExpandableSection.Header>
                </GridItemLayout>
            </GridContainerColumns>

            <ExpandableSection.Content>
                <ComponentsSlider
                    date={date}
                    onComponentClick={onComponentClick}
                />
            </ExpandableSection.Content>
        </ExpandableSection>
    )
}

export default DataRow
