import { type FC } from 'react'

import { useCreatePath } from 'react-admin'
import { useWatch } from 'react-hook-form'

import { type Identifier } from 'appTypes'
import { FormPreventSubmit } from 'components'
import { api } from 'core'
import { useQuery, dateTimeParse } from 'lib'
import { type PMIntervalTypes } from 'resources/pm'
import { getMetersResource, type MeterModel } from 'resources/units'
import { Stack } from 'ui'
import { pathJoin } from 'utils'

import { type WorkOrderModel } from '../../types'
import { woResource } from '../../utils'

import LabelSwitch from './LabelSwitch'
import PmMeterInputs from './PmMeterInput'

interface WoCloseGetData {
    defects: { id: Identifier; name: string }[]
    pmSchedules: { id: Identifier; name: string; types: PMIntervalTypes[] }[]
    issues: { id: Identifier; name: string }[]
}

interface WoCloseDataType extends WoCloseGetData {
    meters: { [key in MeterModel['type']]?: MeterModel | null }
    requiredMeterTypes?: {
        [key in MeterModel['type']]?: MeterType
    }
}

export type MeterType = Partial<MeterModel> & { pmIds: Identifier[] }

const WoCloseFields: FC<{ workOrder: WorkOrderModel }> = ({ workOrder }) => {
    const completed = useWatch({ name: 'completed' })

    const createPath = useCreatePath()

    const { data } = useQuery<WoCloseDataType>(
        ['wo-close-data', String(completed || '')],
        async () => {
            const woPath = createPath({
                resource: woResource.resource,
                type: 'edit',
                id: workOrder.id,
            })
            const { defects, pmSchedules, issues }: WoCloseGetData = await api.get(
                woPath + '/close',
            )

            const data: { currentMeters: { [key in MeterModel['type']]?: MeterModel | null } } =
                await api.get(pathJoin(getMetersResource(workOrder.unit).resource, `current`), {
                    now: dateTimeParse(completed),
                })

            const requiredMeterTypes = pmSchedules.reduce(
                (obj, pm) => {
                    pm.types.forEach((type) => {
                        if (type === 'TIME') {
                            return
                        }
                        if (!obj[type]) {
                            obj[type] = { ...data.currentMeters[type], type, pmIds: [] }
                        }
                        obj[type].pmIds.push(pm.id)
                    })
                    return obj
                },
                {} as WoCloseDataType['requiredMeterTypes'],
            )

            return {
                defects,
                pmSchedules,
                meters: data.currentMeters,
                requiredMeterTypes,
                issues,
            }
        },
        {
            cacheTime: 0,
        },
    )

    if (!data) {
        return <FormPreventSubmit />
    }

    const { defects, pmSchedules, requiredMeterTypes, issues } = data

    return (
        <>
            {Object.keys(requiredMeterTypes).map((meter) => {
                return (
                    <PmMeterInputs
                        key={meter}
                        meter={requiredMeterTypes[meter]}
                    />
                )
            })}
            <Stack spacing="13px">
                {issues.map((issue) => (
                    <LabelSwitch
                        label="Issue"
                        source={`issue.${issue.id}`}
                        key={issue.id}
                    >
                        {`Resolve ${issue.name}`}
                    </LabelSwitch>
                ))}
                {defects.map((defect) => (
                    <LabelSwitch
                        label="DVIR"
                        source={`dvir.${defect.id}`}
                        key={defect.id}
                    >
                        {`Resolve ${defect.name}`}
                    </LabelSwitch>
                ))}
                {pmSchedules.map((pm) => (
                    <LabelSwitch
                        label="PM"
                        source={`pm.${pm.id}`}
                        key={pm.id}
                    >
                        {`Reset ${pm.name}`}
                    </LabelSwitch>
                ))}
            </Stack>
        </>
    )
}

export default WoCloseFields
