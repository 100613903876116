import { type NoInfer } from 'appTypes'
import { type SortBy, type ListFilterChoice, type Column, type DataListDetails } from 'components'

import { type UnitModel } from '../types'

import { linkValue, linkWithIconValue } from './number'

const source = 'unit' as const
const label = 'Unit Number'

export const unitFilter = <Source extends string = 'unit'>({ id }: { id?: Source } = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label,
    }) as const satisfies ListFilterChoice

export const unitSort = <Source extends string = 'unit'>({ id }: { id?: Source } = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label,
    }) as const satisfies SortBy

const value = (record: UnitModel, withLink?: boolean) => {
    if (withLink) {
        return linkValue(record)
    }
    return record?.number
}

const tableColumn = <Source extends string = typeof source>({
    id,
    dataToRecord,
    withLink,
}: {
    id?: Source
    withLink?: boolean
    dataToRecord: (data) => UnitModel
}) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: label,
        renderCell: ({ row }) => value(dataToRecord(row), withLink),
    }) as const satisfies Column

const dataCardRow = <Source extends string = typeof source>({
    id,
    dataToRecord,
    withLink,
}: {
    id?: Source
    withLink?: boolean
    dataToRecord: (data) => UnitModel
}) =>
    ({
        source: (id || source) as NoInfer<Source>,
        label,
        render: (_, data) => value(dataToRecord(data), withLink),
    }) as const satisfies DataListDetails<any, any>

const self = {
    source,
    label,
    filter: unitFilter,
    sort: unitSort,
    tableColumn,
    dataCardRow,
    value,
    linkValue,
    linkWithIconValue,
}

export default self
