import { type Dispatch, useState } from 'react'

import { useFormContext } from 'react-hook-form'

import { DateTimeInput } from 'components'
import { requiredValidation } from 'core'
import { customerFields, CustomerInput } from 'resources/customers'
import { unitFields, UnitInput } from 'resources/units'
import { SectionTitleSmall } from 'ui'
import { isInputDisabledDefault } from 'utils'

import assignmentFields from '../fields'
import { assignmentsResource } from '../utils'

import AssignmentEndTimeInput from './AssignmentEndTimeInput'
import AssignmentTimeInputsHandler from './AssignmentTimeInputsHandler'

export interface AssignmentsFormProps {
    isInputDisabled?: (source: string) => boolean
}
const AssignmentsForm = ({ isInputDisabled = isInputDisabledDefault }: AssignmentsFormProps) => {
    const [endInputMessage, setEndInputMessage] = useState(null)
    return (
        <>
            <SectionTitleSmall>Details</SectionTitleSmall>

            <AssignmentUnitInput
                setEndInputMessage={setEndInputMessage}
                disabled={isInputDisabled(unitFields.self.source)}
            />

            <CustomerInput
                required
                disabled={isInputDisabled(customerFields.self.source)}
            />

            <AssignmentsStartInput disabled={isInputDisabled(assignmentFields.startDate.source)} />

            <AssignmentEndTimeInput
                helperText={endInputMessage}
                disabled={isInputDisabled(assignmentFields.endDate.source)}
            />

            <AssignmentTimeInputsHandler setEndInputMessage={setEndInputMessage} />
        </>
    )
}

export default AssignmentsForm

const AssignmentsStartInput = ({ disabled }: { disabled: boolean }) => {
    const { watch, getValues } = useFormContext()
    const unit = watch(unitFields.self.source) || getValues(unitFields.self.source)
    return (
        <DateTimeInput
            disableFuture
            validate={requiredValidation}
            source={assignmentFields.startDate.source}
            label={assignmentFields.startDate.label}
            disabled={disabled || !unit}
        />
    )
}

const AssignmentUnitInput = ({
    disabled,
    setEndInputMessage,
}: {
    disabled: boolean
    setEndInputMessage: Dispatch<string>
}) => {
    const { setValue, watch, getValues } = useFormContext()

    const start =
        watch(assignmentFields.startDate.source) || getValues(assignmentFields.startDate.source)

    const end = watch(assignmentFields.endDate.source) || getValues(assignmentFields.endDate.source)

    return (
        <UnitInput
            required
            disabled={disabled}
            contextType={assignmentsResource.name}
            onSelectedChange={() => {
                start &&
                    setValue(assignmentFields.startDate.source, null, {
                        shouldValidate: true,
                        shouldTouch: true,
                    })
                end &&
                    setValue(assignmentFields.endDate.source, null, {
                        shouldValidate: true,
                        shouldTouch: true,
                    })
                setEndInputMessage(null)
            }}
        />
    )
}
