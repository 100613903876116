import { createResource } from 'core'

import { type CK2Aspects } from './types'

export const ck2Aspects = {
    totals: 'totals',
} satisfies { [key: string]: CK2Aspects }

export const ck2Resource = createResource({
    name: 'equipment-category',
    resource: 'vmrs/ck2',
})
