import { type FC } from 'react'

import { type SvgIconElement } from 'appTypes'
import { SvgIcon } from 'ui'

interface Props {
    Icon: SvgIconElement
}

const InfoCardTitleIcon: FC<Props> = ({ Icon }) => {
    return (
        <SvgIcon
            component={Icon}
            inheritViewBox
            sx={{ width: '16px', height: '16px', color: 'inherit' }}
        />
    )
}

export default InfoCardTitleIcon
