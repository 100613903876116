import { createContext, type FC, type ReactNode, useContext } from 'react'

import { type RaRecord } from 'react-admin'

type ContextValue = (record: RaRecord, action: string) => { value: boolean; message: string }

export const CanAccessContext = createContext<ContextValue>(null)
export const useCanAccessContext = () => useContext(CanAccessContext)

export const CanAccessContextProvider: FC<{
    children: ReactNode
    value: (record: RaRecord, action: string) => string | boolean
}> = ({ children, value }) => {
    const getCanAccess: ContextValue = (record, action) => {
        const result = value(record, action)

        if (typeof result === 'boolean') {
            return { value: result, message: '' }
        } else if (result) {
            return { value: false, message: result }
        }

        return { value: true, message: '' }
    }
    return <CanAccessContext.Provider value={getCanAccess}>{children}</CanAccessContext.Provider>
}
