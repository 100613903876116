import { type FC } from 'react'

import { Show, tabRoutes } from 'components'
import { Routes } from 'lib'

import InvoicePayments from '../InvoicePayments'
import InvoiceLineItems from '../LineItems'
import InvoiceOverview from '../Overview'

import { InvoiceShowHeader } from './components'

const InvoiceShow: FC = () => {
    const { tabs, routes } = tabRoutes([
        {
            value: '',
            label: 'Overview',
            element: <InvoiceOverview />,
        },
        {
            value: 'line-items',
            label: 'Line Items',
            element: <InvoiceLineItems />,
        },
        {
            value: 'payments',
            label: 'Payments',
            element: <InvoicePayments />,
        },
    ])

    return (
        <Show>
            <>
                <InvoiceShowHeader tabs={tabs} />
                <Routes>{routes}</Routes>
            </>
        </Show>
    )
}

export default InvoiceShow
