import { type AxiosError } from 'axios'
import { useLogoutIfAccessDenied } from 'react-admin'

import { type FormErrors } from 'core/form'
import { useNotify } from 'core/notifications'

import { type ServerErrors } from './types'
import { elementNotFoundMessage, nonFieldErrors } from './utils'

export interface UseErrorHandlerParams {
    anonymous?: boolean
    onNotFound?: { message?: string }
}

export const useErrorHandler = (props: UseErrorHandlerParams = {}) => {
    const logoutIfAccessDenied = useLogoutIfAccessDenied()

    const notify = useNotify()

    // promise. Use .then
    // true : if the error is handled
    // false: if the error is not handled
    return async (error: AxiosError, params?: UseErrorHandlerParams): Promise<boolean> => {
        const { anonymous, onNotFound } = Object.assign({}, props, params)
        return (anonymous ? Promise.resolve(false) : logoutIfAccessDenied(error))
            .then((loggedOut) => {
                if (loggedOut) {
                    return true
                }

                if (error.status === 403) {
                    notify({
                        title: "You don't have permission to perform this action.",
                        type: 'error',
                    })
                    return true
                }

                if (error.status === 404) {
                    const message =
                        onNotFound?.message ??
                        (error.config.method?.toLowerCase?.() === 'get'
                            ? elementNotFoundMessage
                            : 'Action could not be completed, because a related item no longer exist')
                    if (message) {
                        notify({
                            title: message,
                            type: 'error',
                        })
                    }
                    return true
                }

                if ([405, 415, 429, 500].includes(error.status)) {
                    return true
                }

                if (error.message === 'Network Error') {
                    return true
                }

                return false
            })
            .catch(() => {
                return false
            })
    }
}

interface ErrorParams {
    fallbackError?: string
}

export const useServerErrorHandler = () => {
    const notify = useNotify()

    return (
        error: ServerErrors | FormErrors,
        { fallbackError = 'Unknown error' }: ErrorParams = {},
    ): boolean => {
        if (nonFieldErrors in error) {
            notify({
                title:
                    typeof error[nonFieldErrors].message === 'object'
                        ? error[nonFieldErrors].message.message
                        : error[nonFieldErrors].message,

                type: 'error',
            })
            return true
        }

        if (fallbackError) {
            notify({
                title: fallbackError,
                type: 'error',
            })
            return true
        }

        return false
    }
}

export const useFinalErrorHandler = () => {
    const errorHandler = useErrorHandler()
    const serverErrorHandler = useServerErrorHandler()

    return async (
        error,
        { fallbackError, ...params }: FinalErrorHandlerParams = {},
    ): Promise<boolean> => {
        return errorHandler(error, params).then((handled) => {
            if (handled) {
                return true
            }

            return serverErrorHandler(error, { fallbackError })
        })
    }
}

export interface FinalErrorHandlerParams extends ErrorParams, UseErrorHandlerParams {}
