import { CreateButtonView, Header, ListTotalBadge, MainHeader } from 'components'
import { PartDrawerToggler } from 'resources/parts'

const PartsListHeader = () => {
    return (
        <MainHeader
            mainAction={
                <PartDrawerToggler>
                    {({ onClick }) => (
                        <CreateButtonView onClick={onClick}>CREATE PART</CreateButtonView>
                    )}
                </PartDrawerToggler>
            }
        >
            <Header.Info>
                <Header.Content>
                    <Header.Title>Parts Catalog</Header.Title>
                </Header.Content>
                <Header.Content mobilePlacement="bottom">
                    <ListTotalBadge />
                </Header.Content>
            </Header.Info>
        </MainHeader>
    )
}

export default PartsListHeader
