import { type PropsWithChildren } from 'react'

import { styled, alpha, type ThemeColorType, themeColor } from 'lib'
import { BoxContainer, Typography } from 'ui'

const StatusBadge = ({ color, children }: { color: ThemeColorType } & PropsWithChildren) => {
    return (
        <StatusContainer
            bgColor={color}
            maxWidth="fit-content"
        >
            <Typography
                sx={{ opacity: 1 }}
                variant="chartLabel"
            >
                {children}
            </Typography>
        </StatusContainer>
    )
}
interface StatusContainerProps {
    bgColor: ThemeColorType
}
const StatusContainer = styled(BoxContainer, {
    shouldForwardProp: (prop) => prop !== 'bgColor',
})<StatusContainerProps>(
    ({ theme, bgColor }) => `
    padding: 2px 4px;
    border-radius: 2px;
    height: 13px;
    color:${themeColor(bgColor, theme)};
    justify-content: center;
    background-color:${alpha(themeColor(bgColor, theme), 0.15)}
`,
)
export default StatusBadge
