import { type FC } from 'react'

import {
    EmailInput,
    SelectInput as SelectInputBase,
    TelInput,
    TextInput as TextInputBase,
    UploadImage,
} from 'components'
import { maxLengthValidation, requiredValidation, urlValidation, validateMaxLength } from 'core'
import { AddressFormSection } from 'resources/address'
import { PaymentMethodInput } from 'resources/paymentMethods'
import { PaymentTermInput } from 'resources/paymentTerms'
import { TagInput } from 'resources/tags'
import { SectionTitleSmall } from 'ui'
import { lowerCaseFormat } from 'utils'

import vendorFields from '../fields'
import { type VendorModel, type VendorType } from '../types'
import { vendorTypes } from '../utils'

const TextInput = TextInputBase<VendorModel>
const SelectInput = SelectInputBase<VendorModel>

const nameValidation = [requiredValidation, maxLengthValidation]
const taxValidation = validateMaxLength(20)

export interface VendorFormProps {
    typeInputProps?: {
        defaultValue?: VendorType[]
        choices?: (
            choices: typeof vendorTypes,
        ) => ((typeof vendorTypes)[0] & { disabled?: boolean })[]
        helperText?: string
    }
}

export const VendorForm: FC<VendorFormProps> = (props) => {
    return (
        <>
            <SectionTitleSmall>Company Details</SectionTitleSmall>

            <TextInput
                source="name"
                label="Name"
                validate={nameValidation}
            />
            <SelectInput
                {...props.typeInputProps}
                source="type"
                choices={
                    props.typeInputProps?.choices
                        ? props.typeInputProps.choices(vendorTypes)
                        : vendorTypes
                }
                label="Vendor Type"
                multiple
                validate={requiredValidation}
            />
            <UploadImage
                buttonChangeText="Change Logo"
                buttonUploadText="Upload Logo"
                source="logo"
                defaultIcon={<vendorFields.avatar.Icon />}
            />
            <TelInput label="Company Phone" />
            <EmailInput label="Company Email" />
            <TextInput
                parse={lowerCaseFormat}
                source="website"
                label="Website"
                validate={urlValidation}
            />
            <TagInput />

            <AddressFormSection />

            <SectionTitleSmall>Financial Information</SectionTitleSmall>

            <TextInput
                source="taxId"
                label="Tax ID"
                validate={taxValidation}
            />
            <TextInput
                source="nationalAccount"
                label="National Account"
                validate={maxLengthValidation}
            />
            <PaymentMethodInput
                source="paymentMethod"
                label="Default Payment Method"
            />
            <PaymentTermInput
                source="paymentTerm"
                label="Default Payment Term"
            />
        </>
    )
}
