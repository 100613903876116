import { useEffect } from 'react'

import { useBlockerContext } from './BlockerContext'
import { type BlockerConfig } from './types'

interface Options {
    isOpen?: boolean
}

export const useSetBlocker = (params: BlockerConfig, { isOpen = true }: Options = {}) => {
    const block = useBlockerContext()

    useEffect(() => {
        if (isOpen) {
            const unblock = block(params)

            return unblock
        }
    }, [isOpen])
}
