import { Anchor } from 'ui'

export const websiteValue = (website: string) => {
    if (!website) {
        return null
    }

    return (
        <Anchor
            variant="inherit"
            href={website}
            target="_blank"
            rel="noopener noreferrer"
        >
            {website}
        </Anchor>
    )
}

export interface WithWebsite {
    website: string
}

export const websiteLabel = 'Website' as const
