import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import {
    CardCollapsibleContent,
    type CardListConfig,
    List,
    ListAvatar,
    ListBase,
    PhotoButton,
    PhotosCollapsibleIconHolder,
    PhotosCollapsibleContent,
} from 'components'
import { datagridAvatarColumn, DatagridLink } from 'components/Datagrid'
import { type SortPayload } from 'core'
import {
    deleteManyFromListAction,
    deleteOneAction,
    editRedirectInListAction,
    EditRedirectInListAction,
    exportAction,
    multiselectAction,
} from 'core/actions'
import { type AuthStore } from 'core/auth'
import { createdField } from 'resources/base'
import { NotesCollapsibleContent, NotesCollapsibleIconHolder } from 'resources/notes'
import { PartDrawerToggler, partFields, type PartModel } from 'resources/parts'
import { TagsCollapsibleContent, TagsCollapsibleIconHolder, tagFields } from 'resources/tags'
import { uomFields } from 'resources/unitsOfMeasure'
import { ck33Fields, ck34Fields } from 'resources/vmrs'
import { PageContent, Typography } from 'ui'

import { deletePartAction } from '../config/constants'

import { PartsListHeader } from './components'

import type {
    DatagridColumnsProps,
    FilterConfig,
    ListBulkActions,
    ListSortContentProps,
} from 'components'

const defaultPartSort: SortPayload<PartModel> = {
    field: 'created',
    order: 'DESC',
}

const bulkActions: ListBulkActions = ({ children }) => [
    deleteManyFromListAction({
        children,
        confirmConfig: {
            title: 'Are you sure you want to delete the selected Parts',
        },
    }),
]

const PartsList: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const inventoryEnabled = auth.companySettings.hasInventory

        const cardConfig: CardListConfig<PartModel> = {
            titleSource: 'number',
            subheaderTypographyProps: {
                variant: 'chartTitle',
            },
            subTitleSource: 'description',
            imageSource: 'image',
            defaultImage: <partFields.avatar.Icon />,
            details: [
                ck33Fields.self.dataCardRow({
                    dataToRecord: (data: PartModel) => data.componentData,
                }),
                ck34Fields.self.cardRow({
                    dataToRecord: (data: PartModel) => data.manufacturerData,
                }),
                uomFields.self.dataCardRow({
                    dataToRecord: (data: PartModel) => data.unitOfMeasureData,
                }),
                ...(inventoryEnabled
                    ? []
                    : [
                          partFields.cost.dataCardRow({
                              dataToValue: (data: PartModel) => data.cost,
                          }),
                      ]),
            ],
            collapsibleContent: (record) => (
                <CardCollapsibleContent
                    content={[
                        {
                            iconHolder: (
                                <TagsCollapsibleIconHolder
                                    tags={record.tagsData}
                                    key="tagsIcon"
                                />
                            ),
                            component: (
                                <TagsCollapsibleContent
                                    tags={record.tagsData}
                                    key="tagsContent"
                                />
                            ),
                        },
                        {
                            iconHolder: (
                                <NotesCollapsibleIconHolder
                                    notes={record.notes}
                                    key="notesIcon"
                                />
                            ),
                            component: (
                                <NotesCollapsibleContent
                                    notes={record.notes}
                                    key="notesContent"
                                />
                            ),
                        },
                        {
                            iconHolder: (
                                <PhotosCollapsibleIconHolder
                                    record={record}
                                    key="photosIcon"
                                />
                            ),
                            component: (
                                <PhotosCollapsibleContent
                                    record={record}
                                    key="photosContent"
                                />
                            ),
                        },
                    ]}
                />
            ),
            actions: (record, { children }) => [
                editRedirectInListAction({
                    children,
                    id: record.id,
                }),
                exportAction({
                    children,
                }),
                multiselectAction({
                    children,
                    id: record.id,
                }),
                deleteOneAction({
                    children: (params) =>
                        children({
                            ...params,
                        }),
                    id: record.id,
                    disabled: Boolean(record.inventoryCount),
                    titleOnDisabled: record.inventoryCount
                        ? 'This part has an inventory instance and cannot be deleted.'
                        : undefined,
                    ...deletePartAction,
                }),
            ],
        }

        const sortConfig: ListSortContentProps<PartModel> = {
            sortBy: [
                createdField.sort(),
                partFields.number.sort(),
                partFields.description.sort(),
                ck33Fields.self.sort(),
                ck34Fields.self.sort(),
                uomFields.self.sort(),
                partFields.manufacturerPartNumber.sort(),
                partFields.positionApplicable.sort(),
                partFields.serializedPart.sort(),
                partFields.photos.sort(),
                ...(inventoryEnabled ? [] : [partFields.cost.sort()]),
                partFields.upc.sort(),
            ],
        }

        const columnsConfig: DatagridColumnsProps<PartModel> = {
            columns: [
                datagridAvatarColumn({
                    field: 'image',
                    headerName: 'Picture',
                    avatar: (record: PartModel) => (
                        <ListAvatar
                            id={record.id}
                            imageSrc={record.image}
                            color={({ palette }) => palette.primary.main}
                            defaultImage={<partFields.avatar.Icon />}
                        />
                    ),
                }),
                {
                    field: 'number',
                    headerName: 'Part Number',
                    renderCell: (params) => <DatagridLink {...params} />,
                },
                partFields.description.tableColumn({
                    dataToValue: (data: PartModel) => data.description,
                }),
                ck33Fields.self.gridColumn({
                    dataToRecord: (data: PartModel) => data.componentData,
                }),
                ck34Fields.self.column({
                    dataToRecord: (data: PartModel) => data.manufacturerData,
                }),
                partFields.manufacturerPartNumber.tableColumn({
                    dataToValue: (data: PartModel) => data.manufacturerPartNumber,
                }),
                partFields.upc.tableColumn({
                    dataToValue: (data: PartModel) => data.universalProductCode,
                }),
                partFields.positionApplicable.tableColumn({
                    dataToValue: (data: PartModel) => data.positionApplicable,
                }),
                partFields.serializedPart.tableColumn({
                    dataToValue: (data: PartModel) => data.serializedPart,
                }),
                tagFields.self.column({
                    dataToRecord: (data: PartModel) => data.tagsData,
                }),
                {
                    field: 'photosCount',
                    headerName: 'Photos',
                    renderCell: ({ row }) => (
                        <PhotoButton files={partFields.photos.getPartPhotos(row)} />
                    ),
                },
                createdField.tableColumn({
                    dataToValue: (data: PartModel) => data.created,
                }),
                uomFields.self.tableColumn({
                    dataToRecord: (data: PartModel) => data.unitOfMeasureData,
                }),
                ...(inventoryEnabled
                    ? []
                    : [
                          partFields.cost.tableColumn({
                              dataToValue: (data) => data.cost,
                          }),
                      ]),
            ],
            resetColumns: {
                tagsData: false,
                positionApplicable: false,
                serializedPart: false,
                photosCount: false,
                universalProductCode: false,
            },
            mainField: 'number',
            avatarSource: 'image',
            actions: ({ row: part }, { children }) => [
                <EditRedirectInListAction
                    id={part.id}
                    children={children}
                    key="show"
                />,
                deleteOneAction({
                    children: (params) =>
                        children({
                            ...params,
                        }),
                    disabled: Boolean(part.inventoryCount),
                    titleOnDisabled: part.inventoryCount
                        ? 'This part has an inventory instance and cannot be deleted.'
                        : undefined,
                    id: part.id,
                    ...deletePartAction,
                }),
            ],
        }

        const filterConfig: FilterConfig<PartModel> = {
            filters: [
                createdField.filter(),
                partFields.number.filter(),
                ck34Fields.self.filter(),
                ...(inventoryEnabled ? [] : [partFields.cost.filter()]),
                uomFields.self.filter(),
                tagFields.self.filter(),
                partFields.photos.filter(),
                partFields.positionApplicable.filter(),
                partFields.serializedPart.filter(),
                ck33Fields.self.filter<PartModel>({
                    id: 'component',
                }),
            ],
        }

        return (
            <ListBase
                filter={{
                    type: 'INTERNAL',
                }}
                sort={defaultPartSort}
            >
                <PartsListHeader />
                <PageContent>
                    <List
                        bulkActions={bulkActions}
                        sortCfg={sortConfig}
                        columnsCfg={columnsConfig}
                        cardsCfg={cardConfig}
                        filtersCfg={filterConfig}
                        listFTUProps={{
                            secondaryTitle: 'Would you like to add one?',
                            linkText: (
                                <PartDrawerToggler>
                                    {({ onClick }) => (
                                        <Typography
                                            variant="body1"
                                            onClick={onClick}
                                            color={(theme) => theme.palette.primary.main}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            Add Part{' '}
                                        </Typography>
                                    )}
                                </PartDrawerToggler>
                            ),
                            linkAction: (e) => {
                                e.preventDefault()
                            },
                        }}
                    />
                </PageContent>
            </ListBase>
        )
    }),
)

export default PartsList
