import { type Column, type DataListDetails, type ListFilterChoice, type SortBy } from 'components'

import { getDefaultText } from '../../utils'
import { type PtServiceModel } from '../types'

const source = 'serviceTier' as const

const value = (record: Pick<PtServiceModel, 'isDefault' | 'name'>) => {
    if (!record.isDefault) {
        return valueBase(record)
    }

    return getDefaultText(record.name)
}

const valueBase = (record: Pick<PtServiceModel, 'name'>) => {
    return record.name
}

const label = 'Services'

const tableColumn = ({ dataToRecord }: { dataToRecord: (data) => PtServiceModel }) =>
    ({
        field: source,
        headerName: label,
        renderCell: ({ row }) => value(dataToRecord(row)),
    }) as const satisfies Column

const dataCardRow = ({ dataToRecord }: { dataToRecord: (data) => PtServiceModel }) =>
    ({
        source,
        label,
        render: (_, data) => value(dataToRecord(data)),
    }) as const satisfies DataListDetails<any, any>

const filter = () =>
    ({
        id: source,
        label,
    }) as const satisfies ListFilterChoice

const sort = () =>
    ({
        id: source,
        label,
    }) as const satisfies SortBy

const self = {
    source,
    label,
    value,
    valueBase,
    tableColumn,
    dataCardRow,
    sort,
    filter,
}

export default self
