import { useFormContext } from 'react-hook-form'

import FooterSaveButton from './FooterSaveButton'

const DialogFooterSaveButton = ({ onClick }: { onClick: () => void }) => {
    const formContext = useFormContext()

    return (
        <FooterSaveButton
            onClick={(synced) => {
                formContext?.setValue('vehiclesSyncStatus', synced)
                onClick?.()
            }}
        />
    )
}

export default DialogFooterSaveButton
