import { type PropsWithChildren } from 'react'

import Icons from 'assets/icons'
import { ActionsMenu } from 'core/actions'
import { formatDate } from 'lib'
import { type TelematicsDataModel } from 'resources/telematics/types'
import { telematicsExtras } from 'resources/telematics/utils'
import { Alert, SvgIcon, Stack, Spacer, Img, Typography, BoxContainer } from 'ui'

import { ConnectedUnitStatus } from './ConnectedUnitStatus'

export const UnitIntegrationsStatusPopper = ({
    telematicData,
}: {
    telematicData: TelematicsDataModel
}) => {
    const errorContent = <Alert severity="error">{telematicData.error}</Alert>
    const connectedContent = (
        <>
            {telematicData.syncMeters && <CheckedItem>Meters</CheckedItem>}
            {telematicData.syncDvirs && <CheckedItem>DVIR</CheckedItem>}
        </>
    )
    return (
        <ActionsMenu
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            renderToggler={(open) => (
                <ConnectedUnitStatus
                    type={telematicData.error ? 'error' : 'sync'}
                    onClick={open}
                />
            )}
            actions={() => [
                <Stack
                    key="content"
                    maxWidth="300px"
                    p="4px 12px"
                    spacing="12px"
                >
                    <Spacer>
                        <Img
                            src={telematicsExtras[telematicData.provider].logo}
                            sx={{
                                width: '20px',
                                height: '20px',
                                maxWidth: '100%',
                            }}
                        />
                        <Typography
                            variant="subtitle1"
                            textTransform="capitalize"
                        >
                            {telematicData.provider}
                        </Typography>
                    </Spacer>
                    <BoxContainer>
                        <Typography
                            color="text.disabled"
                            variant="body1"
                        >
                            Last Synced
                        </Typography>
                        &nbsp;
                        <Typography
                            textAlign="right"
                            color="text.primary"
                            variant="body1"
                        >
                            {formatDate(
                                new Date(telematicData.lastSyncedMeters),
                                (dateFormats) => dateFormats.shortenedDateTime,
                            )}
                        </Typography>
                    </BoxContainer>
                    {telematicData.error ? errorContent : connectedContent}
                </Stack>,
            ]}
        />
    )
}

const CheckedItem = ({ children }: PropsWithChildren) => {
    return (
        <BoxContainer gap="16px">
            <SvgIcon
                sx={{
                    width: '20px',
                    height: '20px',
                    color: (theme) => theme.palette.charts.greenBody,
                }}
                component={Icons.Check}
            />
            <Typography variant="menuItem">{children}</Typography>
        </BoxContainer>
    )
}
