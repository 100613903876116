import { type PropsWithChildren } from 'react'

import { Tooltip } from 'ui'

import LineItemsTooltipContent, {
    type LineItemsTooltipContentProps,
} from './LineItemsTooltipContent'

export const LineItemsTooltip = ({
    children,
    data,
}: PropsWithChildren<LineItemsTooltipContentProps>) => {
    return (
        <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={5000}
            componentsProps={{
                tooltip: {
                    sx: {
                        padding: '0px',
                    },
                },
            }}
            title={<LineItemsTooltipContent data={data} />}
        >
            <div>{children}</div>
        </Tooltip>
    )
}

export default LineItemsTooltip
