import { costMaskParams, TextareaInput, TextInput } from 'components'
import { maxLengthValidationText, requiredValidation } from 'core'
import { type InvoiceService } from 'resources/invoices'

export interface ServiceFormProps {
    isDisabled?: (source: keyof InvoiceService) => boolean
    isHidden?: (source: keyof InvoiceService) => boolean
}

const defaultDisabled = () => false

const descriptionValidation = [maxLengthValidationText, requiredValidation]

const ServiceForm = ({ isDisabled = defaultDisabled, isHidden }: ServiceFormProps) => {
    return (
        <>
            <TextareaInput
                source="description"
                label="Description"
                validate={descriptionValidation}
                disabled={isDisabled('description')}
            />
            {isHidden?.('orderPrice') ? null : (
                <TextInput
                    source="orderPrice"
                    label="Amount"
                    {...costMaskParams}
                    validate={requiredValidation}
                    disabled
                />
            )}
            <TextInput
                source="price"
                label="Selling Price"
                {...costMaskParams}
                validate={requiredValidation}
                disabled={isDisabled('price')}
            />
        </>
    )
}

export default ServiceForm
