import { ActionsMenu } from 'core/actions'
import { type ShopModel } from 'resourcesBase'
import { Chip, Stack, SimpleScrollbar } from 'ui'

import ShopLabel from './ShopLabel'

const ShopsField = ({ shops, extraSmall }: { shops: ShopModel[]; extraSmall?: boolean }) => {
    const shopsCount = shops?.length

    if (!shopsCount) {
        return <>-</>
    }
    return (
        <ActionsMenu
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            renderToggler={(open) => (
                <Chip
                    component="span"
                    onClick={open}
                    label={`${shopsCount} Repair shops`}
                    variant="filled"
                    size={extraSmall ? 'extraSmall' : 'small'}
                />
            )}
            actions={() => [
                <SimpleScrollbar
                    key="shops"
                    sx={{
                        cursor: 'default',
                        maxHeight: '268px',
                        [`& .simplebar-content`]: {
                            width: 'max-content',
                            maxWidth: '300px',
                        },
                    }}
                >
                    <Stack
                        p="6px 16px"
                        key="tags"
                        gap="12px"
                    >
                        {shops.map((shop) => (
                            <ShopLabel
                                shop={shop}
                                key={shop.id}
                            />
                        ))}
                    </Stack>
                </SimpleScrollbar>,
            ]}
        />
    )
}

export default ShopsField
