import { type FC, createContext, useContext, useState } from 'react'
import type { ReactNode } from 'react'

import { type NotificationPayload } from './types'

const NotificationContext = createContext<NotificationPayload | null>(null)
const NotificationSetContext = createContext<(params: NotificationPayload) => void>(null)

interface Props {
    children: ReactNode
}

export const NotificationProvider: FC<Props> = ({ children }) => {
    const [notification, setNotification] = useState<NotificationPayload>(null)

    return (
        <NotificationContext.Provider value={notification}>
            <NotificationSetContext.Provider value={setNotification}>
                {children}
            </NotificationSetContext.Provider>
        </NotificationContext.Provider>
    )
}

export const useNotify = () => {
    return useContext(NotificationSetContext)
}

export const useNotificationContext = () => {
    return useContext(NotificationContext)
}
