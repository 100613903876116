import { type FC } from 'react'

import { LineItemTypeKeys, type LineItemTypesUnion } from 'resources/lineItems'
import { type JobItem } from 'resources/workOrders'

import useJobItemTranslate from '../useJobItemTranslate'

import LineItemsTooltipContent from './LineItemsTooltipContent'
import { LineItemsMap } from './useJobItemsTooltipData'

interface ItemTotalsType extends Record<LineItemTypesUnion, number> {}

const itemsTotalInitial: ItemTotalsType = {
    [LineItemTypeKeys.PART]: null,
    [LineItemTypeKeys.LABOR]: null,
    [LineItemTypeKeys.FEE]: null,
    [LineItemTypeKeys.SERVICE]: null,
    [LineItemTypeKeys.TAX]: null,
}

const LineItemsTooltipTitle: FC<{ data: JobItem[] }> = ({ data }) => {
    const itemsTranslate = useJobItemTranslate()

    const itemTotals = data.reduce(
        (prev, obj) => {
            const { total } = itemsTranslate(obj)
            const itemTotal = typeof total === 'function' ? total() : total
            prev[obj.type] = prev[obj.type] + itemTotal
            return prev
        },
        { ...itemsTotalInitial },
    )

    const mutatedItems = LineItemsMap.map((item) => ({
        ...item,
        total: itemTotals[item.id],
    }))

    return <LineItemsTooltipContent data={mutatedItems} />
}

export default LineItemsTooltipTitle
