import { type FC } from 'react'

import { useOpenUtilityDrawer, UtilityDrawerEditor } from 'components'
import { type IssueModel, issuesResource } from 'resources/issues'
import { type UnitModel } from 'resources/units'

import { canEdit, issueSerializer } from '../utils'

import IssuesForm, { type IssueFormProps } from './IssueForm'

export interface IssueDrawerTogglerProps extends IssueFormProps {
    children: (action: () => void) => JSX.Element
    record?: IssueModel
    defaultValues?: Partial<IssueModel & Pick<UnitModel, 'customer'>>
}

const IssueDrawerToggler: FC<IssueDrawerTogglerProps> = ({
    children,
    record,
    defaultValues,
    isInputDisabled,
}) => {
    const open = useOpenUtilityDrawer()
    const disabled = canEdit(record)

    return children(() => {
        open({
            drawerArgs: {
                title: record ? (disabled ? 'View Details' : 'Edit Details') : 'Create Issue',
                renderBottomLeft: disabled ? (render) => render({ children: 'Close' }) : undefined,
                renderBottomRight: disabled ? () => null : undefined,
                renderWrapper: (params) => (
                    <UtilityDrawerEditor<IssueModel & { notesToAdd?: string[] }>
                        {...params}
                        serializer={issueSerializer}
                        defaultValues={defaultValues}
                    />
                ),
                renderContent: () => (
                    <IssuesForm
                        isInputDisabled={(source) => {
                            if (disabled || record?.status === 'IN_PROGRESS') {
                                return true
                            }

                            if (record?.id && source === 'unit') {
                                return true
                            }

                            if (isInputDisabled?.(source)) {
                                return true
                            }

                            return false
                        }}
                    />
                ),
            },
            extraArgs: {
                id: record?.id,
                resource: issuesResource,
            },
        })
    })
}

export default IssueDrawerToggler
