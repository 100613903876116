import Icons from 'assets/icons'
import UtilityDrawerCancelButton from 'components/Drawer/UtilityDrawerCancelButton'

import { useStepsContext } from './UseWOStepsContext'

const UseWOBackButton = () => {
    const { step, prev } = useStepsContext()

    return (
        <UtilityDrawerCancelButton
            children={step === 1 ? undefined : 'Back'}
            startIcon={step === 1 ? undefined : <Icons.ChevronLeft />}
            customAction={step === 1 ? undefined : prev}
        />
    )
}

export default UseWOBackButton
