import { type MouseEventHandler, type FC } from 'react'

import Icons from 'assets/icons'
import { IconBox } from 'ui'

interface Props {
    onClick?: MouseEventHandler<HTMLDivElement>
    disabled?: boolean
    title?: string
}

const AddButton: FC<Props> = ({ onClick, disabled, title: titleProp }) => {
    const title = titleProp || 'Add'

    return (
        <IconBox
            title={title}
            aria-label={title}
            disabled={disabled}
            onClick={onClick}
        >
            <Icons.Add />
        </IconBox>
    )
}

export default AddButton
