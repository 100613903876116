import { type NoInfer, type ObjectAny } from 'appTypes'

export const arrayOfStringToObject = <T, V>(params: (keyof T)[], value?: V) => {
    const obj = {} as { [K in keyof T]: V }
    for (const prop of params) {
        obj[prop] = value
    }

    return obj
}

export const isObjectEmpty = (obj: ObjectAny): boolean => {
    for (const property in obj) {
        if (!Object.prototype.hasOwnProperty.call(obj, property)) {
            continue
        }
        return false
    }
    return true
}

export const addElementToPrevOfLast = <ElementType = any>(
    array: ElementType[],
    elementToAdd: NoInfer<ElementType>,
) => {
    const index = array.length - 1

    const newArray = [...array.slice(0, index), elementToAdd, array[index]]

    return newArray
}

export const WindowURL = window.URL || window.webkitURL

export const isInputDisabledDefault = () => false
