import { type ComponentProps } from 'react'

import { useChoicesContext, AutocompleteInput as RAutocompleteInput } from 'react-admin'

import { type DataRecord } from 'appTypes'
import { useCreateInputId } from 'core/form'
import { useInputState } from 'core/inputs'
import { createLayerNotFoundKey } from 'core/referenceController'

import { type CreateOption, type ListBoxProps } from './ListBox'

const defaultMatchSuggestion = () => true
export interface AutocompleteInputBaseProps<RecordType extends DataRecord = any>
    extends ComponentProps<typeof RAutocompleteInput> {
    source?: Extract<keyof RecordType, string>
    handleScroll?: ListBoxProps['onScroll']
    renderCreateOption?: CreateOption
}
const AutocompleteInputBase = ({
    handleScroll,
    ListboxProps,
    renderCreateOption,
    ...props
}: AutocompleteInputBaseProps) => {
    const { source } = useChoicesContext({
        choices: props.choices,
    })

    const createId = useCreateInputId()
    const { source: finalSource, ...state } = useInputState(props.source || source || '', {
        disabled: props.disabled,
    })

    return (
        <RAutocompleteInput
            fullWidth
            defaultValue=""
            size="medium"
            variant="outlined"
            matchSuggestion={defaultMatchSuggestion}
            filterSelectedOptions={false}
            noOptionsText="No Options"
            {...props}
            {...state}
            id={createId({ source: finalSource, id: props.id })}
            format={(value) => {
                const v = props.format ? props.format(value) : value
                return v ? v : ''
            }}
            ListboxProps={{
                onScroll: handleScroll,
                // @ts-ignore
                renderCreateOption,
                ...ListboxProps,
            }}
            {...(renderCreateOption && {
                getOptionDisabled: (record) => {
                    return (
                        record[props.optionValue ?? 'id'] === createLayerNotFoundKey ||
                        props.getOptionDisabled?.(record)
                    )
                },
                // inputText: (record) =>
                //     (record.id === notFoundKey ? props.noOptionsText : props.inputText?.(record)),
                // optionText: (record) => (
                //      record.id === notFoundKey
                //          ? props.noOptionsText
                //          : (typeof props.optionText === 'function' ? props.optionText(record) : props.optionText)),
            })}
        />
    )
}
export default AutocompleteInputBase
