import { useState } from 'react'

import { inject, observer } from 'mobx-react'
import { BooleanInput } from 'react-admin'
import { useFormContext } from 'react-hook-form'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import {
    costMaskParams,
    UploadImage,
    TextInput,
    TextareaInput,
    MultiFormatScanner,
} from 'components'
import {
    type AuthStore,
    maxLengthValidationText,
    requiredValidation,
    validateMaxLength,
} from 'core'
import { useFlags, formHelperTextHeight } from 'lib'
import { TagInput } from 'resources/tags'
import { UOMInput } from 'resources/unitsOfMeasure'
import { CK33Input, CK34Input } from 'resources/vmrs'
import { SvgIcon, IconButton, BoxContainer, SectionTitleSmall } from 'ui'

import partFields from '../fields'
import { type PartModel } from '../types'

export const DescriptionField = () => {
    const { watch } = useFormContext()
    const componentValue = watch('component')

    return (
        <TextareaInput<PartModel>
            source="description"
            validate={maxLengthValidationText}
            label="Description"
            disabled={!componentValue}
        />
    )
}

const PartCK33Input = () => {
    const { setValue } = useFormContext<PartModel>()

    return (
        <CK33Input
            onSelectedChange={({ selected }) => {
                setValue('description', selected?.text || '')
            }}
            required
        />
    )
}

const maxCharactersValidate = validateMaxLength(30)

const UPCInput = () => {
    const [isOpen, setIsOpen] = useState(false)
    const { setValue } = useFormContext()
    const { flag4047UpcScanner } = useFlags()

    return (
        <BoxContainer gap="15px">
            <TextInput
                source="universalProductCode"
                label="UPC"
                validate={maxLengthValidationText}
            />
            {flag4047UpcScanner && (
                <>
                    <IconButton
                        size="large"
                        sx={{ mb: formHelperTextHeight }}
                        aria-label="Scan Barcode"
                        onClick={() => setIsOpen(true)}
                        title="Scan UPC"
                    >
                        <SvgIcon component={Icons.BarcodeScan} />
                    </IconButton>
                    <MultiFormatScanner
                        upcOnly
                        isOpen={isOpen}
                        close={() => setIsOpen(false)}
                        onDecode={(text) => {
                            setValue('universalProductCode', text, {
                                shouldValidate: true,
                                shouldDirty: true,
                                shouldTouch: true,
                            })
                        }}
                    />
                </>
            )}
        </BoxContainer>
    )
}
type InputsCfgType = {
    [key in keyof PartModel]?: {
        hide: boolean
    }
}

export const PartForm = inject('auth')(
    observer(
        ({
            auth,
            id,
            inputsCfg = { cost: { hide: Boolean(auth.companySettings.hasInventory) } },
        }: {
            auth?: AuthStore
            id?: Identifier
            inputsCfg?: InputsCfgType
        }) => {
            return (
                <>
                    <SectionTitleSmall>Basic Details</SectionTitleSmall>
                    <TextInput
                        source="number"
                        label="Part No"
                        validate={requiredValidation}
                    />

                    <PartCK33Input />

                    <DescriptionField />

                    <UploadImage
                        source="image"
                        buttonUploadText="UPLOAD THUMBNAIL"
                        buttonChangeText="CHANGE THUMBNAIL"
                        defaultIcon={<partFields.avatar.Icon />}
                    />

                    <CK34Input
                        label="Manufacturer"
                        source="manufacturer"
                    />

                    <TextInput
                        source="manufacturerPartNumber"
                        label="Manufacturer Part Number"
                        validate={maxCharactersValidate}
                    />

                    {inputsCfg?.universalProductCode?.hide ? null : <UPCInput />}

                    <UOMInput />

                    {inputsCfg?.cost?.hide
                        ? null
                        : id && (
                              <>
                                  <TextInput
                                      source="cost"
                                      label="Last Known Cost"
                                      {...costMaskParams}
                                      disabled
                                  />
                              </>
                          )}

                    <TagInput />
                    <SectionTitleSmall>Settings</SectionTitleSmall>
                    <BooleanInput
                        source="positionApplicable"
                        label="Position Applicable"
                    />

                    <BooleanInput
                        source="serializedPart"
                        label="Serialized Part"
                    />
                </>
            )
        },
    ),
)
