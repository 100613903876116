import { type NoInfer } from 'appTypes'
import { type ListFilterChoice, type Column } from 'components'

import { type UnitModel } from '../types'

const source = 'engineHp' satisfies keyof UnitModel

const label = 'Engine HP'

const tableColumn = <Source extends string = typeof source>({
    id,
    dataToValue,
}: {
    id?: Source
    dataToValue: (data) => UnitModel[typeof source]
}) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: label,
        renderCell: ({ row }) => dataToValue(row),
    }) as const satisfies Column

const filter = <Source extends string = typeof source>({ id }: { id?: Source } = {}) =>
    ({
        id: id || source,
        label,
    }) as const satisfies ListFilterChoice

export const engineHp = {
    source,
    label,
    tableColumn,
    filter,
}
