import { addElementToPrevOfLast } from 'utils'

import { type ColorOption } from './types'

const colors: ColorOption[] = [
    '#AE3115',
    '#E60023',
    '#E67C73',
    '#E66E00',
    '#CD970F',
    '#C47D30',
    '#5C9C7A',
    '#19C184',
    '#9CA700',
    '#3F85F7',
    '#10B7FF',
    '#45A0B9',
    '#766EBE',
    '#8E24AA',
    '#C025C3',
    '#EF10F4',
    '#6D778A',
    '#000000',
    'custom',
].map((color) => ({ id: color, name: color }))

export const getOptions = (value: string | undefined) => {
    if (!value) {
        return colors
    }

    const valueInOptions = colors.some((option) => option.id === value)
    return valueInOptions ? colors : addElementToPrevOfLast(colors, { id: value, name: value })
}
