import { type CustomerModel } from '../types'

const source: keyof CustomerModel = 'paymentTerm'

const PaymentTerm = {
    source,
    label: 'Default Payment Term',
}

export default PaymentTerm
