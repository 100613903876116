import Icons from 'assets/icons'
import { CollapsibleIconHolder } from 'components/Collapsible'
import { getRecordPhotos } from 'components/Gallery'

import { type PhotosCollapsibleContentProps } from './PhotosCollapsibleContent'

export const PhotosCollapsibleIconHolder = ({ record }: PhotosCollapsibleContentProps) => {
    const photos = getRecordPhotos(record)
    return (
        <CollapsibleIconHolder
            name="photos"
            title={`Photos (${photos.count})`}
            disabled={!photos.count}
            titleOnDisabled="No Photos Added"
        >
            <Icons.PhotoOutlined fontSize="small" />
        </CollapsibleIconHolder>
    )
}
export default PhotosCollapsibleIconHolder
