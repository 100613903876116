import { type FC } from 'react'

import { useUtilityDrawerContext } from 'components'
import { classes } from 'lib'
import { Stepper, Step, StepLabel, Box, stepIconClasses } from 'ui'

import { useStepsContext } from './UseWOStepsContext'
const UseWOStepper = () => {
    const { step } = useStepsContext()

    return (
        <Box
            p="20px 12px"
            bgcolor={(theme) => theme.palette.gray}
            mr="-40px"
            position="sticky"
            top="0px"
            left="0"
            right="0"
            zIndex={2}
            sx={{ transform: 'translate(-20px, -20px)' }}
        >
            <Stepper activeStep={step - 1}>
                {[<WorkOrderTitle />, 'Create Job'].map((label, i) => (
                    <Step
                        sx={{
                            [`&.${classes.completed} .${stepIconClasses.root}`]: {
                                color: (theme) => theme.palette.success.main,
                            },
                        }}
                        key={i}
                    >
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    )
}

export default UseWOStepper

const WorkOrderTitle: FC = () => {
    const { extraArgs } = useUtilityDrawerContext()
    const { id } = extraArgs

    if (id) {
        return <>Work Order</>
    }

    return <>Create Work Order</>
}
