import { useRecordContext } from 'react-admin'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import { SliderView, ViewHeader } from 'components'
import {
    VendorPartDrawerToggler,
    PartDetailsCard,
    type PartModel,
    partCardDetails,
    PartSliderSkeleton,
} from 'resources/parts'
import { TagsCard } from 'resources/tags'
import { IconBox, Section } from 'ui'

const VendorPartEditDrawerButton = ({ id }: { id?: Identifier }) => {
    return (
        <VendorPartDrawerToggler
            id={id}
            children={({ onClick }) => (
                <IconBox
                    title="Edit"
                    onClick={onClick}
                >
                    <Icons.Edit />
                </IconBox>
            )}
        />
    )
}

const skeletonSlides = new Array(2).fill(0).map((v, i) => <PartSliderSkeleton key={i} />)
const VendorPartDetails = () => {
    const record = useRecordContext<PartModel>()
    const slides = record
        ? [
              <PartDetailsCard
                  details={[
                      partCardDetails.componentData,
                      partCardDetails.manufacturerData,
                      partCardDetails.manufacturerPartNumber,
                      partCardDetails.unitOfMeasure,
                      partCardDetails.positionApplicable,
                      partCardDetails.serializedPart,
                      partCardDetails.cost,
                  ]}
              />,
              <VendorPartDrawerToggler
                  id={record.id}
                  children={({ onClick }) => (
                      <TagsCard
                          create={onClick}
                          tags={record.tagsData}
                      />
                  )}
              />,
          ]
        : skeletonSlides

    return (
        <Section>
            <ViewHeader
                title="Details"
                loading={!record}
            >
                <ViewHeader.Content placement="rightMobile">
                    <VendorPartEditDrawerButton id={record?.id} />
                </ViewHeader.Content>
            </ViewHeader>
            <SliderView items={slides} />
        </Section>
    )
}

export default VendorPartDetails
