import { useListContext } from 'react-admin'

import { UtilityDrawerForm, useOpenUtilityDrawer } from 'components'
import { api, useSubmit, useResource } from 'core'

import PMRepositionForm from './PMRepositionForm'

const usePMRepositionDrawer = () => {
    const open = useOpenUtilityDrawer()
    const listContext = useListContext()
    const repositionPath = listContext.resource + '/reorder_dependent'
    const resource = useResource()

    const handleSubmit = useSubmit(
        async (formData) => {
            const { parentFirstOrder } = formData
            await api.post(repositionPath, { parentFirstOrder })
        },
        {
            successMessage: ({ defaultMessages }) => defaultMessages.updated,
        },
    )

    return () => {
        open({
            drawerArgs: {
                title: 'Reposition',
                renderContent: () => <PMRepositionForm />,
                renderWrapper: (props) => (
                    <UtilityDrawerForm
                        {...props}
                        onSubmit={handleSubmit}
                    />
                ),
            },
            extraArgs: {
                type: 'edit',
                listContext,
                resource,
            },
        })
    }
}

export default usePMRepositionDrawer
