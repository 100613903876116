import { type FC, type ReactElement } from 'react'

import { UtilityDrawerNoResults } from 'components/Drawer'
import { type NoResultsCardProps } from 'components/NoResultsCard/NoResultsCard'

interface DialogSelectorNoResultsProps {
    title?: string
    text?: string | ReactElement
    image?: NoResultsCardProps['imageSrc']
    imageWidth?: NoResultsCardProps['imageProps']['width']
}

const DialogSelectorNoResults: FC<DialogSelectorNoResultsProps> = ({
    title,
    text = 'No matching items found. Please modify your search.',
    image,
    imageWidth,
}) => {
    return (
        <UtilityDrawerNoResults
            title={title}
            text={text}
            image={image}
            imageWidth={imageWidth}
        />
    )
}

export default DialogSelectorNoResults
