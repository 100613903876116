import { ResourceLinkButton } from 'components'
import { useCreateResourcePath } from 'core'
import { poResource, type PurchaseOrderModel } from 'resources/purchaseOrders'
import { woResource } from 'resources/workOrders'
import { BoxContainer } from 'ui'

const PurchaseOrderTitle = ({ record }: { record: PurchaseOrderModel }) => {
    const createPath = useCreateResourcePath()

    return (
        <BoxContainer sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            <ResourceLinkButton
                to={
                    record.workOrder
                        ? createPath({
                              resource: woResource.resource,
                              type: 'edit',
                              id: record.workOrder,
                          }) + '/invoice'
                        : {
                              resource: poResource.resource,
                              type: 'edit',
                              id: record.id,
                          }
                }
            >
                {record.workOrder
                    ? `${record.number} • ${record.workOrderData.number}`
                    : record.number}
            </ResourceLinkButton>
        </BoxContainer>
    )
}

export default PurchaseOrderTitle
