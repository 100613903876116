import { type FC, type ReactElement } from 'react'

import { type Identifier } from 'appTypes'
import { useOpenUtilityDrawer } from 'components'
import UtilityDrawerEditor, {
    type UtilityDrawerEditorProps,
} from 'components/Drawer/UtilityDrawerEditor'
import { type Serializer, NotificationMessage } from 'core'
import { useCreateResourcePath } from 'core/resource'

import { type PartModel } from '../types'
import { partsResource } from '../utils'

import { PartForm } from './PartForm'

interface Props extends Pick<UtilityDrawerEditorProps, 'onSuccess'> {
    children: (params: { onClick: () => void }) => ReactElement
    id?: Identifier
    shortSuccessMessage?: boolean
}

export const PartDrawerToggler: FC<Props> = ({ children, id, onSuccess, shortSuccessMessage }) => {
    const open = useOpenUtilityDrawer()
    const createPath = useCreateResourcePath()

    return children({
        onClick: () => {
            open({
                extraArgs: {
                    id,
                    resource: id
                        ? partsResource
                        : {
                              name: partsResource.name,
                              resource: partsResource.resource + '/internal',
                          },
                },
                drawerArgs: {
                    title: id ? 'Edit Details' : 'Create Part',
                    renderWrapper: (params) => (
                        <UtilityDrawerEditor
                            onSuccess={onSuccess}
                            {...params}
                            successMessage={({ defaultMessages, response }) => {
                                if (id) {
                                    return defaultMessages.updated
                                }
                                if (shortSuccessMessage) {
                                    return defaultMessages.created
                                }
                                const part = response as PartModel
                                return {
                                    title: defaultMessages.created,
                                    message: (
                                        <NotificationMessage.Navigate
                                            to={createPath({
                                                type: 'edit',
                                                id: part.id,
                                            })}
                                        >
                                            {part.number}
                                        </NotificationMessage.Navigate>
                                    ),
                                }
                            }}
                            serializer={partSerializer}
                        />
                    ),
                    renderContent: () => <PartForm id={id} />,
                },
            })
        },
    })
}

export const partSerializer: Serializer<PartModel> = [
    'number',
    'description',
    { name: 'image', parse: 'file' },
    'universalProductCode',
    'unitOfMeasure',
    'positionApplicable',
    'serializedPart',
    'manufacturerPartNumber',
    { name: 'component', parse: 'emptyToNull' },
    { name: 'manufacturer', parse: 'emptyToNull' },
    { name: 'tags', parse: 'emptyToNull' },
]
