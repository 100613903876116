import authBg from './auth-bg.svg'
import crossReferenceFtu from './cross-reference-ftu.svg'
import deactivate from './deactivate.svg'
import deactivated from './deactivated.svg'
import documentUpload from './document-upload.svg'
import eroad from './eroad-logo.svg'
import expirations from './expirations.svg'
import favorites from './favorites.svg'
import geotabLogo from './geotab-logo.svg'
import image1 from './image-1.svg'
import image2 from './image-2.svg'
import image3 from './image-3.svg'
import initialLogo from './initial-logo.svg'
import listFilterNoResult from './list-filter-no-results.svg'
import listFtu from './list-ftu.svg'
import logoBillingFree from './logo-billing-free.svg'
import fleetpalBlack from './logo-black.svg'
import fleetpalSmall from './logo-small-part.svg'
import fleetpalWhite from './logo-white.svg'
import fleetpal from './logo.svg'
import marker from './marker.svg'
import meter from './meter.svg'
import motiveLogo from './motive-logo.svg'
import noCrossReference from './no-cross-reference.svg'
import noInvoices from './no-invoices.svg'
import noJobs from './no-jobs.svg'
import noLineItems from './no-line-items.svg'
import noPayments from './no-payments.svg'
import noPhotos from './no-photos.svg'
import purchaseHistoryFtu from './no-purchase-history.svg'
import noShops from './no-shops.svg'
import noVendorContacts from './no-vendor-contacts.svg'
import notes from './notes.svg'
import onOrderCardIcon from './on-order-card-icon.svg'
import page404 from './page-404.svg'
import reposition from './reposition.svg'
import returnPoItems from './return-po-items.svg'
import samsaraLogo from './samsara-logo.svg'
import google from './social-google.svg'
import tags from './tags.svg'
import widgetsFtu from './widgets-ftu.svg'
const images = {
    google,
    page404,
    deactivated,
    deactivate,
    crossReferenceFtu,
    authBg,
    image1,
    favorites,
    image2,
    geotabLogo,
    listFtu,
    listFilterNoResult,
    logoBillingFree,
    fleetpalBlack,
    fleetpalSmall,
    fleetpalWhite,
    fleetpal,
    marker,
    meter,
    widgetsFtu,
    tags,
    samsaraLogo,
    returnPoItems,
    motiveLogo,
    reposition,
    onOrderCardIcon,
    notes,
    noVendorContacts,
    noShops,
    purchaseHistoryFtu,
    noPhotos,
    noPayments,
    noLineItems,
    noJobs,
    noInvoices,
    image3,
    noCrossReference,
    initialLogo,
    expirations,
    documentUpload,
    eroad,
} satisfies Record<string, string>

export default images
