import { useRecordContext } from 'react-admin'

import { maxLengthValidationText, requiredValidation } from 'core'
import { NotesTab } from 'resources/notes'
import { type UnitModel, isUnitArchived } from 'resources/units'

export const noteInputValidations = [maxLengthValidationText, requiredValidation]

const UnitNotes = () => {
    const record = useRecordContext<UnitModel>()
    return <NotesTab removeAddButton={isUnitArchived(record)} />
}

export default UnitNotes
