import { useUtilityDrawerContext } from 'components'

const UseWOTitle = () => {
    const { extraArgs } = useUtilityDrawerContext()

    const { type } = extraArgs

    if (type === 'edit') {
        return <>Use Existing Work Order</>
    }

    return <>Use New Work Order</>
}

export default UseWOTitle
