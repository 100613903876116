import { createResource } from 'core'
import { type AuthStore } from 'core/auth'

import { type CK33Aspects } from './types'

export const ck33Aspects = {
    totals: 'totals',
    totalSystem: 'totals_system',
    totalsGroup: 'totals_group',
} satisfies { [key: string]: CK33Aspects }

export const ck33Resource = createResource({
    name: 'system-category',
    resource: 'vmrs/ck33',
})

export enum CK33Levels {
    GROUP = 0,
    SYSTEM = 1,
    ASSEMBLY = 2,
    COMPONENT = 3,
}

export const ck33Names = {
    [CK33Levels.GROUP]: 'Group',
    [CK33Levels.SYSTEM]: 'System',
    [CK33Levels.ASSEMBLY]: 'Assembly',
    [CK33Levels.COMPONENT]: 'Component',
}

export type GetCk33InfoArgs = AuthStore

export const getCk33Info = (auth: GetCk33InfoArgs, excludeLevelAndBelow?: number) => {
    const maxLevel = auth.companySettings.ck33Level
    const defaultCk33Levels = [...(auth.companySettings.ck33GroupsEnabled ? [0] : []), 1, 2, 3]

    const levels = defaultCk33Levels.filter((level) => {
        if (typeof maxLevel === 'number' && level > maxLevel) {
            return false
        }

        if (typeof excludeLevelAndBelow === 'number' && excludeLevelAndBelow <= level) {
            return false
        }

        return true
    })

    return {
        levels,
    }
}
