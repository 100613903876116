/* eslint-disable @typescript-eslint/default-param-last */
import { useRef } from 'react'

import { type DeleteParams, type MutationMode, type RaRecord, useEvent } from 'react-admin'
import {
    useMutation,
    type UseMutationOptions,
    type UseMutationResult,
    type MutateOptions,
    type QueryKey,
} from 'react-query'

import { useDataProvider } from 'core/data'

/* data provider*/

export const useDelete = <RecordType extends RaRecord = any, MutationError = unknown>(
    resource?: string,
    params: Partial<DeleteParams<RecordType>> = {},
    options: UseDeleteOptions<RecordType, MutationError> = {},
): UseDeleteResult<RecordType, MutationError> => {
    const dataProvider = useDataProvider()
    const reactMutationOptions = options

    const paramsRef = useRef<Partial<DeleteParams<RecordType>>>(params)
    const snapshot = useRef<Snapshot>([])

    const mutation = useMutation<
        RecordType,
        MutationError,
        Partial<UseDeleteMutateParams<RecordType>>
    >(
        ({
            resource: callTimeResource = resource,
            id: callTimeId = paramsRef.current.id,
            previousData: callTimePreviousData = paramsRef.current.previousData,
            meta: callTimeMeta = paramsRef.current.meta,
        } = {}) =>
            dataProvider
                .delete<RecordType>(callTimeResource, {
                    id: callTimeId,
                    previousData: callTimePreviousData,
                    meta: callTimeMeta,
                })
                .then(({ data }) => data),
        {
            ...reactMutationOptions,
            onMutate: async (variables: Partial<UseDeleteMutateParams<RecordType>>) => {
                if (reactMutationOptions.onMutate) {
                    const userContext = (await reactMutationOptions.onMutate(variables)) || {}
                    return {
                        snapshot: snapshot.current,
                        // @ts-ignore
                        ...userContext,
                    }
                }
                // Return a context object with the snapshot value
                return { snapshot: snapshot.current }
            },
            onError: (
                error: MutationError,
                variables: Partial<UseDeleteMutateParams<RecordType>> = {},
                context: { snapshot: Snapshot },
            ) => {
                if (reactMutationOptions.onError) {
                    return reactMutationOptions.onError(error, variables, context)
                }
                // call-time error callback is executed by react-query
            },
            onSuccess: (
                data: RecordType,
                variables: Partial<UseDeleteMutateParams<RecordType>> = {},
                context: unknown,
            ) => {
                if (reactMutationOptions.onSuccess) {
                    reactMutationOptions.onSuccess(data, variables, context)
                }
                // call-time success callback is executed by react-query
            },
            onSettled: (
                data: RecordType,
                error: MutationError,
                variables: Partial<UseDeleteMutateParams<RecordType>> = {},
                context: { snapshot: Snapshot },
            ) => {
                if (reactMutationOptions.onSettled) {
                    return reactMutationOptions.onSettled(data, error, variables, context)
                }
            },
        },
    )

    const mutate = async (
        callTimeResource: string = resource,
        callTimeParams: Partial<DeleteParams<RecordType>> = {},
        updateOptions: MutateOptions<
            RecordType,
            MutationError,
            Partial<UseDeleteMutateParams<RecordType>>,
            unknown
        > & { mutationMode?: MutationMode } = {},
    ) => {
        const { onSuccess, onSettled, onError } = updateOptions

        // store the hook time params *at the moment of the call*
        // because they may change afterwards, which would break the undoable mode
        // as the previousData would be overwritten by the optimistic update
        paramsRef.current = params

        return mutation.mutate(
            { resource: callTimeResource, ...callTimeParams },
            { onSuccess, onSettled, onError },
        )
    }

    return [useEvent(mutate), mutation]
}

type Snapshot = [key: QueryKey, value: any][]

export interface UseDeleteMutateParams<RecordType extends RaRecord = any> {
    resource?: string
    id?: RecordType['id']
    data?: Partial<RecordType>
    previousData?: any
    meta?: any
}

export type UseDeleteOptions<
    RecordType extends RaRecord = any,
    MutationError = unknown,
> = UseMutationOptions<RecordType, MutationError, Partial<UseDeleteMutateParams<RecordType>>> & {
    mutationMode?: MutationMode
}

export type UseDeleteResult<RecordType extends RaRecord = any, MutationError = unknown> = [
    (
        resource?: string,
        params?: Partial<DeleteParams<RecordType>>,
        options?: MutateOptions<
            RecordType,
            MutationError,
            Partial<UseDeleteMutateParams<RecordType>>,
            unknown
        > & { mutationMode?: MutationMode },
    ) => Promise<void>,
    UseMutationResult<
        RecordType,
        MutationError,
        Partial<DeleteParams<RecordType> & { resource?: string }>,
        unknown
    >,
]
