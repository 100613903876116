import { type InfoCardDetailType } from 'components'
import { type UnitModel } from 'resources/units'

export const getDetails = (details: InfoCardDetailType<UnitModel>['details'], n: number) => {
    if (!Array.isArray(details)) {
        return { visibleDetails: details, collapsibleDetails: [] }
    }
    const visibleDetails = details.slice(0, n)

    const collapsibleDetails = details.slice(n)

    return { visibleDetails, collapsibleDetails }
}
