import { type FC } from 'react'

import { type WithChildrenElement } from 'appTypes'
import { Tooltip, tooltipClasses } from 'ui'

const SidebarTooltip: FC<WithChildrenElement & { title: string }> = ({ children, title }) => {
    return (
        <Tooltip
            title={title}
            placement="right"
            PopperProps={{
                sx: {
                    [`& .${tooltipClasses.tooltip}`]: {
                        margin: '0 !important',
                    },
                },
            }}
        >
            {children}
        </Tooltip>
    )
}

export default SidebarTooltip
