import { styled } from 'lib'
import { Stack } from 'ui'

const ColumnsContainer = styled(Stack)`
    height: 61px;
    gap: 8px;
    flex: 1;
    padding: 4px;
    align-items: baseline;
`
export default ColumnsContainer
