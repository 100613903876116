import { type FC } from 'react'

import { unitFields } from 'resources/units'
import { woFields, type WorkOrderModel } from 'resources/workOrders'
import { AutoGrid } from 'ui'

interface Props {
    workOrder: WorkOrderModel
}
const WorkOrderSubtitle: FC<Props> = ({ workOrder }) => {
    if (!workOrder) {
        return null
    }

    return (
        <AutoGrid.Row>
            <AutoGrid.Column>{woFields.status.value(workOrder)}</AutoGrid.Column>
            <AutoGrid.Column>
                {unitFields.self.linkWithIconValue(workOrder.unitData)}
            </AutoGrid.Column>
        </AutoGrid.Row>
    )
}

export default WorkOrderSubtitle
