import { type NoInfer } from 'appTypes'
import {
    ListFilterRangeInput,
    formatMoney,
    getDistanceLabel,
    type Column,
    type ListFilterChoice,
    costMaskParams,
} from 'components'

import { type UnitModel } from '../types'

const source = 'costPerDistance' satisfies keyof UnitModel

const label = () => `Cost per ${getDistanceLabel({ textCase: 'capital' })}`

const tableColumn = <Source extends string = typeof source>({
    id,
    dataToValue,
}: {
    id?: Source
    dataToValue: (data) => UnitModel[typeof source]
}) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: label(),
        renderCell: ({ row }) => formatMoney(dataToValue(row)),
        align: 'right',
    }) as const satisfies Column

const sort = <Source extends string = typeof source>({ id }: { id?: Source } = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label: label(),
    }) as const

const filter = () =>
    ({
        id: source,
        label: label(),
        filterType: 'range',
        renderComponent: (props) => (
            <ListFilterRangeInput
                inputProps={costMaskParams}
                {...props}
            />
        ),
    }) as const satisfies ListFilterChoice

export const costPerDistance = {
    source,
    tableColumn,
    sort,
    filter,
}
