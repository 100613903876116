import { type ReactNode } from 'react'

import { type ExtendRecordType } from 'appTypes'
import { InfoCard, InfoCardDetails, InfoCardHeader } from 'components'

import { type PartModel } from '../types'

const PartDetailsCard = ({
    details,
}: {
    details: {
        label: React.ReactNode
        source: ExtendRecordType<PartModel, ReactNode>
    }[]
}) => {
    return (
        <InfoCard>
            <InfoCardHeader title="Basic Details" />
            <InfoCardDetails details={[...details]} />
        </InfoCard>
    )
}
export default PartDetailsCard
