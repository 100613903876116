import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import { type Identifier } from 'appTypes'
import {
    type CardListConfig,
    type DatagridColumnsProps,
    type FilterConfig,
    LinkArrowButton,
    List,
    ListBase,
    ListFilterDateRangeValueInput,
    ListFilterNoResults,
    type ListSortContentProps,
    resolveIntegerSpacedMask,
    ResourceLinkButton,
} from 'components'
import { urls } from 'configs'
import { ResourceContextProviderWithClearEffect, type SortPayload } from 'core'
import { type AuthStore } from 'core/auth'
import { formatDate } from 'lib'
import { getLeft } from 'pages/Units/PM/UnitPMCard/BottomSection'
import { type PmModel, pmResource } from 'resources/pm'
import { tagFields } from 'resources/tags'
import { getMeterTypeAdornment, type UnitModel } from 'resources/units'
import unitFields from 'resources/units/fields'
import { ck33Fields, ck2Fields, ck14Fields, ck34Fields } from 'resources/vmrs'
import { woFields } from 'resources/workOrders'
import { PageContent, Typography, BoxContainer } from 'ui'
import { capitalizeWithLowerCase } from 'utils'

import { ReportsHeader } from '../components'
import IntervalsShowMoreButton, { StatusSection } from '../components/IntervalsShowMoreButton'
import { getUnitPrefix } from '../utils'

const defaultSort: SortPayload = {
    field: 'status',
    order: 'DESC',
}
const timeIntervalAdornment = (value: number, adornment: string) => {
    if (value === 1) {
        return adornment.toLocaleLowerCase()
    }
    return adornment.toLocaleLowerCase() + 's'
}
const sortCfg: ListSortContentProps<
    PmModel & { unit: string; workOrder: string; dependency: string; status: string }
> = {
    sortBy: [
        { id: 'name', label: 'Name' },
        { id: 'unit', label: 'Unit Number' },
        {
            id: 'component',
            label: 'Component',
        },
        { id: 'reasonForRepair', label: 'Reason for Repair' },
        { id: 'workOrder', label: 'WOs Attached' },
        { id: 'dependency', label: 'Dependency ' },
        { id: 'lastDone', label: 'Last Done Date' },
        { id: 'status', label: 'Status' },
    ],
}

const getUnitFilters: (auth: AuthStore) => FilterConfig<UnitModel>['filters'] = (auth) =>
    [
        unitFields.created.filter({ id: getUnitPrefix(unitFields.created.source) }),
        unitFields.name.filter({
            label: unitFields.name.longLabel,
            id: getUnitPrefix(unitFields.name.source),
        }),
        unitFields.domicile.filter({
            label: 'Unit Domicile',
            auth,
        }),
        unitFields.vin.filter({ id: getUnitPrefix(unitFields.vin.source) }),
        unitFields.licensePlate.filter({ id: getUnitPrefix(unitFields.licensePlate.source) }),
        unitFields.status.filter({
            label: 'Unit Status',
            id: getUnitPrefix(unitFields.status.source),
        }),
        ck2Fields.self.filter({ id: 'unitVmrsEquipmentCategory', label: 'Equipment Category' }),
        ck34Fields.self.filter({ id: 'unitVmrsManufacturer', label: 'Manufacturer/Make' }),
        unitFields.model.filter({ id: getUnitPrefix(unitFields.model.source) }),
        unitFields.modelYear.filter({ id: getUnitPrefix(unitFields.modelYear.source) }),
        ck34Fields.self.filter({ id: 'unitEngineVmrsManufacturer', label: 'Engine Make' }),
        unitFields.engineModel.filter({ id: getUnitPrefix(unitFields.engineModel.source) }),
        unitFields.engineHp.filter({ id: getUnitPrefix(unitFields.engineHp.source) }),
        ck34Fields.self.filter({
            id: 'unitTransmissionVmrsManufacturer',
            label: 'Transmission Make',
        }),
        unitFields.transmissionModel.filter({
            id: getUnitPrefix(unitFields.transmissionModel.source),
        }),
        unitFields.transmissionGears.filter({
            id: getUnitPrefix(unitFields.transmissionGears.source),
        }),
        unitFields.color.filter({ id: getUnitPrefix(unitFields.color.source) }),
        unitFields.tireSize.filter({ id: getUnitPrefix(unitFields.tireSize.source) }),
    ] as FilterConfig<UnitModel>['filters']

const navigateTo = (id: Identifier) => `${urls.units}/${id}/pm`

const PMIntervals: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const filtersCfg: FilterConfig<
            PmModel &
                UnitModel & {
                    unit: string
                    workOrder: string
                    dependency: string
                }
        > = {
            filters: [
                {
                    id: 'name',
                    label: 'Name',
                },
                {
                    id: 'unit',
                    label: 'Unit Number',
                },
                ck33Fields.self.filter<PmModel>({
                    id: 'component',
                }),
                {
                    id: 'reasonForRepair',
                    label: 'Reason for Repair',
                },
                {
                    id: 'workOrder',
                    label: 'WOs Attached',
                },
                {
                    id: 'dependency',
                    label: 'Dependency ',
                    makeItemLabel: ({ id }) =>
                        typeof id === 'string'
                            ? capitalizeWithLowerCase(id as string)
                            : `Position ${id}`,
                },
                {
                    id: 'lastDone',
                    label: 'Last Done Date',
                    filterType: 'range',
                    renderComponent: (props) => <ListFilterDateRangeValueInput {...props} />,
                },
                {
                    id: 'status',
                    label: 'Status',
                    makeItemLabel: ({ id }) => {
                        const label = capitalizeWithLowerCase(id as string)
                        return label === 'Due' ? label + ' Soon' : label
                    },
                },
            ],
        }

        filtersCfg.filters.push(
            ...getUnitFilters(auth).filter(Boolean),
            tagFields.self.filter({ id: 'unitTags' as keyof PmModel }),
        )

        const columnsCfg: DatagridColumnsProps<
            PmModel & {
                lastService: string
                nextService: string
                left: string
                domicile: string
            }
        > = {
            checkboxSelection: false,
            resetColumns: { domicile: false },
            mainField: 'name',
            columns: [
                {
                    field: 'name',
                    headerName: 'Name',
                    renderCell: ({ row }) => (
                        <ResourceLinkButton to={navigateTo(row.unitData.id)}>
                            {row.name}
                        </ResourceLinkButton>
                    ),
                },
                {
                    field: 'unitData',
                    headerName: 'Unit Number',
                    renderCell: ({ value }) => (
                        <ResourceLinkButton to={`${urls.units}/${value.id}`}>
                            {value.number}
                        </ResourceLinkButton>
                    ),
                },
                unitFields.domicile.tableColumn({
                    label: 'Unit Domicile',
                    auth,
                    dataToValue: (row: PmModel) => row.unitData?.domicileData?.name,
                }),
                {
                    field: 'componentData',
                    headerName: 'Component',
                    valueFormatter: ({ value }) => ck33Fields.self.value(value),
                },
                {
                    field: 'reasonForRepairData',
                    headerName: 'Reason for Repair',
                    valueFormatter: ({ value }) => ck14Fields.self.value(value),
                },
                {
                    field: 'workOrderData',
                    headerName: 'WOs Attached',
                    renderCell: ({ row }) => woFields.self.linkValue(row.workOrderData),
                },
                {
                    field: 'rank',
                    headerName: 'Dependency',
                    valueFormatter: ({ value }) => (value ? `Position ${value}` : 'Independent'),
                },
                {
                    field: 'lastDone',
                    headerName: 'Last Done Date',
                    valueFormatter: ({ value }) =>
                        formatDate(value, (dateFormats) => dateFormats.shortenedDate),
                },
                {
                    field: 'intervals',
                    headerName: 'Interval',
                    renderCell: ({ value, row }) => (
                        <BoxContainer
                            component="span"
                            gap="10px"
                        >
                            <Typography
                                component="span"
                                color="inherit"
                                variant="inherit"
                            >
                                {resolveIntegerSpacedMask(value[0].value)}{' '}
                                {value[0].type === 'TIME'
                                    ? timeIntervalAdornment(value[0].value, value[0].valueType)
                                    : getMeterTypeAdornment(value[0].type)}
                            </Typography>
                            <IntervalsShowMoreButton record={row} />
                        </BoxContainer>
                    ),
                },

                {
                    field: 'lastService',
                    headerName: 'Last Service',
                    renderCell: ({ row }) =>
                        row.intervals[0].type === 'TIME' ? (
                            formatDate(row.lastDone, (dateFormats) => dateFormats.shortenedDate)
                        ) : (
                            <>
                                {resolveIntegerSpacedMask(row.intervals[0].lastDoneMeterValue)}{' '}
                                {getMeterTypeAdornment(row.intervals[0].type)}
                            </>
                        ),
                },
                {
                    field: 'nextService',
                    headerName: 'Next Service',
                    renderCell: ({ row }) =>
                        row.intervals[0].type === 'TIME' ? (
                            formatDate(
                                row.intervals[0].overdue,
                                (dateFormats) => dateFormats.shortenedDate,
                            )
                        ) : (
                            <>
                                {resolveIntegerSpacedMask(row.intervals[0].overdue)}{' '}
                                {getMeterTypeAdornment(row.intervals[0].type)}
                            </>
                        ),
                },
                {
                    field: 'left',
                    headerName: 'Left',
                    renderCell: ({ row }) => {
                        const left = getLeft(row.intervals[0])
                        return (
                            <BoxContainer
                                textOverflow="ellipsis"
                                overflow="hidden"
                                whiteSpace="nowrap"
                                gap="5px"
                            >
                                <Typography
                                    overflow="hidden"
                                    color="inherit"
                                    variant="inherit"
                                >
                                    {left} {getMeterTypeAdornment(row.intervals[0].type)}
                                </Typography>
                                {row.intervals[0].status === 'OVERDUE' && left[0] !== '0'
                                    ? 'overdue'
                                    : ''}
                            </BoxContainer>
                        )
                    },
                },
                {
                    field: 'mostUrgentStatus',
                    headerName: 'Status',
                    renderCell: ({ row }) => (
                        <StatusSection
                            gap="6px"
                            status={row.intervals[0].status}
                            colorful
                        />
                    ),
                },
            ],
            actions: null,
        }
        const cardsCfg: CardListConfig<
            PmModel & {
                lastService: string
                nextService: string
                left: string
            }
        > = {
            titleSource: 'name',
            defaultImage: null,
            titleLink: (record) => navigateTo(record.unitData.id),
            details: [
                {
                    source: 'unitData',
                    label: 'Unit Number',
                    render: (value) => (
                        <ResourceLinkButton to={`${urls.units}/${value.id}`}>
                            {value.number}
                        </ResourceLinkButton>
                    ),
                },
                {
                    source: 'componentData',
                    label: 'Component',
                    render: ck33Fields.self.value,
                },
                {
                    source: 'reasonForRepairData',
                    label: 'Reason for Repair',
                    render: ck14Fields.self.value,
                },
                {
                    source: 'workOrderData',
                    label: 'WOs Attached',
                    render: (_, record) => woFields.self.linkValue(record.workOrderData),
                },
                {
                    source: 'rank',
                    label: 'Dependency',
                    render: (value) => (value ? `Position ${value}` : 'Independent'),
                },
                {
                    source: 'lastDone',
                    label: 'Last Done Date',
                    render: (value) =>
                        formatDate(value, (dateFormats) => dateFormats.shortenedDate),
                },
                {
                    source: 'intervals',
                    label: 'Interval',
                    render: (value, record) => (
                        <BoxContainer
                            component="span"
                            gap="10px"
                        >
                            <Typography component="span">
                                {resolveIntegerSpacedMask(value[0].value)}{' '}
                                {value[0].type === 'TIME'
                                    ? timeIntervalAdornment(value[0].value, value[0].valueType)
                                    : getMeterTypeAdornment(value[0].type)}
                            </Typography>
                            <IntervalsShowMoreButton record={record} />
                        </BoxContainer>
                    ),
                },
                {
                    source: 'lastService',
                    label: 'Last Service',
                    render: (_, record) =>
                        record.intervals[0].type === 'TIME' ? (
                            formatDate(record.lastDone, (dateFormats) => dateFormats.shortenedDate)
                        ) : (
                            <>
                                {resolveIntegerSpacedMask(record.intervals[0].lastDoneMeterValue)}{' '}
                                {getMeterTypeAdornment(record.intervals[0].type)}
                            </>
                        ),
                },
                {
                    source: 'nextService',
                    label: 'Next Service',
                    render: (_, record) =>
                        record.intervals[0].type === 'TIME' ? (
                            formatDate(
                                record.intervals[0].overdue,
                                (dateFormats) => dateFormats.shortenedDate,
                            )
                        ) : (
                            <>
                                {resolveIntegerSpacedMask(record.intervals[0].overdue)}{' '}
                                {getMeterTypeAdornment(record.intervals[0].type)}
                            </>
                        ),
                },
                {
                    source: 'left',
                    label: 'Left',
                    render: (_, record) => {
                        const left = getLeft(record.intervals[0])
                        return (
                            <>
                                {left} {getMeterTypeAdornment(record.intervals[0].type)}{' '}
                                {record.intervals[0].status === 'OVERDUE' && left[0] !== '0'
                                    ? 'overdue'
                                    : ''}
                            </>
                        )
                    },
                },
                {
                    source: 'mostUrgentStatus',
                    label: 'Status',
                    render: (_, record) => (
                        <StatusSection
                            gap="6px"
                            status={record.intervals[0].status}
                            colorful
                        />
                    ),
                },
            ],
            action: (record) => <LinkArrowButton path={navigateTo(record.unitData.id)} />,
        }

        return (
            <ResourceContextProviderWithClearEffect value={pmResource}>
                <ListBase sort={defaultSort}>
                    <ReportsHeader>PM Intervals</ReportsHeader>
                    <PageContent>
                        <List
                            exportFileName="pm-intervals"
                            renderNoResults={() => (
                                <ListFilterNoResults
                                    disableFilter
                                    subtitle={
                                        <>
                                            No results match your criteria.
                                            <br />
                                            Try modifying the search or filters.
                                        </>
                                    }
                                />
                            )}
                            filtersCfg={filtersCfg}
                            sortCfg={sortCfg}
                            columnsCfg={columnsCfg}
                            cardsCfg={cardsCfg}
                        />
                    </PageContent>
                </ListBase>
            </ResourceContextProviderWithClearEffect>
        )
    }),
)

export default PMIntervals
