import { type FC, type ReactElement } from 'react'

import { type Identifier } from 'appTypes'
import { useOpenUtilityDrawer, UtilityDrawerEditor } from 'components'
import { getResource } from 'pages/Invoices/LineItems/components/utils'

import ManageTaxForm from './ManageTaxForm'

interface Props {
    children: (params: () => void) => ReactElement
    id: Identifier
    invoiceId: Identifier
    readOnly?: boolean
}

const ManageTaxDrawerToggler: FC<Props> = ({ children, invoiceId, id, readOnly }) => {
    const open = useOpenUtilityDrawer()
    const resource = getResource(invoiceId)

    return children(() => {
        open({
            extraArgs: {
                resource,
                id,
            },
            drawerArgs: {
                title: readOnly ? 'View Tax' : 'Edit Tax',
                renderWrapper: (params) => <UtilityDrawerEditor {...params} />,
                renderContent: () => <ManageTaxForm isInputDisabled={() => readOnly} />,
                renderTopRight: readOnly ? null : (render) => render(),
                renderBottomRight: readOnly ? null : (render) => render(),
            },
        })
    })
}

export default ManageTaxDrawerToggler
