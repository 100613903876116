import { useRecordContext } from 'react-admin'

import { type IssueModel } from 'resources/issues'
import { Section } from 'ui'

import DetailsCard, { detailsCardSkeleton } from './DetailsCard'
import DetailsHeader from './DetailsHeader'
import ReasonForResolveCard from './ReasonForResolveCard'

const DetailsSection = () => {
    const record = useRecordContext<IssueModel>()

    return (
        <Section>
            <DetailsHeader />
            {record ? (
                <>
                    <ReasonForResolveCard />
                    <DetailsCard />
                </>
            ) : (
                detailsCardSkeleton
            )}
        </Section>
    )
}

export default DetailsSection
