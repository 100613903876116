import { type DeleteOneParams } from 'core'

import { type InvoiceModel } from '../types'

export const deleteInvoiceTitle = 'Are you sure you want to delete the selected Invoices?'

export const deleteInvoiceParams = (record: InvoiceModel): DeleteOneParams => ({
    confirmConfig: {
        title: deleteInvoiceTitle,
        content: `This invoice contains ${record.itemsCount} line items.`,
    },
})
