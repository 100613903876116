import { type FC, type ReactElement } from 'react'

import images from 'assets/images'
import NoResultsCard from 'components/NoResultsCard'
import { type NoResultsCardProps } from 'components/NoResultsCard/NoResultsCard'
import { withColor } from 'lib'
import { Box, Typography } from 'ui'

export interface UtilityDrawerNoResultsProps {
    title?: string
    text?: string | ReactElement
    image?: NoResultsCardProps['imageSrc']
    imageWidth?: NoResultsCardProps['imageProps']['width']
}

const UtilityDrawerNoResults: FC<UtilityDrawerNoResultsProps> = ({
    title = 'No Results',
    text,
    image = images.listFilterNoResult,
    imageWidth,
}) => {
    return (
        <Box
            display="flex"
            height="100%"
            alignItems="center"
            justifyContent="center"
        >
            <NoResultsCard
                transparent
                height="auto"
                title={title}
                gridItem={false}
                subtitle={
                    <Typography
                        variant="body1"
                        color={withColor('text.secondary')}
                    >
                        {text}
                    </Typography>
                }
                imageSrc={image}
                imageProps={{
                    mb: '16px',
                    width: imageWidth || '140px',
                }}
            />
        </Box>
    )
}

export default UtilityDrawerNoResults
