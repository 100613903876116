import { makeid } from 'utils'

import { type NavItemConfig } from './Sidebar'

export const createMenu = (items: NavItemConfig[]): NavItemConfig[] => {
    return items.map((item) => {
        const _item = {
            ...item,
            key: item.key || makeid(),
        }

        if (item.items) {
            _item.items = createMenu(item.items)
        }

        if (item.path) {
            _item.path = item.path.trim()
        }

        return _item
    })
}
