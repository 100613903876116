import { type ShopModel } from 'resourcesBase'

const source = 'email' as const satisfies keyof ShopModel

interface Data extends Pick<ShopModel, 'email'> {}

const value = (params: Data) => {
    if (!params) {
        return ''
    }
    return params.email
}

const emailAddress = {
    source,
    label: 'Email Address',
    value,
}

export default emailAddress
