import {
    InfoCardDetails,
    InfoCardHeader,
    CollapsibleInfoCard,
    CollapsibleContent,
    infoCardDetailsGap,
    CollapsibleTogglerAction,
    type InfoCardDetailType,
} from 'components'
import { type UnitModel } from 'resources/units'
import { ck2Fields, ck34Fields } from 'resources/vmrs'

import { getDetails } from './utils'
const details: InfoCardDetailType<UnitModel>['details'] = [
    {
        label: 'Equipment',
        source: ({ vmrsEquipmentCategoryData }) => ck2Fields.self.value(vmrsEquipmentCategoryData),
    },
    {
        label: 'Manufacturer',
        source: ({ vmrsManufacturerData }) => ck34Fields.self.value(vmrsManufacturerData),
    },
    {
        label: 'Model',
        source: 'model',
    },
    {
        label: 'Model Year',
        source: 'modelYear',
    },
    {
        label: 'Color',
        source: 'color',
    },
    {
        label: 'Tire Size',
        source: 'tireSize',
    },
]
const Specifications = ({ maxVisibleColumns }: { maxVisibleColumns: number }) => {
    const { visibleDetails, collapsibleDetails } = getDetails(details, maxVisibleColumns)
    return (
        <CollapsibleInfoCard>
            <InfoCardHeader
                title="Specifications"
                action={collapsibleDetails.length ? <CollapsibleTogglerAction /> : undefined}
            />
            <InfoCardDetails<UnitModel> details={visibleDetails} />
            {collapsibleDetails.length ? (
                <CollapsibleContent>
                    <InfoCardDetails<UnitModel>
                        mt={infoCardDetailsGap}
                        details={collapsibleDetails}
                    />
                </CollapsibleContent>
            ) : null}
        </CollapsibleInfoCard>
    )
}

export default Specifications
