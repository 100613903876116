import { type FC } from 'react'

import { Header, MainHeader } from 'components'

const DashboardHeader: FC = () => {
    return (
        <MainHeader>
            <Header.Title>Dashboard</Header.Title>
        </MainHeader>
    )
}

export default DashboardHeader
