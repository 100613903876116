import { type FC } from 'react'

import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import { ListTotalBadge, ViewHeader } from 'components'
import { emptyOptionValue } from 'core'
import {
    type IssueCreate,
    IssueDrawerToggler,
    IssuesList,
    IssuesListBase,
} from 'pages/Issues/components'
import { customerFields } from 'resources/customers'
import { isUnitArchived, unitFields, type UnitModel } from 'resources/units'
import { IconBox, PageContent } from 'ui'

import { createIssueDefaulValues } from './utils'

export const getIssuesCreateConfig: (record: UnitModel) => IssueCreate = (record) => ({
    defaultValues: {
        unit: record?.id,
        customer: record?.customer,
    },
    isInputDisabled: (source) => {
        if (source === 'unit') {
            return true
        }
    },
})
const UnitIssues = () => {
    const record = useRecordContext<UnitModel>()
    const createConfig = getIssuesCreateConfig(record)
    if (!record) {
        return null
    }

    const disabled = isUnitArchived(record)

    return (
        <IssuesListBase
            filter={{
                [unitFields.self.source]: record.number,
                [customerFields.self.source]: record?.customerData?.name || emptyOptionValue,
            }}
            preferencesName="unit-issues"
        >
            <PageContent>
                <ViewHeader
                    title="Issues"
                    loading={!record}
                >
                    <ViewHeader.Content>
                        <ListTotalBadge />
                    </ViewHeader.Content>
                    <ViewHeader.Content placement="rightMobile">
                        <ActionAdd create={createConfig} />
                    </ViewHeader.Content>
                </ViewHeader>
                <IssuesList
                    create={createConfig}
                    filter={false}
                    disableExport
                    disableActions={disabled}
                    listFTUProps={{
                        queryParams: createIssueDefaulValues(record),
                    }}
                    disableLink={disabled}
                />
            </PageContent>
        </IssuesListBase>
    )
}

export default UnitIssues

const ActionAdd: FC<{ create: IssueCreate }> = ({ create }) => {
    const record = useRecordContext<UnitModel>()

    if (isUnitArchived(record)) {
        return null
    }

    return (
        <IssueDrawerToggler {...create}>
            {(action) => (
                <IconBox
                    title="Create Issue"
                    onClick={action}
                >
                    <Icons.Add />
                </IconBox>
            )}
        </IssueDrawerToggler>
    )
}
