import { type ReactNode } from 'react'

import { type PaginationPayload } from 'react-admin'

import { type ExtendRecordType } from 'appTypes'
import { type SortPayload } from 'core/types'

export const extendRecord = <T = any, Return = ReactNode>(
    record: T,
    source: ExtendRecordType<T, Return>,
) => {
    if (typeof source === 'function') {
        return source(record)
    }

    if (!record) {
        return ''
    }

    return record[source as keyof T]
}

export const getListData = <T = any>(list: { hasPreviousPage?: boolean }): T => {
    return ((list?.hasPreviousPage as any)?.aggregates || {}) as T
}

export const filterSearchText = 'text'

export const emptySelectValue = 'empty-value'

export const perPageLimit = 1000

export const emptyOptionValue = '__EMPTY__'

export const updatedMessage = 'Successfully updated'
export const createMessage = 'Successfully created'
export const defaultPagination: PaginationPayload = { page: 1, perPage: 25 }
export const defaultSort: SortPayload = { field: 'id', order: 'DESC' }
export const defaultFilter = {}

export interface DisplayedFilters {
    info: {
        period: 'custom'
    }
}
