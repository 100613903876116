import { alpha, styled } from 'lib'

import { badgeClasses, type BadgeProps as BadgeBaseProps } from './Badge'
import BadgeText from './BadgeText'

export interface BadgeProps extends BadgeBaseProps {}

const InfoBadge = styled((props: BadgeProps) => {
    if (typeof props.badgeContent === 'undefined') {
        return null
    }
    return (
        <BadgeText
            {...props}
            badgeContent={
                typeof props.badgeContent === 'object'
                    ? props.badgeContent
                    : String(props.badgeContent)
            }
        />
    )
})<BadgeProps>`
    & .${badgeClasses.badge} {
        background-color: ${({ theme }) => alpha(theme.palette.text.main, 0.08)};
    }
`

InfoBadge.defaultProps = {
    max: Infinity,
    variant: 'standard',
}

export default InfoBadge
