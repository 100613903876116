import { useTheme } from 'react-admin'

import { getTheme } from 'components'
import { type PaletteMode } from 'lib'
import { Button } from 'ui'
import { capitalize } from 'utils'

// This is for test
const ThemeButton = ({ mode }: { mode: PaletteMode | 'system' }) => {
    const [, setTheme] = useTheme()
    // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
    // const preferedMode = prefersDarkMode ? 'dark' : 'light'

    // getTheme(mode === 'system' ? preferedMode : mode)

    return <Button onClick={() => setTheme(getTheme())}>{capitalize(mode)}</Button>
}

export default ThemeButton
