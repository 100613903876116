import { type FC, type ReactElement, type ReactNode } from 'react'

import { useListContext } from 'react-admin'

import NoResultsCard, { ListFtuCardContainer } from 'components/NoResultsCard'
import { filterSearchText } from 'core'
import { Typography, Anchor } from 'ui'

interface ListFilterNoResultsProps {
    disableFilter?: boolean
    title?: string
    subtitle?: string | ReactElement
}

const FilterButtonAction: FC<{ children: ReactNode }> = ({ children }) => {
    const { filterValues, setFilters } = useListContext()
    const newFilter = filterValues[filterSearchText] ? { text: filterValues[filterSearchText] } : {}

    return (
        <Anchor
            component="button"
            underline="none"
            onClick={() => setFilters(newFilter, [])}
        >
            <Typography
                variant="body1"
                mb="2.42px"
                color="primary"
                textAlign="center"
            >
                {children}
            </Typography>
        </Anchor>
    )
}

const ListFilterNoResults = ({
    disableFilter,
    subtitle = 'There are no results that match your criteria.',
    title = 'No Results Found',
}: ListFilterNoResultsProps) => {
    return (
        <ListFtuCardContainer
            container
            sx={{ flex: 1 }}
        >
            <NoResultsCard
                transparent
                height="auto"
                title={title}
                subtitle={subtitle}
                imageSrc={(images) => images.listNotFound}
                imageProps={{
                    mb: {
                        sm: '32px',
                        xs: '16px',
                    },
                    width: {
                        sm: 'auto',
                        xs: '140px',
                    },
                }}
                action={
                    disableFilter ? null : <FilterButtonAction>Clear Filters</FilterButtonAction>
                }
            />
        </ListFtuCardContainer>
    )
}

export default ListFilterNoResults
