import { type ReactElement } from 'react'

import { useRecordContext } from 'react-admin'

import { useClearResource, RaResourceContextProvider } from 'core'
import { getMetersResource } from 'resources/units'

interface UnitMetersResourceProps {
    children: ReactElement
    fallback?: null | ReactElement
}
const UnitMetersResource = ({ children, fallback = null }: UnitMetersResourceProps) => {
    const { id } = useRecordContext() || {}

    const metersResource = getMetersResource(id)

    useClearResource(metersResource.resource)

    if (!id) {
        return fallback
    }

    return <RaResourceContextProvider value={metersResource}>{children}</RaResourceContextProvider>
}

export default UnitMetersResource
