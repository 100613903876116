import { type ReactNode, type FC } from 'react'

import images from 'assets/images'
import { landingPageUrl } from 'configs'
import { styled } from 'lib'
import { Card as CardBase, CardContent, Img, Box, Anchor } from 'ui'

const Card = styled(CardBase)`
    box-shadow: ${({ theme }) => theme.shadows[3]};
    & > * {
        flex-grow: 1;
        flex-basis: 50%;
    }
    width: 356px;
    ${({ theme }) => theme.breakpoints.down('sm')} {
        width: 335px;
    }
`

const StyledImageWrapper = styled('div')`
    width: 100%;
    height: 100vh;
    position: fixed;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`

interface AuthBodyProps {
    children: ReactNode
}

const AuthBody: FC<AuthBodyProps> = ({ children }) => {
    return (
        <>
            <StyledImageWrapper>
                <Img
                    src={images.authBg}
                    sx={{
                        position: 'absolute',
                        height: 'calc(100% + 170px)',
                        top: '-85px',
                        left: 'calc(50% - 216px)',
                    }}
                    alt="background"
                />
            </StyledImageWrapper>
            <Box
                position="relative"
                display="flex"
                justifyContent="center"
                sx={{ typography: 'body1' }}
            >
                <div>
                    <Anchor
                        href={landingPageUrl.root}
                        target="_blank"
                        rel="noreferrer"
                        mb="24px"
                        mt="40px"
                        display="inline-block"
                    >
                        <img
                            src={images.fleetpal}
                            width={162}
                            alt="Fleetpal"
                        />
                    </Anchor>
                    <Card>
                        <CardContent
                            sx={{
                                p: '24px !important',
                            }}
                        >
                            {children}
                        </CardContent>
                    </Card>
                </div>
            </Box>
        </>
    )
}

export default AuthBody
