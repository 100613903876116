import { type FC } from 'react'

import Icons from 'assets/icons'
import { SelectorOption } from 'components'

import { type BaseAddress } from '../types'

interface Props {
    record: BaseAddress
}

const AddressSelectOption: FC<Props> = ({ record }) => {
    const row1 = [record.address, record.address2].filter(Boolean).join(', ')
    const row2End = [record.state, record.zipCode].filter(Boolean).join(' ')
    const row2 = [record.city, row2End].filter(Boolean).join(', ')

    if (!row1 && !row2) {
        return null
    }

    return (
        <SelectorOption.Row
            label="Address"
            Icon={Icons.PlaceOutlined}
        >
            {row1 ? <div>{row1}</div> : null}
            {row2 ? <div>{row2}</div> : null}
        </SelectorOption.Row>
    )
}

export default AddressSelectOption
