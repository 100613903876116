import Icons from 'assets/icons'
import { PurchaseOrderDrawerToggler } from 'pages/PurchaseOrders/components'
import { type PurchaseOrderModel } from 'resources/purchaseOrders'
import { IconBox } from 'ui'

const POEditOpenDrawerButton = ({ record }: { record: PurchaseOrderModel }) => {
    if (record.status === 'CANCELED') {
        return null
    }
    return (
        <PurchaseOrderDrawerToggler
            record={record}
            children={({ onClick }) => (
                <IconBox
                    title="Edit"
                    onClick={onClick}
                >
                    <Icons.Edit />
                </IconBox>
            )}
        />
    )
}

export default POEditOpenDrawerButton
