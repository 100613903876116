import { formatMoney } from 'components'
import { useResizeObserverWidth } from 'hooks'
import { reportsUrls, createReportResource } from 'resources/reports'

import WidgetBase, { WidgetSkeleton } from '../../WidgetBase/WidgetBase'
import { useCreateDashboardWidgetLink, useDashboardWidget } from '../../WidgetsForm'
import { roundWidgetJobsData } from '../utils'

import { Clarification, CostPerUnitList, CostPerUnitChart } from './components'
import { type CostPerUnitResponse } from './types'

const description = `The Maintenance Cost widget calculates the aggregated expenses
associated with all work orders for the selected reporting period.`

let validData = false
let hasData = false
const CostPerUnitWidget = () => {
    const createLink = useCreateDashboardWidgetLink()

    const { ref, match } = useResizeObserverWidth(360)
    const data = useDashboardWidget<CostPerUnitResponse>({
        resource: createReportResource('cost-per-unit'),
        sideEffect: (data) => {
            const finalData = roundWidgetJobsData(data)
            hasData = typeof finalData.total === 'number'
            validData = finalData.total > 0
            return finalData as CostPerUnitResponse
        },
    })
    if (!data) {
        return <WidgetSkeleton />
    }
    const { maxTotalUnits, ...ratioData } = data

    return (
        <WidgetBase
            ref={ref}
            headerProps={{
                title: 'Maintenance Cost',
                label: formatMoney(ratioData?.total),
                tooltipText: description,
                clarification: (
                    <Clarification
                        {...ratioData}
                        showTooltip={match}
                        validData={hasData}
                    />
                ),
                chart: (
                    <CostPerUnitChart
                        {...ratioData}
                        disabled={!validData}
                    />
                ),
            }}
            infoProps={{
                content: (
                    <CostPerUnitList
                        data={maxTotalUnits}
                        description={description}
                        validData={hasData && validData}
                    />
                ),
            }}
            separator="Top Spenders"
            link={createLink(reportsUrls.maintenanceCost)}
        />
    )
}
export default CostPerUnitWidget
