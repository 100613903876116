import Icons from 'assets/icons'
import { alpha, type Theme, Link, globalClassNames, type ThemeColorType } from 'lib'
import { MenuItem, type MenuItemProps, SvgIcon, typographyClasses, Typography, Tooltip } from 'ui'

import { type BaseActionRenderParams } from '../Actions'

import { useActionsMenuContext } from './ActionsMenuContext'

export type ActionsOptionProps = BaseActionRenderParams &
    Omit<MenuItemProps, 'title'> & {
        rightIcon?: boolean
        isOverflow?: boolean
        iconColor?: ThemeColorType
    }

const ActionsOption = ({
    Icon,
    title,
    to,
    onClick,
    titleOnDisabled,
    disableCloseOnClick,
    itemColor,
    rightIcon,
    iconColor,
    ...rest
}: ActionsOptionProps) => {
    const { close } = useActionsMenuContext()
    const content = (
        <span>
            <MenuItem
                {...rest}
                {...(to && { to, component: Link })} // TODO: this makes 'ul > a' in DOM
                onClick={(e) => {
                    onClick?.(e)
                    if (!disableCloseOnClick) {
                        close()
                    }
                }}
                sx={{
                    width: '100%',
                    minHeight: 'unset',
                    height: '36px',
                    [`& > .${typographyClasses.root}`]: {
                        width: '100%',
                        textAlign: 'left',
                    },
                }}
            >
                {Icon ? (
                    <SvgIcon
                        inheritViewBox
                        component={Icon}
                        sx={{
                            width: '24px',
                            height: '24px',
                            marginRight: '18.5px',
                            color: iconColor || itemColor || defaultColor,
                        }}
                    />
                ) : null}
                <Typography
                    color={itemColor || 'text.primary'}
                    className={globalClassNames.ellipsis}
                >
                    {title}
                </Typography>
                {rightIcon ? (
                    <Icons.ArrowRightOutlined
                        sx={{
                            marginLeft: 'auto',
                            color: defaultColor,
                        }}
                    />
                ) : null}
            </MenuItem>
        </span>
    )
    // TODO: this renders a>li inside ul.
    return titleOnDisabled && rest.disabled ? (
        <Tooltip title={titleOnDisabled}>{content}</Tooltip>
    ) : (
        <>{content}</>
    )
}

export default ActionsOption

const defaultColor = (theme: Theme) => alpha(theme.palette.text.primary, 0.54)
