import { useOpenUtilityDrawer } from 'components/Drawer'
import { authStore } from 'core/auth'

import { LimitClear } from './LimitClear'
import { LimitForm } from './LimitForm'
import { LimitFormWrapper } from './LimitFormWrapper'

export const useOpenPaymentLimit = () => {
    const open = useOpenUtilityDrawer()

    return () => {
        const billing = authStore.billing
        const hasLimit = billing.paymentLimit || billing.paymentLimit === 0

        open({
            drawerArgs: {
                title: hasLimit ? 'Edit Limit' : 'Add Limit',
                renderWrapper: (params) => <LimitFormWrapper {...params} />,
                renderContent: () => <LimitForm />,
                renderTopRight: () => <LimitClear />,
                renderBottomRight: (render) => render({ alwaysEnable: true }),
            },
            extraArgs: {
                type: 'edit',
            },
        })
    }
}
