import PMRepositionExplanation from './PMRepositionExplanation'
import PMRepositionList from './PMRepositionList'

const PMRepositionForm = () => {
    return (
        <>
            <PMRepositionExplanation />
            <PMRepositionList />
            <br />
        </>
    )
}

export default PMRepositionForm
