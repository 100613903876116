import { type FC } from 'react'

import { type Breakpoint } from 'lib'

import Grid, { type GridProps, type GridSize } from './Grid'

export interface GridItemLayoutProps extends GridProps {
    gridColumns?: { [K in Breakpoint]?: GridSize }
}

export const GridItemLayout: FC<GridItemLayoutProps> = ({
    gridColumns = gridItemLayoutColumns,
    ...rest
}) => {
    return (
        <Grid
            item
            {...gridColumns}
            {...rest}
        />
    )
}

export const gridItemLayoutColumns: { [K in Breakpoint]?: number } = {
    xs: 12,
    sm: 6,
    md: 5,
    lg: 4,
    xl: 3,
}
export const gridItemLayoutColumnsDoubled = Object.fromEntries(
    Object.entries(gridItemLayoutColumns).map(([key, value]) => [
        key,
        value * 2 > 12 ? 12 : value * 2,
    ]),
)

export const GridItem: FC<GridProps> = (props) => {
    return (
        <Grid
            item
            xs={12}
            {...props}
        />
    )
}

export const GridItemCard: FC<GridProps> = (props) => {
    return (
        <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            xl={2}
            {...props}
        />
    )
}
