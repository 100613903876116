import { SkeletonSliderCardContainer } from 'components'
import { Skeleton } from 'ui'

interface PurchaseOrderCardSkeletonProps {
    height?: `${number}px`
}
const PurchaseOrderCardSkeleton = ({ height }: PurchaseOrderCardSkeletonProps) => {
    return (
        <SkeletonSliderCardContainer
            sx={{
                height,
            }}
        >
            <Skeleton
                sx={{ maxWidth: '120px', mt: '20px', mb: '30px' }}
                height="8px"
            />
            <Skeleton />
        </SkeletonSliderCardContainer>
    )
}
export default PurchaseOrderCardSkeleton
