import { type FC, type ReactNode } from 'react'

import { Link, type LinkProps, styled, type SxProps, withColor } from 'lib'
import { Typography, type TypographyProps, BoxContainer } from 'ui'

import { InfoCard } from './InfoCard'

const StyledInfoCard = styled(InfoCard)`
    height: 119px;
    display: flex;
    justify-content: center;
    align-items: center;
`
interface BoxLinkProps {
    to: LinkProps['to']
    label: ReactNode | string
    linkProps?: Omit<LinkProps, 'to'>
    typographyProps?: TypographyProps
    icon?: ReactNode
    sx?: SxProps
}
const BoxLink: FC<BoxLinkProps> = ({ label, to, linkProps, typographyProps, icon, sx }) => {
    return (
        <StyledInfoCard sx={sx}>
            <Link
                to={to}
                {...linkProps}
            >
                <Typography
                    gap="12px"
                    color={withColor('primary.main')}
                    component={BoxContainer}
                    {...typographyProps}
                >
                    {label}
                    {icon}
                </Typography>
            </Link>
        </StyledInfoCard>
    )
}
export default BoxLink
