import Icons from 'assets/icons'
import { Typography, type TypographyProps } from 'ui'
import { getInitials } from 'utils'

interface DefaultMemberIconProps extends Pick<TypographyProps, 'fontSize' | 'color'> {
    record: { name: string }
}

const MemberIcon = ({ fontSize, record, color = 'primary.main' }: DefaultMemberIconProps) => {
    const initials = getInitials(record.name)

    if (!initials) {
        return (
            <Icons.PersonOutlined
                sx={{
                    color,
                }}
            />
        )
    }

    return (
        <Typography
            color={color}
            fontSize={fontSize}
            lineHeight="100%"
            fontWeight={500}
        >
            {initials}
        </Typography>
    )
}

export default MemberIcon
