import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import { ViewHeader } from 'components'
import { ReasonForCancelation } from 'resources/common'
import { InvoiceDrawerToggler, type InvoiceModel } from 'resources/invoices'
import { IconBox, Section } from 'ui'

import DetailsCard from './DetailsCard'

const DetailsSection = () => {
    const record = useRecordContext<InvoiceModel>()

    return (
        <Section>
            <ViewHeader title="Details">
                <InvoiceDrawerToggler id={record?.id}>
                    {(open) => (
                        <IconBox
                            title="View/Edit"
                            onClick={open}
                        >
                            <Icons.Edit />
                        </IconBox>
                    )}
                </InvoiceDrawerToggler>
            </ViewHeader>
            <ReasonForCancelation />
            <DetailsCard />
        </Section>
    )
}

export default DetailsSection
