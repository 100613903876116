import { type FC } from 'react'

import { CreateButtonView, Header, ListTotalBadge, MainHeader } from 'components'
import { PurchaseOrderDrawerToggler } from 'pages/PurchaseOrders/components'

const PoListHeader: FC = () => {
    return (
        <MainHeader
            mainAction={
                <PurchaseOrderDrawerToggler>
                    {({ onClick }) => (
                        <CreateButtonView onClick={onClick}>Create PO</CreateButtonView>
                    )}
                </PurchaseOrderDrawerToggler>
            }
        >
            <Header.Info>
                <Header.Content>
                    <Header.Title>Purchase Orders</Header.Title>
                </Header.Content>
                <Header.Content mobilePlacement="bottom">
                    <ListTotalBadge />
                </Header.Content>
            </Header.Info>
        </MainHeader>
    )
}

export default PoListHeader
