import { type FC } from 'react'

import { formatMoney } from 'components'
import { getListData } from 'core/data'
import { ck33Aspects, type CK31Aggregates, type CK33Aspects } from 'resources/vmrs'

import { CostPerCK33 } from '../components'

const aspects = [ck33Aspects.totalSystem] satisfies CK33Aspects[]

const CostPerCategoryCK31: FC = () => {
    return (
        <CostPerCK33
            title="Cost per Category"
            preferencesName="cost-per-category"
            aspects={aspects}
            codeLabel="System"
            descriptionLabel="System Description"
            renderTotal={(list) => {
                const listData = getListData<CK31Aggregates>(list)
                return (
                    <>
                        {list.total} • {formatMoney(listData.total || 0)} total | Uncategorized •{' '}
                        {formatMoney(listData.uncategorized || 0)}
                    </>
                )
            }}
            codeValue={(record) => record.code}
            exportFileName="cost-per-category"
        />
    )
}

export default CostPerCategoryCK31
