import name from './name'
import { percentageOnTotal } from './percentageOnTotal'
import self from './self'

const ptTaxFields = {
    name,
    percentageOnTotal,
    self,
}

export default ptTaxFields
