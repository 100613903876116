import { styled } from 'lib'

import Badge, { badgeClasses } from './Badge'

const BadgeText = styled(Badge)`
    position: static;

    & .${badgeClasses.badge} {
        position: static;
        transform: unset;
        z-index: unset;
    }
`

BadgeText.defaultProps = {
    max: Infinity,
}

export default BadgeText
