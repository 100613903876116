import Icons from 'assets/icons'
import { UtilityDrawerSaveButton } from 'components'
import { buttonClasses } from 'ui'

import { maxSteps, useStepsContext } from './UseWOStepsContext'

const SubmitButton = UtilityDrawerSaveButton

const UseWONextButton = () => {
    const { step } = useStepsContext()

    if (step === maxSteps) {
        return <SubmitButton formType="create" />
    }

    return (
        <SubmitButton
            label="Next"
            endIcon={<Icons.ChevronRight />}
            icon={null}
            sx={{
                [`& .${buttonClasses.endIcon} svg`]: {
                    marginRight: 0,
                },
            }}
        />
    )
}

export default UseWONextButton
