import { useState } from 'react'

import { Box } from 'ui'

import SidebarProfileMenu, { UserAvatar } from '../SidebarProfile'

const MobileProfile = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const handleMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <Box
                sx={{ cursor: 'pointer' }}
                role="button"
                aria-label="Sidebar Menu"
                onClick={handleMenuClick}
            >
                <UserAvatar size="24px" />
            </Box>
            <SidebarProfileMenu
                onClose={handleMenuClose}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            />
        </>
    )
}

export default MobileProfile
