import { type FC, type MouseEventHandler, type ReactNode } from 'react'

import { type SvgIconElement } from 'appTypes'
import Icons from 'assets/icons'
import { Link, styled } from 'lib'
import { IconElement, MenuItem, type MenuItemProps } from 'ui'

interface DrawerItemProps extends Pick<MenuItemProps, 'selected'> {
    className?: string
    children: ReactNode
    onClick?: MouseEventHandler
    startIcon?: SvgIconElement
    endIcon?: boolean | SvgIconElement
    to?: string
}

const DrawerItem: FC<DrawerItemProps> = styled(
    ({ children, startIcon, endIcon, to, ...props }: DrawerItemProps) => {
        const content = (
            <MenuItem
                role="button"
                component="div"
                sx={{
                    typography: 'body2',
                }}
                {...props}
            >
                {startIcon ? <Icon component={startIcon} /> : null}
                <Text>{children}</Text>
                {endIcon ? (
                    <Icon component={typeof endIcon === 'boolean' ? Icons.ChevronRight : endIcon} />
                ) : null}
            </MenuItem>
        )

        if (to) {
            return <Link to={to}>{content}</Link>
        }

        return content
    },
)<DrawerItemProps>`
    color: ${({ theme }) => theme.palette.text.primary};
    padding: 12px 16px;
    border-radius: 100px;
    gap: 10px;
    white-space: normal;
`

interface IconProps {
    component: SvgIconElement
}

const Icon: FC<IconProps> = ({ component }) => {
    return (
        <IconElement
            component={component}
            size="24px"
        />
    )
}

const Text = styled('div')`
    color: inherit;
    font-size: inherit;
    flex-grow: 1;
`

export default DrawerItem
