import { type ReactNode, type FC, type ReactElement } from 'react'

import { clsx, cssVariables, globalClassNames, styled } from 'lib'

import headerClasses, { headerCssVariables } from './config'

interface Props {
    children: ReactNode
    className?: string
    avatar?: ReactElement
}

const HeaderInfo: FC<Props> = styled(({ avatar, children, className }: Props) => {
    return (
        <div className={className}>
            {avatar ? <div className={headerClasses.avatarContainer}>{avatar}</div> : null}
            <div className={clsx(headerClasses.mainContainer, globalClassNames.ellipsis)}>
                {children}
            </div>
        </div>
    )
})<Props>`
    display: flex;
    align-items: center;

    & .${headerClasses.mainContainer} {
        gap: var(${cssVariables.gap});
        align-items: center;
        display: inline-grid;
        grid-auto-flow: column;
        grid-auto-columns: auto;
    }

    ${({ theme }) => theme.breakpoints.down(theme.props.mobileViewBreakpoint)} {
        & .${headerClasses.mainContainer} {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            display: grid;
            grid-template-columns: 1fr auto;
            grid-template-rows: auto 1fr;
            gap: 0;

            & .${headerClasses.contentLeft} {
                grid-column: 1 / 2;
                grid-row: 1 / 2;
                overflow: hidden;
            }
            & .${headerClasses.contentRight} {
                grid-column: 2 / 3;
                grid-row: 1 / 2;
                white-space: nowrap;
            }
            & .${headerClasses.contentBottom} {
                grid-column: 1 / 3;
                grid-row: 2 / 3;
            }
        }
    }

    & .${headerClasses.avatarContainer} + .${headerClasses.mainContainer} {
        padding-left: var(${headerCssVariables.primaryGap});
    }
`

export default HeaderInfo
