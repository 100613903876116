import { websiteLabel, websiteValue } from 'resources/common'

import { type CustomerModel } from '../types'

const source = 'website' as const satisfies keyof CustomerModel

interface Data extends Pick<CustomerModel, 'website'> {}

const value = ({ website }: Data) => {
    return websiteValue(website)
}

const website = {
    source,
    label: websiteLabel,
    value,
}

export default website
