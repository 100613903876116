import Icons from 'assets/icons'
import { Link } from 'lib'
import { IconBox } from 'ui'
const SeeAllButton = () => {
    return (
        <Link to="cross-reference">
            <IconBox title="See All">
                <Icons.GridView />
            </IconBox>
        </Link>
    )
}
export default SeeAllButton
