import { useLayoutEffect, useState } from 'react'

import Icons from 'assets/icons'
import { Button } from 'ui'

import { useMultiFormatScannerContext } from './MultiFormatScannerContext'

const MultiFormatScannerFlashButton = () => {
    const [flash, setFlash] = useState<boolean | null>(null)
    const { activeCameraId, streamSettings, applyConstraints } = useMultiFormatScannerContext()

    useLayoutEffect(() => {
        const torchState = (streamSettings as any).torch
        setFlash(typeof torchState === 'boolean' ? torchState : null)
    }, [activeCameraId])

    if (flash === null) {
        return null
    }

    const toggle = async () => {
        await applyConstraints({ torch: !flash } as any)
        setFlash(!flash)
    }

    return (
        <Button
            variant="contained"
            sx={{
                position: 'absolute',
                top: 20,
                left: 20,
            }}
            startIcon={flash ? <Icons.FlashOnOutlined /> : <Icons.FlashOffOutlined />}
            onClick={toggle}
        >
            Flash
        </Button>
    )
}

export default MultiFormatScannerFlashButton
