import { withColor } from 'lib'
import { Divider, BoxContainer, Typography } from 'ui'

const divider = (
    <Divider
        sx={{ flexGrow: 1 }}
        orientation="horizontal"
    />
)

const SignDivider = () => {
    return (
        <BoxContainer
            mb="21px"
            mt="20px"
        >
            {divider}
            <Typography
                variant="body1"
                px="22px"
                color={withColor('secondary.dark')}
            >
                OR
            </Typography>
            {divider}
        </BoxContainer>
    )
}

export default SignDivider
