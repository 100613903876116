import { type FC } from 'react'

import { useRecordContext } from 'react-admin'

import { ShowHeaderSkeleton, Header, InnerHeader, type TabType } from 'components'
import { deleteOneAction } from 'core'
import { customerFields, type CustomerModel } from 'resources/customers'

import { deleteCustomerAction } from '../../config'

export const ShowHeader: FC<{ tabs: TabType[] }> = ({ tabs }) => {
    const record = useRecordContext<CustomerModel>()

    if (!record) {
        return <ShowHeaderSkeleton />
    }

    return (
        <InnerHeader
            tabs={tabs}
            tabWidth="192px"
            tabMinWidth="176px"
            actions={(record, { children }) => [
                deleteOneAction({
                    children,
                    ...deleteCustomerAction,
                }),
            ]}
        >
            <Header.Info
                avatar={
                    <Header.Avatar
                        imageSrc={record.logo}
                        defaultImage={<customerFields.avatar.Icon />}
                    />
                }
            >
                <Header.Content>
                    <Header.Title>{record.name}</Header.Title>
                </Header.Content>
            </Header.Info>
        </InnerHeader>
    )
}
