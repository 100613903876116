import { type NoInfer } from 'appTypes'
import { type Column, type ListFilterChoice, ListFilterValueInput } from 'components'
import { defaultStatuses, options, type StatusConfig } from 'resources/common'

import { type UnitModel, UnitStatusKeys } from '../types'

const source = 'status' as const satisfies keyof UnitModel

const statusLabel = 'Status'
const statusLongName = 'Unit Status'

const statuses = [
    { ...defaultStatuses.closed, id: UnitStatusKeys.IN_SERVICE, text: 'In Service' },
    { ...defaultStatuses.canceled, id: UnitStatusKeys.OUT_OF_SERVICE, text: 'Out of Service' },
] satisfies StatusConfig<UnitStatusKeys>[]

const getConfig = options.createConfig<StatusConfig<UnitStatusKeys>>(statuses)

const value = options.makeIconValue(getConfig)

const tableColumn = <Source extends string = typeof source>({ label }: { label?: string } = {}) =>
    ({
        field: source as NoInfer<Source>,
        headerName: label || statusLabel,
        renderCell: ({ row }) => {
            return value((row as UnitModel).status)
        },
    }) as const satisfies Column

const sort = <Source extends string = typeof source>({
    label: labelProp,
}: { label?: string } = {}) =>
    ({
        id: source as NoInfer<Source>,
        label: labelProp || statusLabel,
    }) as const

const filter = <Source extends string = typeof source>({
    id,
    label: labelProp,
}: { id?: Source; label?: string } = {}) =>
    ({
        id: id || source,
        label: labelProp || statusLabel,
        renderComponent: (props) => (
            <ListFilterValueInput
                {...props}
                inputText={(option) => option.id}
                makeItemLabel={(record) => {
                    return value(record.id as UnitStatusKeys)
                }}
            />
        ),
    }) as const satisfies ListFilterChoice

const status = {
    source,
    label: statusLabel,
    longLabel: statusLongName,
    getConfig,
    value: (data: Pick<UnitModel, 'status'>) => value(data?.status),
    sort,
    filter,
    tableColumn,
    choices: options.createIdChoices(statuses),
}

export default status
