import { ResourceLinkButton } from 'components'
import { globalClassNames } from 'lib'
import { type UnitModel, unitResource, isUnitArchived, ArchivedUnitBadge } from 'resources/units'
import { BoxContainer, StyledElement } from 'ui'

const UnitCardTitleLink = ({ record }: { record: Omit<UnitModel, 'downtime'> }) => {
    return (
        <BoxContainer>
            <StyledElement className={globalClassNames.ellipsis}>
                <ResourceLinkButton
                    to={{
                        id: record.id,
                        type: 'edit',
                        resource: unitResource.resource,
                    }}
                >
                    {record.number}
                </ResourceLinkButton>
            </StyledElement>
            {isUnitArchived(record) && <ArchivedUnitBadge />}
        </BoxContainer>
    )
}

export default UnitCardTitleLink
