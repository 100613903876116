import { differenceInCalendarMonths, subMonths } from 'date-fns'

import Icons from 'assets/icons'
import { useFileDownload, type ActionsOptionProps, ActionsMenu, type ActionChildren } from 'core'
import { formatDate } from 'lib'
import { Stack } from 'ui'

import { type UnitModel } from '../types'
import unitResource from '../unitResource'

interface DownloadRecordActionProps {
    children: ActionChildren<ActionsOptionProps>
    record: UnitModel
}

export const DownloadRecordAction = ({ children, record }: DownloadRecordActionProps) => {
    const months = getPastTwelveMonths(record.archived, record.utilizationStart)
    const downloadOne = useFileDownload()

    return (
        <ActionsMenu
            renderToggler={(open) =>
                children({
                    title: 'Download Maintenance Record',
                    Icon: Icons.ClipboardClock,
                    onClick: open,
                    disabled: months.length === 0,
                    titleOnDisabled: 'No data for previous months',
                    disableCloseOnClick: true,
                    isOverflow: true,
                })
            }
            actions={(_, { children }) => [
                <Stack
                    key="container"
                    width="215px"
                >
                    {months.map((date) =>
                        children({
                            key: date.label,
                            title: date.label,
                            Icon: Icons.ClipboardClock,
                            onClick: () => {
                                downloadOne({
                                    url: `${unitResource.resource}/${record?.id}/pdf`,
                                    filename: `${record.number}-${date.label}`,
                                    type: 'pdf',
                                    queryParams: {
                                        date: date.startDate,
                                    },
                                })
                            },
                        }),
                    )}
                </Stack>,
            ]}
        />
    )
}

interface MonthType {
    label: string
    startDate: string
}
function getLastNMonthsFromDate(date, n) {
    const result: MonthType[] = []
    for (let i = 0; i < n; i += 1) {
        const month = subMonths(date, i)
        result.push({
            label: formatDate(month, 'MMMM yyyy'),
            startDate: formatDate(month, (dateFormatsObject) => dateFormatsObject.serverDate),
        })
    }
    return result
}
const getPastTwelveMonths = (lastDate: string, createdDate: string) => {
    const startDate = lastDate ? new Date(lastDate) : new Date().setDate(0)
    const endDate = new Date(createdDate)

    if (endDate > startDate) {
        return []
    }

    const difference = differenceInCalendarMonths(startDate, endDate) + 1
    const lastMonths = getLastNMonthsFromDate(startDate, difference > 12 ? 12 : difference)

    return lastMonths
}
