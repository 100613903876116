import { Header, MainHeader } from 'components'

const IntegrationsHeader = () => {
    return (
        <MainHeader>
            <Header.Title>Integrations</Header.Title>
        </MainHeader>
    )
}

export default IntegrationsHeader
