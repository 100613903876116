import { type FC } from 'react'

import { type NoInfer } from 'appTypes'
import { type Column } from 'components'
import { Tooltip } from 'ui'

import { type PartModel } from '../types'

import { partLongSource } from './utils'

const source = 'description' satisfies keyof PartModel
const longSource = partLongSource(source)

const label = 'Description'

interface Props {
    value: PartModel[typeof source]
}

const Value: FC<Props> = ({ value }) => {
    if (!value) {
        return null
    }

    return (
        <Tooltip title={value}>
            <span>{value}</span>
        </Tooltip>
    )
}

const tableColumn = <Source extends string = typeof source>({
    id,
    dataToValue,
}: {
    id?: Source
    dataToValue: (data) => PartModel[typeof source]
}) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: label,
        renderCell: ({ row }) => <Value value={dataToValue(row)} />,
    }) as const satisfies Column

const sort = <Source extends string = typeof source>({ id }: { id?: Source } = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label,
    }) as const

export const description = {
    source,
    longSource,
    label,
    Value,
    tableColumn,
    sort,
}
