import { Form, useListContext } from 'react-admin'
import { useWatch } from 'react-hook-form'

import {
    PeriodSelector as PeriodSelectorBase,
    type PeriodSelectorFormValues,
    type PeriodFilter,
    periodSelectorFormValues,
    periodSelectorDefaultDate,
} from 'components'
import { useDidUpdate, useOptimizedRef } from 'hooks'
import { styled } from 'lib'
import { BoxContainer } from 'ui'

interface PeriodSelectorProps {
    className?: string
}

export const defaultReportPeriod = (): PeriodFilter => {
    const defaultValue = periodSelectorFormValues()
    return {
        periodStart: defaultValue.dateFrom,
        periodEnd: defaultValue.dateTo,
    }
}

const PeriodSelector = styled(({ className }: PeriodSelectorProps) => {
    const { displayedFilters, filterValues } = useListContext()

    const defaultValues = useOptimizedRef<PeriodSelectorFormValues>(() => {
        const defaultValue = defaultReportPeriod()
        const values: PeriodSelectorFormValues = {
            date: displayedFilters?.info?.period || periodSelectorDefaultDate,
            dateFrom: (filterValues as PeriodFilter)?.periodStart || defaultValue.periodStart,
            dateTo: (filterValues as PeriodFilter)?.periodEnd || defaultValue.periodEnd,
        }

        return values
    })
    return (
        <Form
            defaultValues={defaultValues.current}
            className={className}
        >
            <BoxContainer height="100%">
                <PeriodSelectorBase />
                <OnChange />
            </BoxContainer>
        </Form>
    )
})``

export default PeriodSelector

const OnChange = () => {
    const { date, dateFrom, dateTo }: Partial<PeriodSelectorFormValues> = useWatch()
    const { setFilters, filterValues } = useListContext()

    useDidUpdate(() => {
        if (!dateFrom || !dateTo) {
            return
        }

        const newPeriod: PeriodFilter = {
            periodStart: dateFrom,
            periodEnd: dateTo,
        }

        setFilters({ ...filterValues, ...newPeriod }, { info: { period: date } })
    }, [dateFrom, dateTo])

    return null
}
