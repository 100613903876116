import { NonDisplayedInput } from 'ui'

/**
 * If a form for credentials does not have an email input
 * the browsers asks if the user want to save the password.
 * This stops that popup
 */
const HiddenEmailInput = () => {
    return (
        <NonDisplayedInput
            type="email"
            name="email"
            autoComplete="off"
        />
    )
}

export default HiddenEmailInput
