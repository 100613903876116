import IMask, { type AnyMaskedOptions } from 'imask'
import parsePhoneNumber from 'libphonenumber-js'

import {
    floatMask,
    integerMask,
    integerNonNegativeSpacedMask,
    integerSpacedMask,
    priceMask,
    quantityMask,
} from 'core'

// resolvers

export const createMaskResolver = (params: AnyMaskedOptions) => {
    const mask = IMask.createMask(params)
    return (value: string | number) => {
        return mask.resolve(String(value))
    }
}

export const phoneMaskResolver = (phone: string) => {
    if (typeof phone !== 'string') {
        return ''
    }
    const parser = parsePhoneNumber(phone[0] === '+' ? phone : `+${phone}`)
    if (!parser || !parser.isValid()) {
        return
    }
    return parser.formatInternational()
}

export const priceMaskResolver = createMaskResolver({
    ...priceMask,
    normalizeZeros: false,
})
export const qtyMaskResolver = createMaskResolver(quantityMask)
export const integerMaskResolver = createMaskResolver(integerMask)
export const floatMaskResolver = createMaskResolver(floatMask)
export const integerNonNegativeSpacedMaskResolver = createMaskResolver(integerNonNegativeSpacedMask)
export const integerSpacedMaskResolver = createMaskResolver(integerSpacedMask)

export const resolveQtyMask = (value: number) => {
    if (typeof value === 'undefined' || value === null) {
        return value
    }

    const formattedQty = qtyMaskResolver(String((value || 0).toFixed(3)))

    return formattedQty
}

export const resolveFloatMask = floatMaskResolver
export const resolveIntegerMask = integerMaskResolver
export const resolveIntegerSpacedMask = integerNonNegativeSpacedMaskResolver

export const formatPercent = (
    value: number,
    useAdaptivePrecision: boolean = false,
    displayGreaterValues: boolean = false,
) => {
    if (typeof value === 'undefined' || value === null) {
        return value
    }
    if (value <= 0) {
        value = 0
    }
    if (value >= 100 && !displayGreaterValues) {
        value = 100
    }
    if (value > 0 && value < 0.1) {
        value = 0.1
    }
    if (useAdaptivePrecision && value < 10) {
        const rounded = Math.round(value)
        const rounded1 = Number.parseFloat(value.toFixed(1))
        if (rounded === rounded1) {
            value = rounded
        } else {
            value = rounded1
        }
    } else {
        value = Math.round(value)
    }
    return `${value}%`
}
