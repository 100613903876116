import { useEffect } from 'react'

import api from 'core/api'
import { useFinalErrorHandler } from 'core/errors'
import { type Telematics, useTelematicsContext } from 'resources/telematics'
import { StatusText } from 'ui'

const NewUnits = ({ integration }: { integration: Telematics }) => {
    const vehiclesNew = integration.vehiclesNew
    if (!vehiclesNew) {
        return null
    }

    return (
        <>
            <StatusText
                color={(theme) => theme.palette.primary.main}
                bgcolor={(theme) => theme.palette.primary.main}
            >
                {vehiclesNew} NEW
            </StatusText>
            <NotificationSeen integration={integration} />
        </>
    )
}

export default NewUnits

const NotificationSeen = ({ integration }: { integration: Telematics }) => {
    const { list } = useTelematicsContext()
    const errorHandler = useFinalErrorHandler()

    useEffect(() => {
        if (integration.hasUnseenVehicles) {
            api.post('telematics/' + integration.provider + '/seen_new_vehicles')
                .then(() => {
                    list.refetch()
                })
                .catch(errorHandler)
        }
    }, [integration.hasUnseenVehicles])

    return null
}
