import { type FC } from 'react'

import { useFormContext } from 'react-hook-form'

import { Button } from 'ui'

export const LimitClear: FC = () => {
    const { reset, watch } = useFormContext()
    const { amount } = watch()

    return (
        <Button
            size="small"
            color="error"
            disabled={amount ? false : true}
            onClick={() =>
                reset({
                    amount: null,
                })
            }
        >
            Clear
        </Button>
    )
}
