import { parseISO } from 'date-fns'
import { useWatch } from 'react-hook-form'

import { formatDate, withErrorBoundary } from 'lib'

const PeriodSelectorText = () => {
    const [dateFrom, dateTo] = useWatch({ name: ['dateFrom', 'dateTo'] })

    if (!dateFrom || !dateTo) {
        return null
    }

    return (
        <>
            {formatDate(parseISO(dateFrom), (dateFormats) => dateFormats.shortenedDate)} -{' '}
            {formatDate(parseISO(dateTo), (dateFormats) => dateFormats.shortenedDate)}
        </>
    )
}

export default withErrorBoundary(PeriodSelectorText, {})
