import { GridContainerColumns, GridItemLayout, PageContent } from 'ui'

import { DetailsSection } from './components'

const IssueOverview = () => {
    return (
        <PageContent>
            <GridContainerColumns>
                <GridItemLayout>
                    <DetailsSection />
                </GridItemLayout>
            </GridContainerColumns>
        </PageContent>
    )
}

export default IssueOverview
