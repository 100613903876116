import { type FC } from 'react'

import { useShowContext } from 'react-admin'

import { type InvoiceModel } from 'resources/invoices'
import { GridContainerColumns, GridItemLayout, SmallSection } from 'ui'

import TotalCard from './TotalCard'
const TotalSection: FC = () => {
    const { record, isLoading } = useShowContext<InvoiceModel>()
    if (isLoading) {
        return null
    }
    return (
        <SmallSection>
            <GridContainerColumns>
                <GridItemLayout>
                    <TotalCard {...record} />
                </GridItemLayout>
            </GridContainerColumns>
        </SmallSection>
    )
}

export default TotalSection
