import { useShowContext } from 'react-admin'

import { type WorkOrderModel } from './types'

export const isVendorWo = (wo: WorkOrderModel) => Boolean(wo?.purchaseOrderData)

export const useIsVenderWo = (raRecord?: WorkOrderModel) => {
    const { record: contextRecord } = useShowContext<WorkOrderModel>()
    const record = raRecord || contextRecord

    if (!record || !record.unit) {
        return null
    }
    return isVendorWo(record)
}
interface WithIsVendorWOProps {
    record?: WorkOrderModel
    [key: string]: any
}

export const WithIsVendorWO = (props: WithIsVendorWOProps) => {
    const isVendorWorkOrder = useIsVenderWo(props.record)
    return props.children(isVendorWorkOrder)
}
