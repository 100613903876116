import {
    type DeleteOneParams,
    type ResourceType,
    type ConfirmConfig,
    ConfirmContentWithFetchOne,
} from 'core'

import { type WorkOrderModel } from '../types'

export const vendorWODeleteDialogAction = (): {
    confirmConfig: ConfirmConfig
} => ({
    confirmConfig: {
        cancelButtonProps: {
            children: 'Close',
        },
        confirmButtonProps: null,
        title: 'This Vendor Work Order cannot be deleted because a related Invoice exists.',
    },
})

export const deleteWorkOrderAction = (
    { id }: WorkOrderModel,
    resource: ResourceType,
): DeleteOneParams => ({
    confirmConfig: {
        title: 'Are you sure you want to delete this Work Order?',
        content: (
            <ConfirmContentWithFetchOne<WorkOrderModel>
                id={id}
                resource={resource}
                render={(record) => (
                    <>
                        This Work Order contains {record.jobsCount} jobs and {record.jobItemsCount}{' '}
                        line items. Deleting the WO will remove its job's connections to any
                        PM/DVIR/Issues and will return them to their initial state.
                    </>
                )}
            />
        ),
    },
    onError: ({ error, defaultOnError, confirm }) => {
        error.nonFieldErrors?.code === 'has_invoices'
            ? confirm(vendorWODeleteDialogAction().confirmConfig)
            : defaultOnError()
    },
})
