import { type FC } from 'react'

import { type SvgIconElement } from 'appTypes'
import Icons from 'assets/icons'
import { withColor } from 'lib'
import { BoxContainer, IconButton, IconElement, Typography } from 'ui'

import { type DateResponse, type ComponentDate } from '../../CostReportContext'

import TopContent from './TopContent'
import { useDrilldownContext } from './context'

const PeriodChanger: FC<{ dates: ComponentDate[] }> = ({ dates }) => {
    const { updateModifiers, isFetching, modifiers, dateHelpers } = useDrilldownContext()

    const dateIndex = dates.findIndex((date) => date.source === modifiers.filter.dateCompleted)
    const prev = dateIndex === -1 ? null : dates[dateIndex - 1]
    const next = dateIndex === -1 ? null : dates[dateIndex + 1]

    const update = (date: DateResponse) => {
        updateModifiers({ filter: { dateCompleted: date } })
    }

    return (
        <TopContent>
            <BoxContainer justifyContent="space-between">
                <Icon
                    icon={Icons.ChevronLeft}
                    disabled={!prev || isFetching}
                    onClick={() => update(prev?.source)}
                />
                <Typography
                    variant="subtitle1"
                    color={withColor('text.primary')}
                >
                    {dateHelpers.formatSource(modifiers.filter.dateCompleted, true)}
                </Typography>
                <Icon
                    icon={Icons.ChevronRight}
                    disabled={!next || isFetching}
                    onClick={() => update(next?.source)}
                />
            </BoxContainer>
        </TopContent>
    )
}

export default PeriodChanger

interface IconProps {
    icon: SvgIconElement
    disabled?: boolean
    onClick: () => void
}

const Icon: FC<IconProps> = ({ icon, disabled, onClick }) => {
    return (
        <IconButton
            disabled={disabled}
            onClick={onClick}
        >
            <IconElement component={icon} />
        </IconButton>
    )
}
