import { useConfirm } from 'core/confirmation'
import { type ServerError } from 'core/errors'
import { Typography } from 'ui'
interface Params {
    onConfirm: () => void
    text: string
}

const usePOWillBeClosed = () => {
    const confirm = useConfirm()

    return {
        hasError: (err: ServerError) => {
            return err.code === 'po_will_close'
        },
        action: ({ onConfirm, text }: Params) => {
            confirm({
                title: `Purchase Order will be closed`,
                content: (
                    <Typography
                        variant="body2"
                        color="text.primary"
                    >
                        {text}
                    </Typography>
                ),
                confirmButtonProps: {
                    children: 'Confirm, Close PO',
                    type: 'submit',
                },
                onConfirm,
            })
        },
    }
}

export default usePOWillBeClosed
