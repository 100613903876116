import { type FC, type ReactNode } from 'react'

import { type Identifier } from 'appTypes'
import { UtilityDrawerCreate, useUtilityDrawerContext } from 'components'
import { RaResourceContextProvider, useResource, type Serializer, NotificationMessage } from 'core'
import { useCreateResourcePath } from 'core/resource'
import {
    woResource,
    type WorkOrderModel,
    type JobModel,
    useWoLimit,
    woJobSerializer,
} from 'resources/workOrders'

import { useStepsContext } from './UseWOStepsContext'
import { type UseWOExtraState } from './useAttachWO'

const UseWOJobForm: FC<{ children: ReactNode }> = ({ children }) => {
    const { extra, forceClose } = useUtilityDrawerContext()
    const { parentRecord, workOrder, options }: UseWOExtraState = extra
    const { stepsData, step } = useStepsContext()
    const resource = useResource()
    const { hasError, woLimitReachedAction } = useWoLimit()
    const createPath = useCreateResourcePath()

    const jobSerializer: Serializer<JobModel & { workOrder: Identifier }> = [
        ...woJobSerializer,
        { name: 'workOrder', parse: () => workOrder.id },
    ]

    return (
        <RaResourceContextProvider
            value={{ resource: resource.resource + '/' + parentRecord.id + '/job' }}
        >
            <UtilityDrawerCreate
                formOnError={({ errors, defaultOnError, reset }) => {
                    if (hasError(errors)) {
                        woLimitReachedAction()
                        reset(
                            {},
                            {
                                keepValues: true,
                                keepDirty: true,
                                keepTouched: true,
                            },
                        )
                        return
                    }

                    defaultOnError()

                    if (errors.workOrder) {
                        forceClose()
                    }
                }}
                serializer={jobSerializer}
                defaultValues={
                    stepsData.current[step]?.values || {
                        name: parentRecord.name,
                        component: parentRecord.component,
                        reasonForRepair: parentRecord.reasonForRepair,
                        ...options?.jobDefaultValues,
                    }
                }
                successMessage={({ defaultMessages, response }) => {
                    const wo = response as { workOrderData: Pick<WorkOrderModel, 'id' | 'number'> }

                    return {
                        title: defaultMessages.created,
                        message: (
                            <NotificationMessage.Navigate
                                to={createPath({
                                    resource: woResource.resource,
                                    type: 'edit',
                                    id: wo.workOrderData.id,
                                })}
                            >
                                {wo.workOrderData.number}
                            </NotificationMessage.Navigate>
                        ),
                    }
                }}
            >
                {children}
            </UtilityDrawerCreate>
        </RaResourceContextProvider>
    )
}

export default UseWOJobForm
