const filterOperators = {
    any: 'any',
    none: 'none',
} as const

const OPERATOR_PREFIX = '!'

const isSourceNegative = (source: string) => source.startsWith(OPERATOR_PREFIX)

const makeNegativeSource = (source: string) => OPERATOR_PREFIX + source

export const filterOperator = {
    choices: [
        {
            id: filterOperators.any,
            name: 'Is Any of',
        },
        {
            id: filterOperators.none,
            name: 'Is None of',
        },
    ],
    values: filterOperators,
    getSource: (source: string) => `${source}_operator`,
    isSourceNegative,

    sourceToNegative: (source: string) =>
        isSourceNegative(source) ? source : makeNegativeSource(source),

    getSourceFromNegative: (source: string) =>
        isSourceNegative(source) ? source.slice(1) : source,

    sourceToValue: (source: string) =>
        isSourceNegative(source) ? filterOperators.none : filterOperators.any,

    valueToSource: (
        value: (typeof filterOperators)[keyof typeof filterOperators],
        source: string,
    ) => (value === filterOperators.none ? makeNegativeSource(source) : source),
}
