import { useShowContext } from 'react-admin'

import images from 'assets/images'
import NoResultsCard from 'components/NoResultsCard'
import { type UnitModel, isUnitArchived } from 'resources/units'
import { Typography } from 'ui'

import { UnitMetersDrawerListToggler } from './UnitMetersDrawerToggler'

const UnitMetersEmpty = () => {
    const { record } = useShowContext<UnitModel>()
    const isArchived = isUnitArchived(record)

    return (
        <NoResultsCard
            title="No Meters"
            direction="row"
            height="160px"
            heightsm="280px"
            directionsm="column"
            imageSrc={images.meter}
            imageProps={{
                alt: 'No meters',
                width: {
                    xs: '122px',
                    sm: '107px',
                },
                mr: {
                    xs: 0,
                    sm: '32px',
                },
            }}
            action={
                isArchived ? null : (
                    <UnitMetersDrawerListToggler>
                        {({ onClick }) => (
                            <Typography
                                variant="body1"
                                color="primary"
                                sx={{ cursor: 'pointer' }}
                                onClick={onClick}
                            >
                                Add Meters
                            </Typography>
                        )}
                    </UnitMetersDrawerListToggler>
                )
            }
        />
    )
}

export default UnitMetersEmpty
