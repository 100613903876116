import { useState } from 'react'

import { formatMoney } from 'components'
import { reportsUrls, createReportResource } from 'resources/reports'

import WidgetBase, { WidgetSkeleton } from '../../WidgetBase/WidgetBase'
import { useCreateDashboardWidgetLink, useDashboardWidget } from '../../WidgetsForm'
import { Clarification, CostListSelect } from '../FleetCostPerDistanceWidget/components'
import { WidgetLineChartBase } from '../components'

import { CostPerHourList } from './components'
import { type listOrderType, type CostPerHourResponse } from './types'

const description = `The accumulated maintenance expenses are divided by the total engine hours logged, 
and the result is displayed for the selected period of time, empowering you to spot the trend at once.`
let isValid = false
const FleetCostPerHourWidget = () => {
    const createLink = useCreateDashboardWidgetLink()
    const [listOrder, setListOrder] = useState<listOrderType>('DESC')
    const data = useDashboardWidget<CostPerHourResponse>({
        resource: createReportResource('cost-per-hour'),
        sideEffect: (data) => {
            isValid = typeof data.fleetAverage === 'number'
            return data
        },
    })
    if (!data) {
        return <WidgetSkeleton />
    }
    const { fleetAverage, changeInPercentsForPeriod, highestCostUnits, dataPoints } = data

    return (
        <WidgetBase
            headerProps={{
                title: 'Cost per Engine Hour',
                label: isValid ? formatMoney(fleetAverage) : null,
                tooltipText: description,
                clarification: <Clarification changeForPeriod={changeInPercentsForPeriod} />,
                chart: (
                    <WidgetLineChartBase
                        data={dataPoints}
                        isValid={isValid}
                    />
                ),
            }}
            infoProps={{
                content: (
                    <CostPerHourList
                        data={highestCostUnits}
                        description={description}
                        listOrder={listOrder}
                    />
                ),
            }}
            separator={
                <CostListSelect
                    setListOrder={setListOrder}
                    listLength={highestCostUnits.length}
                />
            }
            link={createLink(reportsUrls.costPerMeter)}
        />
    )
}
export default FleetCostPerHourWidget
