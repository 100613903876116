import { useState, type ReactNode } from 'react'

import { observer } from 'mobx-react'
import { type Layout, type Layouts } from 'react-grid-layout'

import { authStore } from 'core/auth'
import { useOptimizedRef } from 'hooks'
import { useFlags } from 'lib'

import { gridBreakpoints, gridColumns } from './GridLayout/DashboardGridLayout'
import { WidgetList, type WidgetListType } from './Widgets/Widgets'
import WidgetsLayoutContext from './WidgetsLayoutContext'

const layoutItemDimensions: Pick<Layout, 'w' | 'h' | 'maxH' | 'maxW'> = {
    w: 2,
    h: 2,
    maxH: 2,
    maxW: 2,
}
const layoutBpConfig: {
    [key in keyof typeof gridBreakpoints]?: Omit<
        Layout,
        keyof typeof layoutItemDimensions | 'i' | 'x' | 'y'
    >
} = {}

const defaultParseFormula = (columns: number, bp: string, widgets: WidgetListType) => {
    let currentX = 0
    let currentY = 0
    return widgets.map((widget, i) => {
        currentX += 2
        if (i % columns === 0) {
            currentY += 1
            currentX = 0
        }
        return {
            ...layoutItemDimensions,
            ...layoutBpConfig[bp],
            i: widget.id,
            x: currentX,
            y: currentY - 1,
        }
    })
}
const mutateLayout = (layouts: Layouts, visibleWidgets: { [key: string]: boolean }) => {
    const mutatedLayouts = JSON.parse(JSON.stringify(layouts))
    const virtualLayout = createVirtualLayout(layouts)
    return Object.keys(gridBreakpoints).reduce((current, bp) => {
        return (
            (current[bp] = Object.keys(visibleWidgets).flatMap((widgetId) => {
                if (!visibleWidgets[widgetId]) {
                    return []
                }
                const currentItem = mutatedLayouts[bp].find((ly) => ly.i === widgetId)
                if (currentItem) {
                    return currentItem
                }

                let widgetX = 0,
                    widgetY = virtualLayout[bp].length
                let found = false
                // Algorithm to find free space
                for (
                    let rowIndex = 0;
                    rowIndex < virtualLayout[bp].length && found === false;
                    rowIndex += 1
                ) {
                    const row = virtualLayout[bp][rowIndex]
                    for (let columnIndex = 0; columnIndex < row.length; columnIndex += 1) {
                        if (columnIndex === row.length - 1) {
                            continue
                        }
                        if (
                            rowIndex === virtualLayout[bp].length - 1 &&
                            row[columnIndex] === 0 &&
                            row[columnIndex + 1] === 0
                        ) {
                            widgetX = columnIndex
                            widgetY = rowIndex
                            found = true
                            break
                        }
                        if (
                            row[columnIndex] === 0 &&
                            row[columnIndex + 1] === 0 &&
                            virtualLayout[bp][rowIndex + 1][columnIndex] === 0 &&
                            virtualLayout[bp][rowIndex + 1][columnIndex + 1] === 0
                        ) {
                            widgetX = columnIndex
                            widgetY = rowIndex
                            found = true
                            break
                        }
                    }
                }
                return {
                    ...layoutItemDimensions,
                    ...layoutBpConfig[bp],
                    i: widgetId,
                    x: widgetX,
                    y: widgetY,
                }
            })),
            current
        )
    }, {})
}

/* The virtual layouts consists of an array of arrays where each array
is one row and each slot in an array one column slots.
Slots populated by 0 are empty and 1 are filled by grid items.
Example:
    x-> 0 1 2 3 4 5
y:0    [1 1 0 1 0 0]
y:1    [1 1 0 1 1 1]
y:2    [0 0 0 0 1 1]
*/

// This gets called every time the layout changes
// Creates a map of the virtual layout explained above, this is used later
// to check for free space in case of a layout change
const createVirtualLayout = (layouts: Layouts) => {
    return Object.keys(gridBreakpoints).reduce((current, bp) => {
        let maxY = 0
        for (let rowIndex = 0; rowIndex < layouts[bp].length; rowIndex += 1) {
            if (maxY < layouts[bp][rowIndex].y + layouts[bp][rowIndex].h - 1) {
                maxY = layouts[bp][rowIndex].y + layouts[bp][rowIndex].h - 1
            }
        }

        const defaultMap = []
        for (let rowIndex = 0; rowIndex <= maxY; rowIndex += 1) {
            defaultMap[rowIndex] = new Array(gridColumns[bp]).fill(0)
        }
        for (let gridItemIndex = 0; gridItemIndex < layouts[bp].length; gridItemIndex += 1) {
            const currentLy = layouts[bp][gridItemIndex]

            defaultMap[currentLy.y][currentLy.x] = 1
            if (gridItemIndex === maxY - 1) {
                if (currentLy.w === 2) {
                    defaultMap[currentLy.y][currentLy.x + 1] = 1
                } else {
                    defaultMap[currentLy.y][currentLy.x] = 1
                }
            }

            if (currentLy.w === 2 && currentLy.h === 2) {
                defaultMap[currentLy.y][currentLy.x + 1] = 1
                defaultMap[currentLy.y + 1][currentLy.x] = 1
                defaultMap[currentLy.y + 1][currentLy.x + 1] = 1
            } else if (currentLy.w === 2) {
                defaultMap[currentLy.y][currentLy.x + 1] = 1
            } else if (currentLy.h === 2) {
                defaultMap[currentLy.y + 1][currentLy.x] = 1
            }
        }
        current[bp] = defaultMap
        return current
    }, {})
}
// Called once when there are no preferences
const createLayout = (widgets: WidgetListType) =>
    Object.keys(gridBreakpoints).reduce((current, bp) => {
        current[bp] = defaultParseFormula(gridColumns[bp] / 2, bp, widgets)
        return current
    }, {})

// This is needed to make new widgets visible in the layout, default to true
const syncVisibility = (visibleWidgets: { [key: string]: boolean }) => {
    return WidgetList.reduce((curr, widget) => {
        if (visibleWidgets[widget.id] !== undefined) {
            curr[widget.id] = visibleWidgets[widget.id]
            return curr
        }
        curr[widget.id] = true
        return curr
    }, {})
}

// This is needed to check if there are new widgets in the layout,
// If there are, assign them the {0,0} coordinates
const syncLayouts = (layouts: Layouts, widgets: WidgetListType) =>
    Object.keys(gridBreakpoints).reduce((current, bp) => {
        const y = Math.max(...layouts[bp].map((ly) => ly.y))
        let x = 0
        return (
            (current[bp] = widgets.map((widget) => {
                const currItem = layouts[bp].find((item) => item.i === widget.id)
                if (currItem) {
                    return currItem
                }

                // const x = Math.floor(i % (gridColumns[bp] / 2))
                const _x = x
                x += 1
                return {
                    ...layoutItemDimensions,
                    ...layoutBpConfig[bp],
                    i: widget.id,
                    x: (_x * 2) % gridColumns[bp],
                    y: y + Math.floor((_x * 2) / gridColumns[bp]),
                    // x: 0,
                    // y: 0,
                }
            })),
            current
        )
    }, {})

const WidgetLayoutProvider = observer(({ children }: { children: ReactNode }) => {
    const preferences = authStore.preferences.dashboard

    const layoutRef = useOptimizedRef(() => ({
        visibleWidgets: preferences?.visibleWidgets
            ? syncVisibility(preferences.visibleWidgets)
            : WidgetList.reduce((current, widget) => {
                  current[widget.id] = true
                  return current
              }, {}),
    }))
    const flags = useFlags()
    const visibleWidgetsList = WidgetList.filter(
        (widget) =>
            layoutRef.current.visibleWidgets[widget.id] &&
            !widget.hide?.({ flags, auth: authStore }),
    )
    const [layouts, setLayouts] = useState(() => {
        return preferences?.gridLayout
            ? syncLayouts(preferences.gridLayout, visibleWidgetsList)
            : createLayout(visibleWidgetsList)
    })

    const watch = (currentLayout: Layout[], allLayouts: Layouts) => {
        setLayouts(allLayouts)
        if (!authStore.preferences.dashboard) {
            authStore.preferences.dashboard = {}
        }
        authStore.preferences.dashboard.visibleWidgets = layoutRef.current.visibleWidgets
        authStore.preferences.dashboard.gridLayout = allLayouts

        authStore.syncPreferences()
    }

    const reset = () => {
        layoutRef.current.visibleWidgets = WidgetList.reduce((current, widget) => {
            current[widget.id] = true
            return current
        }, {})
        setLayouts(
            mutateLayout(
                createLayout(
                    WidgetList.filter((widget) => !widget.hide?.({ flags, auth: authStore })),
                ),
                layoutRef.current.visibleWidgets,
            ),
        )
    }

    const mutate = (widgetId: string) => {
        layoutRef.current.visibleWidgets[widgetId] = !layoutRef.current.visibleWidgets[widgetId]

        setLayouts(mutateLayout(layouts, layoutRef.current.visibleWidgets))
    }
    return (
        <WidgetsLayoutContext.Provider
            value={{
                widgets: visibleWidgetsList,
                watch,
                layouts,
                mutate,
                reset,
                visibleWidgets: layoutRef.current.visibleWidgets,
            }}
        >
            {children}
        </WidgetsLayoutContext.Provider>
    )
})

export default WidgetLayoutProvider
