import { useInventoryContext } from 'resources/inventory'
import { FlexReverse, PageContent } from 'ui'

import { PartCrossReference, PartDetails, InventoryDetails } from './components'

const PartOverview = () => {
    const inventoryView = useInventoryContext()

    return (
        <PageContent>
            <FlexReverse>
                {inventoryView ? <InventoryDetails /> : null}
                <PartDetails />
                <PartCrossReference />
            </FlexReverse>
        </PageContent>
    )
}

export default PartOverview
