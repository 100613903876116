import Icons from 'assets/icons'
import { formatDuration } from 'lib'
import { type DowntimeList } from 'resources/units'
import { Box, BoxContainer, Status, Typography } from 'ui'

import { WidgetListContainer } from '../../../WidgetBase/styled'
import { DotSeparator, ListItem, WidgetListDescription } from '../../components'
interface DistributionProps {
    data: DowntimeList
    description: string
    validData: boolean
}
export const DowntimekeysToDataMap = {
    EMERGENCY: {
        name: 'Emergency',
        color: 'red',
        icon: Icons.CalendarTodayOutlined,
    },
    NON_SCHEDULED: {
        name: 'Non-Scheduled',
        color: 'yellowBody',
        icon: Icons.EventBusyOutlined,
    },
    SCHEDULED: {
        name: 'Scheduled',
        color: 'greenBody',
        icon: Icons.TodayOutlined,
    },
}
const Distribution = ({ data, description, validData }: DistributionProps) => {
    if (!validData) {
        return <WidgetListDescription> {description} </WidgetListDescription>
    }
    const extendedSortedData = Object.keys(DowntimekeysToDataMap)
        .map((key) => {
            return {
                ...DowntimekeysToDataMap[key],
                ...data[key],
            }
        })
        .sort((a, b) => b.total - a.total)

    const List = extendedSortedData.map((obj, index) => {
        const duration = formatDuration(obj.total, true)
        return (
            <Typography
                component={ListItem}
                variant="chartListItem"
                key={`DownTime${index}`}
            >
                <BoxContainer>
                    <Box marginRight="13px">
                        <Status
                            size="8px"
                            iconColor={(theme) => theme.palette.charts[obj.color]}
                        />
                    </Box>
                    {obj.name}
                    <DotSeparator color="text.disabled" />
                    <Typography
                        color="text.disabled"
                        variant="chartListItem"
                        lineHeight="150%"
                    >
                        {duration}
                    </Typography>
                </BoxContainer>
                {Math.round(obj.percentOfTotalDowntime)}%
            </Typography>
        )
    })
    return <WidgetListContainer>{List}</WidgetListContainer>
}
export default Distribution
