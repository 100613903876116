import { Link } from 'react-router-dom'

import Icons from 'assets/icons'
import {
    blockerForceRedirectState,
    authStore,
    formErrors,
    formErrorToString,
    useConfirm,
    type ConfirmConfig,
} from 'core'
import { useOpenPaymentLimit } from 'resources/billing'

interface UseWoLimitResult {
    woLimitReachedAction: () => void
    canCreateWo: () => boolean
    hasError: (errors: any) => boolean
}

const nonBillingResult: UseWoLimitResult = {
    canCreateWo: () => true,
    woLimitReachedAction: () => {
        /* */
    },
    hasError: () => false,
}

const WoLimitReachedDialog = ({ title }: { title: string }) => {
    return (
        <>
            <Icons.WarningAmberOutlined
                sx={{
                    width: '24px',
                    height: '24px',
                    color: (theme) => theme.palette.error.main,
                    mr: '10px',
                }}
            />
            {title}
        </>
    )
}

const useWoLimit = (): UseWoLimitResult => {
    const confirm = useConfirm()
    const openPaymentLimit = useOpenPaymentLimit()
    if (!authStore.billing) {
        return nonBillingResult
    }
    return {
        woLimitReachedAction: () => {
            const billing = authStore.billing

            const confirmConfig: ConfirmConfig = {
                title: '',
                confirmButtonProps: {
                    variant: 'contained',
                },
            }

            if (billing.subscription === 'free') {
                confirmConfig.title = <WoLimitReachedDialog title="Work Orders limit reached." />
                confirmConfig.confirmButtonProps = {
                    ...confirmConfig.confirmButtonProps,
                    children: 'Go unlimited',
                    component: Link,
                    // to: urls.changePlan,
                    state: blockerForceRedirectState,
                }
            } else {
                confirmConfig.title = <WoLimitReachedDialog title="Payment limit reached." />
                confirmConfig.confirmButtonProps = {
                    ...confirmConfig.confirmButtonProps,
                    children: 'Change Limit',
                }
                confirmConfig.onConfirm = () => {
                    openPaymentLimit()
                }
            }

            confirm(confirmConfig)
        },
        canCreateWo: () => {
            const billing = authStore.billing
            return (
                (billing.subscription === 'free' &&
                    billing.allUsedWorkOrdersCount < billing.allFreeWorkOrdersCount) ||
                (billing.subscription === 'pro' && !billing.paymentLimitReached)
            )
        },
        hasError: (errors) => {
            return formErrorToString(formErrors(errors).nonFieldErrors, 'code') === 'billing'
        },
    }
}

export default useWoLimit
