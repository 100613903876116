import { forwardRef, useRef, type ComponentProps } from 'react'

import { ErrorBoundary } from 'lib'

import { useWidgetContext } from '../GridLayout'

import { WidgetHeader, type WidgetHeaderProps, WidgetHeaderSkeleton } from './WidgetHeader'
import { type WidgetInfoProps, WidgetInfo, WidgetInfoSkeleton } from './WidgetInfo'
import {
    WidgetBaseContainer,
    WidgetBaseDragIndicator,
    WidgetBaseLink,
    WidgetBaseResizeIndicator,
} from './styled/styled'
import { checkWidgetDimensions } from './useCollapseWidget'
// TODO: Rename p
export interface WidgetBaseProps extends ComponentProps<typeof WidgetBaseContainer> {
    headerProps: Omit<WidgetHeaderProps, 'separator'>
    infoProps?: WidgetInfoProps
    link?: string
    separator?: WidgetHeaderProps['separator']
}
export interface WidgetSkeletonProps
    extends Omit<WidgetBaseProps, 'headerProps' | 'infoProps' | 'link' | 'separator'> {
    disableInfo?: boolean
}
export const WidgetSkeleton = ({ disableInfo, ...rest }: WidgetSkeletonProps) => {
    const dimension = useWidgetContext()

    return (
        <WidgetBaseContainer {...rest}>
            <WidgetHeaderSkeleton />
            {!disableInfo && checkWidgetDimensions(dimension, ['lg', 'mdY']) ? (
                <WidgetInfoSkeleton />
            ) : null}
        </WidgetBaseContainer>
    )
}

const WidgetBase = forwardRef(
    (
        { link, headerProps, infoProps, separator, ...rest }: WidgetBaseProps,
        ref: React.MutableRefObject<HTMLDivElement>,
    ) => {
        const widgetRef = useRef<HTMLDivElement>()
        const currentRef = ref || widgetRef
        const dimension = useWidgetContext()

        return (
            <WidgetBaseContainer
                {...rest}
                ref={currentRef}
            >
                <ErrorBoundary>
                    {link ? <WidgetBaseLink link={link} /> : null}
                    <WidgetBaseDragIndicator />
                    <WidgetBaseResizeIndicator />
                    <WidgetHeader
                        {...headerProps}
                        separator={separator}
                    />
                    {infoProps && checkWidgetDimensions(dimension, ['lg', 'mdY']) ? (
                        <WidgetInfo {...infoProps} />
                    ) : null}
                </ErrorBoundary>
            </WidgetBaseContainer>
        )
    },
)

export default WidgetBase
