import { formatMoney } from 'components'
import { Typography } from 'ui'

import { useCostReportContext } from '../../CostReportContext/CostReportContext'

import ComparedToPrevYearLabel from './ComparedToPrevYearLabel'
import LineChart from './LineChart'
import TitleTooltip from './TitleTooltip'
import WidgetSkeleton from './WidgetSkeleton'
import WidgetsContainer from './WidgetsContainer'
import WidgetsNoData from './WidgetsNoData'
import { formatData } from './utils'

const MaintenanceCostWidget = () => {
    const { data, isLoading } = useCostReportContext()

    if (isLoading) {
        return <WidgetSkeleton title="Maintenance Cost" />
    }

    if (!data.maintenanceCost) {
        return (
            <WidgetsNoData
                title="Maintenance Cost"
                text={widgetText}
            />
        )
    }

    const { leftAxisValues, formattedData } = formatData(data.maintenanceCost.data)

    return (
        <WidgetsContainer>
            <Typography
                variant="chartTitle"
                color="text.secondary"
            >
                Maintenance Cost
            </Typography>
            <Typography
                variant="h5"
                color="text.primary"
            >
                {formatMoney(data.maintenanceCost.total || 0)}{' '}
                <TitleTooltip tooltipText={widgetText} />
            </Typography>
            <ComparedToPrevYearLabel value={data.maintenanceCost?.differenceWithPrevYear} />
            <LineChart
                data={formattedData}
                leftAxisValues={leftAxisValues}
                formatValue={formatValue}
                bottomAxisValues={data.maintenanceCost.xAxis}
            />
        </WidgetsContainer>
    )
}

export default MaintenanceCostWidget

const formatValue = (value: number, formattedValue: string) => {
    return formatMoney({ value, formattedValue })
}

const widgetText =
    "Maintenance Cost displays the aggregated costs of parts and labor associated with the unit's work orders during the specified timeframe."
