import { useRecordContext } from 'react-admin'

import { InfoCard, InfoCardDetails, InfoCardHeader, ViewHeader, formatMoney } from 'components'
import {
    SendPoAction,
    PurchaseOrderExportAction,
} from 'pages/WorkOrders/components/WorkOrderPurchaseInvoice'
import { type WorkOrderModel } from 'resources/workOrders'
import { GridItemLayout, Section, GridContainerColumns, Spacer } from 'ui'

const PurchaseOrderSection = () => {
    const record = useRecordContext<WorkOrderModel>()
    if (!record) {
        return null
    }
    const { purchaseOrderData } = record
    return (
        <Section>
            <ViewHeader title="Purchase Order" />
            <GridContainerColumns>
                <GridItemLayout>
                    <InfoCard>
                        <InfoCardHeader
                            action={
                                <Spacer>
                                    <SendPoAction />
                                    <PurchaseOrderExportAction />
                                </Spacer>
                            }
                            title={purchaseOrderData.number}
                            titleTypographyProps={{
                                variant: 'subtitle2',
                            }}
                        />
                        <InfoCardDetails<WorkOrderModel>
                            details={[
                                {
                                    label: 'Purchase Order Total',
                                    source: ({ purchaseOrderData }) =>
                                        formatMoney(purchaseOrderData.total),
                                },
                            ]}
                        />
                    </InfoCard>
                </GridItemLayout>
            </GridContainerColumns>
        </Section>
    )
}
export default PurchaseOrderSection
