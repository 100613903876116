import { type ReactElement, type FC } from 'react'

import { type Identifier } from 'appTypes'
import { useOpenUtilityDrawer, UtilityDrawerEditor } from 'components'
import { type Serializer } from 'core'

import { type TagModel } from '../types'
import { tagsResource } from '../utils'

import { TagForm } from './TagForm'

interface TagDrawerTogglerProps {
    children: (open: () => void) => ReactElement
    id?: Identifier
    onSuccess?: (record: TagModel) => void
}

const TagDrawerToggler: FC<TagDrawerTogglerProps> = ({ children, id, onSuccess }) => {
    const open = useOpenUtilityDrawer()

    return children(() => {
        open({
            drawerArgs: {
                title: id ? 'Edit Tag' : 'Create Tag',
                renderWrapper: (params) => (
                    <UtilityDrawerEditor
                        {...params}
                        onSuccess={onSuccess}
                        serializer={tagSerializer}
                    />
                ),
                renderContent: () => <TagForm />,
                renderBottomRight: (render) => render({ label: id ? undefined : 'Create tag' }),
            },
            extraArgs: {
                id,
                resource: tagsResource,
            },
        })
    })
}

export default TagDrawerToggler

const tagSerializer: Serializer<TagModel> = ['name', 'color']
