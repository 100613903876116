import { type FC, type ReactElement, type ReactNode, useRef, useState } from 'react'

import { useWatch } from 'react-hook-form'

import UtilityDrawerBlock from 'components/Drawer/UtilityDrawerBlock'

import UseWOJobForm from './UseWOJobForm'
import { StepsContext, type StepsData, useStepsContext } from './UseWOStepsContext'
import UseWOWOForm from './UseWOWOForm'

const StepsContextProvider: FC<{
    children: ReactNode
}> = ({ children }) => {
    const [step, setStep] = useState(1)
    const stepsData = useRef<StepsData>({})
    const content: {
        [key: number]: (params: { children: ReactNode; key: number }) => ReactElement
    } = {
        1: (params) => <UseWOWOForm {...params} />,
        2: (params) => <UseWOJobForm {...params} />,
    }
    const next = () => {
        setStep((prevStep) => prevStep + 1)
    }

    const prev = () => {
        setStep((prevStep) => prevStep - 1)
    }

    return (
        <StepsContext.Provider
            value={{
                step,
                stepsData,
                next,
                prev,
            }}
        >
            {content[step]({
                children: (
                    <>
                        {children}
                        <UpdateStepFormData dataRef={stepsData} />
                    </>
                ),
                key: step,
            })}
            <BlockClose />
        </StepsContext.Provider>
    )
}

const UpdateStepFormData: FC<{ dataRef: React.MutableRefObject<StepsData> }> = ({ dataRef }) => {
    const values = useWatch()
    const { step } = useStepsContext()

    if (!dataRef.current[step]) {
        dataRef.current[step] = {}
    }

    dataRef.current[step].values = values

    return null
}

const BlockClose = () => {
    const step = useStepsContext().step
    const ref = useRef(step)

    if (ref.current < step) {
        ref.current = step
    }

    return <UtilityDrawerBlock isBlocked={ref.current > 1} />
}

export default StepsContextProvider
