import { useEffect } from 'react'

import { useFormContext } from 'react-hook-form'

import { SelectInput, TextInput, inputIntegerNonNegativeSpacedMaskParams } from 'components'
import { meterTypeChoices } from 'resources/units'
import { SectionTitle } from 'ui'

const WorkOrderFormMeter = () => {
    const { setValue, watch } = useFormContext()
    const value = watch('meterType')

    const isEmptyMeterType = !value

    useEffect(() => {
        if (isEmptyMeterType) {
            setValue('meterValue', '')
        }
    }, [isEmptyMeterType])

    return (
        <>
            <SectionTitle
                largeVariant="subtitle1"
                frontLine
            >
                Meter
            </SectionTitle>

            <SelectInput
                source="meterType"
                label="Meter Type"
                choices={meterTypeChoices}
                disableEmptyValue
            />

            <TextInput
                source="meterValue"
                label="Value"
                disabled={isEmptyMeterType}
                {...inputIntegerNonNegativeSpacedMaskParams}
            />
        </>
    )
}

export default WorkOrderFormMeter
