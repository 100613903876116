import { type DataRecord } from 'appTypes'
import { Create } from 'components/resource'
import { type CreateCustomProps } from 'components/resource/Create'

import { useUtilityDrawerContext } from './UtilityDrawerContext'
import { type UtilityDrawerExtraProps } from './UtilityDrawerEditor'
import UtilityDrawerWarnWhenUnsavedChanges from './UtilityDrawerWarnWhenUnsavedChanges'

export interface UtilityDrawerCreateCustomProps<RecordType extends DataRecord = any>
    extends CreateCustomProps<RecordType> {
    disableCloseOnSubmit?: boolean
}

interface UtilityDrawerCreateProps<RecordType extends DataRecord = any>
    extends UtilityDrawerCreateCustomProps<RecordType>,
        UtilityDrawerExtraProps {}

const UtilityDrawerCreate = <RecordType extends DataRecord = any>({
    disableCloseOnSubmit,
    ...props
}: UtilityDrawerCreateProps<RecordType>) => {
    const { forceClose } = useUtilityDrawerContext()
    const onSuccess = (params) => {
        if (!disableCloseOnSubmit) {
            forceClose()
        }
        props.onSuccess?.(params)
    }

    return (
        <Create
            disableSuccessRedirect
            {...props}
            onSuccess={onSuccess}
            warnWhenUnsavedChanges={false}
            children={
                <>
                    <UtilityDrawerWarnWhenUnsavedChanges />
                    {props.children}
                </>
            }
        />
    )
}

export default UtilityDrawerCreate
