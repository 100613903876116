import { type PropsWithChildren, type ReactNode } from 'react'

import { type SvgIconElement } from 'appTypes'
import { globalClassNames } from 'lib'
import { SvgIcon, BoxContainer, Typography, StatusText } from 'ui'

interface MonitorTitleProps extends PropsWithChildren {
    icon?: SvgIconElement
    statusText?: ReactNode
}
const MonitorTitle = ({ icon, children, statusText }: MonitorTitleProps) => {
    return (
        <BoxContainer
            gap="4px"
            overflow="hidden"
            mr="20px"
        >
            <BoxContainer gap="6px">
                {icon && (
                    <SvgIcon
                        component={icon}
                        sx={{ width: '16px', height: '16px', color: 'text.secondary' }}
                        inheritViewBox
                    />
                )}
                <Typography
                    variant="chartTitle"
                    color="text.secondary"
                    className={globalClassNames.ellipsis}
                >
                    {children}
                </Typography>
            </BoxContainer>
            {statusText && (
                <StatusText
                    color={(theme) => theme.palette.primary.main}
                    bgcolor={(theme) => theme.palette.primary.main}
                >
                    {statusText}
                </StatusText>
            )}
        </BoxContainer>
    )
}

export default MonitorTitle
