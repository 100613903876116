import { createResource } from 'core'

import { type DefectModel } from './types'

export const deffectResource = createResource({
    name: 'defects',
    resource: 'defects',
})

export const isDefectInProgress = (record: DefectModel): boolean => Boolean(record?.workOrderData)
