import { useCreatePath } from 'react-admin'

import { type Identifier } from 'appTypes'
import { api, dispatchAction, NotificationMessage, updatedMessage, useNotify } from 'core'
import { invoicesResource } from 'resources/invoices'
import { type WorkOrderModel } from 'resources/workOrders/types'
import { pathJoin } from 'utils'

import { woResource } from '../../utils'

import { WoActionKeys } from './types'
const useUpdateWoStatus = () => {
    const notify = useNotify()
    const createPath = useCreatePath()
    return async (id: Identifier, action: WoActionKeys, data?: any) => {
        await api
            .post(pathJoin(woResource.resource, id, action), data)
            .then((wo: WorkOrderModel) => {
                if (action === WoActionKeys.CLOSE && data?.invoiceOnClose) {
                    notify({
                        title: (
                            <>
                                Work order status updated!
                                <br />
                                Successfully created invoice!
                            </>
                        ),
                        message: (
                            <NotificationMessage.Navigate
                                to={createPath({
                                    resource: invoicesResource.resource,
                                    type: 'edit',
                                    id: wo.salesOrderData.invoiceData.id,
                                })}
                            >
                                {wo.salesOrderData.invoiceData.number}
                            </NotificationMessage.Navigate>
                        ),
                    })
                } else {
                    notify(updatedMessage)
                }
            })
        dispatchAction(woResource.name, {
            name: 'statusChange',
        })
    }
}

export default useUpdateWoStatus
