import { formatPercent } from 'components'
import { type PtServiceModel } from 'resources/pricingTiers'

const source = 'markupPercent' as const satisfies keyof PtServiceModel

const label = 'Markup'

const value = (record: Pick<PtServiceModel, 'markupPercent'>) => {
    return formatPercent(record.markupPercent, undefined, true)
}

export const markup = {
    source,
    label,
    value,
}
