import { type ReactNode, type FC } from 'react'

import { clsx, styled } from 'lib'

import headerClasses, { headerCssVariables } from './config'

interface Props {
    children: ReactNode
}

/**
 * The main content block of the header
 */
const HeaderBlock: FC<Props> = styled(({ className, children }: Props & { className: string }) => {
    return <div className={clsx(className, headerClasses.block)}>{children}</div>
})`
    padding-left: var(${headerCssVariables.offsetX});
    padding-right: var(${headerCssVariables.offsetX});
    display: flex;
    width: 100%;
    height: var(${headerCssVariables.height});
    align-items: center;
    box-sizing: border-box;
    gap: var(${headerCssVariables.primaryGap});
`

export default HeaderBlock
