import { type FC, type ReactNode } from 'react'

import { clsx, cssVariables, styled } from 'lib'
import { Box, textWithIconClasses } from 'ui'

import { FixedTopPortal } from './FixedTopPart'
import headerClasses, { headerCssVariables } from './config'

interface Props {
    children: ReactNode
    fixed?: boolean
}

const Header: FC<Props> = styled(
    ({ children, fixed, className }: Props & { className: string }) => {
        const content = (
            <Box
                width="100%"
                bgcolor={(theme) => theme.palette.white}
                className={clsx(className, headerClasses.root)}
            >
                {children}
            </Box>
        )

        if (fixed) {
            return <FixedTopPortal>{content}</FixedTopPortal>
        }

        return content
    },
)`
    ${cssVariables.gap}: 8px;
    ${headerCssVariables.offsetX}: 20px;
    ${headerCssVariables.primaryGap}: 8px;
    ${headerCssVariables.avatarSize}: 40px;
    ${headerCssVariables.iconSize}: 16px;
    ${headerCssVariables.height}: 68px;

    .${textWithIconClasses.root} {
        ${cssVariables.gap}: 4px;
    }

    ${({ theme }) => theme.breakpoints.up(theme.props.mobileViewBreakpoint)} {
        ${headerCssVariables.offsetX}: 28px;
        ${headerCssVariables.primaryGap}: 16px;
        ${headerCssVariables.avatarSize}: 48px;
        ${headerCssVariables.iconSize}: 20px;
        ${headerCssVariables.height}: 80px;
    }
`

export default Header
