import { type PropsWithChildren, type FC } from 'react'

import { type SvgIconElement } from 'appTypes'
import { InfoCardRowBase, MoneyField } from 'components'
import { globalClassNames } from 'lib'
import { Anchor, Box, Stack, SvgIcon, type SvgIconComponent, Typography } from 'ui'

import { type BaseLineItem } from '../types'
import { getItemData } from '../utils'

interface Props extends Pick<LabelProps, 'action'> {
    item: BaseLineItem
}

const ItemRow: FC<Props> = ({ item, ...props }) => {
    const { total, totalCaption, ...rest } = getItemData(item)
    return (
        <InfoCardRowBase
            label={
                <Label
                    {...props}
                    {...rest}
                >
                    {item.description}
                </Label>
            }
            align="top"
        >
            <Stack
                direction="row"
                alignItems="center"
            >
                <Typography
                    variant="helperText"
                    mr="38px"
                >
                    {totalCaption}
                </Typography>
                <MoneyField value={total} />
            </Stack>
        </InfoCardRowBase>
    )
}

export default ItemRow

interface LabelProps extends PropsWithChildren {
    icon: SvgIconComponent | SvgIconElement
    subtitle: string
    action?: () => void
}
const Label: FC<LabelProps> = ({ children, subtitle, icon, action }) => {
    const title = (
        <Typography
            variant="helperText"
            display="flex"
            alignItems="center"
        >
            <SvgIcon
                sx={{
                    width: '16px',
                    height: '16px',
                    mr: '8px',
                }}
                component={icon}
            />
            <Typography
                className={globalClassNames.ellipsis}
                variant="inherit"
            >
                {children}
            </Typography>
        </Typography>
    )
    return (
        <Box>
            {action ? <Anchor onClick={action}>{title}</Anchor> : title}
            <Typography
                variant="chartLabel"
                color="text.secondary"
                component="div"
                ml="24px"
                className={globalClassNames.ellipsis}
            >
                {subtitle}
            </Typography>
        </Box>
    )
}
