import { type UseQueryMeta } from './types'
import useQueryClient from './useQueryClient'

const useResetQueries = () => {
    const queryClient = useQueryClient()

    return async () => {
        queryClient.removeQueries({ active: false })
        // If we have redirect, wait for it to finish before refetching.
        setTimeout(() => {
            queryClient.invalidateQueries({
                active: true,
                predicate: (query) => {
                    if ((query.meta as UseQueryMeta)?.refetchState) {
                        return false
                    }

                    const queryKey = query.queryKey
                    if (
                        Array.isArray(queryKey) &&
                        queryKey[1] === 'getList' &&
                        queryKey[2]?.meta?.referenceInput
                    ) {
                        return false
                    }

                    return true
                },
            })
        }, 50)
    }
}

export default useResetQueries
