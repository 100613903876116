import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import { Header, ListBase, ListTotalBadge, MainHeader, List } from 'components'
import { type AuthStore, type SortPayload } from 'core'
import { PoTypeKeys, type PurchaseOrderModel } from 'resources/purchaseOrders'
import { woResource } from 'resources/workOrders'
import { PageContent } from 'ui'

import { getPoConfigs } from './PurchaseOrdersList'

const defaultSort: SortPayload<PurchaseOrderModel> = {
    field: 'created',
    order: 'DESC',
}

const VendorPoList: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const { filtersConfig, sortConfig, columnsConfig, cardsConfig } = getPoConfigs(
            auth,
            navigateTo,
        )
        return (
            <ListBase
                sort={defaultSort}
                filter={{
                    type: PoTypeKeys.WORK_ORDER,
                }}
            >
                <MainHeader>
                    <Header.Info>
                        <Header.Content>
                            <Header.Title>Purchase Orders</Header.Title>
                        </Header.Content>
                        <Header.Content mobilePlacement="bottom">
                            <ListTotalBadge />
                        </Header.Content>
                    </Header.Info>
                </MainHeader>
                <PageContent>
                    <List
                        sortCfg={sortConfig}
                        columnsCfg={columnsConfig}
                        cardsCfg={cardsConfig}
                        filtersCfg={filtersConfig}
                        listFTUProps={{
                            linkText: null,
                        }}
                    />
                </PageContent>
            </ListBase>
        )
    }),
)

export default VendorPoList

const navigateTo = (record: PurchaseOrderModel) =>
    `${woResource.resource}/${record.workOrder}/invoice`
