import { type FC } from 'react'

import { Link } from 'react-router-dom'

import { type Identifier } from 'appTypes'
import { useCreateResourcePath } from 'core/resource'
import { alpha, globalClassNames } from 'lib'
import { Anchor, Spacer, Typography, BoxContainer } from 'ui'

import { type UnitModel } from '../types'
import unitResource from '../unitResource'
import { isUnitArchived } from '../utils'

import { UnitDeactivatedBadge } from './UnitDeactivatedBadge'
import { UnitIntegrationsStatusPopper } from './UnitIntegrationsStatusPopper'

export const TitleWithTelematicsStatus: FC<{ record: UnitModel; disableLink?: boolean }> = ({
    record,
    disableLink = false,
}) => {
    const title = disableLink ? (
        <div className={globalClassNames.ellipsis}>{record.number}</div>
    ) : (
        <TitleLink id={record.id}>{record.number}</TitleLink>
    )
    return (
        <Spacer overflow="hidden">
            {title}
            {isUnitArchived(record) && disableLink ? (
                <BoxContainer>
                    <Typography
                        color="text.disabled"
                        variant="chartLabel"
                        sx={({ palette }) => ({
                            background: alpha(palette.text.primary, 0.12),
                            borderRadius: '2px',
                            padding: '2px 4px',
                            marginLeft: '4px',
                            marginTop: '2px',
                        })}
                    >
                        ARCHIVED
                    </Typography>
                </BoxContainer>
            ) : record.telematicsData ? (
                <Spacer
                    onClick={(e) => {
                        e.preventDefault()
                    }}
                >
                    {(record.telematicsData.telematicsStatus === 'CONNECTED' ||
                        record.telematicsData.error) && (
                        <UnitIntegrationsStatusPopper telematicData={record.telematicsData} />
                    )}
                    {record.telematicsData.status === 'DEACTIVATED' &&
                        record.telematicsData?.telematicsStatus === 'CONNECTED' && (
                            <UnitDeactivatedBadge provider={record.telematicsData.provider} />
                        )}
                </Spacer>
            ) : null}
        </Spacer>
    )
}

const TitleLink = ({ children, id }: { children: string; id: Identifier }) => {
    const createPath = useCreateResourcePath()

    return (
        <Anchor
            className={globalClassNames.ellipsis}
            fontSize="inherit"
            fontWeight="inherit"
            underline="none"
            component={Link}
            to={createPath({
                type: 'edit',
                resource: unitResource.resource,
                id,
            })}
        >
            {children}
        </Anchor>
    )
}
