import { type NoInfer } from 'appTypes'
import { ListFilterValueInput, type Column, type ListFilterChoice } from 'components'
import { defaultPriorities, options, type PriorityConfig } from 'resources/common'

import { WoPriorityKeys, type WorkOrderModel } from '../types'

const source = 'priority' as keyof WorkOrderModel

const label = 'Priority'

const priorities = [
    { ...defaultPriorities.HIGH, id: WoPriorityKeys.HIGH },
    { ...defaultPriorities.MEDIUM, id: WoPriorityKeys.MEDIUM },
    { ...defaultPriorities.LOW, id: WoPriorityKeys.LOW },
] satisfies PriorityConfig<WoPriorityKeys>[]

const getConfig = options.createConfig<PriorityConfig<WoPriorityKeys>>(priorities)

const value = options.makeIconValue(getConfig)

const tableColumn = <Source extends string = typeof source>({ id }: { id?: Source }) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: label,
        renderCell: (cell) => value(cell.value),
    }) as const satisfies Column

const sort = <Source extends string = typeof source>() =>
    ({
        id: source as NoInfer<Source>,
        label,
    }) as const

const filter = () =>
    ({
        id: source,
        label,
        renderComponent: (props) => (
            <ListFilterValueInput
                {...props}
                inputText={(option) => option.id}
                makeItemLabel={(record) => {
                    return value(record.id as WoPriorityKeys)
                }}
            />
        ),
    }) as const satisfies ListFilterChoice

export const priority = {
    source,
    label,
    tableColumn,
    sort,
    filter,
    choices: options.createIdChoices(priorities),
    value: (record: Pick<WorkOrderModel, 'priority'>) => value(record.priority),
    getConfig,
    inputValue: options.makeIdValue(value),
}
