import images from 'assets/images'
import { config, urls } from 'configs'
import { authStore } from 'core/auth'
import { makeid } from 'utils'

import EroadConnect from './components/EroadConnect'
import GeotabConnect from './components/GeotabConnect'
import { type IntegrationProvider, type Telematics, type TelematicsExtra } from './types'

export const integrationRedirectUri = window.location.origin + urls.integrationsRedirect

const samsaraUrl =
    `https://api.samsara.com/oauth2/authorize\
?client_id=${config.SAMSARA_APP_ID}\
&redirect_uri=${integrationRedirectUri}?oauth2=samsara&response_type=code&state=` + makeid(20)

const motiveUrl = `https://api.gomotive.com/oauth/authorize\
?client_id=${config.MOTIVE_APP_ID}\
&redirect_uri=${integrationRedirectUri}?oauth2=motive\
&response_type=code\
&scope=fault_codes.read+locations.vehicle_locations_single+companies.read\
+vehicles.read+inspection_reports.manage+assets.read+locations.vehicle_locations_list`

export const telematicsExtras: {
    [key in IntegrationProvider]: TelematicsExtra & Pick<Telematics, 'provider'>
} = {
    samsara: {
        connect: { url: samsaraUrl },
        logo: images.samsaraLogo,
        provider: 'samsara',
        providerName: 'Samsara',
    },
    motive: {
        connect: { url: motiveUrl },
        logo: images.motiveLogo,
        provider: 'motive',
        providerName: 'Motive',
    },
    geotab: {
        connect: {
            Content: GeotabConnect,
        },
        logo: images.geotabLogo,
        provider: 'geotab',
        providerName: 'Geotab',
    },
    eroad: {
        connect: {
            Content: EroadConnect,
        },
        logo: images.eroad,
        provider: 'eroad',
        providerName: 'EROAD',
    },
}

export const hasIntegrationActiveTasks = (provider: string, tasks?: string[]) => {
    return (tasks || authStore.currentCompany.activeTasks).some((item) => item.startsWith(provider))
}

export const startProviderTask = (provider: string) => {
    authStore.startAsyncTask(provider + '_' + makeid())
}
