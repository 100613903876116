import { type FC } from 'react'

import { Header, InnerHeader, type TabType } from 'components'

const ProfileHeader: FC<{ tabs: TabType[] }> = ({ tabs }) => {
    return (
        <InnerHeader
            tabs={tabs}
            tabWidth="188px"
        >
            <Header.Info>
                <Header.Content>
                    <Header.Title>Personal Account</Header.Title>
                </Header.Content>
            </Header.Info>
        </InnerHeader>
    )
}

export default ProfileHeader
