import { formatMoney } from 'components'
import { type ShopModel } from 'resourcesBase'

const source = 'laborHourlyRate' as const satisfies keyof ShopModel
const label = 'Hourly Labor Rate'

interface Data extends Pick<ShopModel, 'laborHourlyRate'> {}

const value = (params: Data) => {
    if (!params) {
        return ''
    }
    return formatMoney(params.laborHourlyRate)
}

const hourlyLaborRate = {
    source,
    label,
    value,
}

export default hourlyLaborRate
