import { MainHeader, CreateButtonView, Header, ListTotalBadge, formatMoney } from 'components'
import { getListData } from 'core/data'
import { WorkOrderDrawerToggler } from 'pages/WorkOrders/components'
import { type WorkOrderAggregates, type WorkOrderModel } from 'resources/workOrders'

const WOListHeader = () => {
    return (
        <MainHeader
            mainAction={
                <WorkOrderDrawerToggler>
                    {({ onClick }) => (
                        <CreateButtonView onClick={onClick}>CREATE WORK ORDER</CreateButtonView>
                    )}
                </WorkOrderDrawerToggler>
            }
        >
            <Header.Info>
                <Header.Content>
                    <Header.Title>Work Orders</Header.Title>
                </Header.Content>

                <Header.Content mobilePlacement="bottom">
                    <ListTotalBadge<WorkOrderModel>
                        renderContent={(list) => {
                            const listData = getListData<WorkOrderAggregates>(list)
                            return (
                                <>
                                    {list.total || 0} • {formatMoney(listData.total || 0)} Total
                                </>
                            )
                        }}
                    />
                </Header.Content>
            </Header.Info>
        </MainHeader>
    )
}

export default WOListHeader
