import { useFormState } from 'react-hook-form'

import { formDirtyTriggerField } from 'components/form'

import UtilityDrawerBlock from './UtilityDrawerBlock'

const UtilityDrawerWarnWhenUnsavedChanges = () => {
    const formState = useFormState()
    const { isSubmitSuccessful, isSubmitting, dirtyFields } = formState
    const { [formDirtyTriggerField]: dirtyField, ...restDirtyFields } = dirtyFields
    const isDirty = Object.keys(restDirtyFields).length > 0

    const isSubmit = !isSubmitting && isSubmitSuccessful
    const canLeave = !isDirty || isSubmit

    return <UtilityDrawerBlock isBlocked={!canLeave} />
}

export default UtilityDrawerWarnWhenUnsavedChanges
