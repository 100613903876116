import { DateRangeKeys, getDateRangeDates } from 'lib'

export const periodSelectorDefaultDate = DateRangeKeys.LAST_30_DAYS

export const periodSelectorFormValues = (): PeriodSelectorFormValues => {
    const [periodSelectorDefaultFrom, periodSelectorDefaultTo] =
        getDateRangeDates(periodSelectorDefaultDate)

    return {
        date: periodSelectorDefaultDate,
        dateFrom: periodSelectorDefaultFrom,
        dateTo: periodSelectorDefaultTo,
    }
}

export interface PeriodSelectorFormValues {
    dateFrom: string
    dateTo: string
    date: string
}

export interface PeriodFilter {
    periodStart: string
    periodEnd: string
}
