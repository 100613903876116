import Icons from 'assets/icons'
import { Button } from 'ui'

import { useMultiFormatScannerModalContext } from './MultiFormatScannerContext'

const MultiFormatScannerCloseButton = () => {
    const { close } = useMultiFormatScannerModalContext()

    return (
        <Button
            variant="contained"
            sx={{ position: 'absolute', top: '20px', right: '20px' }}
            onClick={close}
            startIcon={<Icons.Close />}
        >
            Close
        </Button>
    )
}

export default MultiFormatScannerCloseButton
