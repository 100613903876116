import { type FC, type ReactNode } from 'react'

import { RecordContextProvider } from 'react-admin'

import { UtilityDrawerForm, useUtilityDrawerContext, UtilityDrawerEditor } from 'components'
import {
    InputStateContextProvider,
    type InputStateContextResult,
    inputStateAllDisabled,
    RaResourceContextProvider,
    useResource,
    useDataProviderConfig,
} from 'core'
import { workOrderSerializer } from 'pages/WorkOrders/config/constants'
import { WoPriorityKeys, WoRepairClassKeys, type WorkOrderModel } from 'resources/workOrders'

import { useStepsContext } from './UseWOStepsContext'
import { type UseWOExtraState } from './useAttachWO'

const woInputState: InputStateContextResult<WorkOrderModel> = {
    inputs: {
        unit: {
            disabled: true,
        },
        vendor: {
            disabled: false,
        },
    },
}

const UseWOWOForm: FC<{ children: ReactNode }> = ({ children }) => {
    const { extraArgs, extra, updateState, state } = useUtilityDrawerContext()
    const isFromDvir = extra?.parentRecord?.dvirs
    const { next } = useStepsContext()
    const { id, type } = extraArgs
    const { unit, workOrder, parentRecord, options }: UseWOExtraState = extra
    const resource = useResource()
    const onSuccess = () => {
        next()
    }

    const woResource = resource.resource + '/' + parentRecord.id + '/work_order'

    useDataProviderConfig(type ? woResource : '', {
        getOne: {
            makeId: () => '',
        },
        update: {
            makeId: () => '',
        },
    })

    if (type === 'create') {
        return (
            <RaResourceContextProvider value={{ resource: woResource }}>
                <InputStateContextProvider value={woInputState}>
                    <UtilityDrawerEditor<WorkOrderModel & { poNumber: string }>
                        disableResetCache
                        successMessage=""
                        serializer={workOrderSerializer}
                        defaultValues={
                            options?.woDefaultValues || {
                                priority: WoPriorityKeys.LOW,
                                repairPriorityClass: isFromDvir
                                    ? WoRepairClassKeys.NON_SCHEDULED
                                    : WoRepairClassKeys.SCHEDULED,
                                unit: unit.id,
                                domicile: unit.domicile,
                                customer: unit.customer,
                            }
                        }
                        record={workOrder}
                        onSuccess={(wo: WorkOrderModel) => {
                            const extra: UseWOExtraState = { ...state.extra, workOrder: wo }
                            updateState({
                                ...state,
                                extra,
                                extraArgs: { ...state.extraArgs, id: wo.id },
                            })
                            onSuccess()
                        }}
                        disableCloseOnSubmit
                    >
                        {children}
                    </UtilityDrawerEditor>
                </InputStateContextProvider>
            </RaResourceContextProvider>
        )
    }

    if (id) {
        return (
            <InputStateContextProvider value={inputStateAllDisabled}>
                <RecordContextProvider value={workOrder}>
                    <UtilityDrawerForm
                        onSubmit={async () => onSuccess()}
                        disableCloseOnSubmit
                        defaultValues={options?.woDefaultValues}
                    >
                        {children}
                    </UtilityDrawerForm>
                </RecordContextProvider>
            </InputStateContextProvider>
        )
    }

    return null
}

export default UseWOWOForm
