import Icons from 'assets/icons'
import images from 'assets/images'
import { urls } from 'configs'
import { Link, withColor } from 'lib'
import { Box, Img, Typography, BoxContainer, Button } from 'ui'

const NotFound = () => {
    return (
        <BoxContainer
            minHeight="100vh"
            justifyContent="center"
            px="15px"
        >
            <Box
                maxWidth="424px"
                textAlign="center"
            >
                <Img
                    src={images.page404}
                    sx={{ mb: '32px', width: 'auto', height: 'auto' }}
                />

                <Typography
                    variant="h5"
                    component="div"
                    color={withColor('text.primary')}
                    mb="16px"
                >
                    Something is Wrong
                </Typography>

                <Typography
                    variant="body1"
                    color={withColor('text.secondary')}
                    mb="16px"
                >
                    The page you are looking for was moved, removed, renamed, or might never existed
                </Typography>

                <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    component={Link}
                    to={urls.root}
                    startIcon={<Icons.HomeOutlined />}
                >
                    Home
                </Button>
            </Box>
        </BoxContainer>
    )
}

export default NotFound
