import { type FC } from 'react'

import images from 'assets/images'
import { NoResultsCard } from 'components'
import { AddReferenceButton } from 'pages/Parts/CrossReference/components'
import { useInventoryContext } from 'resources/inventory'
import { Typography } from 'ui'

const NoResultCard: FC<{ path: string }> = ({ path }) => {
    const inventoryView = useInventoryContext()

    return (
        <NoResultsCard
            direction="row"
            height="240px"
            title="No Cross-reference"
            imageSrc={images.noCrossReference}
            imageProps={{
                width: '80px',
                height: '70px',
                mr: '32px',
                ml: '6px',
            }}
            subtitle={
                inventoryView ? null : (
                    <AddReferenceButton
                        path={path}
                        renderElement={(onClick) => (
                            <Typography
                                onClick={onClick}
                                variant="body1"
                                sx={{
                                    cursor: 'pointer',
                                }}
                                color="primary.main"
                            >
                                Add Cross-reference
                            </Typography>
                        )}
                    />
                )
            }
        />
    )
}
export default NoResultCard
