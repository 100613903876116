import { type FC } from 'react'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import { InfoCardRowBase, MoneyField } from 'components'
import { type InvoiceTax } from 'resources/invoices'

import ItemLabel from '../ItemLabel'

import ManageTaxDrawerToggler from './ManageTaxDrawerToggler'

const TaxRow: FC<{ tax: InvoiceTax; invoiceId: Identifier; readOnly: boolean }> = ({
    tax,
    invoiceId,
    readOnly,
}) => {
    return (
        <InfoCardRowBase
            label={
                <ManageTaxDrawerToggler
                    id={tax.id}
                    invoiceId={invoiceId}
                    readOnly={readOnly}
                >
                    {(open) => (
                        <ItemLabel
                            Icon={Icons.Tax}
                            onClick={open}
                        >
                            {tax.name}
                        </ItemLabel>
                    )}
                </ManageTaxDrawerToggler>
            }
        >
            <MoneyField value={tax.price} />
        </InfoCardRowBase>
    )
}

export default TaxRow
