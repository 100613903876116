import { type MouseEvent } from 'react'

import { useTranslate } from 'react-admin'

import { type IntervalsType, type PmModel } from 'resources/pm'
import { getMeterIcon } from 'resources/units'
import { SvgIcon, ToggleButton, ToggleButtonGroup, BoxContainer, Typography } from 'ui'

import { PMBadge } from './PMBadge'
interface MeterTypeSelectionButtonProps {
    selectedPM: IntervalsType
    setSelectedPM: React.Dispatch<React.SetStateAction<IntervalsType>>
    unitPmRecord: PmModel['intervals']
}

const MeterTypeSelectionButton = ({
    selectedPM,
    setSelectedPM,
    unitPmRecord,
}: MeterTypeSelectionButtonProps) => {
    const translate = useTranslate()
    const handleAlignment = (event: MouseEvent, newMeter: IntervalsType['type']) => {
        setSelectedPM(() =>
            newMeter ? unitPmRecord.find((interval) => interval.type === newMeter) : selectedPM,
        )
    }
    const isMultipleIntervals = unitPmRecord.length > 1
    return (
        <BoxContainer justifyContent="space-between">
            <Typography
                variant="tooltip"
                color="text.primary"
            >
                {translate(selectedPM.type).toUpperCase()}
            </Typography>
            <ToggleButtonGroup
                value={selectedPM.type}
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
            >
                <ToggleButton
                    value={unitPmRecord[0].type}
                    sx={{ width: '24px', height: '24px' }}
                    aria-label={translate(unitPmRecord[0].type)}
                >
                    <PMBadge
                        unitPmRecord={unitPmRecord[0]}
                        disabled={!isMultipleIntervals}
                    >
                        <SvgIcon sx={{ width: '16px', height: '16px' }}>
                            {getMeterIcon(unitPmRecord[0].type)}
                        </SvgIcon>
                    </PMBadge>
                </ToggleButton>
                {isMultipleIntervals && (
                    <ToggleButton
                        value={unitPmRecord[1].type}
                        sx={{ width: '24px', height: '24px' }}
                        aria-label={translate(unitPmRecord[1].type)}
                    >
                        <PMBadge unitPmRecord={unitPmRecord[1]}>
                            <SvgIcon sx={{ width: '16px', height: '16px' }}>
                                {getMeterIcon(unitPmRecord[1].type)}
                            </SvgIcon>
                        </PMBadge>
                    </ToggleButton>
                )}
            </ToggleButtonGroup>
        </BoxContainer>
    )
}
export default MeterTypeSelectionButton
