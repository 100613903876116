import { useEffect } from 'react'

import { useInput } from 'react-admin'
import { useWatch, useFormContext } from 'react-hook-form'

import { makeid } from 'utils'

export const formDirtyTriggerField = 'formDirtyTriggerField'

const FormMakeSubmittable = () => {
    const { setValue, trigger, formState } = useFormContext()
    const values = useWatch()

    useInput({ source: formDirtyTriggerField })

    useEffect(() => {
        if (formState.isDirty) {
            return
        }
        // This forces the form to be isDirty = true
        setValue(formDirtyTriggerField, makeid(), {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
        })
    }, [formState.isDirty || values])

    // If a required reference input has default value
    // it makes the form inValid = false, while still fetching the default value.
    // When the data arrives, it does not set the form to valid.
    // This is a workaround to force the form to revalidate
    // on every change
    useEffect(() => {
        if (formState.isDirty && formState.isValid) {
            return
        }
        trigger(formDirtyTriggerField)
    }, [values])

    return null
}

export default FormMakeSubmittable
