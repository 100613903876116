import { type NoInfer } from 'appTypes'
import { type Column } from 'components'
import { formatDate } from 'lib'

import { type InvoiceModel } from '../types'

const source = 'dueDate' as const satisfies keyof InvoiceModel
const label = 'Due Date'

const value = (record: Pick<InvoiceModel, 'dueDate'>) =>
    formatDate(record.dueDate, (format) => format.shortenedDate)

const tableColumn = <Source extends string = typeof source>() =>
    ({
        field: source as NoInfer<Source>,
        headerName: label,
        renderCell: (cell) => value(cell.row),
    }) as const satisfies Column

const dueDate = {
    source,
    label,
    value,
    tableColumn,
}

export default dueDate
