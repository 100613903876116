import { hasPermission, basePermissions, useResourcePermissions } from 'core/permissions'

import { type ActionChildren } from '../Actions'

import { archiveActionBaseOptions } from './archiveActionCommon'
import useArchiveOne, { type ArchiveOneParams } from './useArchiveOne'

interface ArchiveOneActionProps extends ArchiveOneParams {
    children: ActionChildren
}

export const ArchiveOneAction = ({ children, ...rest }: ArchiveOneActionProps) => {
    const archiveOne = useArchiveOne()
    const permissions = useResourcePermissions(rest.resource)
    // TODO: refactor this.
    if (
        !hasPermission(
            rest.isArchived
                ? permissions[basePermissions.unarchive]
                : permissions[basePermissions.archive],
        )
    ) {
        return null
    }

    return children({
        disabled: false,
        onClick: () => {
            archiveOne(rest)
        },
        ...archiveActionBaseOptions(rest.isArchived),
    })
}

export const archiveOneAction = (params: ArchiveOneActionProps) => (
    <ArchiveOneAction
        {...params}
        key="archive-one"
    />
)
