import { useState, useRef } from 'react'

import Cropper, { type Point } from 'react-easy-crop'

import { useSetBlocker } from 'core/blocker'
import { alpha, withColor } from 'lib'
import { Slider, Modal, backdropClasses, Typography, BoxContainer, Button } from 'ui'

import { getCroppedImg, urltoFile } from './crop'
import { CropperCardWrapper, CropperCroppingArea } from './styled'

interface cropperProps {
    image: {
        name: string
        type: string
        blob: string
    }
    onClose: () => void
    onCropped: (file: File) => void
}

const ImageCropper = ({ image, onClose, onCropped }: cropperProps) => {
    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const cropperRef = useRef<Cropper>(null)

    const saveImage = async () => {
        if (!cropperRef) {
            return
        }

        const cropData = cropperRef.current!.getCropData()?.croppedAreaPixels
        if (!cropData) {
            return
        }

        try {
            const blob = await getCroppedImg(image.blob, cropData)
            const file = await urltoFile(blob as string, image.name, image.type)
            onCropped(file)
            onClose()
        } catch (e) {
            return e
        }
    }

    useSetBlocker({
        close: onClose,
    })

    return (
        <Modal
            open
            onClose={onClose}
            sx={{
                [`& .${backdropClasses.root}`]: {},
            }}
        >
            <BoxContainer
                sx={{
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center',
                }}
            >
                <CropperCardWrapper>
                    <Typography
                        variant="h6"
                        mb="24px"
                        color={withColor('text.main')}
                    >
                        Upload Picture
                    </Typography>
                    <CropperCroppingArea>
                        <Cropper
                            ref={cropperRef}
                            image={image.blob}
                            zoom={zoom}
                            crop={crop}
                            cropShape="round"
                            aspect={1 / 1}
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            objectFit="contain"
                            zoomSpeed={0.25}
                        />
                    </CropperCroppingArea>
                    <Slider
                        step={0.125}
                        min={1}
                        max={3}
                        value={zoom}
                        onChange={(e, v) => setZoom(v as number)}
                    />
                    <Typography
                        textAlign="center"
                        sx={(theme) => ({
                            mb: '40px',
                            color: alpha(theme.palette.text.secondary, 0.6),
                        })}
                    >
                        Use slider to zoom in/out of the photo.
                    </Typography>
                    <BoxContainer justifyContent="flex-end">
                        <Button
                            size="medium"
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            size="medium"
                            onClick={saveImage}
                            variant="contained"
                            sx={{ ml: '8px' }}
                        >
                            Apply
                        </Button>
                    </BoxContainer>
                </CropperCardWrapper>
            </BoxContainer>
        </Modal>
    )
}

export default ImageCropper
