import { CreateButtonView, Header, ListTotalBadge, MainHeader } from 'components'
import { VendorDrawerToggler } from 'resources/vendors'

const VendorListHeader = () => {
    return (
        <MainHeader
            mainAction={
                <VendorDrawerToggler>
                    {(onClick) => (
                        <CreateButtonView onClick={onClick}>Create Vendor</CreateButtonView>
                    )}
                </VendorDrawerToggler>
            }
        >
            <Header.Info>
                <Header.Content>
                    <Header.Title>Vendors</Header.Title>
                </Header.Content>
                <Header.Content mobilePlacement="bottom">
                    <ListTotalBadge />
                </Header.Content>
            </Header.Info>
        </MainHeader>
    )
}

export default VendorListHeader
