import { type FC } from 'react'

import { type Theme } from 'lib'
import { StyledElement } from 'ui'

import { type ExpirationModel, StatusKeys } from '../types'

const source: keyof ExpirationModel = 'status'

const statusConfig: { [key in StatusKeys]: { text: string; color: (theme: Theme) => string } } = {
    [StatusKeys.PLANNED]: {
        text: 'Planned',
        color: (theme) => {
            return theme.palette.success.main
        },
    },
    [StatusKeys.OVERDUE]: {
        text: 'Overdue',
        color: (theme) => {
            return theme.palette.error.main
        },
    },
    [StatusKeys.DUE_SOON]: {
        text: 'Due Soon',
        color: (theme) => {
            return theme.palette.warning.main
        },
    },
    [StatusKeys.COMPLETED]: {
        text: 'Completed',
        color: (theme) => {
            return theme.palette.text.primary
        },
    },
}

const getConfig = (key: StatusKeys) => statusConfig[key]

const Value: FC<{ value: StatusKeys }> = ({ value }) => {
    const config = getConfig(value)
    return <StyledElement sx={{ color: config.color }}>{config.text}</StyledElement>
}

export const status = {
    source,
    label: 'Status',
    Keys: StatusKeys,
    getConfig,
    Value,
}
