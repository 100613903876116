import { type SVGProps, type FC } from 'react'

import { type Dimensions } from '@nivo/core'
import {
    type Point,
    type DatumValue,
    Line,
    type LineSvgProps,
    type Datum,
    type CustomLayerProps,
} from '@nivo/line'
import { TooltipWrapper } from '@nivo/tooltip'

import { formatMoney } from 'components'
import { useTheme } from 'lib'
import { Typography, Stack } from 'ui'

import { TooltipBox } from '../../../components'

interface CustomPointProps extends CustomLayerProps {
    currentPoint: {
        x: number
        y: number
    }
}

export const CustomPoint = (props: CustomPointProps) => {
    const { currentPoint } = props
    // it will show the current point
    if (currentPoint) {
        return (
            <g>
                <LineChartPoint
                    cx={currentPoint.x}
                    cy={currentPoint.y}
                />
            </g>
        )
    }
}

export const LineChartPoint = (props: SVGProps<SVGCircleElement>) => {
    return (
        <circle
            r={3}
            strokeWidth="3"
            stroke="#2979FF"
            fill="#ffffff"
            {...props}
        />
    )
}
interface customPoint extends Omit<Point, 'data'> {
    data: {
        x: DatumValue
        xFormatted: string | number
        y: DatumValue
        yFormatted: string | number
        yStacked?: number
        date: string
    }
}
interface CustomPointTooltipProps {
    point: customPoint
    dataFormat?: (value: string | number) => string
}

export const WidgetLineChartTooltip: FC<CustomPointTooltipProps> = ({ dataFormat, point }) => {
    return (
        <TooltipWrapper
            anchor="left"
            position={[0, 0]}
        >
            <TooltipBox>
                <Stack>
                    <Typography
                        variant="tooltip"
                        color="white"
                    >
                        {dataFormat
                            ? dataFormat(point.data.yFormatted)
                            : formatMoney(Number(point.data.yFormatted))}
                    </Typography>
                    <Typography
                        variant="tooltip"
                        color="white"
                    >
                        {point.data.date as any}
                    </Typography>
                </Stack>
            </TooltipBox>
        </TooltipWrapper>
    )
}
interface WidgetLineChartBaseProps
    extends Partial<Omit<LineSvgProps, 'data'>>,
        Partial<Dimensions> {
    data: {
        date: string
        value: number
    }[]
    isValid: boolean
}
const defaultData: Datum[] = [
    { x: 0, y: 0 },
    { x: 1, y: 0 },
]

const NonValidTooltip = () => null

// Refactor widget line charts to have a base
const WidgetLineChartBase = ({
    data,
    isValid,
    tooltip = WidgetLineChartTooltip,
    ...props
}: WidgetLineChartBaseProps) => {
    const theme = useTheme()

    const normalizedData = isValid
        ? data.map((obj, i) => {
              const value = obj.value
              const date = new Date(obj.date)
                  .toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: '2-digit',
                  })
                  .replace(',', '')
              return { x: i, y: value, date }
          })
        : defaultData

    return (
        <Line
            axisBottom={null}
            width={108}
            height={110}
            margin={{ top: 15, right: 4, bottom: 15, left: 4 }}
            curve="cardinal"
            enableCrosshair={false}
            data={[
                {
                    id: 'fake corp. A',
                    data: normalizedData,
                },
            ]}
            enableGridX={false}
            enableGridY={false}
            enableArea={false}
            useMesh={isValid}
            axisLeft={null}
            axisRight={null}
            axisTop={null}
            tooltip={isValid ? tooltip : NonValidTooltip}
            yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                stacked: true,
                reverse: false,
            }}
            yFormat=" >-.2f"
            layers={[
                'grid',
                'markers',
                'axes',
                'areas',
                'crosshair',
                'lines',
                'slices',
                CustomPoint,
                'mesh',
                'legends',
            ]}
            colors={isValid ? theme.palette.primary.main : theme.palette.charts.disable}
            {...props}
        />
    )
}
export default WidgetLineChartBase
