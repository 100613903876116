import { type ReactNode, type ReactElement } from 'react'

import { alpha, type Theme } from 'lib'
import { Typography, BoxContainer, Box } from 'ui'

import {
    ArrayControllerDeleteButton,
    type ArrayControllerDeleteButtonProps,
} from './ArrayControllerDeleteButton'

interface ArrayControllerBoxProps {
    title?: string | ReactElement
    children?: ReactNode
    renderDeleteButton?: (params: {
        defaultButton: (args?: ArrayControllerDeleteButtonProps) => ReactElement
    }) => ReactElement
    deleteAlwaysVisible?: boolean
    gap?: string
}

export const arrayControllerBoxBackground = (theme: Theme) =>
    alpha(theme.palette.text.primary, 0.04)

const ArrayControllerBox = ({
    children,
    title,
    renderDeleteButton,
    deleteAlwaysVisible,
    gap,
}: ArrayControllerBoxProps) => {
    return (
        <Box
            bgcolor={arrayControllerBoxBackground}
            p="20px"
            mb={gap || '20px'}
        >
            <BoxContainer
                justifyContent="space-between"
                minHeight="31px"
                gap="10px"
            >
                <Typography
                    variant="subtitle2"
                    component="div"
                    overflow="hidden"
                >
                    {title}
                </Typography>
                {renderDeleteButton ? (
                    renderDeleteButton({
                        defaultButton: (params) => <ArrayControllerDeleteButton {...params} />,
                    })
                ) : (
                    <ArrayControllerDeleteButton
                        {...(deleteAlwaysVisible && { controller: { alwaysVisible: true } })}
                    />
                )}
            </BoxContainer>
            {children ? <Box mt="20px">{children}</Box> : null}
        </Box>
    )
}

export default ArrayControllerBox
