import { type ListFilterChoice } from 'components'

import { type PaymentTermModel } from '../types'

const source = 'paymentTerm' as const
const label = 'Payment Term'

const value = (record: Pick<PaymentTermModel, 'name'>): string => (record ? record.name : '')

const filter = ({ label }: { label?: string }) =>
    ({
        id: 'paymentTerm',
        label: label || 'Payment Term',
    }) as const satisfies ListFilterChoice

const self = {
    source,
    label,
    value,
    filter,
}

export default self
