import { useTranslate } from 'react-admin'
import { useFormState } from 'react-hook-form'

import { useSetBlocker } from 'core/blocker'
import { useConfirm } from 'core/confirmation/useConfirm'

import { formDirtyTriggerField } from './FormMakeSubmittable'

export const useAskConfirm = () => {
    const confirm = useConfirm()
    const translate = useTranslate()
    const askConfirm = (onConfirm: () => void) =>
        confirm({
            title: translate('ra.message.unsaved_changes'),
            confirmButtonProps: {
                children: 'CONFIRM',
            },
            onConfirm,
            confirmType: 'INFO',
        })

    return askConfirm
}

const FormWarnWhenUnsavedChanges = () => {
    const askConfirm = useAskConfirm()

    const { isSubmitSuccessful, isSubmitting, dirtyFields } = useFormState()
    const { [formDirtyTriggerField]: dirtyField, ...restDirtyFields } = dirtyFields
    const isDirty = Object.keys(restDirtyFields).length > 0
    const isSubmit = isSubmitting === false && isSubmitSuccessful === false

    useSetBlocker(
        {
            preventNavigate: (action, { resume }) => {
                askConfirm(() => {
                    resume()
                })
            },
        },
        {
            isOpen: isDirty && isSubmit,
        },
    )

    return null
}

export default FormWarnWhenUnsavedChanges
