import { useFormContext } from 'react-hook-form'

import { formErrorToString } from 'core'
import { Alert, AlertTitle, Typography } from 'ui'

const ErrorResetMessage = () => {
    const { formState, getValues } = useFormContext<{ email: string }>()
    const error = formErrorToString(formState.errors.email, 'code')

    if (error !== 'does_not_exist') {
        return null
    }

    const email = getValues('email')

    return (
        <Alert
            variant="standard"
            severity="error"
            sx={{ display: 'flex', mb: '24px' }}
        >
            <AlertTitle>Non Existing Account</AlertTitle>
            <Typography
                sx={{ mb: '14px' }}
                variant="body2"
                color="inherit"
            >
                The email {email} is not <br />
                associated with an account.
            </Typography>
            <Typography
                variant="body2"
                color="inherit"
            >
                Please verify/re-enter the email <br />
                address.
            </Typography>
        </Alert>
    )
}

export default ErrorResetMessage
