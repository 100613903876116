import { type FC } from 'react'

import { styled } from 'lib'
import { SimpleScrollbar, simpleScrollbarClasses } from 'ui'

import { useSidebar } from '../SidebarContext'

import NavItem from './NavItem'
import { type WithControls } from './types'

const Nav: FC<WithControls> = (props) => {
    const [snap] = useSidebar()

    return (
        <Items>
            <ItemsContent>
                {snap.menu.map((item) => (
                    <NavItem
                        key={item.key}
                        item={item}
                        {...props}
                    />
                ))}
            </ItemsContent>
        </Items>
    )
}

export default Nav

const Items = styled('div')`
    flex-grow: 1;
    overflow: hidden;
`

const ItemsContent = styled(SimpleScrollbar)`
    flex-grow: 1;
    height: 100%;
    .${simpleScrollbarClasses.content} {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-top: 8px;
        padding-bottom: 8px;
    }
`
