import { type FC } from 'react'

import Icons from 'assets/icons'
import { darken, lighten } from 'lib'
import { Alert as AlertBase, type AlertProps, alertClasses } from 'ui'

import { type NotificationType } from './types'

interface Props extends Pick<AlertProps, 'children' | 'onClose'> {
    severity: NotificationType
}

export const Alert: FC<Props> = (props) => {
    return (
        <AlertBase
            sx={(theme) => ({
                minWidth: 292,
                width: '100%',
                boxSizing: 'border-box',
                color: darken(theme.palette[props.severity].main, 0.6),
                background: lighten(theme.palette[props.severity].main, 0.9),
                [`& .${alertClasses.message}`]: {
                    fontWeight: 400,
                    paddingBottom: 1,
                },
                [`& .${alertClasses.icon}`]: {
                    fontWeight: 500,
                    color: theme.palette[props.severity].main,
                },
            })}
            iconMapping={{
                success: <Icons.CheckCircleOutline fontSize="inherit" />,
            }}
            elevation={1}
            variant="filled"
            {...props}
        />
    )
}
