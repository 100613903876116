import { type FC } from 'react'

import Grid, { type GridProps } from './Grid'

export const GridContainer: FC<GridProps> = (props) => {
    return (
        <Grid
            container
            {...props}
        />
    )
}

const spacing: number = 6

export const GridContainerColumns: FC<GridProps> = (props) => {
    return (
        <Grid
            container
            columnSpacing={spacing}
            {...props}
        />
    )
}

export const GridContainerGrid: FC<GridProps> = (props) => {
    return (
        <Grid
            container
            spacing={spacing}
            {...props}
        />
    )
}
