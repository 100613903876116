import { SkeletonSliderCardContainer } from 'components'
import { Stack, BoxContainer, Skeleton } from 'ui'

const UnitPMCardSkeleton = () => {
    return (
        <SkeletonSliderCardContainer sx={{ gap: '20px' }}>
            <BoxContainer width="100%">
                <Skeleton
                    variant="circular"
                    width="40px"
                    height="40px"
                    sx={{
                        flexShrink: 0,
                        marginRight: '16px',
                    }}
                />
                <Stack
                    width="100%"
                    spacing="12px"
                >
                    <Skeleton
                        height="8px"
                        width="120px"
                    />
                    <Skeleton
                        height="8px"
                        sx={{
                            maxWidth: '240px',
                        }}
                    />
                </Stack>
            </BoxContainer>
            <Skeleton />
        </SkeletonSliderCardContainer>
    )
}
export const unitPMCardSkeletons = new Array(6)
    .fill(0)
    .map((v, i) => <UnitPMCardSkeleton key={i} />)
export default UnitPMCardSkeleton
