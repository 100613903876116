import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import { ViewHeader } from 'components'
import { useCanAccess } from 'core'
import { woOperations, type WorkOrderModel } from 'resources/workOrders'
import { IconBox } from 'ui'

import AddVendorDrawerToggler from './AddVendorDrawerToggler'

const VendorSectionHeader = () => {
    const record = useRecordContext<WorkOrderModel>()
    const getCanAccess = useCanAccess()
    const canAccess = getCanAccess(woOperations.editVendor)

    return (
        <ViewHeader
            title="Vendor"
            loading={!record}
        >
            <ViewHeader.Content placement="rightMobile">
                {record?.purchaseOrderData ? (
                    <IconBox title="To change or remove vendor, you'll need to create a new work order.">
                        <Icons.InfoOutlined />
                    </IconBox>
                ) : (
                    <AddVendorDrawerToggler id={record?.id}>
                        {({ onClick }) => (
                            <IconBox
                                disabled={!canAccess.value}
                                onClick={canAccess.value ? onClick : undefined}
                                title={canAccess.message}
                            >
                                <Icons.Add />
                            </IconBox>
                        )}
                    </AddVendorDrawerToggler>
                )}
            </ViewHeader.Content>
        </ViewHeader>
    )
}

export default VendorSectionHeader
