import { type FC } from 'react'

import { formatPercent } from 'components'
import { Box, Typography } from 'ui'

interface LabelProps {
    label: number
    validData: boolean
    inUnit?: boolean
}
const Label: FC<LabelProps> = ({ label, validData, inUnit = false }) => {
    // Convert into generic component for widgets
    return (
        <Box position="relative">
            {validData ? formatPercent(label, true) : 'No Data'}
            {validData ? (
                <Typography
                    variant="chartLabel"
                    color="text.disabled"
                    sx={{
                        position: 'absolute',
                        bottom: '-10px',
                        left: 0,
                        width: 'max-content',
                    }}
                >
                    of {inUnit ? 'Units' : 'Fleet'}'s Capacity
                </Typography>
            ) : null}
        </Box>
    )
}
export default Label
