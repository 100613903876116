import { InfoCard, InfoCardDetails, InfoCardHeader } from 'components'
import { type UnitModel } from 'resources/units'
import { ck34Fields } from 'resources/vmrs'

const Engine = () => {
    return (
        <InfoCard>
            <InfoCardHeader title="Engine" />
            <InfoCardDetails<UnitModel>
                details={[
                    {
                        label: 'Manufacturer/Make',
                        source: ({ engineVmrsManufacturerData }) =>
                            ck34Fields.self.value(engineVmrsManufacturerData),
                    },
                    {
                        label: 'Model',
                        source: 'engineModel',
                    },
                    {
                        label: 'HP',
                        source: 'engineHp',
                    },
                ]}
            />
        </InfoCard>
    )
}

export default Engine
