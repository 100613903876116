import { PoTypeKeys } from '../types'

const config: { [key in PoTypeKeys]: string } = {
    [PoTypeKeys.WORK_ORDER]: 'Vendor WO',
    [PoTypeKeys.STANDARD]: 'Standard',
    [PoTypeKeys.CREDIT]: 'Credit',
}

const poType = {
    config,
}

export default poType
