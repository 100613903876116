import { useAuthProvider } from 'core/auth'
import { useResource, type ResourceType } from 'core/resource'

import { type AllPermissions } from './types'

const useResourcePermissions = (resourceProp?: ResourceType): AllPermissions => {
    const resource = useResource(resourceProp)
    const authProvider = useAuthProvider()
    const permission = authProvider.permissions()[resource.name]

    return permission
}

export default useResourcePermissions
