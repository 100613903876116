import avatar from './avatar'
import balanceDue from './balanceDue'
import closedDate from './closedDate'
import dueDate from './dueDate'
import invoiceDate from './invoiceDate'
import invoiceNumber from './number'
import paid from './paid'
import poNumber from './poNumber'
import self from './self'
import status from './status'
import statusChanged from './statusChanged'
import total from './total'
import invoiceType from './type'
import woCompletedDate from './woCompletedDate'

const invoiceFields = {
    self,
    avatar,
    number: invoiceNumber,
    type: invoiceType,
    status,
    invoiceDate,
    closedDate,
    total,
    paid,
    balanceDue,
    woCompletedDate,
    dueDate,
    statusChanged,
    poNumber,
}

export default invoiceFields
