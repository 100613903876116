import Icons from 'assets/icons'
import { IconBox, IconElement } from 'ui'

const ArchivedUnitBadge = () => {
    return (
        <IconBox
            title="Archived"
            sx={{ ml: '6px', minHeight: '20px', minWidth: '20px' }}
        >
            <IconElement
                component={Icons.ArchivedUnit}
                color={(theme) => theme.palette.text.disabled}
            />
        </IconBox>
    )
}

export default ArchivedUnitBadge
