import { AutocompleteInput, type AutocompleteInputProps } from 'components'
import { ReferenceInputCreateLayer, ReferenceArrayInput, createLayerNotFoundKey } from 'core'
import { type ChipProps, autocompleteClasses } from 'ui'

import { type TagModel } from '../types'

import TagDrawerToggler from './TagDrawerToggler'
import { TagField } from './TagField'

const TagInput = () => {
    return (
        <ReferenceArrayInput
            reference="tags"
            source="tags"
            perPage={100}
        >
            <ReferenceInputCreateLayer>
                {({ setValue }) => (
                    <AutocompleteInput
                        multiple
                        renderCreateOption={(renderOption) => (
                            <TagDrawerToggler
                                onSuccess={(record) => {
                                    setValue(record.id)
                                }}
                                children={(open) =>
                                    renderOption({
                                        children: '+ Create New Tag',
                                        onClick: open,
                                    })
                                }
                            />
                        )}
                        label="Tags"
                        optionText={renderTag}
                        inputText={inputText}
                        renderTags={renderTags}
                        filterSelectedOptions
                        componentsProps={{
                            paper: {
                                sx: {
                                    [`.${autocompleteClasses.option}`]: {
                                        // TODO: Check why autocomplete option is changed to block instead of flex
                                        minHeight: 'auto !important',
                                    },
                                },
                            },
                        }}
                    />
                )}
            </ReferenceInputCreateLayer>
        </ReferenceArrayInput>
    )
}

export default TagInput

const renderTag = (option: TagModel, props?: ChipProps) => {
    if (option.id === createLayerNotFoundKey) {
        return null
    }

    return (
        <TagField
            tag={option}
            {...props}
        />
    )
}

const inputText = (record: TagModel) => {
    return record.id
}

const renderTags: AutocompleteInputProps['renderTags'] = (options: TagModel[], getTagProps) => {
    return options.map((option, index) => {
        return renderTag(option, getTagProps({ index }))
    })
}
