import { InfoCard, InfoCardDetails, InfoCardHeader } from 'components'
import { customerFields, type CustomerModel } from 'resources/customers'

export const BasicDetailsCard = () => {
    return (
        <InfoCard>
            <InfoCardHeader title="Basic Details" />
            <InfoCardDetails<CustomerModel>
                details={[
                    {
                        label: customerFields.dotNumber.label,
                        source: customerFields.dotNumber.source,
                    },
                    {
                        label: customerFields.phone.label,
                        source: (record) => <customerFields.phone.Value record={record} />,
                    },
                    {
                        label: customerFields.email.label,
                        source: customerFields.email.source,
                    },
                ]}
            />
        </InfoCard>
    )
}
