import { type SkeletonProps as MuiSkeletonProps, Skeleton as MuiSkeleton } from '@mui/material'

import { alpha, styled, generateNotForwardedProps } from 'lib'

interface SkeletonProps extends MuiSkeletonProps {
    aspect?: 'light' | 'dark'
}
export const createSkeletonArray = (size: number, props: SkeletonProps) =>
    new Array(size).fill(0).map((v, i) => (
        <Skeleton
            key={i}
            {...props}
        />
    ))
const Skeleton = styled(
    (props: SkeletonProps) => {
        return (
            <MuiSkeleton
                animation="wave"
                {...props}
            />
        )
    },
    {
        shouldForwardProp: generateNotForwardedProps<SkeletonProps>(['aspect']),
    },
)`
    transform: initial;
    width: 100%;
    height: 100%;
    background: ${({ theme, aspect = 'dark' }) => {
        const color = aspect === 'dark' ? theme.palette.text.secondary : theme.palette.white
        const opacity = aspect === 'dark' ? 0.03 : 0.45
        return alpha(color, opacity)
    }};
    &:after {
        background: ${({ theme, aspect = 'dark' }) => {
            const opacity = aspect === 'dark' ? 0.05 : 0.03

            return `linear-gradient(90deg, transparent, ${alpha(
                theme.palette.text.secondary,
                opacity,
            )}, transparent)`
        }};
    }
`
export default Skeleton

/* Places without Skeleton
Unit Notes
Work Order PO/Invoice
Part Notes
Part Photos
Company User Roles

Places without Skeleton that load very fast (Might not need)
Company Info & Billing
Configure Integrations
*/
