import Icons from 'assets/icons'
import { urls } from 'configs'
import { useTelematics } from 'resources/telematics'
import { StatusText } from 'ui'

import { Monitor } from '../../MonitorComponents'

import ConnectButton from './ConnectButton'

const MonitorIntegrations = () => {
    const { hasConnection, errorCount, runningCount, list } = useTelematics()
    if (list.isLoading) {
        return <Monitor.Skeleton />
    }

    const navigateTo = urls.integrations

    return (
        <Monitor>
            <Monitor.Title icon={Icons.WorkspacesOutlined}>Integrations</Monitor.Title>

            <Monitor.ArrowButton navigateTo={navigateTo} />
            <Monitor.Container>
                <Monitor.ColumnsContainer>
                    <Monitor.ValueText>{errorCount}</Monitor.ValueText>
                    <StatusText
                        sx={{ width: 'fit-content' }}
                        statusColor={(theme) => theme.palette.charts.red}
                    >
                        Error
                    </StatusText>
                </Monitor.ColumnsContainer>
                <Monitor.ColumnsContainer>
                    <Monitor.ValueText>{runningCount}</Monitor.ValueText>
                    <StatusText
                        sx={{ width: 'fit-content' }}
                        statusColor={(theme) => theme.palette.charts.greenBody}
                    >
                        Running
                    </StatusText>
                </Monitor.ColumnsContainer>
                {hasConnection ? null : <ConnectButton navigateTo={navigateTo} />}
            </Monitor.Container>
        </Monitor>
    )
}
export default MonitorIntegrations
