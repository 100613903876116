import { useListContext, useResourceContext, useShowContext } from 'react-admin'

import Icons from 'assets/icons'
import { ListTotalBadge, ViewHeader } from 'components'
import { getListData } from 'core'
import { type ReceiptModel, type PurchaseOrderModel } from 'resources/purchaseOrders'
import { BoxContainer, Button } from 'ui'

import { LineItemsDrawerToggler } from '../../actions'
import { formatPOItemTotal } from '../utils'

const LineItemsHeader = ({ receipt }: { receipt: ReceiptModel }) => {
    const resource = useResourceContext()
    const { isLoading } = useListContext()
    const { record } = useShowContext<PurchaseOrderModel>()

    return (
        <ViewHeader
            title="Line Items"
            typography={{ xs: 'subtitle1', sm: 'h5' }}
            loading={isLoading}
        >
            <ViewHeader.Content>
                <ListTotalBadge
                    renderContent={(list) => {
                        const { total: totalCount } = list
                        const listData = getListData<{ total: number }>(list)
                        return (
                            <>
                                {totalCount || 0} • {formatPOItemTotal(listData.total || 0, false)}{' '}
                                Total
                            </>
                        )
                    }}
                />
            </ViewHeader.Content>
            <ViewHeader.Content placement="right">
                <BoxContainer gap="24px">
                    <LineItemsDrawerToggler
                        poRecord={record}
                        resource={resource}
                    >
                        {({ onClick }) => (
                            <Button
                                startIcon={<Icons.Add />}
                                onClick={onClick}
                                disabled={record?.status !== 'OPEN' || Boolean(receipt)}
                            >
                                add Items
                            </Button>
                        )}
                    </LineItemsDrawerToggler>
                </BoxContainer>
            </ViewHeader.Content>
        </ViewHeader>
    )
}

export default LineItemsHeader
