import { type BarItemProps, ResponsiveBar } from '@nivo/bar'

import { useTheme } from 'lib'
import { StyledElement } from 'ui'

import { type PMWidgetData } from '../types'

import { pmWidgetMap } from './UnitPmWidgetLabel'

const CustomBarComponent = ({
    bar,
    ...rest
}: BarItemProps<{ ranking: string; color: any; value: any; total: number }>) => {
    const data = bar.data.data

    // const detailedData = data.data.detailedData
    // const selectedDateData = detailedData[data.id]

    // const heightValue = data.value
    // const heightScalePercentage = height / heightValue
    // const internalHeightInPX = selectedDateData.internal * heightScalePercentage
    // const clientHeightInPX = selectedDateData.client * heightScalePercentage

    // const clientHeightYCoordinate = y + height - clientHeightInPX
    // const internalHeightYCoordinate = y + height - internalHeightInPX
    const rectValue = (data.value * 100) / data.total

    return (
        <g>
            <rect
                fill="#E8EAEC"
                rx="2px"
                height={100}
                width={bar.width}
                x={bar.x}
                y={0}
            />
            {rectValue ? (
                <rect
                    fill={data.color}
                    rx="2px"
                    height={rectValue}
                    width={bar.width}
                    x={bar.x}
                    y={100 - rectValue}
                />
            ) : null}
        </g>
    )
}

const UnitPMWidgetBars = ({ data }: { data: PMWidgetData }) => {
    const { palette } = useTheme()

    return (
        <StyledElement
            sx={{
                width: '125px',
                height: '100px',
            }}
        >
            <ResponsiveBar
                data={pmWidgetMap.map((pm) => ({
                    ranking: pm.id,
                    color: palette.charts[pm.color],
                    value: data[pm.id + 'Count'] || 0,
                    total: data.total,
                }))}
                keys={['value']}
                indexBy="ranking"
                barComponent={CustomBarComponent}
                margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
                padding={0.65}
                groupMode="grouped"
                colors="#2a7ef0"
                axisTop={null}
                axisRight={null}
                axisLeft={null}
                enableGridY={false}
                enableGridX={false}
                enableLabel={false}
                axisBottom={null}
            />
        </StyledElement>
    )
}
export default UnitPMWidgetBars
