import { type FC, useEffect } from 'react'

import { useRecordContext } from 'react-admin'

import { Show, tabRoutes } from 'components'
import { useNotify, elementNotFoundMessage } from 'core'
import { Navigate, Routes } from 'lib'
import { photosTab } from 'resources/gallery'
import { type IssueModel } from 'resources/issues'
import { notesTab } from 'resources/notes'
import { isUnitArchived } from 'resources/units'

import IssueOverview from '../Overview'
import { canEdit } from '../utils'

import { Header } from './components'

const IssueShow = () => {
    return (
        <Show>
            <ShowBase />
        </Show>
    )
}

export default IssueShow

const ShowBase = () => {
    const record = useRecordContext<IssueModel>()

    if (isUnitArchived(record?.unitData)) {
        return <IssueGuard />
    }

    const { routes, tabs } = tabRoutes([
        {
            value: '',
            label: 'Overview',
            element: <IssueOverview />,
        },
        photosTab({ elementProps: { disableActions: canEdit(record) } }),
        notesTab({ elementProps: { removeAddButton: canEdit(record) } }),
    ])

    return (
        <>
            <Header tabs={tabs} />
            <Routes>{routes}</Routes>
        </>
    )
}

const IssueGuard: FC = () => {
    const notify = useNotify()

    useEffect(() => {
        notify({
            title: elementNotFoundMessage,
            type: 'error',
        })
    }, [])

    return <Navigate to=".." />
}
