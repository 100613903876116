import { type ReactNode } from 'react'

import { type BaseActionRenderParams } from 'core/actions'
import { alpha, styled } from 'lib'
import { Tooltip, Spacer, SvgIcon, svgIconClasses, IconButton, type IconButtonProps } from 'ui'

export interface GridActionViewProps
    extends Omit<IconButtonProps, 'onClick' | 'to' | 'title'>,
        Omit<BaseActionRenderParams, 'key' | 'color'> {
    titleOnDisabled?: boolean | string
}

export const DatagridActionView = styled(
    ({ title, Icon, disabled, to, titleOnDisabled, ...rest }: GridActionViewProps) => {
        const content = (
            <IconButton
                {...rest}
                disableRipple
                onClick={disabled ? undefined : rest.onClick}
                children={
                    <SvgIcon
                        component={Icon}
                        inheritViewBox
                        fontSize="small"
                    />
                }
            />
        )

        let tooltip: ReactNode | undefined = title
        if (disabled) {
            if (typeof titleOnDisabled === 'undefined') {
                tooltip = 'This action is disabled.'
            } else if (typeof titleOnDisabled === 'string') {
                tooltip = titleOnDisabled
            } else if (titleOnDisabled === false) {
                tooltip = undefined
            }
        }

        if (tooltip) {
            return <Tooltip title={tooltip}>{content}</Tooltip>
        }

        return content
    },
)`
    width: 28px;
    height: 28px;
    color: ${({ theme, disabled }) => alpha(theme.palette.text.primary, disabled ? 0.2 : 1)};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`

export const DatagridActionsContainer = styled(Spacer)`
    .${svgIconClasses.root} {
        display: block;
    }
`
