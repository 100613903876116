import { formatMoney } from 'components'

import { type PtFeeModel } from '../types'

const source: keyof PtFeeModel = 'maxValue'

const label = 'Max Amount'

const value = (record: Pick<PtFeeModel, 'maxValue'>) => {
    return <>{formatMoney(record.maxValue)}</>
}
export const maxAmount = {
    source,
    label,
    value,
}
