import { type Identifier } from 'appTypes'
import { api, type DeleteOneParams, type ResourceType } from 'core'
import { type InvoiceItemKeys, invoicesResource } from 'resources/invoices'
import { LineItemTypeKeys } from 'resources/lineItems'
import { pathJoin } from 'utils'

import { InvoiceLaborDrawer } from './InvoiceLaborDrawer'
import InvoicePartDrawer from './InvoicePartDrawer'
import InvoiceServiceDrawer from './InvoiceServiceDrawer'

export const getResource: (id: Identifier, action?: string) => ResourceType = (id, action) => ({
    name: 'invoice-items',
    resource: pathJoin(invoicesResource.resource, id, 'items', action),
})

const ItemTypeDrawerMap = {
    [LineItemTypeKeys.PART]: InvoicePartDrawer,
    [LineItemTypeKeys.LABOR]: InvoiceLaborDrawer,
    [LineItemTypeKeys.SERVICE]: InvoiceServiceDrawer,
}

export const getInvoiceItemDrawer = (type: InvoiceItemKeys) => ItemTypeDrawerMap[type]

export const getSuggestedPrice = (
    id: Identifier,
    data: { type: LineItemTypeKeys; part?: Identifier },
): Promise<{ sellingPrice: number }> => {
    const resource = pathJoin(invoicesResource.resource, id, 'selling_price')
    const response = api.post(resource, data)

    return response
}

export const deleteInvoiceItemActionParams: DeleteOneParams = {
    confirmConfig: {
        title: 'Are you sure you want to delete this item?',
    },
}
