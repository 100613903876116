import { TextInput, EmailInput, TelInput, ColorInput } from 'components'
import { maxLengthValidation, requiredValidation, urlValidation } from 'core'
import { AddressFormSection } from 'resources/address'
import { SectionTitleSmall } from 'ui'

import shopFields from '../fields'

const nameValidation = [requiredValidation, maxLengthValidation]

const ShopForm = ({ disabled }: { disabled: boolean }) => {
    return (
        <>
            <SectionTitleSmall>Basic Details</SectionTitleSmall>

            <TextInput
                source={shopFields.name.source}
                label={shopFields.name.label}
                validate={nameValidation}
                disabled={disabled}
            />

            <TelInput
                label={shopFields.phoneNumber.label}
                disabled={disabled}
                validate={requiredValidation}
            />

            <ColorInput
                source={shopFields.avatar.avatarColorSource}
                required
                label={shopFields.avatar.avatarColorLabel}
            />

            <TextInput
                source={shopFields.contactPerson.source}
                label="Contact Person (Name)"
                validate={maxLengthValidation}
                disabled={disabled}
            />
            <EmailInput
                source={shopFields.emailAddress.source}
                label={shopFields.emailAddress.label}
                disabled={disabled}
            />

            <TextInput
                source={shopFields.website.source}
                label={shopFields.website.label}
                disabled={disabled}
                validate={urlValidation}
            />

            <AddressFormSection disabled={disabled} />
        </>
    )
}

export default ShopForm
