import Icons from 'assets/icons'
import { useTheme } from 'lib'

import { calculateWidgetValue, WidgetPieChartBase } from '../../components'
import { type ComplianceRateResponse } from '../types'

interface ComplianceRateChartProps {
    disabled: boolean
    data: ComplianceRateResponse
}
const ComplianceRateChart = ({ data, disabled }: ComplianceRateChartProps) => {
    const { palette } = useTheme()
    const total = disabled ? 1 : data.notOnTimeCount + data.onTimeCount
    return (
        <WidgetPieChartBase
            disabled={disabled}
            icon={Icons.Alarm}
            renderContent={({ id, value, total }) => [
                value + ' ' + id + ' ' + calculateWidgetValue(total, value),
            ]}
            sortByValue={false}
            data={
                disabled
                    ? []
                    : [
                          {
                              id: 'On-time (Planned + Due Soon)',
                              value: data.onTimeCount,
                              color: palette.charts.greenBody,
                              total,
                          },
                          {
                              id: 'Not On-time (Overdue)',
                              value: data.notOnTimeCount,
                              color: palette.charts.red,
                              total,
                          },
                      ]
            }
        />
    )
}
export default ComplianceRateChart
