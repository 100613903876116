import { createResource } from 'core'

import { type CK14Aspects } from './types'

export const ck14Aspects = {
    totals: 'totals',
} satisfies { [key: string]: CK14Aspects }

export const ck14Resource = createResource({
    // TODO: why name is different than the permission name?
    name: 'reason-for-repair',
    resource: 'vmrs/ck14',
})
