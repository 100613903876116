import { Link, type LinkProps } from '@mui/material'
import { Link as RouterLink, type To, type LinkProps as RRDLinkProps } from 'react-router-dom'

interface LinkButtonProps extends LinkProps, Pick<RRDLinkProps, 'state'> {
    disabled?: boolean
    to?: To
}

const LinkButton = (props: LinkButtonProps) => {
    const { disabled, to, ...rest } = props
    let forwardedProps: LinkProps = rest

    if (disabled) {
        const { href, download, onClick, ...restForwarded } = rest

        forwardedProps = restForwarded
    }

    if (to) {
        return (
            <Link
                variant="inherit"
                color={disabled ? 'text.disabled' : 'primary.main'}
                {...forwardedProps}
                component={RouterLink}
                to={to}
            />
        )
    }

    return (
        <Link
            variant="inherit"
            component={forwardedProps.href ? undefined : 'span'}
            color={disabled ? 'text.disabled' : 'primary.main'}
            {...forwardedProps}
        />
    )
}

export default LinkButton
