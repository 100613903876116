import { type FC } from 'react'

import { useRecordContext } from 'react-admin'

import { ListBase } from 'components'
import { useArchivedContext } from 'context'
import { type SortPayload } from 'core'
import { unitAspects, type UnitModel, unitResource, UnitsListBase } from 'resources/units'
import { displayBooleanValue } from 'utils'

import Header from './Header'

export const CustomerUnitsList: FC = () => {
    const record = useRecordContext()
    const { isArchived } = useArchivedContext()

    return (
        <ListBase
            key={String(isArchived)}
            isLoading={!record}
            sort={defaultSort}
            resource={unitResource.resource}
            filter={{
                withAspects: [unitAspects.costPerMeter, unitAspects.totals],
                customer: record?.name,
                archived: displayBooleanValue(isArchived),
            }}
            preferencesName={getPreferencesName(isArchived)}
        >
            <Header isArchived={isArchived} />
            <UnitsListBase
                removeFilters
                disableExportButton
            />
        </ListBase>
    )
}

const defaultSort: SortPayload<UnitModel> = {
    field: 'created',
    order: 'DESC',
}
const getPreferencesName = (isArchived: boolean): string =>
    `customer-units${isArchived ? '-archived' : ''}`
