import Button from './Button'

const PrimaryButton: typeof Button = (props) => {
    return (
        <Button
            size="large"
            variant="contained"
            {...props}
        />
    )
}

export default PrimaryButton
