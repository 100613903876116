import { type FC } from 'react'

import { type CustomerModel } from '../types'

interface Props {
    record: Pick<CustomerModel, 'email'>
}

const Value: FC<Props> = ({ record }) => {
    return <>{record.email}</>
}

const source: keyof CustomerModel = 'email'

const Email = {
    source,
    label: 'Company Email',
    Value,
}

export default Email
