import { useFormInfo } from 'context'
import { type ResourceType } from 'core/resource'

type useCreateInputIdParam =
    | {
          source: string

          resource?: ResourceType

          id?: string
      }
    | string

export const useCreateInputId = () => {
    const { name } = useFormInfo()

    return (param: useCreateInputIdParam): string => {
        let formName = name
        let source = param as string

        if (typeof param === 'object') {
            if (param.id) {
                return param.id
            }
            formName = param.resource?.resource ?? formName
            source = param.source
        }

        if (formName) {
            return formName + '--' + source
        }

        return source
    }
}
