import { type ReactElement } from 'react'

import { type ListControllerResult, useShowContext } from 'react-admin'

import {
    costMaskParams,
    TextareaInput,
    TextInput,
    useOpenUtilityDrawer,
    UtilityDrawerEditor,
} from 'components'
import {
    type ResourceType,
    maxLengthValidationText,
    requiredValidation,
    type Serializer,
} from 'core'
import { type LineItemTypeKeys } from 'resources/lineItems'
import { type JobTaxModel, useWoActionIsDisabled } from 'resources/workOrders'
import { Stack } from 'ui'

interface DrawerProps {
    children: (params: { onClick: () => void }) => ReactElement
    title: string
    resource: ResourceType
    listContext: ListControllerResult
    itemType: Extract<LineItemTypeKeys, 'FEE' | 'TAX' | 'SERVICE'>
}
const descriptionValidation = [maxLengthValidationText, requiredValidation]

export const jobItemSerializer: Serializer<JobTaxModel> = [
    'description',
    { name: 'price', parse: 'number' },
    'type',
]

const VendorWorkOrderExtraActionsDrawerToggler = ({
    itemType,
    listContext,
    resource,
    children,
    title,
}: DrawerProps) => {
    const open = useOpenUtilityDrawer()
    const { record } = useShowContext()

    const disabled = useWoActionIsDisabled({ workOrder: record })
    return children({
        onClick: disabled
            ? () => {
                  /* * */
              }
            : () =>
                  open({
                      extraArgs: {
                          resource,
                          listContext,
                      },
                      drawerArgs: {
                          title,
                          renderWrapper: (args) => (
                              <UtilityDrawerEditor
                                  serializer={jobItemSerializer}
                                  defaultValues={{
                                      type: itemType as any,
                                  }}
                                  {...args}
                              />
                          ),
                          renderContent: () => (
                              <Stack spacing="10px">
                                  <TextareaInput
                                      source="description"
                                      label="Description"
                                      validate={descriptionValidation}
                                  />
                                  <TextInput
                                      source="price"
                                      label="Amount"
                                      {...costMaskParams}
                                      validate={requiredValidation}
                                  />
                              </Stack>
                          ),
                          renderBottomRight: (render) => render({ label: title }),
                      },
                  }),
    })
}

export default VendorWorkOrderExtraActionsDrawerToggler
