import { type ReactElement } from 'react'

import { type MeterModel } from 'resources/units'
import { woFields } from 'resources/workOrders'

import { translateMeterSource } from '../constants'

const MeterSource = ({ meter }: { meter: MeterModel }): ReactElement => {
    if (meter.source === 'MANUAL') {
        return <>{translateMeterSource(meter.source)}</>
    } else if (meter.source === 'WORK_ORDER') {
        return woFields.self.linkValue(meter.workOrder)
    }
    return <>Integration - {translateMeterSource(meter.source)}</>
}

export default MeterSource
