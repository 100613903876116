import { type FC } from 'react'

import Icons from 'assets/icons'
import { styled } from 'lib'
import { SvgIcon, Spacer, Typography } from 'ui'

import { type DVIRStatusDanger, type DvirStatusType } from '../types'

interface Props {
    status: DvirStatusType
}

export const DVIRStatus: FC<Props> = ({ status }) => {
    if (!status) {
        return null
    }

    const danger = getDvirStatusDanger(status)
    const { icon, color } = getDvirDangerConfig(danger)

    return (
        <Spacer
            overflow="hidden"
            textOverflow="ellipsis"
            component="span"
        >
            <SvgIcon
                component={icon}
                sx={{ width: '16px', height: '16px', color }}
            />
            <Typography
                color={color}
                component={SpanEllipsis}
                variant="body2"
            >
                {formatDvirStatus(status)}
            </Typography>
        </Spacer>
    )
}

const SpanEllipsis = styled('span')`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

type StatusMap = {
    [key in DvirStatusType]: string
}
const dvirStatusMap: StatusMap = {
    SAFE: 'Safe',
    UNSAFE: 'Unsafe',
    RESOLVED: 'Resolved',
    SATISFACTORY: 'Without Defects',
    CORRECTED: 'Corrected',
    HARMLESS: 'Need Not to Be Corrected',
    SATISFACTORY_WITH_DEFECTS: 'With Defects',
    WITH_DEFECTS: 'With Defects',
    OPEN: 'Open',
    ACCEPTABLE: 'Acceptable',
}

const safeStatuses: DvirStatusType[] = [
    'SAFE',
    'RESOLVED',
    'HARMLESS',
    'CORRECTED',
    'SATISFACTORY',
    'ACCEPTABLE',
]

const formatDvirStatus = (status: DvirStatusType) => {
    return dvirStatusMap[status] || 'Unknown'
}

const statusTypesMap: {
    [key in DVIRStatusDanger]: {
        color: string
        icon: typeof SvgIcon
    }
} = {
    unknown: {
        color: 'text.disabled',
        icon: Icons.HelpOutlineOutlined,
    },
    harmful: {
        color: 'error.main',
        icon: Icons.ErrorOutline,
    },
    harmless: {
        color: 'success.main',
        icon: Icons.CheckCircleOutline,
    },
}

const getDvirDangerConfig = (status: DVIRStatusDanger) => {
    return statusTypesMap[status]
}

const getDvirStatusDanger = (status: DvirStatusType): DVIRStatusDanger => {
    if (dvirStatusMap[status]) {
        const isHarmless = safeStatuses.includes(status)
        return isHarmless ? 'harmless' : 'harmful'
    }

    return 'unknown'
}
