import images from 'assets/images'
import { TextInput } from 'components'
import { requiredValidation } from 'core'
import { Anchor } from 'ui'

import ConnectTopSection from './ConnectTopSection'

const EroadConnect = () => {
    return (
        <>
            <ConnectTopSection
                logo={images.eroad}
                title="EROAD Authentication"
                alertText={
                    <>
                        To connect, you'll need your EROAD API key. Instructions for generating one
                        are available in the{' '}
                        <Anchor
                            href="https://help.eroad.com/us/us-apideveloperresources/us-eroadapi/us-apikey/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            help article
                        </Anchor>
                        .
                    </>
                }
            />

            <TextInput
                label="API Key"
                source="apiKey"
                validate={requiredValidation}
            />
        </>
    )
}

export default EroadConnect
