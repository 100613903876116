import { useCallback } from 'react'

import {
    ReferenceArrayInput,
    AutocompleteArrayInput,
    useResourceContext,
    type AutocompleteArrayInputProps,
    type AutocompleteInputProps,
    useListContext,
} from 'react-admin'

import { type DataRecord } from 'appTypes'
import { SelectInput } from 'components/inputs'
import { type DataProviderConfigType, emptyOptionValue, useDataProviderConfig } from 'core/data'
import { getFilterReference } from 'core/resource'
import { Paper, Chip, formHelperTextClasses } from 'ui'

import { filterOperator } from './utils'

export interface ListFilterValueInputProps {
    source: string
    label: string
    // TODO copy/inherit from ListFilterSourceInputProps
    inputKey: string
    makeItemLabel?: (item: DataRecord) => any
    dataProviderProps?: DataProviderConfigType
    inputText?: AutocompleteArrayInputProps['inputText']
    matchSuggestion?: AutocompleteInputProps['matchSuggestion']
    withOperator?: boolean
}

const PaperComponent = (props) => (
    <Paper
        {...props}
        elevation={8}
    />
)

export const prepareFilterResource = (resource: string) =>
    resource.split('/').slice(0, -1).join('/') + '/filter_values'

export const dataProviderConfigProps: DataProviderConfigType = {
    getList: {
        prepareResource: prepareFilterResource,
        prepareDataAfterResponse: (data) => data.map((v) => ({ id: v })),
    },
    getMany: {
        makeResponse: (resource, params) => params.ids.map((id) => ({ id })),
    },
    getOne: {
        shouldStopRequest: true,
    },
}

const defaultMatchSuggestion = () => true

const ListFilterValueInput = ({
    source,
    label,
    inputKey,
    makeItemLabel,
    dataProviderProps = dataProviderConfigProps,
    inputText,
    matchSuggestion = defaultMatchSuggestion,
    withOperator,
}: ListFilterValueInputProps) => {
    const resource = useResourceContext()
    const reference = getFilterReference(resource, source)
    useDataProviderConfig(reference, dataProviderProps)

    const { filter } = useListContext()

    const optionLabel = useCallback(
        (option: DataRecord) => {
            if (option.id === emptyOptionValue) {
                return 'No Value'
            }

            return makeItemLabel ? makeItemLabel(option) : option.id
        },
        [makeItemLabel],
    )

    let autocompleteInput = (
        <AutocompleteArrayInput
            debounce={0}
            key={inputKey + '_value'}
            source={inputKey + '_value'}
            label="Value"
            variant="outlined"
            sx={{
                [`& .${formHelperTextClasses.root}`]: {
                    display: 'none',
                },
            }}
            size="medium"
            PaperComponent={PaperComponent}
            filterToQuery={(filterText) => {
                return {
                    name: source,
                    text: filterText,
                }
            }}
            matchSuggestion={matchSuggestion}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        sx={{
                            backgroundColor: (theme) => theme.palette.grey[50],
                        }}
                        // TODO: Remove after RA - any
                        label={optionLabel(option as any)}
                        // COPY FROM RA, not needed in our case, I think. Leaving it in case something goes wrong
                        // sx={{
                        //    '.MuiSvgIcon-root': {
                        //        // FIXME: Workaround to allow choices deletion
                        //        // Maybe related to storybook and mui using different versions of emotion
                        //        zIndex: 100,
                        //    },
                        // }}
                        {...getTagProps({ index })}
                    />
                ))
            }
            optionText={optionLabel}
            inputText={inputText}
        />
    )
    if (source) {
        autocompleteInput = (
            <ReferenceArrayInput
                key={inputKey + '_reference'}
                source={source}
                reference={reference}
                filter={{ ...filter, name: source }}
            >
                {autocompleteInput}
            </ReferenceArrayInput>
        )
    }

    return (
        <>
            {withOperator ? (
                <SelectInput
                    choices={filterOperator.choices}
                    label="Operator"
                    defaultValue={filterOperator.values.any}
                    disableEmptyValue
                    clearable={false}
                    source={filterOperator.getSource(source)}
                    sx={{
                        mb: '16px !important',
                        [`& .${formHelperTextClasses.root}`]: {
                            display: 'none',
                        },
                    }}
                />
            ) : null}
            {autocompleteInput}
        </>
    )
}

export default ListFilterValueInput
