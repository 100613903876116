import { type FC } from 'react'

import { ColorInput, TextInput } from 'components'
import { validateMaxLength, requiredValidation } from 'core'

import { tagFields } from '../fields'

const nameValidation = [requiredValidation, validateMaxLength(30)]

export const TagForm: FC = () => {
    return (
        <>
            <TextInput
                source={tagFields.name.source}
                validate={nameValidation}
                label={tagFields.name.label}
            />

            <ColorInput
                required
                source={tagFields.color.source}
                label={tagFields.color.label}
            />
        </>
    )
}
