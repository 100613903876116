import { Stack, BoxContainer, Skeleton } from 'ui'

import MonitorBase from './MonitorBase'

const MonitorSkeleton = () => {
    return (
        <MonitorBase>
            <Stack
                spacing="10px"
                height="100%"
            >
                <Skeleton
                    height="8px"
                    width="121px"
                />
                <BoxContainer
                    width="100%"
                    height="100%"
                    gap="12px"
                >
                    <Skeleton />
                    <Skeleton />
                </BoxContainer>
            </Stack>
        </MonitorBase>
    )
}
export default MonitorSkeleton
