import { type PropsWithChildren } from 'react'

import { Link, type To, styled } from 'lib'
import { Button, Stack } from 'ui'

type MonitorButtonProps = {
    navigateTo: To
}
export const MonitorButton = ({ children, navigateTo }: MonitorButtonProps & PropsWithChildren) => {
    return (
        <Button
            color="inherit"
            sx={(theme) => ({
                textTransform: 'inherit',
                justifyContent: 'flex-start',
                padding: '4px',
            })}
            variant="text"
            component={StyledMonitorLink}
            to={navigateTo || ''}
        >
            <Stack
                sx={{ alignItems: 'flex-start' }}
                spacing="8px"
            >
                {children}
            </Stack>
        </Button>
    )
}
const StyledMonitorLink = styled(Link)`
    height: 61px;
    flex: 1;
    &:hover {
        color: ${({ theme }) => theme.palette.primary.main};
    }
`
export default MonitorButton
