import { forwardRef } from 'react'

import { styled } from 'lib'

import WidgetBase, {
    type WidgetBaseProps,
    WidgetSkeleton,
    type WidgetSkeletonProps,
} from '../WidgetBase'

interface SliderWidgetProps extends Omit<WidgetBaseProps, 'infoProps'> {}
export const SliderWidget = styled(
    forwardRef<HTMLDivElement, SliderWidgetProps>((props, ref) => {
        return (
            <WidgetBase
                {...props}
                ref={ref}
            />
        )
    }),
)`
    & .WidgetHeader {
        height: 140px;
        & .WidgetBaseTitle {
            padding-bottom: '22px';
        }
    }
`
interface SliderWidgetSkeletonProps extends Omit<WidgetSkeletonProps, 'disableInfo'> {}
export const SliderWidgetSkeleton = styled((props: SliderWidgetSkeletonProps) => {
    return (
        <WidgetSkeleton
            disableInfo
            {...props}
        />
    )
})`
    & .WidgetHeaderSkeleton {
        height: 140px;
    }
`
