import { styled } from 'lib'

/**
 * Left and right of the main content in main block
 */
const HeaderAside = styled('div')`
    display: flex;
    align-items: center;
    gap: 8px;
`

export default HeaderAside
