import { useFormContext, useWatch } from 'react-hook-form'

import {
    FormPreventSubmit,
    SelectInput,
    TextInput,
    TextareaInput,
    costMaskParams,
    inputQuantityMaskParams,
} from 'components'
import { maxLengthValidationText, requiredValidation, validateNonNegative } from 'core'
import { reasonForQTYAdjustmentChoices } from 'resources/reasonForQtyAdjustment'
import { ShopInput } from 'resources/shops'
import { Divider, SectionTitleSmall } from 'ui'

const actualQtyValidation = [validateNonNegative('Actual QTY cannot be negative.')]

const IsFormSubmittable = () => {
    const actualQty = useWatch({ name: 'actualQty' })
    const acquisitionCost = useWatch({ name: 'acquisitionCost' })
    const isFormSubmittable = actualQty || acquisitionCost
    if (isFormSubmittable) {
        return null
    }
    return <FormPreventSubmit />
}

const ReasonForQtyAdjustmentInput = () => {
    const actualQty = useWatch({ name: 'actualQty' })
    return (
        <SelectInput
            source="quantityAdjustmentReason"
            disabled={!actualQty}
            label="Reason for QTY Adjustment"
            validate={actualQty ? requiredValidation : null}
            choices={reasonForQTYAdjustmentChoices}
        />
    )
}

const QuantityChangeInput = () => {
    const { setValue } = useFormContext()
    const expectedQty = useWatch({ name: 'expectedQty' })
    return (
        <TextInput
            source="quantityAdjustment"
            label="Adjust On Hand QTY by"
            onChange={({ target }) => {
                setValue('actualQty', target.value && Number(expectedQty) + Number(target.value), {
                    shouldDirty: true,
                    shouldTouch: true,
                    shouldValidate: true,
                })
            }}
            {...inputQuantityMaskParams}
        />
    )
}

const ActualQtyInput = () => {
    const { setValue } = useFormContext()
    const expectedQty = useWatch({ name: 'expectedQty' })

    return (
        <TextInput
            source="actualQty"
            label="Actual On Hand QTY"
            validate={actualQtyValidation}
            onChange={({ target }) => {
                setValue(
                    'quantityAdjustment',
                    target.value && Number(target.value) - Number(expectedQty),
                )
            }}
            {...inputQuantityMaskParams}
        />
    )
}

const AdjustItemForm = () => {
    return (
        <>
            <IsFormSubmittable />
            <ShopInput
                contextType="inventory"
                disabled
            />

            <SectionTitleSmall>Adjust On Hand QTY</SectionTitleSmall>

            <TextInput
                source="expectedQty"
                label="Expected On Hand QTY"
                disabled
            />

            <QuantityChangeInput />

            <ActualQtyInput />

            <ReasonForQtyAdjustmentInput />

            <SectionTitleSmall>Adjust Cost</SectionTitleSmall>

            <TextInput
                source="acquisitionCost"
                label="Average Cost"
                {...costMaskParams}
            />

            <Divider sx={{ mb: '32px' }} />

            <TextareaInput
                source="comment"
                label="Comment"
                validate={maxLengthValidationText}
            />
        </>
    )
}

export default AdjustItemForm
