import { styled } from 'lib'
import { Card, type CardProps } from 'ui'

import infoCardStyles from './styles'

export interface InfoCardProps extends CardProps {}

const InfoCard = styled(Card)`
    ${infoCardStyles.headerDividerOffsetBottom}: 10px;
    ${infoCardStyles.p}: 16px;

    width: 100%;
    box-sizing: border-box;
    padding: var(${infoCardStyles.p});
`

export default InfoCard
