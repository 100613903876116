import { styled } from 'lib'

export const ItemSection = styled('div')`
    border-spacing: 0px;
    width: 100%;
    display: block;
    & .colName {
        width: 100%;
        & > div {
            width: 0px;
            min-width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    & .colQtyXCost {
        text-align: end;
        padding-right: 15px;
        padding-left: 1ex;
        white-space: nowrap;
    }
    & .total {
        & .rowData {
            padding: 0px;
        }
    }
    & .tableRowSubtitle {
        margin-bottom: 10px;
    }
`
export const ItemSubtitle = styled('div')`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    margin-left: 25px;
    margin-right: 7px;
    max-width: 100%;
`
export const AddButtonWrapper = styled('div')`
    display: flex;
    align-items: center;
    flex: 1;
`
export const ItemRow = styled('div')`
    & .rowData {
        padding-top: 10px;
        display: inline-block;
    }
    &:first-of-type .rowData {
        padding-top: 0px;
    }
`
