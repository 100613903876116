import { type FC } from 'react'

import images from 'assets/images'
import { NoResultsCard } from 'components'
import { Typography, NonDisplayedInput } from 'ui'

interface Props {
    upload?: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>
}

const NoPhotosCard: FC<Props> = ({ upload }) => {
    return (
        <NoResultsCard
            title="No Photos Added"
            direction="row"
            height="180px"
            imageSrc={images.noPhotos}
            imageProps={{
                alt: 'No Notes',
                width: '106px',
                mt: '20px',
                mr: '28px',
            }}
            action={
                upload ? (
                    <label>
                        <NonDisplayedInput
                            type="file"
                            onChange={upload}
                            accept="image/*"
                        />
                        <Typography
                            variant="body1"
                            color="primary"
                            sx={{ cursor: 'pointer' }}
                        >
                            Add Photo
                        </Typography>
                    </label>
                ) : null
            }
        />
    )
}

export default NoPhotosCard
