export const deleteCustomerAction = {
    confirmConfig: {
        title: 'Are you sure you want to delete the selected Customer?',
    },
}
export const customerContactsResourceName = 'customer-contacts'
export const deleteCustomerBulkAction = {
    confirmConfig: {
        title: 'Are you sure you want to delete the selected Customers?',
    },
}
