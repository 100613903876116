import { type FC } from 'react'

import Header from 'components/pages/header'
import { useMediaQuery } from 'lib'
import { Skeleton, createSkeletonArray, BoxContainer } from 'ui'

const actionsSkeletons = createSkeletonArray(3, { width: '24px', height: '24px' })

export const ShowHeaderSkeleton: FC = () => {
    const match = useMediaQuery((theme) => theme.breakpoints.down('sm'))

    return (
        <Header fixed>
            <Header.Block>
                <Skeleton
                    width="28px"
                    height="28px"
                />
                <Header.Main>
                    <Skeleton
                        height="8px"
                        width={match ? '110px' : '250px'}
                        sx={{
                            mb: '12px',
                        }}
                    />
                    <Skeleton
                        height="8px"
                        width={match ? '55px' : '120px'}
                    />
                </Header.Main>
                <Header.Aside>
                    <BoxContainer gap="12px">
                        {match ? actionsSkeletons[0] : actionsSkeletons}
                    </BoxContainer>
                </Header.Aside>
            </Header.Block>
            <Header.Tabs
                tabs={[{ label: 'fake', value: 'fake' }]}
                loading
            />
        </Header>
    )
}
