import { type FC } from 'react'

import { useRecordContext } from 'react-admin'

import { formatMoney, InfoCard, InfoCardDetails, InfoCardHeader } from 'components'
import { type PartInInventoryModel } from 'resources/inventory'

export const CostCard: FC = () => {
    const record = useRecordContext<PartInInventoryModel>()
    const inventoryItem = record.inventoryItem

    return (
        <InfoCard>
            <InfoCardHeader title="Cost" />
            <InfoCardDetails
                details={[
                    {
                        label: 'Average Cost',
                        source: () => formatMoney(inventoryItem.acquisitionCost),
                    },
                    {
                        label: 'Inventory Total',
                        source: () => formatMoney(inventoryItem.totalCost),
                    },
                ]}
            />
        </InfoCard>
    )
}
