import { type FC } from 'react'

import { dvirFields } from 'resources/dvir'
import { reportsUrls, createReportResource } from 'resources/reports'
import { unitFields } from 'resources/units'
import { BoxContainer, StatusText, Typography } from 'ui'
import { booleanValue, type BooleanValue } from 'utils'

import { Monitor } from '../MonitorComponents'

import { useMonitor } from './useMonitor'

interface dataType {
    unresolvedCount: number
    unresolvedUnitsCount: number
    inProgressCount: number
    inProgressUnitsCount: number
}
const MonitorDVIR: FC = () => {
    const data = useMonitor<dataType>(createReportResource('defects'))
    const createLink = (status: BooleanValue) => {
        return (
            reportsUrls.defects +
            '?filter=' +
            encodeURIComponent(
                JSON.stringify({
                    inProgress: [status],
                }),
            )
        )
    }
    if (!data) {
        return <Monitor.Skeleton />
    }

    const { unresolvedCount, unresolvedUnitsCount, inProgressCount, inProgressUnitsCount } = data
    return (
        <Monitor>
            <Monitor.Title icon={dvirFields.avatar.Icon}>Unresolved Defects</Monitor.Title>

            <Monitor.ArrowButton navigateTo={reportsUrls.defects} />
            <Monitor.Container>
                <Monitor.Button navigateTo={reportsUrls.defects}>
                    <BoxContainer gap="4px">
                        <Monitor.ValueText>{unresolvedCount || 0}</Monitor.ValueText>
                        <Monitor.Counter image={unitFields.avatar.Icon}>
                            {unresolvedUnitsCount}
                        </Monitor.Counter>
                    </BoxContainer>
                    <StatusText statusColor={(theme) => theme.palette.charts.red}>
                        Unresolved
                    </StatusText>
                </Monitor.Button>
                <Monitor.Button navigateTo={createLink(booleanValue.positive)}>
                    <BoxContainer gap="4px">
                        <Monitor.ValueText display="flex">
                            {inProgressCount || 0}
                            <Typography
                                component="span"
                                variant="h5"
                                color="text.disabled"
                            >
                                /{unresolvedCount || 0}
                            </Typography>
                        </Monitor.ValueText>
                        <Monitor.Counter image={unitFields.avatar.Icon}>
                            {inProgressUnitsCount}
                        </Monitor.Counter>
                    </BoxContainer>
                    <StatusText statusColor={(theme) => theme.palette.primary.main}>
                        In Progress
                    </StatusText>
                </Monitor.Button>
            </Monitor.Container>
        </Monitor>
    )
}

export default MonitorDVIR
