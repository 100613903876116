import { type FC } from 'react'

import { Link } from 'react-router-dom'

import { styled, withColor } from 'lib'
import { IconElement, Status as StatusBase, Typography } from 'ui'

import { useSidebar } from '../SidebarContext'
import { DrawerNav } from '../common'
import { type NavItemConfig } from '../types'

import Item from './Item'
import { EllipseHover } from './ItemHover'
import { type WithControls } from './types'

interface NavItemProps extends WithControls {
    item: NavItemConfig
}

const NavItem: FC<NavItemProps> = ({ item, open, close }) => {
    const { Icon: IconProp, text, ActiveIcon, items, path } = item
    const [snap] = useSidebar()
    const active = snap.activeElements[item.key]
    const Icon = active && ActiveIcon ? ActiveIcon : IconProp

    const content = (
        <Item
            active={active}
            onMouseEnter={() => {
                if (!items) {
                    close()
                    return
                }
                open(
                    () => (
                        <DrawerNav
                            p="12px 0"
                            items={items}
                        />
                    ),
                    item.key,
                )
            }}
            padding="2px 0 10px"
        >
            <EllipseHover>
                <IconElement
                    component={Icon}
                    size="24px"
                    color={(theme) => theme.palette.text.primary}
                />
                {item.status?.((color) => <Status color={color} />)}
            </EllipseHover>
            <Typography
                mt="8px"
                variant="inputLabel"
                color={withColor('text.primary')}
            >
                {text}
            </Typography>
        </Item>
    )

    if (path) {
        return <Link to={path}>{content}</Link>
    }

    return content
}

const Status = styled(StatusBase)`
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
`

export default NavItem
