import { createModifications, ModificationProvider } from 'context'
import { type AssignmentsDrawerModifications } from 'resources/assignments'
import { customerFields } from 'resources/customers'
import { PageContent } from 'ui'

import { CustomerAssignmentsList } from './components'

const CustomerAssignments = () => {
    return (
        <PageContent>
            <ModificationProvider value={modifications}>
                <CustomerAssignmentsList />
            </ModificationProvider>
        </PageContent>
    )
}

export default CustomerAssignments

const modifications = createModifications<AssignmentsDrawerModifications>({
    assignmentForm: {
        isInputDisabled: (source) => source === customerFields.self.source,
    },
})
