import { useRecordContext } from 'react-admin'

import { SliderView } from 'components'
import { type WorkOrderModel } from 'resources/workOrders'
import { PageContent } from 'ui'

import WorkOrderFinancialInfo from './WorkOrderFinancialInfo'

const WorkOrderTopSection = () => {
    const record = useRecordContext<WorkOrderModel>()
    const slides = [<WorkOrderFinancialInfo />]
    if (record && (!record.jobsCount || !record.jobItemsCount)) {
        return null
    }
    return (
        <PageContent
            bgcolor={(theme) => theme.palette.darkGray}
            flexGrow="initial !important"
        >
            <SliderView items={slides} />
        </PageContent>
    )
}
export default WorkOrderTopSection
