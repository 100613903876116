import { formHelperTextHeight } from 'lib'

import Tooltip, { tooltipClasses, type TooltipProps } from './Tooltip'

const UnderInputTooltip = (props: Omit<TooltipProps, 'PopperProps' | 'placement'>) => {
    return (
        <Tooltip
            {...props}
            PopperProps={{
                sx: {
                    [`& .${tooltipClasses.tooltip}`]: {
                        mt: `calc(-1 * ${formHelperTextHeight}) !important`,
                    },
                },
            }}
        />
    )
}

export default UnderInputTooltip
