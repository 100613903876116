import { type FC, type ReactNode } from 'react'

import { clsx, globalClassNames, styled } from 'lib'
import { Typography } from 'ui'

import headerClasses from './config'

type Placement = 'left' | 'right' | 'bottom'
interface Props {
    mobilePlacement?: Placement
    children: ReactNode
    className?: string
}

/**
 * Handles the placement of the main content
 */
const HeaderContent: FC<Props> = styled(
    ({ mobilePlacement = 'left', children, className }: Props) => {
        return (
            <Typography
                component="div"
                variant="body2"
                className={clsx(className, classNames[mobilePlacement], globalClassNames.ellipsis)}
            >
                {children}
            </Typography>
        )
    },
)`
    color: ${({ theme }) => theme.palette.text.primary};
`

export default HeaderContent

const classNames: { [key in Placement]: string } = {
    bottom: headerClasses.contentBottom,
    left: headerClasses.contentLeft,
    right: headerClasses.contentRight,
}
