import { formatMoney, type Column, type DataListDetails, type SortBy } from 'components'

import { type InventoryPricingModel } from '../types'

const source = 'price' as const satisfies keyof InventoryPricingModel

const label = 'Selling Price'

const value = (record: InventoryPricingModel) => formatMoney(record.price)

const tableColumn = () =>
    ({
        field: source,
        headerName: label,
        renderCell: ({ row }) => value(row),
        align: 'right',
    }) as const satisfies Column

const dataCardRow = () =>
    ({
        source,
        label,
        render: (_, data) => value(data),
    }) as const satisfies DataListDetails<any, any>

const sort = () =>
    ({
        id: source,
        label,
    }) as const satisfies SortBy

const price = {
    source,
    label,
    value,
    dataCardRow,
    tableColumn,
    sort,
}

export default price
