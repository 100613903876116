import { useDataProviderConfig, ResourceWithClearEffect } from 'core'
import { InventoryContextProvider, inventoryItemsResource } from 'resources/inventory'
import { partsResource } from 'resources/parts'

import List from './List'
import { InventoryShow } from './Show'

const dpConfig = {
    queryParams: () => ({
        withAspects: ['inventory'],
    }),
}

const Inventory = () => {
    useDataProviderConfig(partsResource.resource, {
        getOne: dpConfig,
        update: dpConfig,
    })

    return (
        <ResourceWithClearEffect
            name={inventoryItemsResource}
            list={<List />}
            edit={
                <InventoryContextProvider>
                    <InventoryShow />
                </InventoryContextProvider>
            }
        />
    )
}

export default Inventory
