import {
    type JobItemsCalculations,
    unitClarificationMap,
    type JobItemTotalsPercentages,
    type BaseJobItemTotals,
} from '../components/types'

type funcParams = Partial<
    Omit<JobItemsCalculations, keyof JobItemTotalsPercentages | keyof BaseJobItemTotals>
> &
    BaseJobItemTotals &
    Partial<Pick<JobItemsCalculations, keyof JobItemTotalsPercentages>>
const initializePercentages = (data: funcParams): funcParams => {
    const isUncompleted = unitClarificationMap.find(
        (obj) => !Object.prototype.hasOwnProperty.call(data, obj.totalPercent),
    )

    if (!isUncompleted) {
        return data
    }

    return {
        totalLaborPercent: (data.totalLabor / data.total) * 100,
        totalPartsPercent: (data.totalParts / data.total) * 100,
        totalTaxPercent: (data.totalTax / data.total) * 100,
        totalFeesPercent: (data.totalFees / data.total) * 100,
        totalServicesPercent: (data.totalServices / data.total) * 100,
        ...data,
    }
}

const roundWidgetJobsData = (data: funcParams): JobItemsCalculations => {
    // Algorithm implemented
    // -> https://revs.runtime-revolution.com/getting-100-with-rounded-percentages-273ffa70252b
    const completeData = initializePercentages(data)
    const {
        totalLabor,
        totalFees,
        totalServices = null,
        totalTax = null,
        totalParts = null,
    } = completeData
    const allTotals = {
        totalLabor,
        totalFees,
        totalServices,
        totalTax,
        totalParts,
    }
    const finalData = { ...completeData }
    const allPercents: { id: string; value: number }[] = []
    const allTotalsKeys = Object.keys(allTotals)
    const positiveTotal: number = allTotalsKeys.reduce(
        (sum, num) => (allTotals[num] > 0 ? sum + allTotals[num] : sum + 0),
        0,
    )

    unitClarificationMap.forEach((item, i) => {
        if (finalData[item.totalPercent] >= 0) {
            finalData[item.totalPercent] = (allTotals[item.total] / positiveTotal) * 100
        }
        allPercents[i] = {
            id: item.totalPercent,
            value: finalData[item.totalPercent],
        }
    })

    let currPercentSum = 0
    const roundedPercents = allPercents
        .map((obj) => {
            const value = Math.floor(obj.value)
            if (value >= 0) {
                currPercentSum += value
            }
            return { ...obj, value, decimals: obj.value % 1 }
        })
        .sort((a, b) => b.decimals - a.decimals)

    let percentLeft = 100 - currPercentSum
    roundedPercents.forEach((obj) => {
        if (percentLeft === 0) {
            finalData[obj.id] = obj.value
            return
        }
        finalData[obj.id] = obj.value + 1
        percentLeft -= 1
    })
    return { ...finalData, positiveTotal } as JobItemsCalculations
}
export default roundWidgetJobsData
