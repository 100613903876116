import { useRef, useEffect, useState, type CSSProperties } from 'react'

import {
    BarcodeFormat,
    BrowserMultiFormatReader,
    DecodeHintType,
    type Result,
} from '@zxing/library'

import { useNotify } from 'core/notifications'
import { Box } from 'ui'

import MultiFormatScannerChangeCameraButton from './MultiFormatScannerChangeCameraButton'
import MultiFormatScannerCloseButton from './MultiFormatScannerCloseButton'
import {
    MultiFormatScannerContextProvider,
    type MultiFormatScannerContextResult,
    useMultiFormatScannerModalContext,
} from './MultiFormatScannerContext'
import MultiFormatScannerFlashButton from './MultiFormatScannerFlashButton'
import MultiFormatScannerMenu from './MultiFormatScannerMenu'
import ScannerInfo from './ScannerInfo'

export interface MultiFormatScannerContentProps {
    onDecode: (result: string, isUpcFormat: boolean) => void
    upcOnly?: boolean
}

const MultiFormatScannerContent = ({ onDecode, upcOnly }: MultiFormatScannerContentProps) => {
    const { close } = useMultiFormatScannerModalContext()
    const [state, setState] = useState<MultiFormatScannerContextResult>(null)

    const notify = useNotify()
    const videoRef = useRef()

    useEffect(() => {
        let scanner: BrowserMultiFormatReader

        const initState = async () => {
            const hints = new Map()
            const formats = [
                ...(upcOnly ? [] : [BarcodeFormat.QR_CODE]),
                BarcodeFormat.UPC_A,
                BarcodeFormat.UPC_E,
                BarcodeFormat.EAN_13,
            ]

            hints.set(DecodeHintType.POSSIBLE_FORMATS, formats)

            scanner = new BrowserMultiFormatReader(hints)
            const cameras = await scanner.listVideoInputDevices()

            if (!cameras?.length) {
                notify({
                    title: 'Camera not found',
                    type: 'error',
                })
                close()
                return
            }

            const onScanSuccess = (result: Result) => {
                onDecode(result.getText(), result.getBarcodeFormat() !== BarcodeFormat.QR_CODE)
                close()
            }

            const start = async (track: MediaTrackConstraints) => {
                scanner.reset()
                const stream = await navigator.mediaDevices
                    .getUserMedia({ video: track })
                    .catch<null>(() => null)

                if (!stream) {
                    notify({
                        title: 'Camera not found. Check your Camera Permissions',
                        type: 'error',
                    })
                    close()
                    return
                }

                const videoTrack = stream.getVideoTracks()[0]
                const videoSettings = videoTrack.getSettings()

                setState({
                    cameras: await scanner.listVideoInputDevices(),
                    setActiveCamera: (cameraId) => start({ deviceId: cameraId }),
                    applyConstraints: async (constraints) => {
                        await videoTrack.applyConstraints(constraints)
                    },
                    activeCameraId: videoSettings.deviceId,
                    streamSettings: videoSettings,
                })

                const result = await scanner
                    .decodeOnceFromStream(stream, videoRef.current)
                    .catch(() => null)

                if (result) {
                    onScanSuccess(result)
                }
            }

            start({ facingMode: 'environment' })
        }

        initState()

        return () => {
            if (scanner) {
                scanner.reset()
            }
        }
    }, [])

    return (
        <>
            <Box
                height="100%"
                display="flex"
            >
                <video
                    ref={videoRef}
                    style={videoStyle}
                />
            </Box>
            {state && (
                <MultiFormatScannerContextProvider value={state}>
                    <MultiFormatScannerCloseButton />
                    <MultiFormatScannerFlashButton />
                    <MultiFormatScannerMenu>
                        <MultiFormatScannerChangeCameraButton />
                        <ScannerInfo />
                    </MultiFormatScannerMenu>
                </MultiFormatScannerContextProvider>
            )}
        </>
    )
}

export default MultiFormatScannerContent

const videoStyle: CSSProperties = { position: 'relative', zIndex: 0 }
