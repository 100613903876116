import { Box, PageContent } from 'ui'

import { DashboardHeader, DashboardSlider } from './components'
import Widgets from './components/Widgets'
import WidgetLayoutProvider from './components/WidgetsLayoutProvider'

const Dashboard = () => {
    return (
        <>
            <DashboardHeader />
            <Box>
                <PageContent bgcolor={(theme) => theme.palette.darkGray}>
                    <DashboardSlider />
                </PageContent>
                <PageContent>
                    <WidgetLayoutProvider>
                        <Widgets />
                    </WidgetLayoutProvider>
                </PageContent>
            </Box>
        </>
    )
}

export default Dashboard
