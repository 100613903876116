import { type NoInfer } from 'appTypes'
import { type ListFilterChoice, ListFilterValueInput, type Column } from 'components'
import { defaultStatuses, options, type StatusConfig } from 'resources/common'

import { InvoiceStatusKeys, type InvoiceModel } from '../types'

const source = 'status' as const satisfies keyof InvoiceModel
const label = 'Status'

const getConfig = options.createConfig<StatusConfig<InvoiceStatusKeys>>([
    { ...defaultStatuses.open, id: InvoiceStatusKeys.OPEN },
    { ...defaultStatuses.closed, id: InvoiceStatusKeys.CLOSED },
    { ...defaultStatuses.canceled, id: InvoiceStatusKeys.CANCELED },
])

const config = options.makeIconValue(getConfig)
const value = (data: Pick<InvoiceModel, 'status'>) => config(data?.status)

const tableColumn = <Source extends string = typeof source>() =>
    ({
        field: source as NoInfer<Source>,
        headerName: label,
        renderCell: (cell) => value(cell.row),
    }) as const satisfies Column

const filter = () =>
    ({
        id: source,
        label,
        renderComponent: (props) => (
            <ListFilterValueInput
                {...props}
                inputText={(option) => option.id}
                makeItemLabel={(record) => {
                    return config(record.id as InvoiceStatusKeys)
                }}
            />
        ),
    }) as const satisfies ListFilterChoice

const sort = <Source extends string = typeof source>() =>
    ({
        id: source as NoInfer<Source>,
        label,
    }) as const

const status = {
    source,
    label,
    value,
    getConfig,
    tableColumn,
    filter,
    sort,
}

export default status
