import { ListTotalBadge, Header, CreateButtonView, MainHeader } from 'components'
import { IssueDrawerToggler } from 'pages/Issues/components'

const ListHeader = () => {
    return (
        <MainHeader
            mainAction={
                <IssueDrawerToggler>
                    {(action) => <CreateButtonView onClick={action}>CREATE Issue</CreateButtonView>}
                </IssueDrawerToggler>
            }
        >
            <Header.Info>
                <Header.Content>
                    <Header.Title>Issues</Header.Title>
                </Header.Content>
                <Header.Content mobilePlacement="bottom">
                    <ListTotalBadge />
                </Header.Content>
            </Header.Info>
        </MainHeader>
    )
}

export default ListHeader
