import { createContext, useContext, type ReactNode } from 'react'

import { useJsApiLoader } from '@react-google-maps/api'

import { config } from 'configs'

const GoogleMapContext = createContext<ReturnType<typeof useJsApiLoader>>(null)

export const GoogleMapProvider = ({ children }: { children: ReactNode }) => {
    const data = useJsApiLoader({
        googleMapsApiKey: config.GOOGLE_MAP_API_KEY,
    })

    return <GoogleMapContext.Provider value={data}>{children}</GoogleMapContext.Provider>
}

export const useGoogleMap = () => useContext(GoogleMapContext)
