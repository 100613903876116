import { type Photos } from 'components'
import { type Serializer } from 'core'

export const MAX_PHOTO_COUNT = 10
export type PhotoType = { id: string; file?: string }
export const maxFileSize = {
    size: 31457280,
    errorMessage: 'The maximum allowed file size is 30 MB',
}

export const photoSource = 'photo'

const arr = Array.from({ length: MAX_PHOTO_COUNT }, (_, i) => i)

export const getPhotosFromRecordAsObject = (
    record: Photos,
    { reindex, skipEmpty }: { skipEmpty?: boolean; reindex?: boolean } = {},
) => {
    let currentIndex = 0
    return arr.reduce((photos, index) => {
        if (skipEmpty && !record['photo' + index]) {
            return photos
        }
        currentIndex = reindex ? currentIndex : index

        photos['photo' + currentIndex] = record['photo' + index]
        currentIndex += 1
        return photos
    }, {} as Photos)
}

export const MAX_ATTACHMENTS_COUNT = 10
export const attachmentsIndexes = Array.from({ length: MAX_ATTACHMENTS_COUNT }, (_, i) => i)

export const attachmentName = (index: number) => 'attachment' + index

export const attachmentsSerializer: Serializer = attachmentsIndexes.map((index) => ({
    name: attachmentName(index),
    parse: 'file',
}))

export const photoName = (index: number) => 'photo' + index

export const photoSerializer: Serializer = attachmentsIndexes.map((index) => ({
    name: photoName(index),
    parse: 'file',
}))
