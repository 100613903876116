import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import Icons from 'assets/icons'
import { type AuthStore } from 'core'
import { globalClassNames, withColor } from 'lib'
import { shopFields } from 'resources/shops'
import { BoxContainer, IconButton, Typography } from 'ui'

import { useSidebarFunctions } from '../SidebarContext'
import { SidebarShopContent } from '../common'

const HeaderShop: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const { open } = useSidebarFunctions()
        const shop = auth.shop

        return (
            <BoxContainer
                gap="4px"
                className={globalClassNames.ellipsis}
            >
                <div className={globalClassNames.ellipsis}>
                    <Typography
                        variant="subtitle2"
                        color={withColor('text.primary')}
                        className={globalClassNames.ellipsis}
                        maxWidth="150px"
                    >
                        {shop?.name || shopFields.self.allSelectedText}
                    </Typography>
                </div>
                <IconButton
                    onClick={() => open(() => <SidebarShopContent />)}
                    size="small"
                >
                    <Icons.ExpandMore />
                </IconButton>
            </BoxContainer>
        )
    }),
)

export default HeaderShop
