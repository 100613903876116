import { type FC } from 'react'

import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import {
    type ActionChildren,
    parseNumber,
    useConfirm,
    useCanAccess,
    useResourcePermissions,
} from 'core'
import { dateTimeParse } from 'lib'

import { isVendorWo } from '../../isVendorWo'
import { type WorkOrderModel, WoStatusKeys } from '../../types'
import { woPermissions, woResource } from '../../utils'
import { woOperations } from '../actionPermissions'

import CloseContent from './CloseContent'
import CloseDialogUnsuccessful from './CloseDialogUnsuccessful'
import { pmMeterSources } from './PmMeterInput'
import { WoActionKeys } from './types'
import useUpdateWoStatus from './useUpdateStatus'

export const useWoStatusAction = () => {
    const record = useRecordContext<WorkOrderModel>()
    const permissions = useResourcePermissions(woResource)

    return function (render: ActionChildren) {
        if (
            !record ||
            (record.status !== WoStatusKeys.OPEN && record.status !== WoStatusKeys.CLOSED)
        ) {
            return null
        }
        const isOpen = record.status === WoStatusKeys.OPEN
        const permission = permissions[isOpen ? woPermissions.close : woPermissions.reopen]

        if (!permission) {
            return null
        }

        return <StatusAction key="wo-status">{render}</StatusAction>
    }
}

const StatusAction: FC<{ children: ActionChildren }> = ({ children }) => {
    const record = useRecordContext<WorkOrderModel>()
    const updateStatus = useUpdateWoStatus()
    const confirm = useConfirm()
    const getCanAccess = useCanAccess()
    const canAccess = getCanAccess(woOperations.open)

    const isOpen = record.status === WoStatusKeys.OPEN

    return children({
        Icon: isOpen ? Icons.CheckCircleOutline : Icons.Undo,
        title: isOpen ? 'Close' : 'Reopen',
        disabled: !canAccess.value,
        titleOnDisabled: canAccess.message,
        onClick: async () => {
            const isVendorType = isVendorWo(record)
            if (isOpen) {
                const isVendorWOClosable =
                    Math.round(record.purchaseOrderData?.total) ===
                    Math.round(record.purchaseOrderData?.totalInvoices)

                if (!isVendorWOClosable && isVendorType) {
                    confirm({
                        title: `Vendor Work Order can't be closed.`,
                        content: <CloseDialogUnsuccessful data={record} />,
                        confirmButtonProps: null,
                        cancelButtonProps: {
                            children: 'Close',
                        },
                    })
                } else {
                    confirm({
                        title: 'Close Work Order',
                        closeOnError: false,
                        content: <CloseContent workOrder={record} />,
                        onConfirm: async ({ formValues }) => {
                            const {
                                completed,
                                pm,
                                dvir,
                                issue,
                                poNumber,
                                invoiceDate,
                                paymentTerm,
                            } = formValues

                            const body: Record<string, any> = {
                                pmsToReset: pm ? Object.keys(pm).filter((pmId) => pm[pmId]) : [],
                                defectsToReset: dvir
                                    ? Object.keys(dvir).filter((dvirId) => dvir[dvirId])
                                    : [],
                                completed: dateTimeParse(completed),
                                issuesToClose: issue
                                    ? Object.keys(issue).filter((issueId) => issue[issueId])
                                    : [],
                                invoiceOnClose: invoiceDate
                                    ? {
                                          poNumber,
                                          invoiceDate: dateTimeParse(invoiceDate),
                                          paymentTerm,
                                      }
                                    : null,
                            }

                            pmMeterSources.forEach((source) => {
                                if (formValues[source]) {
                                    body[source] = parseNumber(formValues[source])
                                }
                            })

                            pmMeterSources.forEach((source) => {
                                if (formValues[source]) {
                                    body[source] = parseNumber(formValues[source])
                                }
                            })

                            await updateStatus(record.id, WoActionKeys.CLOSE, body)
                        },
                        confirmButtonProps: {
                            children: 'Confirm',
                        },
                        awaitOnConfirm: true,
                        formProps: {
                            defaultValues: {
                                completed: new Date(),
                            },
                        },
                    })
                }
            } else if (!record.unitData.domicile || record.shop === record.unitData.domicile) {
                confirm({
                    title: 'Are you sure you want to reopen this Work Order?',
                    closeOnError: false,
                    content: (
                        <>
                            Reopening the WO will enable its editing features and will return any
                            corresponding Issues to their previous state.
                        </>
                    ),
                    onConfirm: async () => {
                        await updateStatus(record.id, WoActionKeys.REOPEN)
                    },
                    confirmButtonProps: {
                        children: 'Confirm',
                    },
                    awaitOnConfirm: true,
                })
            } else {
                confirm({
                    title: `Work Order cannot be reopened`,
                    content:
                        "The Unit's domicile has changed and it no longer matches the original Repair Shop location.",
                    confirmButtonProps: null,
                    cancelButtonProps: {
                        children: 'Cancel',
                    },
                })
            }
        },
    })
}
