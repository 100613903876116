import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import { type ListBulkActions } from 'components'
import {
    type Action,
    deleteOneAction,
    deleteManyFromListAction,
    multiselectAction,
} from 'core/actions'
import {
    type UnitModel,
    type MeterModel,
    isUnitArchived,
    deleteMeterActionParams,
} from 'resources/units'
import { IconBox } from 'ui'

import { UnitMetersDrawerListToggler } from './components/UnitMetersDrawerToggler'
import { translateMeterSource } from './constants'

export type MeterActions = Action<MeterModel>

export const unitMeterActions: MeterActions = (meter, { children }) => {
    return [
        <UnitMetersDrawerListToggler
            key="edit"
            id={meter.id}
            children={({ onClick, disabled }) =>
                children({
                    title: 'Edit',
                    Icon: Icons.Edit,
                    onClick,
                    disabled,
                    titleOnDisabled:
                        (meter.source === 'WORK_ORDER' &&
                            'This meter reading originates from a Work Order and can be updated through it') ||
                        (meter.isFromTelematics &&
                            'This meter reading originates from a Telematics system and can not be updated') ||
                        '',
                })
            }
            disabled={meter.source !== 'MANUAL'}
        />,
        deleteOneAction({
            disabled: meter.source === 'WORK_ORDER',
            children: (params) =>
                children({
                    ...params,
                    titleOnDisabled:
                        'This meter reading originates from a Work Order and can be updated through it',
                }),
            id: meter.id,
            ...deleteMeterActionParams,
        }),
    ]
}

export const disableMeterSelection = ({ source, isFromTelematics }: MeterModel) =>
    source !== 'MANUAL' && !isFromTelematics
        ? `This meter reading originates from a ${translateMeterSource(source)} and can be updated through it.`
        : null

export const unitMeterExtendedActions: MeterActions = (meter, args) => [
    ...unitMeterActions(meter, args),
    multiselectAction({
        disabled: Boolean(disableMeterSelection(meter)),
        children: (params) => args.children(params),
        id: meter.id,
    }),
]

export const metersActions: Action<MeterModel> = (params, { children }) => [
    <UnitMetersDrawerListToggler
        key="add"
        children={({ onClick }) => children({ Icon: Icons.Add, title: 'Add Meters', onClick })}
    />,
    children({
        Icon: Icons.GridView,
        title: 'See All',
        key: 'see-all',
        to: 'meters',
    }),
]

export const ActionAdd = () => {
    const record = useRecordContext<UnitModel>()

    if (isUnitArchived(record)) {
        return null
    }

    return (
        <UnitMetersDrawerListToggler>
            {({ onClick }) => (
                <IconBox
                    title="Add Meter"
                    onClick={onClick}
                >
                    <Icons.Add />
                </IconBox>
            )}
        </UnitMetersDrawerListToggler>
    )
}

export const unitMeterBulkActions: ListBulkActions = ({ children }) => [
    deleteManyFromListAction({
        children,
        confirmConfig: {
            title: 'Are you sure you want to delete the selected Meters?',
        },
    }),
]
