import { type MemberModel } from 'resourcesBase'

interface Data extends Pick<MemberModel, 'email'> {}

const value = (data: Data) => {
    return data.email
}

const source = 'email' as const satisfies keyof MemberModel

const email = {
    source,
    label: 'Email Address',
    value,
}

export default email
