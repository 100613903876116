import { type NoInfer } from 'appTypes'
import { type SortBy, type Column } from 'components'

import { type CustomerModel } from '../types'

const source = 'ongoingAssignmentsCount' as const satisfies keyof CustomerModel
const label = 'Unit Assignments'

const tableColumn = <Source extends string = typeof source>() =>
    ({
        field: source as NoInfer<Source>,
        headerName: label,
    }) as const satisfies Column

const sort = ({ label: labelProp }: { label?: string } = {}) =>
    ({
        id: source,
        label: labelProp || label,
    }) as const satisfies SortBy

const ongoingAssignmentsCount = {
    source,
    label,
    tableColumn,
    sort,
}

export default ongoingAssignmentsCount
