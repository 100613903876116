import { type FC } from 'react'

import { type WithChildrenNode } from 'appTypes'
import { getPageContext } from 'context'

import { type CostReportContextValue } from './types'
import useGetData from './useGetData'

const context = getPageContext<CostReportContextValue>()

export const useCostReportContext = context.useContext

export const CostReportContextProvider: FC<WithChildrenNode> = ({ children }) => {
    const result = useGetData()

    return <context.Provider value={result}>{children}</context.Provider>
}
