import { type FC } from 'react'

import { DialogSelector, type DialogSelectorProps } from 'components'

import { type CK79Model } from '../types'
import { ck79Resource, displayCK79 } from '../utils'

interface Props extends Pick<DialogSelectorProps, 'disabled'> {}

export const CK79Input: FC<Props> = ({ disabled }) => {
    return (
        <DialogSelector<CK79Model>
            titleSource={(record) => (record ? displayCK79(record) : 'Position')}
            source="position"
            reference={ck79Resource.resource}
            defaultSelectorProps={{
                label: 'Position',
            }}
            filter={{
                search: {
                    placeholder: 'Search by Code or Name',
                },
            }}
            itemPrimary="sideAndOrientation"
            itemSecondary="description"
            defaultSelectorValueSource={({ selected }) => displayCK79(selected)}
            disabled={disabled}
        />
    )
}
