import { type FC } from 'react'

import { renderOnFlag } from 'lib'
import { Alert } from 'ui'

const ScannerInfo: FC = () => {
    return <Alert severity="info">Align code with camera orientation</Alert>
}

export default renderOnFlag('flag4047UpcScanner', ScannerInfo)
