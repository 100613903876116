import { type FC } from 'react'

import { type SvgIconElement } from 'appTypes'
import { globalClassNames, withColor } from 'lib'
import { MenuItem, type MenuItemProps, Typography, IconElement } from 'ui'

interface Props extends Pick<MenuItemProps, 'onClick' | 'className'> {
    children: string
    Icon: SvgIconElement
}

const ProfileMenuItem: FC<Props> = ({ children, Icon, onClick, className }) => {
    return (
        <MenuItem
            sx={{
                padding: '6px 12px',
                gap: '16px',
                minHeight: 'unset',
            }}
            role="none"
            onClick={onClick}
            className={className}
            color={withColor('text.primary')}
        >
            <IconElement
                component={Icon}
                size="24px"
                color="inherit"
            />
            <Typography
                variant="body2"
                color="inherit"
                className={globalClassNames.ellipsis}
            >
                {children}
            </Typography>
        </MenuItem>
    )
}

export default ProfileMenuItem
