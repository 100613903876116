import { useState } from 'react'

import Icons from 'assets/icons'
import {
    Select,
    MenuItem,
    type SelectChangeEvent,
    outlinedInputClasses,
    selectClasses,
    Typography,
} from 'ui'

import { type listOrderType } from '../types'

const CustomTypography = (props) => {
    return (
        <Typography
            component="div"
            {...props}
            position="relative"
        />
    )
}
interface TopSendersProps {
    setListOrder: React.Dispatch<React.SetStateAction<listOrderType>>
    listLength: number
}
const CostListSelect = ({ setListOrder, listLength }: TopSendersProps) => {
    const [choice, setChoice] = useState('DESC')

    const handleChange = (event: SelectChangeEvent) => {
        setChoice(event.target.value)

        setListOrder((p) => event.target.value as listOrderType)
    }

    return (
        <>
            {listLength > 3 ? (
                <Select
                    value={choice}
                    IconComponent={Icons.KeyboardArrowDownOutlined}
                    components={{ Root: CustomTypography }}
                    sx={{
                        [`& .${selectClasses.select}`]: {
                            padding: 0,
                        },
                        [`& .${selectClasses.icon}`]: {
                            width: '22px',
                            height: '16px',
                            top: 'calc(50% - 10px)',
                        },
                        [`& .${outlinedInputClasses.notchedOutline}`]: {
                            border: 'none',
                        },
                    }}
                    onChange={handleChange}
                >
                    <MenuItem value="DESC">Most Expensive</MenuItem>
                    <MenuItem value="ASC">Least Expensive</MenuItem>
                </Select>
            ) : (
                'Most Expensive'
            )}
        </>
    )
}
export default CostListSelect
