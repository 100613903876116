import Icons from 'assets/icons'

type ArrowType = React.ComponentProps<typeof Icons.ArrowUpward>
interface ArrowProps extends ArrowType {
    direction: 'up' | 'down'
}
const Arrow = ({ direction = 'up', ...props }: ArrowProps) => {
    return direction === 'up' ? (
        <Icons.ArrowUpward {...props} />
    ) : (
        <Icons.ArrowDownward {...props} />
    )
}
export default Arrow
