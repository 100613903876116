import { createContext, type ReactNode, useState, useEffect } from 'react'

import api from 'core/api'
import { authStore } from 'core/auth'
import { makeid } from 'utils'

import { type SyncContextResult } from './types'

export const SyncContext = createContext<SyncContextResult>(null)

interface SyncProviderProps {
    children: ReactNode
}

export const SyncProvider = ({ children }: SyncProviderProps) => {
    const [sync, setSync] = useState<string[]>([])

    const shouldSync = Boolean(sync.length)

    useEffect(() => {
        if (shouldSync) {
            let timer
            const request = async () => {
                const user = await api.getCurrentUser()
                if (!user) {
                    return
                }
                authStore.action(() => {
                    authStore.user.activeTasks = user.activeTasks
                    authStore.currentCompany.activeTasks =
                        user.membership?.company?.activeTasks || []
                })
                timer = setTimeout(request, 10000)
            }

            timer = setTimeout(request, 5000)

            return () => {
                clearTimeout(timer)
            }
        }
    }, [shouldSync])

    const subscribeSync = () => {
        const id = makeid()
        setSync((oldSync) => [...oldSync, id])

        return () => {
            setSync((oldSync) => oldSync.filter((item) => item !== id))
        }
    }

    return (
        <SyncContext.Provider
            value={{
                subscribeSync,
            }}
        >
            {children}
        </SyncContext.Provider>
    )
}
