import Icons from 'assets/icons'
import { urls } from 'configs'
import { withColor } from 'lib'
import { LinkButton } from 'ui'

import Item from './Item'
import { EllipseHover } from './ItemHover'
import SidebarTooltip from './SidebarTooltip'

const Settings = () => {
    return (
        <LinkButton
            to={urls.settings}
            color={withColor('text.primary')}
        >
            <SidebarTooltip title="Account Settings">
                <Item py="16px">
                    <EllipseHover>
                        <Icons.SettingsOutlined />
                    </EllipseHover>
                </Item>
            </SidebarTooltip>
        </LinkButton>
    )
}

export default Settings
