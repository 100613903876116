import { type FC, useEffect } from 'react'

import { useFormContext } from 'react-hook-form'

import { type Identifier } from 'appTypes'
import {
    costMaskParams,
    inputQuantityMaskParams,
    TextareaInput,
    TextInput,
    useUtilityDrawerContext,
} from 'components'
import { maxLengthValidationText, requiredValidation } from 'core'
import { type InvoiceLabor } from 'resources/invoices'
import { LineItemTypeKeys } from 'resources/lineItems'
import { CK15Input, CK18Input, CK33InputWithoutParts } from 'resources/vmrs'
import { SectionTitleSmall } from 'ui'

import { getSuggestedPrice } from '../utils'

import { type InvoiceLaborExtra } from './InvoiceLaborDrawer'

export interface InvoiceLaborFormProps {
    isDisabled?: (source: keyof InvoiceLabor) => boolean
    isHidden?: (source: keyof InvoiceLabor) => boolean
}

const defaultDisabled = () => false

const descriptionValidation = [maxLengthValidationText, requiredValidation]

const InvoiceLaborForm = ({ isDisabled = defaultDisabled, isHidden }: InvoiceLaborFormProps) => {
    const { extra } = useUtilityDrawerContext()
    const { invoice } = extra as InvoiceLaborExtra

    return (
        <>
            <SectionTitleSmall>Basic Details</SectionTitleSmall>

            {isHidden?.('component') ? null : (
                <CK33InputWithoutParts
                    disabled
                    required
                />
            )}

            <TextareaInput
                source="description"
                label="Description"
                disabled={isDisabled('description')}
                validate={descriptionValidation}
            />

            {isHidden?.('cause') ? null : (
                <CK18Input
                    source="cause"
                    disabled
                    label="Cause"
                />
            )}

            {isHidden?.('correction') ? null : <CK15Input disabled />}

            <TextInput
                source="laborRate"
                label="Hourly Labor Rate"
                {...costMaskParams}
                disabled
            />

            <SaleRateInput disabled={isDisabled('price')} />

            {isHidden?.('orderQuantity') ? null : (
                <TextInput
                    source="orderQuantity"
                    label="Work Order Qty/Hours"
                    {...inputQuantityMaskParams}
                    disabled
                />
            )}

            <TextInput
                source="quantity"
                label="Qty/Hours to Invoice"
                disabled={isDisabled('quantity')}
                {...inputQuantityMaskParams}
                validate={requiredValidation}
            />

            <DefaultPriceHandler id={invoice.id} />
        </>
    )
}

const DefaultPriceHandler: FC<{ id: Identifier }> = ({ id }) => {
    const { setValue } = useFormContext()

    useEffect(() => {
        const setPrice = async () => {
            const price = await getSuggestedPrice(id, {
                type: LineItemTypeKeys.LABOR,
            })
            setValue('price', price.sellingPrice)
        }
        setPrice()
    }, [])
    return null
}

export const SaleRateInput = ({ disabled }: { disabled: boolean }) => {
    const { watch, getValues } = useFormContext()

    const laborRate = watch('laborRate') | getValues('laborRate')
    const saleRate = watch('price') | getValues('price')

    return (
        <TextInput
            source="price"
            label="Labor Sale Rate"
            {...costMaskParams}
            disabled={disabled}
            helperText={
                saleRate && Number(saleRate) < Number(laborRate)
                    ? 'Labor sale rate below hourly labor rate'
                    : ''
            }
            validate={requiredValidation}
        />
    )
}
export default InvoiceLaborForm
