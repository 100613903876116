import { type FC } from 'react'

import { formatMoney } from 'components'
import { globalClassNames } from 'lib'
import { useWidgetContext } from 'pages/Dashboard/components/GridLayout'
import { WidgetListContainer } from 'pages/Dashboard/components/WidgetBase/styled'
import LineItemsTooltip from 'pages/WorkOrders/Jobs/WorkOrderJobCard/components/LineItemsTooltip'
import { Box, BoxContainer, Status, Typography } from 'ui'

import { ListItem, WidgetListDescription } from '../../components'
import { type listType, statusColors } from '../types'

interface CostPerRepairListProps {
    list: listType
    description: string
    isValid: boolean
}
const CostPerRepairList: FC<CostPerRepairListProps> = ({ list, description, isValid }) => {
    const dimensions = useWidgetContext()

    const { data } = list

    if (!isValid) {
        return <WidgetListDescription>{description}</WidgetListDescription>
    }

    const List = data.map(({ id, description, type, total, code, ...ratioData }, i) => {
        if (total == null) {
            return null
        }
        return (
            <Typography
                component={ListItem}
                variant="chartListItem"
                key={`${description}-${i}`}
            >
                <BoxContainer
                    sx={{
                        pr: '10px',
                    }}
                    className={globalClassNames.ellipsis}
                >
                    <Box
                        className={globalClassNames.ellipsis}
                        sx={{ marginRight: '13px' }}
                    >
                        <Status
                            size="8px"
                            iconColor={(theme) => theme.palette.charts[statusColors[i]]}
                        />
                    </Box>
                    {/* TODO: better formatting for ck33 */}
                    <Box>{`${code ? code.substring(0, 3) : ''} ${description}`}</Box>
                    {dimensions !== 'mdY' && (
                        <Typography
                            className={globalClassNames.ellipsis}
                            color="text.disabled"
                            variant="chartListItem"
                            ml="8px"
                            lineHeight="150%"
                        >
                            {type}
                        </Typography>
                    )}
                </BoxContainer>

                <LineItemsTooltip data={ratioData}>{formatMoney(total)}</LineItemsTooltip>
            </Typography>
        )
    })
    return <WidgetListContainer>{List}</WidgetListContainer>
}
export default CostPerRepairList
