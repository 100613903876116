import { useFormContext } from 'react-hook-form'

import { DateTimeInput } from 'components'

import assignmentFields from '../fields'

const AssignmentEndTimeInput = ({
    helperText,
    disabled,
}: {
    helperText: string
    disabled: boolean
}) => {
    const { watch, getValues } = useFormContext()

    const startTime =
        watch(assignmentFields.startDate.source) || getValues(assignmentFields.startDate.source)

    return (
        <DateTimeInput
            disabled={disabled || !startTime}
            source={assignmentFields.endDate.source}
            label={assignmentFields.endDate.label}
            helperText={helperText}
        />
    )
}

export default AssignmentEndTimeInput
