import { SliderView } from 'components'
import { type SliderViewProps } from 'components/Slider/SliderView'
import { VendorTotalExpensesWidget } from 'pages/Vendors/components'
import { PageContent } from 'ui'

const baseSlides: SliderViewProps['items'] = [<VendorTotalExpensesWidget />]

const VendorTopSection = () => {
    const slides = [...baseSlides]

    return (
        <PageContent
            bgcolor={(theme) => theme.palette.darkGray}
            flexGrow="initial !important"
        >
            <SliderView items={slides} />
        </PageContent>
    )
}

export default VendorTopSection
