import { SliderView } from 'components'
import { Section } from 'ui'

import MaintenanceCostWidget from './MaintenanceCostWidget'
import UtilizationWidget from './UtilizationWidget'

const WidgetsSection = () => {
    return (
        <Section>
            <SliderView items={[<MaintenanceCostWidget />, <UtilizationWidget />]} />
        </Section>
    )
}

export default WidgetsSection
