import { type FC } from 'react'

import { useRecordContext, useUpdate } from 'react-admin'

import Icons from 'assets/icons'
import {
    type ActionChildren,
    basePermissions,
    updatedMessage,
    useFinalErrorHandler,
    useNotify,
    useResourcePermissions,
} from 'core'

import unitFields from '../fields'
import { type UnitModel, UnitStatusKeys } from '../types'
import unitResource from '../unitResource'
import { isUnitArchived } from '../utils'

export const useUnitStatusAction = () => {
    const permissions = useResourcePermissions(unitResource)

    return function (render: ActionChildren) {
        const permission = permissions[basePermissions.update]

        if (!permission) {
            return null
        }

        return <UnitStatusAction key="unit-status">{render}</UnitStatusAction>
    }
}

const UnitStatusAction: FC<{ children: ActionChildren }> = ({ children }) => {
    const record = useRecordContext<UnitModel>()
    const [update] = useUpdate<UnitModel>()
    const errorHandler = useFinalErrorHandler()
    const notify = useNotify()

    const isInService = record.status === UnitStatusKeys.IN_SERVICE
    const changeStatus = isInService ? UnitStatusKeys.OUT_OF_SERVICE : UnitStatusKeys.IN_SERVICE

    return children({
        Icon: isInService ? Icons.CancelOutlined : Icons.CheckCircleOutline,
        title: unitFields.status.getConfig(changeStatus).text,
        disabled: isUnitArchived(record),
        onClick: async () => {
            try {
                await update(
                    unitResource.resource,
                    {
                        data: {
                            status: changeStatus,
                        },
                        id: record.id,
                    },
                    {
                        returnPromise: true,
                    },
                )
                notify(updatedMessage)
            } catch (err) {
                errorHandler(err)
            }
        },
    })
}
