import images from 'assets/images'
import { NoResultsCard } from 'components'
import { Typography } from 'ui'

import { ContactDrawerToggler } from './ContactDrawerToggler'

export const ContactsEmpty = () => {
    return (
        <NoResultsCard
            title="No Items"
            height="145px"
            direction="row"
            imageSrc={images.noVendorContacts}
            imageProps={{
                alt: 'No Contacts',
                width: '107px',
                mr: {
                    xs: '16px',
                    sm: '20px',
                },
            }}
            action={
                <ContactDrawerToggler>
                    {({ onClick }) => (
                        <Typography
                            variant="body1"
                            color="primary"
                            sx={{ cursor: 'pointer' }}
                            onClick={onClick}
                        >
                            Add Contact Person
                        </Typography>
                    )}
                </ContactDrawerToggler>
            }
        />
    )
}
