import { formatMoney } from 'components'
import { type Serializer } from 'core'
import { attachmentsSerializer } from 'resources/gallery'

import { type PurchaseInvoiceModel } from './types'

export const formatInvoiceAmount = (amount: number) =>
    formatMoney({ value: amount, negativeFormat: 'accounting' })

export const purchaseInvoiceSerializer: Serializer<PurchaseInvoiceModel> = [
    'number',
    { name: 'amount', parse: 'number' },
    { name: 'date', parse: 'date' },
    {
        name: 'paymentTerm',
        parse: 'emptyToNull',
    },
    ...(attachmentsSerializer as Serializer<PurchaseInvoiceModel>),
]
