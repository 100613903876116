import { styled } from 'lib'
import { DataAvatar } from 'ui'

import { headerCssVariables } from './config'

const HeaderAvatar = styled(DataAvatar)`
    width: var(${headerCssVariables.avatarSize});
    height: var(${headerCssVariables.avatarSize});
`

export default HeaderAvatar
