import { useState } from 'react'

import { DistanceLabel, formatMoney } from 'components'
import { reportsUrls, createReportResource } from 'resources/reports'

import WidgetBase, { WidgetSkeleton } from '../../WidgetBase/WidgetBase'
import { useCreateDashboardWidgetLink, useDashboardWidget } from '../../WidgetsForm'
import { WidgetLineChartBase } from '../components'

import { Clarification, CostListSelect, CostPerDistanceList } from './components'
import { type listOrderType, type ResponseType } from './types'

const description = `The accumulated maintenance expenses are divided by the total mileage logged, and
the result is displayed for the selected period of time, empowering you to spot the trend at once.`
let isValid = false

const FleetCostPerDistanceWidget = () => {
    const createLink = useCreateDashboardWidgetLink()

    const [listOrder, setListOrder] = useState<listOrderType>('DESC')
    const data = useDashboardWidget<ResponseType>({
        resource: createReportResource('cost-per-distance'),
        sideEffect: (data) => {
            isValid = typeof data.fleetAverage === 'number'
            return data
        },
    })
    if (!data) {
        return <WidgetSkeleton />
    }
    const { fleetAverage, changeInPercentsForPeriod, highestCostUnits, dataPoints } = data

    return (
        <WidgetBase
            headerProps={{
                title: (
                    <>
                        Maintenance CP
                        <DistanceLabel
                            variant="short-abbr"
                            textCase="upper"
                        />
                    </>
                ),
                label: isValid ? formatMoney(fleetAverage) : null,
                tooltipText: description,
                clarification: <Clarification changeForPeriod={changeInPercentsForPeriod} />,
                chart: (
                    <WidgetLineChartBase
                        data={dataPoints}
                        isValid={isValid}
                    />
                ),
            }}
            infoProps={{
                content: (
                    <CostPerDistanceList
                        data={highestCostUnits}
                        description={description}
                        listOrder={listOrder}
                    />
                ),
            }}
            separator={
                <CostListSelect
                    setListOrder={setListOrder}
                    listLength={highestCostUnits.length}
                />
            }
            link={createLink(reportsUrls.costPerMeter)}
        />
    )
}
export default FleetCostPerDistanceWidget
