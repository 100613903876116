import { type Identifier } from 'appTypes'
import { type CustomerModel } from 'resources/customers'
import { type PurchaseInvoiceModel } from 'resources/purchaseInvoices'
import { type WoPurchaseOrderModel, type WorkOrderModel } from 'resources/workOrders'
import { type ShopModel } from 'resourcesBase'

export interface PurchaseOrderModel extends WoPurchaseOrderModel {
    backorder: boolean
    created: string
    workOrder: Identifier
    workOrderData: WorkOrderModel
    items: number
    vendor: Identifier
    dateLastReceived: string
    status: PoStatusKeys
    type: PoTypeKeys
    totalCost: number
    miscellaneousTax: number
    salesTax: number
    shippingTax: number
    cancellationReason: string
    closedOn: string
    totalItems: number
    costOnOrder: number
    canceledOn: string
    discount: number
    shop: Identifier
    shopData: ShopModel
    invoiceData: PurchaseInvoiceModel[]
    customerData: CustomerModel
}

export enum PoTypeKeys {
    WORK_ORDER = 'WORK_ORDER',
    STANDARD = 'STANDARD',
    CREDIT = 'CREDIT',
}

export enum PoStatusKeys {
    OPEN = 'OPEN',
    DRAFT = 'DRAFT',
    CANCELED = 'CANCELED',
    CLOSED = 'CLOSED',
}
