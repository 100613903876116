import Icons from 'assets/icons'
import { defaultStatuses, options, type StatusConfig } from 'resources/common'
import { MemberInvitationStatusKeys, type MemberModel } from 'resourcesBase'
import { IconElement } from 'ui'

const source = 'invitationStatus' as const satisfies keyof MemberModel

const getConfig = options.createConfig<StatusConfig<MemberInvitationStatusKeys>>([
    { ...defaultStatuses.closed, id: MemberInvitationStatusKeys.ACCEPTED, text: 'Accepted' },
    { ...defaultStatuses.inProgress, id: MemberInvitationStatusKeys.SENT, text: 'Invitation Sent' },
    {
        id: MemberInvitationStatusKeys.EXPIRED,
        icon: (
            <IconElement
                component={Icons.AccessTime}
                color={(theme) => theme.palette.error.main}
            />
        ),
        text: 'Expired',
        iconComponent: Icons.AccessTime,
    },
])

const value = options.makeIconValue(getConfig)

const invitationStatus = {
    source,
    label: 'Invitation Status',
    getConfig,
    value: (data: Pick<MemberModel, 'invitationStatus'>) => value(data.invitationStatus),
}

export default invitationStatus
