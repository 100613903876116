import { type ReactElement, type ReactNode } from 'react'

import { useFormContext } from 'react-hook-form'

import { type DataRecord } from 'appTypes'
import SelectInput from 'components/inputs/SelectInput'
import { formHelperTextClasses } from 'ui'

export interface RenderFilterComponentProps {
    key: string
    inputKey: string
    source: string
    label: string
    makeItemLabel: (
        item: DataRecord,
    ) => string | React.ReactElement<any, string | React.JSXElementConstructor<any>>
}

export type ListFilterChoice<RecordType = any> = {
    id: Extract<keyof RecordType, string>
    label: string | (() => ReactElement)
    makeItemLabel?: (item: DataRecord) => ReactElement | string
    filterType?: undefined | 'range' //
    renderComponent?: (props: RenderFilterComponentProps) => ReactNode
}

export type ListFilterChoices<RecordType = any> = ListFilterChoice<RecordType>[]
export interface ListFilterSourceInputProps {
    inputKey: string
    registerFilterItem: (source: string, currentSourceKey: string) => void
    sourceChoices: ListFilterChoices
    filterKey2source: string[]
}

const filterOptionText = (data) => {
    const label = data.label
    return typeof label === 'function' ? label() : label
}

const ListFilterSourceInput = ({
    inputKey,
    registerFilterItem,
    sourceChoices,
    filterKey2source,
}: ListFilterSourceInputProps) => {
    const { getValues } = useFormContext()

    const sourceFieldKey = inputKey + '_source'
    const formValues = getValues()
    const unusedFilters = sourceChoices.filter((item) => {
        return !Object.values(filterKey2source).some((value) => {
            return item.id === formValues[sourceFieldKey] ? false : item.id === value
        })
    })

    return (
        <SelectInput
            clearable={false}
            source={sourceFieldKey}
            key={sourceFieldKey}
            label="Column/Label"
            // XXX can be done without important, maybe TODO
            sx={{
                width: '100%',
                marginBottom: '16px !important',
                [`& .${formHelperTextClasses.root}`]: {
                    display: 'none',
                },
            }}
            inputProps={{
                onChange: (e) => {
                    const target = e.target as HTMLInputElement
                    registerFilterItem(target.value, sourceFieldKey)
                },
            }}
            // PaperComponent - not accessible
            helperText={false}
            choices={unusedFilters.map((item, ix) => ({
                ...item,
                key: ix,
            }))}
            optionText={filterOptionText}
            disableEmptyValue
        />
    )
}

export default ListFilterSourceInput
