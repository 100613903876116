import { type Point } from '../../CostReportContext'

export const baseValueFormat = (value: number) => {
    const absValue = Math.abs(value)
    if (absValue === 0) {
        return String(absValue)
    }

    if (absValue >= 1000) {
        const number = absValue / 1000
        if (number >= 100) {
            return `${number.toFixed(0)}k`
        }
        return `${number.toFixed(1)}k`
    }
    if (absValue < 10) {
        return absValue % 1 === 0 ? String(absValue) : String(absValue.toFixed(2))
    }
    return String(Math.round(absValue))
}

export const formatData = (data: Point[]) => {
    const dataValues = data.map((item) => item.value).filter((value) => typeof value === 'number')
    const maxValue = Math.max(...dataValues)
    const minValue = Math.min(Math.min(...dataValues), 0)

    const step = (maxValue - minValue) / 3

    const leftAxisValues =
        maxValue === minValue ? [maxValue] : [minValue, minValue + step, maxValue - step, maxValue]

    const formattedData = data.map(({ value, date }) => ({ y: value, x: date }))

    return { leftAxisValues, formattedData }
}
