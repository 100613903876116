import { type NoInfer } from 'appTypes'
import { type Column, type ListFilterChoice } from 'components'

import { type UnitModel } from '../types'

const source = 'ongoingCustomerAssignment' satisfies keyof UnitModel

const label = 'Customer Assignments'

const tableColumn = <Source extends string = typeof source>() =>
    ({
        field: source as NoInfer<Source>,
        headerName: label,
    }) as const satisfies Column

const filter = <Source extends string = typeof source>() =>
    ({
        id: source as NoInfer<Source>,
        label,
    }) as const satisfies ListFilterChoice

const ongoingCustomerAssignment = {
    source,
    label,
    tableColumn,
    filter,
}

export default ongoingCustomerAssignment
