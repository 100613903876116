import { type FC } from 'react'

import { type WithChildrenNode, type DataRecord } from 'appTypes'
import { extendRecord } from 'core/data'
import { globalClassNames } from 'lib'
import { ListItem, ListItemText } from 'ui'

import { type DialogSelectorListProps } from './DialogSelectorList'

export interface DialogSelectorListItemProps<T extends DataRecord = any>
    extends Pick<DialogSelectorListProps<T>, 'itemPrimary' | 'itemSecondary'> {
    choice: T
    onSelect: (event: React.MouseEvent<HTMLElement>, choice: T) => void
}

const DialogSelectorListItem = <T extends DataRecord = any>({
    choice,
    onSelect,
    itemPrimary,
    itemSecondary,
}: DialogSelectorListItemProps<T>) => {
    const secondaryText = extendRecord(choice, itemSecondary)

    return (
        <DialogSelectorItemBase onSelect={(e) => onSelect(e, choice)}>
            <ListItemText
                className={globalClassNames.ellipsis}
                primaryTypographyProps={{
                    sx: {
                        fontWeight: 'bold',
                    },
                    className: globalClassNames.ellipsis,
                }}
                secondaryTypographyProps={{
                    component: 'div',
                    className: globalClassNames.ellipsis,
                }}
                primary={extendRecord(choice, itemPrimary)}
                secondary={
                    Array.isArray(secondaryText)
                        ? secondaryText.map((item, i) => <div key={i}>{item}</div>)
                        : secondaryText
                }
            />
        </DialogSelectorItemBase>
    )
}
export default DialogSelectorListItem

interface DialogSelectorItemBaseProps extends WithChildrenNode {
    onSelect: (e: React.MouseEvent<HTMLElement>) => void
}

export const DialogSelectorItemBase: FC<DialogSelectorItemBaseProps> = ({ children, onSelect }) => {
    return (
        <ListItem
            button
            role="option"
            component="li"
            sx={{
                px: '20px',
                py: '16px',
                borderBottom: (theme) => `1px solid ${theme.palette.other.divider}`,
            }}
            onClick={onSelect}
        >
            {children}
        </ListItem>
    )
}
