import { type FC } from 'react'

import { reportsUrls, createReportResource } from 'resources/reports'

import WidgetBase, { WidgetSkeleton } from '../../WidgetBase/WidgetBase'
import { useCreateDashboardWidgetLink, useDashboardWidget } from '../../WidgetsForm'
import { Clarification } from '../FleetCostPerDistanceWidget/components'
import { WidgetLineChartBase, WidgetLineChartTooltip } from '../components'

import { DataList } from './components'
import { type MTBFResponse } from './types'
import { formatValue } from './utils'

const description = `The MTBF widget calculates the average time between unexpected
failures across your fleet. It tracks engine hours between emergency breakdowns,
displaying both average and trend. The trendline shows whether
fleet reliability is improving (time between failures increasing) or declining.`

let isValid = false
const MTBFWidget: FC = () => {
    const createLink = useCreateDashboardWidgetLink()
    const data = useDashboardWidget<MTBFResponse>({
        resource: createReportResource('mtbf'),
        sideEffect: (data) => {
            isValid = typeof data.fleetAverageMtbf === 'number'
            return data
        },
    })
    if (!data) {
        return <WidgetSkeleton />
    }
    const { changeInPercentsForPeriod, fleetAverageMtbf, fleetMtbfPoints, worstValueUnits } = data

    return (
        <WidgetBase
            headerProps={{
                title: 'Mean Time Between Failures',
                label: isValid ? formatValue(fleetAverageMtbf) : null,
                tooltipText: description,
                clarification: (
                    <Clarification
                        changeForPeriod={changeInPercentsForPeriod}
                        isPositiveColor={changeInPercentsForPeriod > 0}
                    />
                ),
                chart: (
                    <WidgetLineChartBase
                        data={fleetMtbfPoints}
                        isValid={isValid}
                        tooltip={Tooltip}
                    />
                ),
            }}
            infoProps={{
                content: (
                    <DataList
                        data={worstValueUnits}
                        description={description}
                    />
                ),
            }}
            separator="Critical Attention Vehicles"
            link={createLink(reportsUrls.mtbf)}
        />
    )
}
export default MTBFWidget

const Tooltip = (props) => {
    return (
        <WidgetLineChartTooltip
            {...props}
            dataFormat={formatValue}
        />
    )
}
