import { type FC } from 'react'

import { useFormContext } from 'react-hook-form'

import { SelectInput } from 'components'
import { perPageLimit, ReferenceInput } from 'core'

import { stateFields } from '../stateFields'

interface Props {
    countrySource?: string
    disabled?: boolean
    source?: string
}

export const StateInput: FC<Props> = ({
    disabled: disabledProp,
    source = stateFields.self.source,
    countrySource = 'country',
}) => {
    const { getValues, watch } = useFormContext()
    const country = watch(countrySource) || getValues(countrySource) || null

    const disabled = disabledProp || !country

    return (
        <ReferenceInput
            reference="states"
            source={source}
            filter={{ country }}
            perPage={perPageLimit}
            disabled={disabled}
        >
            <SelectInput
                label={stateFields.self.label}
                source={source}
                disableEmptyValue
                disabled={disabled}
            />
        </ReferenceInput>
    )
}
