import { type ReactElement, type FC } from 'react'

import { urls } from 'configs'
import { LinkButton } from 'ui'

import { type CustomerModel } from '../types'

interface Props {
    record: Pick<CustomerModel, 'name'>
}

const valueWithLink = (params: Pick<CustomerModel, 'id' | 'name'>): ReactElement => {
    if (!params) {
        return null
    }

    return <LinkButton to={`${urls.customers}/${params.id}`}>{params.name}</LinkButton>
}

const Value: FC<Props> = ({ record }) => {
    if (!record) {
        return null
    }
    return <>{record.name}</>
}

const source: keyof CustomerModel = 'name'

const Name = {
    source,
    label: 'Customer Name',
    Value,
    value: (record: CustomerModel) => record?.name || '',
    valueWithLink,
}

export default Name
