import { type FC } from 'react'

import Icons from 'assets/icons'
import { formatPercent, formatMoney } from 'components'
import { useTheme } from 'lib'

import { type ExtendPieData, WidgetPieChartBase } from '../../components'
import { type JobItemsCalculations } from '../../components/types'
import { type listType, statusColors } from '../types'

interface PieChartProps {
    list: listType
    total: JobItemsCalculations['total']
    disabled: boolean
}

const PieChart: FC<PieChartProps> = ({ list, total, disabled }) => {
    const { palette } = useTheme()

    const listData = list.data.map((obj, index) => {
        return {
            id: `${obj.description} ${formatPercent(obj.totalPercent)}`,
            subTitle: obj.type,
            value: obj.total,
            color: palette.charts[statusColors[index]],
            total,
        }
    })

    return (
        <WidgetPieChartBase<ExtendPieData<{ subTitle?: string }>>
            disabled={disabled}
            icon={Icons.Top3Reasons}
            sortByValue={false}
            renderContent={({ id, subTitle, value }) => [id, subTitle, formatMoney(value)]}
            data={[
                ...listData,
                {
                    id: `Other ${formatPercent(list.sumPercentageOfExcludedReasons)}`,
                    value: list.sumOfExcludedReasons,
                    color: palette.charts.disable,
                    total,
                },
            ]}
        />
    )
}
export default PieChart
