import { type FC } from 'react'

import Icons from 'assets/icons'
import { DataAvatar } from 'ui'

import type VendorModel from '../types'

interface Props {
    record: Pick<VendorModel, 'logo'>
}

const MainIcon = Icons.StorefrontOutlined

const Avatar: FC<Props> = ({ record }) => (
    <DataAvatar
        imageSrc={record.logo}
        defaultImage={<MainIcon />}
    />
)

const avatar = {
    Value: Avatar,
    Icon: MainIcon,
}

export default avatar
