import { type DataRecord } from 'appTypes'
import Icons from 'assets/icons'
import { ActionsMenu, type MenuActionProps, useActionsMenuContext } from 'core/actions'
import { type UseCreateResourcePathParams } from 'core/resource'
import { type UnitModel } from 'resources/units'
import { useWoLimit, type WorkOrderModel } from 'resources/workOrders'
import { type PopoverOrigin, SimpleScrollbar, Typography, BoxContainer } from 'ui'

import CreateWOSection from './CreateWOSection'
import ExistingWOSection from './ExistingWOSection'
import { type UseWOParams } from './UseWO/useAttachWO'
export interface WorkOrderCreateMenuProps<ParentType extends DataRecord = any>
    extends Omit<MenuActionProps<WorkOrderModel>, 'actions'>,
        Pick<UseCreateResourcePathParams, 'queryParams'>,
        Pick<UseWOParams, 'options'> {
    parentRecord: ParentType
    disabled?: boolean
    anchorOrigin?: PopoverOrigin
    transformOrigin?: PopoverOrigin
    unit?: UnitModel
}

const AddWorkOrderMenu = <ParentType extends DataRecord = any>({
    queryParams,
    disabled,
    parentRecord,
    anchorOrigin,
    transformOrigin,
    options,
    renderToggler,
    unit,
    ...props
}: WorkOrderCreateMenuProps<ParentType>) => {
    const { canCreateWo, woLimitReachedAction } = useWoLimit()
    const { close } = useActionsMenuContext()

    return (
        <ActionsMenu
            anchorOrigin={
                anchorOrigin || {
                    vertical: 'bottom',
                    horizontal: 'right',
                }
            }
            transformOrigin={
                transformOrigin || {
                    vertical: 'top',
                    horizontal: 'right',
                }
            }
            {...props}
            renderToggler={
                renderToggler ||
                ((open) => (
                    <BoxContainer
                        sx={{ cursor: disabled ? 'default' : 'pointer' }}
                        onClick={
                            disabled
                                ? undefined
                                : (event) => {
                                      if (canCreateWo()) {
                                          open(event)
                                          return
                                      }
                                      woLimitReachedAction()
                                  }
                        }
                        role="button"
                        gap="4px"
                    >
                        <Icons.Add
                            sx={{
                                width: '16px',
                                height: '16px',
                                color: disabled ? 'action.disabled' : 'primary.main',
                            }}
                        />
                        <Typography
                            variant="body2"
                            color={disabled ? 'action.disabled' : 'primary.main'}
                        >
                            Add
                        </Typography>
                    </BoxContainer>
                ))
            }
            actions={(params, { children }) => [
                <SimpleScrollbar
                    style={{ maxHeight: '500px', width: '274px' }}
                    key="add-wo"
                >
                    <CreateWOSection<ParentType>
                        key="create-wo"
                        parentRecord={parentRecord}
                        cardMenuClose={close}
                        options={options}
                        unit={unit}
                    />
                    <ExistingWOSection<ParentType>
                        key="existing-wo"
                        parentRecord={parentRecord}
                        cardMenuClose={close}
                        options={options}
                        unit={unit}
                    />
                </SimpleScrollbar>,
            ]}
        />
    )
}
export default AddWorkOrderMenu
