import { type FC } from 'react'

import { Divider } from 'ui'

const HeaderDivider: FC = () => {
    return (
        <Divider
            sx={{
                display: (theme) => ({ xs: 'none', [theme.props.mobileViewBreakpoint]: 'block' }),
            }}
        />
    )
}

export default HeaderDivider
