import { type DataRecord, type RepeatKeys } from 'appTypes'

export type Photos = RepeatKeys<'photo', 9, string>
export const getRecordPhotos = (
    record: DataRecord,
    formValues?: any,
): {
    files: Photos
    count: number
} => {
    const photos = record || formValues
    const files = {}
    let count = 0
    for (let i = 0; i <= 9; i += 1) {
        if (photos && photos['photo' + i]) {
            count += 1
        }
        files['photo' + i] = photos ? photos['photo' + i] : null
    }
    return { files, count }
}
export const photosToArray = (photos: Photos) =>
    Object.keys(photos).map((key) => ({
        id: key,
        file: photos[key],
    }))

export const getPhotos = (record: DataRecord & Photos) => {
    const photos = []
    for (let i = 0; i < 10; i += 1) {
        if (record['photo' + i]) {
            photos.push(record['photo' + i])
        }
    }
    return photos
}
