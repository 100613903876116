import { type FC } from 'react'

import { useChoicesContext } from 'react-admin'

import { Typography } from 'ui'

import { ck33Names } from '../../utils'

import { type DisplayedFiltersInfo } from './types'
import { favoritesSource } from './utils'

interface Props {
    levels: number[]
}

export const CurrentLevel: FC<Props> = ({ levels }) => {
    const { displayedFilters, filterValues } = useChoicesContext()
    const parents = (displayedFilters as DisplayedFiltersInfo)?.parents || []
    const lastChoice = parents[parents.length - 1]

    return (
        <Typography
            variant="h6"
            color="text.primary"
        >
            {filterValues[favoritesSource]
                ? 'Favorites'
                : ck33Names[lastChoice?.level == null ? levels[0] : lastChoice.level + 1]}
        </Typography>
    )
}
