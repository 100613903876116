import { type FC } from 'react'

import { type WithChildrenNode } from 'appTypes'
import { FixedTopPart } from 'components/pages/header'
import { BillingPaymentIssue } from 'resources/billing'
import { TelematicsSyncBar } from 'resources/telematics'

import AppHeader from '../AppHeader'
import LayoutWrapper from '../LayoutWrapper'
import Sidebar, { SidebarController } from '../Sidebar'
import { AppContent, MainContent } from '../styled'

import menu from './menu'

const MainLayout: FC<WithChildrenNode> = ({ children }) => {
    return (
        <LayoutWrapper>
            <SidebarController menu={menu}>
                <FixedTopPart>
                    <BillingPaymentIssue />
                    <TelematicsSyncBar />
                    <AppHeader />
                </FixedTopPart>
                <AppContent>
                    <Sidebar />
                    <MainContent>{children}</MainContent>
                </AppContent>
            </SidebarController>
        </LayoutWrapper>
    )
}

export default MainLayout
