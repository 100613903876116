import { useQuery as useReactQuery } from 'react-query'

import { type UseQueryMeta, type UseQueryOptions } from './types'

const useQuery = <ResponseType = any>(
    key: any[] | string,
    query: (p: any) => Promise<ResponseType>,
    options?: Omit<UseQueryOptions<ResponseType>, 'meta'> & {
        meta?: UseQueryMeta
    },
) => {
    return useReactQuery<ResponseType>(key, query, options)
}

export default useQuery
