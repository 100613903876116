import { type FC } from 'react'

import Icons from 'assets/icons'
import { resolveQtyMask } from 'components'
import { type InventoryItem } from 'resources/inventory'
import { SvgIcon, Tooltip, BoxContainer, Typography } from 'ui'

interface Props {
    record: Pick<InventoryItem, 'isBelowMinQuantity' | 'quantityOnHand' | 'minQuantity'>
}

const OnHandQuantityField: FC<Props> = ({ record }) => {
    const warning = record.isBelowMinQuantity

    return (
        <BoxContainer gap="5px">
            <Typography
                color="inherit"
                variant="inherit"
                component="span"
            >
                {resolveQtyMask(record.quantityOnHand)}
            </Typography>
            {warning ? (
                <Tooltip title={`Min QTY ${record.minQuantity}`}>
                    <SvgIcon
                        sx={{ width: '18px !important', height: '18px' }}
                        component={Icons.ErrorOutline}
                        color="error"
                        fontSize="small"
                    />
                </Tooltip>
            ) : null}
        </BoxContainer>
    )
}

export default OnHandQuantityField
