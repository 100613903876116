import Icons from 'assets/icons'
import UtilityDrawerCancelButton from 'components/Drawer/UtilityDrawerCancelButton'

import { useUtilityDrawerStepsContext } from './UtilityDrawerStepsContext'

const UtilityDrawerBackButton = () => {
    const { step, prev } = useUtilityDrawerStepsContext()

    if (!step) {
        return <UtilityDrawerCancelButton />
    }

    return (
        <UtilityDrawerCancelButton
            children="Back"
            startIcon={<Icons.ChevronLeft />}
            customAction={prev}
        />
    )
}

export default UtilityDrawerBackButton
