import { type FC } from 'react'

import { useRecordContext } from 'react-admin'

import { InfoCard, InfoCardDetails, InfoCardHeader, resolveQtyMask } from 'components'
import { OnHandQuantityField } from 'pages/Inventory/List/components'
import { type PartInInventoryModel, inventoryFields } from 'resources/inventory'

export const QuantityCard: FC = () => {
    const record = useRecordContext<PartInInventoryModel>()
    const inventoryItem = record.inventoryItem

    return (
        <InfoCard>
            <InfoCardHeader title="Quantity" />
            <InfoCardDetails
                details={[
                    {
                        label: 'On hand',
                        source: () => <OnHandQuantityField record={inventoryItem} />,
                    },
                    {
                        label: 'Available Qty',
                        source: () => resolveQtyMask(inventoryItem.availableQuantity),
                    },
                    {
                        label: 'Allocated Qty',
                        source: () => resolveQtyMask(inventoryItem.allocatedQuantity),
                    },
                    {
                        label: 'On Order Qty',
                        source: () => resolveQtyMask(inventoryItem.quantityOnOrder),
                    },
                    {
                        label: inventoryFields.qtyThreshold.label,
                        source: () => <inventoryFields.qtyThreshold.Value record={inventoryItem} />,
                    },
                ]}
            />
        </InfoCard>
    )
}
