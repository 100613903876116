import { type PageTabConfig } from 'components'

import { DocumentsContent, type DocumentsContentProps } from './components'

export const documentsTab = ({
    elementProps,
}: {
    elementProps: DocumentsContentProps
}): PageTabConfig => ({
    value: 'documents',
    label: 'Documents',
    element: <DocumentsContent {...elementProps} />,
})
