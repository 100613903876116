import { type Dispatch, useEffect } from 'react'

import { useFormContext } from 'react-hook-form'

import { useFormInfo } from 'context'

const PurchaseInvoiceNumberHandler = ({
    numberSource,
    setNumberMessage,
}: {
    setNumberMessage: Dispatch<string>
    numberSource: string
}) => {
    const { watch, formState } = useFormContext()
    const { submitValidate } = useFormInfo()
    const number = watch(numberSource)

    useEffect(() => {
        if (!number) {
            return
        }
        if (numberSource.includes('.') && number) {
            const setMessage = async () => {
                setNumberMessage(null)
                const sources = numberSource.split('.')
                const [_, data] = await submitValidate({ [sources[0]]: { number } })
                if (data?.[sources[0]]?.[sources[1]]) {
                    setNumberMessage(data?.[sources[0]]?.[sources[1]].message)
                }
            }
            setMessage()
            return
        }
        if (!formState.dirtyFields[numberSource]) {
            return
        }

        const setMessage = async () => {
            setNumberMessage(null)

            const [_, data] = await submitValidate({ number })

            if (data?.[numberSource]) {
                setNumberMessage(data?.[numberSource].message)
            }
        }
        setMessage()
    }, [number])

    return null
}

export default PurchaseInvoiceNumberHandler
