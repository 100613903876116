import { type ReactElement } from 'react'

import { type Identifier } from 'appTypes'
import { ResourceContextProviderWithClearEffect } from 'core'
import { poResource, type PurchaseOrderModel } from 'resources/purchaseOrders'

interface LineItemsResourceProps {
    id: PurchaseOrderModel['id']
    children: ReactElement
    receipt: Identifier
}
const LineItemsResource = ({ id, children, receipt }: LineItemsResourceProps) => {
    return (
        <ResourceContextProviderWithClearEffect
            value={{
                resource: `${poResource.resource}/${id}/${
                    receipt ? `receipts/${receipt}/items` : 'items'
                }`,
                name: 'receipts',
            }}
        >
            {children}
        </ResourceContextProviderWithClearEffect>
    )
}

export default LineItemsResource
