import { createResource } from 'core'

import { type VendorType, type VendorAspects } from './types'

export const vendorAspects = {
    totals: 'totals',
} satisfies { [key: string]: VendorAspects }

export const vendorsResource = createResource({
    name: 'vendors',
    resource: 'vendors',
})

export const vendorTypes: { id: VendorType; name: string }[] = [
    {
        id: 'REPAIR_SERVICES',
        name: 'Repair Services',
    },
    {
        id: 'PARTS_SUPPLY',
        name: 'Parts Supply',
    },
]

const vendorTypesObject = vendorTypes.reduce(
    (obj, type) => {
        obj[type.id] = type as any
        return obj
    },
    {} as { [key in VendorType]: { id: key; name: string } },
)

export const formatVendorTypes = (type: VendorType[]): string => {
    return type?.map((item) => vendorTypesObject[item].name).join(', ') || ''
}

export const formatVendorType = (type: VendorType): string => {
    if (!type) {
        return ''
    }
    return vendorTypesObject[type].name
}
