import Icons from 'assets/icons'
import { type mediaTypes } from 'utils'

import { type BaseActionOptions } from '../Actions'

export const downloadActionBaseOptions: {
    [key in keyof typeof mediaTypes]: BaseActionOptions
} = {
    pdf: {
        Icon: Icons.Pdf,
        title: 'Export as PDF',
    },
    csv: {
        Icon: null,
        title: 'Export as CSV',
    },
}
