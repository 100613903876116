import { type Identifier } from 'appTypes'
import { useCreateResourcePath } from 'core/resource'
import { Link, withColor } from 'lib'
import { Typography, type TypographyProps } from 'ui'

interface CardLinkProps extends Omit<TypographyProps, 'id' | 'ref' | 'component'> {
    id: Identifier
    disableLink?: boolean
    to?: string
}
const CardLink = ({ id, disableLink = false, to, ...props }: CardLinkProps) => {
    const createPath = useCreateResourcePath()

    return (
        <>
            {disableLink ? (
                <Typography
                    color={withColor('text.primary')}
                    variant="h6"
                    component="div"
                    {...props}
                >
                    {props.children}
                </Typography>
            ) : (
                <Typography
                    variant="h6"
                    component={Link}
                    to={
                        to ||
                        createPath({
                            id,
                            type: 'edit',
                        })
                    }
                    {...props}
                >
                    {props.children}
                </Typography>
            )}
        </>
    )
}

export default CardLink
