import { useState } from 'react'

import { useSetBlocker } from 'core/blocker'
import { type IntegrationType } from 'resources/telematics'
import { Dialog } from 'ui'

import IntegrationsUnitList from './IntegrationsUnitList'
import IntegrationsUnitListDialogHeader from './components/IntegrationsUnitListDialogHeader'
import UnitsToImportButton from './components/UnitsToImportButton'

interface IntegrationsUnitListDialogProps {
    integration: IntegrationType
}

const IntegrationsUnitListDialog = ({ integration }: IntegrationsUnitListDialogProps) => {
    const [open, setOpen] = useState(false)

    const onClose = () => {
        setOpen(false)
    }
    useSetBlocker(
        {
            close: onClose,
        },
        {
            isOpen: open,
        },
    )
    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                fullScreen
            >
                <IntegrationsUnitList
                    HeaderComponent={IntegrationsUnitListDialogHeader}
                    dialog
                    provider={integration.provider}
                    onClose={onClose}
                />
            </Dialog>
            <UnitsToImportButton onClick={() => setOpen(true)}>
                {integration.vehiclesSynced}
            </UnitsToImportButton>
        </>
    )
}

export default IntegrationsUnitListDialog
