import { type FC, type ReactElement, type ReactNode } from 'react'

import { type DataType } from 'csstype'

import { styled } from 'lib'
import { Box } from 'ui'

interface Props extends RowProps {
    label: ReactElement
    children: ReactNode
}

const InfoCardRowBase: FC<Props> = ({ label, children, align }) => {
    return (
        <Row align={align}>
            <Col>{label}</Col>
            <Box
                display="flex"
                justifyContent="flex-end"
            >
                <Col
                    sx={{
                        '&:empty::before': {
                            content: '"-"',
                            display: 'inline-block',
                        },
                    }}
                >
                    {children}
                </Col>
            </Box>
        </Row>
    )
}

export default InfoCardRowBase

interface RowProps {
    align?: 'top' | 'middle'
    children: ReactNode
}

const Row = styled('div', {
    shouldForwardProp: (prop: keyof RowProps) => prop !== 'align',
})<RowProps>`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: ${({ align }) => rowAlign[align || 'middle']};
    gap: 10px;
    ${({ theme }) => theme.typography.body2};
    color: ${({ theme }) => theme.palette.text.primary};
`

const rowAlign = {
    top: 'flex-start',
    middle: 'center',
} satisfies { [key in RowProps['align']]: DataType.SelfPosition }

const Col = styled('div')`
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
`
