import { type FC } from 'react'

import { TextInput } from 'components'
import { validateMaxLength } from 'core'

import { addressFields } from '../fields'

interface Props {
    disabled?: boolean
}

const ZipInput: FC<Props> = ({ disabled }) => {
    return (
        <TextInput
            source={addressFields.zipCode.source}
            label={addressFields.zipCode.label}
            validate={zipValidation}
            disabled={disabled}
        />
    )
}

export default ZipInput

const zipValidation = validateMaxLength(10)
