import { type FC } from 'react'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import { InfoCardRowBase, MoneyField } from 'components'
import { type InvoiceFee } from 'resources/invoices'

import ItemLabel from '../ItemLabel'

import ManageFeeDrawerToggler from './ManageFeeDrawerToggler'

const FeeRow: FC<{ fee: InvoiceFee; invoiceId: Identifier; readOnly: boolean }> = ({
    invoiceId,
    fee,
    readOnly,
}) => {
    return (
        <InfoCardRowBase
            label={
                <ManageFeeDrawerToggler
                    id={fee.id}
                    invoiceId={invoiceId}
                    readOnly={readOnly}
                >
                    {(open) => (
                        <ItemLabel
                            Icon={Icons.Fee}
                            onClick={open}
                        >
                            {fee.name}
                        </ItemLabel>
                    )}
                </ManageFeeDrawerToggler>
            }
        >
            <MoneyField value={fee.price} />
        </InfoCardRowBase>
    )
}

export default FeeRow
