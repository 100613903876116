import copy from 'copy-to-clipboard'

import Icons from 'assets/icons'
import { useNotify } from 'core/notifications'

import { type ActionChildren } from './Actions'

interface ExportActionProps {
    children: ActionChildren
    copyMessage: string
    title: string
}

export const CopyAction = ({ children, copyMessage, title }: ExportActionProps) => {
    const notify = useNotify()

    return children({
        Icon: Icons.ContentCopyOutlined,
        title,
        onClick: () => {
            copy(copyMessage || '')
            notify('Copy to clipboard')
        },
    })
}

export const copyAction = (params: ExportActionProps) => (
    <CopyAction
        {...params}
        key={'copy-to-clipboard-' + params.copyMessage}
    />
)
