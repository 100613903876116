import { type FC } from 'react'

import { useShowContext } from 'react-admin'

import { CardDefaultSkeleton, InfoCard, InfoCardDetails } from 'components'
import { type InvoiceModel } from 'resources/invoices'
import { ptLaborFields, ptPartFields, ptServiceFields, ptTaxFields } from 'resources/pricingTiers'

const CustomerPriceTiersCard: FC = () => {
    const { record, isLoading } = useShowContext<InvoiceModel>()

    if (isLoading) {
        return <CardDefaultSkeleton />
    }

    const tiers = record.pricingTiers
    return (
        <InfoCard>
            <InfoCardDetails
                details={[
                    {
                        label: 'Parts',
                        source: () => ptPartFields.self.value(tiers.partTier),
                    },
                    {
                        label: 'Labor rate',
                        source: () => ptLaborFields.self.value(tiers.laborTier),
                    },
                    {
                        label: 'Service',
                        source: () => ptServiceFields.self.value(tiers.serviceTier),
                    },
                    {
                        label: 'Fees',
                        source: () => tiers.feeTiers,
                    },
                    {
                        label: 'Taxes',
                        source: () => ptTaxFields.self.value(tiers.taxTier),
                    },
                ]}
            />
        </InfoCard>
    )
}

export default CustomerPriceTiersCard
