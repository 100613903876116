import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import { Header, InnerHeader, ShowHeaderSkeleton, formatMoney, type TabType } from 'components'
import { type Action, deleteOneAction } from 'core/actions'
import {
    deleteInvoiceParams,
    invoiceFields,
    InvoiceStatusKeys,
    InvoiceTypeKeys,
    type InvoiceModel,
} from 'resources/invoices'
import { AutoGrid, InfoBadge } from 'ui'

import CloseInvoiceButton from './CloseInvoiceButton'
import { CancelAction, ReopenAction, ResetAction, SendInvoiceAction } from './actions'

const actions: Action<InvoiceModel> = (record, { children }) => {
    return [
        record.type === InvoiceTypeKeys.WORK_ORDER ? (
            <ResetAction
                disabled={record.status !== InvoiceStatusKeys.OPEN}
                record={record}
                key="reset"
                children={children}
            />
        ) : undefined,
        children({
            Icon: Icons.Pdf,
            title: 'Export PDF',
            key: 'export-pdf',
        }),
        <SendInvoiceAction
            disabled={record.status === InvoiceStatusKeys.CANCELED}
            key="sendInvoice"
            record={record}
            children={children}
        />,
        record.status === InvoiceStatusKeys.CLOSED ? (
            <ReopenAction
                record={record}
                key="reopen"
                children={children}
            />
        ) : undefined,
        <CancelAction
            record={record}
            disabled={record.status !== InvoiceStatusKeys.OPEN}
            key="cancel"
            children={children}
        />,
        deleteOneAction({
            id: record.id,
            children,
            ...deleteInvoiceParams(record),
            disabled: record.status !== InvoiceStatusKeys.OPEN,
        }),
    ]
}
const InvoiceShowHeader = ({ tabs }: { tabs: TabType[] }) => {
    const record = useRecordContext<InvoiceModel>()

    if (!record) {
        return <ShowHeaderSkeleton />
    }

    return (
        <InnerHeader
            tabs={tabs}
            tabWidth="186px"
            actions={actions}
            mainAction={<CloseInvoiceButton />}
        >
            <Header.Info
                avatar={
                    <Header.Avatar defaultImage={<invoiceFields.avatar.Icon record={record} />} />
                }
            >
                <Header.Content>
                    <Header.Title>{record.number}</Header.Title>
                </Header.Content>
                <Header.Content mobilePlacement="bottom">
                    <AutoGrid.Row>
                        <AutoGrid.Column>{invoiceFields.status.value(record)}</AutoGrid.Column>
                        <AutoGrid.Column>
                            <InfoBadge badgeContent={formatMoney(record.total)} />
                        </AutoGrid.Column>
                    </AutoGrid.Row>
                </Header.Content>
            </Header.Info>
        </InnerHeader>
    )
}

export default InvoiceShowHeader
