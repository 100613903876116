import { phoneMaskResolver } from 'components'
import { type ShopModel } from 'resourcesBase'

const source = 'phone' as const satisfies keyof ShopModel
const label = 'Phone Number'

interface Data extends Pick<ShopModel, 'phone'> {}

const value = ({ phone }: Data) => {
    if (!phone) {
        return ''
    }

    return phoneMaskResolver(phone)
}

const phoneNumber = {
    source,
    label,
    value,
}

export default phoneNumber
