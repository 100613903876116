import Icons from 'assets/icons'
import { CardCollapsibleScrollbar, CollapsibleContent, CollapsibleIconHolder } from 'components'
import { alpha, styled, formatDate, globalClassNames } from 'lib'
import {
    SvgIcon,
    Typography,
    BoxContainer,
    StatusText,
    type BoxProps,
    Box,
    Divider,
    Chip,
    Stack,
} from 'ui'

import { noteSourceMap } from '../constats'

import type NoteModel from '../models/NoteModel'

interface NoteProps extends BoxProps, NoteModel {}
export type NoteType = {
    created?: string
    text: string
    createdByData: {
        name?: string
        email?: string
    }
    source: 'DEFECT' | 'MANUAL' | 'PM_SCHEDULE' | 'ISSUE'
}
interface NoteProps extends BoxProps, NoteType {}
const Note = styled(({ text, created, createdByData, source, ...props }: NoteProps) => {
    const authorName = createdByData?.name || createdByData?.email
    return (
        <Stack
            gap="4px"
            {...props}
        >
            <Box
                display="flex"
                gap="10px"
                alignItems="flex-start"
            >
                <Box
                    flexGrow={1}
                    display="flex"
                    flexDirection="column"
                    gap="4px"
                    overflow="hidden"
                >
                    {authorName && (
                        <Typography
                            variant="chartTitle"
                            justifyContent="space-between"
                            lineHeight="120%"
                            className={globalClassNames.ellipsis}
                        >
                            {authorName}
                        </Typography>
                    )}
                    {created ? (
                        <Typography
                            variant="chartLabel"
                            component={BoxContainer}
                            color="text.secondary"
                        >
                            <SvgIcon
                                component={Icons.CalendarTodayOutlined}
                                sx={{
                                    width: '10px',
                                    height: '10px',
                                    marginRight: '4px',
                                }}
                            />
                            {formatDate(created, (dateFormats) => dateFormats.fullDateTime)}
                        </Typography>
                    ) : null}
                </Box>
                {noteSourceMap[source] ? <StatusText>{noteSourceMap[source]}</StatusText> : null}
            </Box>
            <Typography variant="chartsBody">{text}</Typography>
        </Stack>
    )
})`
    padding: 12px;
    gap: 4px;
    border-radius: 8px;
    background: ${({ theme }) => alpha(theme.palette.info.main, 0.12)};
    &:nth-last-of-type(even) {
        background: ${({ theme }) => alpha(theme.palette.text.primary, 0.06)};
    }
`
interface NotesProps extends BoxProps {
    notes: NoteModel[]
}
export const Notes = ({ notes, ...rest }: NotesProps) => {
    return (
        <Stack
            gap="10px"
            {...rest}
        >
            {notes.map((obj, i) => (
                <Note
                    {...obj}
                    key={i}
                />
            ))}
        </Stack>
    )
}
export const NotesCollapsibleContent = ({ notes, ...rest }: NotesProps) => {
    return (
        <CollapsibleContent name="notes">
            <Stack mt="15px">
                <Typography
                    gap="8px"
                    component={BoxContainer}
                    variant="chartTitle"
                    mb="10px"
                >
                    Notes
                    <Chip
                        component="span"
                        label={notes.length}
                        sx={(theme) => ({
                            fontSize: '12px',
                        })}
                        variant="filled"
                        size="extraSmall"
                    />
                </Typography>
                <Divider />
                <CardCollapsibleScrollbar>
                    <Notes notes={notes} />
                </CardCollapsibleScrollbar>
            </Stack>
        </CollapsibleContent>
    )
}
interface NotesCollapsibleContentProps {
    notes: NoteModel[]
}
export const NotesCollapsibleIconHolder = ({ notes }: NotesCollapsibleContentProps) => {
    return (
        <CollapsibleIconHolder
            name="notes"
            title={`Notes (${notes.length})`}
            disabled={!notes.length}
            titleOnDisabled="No Notes Added"
        >
            <Icons.CommentOutlined fontSize="small" />
        </CollapsibleIconHolder>
    )
}

export default Note
