import { styled } from 'lib'
import { Box } from 'ui'

const ListItem = styled(Box)`
    justify-content: space-between;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    & > * {
        margin-top: 0px;
        margin-bottom: 0px;
    }
`

export default ListItem
