import { type FC } from 'react'

import { ViewHeader } from 'components'
import { PageContent } from 'ui'

import { DirectlyAddedSection, TotalSection, WoItemsSection } from './sections'

const InvoiceLineItems: FC = () => {
    return (
        <PageContent>
            <ViewHeader title="Line Items" />
            <WoItemsSection />
            <DirectlyAddedSection />
            <TotalSection />
        </PageContent>
    )
}

export default InvoiceLineItems
