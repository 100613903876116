import { type FC } from 'react'

import {
    EditButton,
    InfoCard,
    InfoCardHeader,
    InfoCardRowBase,
    InfoCardRows,
    InfoCardTitle,
    MoneyField,
} from 'components'
import { InvoiceStatusKeys, type InvoiceModel } from 'resources/invoices'
import { Divider } from 'ui'

import ManageTotalDrawerToggler from './ManageTotalDrawerToggler'
import { FeeRow } from './fees'
import { TaxRow } from './taxes'

interface TotalCardProps
    extends Pick<
        InvoiceModel,
        | 'fees'
        | 'taxes'
        | 'total'
        | 'totalFees'
        | 'totalTax'
        | 'id'
        | 'status'
        | 'removeFees'
        | 'removeTaxes'
    > {}
const TotalCard: FC<TotalCardProps> = ({
    totalTax,
    totalFees,
    fees,
    taxes,
    total,
    id,
    status,
    removeFees,
    removeTaxes,
}) => {
    const readOnly = status !== InvoiceStatusKeys.OPEN
    return (
        <InfoCard>
            <InfoCardHeader
                title="Total"
                action={
                    <ManageTotalDrawerToggler
                        readOnly={readOnly}
                        id={id}
                        defaultValues={{
                            removeFees,
                            removeTaxes,
                        }}
                    >
                        {(open) => <EditButton onClick={open} />}
                    </ManageTotalDrawerToggler>
                }
            />
            <InfoCardRowBase label={<InfoCardTitle>Subtotal</InfoCardTitle>}>
                <MoneyField value={total - totalFees - totalTax} />
            </InfoCardRowBase>
            <InfoCardRows>
                {fees.map((fee) => (
                    <FeeRow
                        key={fee.id}
                        readOnly={readOnly}
                        invoiceId={id}
                        fee={fee}
                    />
                ))}
                {taxes.map((tax) => (
                    <TaxRow
                        key={tax.id}
                        readOnly={readOnly}
                        tax={tax}
                        invoiceId={id}
                    />
                ))}
            </InfoCardRows>
            <Divider />
            <InfoCardRows offsetBottom="0">
                <InfoCardRowBase label={<InfoCardTitle>Grand Total</InfoCardTitle>}>
                    <InfoCardTitle>
                        <MoneyField value={total} />
                    </InfoCardTitle>
                </InfoCardRowBase>
            </InfoCardRows>
        </InfoCard>
    )
}

export default TotalCard
