import { type MemberModel } from 'resourcesBase'
import { capitalize } from 'utils'

interface Data extends Pick<MemberModel, 'roleData'> {}

const value = (data: Data) => {
    const role = data.roleData?.label
    if (!role) {
        return ''
    }

    return capitalize(role)
}

const source = 'role' as const satisfies keyof MemberModel

const role = {
    source,
    label: 'User Role',
    value,
}

export default role
