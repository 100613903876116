type FuncReturnType<T> = {
    listData: T[]
    positiveDataTotal: number
    top3Percentage: number
    top3Total: number
    uncategorizedInTop3: boolean
}

// Extract into different functions
const roundTop3WidgetData: <
    T extends { id: string | number; totalPercent: number; total: number; [key: string]: any },
>(
    a1: T[],
    options?: {
        total?: number
        allowNegative?: boolean
    },
) => FuncReturnType<T> = (initialArr, options = { total: 0, allowNegative: false }) => {
    const finalData = [...initialArr]

    if (!finalData.length || finalData[0].total === null) {
        return {
            listData: [],
            positiveDataTotal: null,
            top3Percentage: null,
            top3Total: null,
            uncategorizedInTop3: null,
        }
    }
    const positiveDataTotal = finalData.reduce(
        (sum, obj) => (obj.total > 0 ? (sum += obj.total) : sum),
        options.total || 0,
    )

    let top3Total = 0
    let top3Percentage = 0

    let uncategorizedInTop3 = false
    const convertedPositiveData = finalData.map((item, i) => {
        let totalPercent = item.totalPercent
        if (item.totalPercent >= 0) {
            totalPercent = (item.total / positiveDataTotal) * 100
        }
        if (i <= 2) {
            top3Total += Math.abs(item.total)
            if (item.id === 'uncategorized') {
                uncategorizedInTop3 = true
            }
            if (options.allowNegative) {
                top3Percentage += item.totalPercent
            }
        }
        return { ...item, totalPercent }
    })

    let percentSum = 0
    const roundedPercents = convertedPositiveData
        .map(({ id, totalPercent }) => {
            const value = Math.floor(totalPercent)
            if (value >= 0) {
                percentSum += value
            }
            const index = finalData.findIndex((currentObj) => currentObj.id === id)
            convertedPositiveData[index] = {
                ...convertedPositiveData[index],
                totalPercent: value,
            }
            return { id, value, decimals: totalPercent % 1 }
        })
        .sort((a, b) => b.decimals - a.decimals)
    let percentLeft = 100 - percentSum

    // Round percentages ordered by decimal
    let i = 0
    while (percentLeft > 0) {
        const obj = roundedPercents[i]

        if (!obj?.value) {
            break
        }
        const index = finalData.findIndex((currentObj) => currentObj.id === obj.id)

        const totalPercent = percentLeft > 0 ? obj.value + 1 : obj.value

        convertedPositiveData[index] = {
            ...convertedPositiveData[index],
            totalPercent,
        }
        percentLeft -= 1
        i += 1
    }
    // Extract the sum percentage of the top 3 objects by total

    i = 0
    while (i <= 2 && !options.allowNegative) {
        const obj = convertedPositiveData[i]
        if (obj && obj.total >= 0) {
            top3Percentage += obj.totalPercent
        }
        i += 1
    }

    return {
        listData: convertedPositiveData.slice(0, 3),
        positiveDataTotal,
        top3Percentage: top3Percentage === 0 ? null : Math.round(top3Percentage),
        top3Total,
        uncategorizedInTop3,
    }
}
export default roundTop3WidgetData
