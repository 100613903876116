import { type FC } from 'react'

import { emailValidation, parseEmail, validateEmail } from 'core'

import TextInput from './TextInput'

interface EmailInputProps {
    source?: string
    label?: string
    required?: boolean
    defaultValue?: string
    disabled?: boolean
}

const EmailInput: FC<EmailInputProps> = ({
    source = 'email',
    label = 'Email',
    required = false,
    disabled = false,
    defaultValue,
}) => {
    return (
        <TextInput
            type="email"
            source={source}
            label={label}
            parse={parseEmail}
            validate={required ? requiredValidation : emailValidation}
            defaultValue={defaultValue}
            disabled={disabled}
        />
    )
}

export default EmailInput

const requiredValidation = validateEmail(true)
