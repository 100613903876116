import { type FC } from 'react'

import { ViewHeader } from 'components'
import { Section } from 'ui'

import CustomerPriceTiersCard from './CustomerPriceTiersCard'

const CustomerPriceTierSection: FC = () => {
    return (
        <Section>
            <ViewHeader title="Customer Price Tiers" />
            <CustomerPriceTiersCard />
        </Section>
    )
}

export default CustomerPriceTierSection
