import {
    SelectInput as RASelectInput,
    type SelectInputProps as RASelectInputProps,
    type UseInputValue,
} from 'react-admin'

import { type DataRecord } from 'appTypes'
import { emptySelectValue } from 'core/data'
import { useCreateInputId } from 'core/form'
import { styled } from 'lib'
import { ClearAdornment } from 'ui'
import { propertyToText } from 'utils'

const StyledRASelectInput = styled(RASelectInput)`
    margin-top: 0px;
`
export interface SelectInputProps<FormType extends DataRecord = DataRecord>
    extends Omit<RASelectInputProps, 'source' | 'optionText'> {
    disableEmptyValue?: boolean
    clearable?: boolean
    source?: Extract<keyof FormType, string>
    multiple?: boolean
    optionText?: string | ((choice: DataRecord) => React.ReactNode)
}

export interface SelectInputPropsExtended extends SelectInputProps {
    inputField: UseInputValue
}

const SelectInputBase = ({
    disableEmptyValue,
    clearable,
    format,
    multiple,
    inputField,
    ...rest
}: SelectInputPropsExtended) => {
    const createId = useCreateInputId()

    return (
        <StyledRASelectInput
            // @ts-ignore
            clearAlwaysVisible={false}
            fullWidth
            size="medium"
            emptyValue={disableEmptyValue ? emptySelectValue : ''}
            variant="outlined"
            format={(value) => {
                const v = format ? format(value) : value
                return v ? v : ''
            }}
            {...rest}
            SelectProps={{
                ...rest.SelectProps,
                MenuProps: {
                    ...rest.SelectProps?.MenuProps,
                    MenuListProps: {
                        'aria-label': `Options for ${propertyToText(rest.source)}`,
                        ...rest.SelectProps?.MenuProps?.MenuListProps,
                    },
                },
            }}
            id={createId({ source: rest.source, id: rest.id })}
            {...(!rest.disabled &&
                (clearable ?? !inputField.isRequired) &&
                Boolean(inputField.field.value) && {
                    InputProps: {
                        ...rest.InputProps,
                        endAdornment: (
                            <ClearAdornment
                                className="show-on-hover"
                                onClick={() => inputField.field.onChange('')}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    right: '29px',
                                    transform: 'translate(0px,-50%)',
                                }}
                            />
                        ),
                    },
                })}
        />
    )
}

export default SelectInputBase
