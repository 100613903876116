import { useShowContext } from 'react-admin'

import { InfoCard, type InfoCardDetailType, InfoCardDetails, InfoCardHeader } from 'components'
import { formatDate } from 'lib'
import { poFields, type PurchaseOrderModel } from 'resources/purchaseOrders'
import { ShopLink } from 'resources/shops'

const PODetailsCard = () => {
    const { record } = useShowContext<PurchaseOrderModel>()

    const details: InfoCardDetailType<PurchaseOrderModel>['details'] = [
        {
            label: 'Repair Shop',
            source: ({ shopData }) => <ShopLink id={shopData.id}>{shopData.name}</ShopLink>,
        },
        { label: 'PO Type', source: ({ type }) => poFields.type.config[type] },
        {
            label: record.cancellationReason ? 'Canceled on' : 'Created on',
            source: ({ created, canceledOn }) =>
                formatDate(
                    record.cancellationReason ? canceledOn : created,
                    'MMM dd yyyy h:mm aaa',
                ),
        },
        record.closedOn
            ? {
                  label: 'Closed on',
                  source: ({ closedOn }) => formatDate(closedOn, 'MMM dd yyyy h:mm aaa'),
              }
            : undefined,
        {
            label: 'Description',
            source: 'description',
        },
    ]

    return (
        <InfoCard>
            <InfoCardHeader title="Basic Details" />
            <InfoCardDetails details={details.filter(Boolean)} />
        </InfoCard>
    )
}

export default PODetailsCard
