import { markup } from './markup'
import self from './self'
import tierName from './tierName'

const ptServiceFields = {
    tierName,
    markup,
    self,
}

export default ptServiceFields
