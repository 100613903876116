import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import { type FormProps, UtilityDrawerForm } from 'components'
import { type AuthStore, useAuthProvider, parseNumber, useSubmit, updatedMessage } from 'core'

export const LimitFormWrapper: FC<FormProps> = inject('auth')(
    observer(({ auth, ...props }: FormProps & { auth?: AuthStore }) => {
        const authProvider = useAuthProvider()
        const billing = auth.billing

        const submit = useSubmit<{ amount: string }>(
            async ({ amount }) => {
                await authProvider.setPaymentLimit(parseNumber(amount) as number)
            },
            {
                successMessage: updatedMessage,
            },
        )

        return (
            <UtilityDrawerForm
                {...props}
                onSubmit={submit}
                record={{ amount: billing.paymentLimit }}
            />
        )
    }),
)
