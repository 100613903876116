import { useListContext, useShowContext } from 'react-admin'

import Icons from 'assets/icons'
import { BoxLink, SliderView, ViewHeader } from 'components'
import { Link } from 'lib'
import { PurchaseOrderCardSkeleton } from 'pages/PurchaseOrders/components'
import { type PurchaseOrderModel } from 'resources/purchaseOrders'
import { IconBox, Section } from 'ui'

import NoLineItemsCard from './NoLineItemsCard'
import OnOrderCard from './OnOrderCard'
import ReceiptCard from './ReceiptCard'

export const LineItemsSectionSkeleton = () => {
    return (
        <SliderView
            items={new Array(2).fill(0).map((v, i) => (
                <PurchaseOrderCardSkeleton
                    key={i}
                    height="270px"
                />
            ))}
        />
    )
}

const LineItemsSection = () => {
    const { record } = useShowContext<PurchaseOrderModel>()
    const { data, isLoading, perPage } = useListContext()

    if (!record || isLoading) {
        return <LineItemsSectionSkeleton />
    }

    const slides = [
        record.status !== 'CLOSED' && <OnOrderCard total={record.costOnOrder} />,
        ...(data?.map((receipt) => <ReceiptCard receipt={receipt} />) || []),
        perPage === data?.length && (
            <BoxLink
                to="line-items"
                label="SEE ALL LINE ITEMS"
                icon={<Icons.ArrowForward />}
            />
        ),
    ]

    return (
        <Section>
            <ViewHeader
                title="Line Items"
                loading={!record}
            >
                <ViewHeader.Content placement="rightMobile">
                    <Link to="line-items">
                        <IconBox title="See All">
                            <Icons.GridView />
                        </IconBox>
                    </Link>
                </ViewHeader.Content>
            </ViewHeader>
            {record.items || record.dateLastReceived ? (
                <SliderView items={slides.filter(Boolean)} />
            ) : (
                <NoLineItemsCard />
            )}
        </Section>
    )
}

export default LineItemsSection
