import { type FC } from 'react'

import { type Identifier } from 'appTypes'
import {
    formatMoney,
    InfoCard,
    InfoCardDetails,
    type InfoCardDetailType,
    InfoCardHeader,
    LinkArrowButtonBase,
} from 'components'
import { ck33Fields, type CK33Model } from 'resources/vmrs'
import { Anchor } from 'ui'

interface ComponentCardProps {
    total: number
    record: CK33Model
    onComponentClick: (component: Identifier) => void
}

const ComponentCard: FC<ComponentCardProps> = ({ total, record, onComponentClick }) => {
    const selectComponent = () => {
        onComponentClick(record.id)
    }

    return (
        <InfoCard>
            <InfoCardHeader
                action={<LinkArrowButtonBase onClick={selectComponent} />}
                title={
                    <Anchor
                        variant="h6"
                        onClick={selectComponent}
                    >
                        {formatMoney(total)}
                    </Anchor>
                }
            />
            <InfoCardDetails
                details={details}
                propRecord={record}
            />
        </InfoCard>
    )
}

export default ComponentCard

const details: InfoCardDetailType<CK33Model>['details'] = [
    { label: 'Component Code', source: (record) => ck33Fields.self.formatCode(record.code, 2) },
    { label: 'Description', source: 'text' },
]
