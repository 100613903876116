import { type FC } from 'react'

import Icons from 'assets/icons'
import { Link } from 'lib'
import { IconBox, IconElement } from 'ui'

export const LinkArrowButtonBase: FC<{ onClick?: () => void }> = ({ onClick }) => (
    <IconBox
        onClick={onClick}
        sx={{
            width: '24px',
            height: '24px',
        }}
    >
        <IconElement component={Icons.ArrowForward} />
    </IconBox>
)

export const LinkArrowButton: FC<{ path: string }> = ({ path }) => {
    return (
        <Link to={path}>
            <LinkArrowButtonBase />
        </Link>
    )
}
