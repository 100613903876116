import { useState } from 'react'

import { useResourcePreferences } from 'core/context'

interface Props {
    columns: { field: string }[]
    disableColumnReorder: boolean
}

const useOrdering = ({
    columns,
    disableColumnReorder,
}: Props): {
    order: string[] | null
    updateOrder: (fields: string[]) => void
} => {
    const preferences = useResourcePreferences()
    const [order, setOrder] = useState<string[]>(
        () => preferences.value.order || columns.map((col) => col.field),
    )

    const updateOrder = (fields: string[]) => {
        setOrder(fields)
        preferences.updateLocal('order', fields)
        preferences.syncLocal()
    }

    return { order: disableColumnReorder ? null : order, updateOrder }
}

export default useOrdering
