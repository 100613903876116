import { defaultStatuses, options, type StatusConfig } from 'resources/common'

import { type IssueModel, IssueStatusKeys } from '../types'

const source = 'status' as const satisfies keyof IssueModel

const getConfig = options.createConfig<StatusConfig<IssueStatusKeys>>([
    { ...defaultStatuses.open, id: IssueStatusKeys.OPEN },
    { ...defaultStatuses.inProgress, id: IssueStatusKeys.IN_PROGRESS },
    { ...defaultStatuses.closed, id: IssueStatusKeys.CLOSED },
])

const value = options.makeIconValue(getConfig)

const status = {
    source,
    label: 'Status',
    getConfig,
    value: (data: Pick<IssueModel, 'status'>) => value(data?.status),
}

export default status
