import { type FC } from 'react'

import Icons from 'assets/icons'

import { InvoiceTypeKeys, type InvoiceModel } from '../types'

const source = 'avatar' as keyof InvoiceModel
const label = 'Avatar'

interface Data extends Pick<InvoiceModel, 'type'> {}

const getIcon = (record: Data) =>
    record.type === InvoiceTypeKeys.WORK_ORDER ? Icons.WoInvoice : Icons.ReceiptOutlined

const Icon: FC<{ record: Data }> = ({ record }) => {
    const IconView = getIcon(record)

    return <IconView />
}

const avatar = {
    BaseIcon: Icons.ReceiptOutlined,
    getIcon,
    Icon,
    source,
    label,
}

export default avatar
