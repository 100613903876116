import { type FC } from 'react'

import DrawerJobCard from './DrawerJobCard'
import {
    type GetJobsModifiers,
    ComponentHeader,
    Section,
    useDrilldownContext,
    useDrilldownDrawer,
} from './Drilldown'

export const useCrossDrilldown = () => {
    const open = useDrilldownDrawer({
        title: 'Cross Drilldown',
        renderBelowHeader: () => <ComponentHeader />,
        renderContent: () => <Content />,
    })

    return (filter: Required<GetJobsModifiers['filter']>) => {
        open({ filter })
    }
}

const Content: FC = () => {
    const { data } = useDrilldownContext()

    return (
        <Section.Cards>
            {data.map((job) => (
                <DrawerJobCard
                    job={job}
                    key={job.id}
                />
            ))}
        </Section.Cards>
    )
}
