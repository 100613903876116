import { useParams } from 'lib'

import IntegrationsUnitList from './IntegrationsUnitList'
import IntegrationsUnitListHeader from './components/IntegrationsUnitListHeader'

const IntegrationsUnitPage = () => {
    const { provider } = useParams()

    return (
        <IntegrationsUnitList
            provider={provider}
            HeaderComponent={IntegrationsUnitListHeader}
        />
    )
}

export default IntegrationsUnitPage
