import { formatDate } from 'lib'
import { type IntegrationType } from 'resources/telematics'
import { ConnectedUnitStatus } from 'resources/units'
import { Box, BoxContainer, Typography } from 'ui'

import NewUnits from './NewUnits'

const ConnectedCardContent = ({ integration }: { integration: IntegrationType }) => {
    const {
        connectionTimestamp: date,
        vehiclesNotSynced,
        vehiclesSynced,
        vehiclesTotal,
        vehiclesWithError,
        providerName,
    } = integration
    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap="10px"
                my="10px"
            >
                <Typography
                    color="text.disabled"
                    textTransform="uppercase"
                    variant="tooltip"
                >
                    Last Synced
                </Typography>
                <Typography
                    textAlign="right"
                    color="text.primary"
                    variant="body2"
                >
                    {formatDate(date, (dateFormats) => dateFormats.shortenedDateTime)}
                </Typography>
            </Box>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap="10px"
                my="10px"
            >
                <Typography
                    color="text.disabled"
                    textTransform="uppercase"
                    variant="tooltip"
                >
                    Units from {providerName}
                </Typography>
                <Typography
                    textAlign="right"
                    color="text.primary"
                    variant="body1"
                >
                    {vehiclesTotal}
                </Typography>
            </Box>
            <BoxContainer
                justifyContent="flex-end"
                gap="16px"
            >
                <ConnectedUnitStatus type="sync">{vehiclesSynced}</ConnectedUnitStatus>
                <ConnectedUnitStatus type="error">{vehiclesWithError}</ConnectedUnitStatus>
                <BoxContainer gap="4px">
                    <ConnectedUnitStatus type="notImported">
                        {vehiclesNotSynced}
                    </ConnectedUnitStatus>
                    <NewUnits integration={integration} />
                </BoxContainer>
            </BoxContainer>
        </>
    )
}

export default ConnectedCardContent
