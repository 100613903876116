import { QueryClient } from 'react-query'

const queryClientProvider = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
            queryFn: () => {
                /* hack for WO redirect */
            },
            cacheTime: 1000 * 60 * 4,
        },
    },
})

export default queryClientProvider
