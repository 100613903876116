import { type FC } from 'react'

import { useListContext, useShowContext } from 'react-admin'

import Icons from 'assets/icons'
import { SliderView, BoxLink } from 'components'
import { ActionsMenu } from 'core/actions'
import { useCreateResourcePath } from 'core/resource'
import { ArchivedAction } from 'pages/Units/components'
import { type UnitModel, type MeterModel, isUnitArchived } from 'resources/units'

import { metersActions } from '../../Meters/actions'
import { UnitMetersEmpty, UnitMetersCard, UnitMetersSectionTitle } from '../../Meters/components'

import UnitMetersFallback from './UnitMetersFallback'

const UnitMeters: FC = () => {
    const { data = [], total, isLoading } = useListContext<MeterModel>()
    const { record: unit } = useShowContext<UnitModel>()
    const isArchived = isUnitArchived(unit)

    const action = isArchived ? (
        <ArchivedAction to="meters" />
    ) : (
        <ActionsMenu actions={metersActions} />
    )

    const createPath = useCreateResourcePath()
    if (isLoading) {
        return <UnitMetersFallback />
    }
    let content

    if (total) {
        const items = data.map((meter) => <UnitMetersCard meter={meter} />)
        if (total >= 4) {
            const path = createPath({
                type: 'list',
            })
            items.push(
                <BoxLink
                    to={path}
                    label="SEE ALL METERS"
                    icon={<Icons.ArrowForward />}
                />,
            )
        }
        content = <SliderView items={items} />
    } else {
        content = <UnitMetersEmpty />
    }

    return (
        <div>
            <UnitMetersSectionTitle action={action} />
            {content}
        </div>
    )
}

export default UnitMeters
