import { useListContext } from 'react-admin'

import { ViewHeader } from 'components'
import { formatDate } from 'lib'
import { type ReceiptModel } from 'resources/purchaseOrders'
import { InfoBadge } from 'ui'

const ClosedPOReceiptHeader = ({ receiptData }: { receiptData: ReceiptModel }) => {
    const { data, total } = useListContext()
    const receiptIndex = data.findIndex(({ id }) => id === receiptData.id)
    return (
        <ViewHeader title={`Receipt ${total - receiptIndex}`}>
            <InfoBadge
                badgeContent={`Closed on ${formatDate(
                    receiptData.created,
                    () => 'MMM dd yyyy h:mm aaa',
                )}`}
            />
        </ViewHeader>
    )
}

export default ClosedPOReceiptHeader
