import { type FC } from 'react'

import { SvgIcon, ToggleButton, ToggleButtonGroup } from 'ui'

import { useDialogSelectorContext } from './DialogSelectorContext'

export interface DialogSelectorFilterButtonsProps {
    makeFilters?: (filters: Record<string, any>) => Record<string, any>
    source: string
    items: {
        value: string | boolean
        Icon: FC
    }[]
}

const DialogSelectorFilterButtons = ({
    items,
    source,
    makeFilters,
}: DialogSelectorFilterButtonsProps) => {
    const { control, defaultFilter } = useDialogSelectorContext()
    const { setFilters, filterValues, isFetching } = control

    const handleChange = (event, value) => {
        // when clicked on same button - value is null
        if (value === null) {
            return
        }
        const filter = value ? { [source]: value } : defaultFilter
        setFilters(makeFilters ? makeFilters(filter) : filter, {}, false)
    }

    const value = String(filterValues[source] || '')

    return (
        <ToggleButtonGroup
            size="small"
            exclusive
            onChange={handleChange}
            value={value}
            disabled={isFetching}
        >
            {items.map((item) => (
                <ToggleButton
                    size="small"
                    value={String(item.value)}
                    key={String(item.value)}
                >
                    <SvgIcon
                        inheritViewBox
                        component={item.Icon}
                    />
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    )
}

export default DialogSelectorFilterButtons
