import { type FC } from 'react'

import { Navigate, useLocation } from 'react-router-dom'

export const Redirect: FC<{ to: string }> = ({ to }) => {
    const location = useLocation()

    return <Navigate to={{ pathname: to, search: location.search }} />
}

export { Navigate }
