import { createResource } from 'core'

import { type CK15Model } from './types'

export const displayCK15 = (record: CK15Model) => `${record.code} ${record.description}`

export const ck15Resource = createResource({
    name: 'ck15',
    resource: 'vmrs/ck15',
})
