import { type ReactElement } from 'react'

import { useListContext } from 'react-admin'

import { type Identifier } from 'appTypes'
import {
    useOpenUtilityDrawer,
    UtilityDrawerEditor,
    type UtilityDrawerEditorProps,
} from 'components'
import { useModificationContext } from 'context'
import { useResource, type Serializer } from 'core'

import { type ContactModel } from '../types'

import { ContactForm, type ContactFormProps } from './ContactForm'

interface VendorContactDrawerTogglerProps extends Pick<UtilityDrawerEditorProps, 'onSuccess'> {
    children: (params: { onClick: () => void }) => ReactElement
    id?: Identifier
    contactFormProps?: ContactFormProps
}

export const ContactDrawerToggler = ({
    children,
    onSuccess,
    id,
    contactFormProps,
}: VendorContactDrawerTogglerProps) => {
    const open = useOpenUtilityDrawer()
    const resource = useResource()
    const listContext = useListContext()
    const modification = useModificationContext<ContactDrawerModifications>()

    return children({
        onClick: () => {
            open({
                extraArgs: {
                    id,
                    resource,
                    listContext,
                },
                drawerArgs: {
                    title: id ? 'Edit Contact Person' : 'Add Contact Person',
                    renderWrapper: (params) => (
                        <UtilityDrawerEditor
                            {...params}
                            onSuccess={onSuccess}
                            serializer={contactSerializer}
                        />
                    ),
                    renderContent: () => (
                        <ContactForm
                            {...modification('contactForm')}
                            {...contactFormProps}
                        />
                    ),
                    renderBottomRight: (renderButton) =>
                        renderButton({
                            label: id ? undefined : 'Add',
                        }),
                },
            })
        },
    })
}

export interface ContactDrawerModifications {
    contactForm: ContactFormProps
}

const contactSerializer: Serializer<ContactModel> = [
    'name',
    { name: 'email', parse: 'email' },
    'phone',
]
