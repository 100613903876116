import Icons from 'assets/icons'
import { Button } from 'ui'

import { type SyncedStateType, useSyncedStateContext } from '../../SyncedStateContext'

export interface FooterSaveButtonProps {
    onClick?: (synced: SyncedStateType) => void
    disabled?: boolean
    children?: React.ReactNode
    startIcon?: React.ReactNode
}

const FooterSaveButton = ({ onClick, disabled, children, startIcon }: FooterSaveButtonProps) => {
    const { synced } = useSyncedStateContext()

    return (
        <Button
            size="large"
            startIcon={startIcon || <Icons.Save />}
            variant="contained"
            color="primary"
            onClick={() => onClick?.(synced)}
            disabled={disabled}
            children={children || 'Save'}
        />
    )
}

export default FooterSaveButton
