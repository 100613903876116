import { type NoInfer } from 'appTypes'
import { type Column } from 'components'
import { DatagridLink } from 'components/Datagrid'
import { urls } from 'configs'
import { LinkButton } from 'ui'
import { pathJoin } from 'utils'

import { type WorkOrderModel } from '../types'

const source = 'number' satisfies keyof WorkOrderModel
const label = 'WO Number'

export const linkValue = (record: WorkOrderModel) => (
    <LinkButton to={pathJoin(urls.workOrders, record.id)}>{record.number}</LinkButton>
)

const tableColumn = <Source extends string = typeof source>({ id }: { id?: Source }) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: label,
        renderCell: (params) => <DatagridLink {...params} />,
    }) as const satisfies Column

const sort = <Source extends string = typeof source>() =>
    ({
        id: source as NoInfer<Source>,
        label,
    }) as const

export const number = {
    source,
    label,
    tableColumn,
    sort,
}
