import { type CK33Model } from '../types'

const source = 'text' as const satisfies keyof CK33Model
const label = 'Description'

const description = {
    source,
    label,
}

export default description
