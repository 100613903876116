import { useInventoryContext } from 'resources/inventory'
import { NotesTab } from 'resources/notes'

const PartNotes = () => {
    const inventoryView = useInventoryContext()

    return <NotesTab removeAddButton={inventoryView} />
}

export default PartNotes
