import { type FC } from 'react'

import { styled } from 'lib'

import { badgeClasses } from './Badge'
import InfoBadge, { type BadgeProps } from './InfoBadge'

interface Props extends BadgeProps {}

const ButtonBadge: FC<Props> = styled((props) => {
    return <InfoBadge {...props} />
})`
    & .${badgeClasses.badge} {
        background-color: ${({ theme }) => theme.palette.primary.main};
        color: ${({ theme }) => theme.palette.primary.contrastText};
        cursor: pointer;
    }
`

export default ButtonBadge
