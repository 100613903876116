import { calculateOnLabor } from './calculateOnLabor'
import { calculateOnParts } from './calculateOnParts'
import { calculateOnServices } from './calculateOnServices'
import { flatFee } from './flatFee'
import { maxAmount } from './maxAmoint'
import { minAmount } from './minAmount'
import { name } from './name'
import { percentage } from './percentage'
import { taxable } from './taxable'

const ptFeesFields = {
    name,
    taxable,
    flatFee,
    percentage,
    calculateOnParts,
    calculateOnLabor,
    calculateOnServices,
    minAmount,
    maxAmount,
}

export default ptFeesFields
