import { type FC } from 'react'

import { alpha, classes, styled } from 'lib'
import { menuItemClasses } from 'ui'

import { type ColorOption } from './types'

export const Option = styled('div')`
    border-radius: 50%;
    width: 24px;
    height: 24px;
`

export const SelectableOption: FC<{ option: ColorOption }> = ({ option }) => {
    return (
        <Option
            sx={{
                background: option.id,
                [`.${menuItemClasses.root}.${classes.selected} &`]: {
                    boxShadow: '0 0 0 4px ' + alpha(option.id, 0.25),
                },
            }}
        />
    )
}
