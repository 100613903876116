import Icons from 'assets/icons'
import { useTheme } from 'lib'
import { type DowntimeList } from 'resources/units'

import { WidgetPieChartBase } from '../../components'

interface DowntimeChartProps extends DowntimeList {
    disabled: boolean
}
const DowntimeChart = ({ EMERGENCY, NON_SCHEDULED, SCHEDULED, disabled }: DowntimeChartProps) => {
    const theme = useTheme()
    return (
        <WidgetPieChartBase
            disabled={disabled}
            icon={Icons.HistoryToggleOffOutlined}
            data={[
                {
                    id: 'Emergency',
                    value: EMERGENCY.percentOfTotalDowntime,
                    color: theme.palette.charts.red,
                },
                {
                    id: 'Non-Scheduled',
                    value: NON_SCHEDULED.percentOfTotalDowntime,
                    color: theme.palette.charts.yellowBody,
                },
                {
                    id: 'Scheduled',
                    value: SCHEDULED.percentOfTotalDowntime,
                    color: theme.palette.charts.greenBody,
                },
            ]}
        />
    )
}
export default DowntimeChart
