import { useListContext } from 'react-admin'

import Icons from 'assets/icons'
import { IconButton, SvgIcon, HideOnBreakpoint, Typography, BoxContainer, Button } from 'ui'

import { useListFilterDrawerContext } from './filter/ListFilterDrawerContext'

const FilterButton = ({ excludeFields }: { excludeFields?: string[] }) => {
    const { filterValues, setFilters } = useListContext()
    const { open } = useListFilterDrawerContext()

    const resetFilter = () => {
        const newFilters: {
            [key: string]: string
        } = {}
        excludeFields?.forEach((source) => {
            if (filterValues[source]) {
                newFilters[source] = filterValues[source]
            }
        })
        setFilters(newFilters, {})
    }

    let extract = 0

    excludeFields?.forEach((source) => {
        if (filterValues[source]) {
            extract += 1
        }
    })

    const filtersLength: number = Object.keys(filterValues).length - extract

    const Icon = filtersLength ? Icons.FilterActive : Icons.FilterAltOutlined

    return (
        <BoxContainer>
            <HideOnBreakpoint
                render={(match) => (
                    <>
                        <Typography
                            component="div"
                            color="text"
                        >
                            {match ? (
                                <Button
                                    variant="text"
                                    color="inherit"
                                    onClick={open}
                                    startIcon={
                                        <SvgIcon
                                            inheritViewBox
                                            component={Icon}
                                        />
                                    }
                                >
                                    Filters
                                </Button>
                            ) : (
                                <IconButton
                                    aria-label="Open Filter"
                                    size="small"
                                    color="inherit"
                                    onClick={open}
                                >
                                    <SvgIcon
                                        inheritViewBox
                                        component={Icon}
                                        sx={{ width: '20px', height: '20px' }}
                                    />
                                </IconButton>
                            )}
                        </Typography>
                        {match && Boolean(filtersLength) && (
                            <IconButton
                                aria-label="Reset Filter"
                                size="small"
                                onClick={resetFilter}
                            >
                                <Icons.Close />
                            </IconButton>
                        )}
                    </>
                )}
            />
        </BoxContainer>
    )
}

export default FilterButton
