import { websiteLabel, websiteValue } from 'resources/common'
import { type ShopModel } from 'resourcesBase'

const source = 'website' as const satisfies keyof ShopModel

interface Data extends Pick<ShopModel, 'website'> {}

const value = ({ website }: Data) => {
    return websiteValue(website)
}

const website = {
    source,
    label: websiteLabel,
    value,
}

export default website
