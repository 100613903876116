import { Tooltip as MuiTooltip } from '@mui/material'

const Tooltip = ((props) => {
    if (!props.title) {
        return <>{props.children}</>
    }

    return (
        <MuiTooltip
            placement="bottom-start"
            {...props}
        />
    )
}) as typeof MuiTooltip

export default Tooltip
export { type TooltipProps, tooltipClasses } from '@mui/material'
