import { fileToBase64 } from 'utils'

type Data = Record<string, any> | any[]

/**
 * If the data has file:
 *  - If the data has nested sctructures, convert files to Base64
 *  - Otherwise convert data to FormData
 */
export async function dataAndFiles(data: Data): Promise<Data> {
    if (hasFile(data)) {
        if (isNested(data)) {
            return makeFormObject(data)
        }

        return makeFormData(data)
    }

    return data
}

const getFile = (data: Record<string, any>): File => {
    return data?.rawFile || (data instanceof File && data)
}

const isFileValue = (data: Data) => {
    return Boolean(getFile(data))
}

const makeFormObject = async (data: Data, obj: Record<string, any> | any[] = {}) => {
    await Promise.all(
        Object.keys(data).map(async (property) => {
            const value = data[property]
            if (typeof value === 'undefined') {
                return
            }

            let _value = value

            if (value && typeof value === 'object') {
                const file = getFile(value)
                if (file) {
                    const data = await fileToBase64(file)
                    // Base64 does not contain the name of the file.
                    // We need to store the name of the file in the value
                    // Change the format of the value to { data: base64, name: filename }
                    _value = { data, name: file.name }
                } else {
                    _value = await makeFormObject(value, Array.isArray(value) ? [] : {})
                }
            }
            obj[property] = _value
        }),
    )
    return obj
}

const makeFormData = (data: Record<string, any>) => {
    const formData = new FormData()

    for (const property in data) {
        if (!Object.prototype.hasOwnProperty.call(data, property)) {
            continue
        }

        const value = data[property]

        if (typeof value === 'undefined') {
            continue
        }

        formData.append(property, formDataParseValue(value))
    }

    return formData
}

const formDataParseValue = (value: any) => {
    if (!value) {
        return ''
    }

    if (Array.isArray(value)) {
        return JSON.stringify(value)
    }

    if (typeof value === 'object') {
        const fileValue = getFile(value)
        if (fileValue) {
            return fileValue
        }
    }

    return value
}

const hasFile = (data: Data): boolean => {
    if (!data) {
        return false
    }
    return (Array.isArray(data) ? data : Object.values(data)).some((item) => {
        if (typeof item !== 'object') {
            return false
        }
        if (isFileValue(item)) {
            return true
        }
        return hasFile(item)
    })
}

const isNested = (data: Data): boolean =>
    (Array.isArray(data) ? data : Object.values(data)).some((item) => {
        if (!item) {
            return false
        }
        if (Array.isArray(item)) {
            return item.some((nestedItem) => typeof nestedItem === 'object' && !isFileValue(item))
        }
        if (typeof item === 'object') {
            return !isFileValue(item)
        }
        return false
    })
