import { createContext, useContext } from 'react'

import { type ObjectAny } from 'appTypes'

export const StepsContext = createContext<StepContextResult>(null)
export const useStepsContext = () => useContext(StepsContext)

interface StepContextResult {
    step: number
    stepsData: React.MutableRefObject<StepsData>

    next: () => void
    prev: () => void
}

export interface StepsData {
    [key: number]: {
        values?: ObjectAny
    }
}

export const maxSteps = 2
