import { type FC } from 'react'

import { getFileExtensionData } from 'core/files'
import { DataAvatar, type DataAvatarProps, type AvatarProps } from 'ui'
import { fileType } from 'utils'

interface Props extends Pick<AvatarProps, 'onClick'> {
    file: File | string
}

const DocumentAvatar: FC<Props> = ({ file }) => {
    // TODO: delete this check
    if (!file) {
        return
    }

    return <DataAvatar {...getDocumentAvatarProps(file)} />
}

export default DocumentAvatar

export const getDocumentAvatarProps = (
    file: File | string,
): Pick<DataAvatarProps, 'color' | 'avatarOpacity' | 'defaultImage'> => {
    const extension = fileType(typeof file === 'string' ? file : file.name)
    const config = getFileExtensionData(extension)

    return {
        color: config.color,
        avatarOpacity: 0.12,
        defaultImage: config.icon,
    }
}
