import { type FC } from 'react'

import { SmallSection } from 'ui'

import DirectlyAddedCard from './DirectlyAddedCard'

const DirectlyAddedSection: FC = () => {
    return (
        <SmallSection sx={{ zIndex: 1 }}>
            <DirectlyAddedCard />
        </SmallSection>
    )
}

export default DirectlyAddedSection
