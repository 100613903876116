import { type ReactElement } from 'react'

import { type SvgIconElement } from 'appTypes'
import Icons from 'assets/icons'
import { IconElement } from 'ui'

export interface StatusConfig<Status extends string> {
    text: string | ReactElement
    icon: ReactElement
    id: Status
    iconComponent: SvgIconElement
}

export const defaultStatuses = {
    open: {
        text: 'Open',
        icon: (
            <IconElement
                component={Icons.StatusOpen}
                color={(theme) => theme.palette.primary.main}
            />
        ),
        iconComponent: Icons.StatusOpen,
    },
    inProgress: {
        text: 'In Progress',
        icon: (
            <IconElement
                component={Icons.StatusDraft}
                color={(theme) => theme.palette.primary.main}
            />
        ),
        iconComponent: Icons.StatusDraft,
    },
    closed: {
        text: 'Closed',
        icon: (
            <IconElement
                component={Icons.CheckCircle}
                color={(theme) => theme.palette.success.main}
            />
        ),
        iconComponent: Icons.CheckCircle,
    },
    canceled: {
        text: 'Canceled',
        icon: (
            <IconElement
                component={Icons.Cancel}
                color={(theme) => theme.palette.error.main}
            />
        ),
        iconComponent: Icons.Cancel,
    },
} satisfies { [key: string]: Omit<StatusConfig<any>, 'id'> }
