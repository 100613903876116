import { type FC, useRef, type ReactNode, useEffect } from 'react'

import { styled } from 'lib'
import { Box, Divider, Drawer, SimpleScrollbar } from 'ui'

import { useSidebar, useSidebarFunctions } from '../SidebarContext'
import { FadeByValue } from '../common'
import { SidebarContainer } from '../styled'

import DesktopProfile from './DesktopProfile'
import Logo from './Logo'
import Nav from './Nav'
import Settings from './Settings'
import ShopMenuItem from './ShopMenuItem'
import { type WithControls } from './types'

const DesktopSidebar = () => {
    const functions = useSidebarFunctions()
    // in order to not open same thing again, track what is already opened
    const keyRef = useRef<string>(null)
    const timer = useRef(null)

    const action = (cb: () => void) => {
        clearTimeout(timer.current)
        timer.current = setTimeout(cb, 200)
    }

    const close = () => {
        action(() => {
            keyRef.current = null
            functions.close()
        })
    }

    const open: WithControls['open'] = async (render, key) => {
        if (key && key === keyRef.current) {
            clearTimeout(timer.current)
            return
        }

        action(() => {
            keyRef.current = key
            functions.open(render)
        })
    }

    const clearAction = () => {
        clearTimeout(timer.current)
    }

    return (
        <SidebarContainer
            onMouseLeave={close}
            onMouseEnter={clearAction}
        >
            <Main>
                <div onMouseEnter={close}>
                    <Logo />
                    <Divider />
                </div>
                <ShopMenuItem
                    open={open}
                    close={close}
                />
                <Divider />
                <Nav
                    open={open}
                    close={close}
                />
                <div onMouseEnter={close}>
                    <Divider />
                    <DesktopProfile />
                    <Divider />
                    <Settings />
                </div>
            </Main>
            {/* if we open the panel from one item and move FAST over another item
                in order to not open the panel for the second item, we need to clear the action */}
            <Panel
                onMouseEnter={clearAction}
                // on top level close clear everything
                onClose={() => {
                    keyRef.current = null
                    timer.current = null
                }}
            />
        </SidebarContainer>
    )
}

const Panel: FC<{ onMouseEnter: () => void; onClose: () => void }> = ({
    onMouseEnter,
    onClose,
}) => {
    const [snap] = useSidebar()
    const isOpen = Boolean(snap.content)

    useEffect(() => {
        if (!isOpen) {
            onClose()
        }
    }, [snap])

    return (
        <Drawer
            open={isOpen}
            BackdropComponent={null}
            ModalProps={{
                disablePortal: true,
                sx: {
                    width: 0,
                    zIndex: 2,
                },
            }}
            PaperProps={{
                sx: {
                    marginLeft: '80px',
                    width: 240,
                    boxSizing: 'border-box',
                    borderRadius: '0 8px 8px 0',
                },
                onMouseEnter,
            }}
        >
            <Box overflow="hidden">
                <SimpleScrollbar sx={{ height: '100%' }}>
                    <FadeByValue value={snap.content}>
                        <Box p="8px">
                            {snap.content ? (snap.content as () => ReactNode)() : null}
                        </Box>
                    </FadeByValue>
                </SimpleScrollbar>
            </Box>
        </Drawer>
    )
}

const Main = styled('div')`
    z-index: 4;
    position: relative;
    background: ${({ theme }) => theme.palette.white};
    border-right: 1px solid ${({ theme }) => theme.palette.other.divider};
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
`

export default DesktopSidebar
