import { type FC } from 'react'

import { type WithChildrenNode } from 'appTypes'
import { alpha } from 'lib'
import { Typography } from 'ui'

const UtilityDrawerAboveFooter: FC<WithChildrenNode> = ({ children }) => {
    return (
        <Typography
            component="div"
            variant="subtitle2"
            sx={({ palette }) => ({
                borderTop: `1px solid ${alpha(palette.text.primary, 0.12)}`,
                background: 'white',
                padding: '9px 20px',
                justifyContent: 'space-between',
                boxShadow: '0px -2px 5px 0px rgba(0,0,0,0.20)',
            })}
        >
            {children}
        </Typography>
    )
}

export default UtilityDrawerAboveFooter
