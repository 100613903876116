export const fileToBase64 = (file: File): Promise<string | ArrayBuffer> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(file)
    })

export const fileType = (url: string): Lowercase<string> => {
    return url.split(/[#?]/)[0].split('.').pop().trim().toLowerCase() as Lowercase<string>
}

export const displayFileSize = (file: File | number): string => {
    const bytes = typeof file === 'number' ? file : file.size

    // If less than KB, display 1 KB
    if (bytes < 1024) {
        return '1 KB'
    }

    if (bytes < 1024 * 1024) {
        return (bytes / 1024).toFixed(0) + ' KB'
    }

    return (bytes / (1024 * 1024)).toFixed(2) + ' MB'
}

export const displayFileExtension = (file: File | string): string => {
    return '.' + fileType(typeof file === 'string' ? file : file.name)
}

export const getFileName = (file: File | string): string =>
    (typeof file === 'string' ? file : file.name).split('?')[0].split('/').pop()

const imageExtensions = [
    'jpg',
    'jpeg',
    'png',
    'gif',
    'bmp',
    'webp',
    'tiff',
    'svg',
    'ico',
    'jfif',
    'pjpeg',
    'pjp',
    'heic',
    'heif',
]

const audioExtensions = ['mp3', 'wav', 'ogg', 'aac', 'flac', 'm4a', 'wma']

const videoExtensions = ['mp4', 'avi', 'mkv', 'mov', 'wmv', 'flv', 'webm', 'mpeg']

export const fileExtension = {
    isPdf: (ext: string) => {
        return ext === 'pdf'
    },
    isAudio: (ext: string) => {
        return audioExtensions.includes(ext)
    },
    isWord: (ext: string) => {
        return ext === 'doc' || ext === 'docx'
    },
    isExcel: (ext: string) => {
        return ext === 'xls' || ext === 'xlsx' || ext === 'csv'
    },
    isVideo: (ext: string) => {
        return videoExtensions.includes(ext)
    },
    isTxt: (ext: string) => {
        return ext === 'txt'
    },
    isImg: (ext: string) => {
        return imageExtensions.includes(ext)
    },
}

export const mediaTypes = {
    pdf: 'application/pdf',
    csv: 'csv',
}
