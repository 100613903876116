import { useEffect, type FC } from 'react'

import { RadioButtonGroupInput } from 'react-admin'
import { useWatch, useFormContext } from 'react-hook-form'

import { TextInput, costMaskParams, percentageMaskParams } from 'components'
import { requiredValidation, validateMaxNum, validateNonNegative } from 'core'
import { inventoryPricingFields, InventoryPricingTypeKeys } from 'resources/inventory'
import { PartInput } from 'resources/parts'
import { ShopInput } from 'resources/shops'
import { GridContainerColumns, GridItem } from 'ui'

const avgCostValidator = [validateNonNegative()]
const markupValidation = [validateNonNegative(), requiredValidation]
const marginValidation = [
    validateNonNegative(),
    validateMaxNum(99.999, 'Invalid Value'),
    requiredValidation,
]

const costFloorValidator = [validateNonNegative()]

const typeChoices: { id: InventoryPricingTypeKeys; name: string }[] = [
    { id: InventoryPricingTypeKeys.PERCENTAGE, name: 'Percentage' },
    { id: InventoryPricingTypeKeys.FLAT_MARKUP, name: 'Flat Markup/Margin' },
    { id: InventoryPricingTypeKeys.SELLING_PRICE, name: 'Flat Selling Price' },
]

export const InventoryPricingForm: FC = () => {
    return (
        <>
            <ShopInput
                disabled
                contextType="inventory"
            />
            <PartInput
                disabled
                required={false}
                contextType="inventory"
            />
            <TextInput
                source="customerPartTier"
                label={inventoryPricingFields.partTier.label}
                disabled
            />
            <TextInput
                source={inventoryPricingFields.acquisitionCost.source}
                label={inventoryPricingFields.acquisitionCost.label}
                validate={avgCostValidator}
                disabled
                {...costMaskParams}
            />
            <TypeInput />
            <TypeChangeHandler />
        </>
    )
}

const TypeInput = () => {
    const { setValue, clearErrors } = useFormContext()

    return (
        <RadioButtonGroupInput
            defaultValue={InventoryPricingTypeKeys.PERCENTAGE}
            sx={{ flexDirection: 'column' }}
            label={false}
            source={inventoryPricingFields.type.source}
            choices={typeChoices}
            onChange={() => {
                // clear validation error from previous type
                clearErrors([
                    inventoryPricingFields.marginPercent.source,
                    inventoryPricingFields.markup.source,
                    inventoryPricingFields.markupPercent.source,
                    inventoryPricingFields.price.source,
                ])

                setValue(inventoryPricingFields.marginPercent.source, null)
                setValue(inventoryPricingFields.markup.source, null)
                setValue(inventoryPricingFields.markupPercent.source, null)
                setValue(inventoryPricingFields.price.source, null)
                setValue(inventoryPricingFields.costFloor.source, null)
            }}
        />
    )
}

const FlatSellingPrice: FC = () => {
    return (
        <>
            <FlatPriceInput />
            <TextInput
                source={inventoryPricingFields.markup.source}
                label={inventoryPricingFields.markup.label}
                disabled
                {...costMaskParams}
            />
        </>
    )
}

const FlatMarkupMargin: FC = () => {
    return (
        <>
            <TextInput
                source={inventoryPricingFields.markup.source}
                label={inventoryPricingFields.markup.label}
                validate={markupValidation}
                {...costMaskParams}
            />
            <TextInput
                source={inventoryPricingFields.costFloor.source}
                label={inventoryPricingFields.costFloor.label}
                validate={costFloorValidator}
                {...costMaskParams}
            />
            <TextInput
                source={inventoryPricingFields.price.source}
                label={inventoryPricingFields.price.label}
                disabled
                {...costMaskParams}
            />
            <FlatMarkupValueHandler />
        </>
    )
}

const Percentage: FC = () => {
    return (
        <>
            <GridContainerColumns>
                <GridItem xs={6}>
                    <PercentageMarkupInput />
                </GridItem>
                <GridItem xs={6}>
                    <PercentageMarginInput />
                </GridItem>
            </GridContainerColumns>
            <TextInput
                source={inventoryPricingFields.costFloor.source}
                label={inventoryPricingFields.costFloor.label}
                validate={costFloorValidator}
                {...costMaskParams}
            />
            <TextInput
                source={inventoryPricingFields.markup.source}
                label={inventoryPricingFields.markup.label}
                disabled
                {...costMaskParams}
            />
            <TextInput
                source={inventoryPricingFields.price.source}
                label={inventoryPricingFields.price.label}
                disabled
                {...costMaskParams}
            />
            <PercentageValueHandler />
        </>
    )
}

const PercentageMarkupInput = () => {
    const { setValue } = useFormContext()

    return (
        <TextInput
            source={inventoryPricingFields.markupPercent.source}
            label={inventoryPricingFields.markupPercent.label}
            onChange={({ target }) => {
                const value = Number(target.value)
                setValue(
                    inventoryPricingFields.marginPercent.source,
                    ((value / (100 + value)) * 100).toFixed(3),
                )
            }}
            validate={markupValidation}
            {...percentageMaskParams}
        />
    )
}

const PercentageMarginInput = () => {
    const { setValue } = useFormContext()

    return (
        <TextInput
            source={inventoryPricingFields.marginPercent.source}
            label={inventoryPricingFields.marginPercent.label}
            validate={marginValidation}
            onChange={({ target }) => {
                const value = Number(target.value)

                if (value >= 100) {
                    setValue(inventoryPricingFields.markupPercent.source, null)
                } else {
                    setValue(
                        inventoryPricingFields.markupPercent.source,
                        ((value / (100 - value)) * 100).toFixed(3),
                    )
                }
            }}
            {...percentageMaskParams}
        />
    )
}

const PercentageValueHandler = () => {
    const { watch, getValues, setValue } = useFormContext()

    const acquisitionCost =
        watch(inventoryPricingFields.acquisitionCost.source) ||
        getValues(inventoryPricingFields.acquisitionCost.source)

    const markupPercentage =
        watch(inventoryPricingFields.markupPercent.source) ||
        getValues(inventoryPricingFields.markupPercent.source)

    const costFloor = Number(
        watch(inventoryPricingFields.costFloor.source) ||
            getValues(inventoryPricingFields.costFloor.source),
    )

    useEffect(() => {
        const cost = costFloor > acquisitionCost ? costFloor : acquisitionCost
        const markup = (markupPercentage / 100) * cost
        const price = (markup + cost).toFixed(3)
        setValue(inventoryPricingFields.markup.source, markup)
        setValue(inventoryPricingFields.price.source, price)
    }, [markupPercentage, costFloor])
    return null
}

const FlatMarkupValueHandler = () => {
    const { watch, getValues, setValue } = useFormContext()

    const acquisitionCost =
        watch(inventoryPricingFields.acquisitionCost.source) ||
        getValues(inventoryPricingFields.acquisitionCost.source)

    const markupValue = Number(
        watch(inventoryPricingFields.markup.source) ||
            getValues(inventoryPricingFields.markup.source),
    )

    const costFloor = Number(
        watch(inventoryPricingFields.costFloor.source) ||
            getValues(inventoryPricingFields.costFloor.source),
    )

    useEffect(() => {
        const cost = costFloor > acquisitionCost ? costFloor : acquisitionCost
        const price = markupValue ? (markupValue + cost).toFixed(3) : null
        setValue(inventoryPricingFields.price.source, price)
    }, [markupValue, costFloor])
    return null
}

const FlatPriceInput = () => {
    const { watch, getValues, setValue } = useFormContext()

    const acquisitionCost =
        watch(inventoryPricingFields.acquisitionCost.source) ||
        getValues(inventoryPricingFields.acquisitionCost.source)

    const price =
        watch(inventoryPricingFields.price.source) || getValues(inventoryPricingFields.price.source)

    return (
        <TextInput
            source={inventoryPricingFields.price.source}
            label={inventoryPricingFields.price.label}
            onChange={({ target }) => {
                setValue(
                    inventoryPricingFields.markup.source,
                    (target.value - acquisitionCost).toFixed(3),
                    {
                        shouldValidate: true,
                    },
                )
            }}
            helperText={price < acquisitionCost && price ? 'Price below average cost' : ''}
            validate={requiredValidation}
            {...costMaskParams}
        />
    )
}

const TypeChangeHandler = () => {
    const type = useWatch({ name: inventoryPricingFields.type.source })

    if (type === InventoryPricingTypeKeys.SELLING_PRICE) {
        return <FlatSellingPrice key="sellingPrice" />
    }
    if (type === InventoryPricingTypeKeys.FLAT_MARKUP) {
        return <FlatMarkupMargin key="flatMargin" />
    }
    return <Percentage key="percentage" />
}
