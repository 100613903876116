import { type FC } from 'react'

import { BooleanInput } from 'react-admin'

import { globalClassNames } from 'lib'
import { formControlLabelClasses, StatusText, Typography, BoxContainer } from 'ui'

interface Props {
    children: string
    label: string
    source: string
}

const LabelSwitch: FC<Props> = ({ children, label, source }) => {
    return (
        <BoxContainer>
            <BooleanInput
                sx={{ [`& .${formControlLabelClasses.root}`]: { marginRight: 0 } }}
                source={source}
                helperText={false}
                defaultValue
                label={null}
            />
            <BoxContainer
                textOverflow="ellipsis"
                justifyContent="space-between"
                overflow="hidden"
                width="100%"
            >
                <Typography
                    variant="body1"
                    className={globalClassNames.ellipsis}
                    mr="16px"
                >
                    {children}
                </Typography>
                <StatusText
                    color={(theme) => theme.palette.primary.main}
                    bgcolor={(theme) => theme.palette.primary.main}
                >
                    {label}
                </StatusText>
            </BoxContainer>
        </BoxContainer>
    )
}

export default LabelSwitch
