import { useFormContext } from 'react-hook-form'

import { EmailInput, SelectInput, TelInput } from 'components'
import { addressFields, AddressFormSection } from 'resources/address'
import { countryFields } from 'resources/country'
import { stateFields } from 'resources/state'
import { VendorInput, vendorTypes } from 'resources/vendors'
import { SectionTitleSmall } from 'ui'

const AddVendorForm = () => {
    const { setValue } = useFormContext()

    return (
        <>
            <SectionTitleSmall>Vendor Details</SectionTitleSmall>
            <VendorInput
                required
                vendorType="REPAIR_SERVICES"
                onSelectedChange={(record) => {
                    setValue('type', record?.type || undefined)
                    setValue('phone', record?.phone || undefined)
                    setValue('email', record?.email || undefined)
                    setValue(addressFields.address1.source, record?.address || undefined)
                    setValue(addressFields.address2.source, record?.address2 || undefined)
                    setValue(addressFields.city.source, record?.city || undefined)
                    setValue(stateFields.self.source, record?.state || undefined)
                    setValue(addressFields.zipCode.source, record?.zipCode || undefined)
                    setValue(countryFields.self.source, record?.country || undefined)
                }}
            />
            <SelectInput
                source="type"
                label="Vendor Type"
                choices={vendorTypes}
                multiple
                disabled
            />
            <TelInput
                label="Company Phone"
                disabled
            />
            <EmailInput
                label="Company Email"
                disabled
            />

            <AddressFormSection disabled />
        </>
    )
}

export default AddVendorForm
