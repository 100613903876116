import { useEffect, useState } from 'react'

import { type BarDatum, ResponsiveBar } from '@nivo/bar'
import { useListContext, type TextInputProps, useGetList, useResourceContext } from 'react-admin'
import { useFormContext, useWatch } from 'react-hook-form'

import { type ObjectAny } from 'appTypes'
import { integerSpacedMaskResolver } from 'components/format'
import { inputIntegerSpacedMaskParams, TextInput } from 'components/inputs'
import { formatMoney } from 'components/money'
import { getFilterReference, useDataProviderConfig } from 'core'
import { useDebounce, useDidUpdate } from 'hooks'
import { alpha, useTheme, withColor } from 'lib'
import { Box, Slider as SliderBase, Typography } from 'ui'

import { type RenderFilterComponentProps } from './ListFilterSourceInput'
import { dataProviderConfigProps } from './ListFilterValueInput'

export interface ListFilterRangeInputProps extends RenderFilterComponentProps {
    inputProps?: Partial<TextInputProps>
    rangeListFilter?: () => ObjectAny
    integerValuesInput?: boolean
}

interface SliderProps {
    maxValue: number
    minValue: number
    sourceMin: string
    sourceMax: string
    min: number
    max: number
    step: number
}

type SliderValue = [number, number]

const Slider = ({ sourceMin, sourceMax, min, max, maxValue, minValue, step }: SliderProps) => {
    const { setValue } = useFormContext()
    const [sliderValue, setSliderValue] = useState<SliderValue>([minValue, maxValue])

    const slideChange = (event, values: SliderValue) => {
        setSliderValue(values)
    }

    const setDebouncedValues = useDebounce(
        (values: SliderValue) => {
            setValue(sourceMin, values[0])
            setValue(sourceMax, values[1])
        },
        { time: 100 },
    )

    useDidUpdate(() => {
        setDebouncedValues(sliderValue)
    }, [sliderValue])

    useEffect(() => {
        setSliderValue((oldValue) => {
            if (minValue === oldValue[0] && maxValue === oldValue[1]) {
                return oldValue
            }
            return [minValue, maxValue]
        })
    }, [minValue, maxValue])

    return (
        <SliderBase
            size="small"
            min={min < minValue ? min : minValue}
            max={max > maxValue ? max : maxValue}
            step={step}
            value={sliderValue}
            onChange={slideChange}
        />
    )
}

// TODO: remove BarDatum interface
interface RangeCount extends BarDatum {
    min: number
    max: number
    count: number
    id: number
}

interface ChartsProps {
    sourceMin: string
    sourceMax: string
    min: number
    max: number
    counts: RangeCount[]
    integerValuesInput?: boolean
}

const Charts = ({ sourceMin, sourceMax, min, max, counts, integerValuesInput }: ChartsProps) => {
    const minInputValue = +useWatch({ name: sourceMin })
    const maxInputValue = +useWatch({ name: sourceMax })

    const minValue = isNaN(minInputValue) ? 0 : minInputValue
    const maxValue = isNaN(maxInputValue) ? 0 : maxInputValue
    const theme = useTheme()

    return (
        <>
            <Box
                height="20px"
                mb="6px"
            >
                <ResponsiveBar
                    // 0 is not shown, this is why + 0.3
                    data={counts.map((range) => ({
                        ...range,
                        count: range.count + 0.3,
                    }))}
                    keys={['count']}
                    padding={0.5}
                    indexBy={(d) => String(d.id)}
                    colors={({ data }) => {
                        if ((data.max || 0) >= minValue && (data.min || 0) <= maxValue) {
                            return theme.palette.primary.main
                        }

                        return alpha(theme.palette.primary.main, 0.38)
                    }}
                    enableGridY={false}
                    enableLabel={false}
                    isInteractive={false}
                />
            </Box>
            <Slider
                min={min}
                max={max}
                maxValue={maxValue}
                minValue={minValue}
                sourceMax={sourceMax}
                sourceMin={sourceMin}
                step={integerValuesInput ? 1 : 0.01}
            />
        </>
    )
}

const ListFilterRangeInput = ({
    source,
    inputProps,
    rangeListFilter,
    integerValuesInput,
}: ListFilterRangeInputProps) => {
    const { setValue, getValues } = useFormContext()
    const sourceMin = source + 'Min'
    const sourceMax = source + 'Max'
    const { filter } = useListContext()
    const resource = useResourceContext()
    const reference = getFilterReference(resource, source)
    useDataProviderConfig(reference, dataProviderConfigProps)
    const [counts, setCounts] = useState([])
    // TODO: This hook adds offset and limit as query params. Use api instead of useGetList
    useGetList(
        reference,
        {
            filter: {
                ...filter,
                name: source,
                ...rangeListFilter?.(),
            },
        },
        {
            enabled: !!source,
            onSuccess: ({ data }) => {
                const values = getValues()
                const counts = data.map((x, index) => ({ ...x.id, id: index }))
                setCounts(counts)
                if (values[sourceMin] == null && values[sourceMax] == null) {
                    setValue(sourceMin, counts[0].min)
                    setValue(sourceMax, counts[counts.length - 1].max)
                }
            },
        },
    )

    const min = counts.length ? counts[0].min : 0
    const max = counts.length ? counts[counts.length - 1].max : 0

    // because this widgets sets its' value onMount it must unset its' value on unMount
    useEffect(() => {
        return () => {
            setValue(sourceMin, '')
            setValue(sourceMax, '')
        }
    }, [])

    return (
        <Box>
            <Charts
                sourceMin={sourceMin}
                sourceMax={sourceMax}
                min={min}
                max={max}
                counts={counts}
                integerValuesInput={integerValuesInput}
            />
            <Box
                display="flex"
                justifyContent="space-between"
                mt="6px"
                mb="16px"
            >
                <Typography
                    variant="body2"
                    color={withColor('text.secondary')}
                >
                    {integerValuesInput ? integerSpacedMaskResolver(min) : formatMoney(min)}
                </Typography>
                <Typography
                    variant="body2"
                    color={withColor('text.secondary')}
                >
                    {integerValuesInput ? integerSpacedMaskResolver(max) : formatMoney(max)}
                </Typography>
            </Box>

            <Box display="flex">
                <TextInput
                    source={sourceMin}
                    label="From"
                    {...(integerValuesInput ? inputIntegerSpacedMaskParams : inputProps)}
                    sx={{ marginRight: '25px' }}
                />
                <TextInput
                    source={sourceMax}
                    label="To"
                    {...(integerValuesInput ? inputIntegerSpacedMaskParams : inputProps)}
                />
            </Box>
        </Box>
    )
}

export default ListFilterRangeInput
