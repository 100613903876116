import { type FC } from 'react'

import { Skeleton, Stack, Typography } from 'ui'

import WidgetsContainer from './WidgetsContainer'

const WidgetSkeleton: FC<{ title: string }> = ({ title }) => {
    return (
        <WidgetsContainer>
            <Typography
                variant="chartTitle"
                color="text.secondary"
            >
                {title}
            </Typography>
            <Stack
                spacing="12px"
                width="100%"
            >
                <Skeleton height="8px" />
                <Skeleton height="8px" />
                <Skeleton
                    height="8px"
                    width="30%"
                />
            </Stack>
            <Skeleton height="100%" />
        </WidgetsContainer>
    )
}

export default WidgetSkeleton
