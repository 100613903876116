import { getCurrentYear, Navigate, useParams } from 'lib'
import { PageContent } from 'ui'
import { isValidYear } from 'utils'

import { CostReportContextProvider } from './CostReportContext'
import { DateSelector, Header } from './components'
import { ListSection, WidgetsSection } from './sections'

const CostReport = () => {
    const { year } = useParams()

    if (year && !isValidYear(year) && Number(year) > getCurrentYear()) {
        return <Navigate to=".." />
    }

    return (
        <CostReportContextProvider>
            <DateSelector />
            <PageContent>
                <Header />
                <WidgetsSection />
                <ListSection />
            </PageContent>
        </CostReportContextProvider>
    )
}

export default CostReport
