import { Card } from 'ui'

const BlankMonitor = () => {
    return (
        <Card
            sx={{
                position: 'relative',
                boxSizing: 'border-box',
                height: '130px',
                p: '20px',
            }}
        />
    )
}

export default BlankMonitor
