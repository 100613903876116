import avatar from './avatar'
import poType from './poType'
import status from './status'

const poFields = {
    type: poType,
    status,
    avatar,
}

export default poFields
