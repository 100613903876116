import { createContext, useContext } from 'react'
import type { ReactElement } from 'react'

import { type DataRecord } from 'appTypes'

import type { DialogSelectorUniversalParam } from './DialogSelector'

export interface DialogSelectorContextResult<T extends DataRecord = DataRecord>
    extends DialogSelectorUniversalParam<T> {}

export const DialogSelectorContext = createContext<DialogSelectorContextResult<any>>(null)

export const useDialogSelectorContext = <
    T extends DataRecord = DataRecord,
>(): DialogSelectorContextResult<T> => useContext(DialogSelectorContext)

export interface DialogSelectorProviderProps<T extends DataRecord> {
    children: ReactElement
    value: DialogSelectorContextResult<T>
}

export const DialogSelectorProvider = <T extends DataRecord>({
    children,
    value,
}: DialogSelectorProviderProps<T>) => {
    return <DialogSelectorContext.Provider value={value}>{children}</DialogSelectorContext.Provider>
}
