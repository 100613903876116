import type CK14Model from '../types'

const source = 'reasonForRepair' as const
const label = 'Reason for Repair'

interface ParentData {
    reasonForRepairData: CK14Model
}

const isParentData = (data: CK14Model | ParentData): data is ParentData =>
    data && 'reasonForRepairData' in data

const value = (data: CK14Model | ParentData) => {
    const record = isParentData(data) ? data.reasonForRepairData : data

    return record ? `${record.code} ${record.description}` : ''
}
const self = {
    source,
    label,
    value,
}

export default self
