import { type FC, type ReactNode } from 'react'

import { GridContainerColumns } from './GridContainer'
import { GridItemLayout } from './GridItemLayout'

const NoResultsLayout: FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <GridContainerColumns>
            <GridItemLayout sm={12}>{children}</GridItemLayout>
        </GridContainerColumns>
    )
}

export default NoResultsLayout
