import { useListController } from 'react-admin'

import { createResource } from 'core'

import { type BillingInvoice } from './types'

export const billingInvoiceResource = createResource({
    name: 'billing/invoices',
    resource: 'billing/invoices',
})

export const useGetBillingInvoices = () => {
    return useListController<BillingInvoice>({
        resource: billingInvoiceResource.resource,
    })
}
