import { type FC } from 'react'

import { useShowContext, useUpdate } from 'react-admin'

import Icons from 'assets/icons'
import { type PageTabConfig, TextareaInput, ViewHeader } from 'components'
import { maxLengthValidationText, requiredValidation, useConfirm } from 'core'
import { useCreateResourcePath } from 'core/resource'
import { PageContent, GridContainer, GridItemLayout, IconBox, InfoBadge, Stack } from 'ui'

import Note from './Note'
import NotesEmptyCard from './NotesEmptyCard'

export const noteInputValidations = [maxLengthValidationText, requiredValidation]

export interface NotesTabProps {
    removeAddButton?: boolean
}

const NotesTab: FC<NotesTabProps> = ({ removeAddButton }) => {
    const [update] = useUpdate()
    const { record } = useShowContext()
    const createPath = useCreateResourcePath()
    const confirm = useConfirm()
    if (!record) {
        return null
    }
    const addNote = () =>
        confirm({
            title: 'Add Note',
            closeOnError: false,
            formProps: {
                warnWhenUnsavedChanges: true,
            },
            content: (
                <TextareaInput
                    source="note"
                    label="Notes"
                    parse={(v) => {
                        const s = String(v)
                        return s.trimStart()
                    }}
                    validate={noteInputValidations}
                />
            ),
            onConfirm: async ({ formValues }) => {
                await update(
                    createPath({
                        id: record.id,
                        type: 'edit',
                    }),

                    { data: { notesToAdd: [formValues.note] } },
                    {
                        returnPromise: true,
                    },
                )
            },
            confirmButtonProps: {
                size: 'small',
                startIcon: <Icons.AddCommentOutlined />,
                children: 'Add Note',
            },
            awaitOnConfirm: true,
        })
    const notesLength = record?.notes?.length
    return (
        <PageContent>
            <ViewHeader title="Notes">
                <ViewHeader.Content>
                    <InfoBadge badgeContent={notesLength || 0} />
                </ViewHeader.Content>
                {removeAddButton ? null : (
                    <ViewHeader.Content placement="rightMobile">
                        <IconBox
                            title="Add Note"
                            onClick={addNote}
                        >
                            <Icons.AddCommentOutlined />
                        </IconBox>
                    </ViewHeader.Content>
                )}
            </ViewHeader>
            <GridContainer>
                {notesLength ? (
                    <GridItemLayout>
                        <Stack spacing="10px">
                            {record.notes.map((obj, i) => (
                                <Note
                                    {...obj}
                                    key={i}
                                />
                            ))}
                        </Stack>
                    </GridItemLayout>
                ) : (
                    <NotesEmptyCard
                        action={addNote}
                        removeAddButton={removeAddButton}
                    />
                )}
            </GridContainer>
        </PageContent>
    )
}

export default NotesTab

export const notesTab = (params?: { elementProps?: NotesTabProps }): PageTabConfig => ({
    value: 'notes',
    label: 'Notes',
    element: <NotesTab {...params?.elementProps} />,
})
