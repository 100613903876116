import { CreateButtonView, Header, ListTotalBadge, MainHeader } from 'components'
import { VendorPartDrawerToggler } from 'resources/parts'

const VendorPartsListHeader = () => {
    return (
        <MainHeader
            mainAction={
                <VendorPartDrawerToggler>
                    {({ onClick }) => (
                        <CreateButtonView onClick={onClick}>CREATE PART</CreateButtonView>
                    )}
                </VendorPartDrawerToggler>
            }
        >
            <Header.Info>
                <Header.Content>
                    <Header.Title>Vendor Parts</Header.Title>
                </Header.Content>
                <Header.Content mobilePlacement="bottom">
                    <ListTotalBadge />
                </Header.Content>
            </Header.Info>
        </MainHeader>
    )
}

export default VendorPartsListHeader
