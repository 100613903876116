import { type FC, type ReactElement } from 'react'

import { type CollapsibleControllerResult, useCollapsibleController } from './CollapsibleContext'

interface WithCollapsibleControllerProps {
    children: (params: CollapsibleControllerResult) => ReactElement
    name?: string
}

export const WithCollapsibleController: FC<WithCollapsibleControllerProps> = ({
    children,
    name,
}) => {
    const context = useCollapsibleController(name)

    return children(context)
}
