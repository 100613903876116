import { type FC } from 'react'

import { useWatch, useFormContext } from 'react-hook-form'

import { useDidUpdate } from 'hooks'

interface CombinedInputsProps {
    sources: string[]
    track?: string[]
}

const CombinedInputs: FC<CombinedInputsProps> = ({ sources, track }) => {
    const values = useWatch({ name: track || sources })
    const { trigger } = useFormContext()

    useDidUpdate(() => {
        sources.forEach((source) => {
            trigger(source)
        })
    }, values)

    return null
}

export default CombinedInputs
