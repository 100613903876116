import { type FC } from 'react'

import { useWatch } from 'react-hook-form'

import { LastDoneHelperTextBase, type PMIntervalMeterType } from 'resources/pm'

interface UnitPMCardResetLastDoneHelperTextProps {
    interval: PMIntervalMeterType
    name: string
}

const UnitPMCardResetLastDoneHelperText: FC<UnitPMCardResetLastDoneHelperTextProps> = ({
    interval,
    name,
}) => {
    const lastDone = useWatch({ name })

    return (
        <LastDoneHelperTextBase
            meterType={interval.type}
            value={interval.value}
            lastDone={lastDone}
        />
    )
}

export default UnitPMCardResetLastDoneHelperText
