import { SkeletonSliderCardContainer } from 'components'
import { Skeleton } from 'ui'

const UnitSliderSkeleton = ({ height }: { height?: `${number}px` }) => {
    return (
        <SkeletonSliderCardContainer
            sx={{
                height,
                gap: '10px',
            }}
        >
            <Skeleton
                height="8px"
                width="120px"
                sx={{
                    my: '20px',
                }}
            />
            <Skeleton />
        </SkeletonSliderCardContainer>
    )
}
export default UnitSliderSkeleton
