import { type FC } from 'react'

import { type PaletteMainColors } from 'lib'

import { useTelematicsContext } from '../TelematicsContext'

interface Props {
    render: (color: PaletteMainColors) => JSX.Element
}

export const useIntegrationStatus = (): PaletteMainColors => {
    const { errorCount, hasUnseenVehicles } = useTelematicsContext()

    if (errorCount) {
        return 'error'
    }

    if (hasUnseenVehicles) {
        return 'primary'
    }

    return null
}

const IntegrationsStatus: FC<Props> = ({ render }) => {
    const status = useIntegrationStatus()
    if (status) {
        return render(status)
    }

    return null
}

export default IntegrationsStatus
