import { createContext, type FC, useContext, type ReactNode, type PropsWithChildren } from 'react'

const InventoryContext = createContext<boolean>(false)

export const useInventoryContext = () => useContext(InventoryContext)

interface Props extends PropsWithChildren {
    children: ReactNode
}

export const InventoryContextProvider: FC<Props> = ({ children }) => {
    return <InventoryContext.Provider value>{children}</InventoryContext.Provider>
}

export default InventoryContext
