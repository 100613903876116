import { type FC } from 'react'

import { TextInput } from 'components'

import { addressFields } from '../fields'

interface Props {
    disabled?: boolean
}

const Address2Input: FC<Props> = ({ disabled }) => {
    return (
        <TextInput
            source={addressFields.address2.source}
            label={addressFields.address2.label}
            disabled={disabled}
        />
    )
}

export default Address2Input
