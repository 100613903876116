import { useFormState, useFormContext } from 'react-hook-form'

import { useResource } from 'core/resource'

import { RaSaveButton, type RaSaveButtonProps } from './RaSaveButton'
import { preventSubmitError, resourceToFormId } from './utils'

export interface SubmitButtonProps extends RaSaveButtonProps {}

const SubmitButton = (props: SubmitButtonProps) => {
    const { clearErrors } = useFormContext()
    const { isValid, errors } = useFormState()
    const resource = useResource()

    const disabled = !isValid || Boolean(errors[preventSubmitError.name])

    const onClick: SubmitButtonProps['onClick'] = (event) => {
        props.onClick?.(event)

        clearErrors()
    }

    return (
        <RaSaveButton
            form={resourceToFormId(resource)}
            disabled={disabled}
            {...props}
            onClick={onClick}
            // wrap the label string with span, so the browser translation does not crash
            // while trying to insertBefore on a text node
            // @ts-ignore
            label={props.label ? <span>{props.label}</span> : undefined}
        />
    )
}

export default SubmitButton
