import { formatMoney } from 'components'
import { useResizeObserverWidth } from 'hooks'
import { reportsUrls, createReportResource } from 'resources/reports'
import { vendorFields } from 'resources/vendors'

import WidgetBase, { WidgetSkeleton } from '../../WidgetBase/WidgetBase'
import { useCreateDashboardWidgetLink, useDashboardWidget } from '../../WidgetsForm'
import { Clarification, CostPerUnitChart } from '../CostPerUnitWidget/components'
import { roundWidgetJobsData } from '../utils'

import { VendorExpensesList } from './components'
import { type VendorExpensesResponse } from './types'

const description = `The sum of all costs, fees, and taxes associated with Vendor Work Orders 
for the selected period is displayed alongside a breakdown by Vendor.`

let validData = false
let hasData = false
const VendorExpensesWidget = () => {
    const createLink = useCreateDashboardWidgetLink()

    const { ref, match } = useResizeObserverWidth(360)
    const data = useDashboardWidget<VendorExpensesResponse>({
        resource: createReportResource('cost-per-vendor'),
        sideEffect: (data) => {
            const finalData = roundWidgetJobsData(data)

            hasData = typeof finalData.total === 'number'
            validData = finalData.total > 0
            return finalData as VendorExpensesResponse
        },
    })
    if (!data) {
        return <WidgetSkeleton />
    }
    const { maxTotalVendors, ...ratioData }: VendorExpensesResponse = data

    return (
        <WidgetBase
            ref={ref}
            link={createLink(reportsUrls.vendorExpenses)}
            headerProps={{
                title: 'Total Vendor Expenses',
                label: formatMoney(ratioData?.total),
                tooltipText: description,
                clarification: (
                    <Clarification
                        {...ratioData}
                        showTooltip={match}
                        validData={hasData}
                    />
                ),
                chart: (
                    <CostPerUnitChart
                        PieIcon={vendorFields.avatar.Icon}
                        {...ratioData}
                        disabled={!validData}
                    />
                ),
            }}
            infoProps={{
                content: (
                    <VendorExpensesList
                        data={maxTotalVendors}
                        description={description}
                        validData={hasData && validData}
                    />
                ),
            }}
            separator="Vendors by Expenses"
        />
    )
}
export default VendorExpensesWidget
