import {
    formatMoney,
    type Column,
    type ListFilterChoice,
    type DataListDetails,
    type SortBy,
    ListFilterRangeInput,
    costMaskParams,
} from 'components'
import { LineItemTypeKeys, type LineItemTypesUnion } from 'resources/lineItems'

type Config = { [key in LineItemTypesUnion | 'total']: { source: string; label: string } }

type ExtendedParams = LineItemTypesUnion | { type?: LineItemTypesUnion; label?: string }

const config = {
    [LineItemTypeKeys.LABOR]: {
        source: 'totalLabor',
        label: 'Labor',
    },
    [LineItemTypeKeys.PART]: {
        source: 'totalParts',
        label: 'Parts',
    },
    [LineItemTypeKeys.FEE]: {
        source: 'totalFees',
        label: 'Fees',
    },
    [LineItemTypeKeys.TAX]: {
        source: 'totalTax',
        label: 'Tax',
    },
    [LineItemTypeKeys.SERVICE]: {
        source: 'totalServices',
        label: 'Services',
    },
    total: {
        source: 'total',
        label: 'Total Cost',
    },
} as const satisfies Config

const tableColumn = (itemConfig?: ExtendedParams) => {
    const { source, label } = getConfig(itemConfig)

    return {
        field: source,
        headerName: label,
        align: 'right',
        renderCell: ({ value }) => formatMoney(value),
    } as const satisfies Column
}

const dataCardRow = (itemConfig?: ExtendedParams) => {
    const { source, label } = getConfig(itemConfig)

    return {
        source,
        label,
        render: (value) => formatMoney(value),
    } as const satisfies DataListDetails<any, any>
}

const sort = (itemConfig?: ExtendedParams) => {
    const { source, label } = getConfig(itemConfig)

    return {
        id: source,
        label,
    } as const satisfies SortBy
}

const filter = (itemConfig?: ExtendedParams) => {
    const { source, label } = getConfig(itemConfig)

    return {
        id: source,
        label,
        filterType: 'range',
        renderComponent: (props) => (
            <ListFilterRangeInput
                inputProps={costMaskParams}
                {...props}
            />
        ),
    } as const satisfies ListFilterChoice
}

const getConfig = (itemConfig?: ExtendedParams) => {
    const itemType = typeof itemConfig === 'string' ? itemConfig : itemConfig?.type
    const defaultConfig = config[itemType || 'total']
    const source = defaultConfig.source
    const label = (typeof itemConfig === 'object' && itemConfig?.label) || defaultConfig.label

    return {
        source,
        label,
    }
}

const total = {
    tableColumn,
    dataCardRow,
    sort,
    filter,
    getConfig,
}

export default total
