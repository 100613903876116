import { styled } from 'lib'

export const Section = styled('div')`
    margin-bottom: 32px;
    width: 100%;
`

export const SmallSection = styled('div')`
    margin-bottom: 24px;
    width: 100%;
`
