import { type NoInfer } from 'appTypes'
import { type DataListDetails, type Column } from 'components'

import { type InvoiceModel } from '../types'

const source = 'poNumber' as const satisfies keyof InvoiceModel
const label = 'Customer PO Number'

interface Data extends Pick<InvoiceModel, 'poNumber'> {}

const value = (record: Data) => record.poNumber

const tableColumn = <Source extends string = typeof source>() =>
    ({
        field: source as NoInfer<Source>,
        headerName: label,
        renderCell: (cell) => value(cell.row),
    }) as const satisfies Column

const sort = <Source extends string = typeof source>() =>
    ({
        id: source as NoInfer<Source>,
        label,
    }) as const

const dataCardRow = <Source extends string = typeof source>() =>
    ({
        source: source as NoInfer<Source>,
        label,
    }) as const satisfies DataListDetails<any, any>

const poNumber = {
    source,
    label,
    value,
    sort,
    tableColumn,
    dataCardRow,
}

export default poNumber
