import { ArchivedContextProvider } from 'context'
import { PageContent } from 'ui'

import { CustomerUnitsList } from './components'

export const CustomerUnits = () => {
    return (
        <PageContent>
            <ArchivedContextProvider>
                <CustomerUnitsList />
            </ArchivedContextProvider>
        </PageContent>
    )
}
