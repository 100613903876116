import { type FC, type ReactNode } from 'react'

import { Typography, type TypographyProps } from 'ui'

interface Props extends Pick<TypographyProps, 'className'> {
    children: ReactNode
}

const InfoCardTitle: FC<Props> = ({ children, className }) => {
    return (
        <Typography
            color="inherit"
            variant="subtitle2"
            className={className}
        >
            {children}
        </Typography>
    )
}

export default InfoCardTitle
