import { type PropsWithChildren } from 'react'

import { ErrorBoundary } from 'lib'
import { Card } from 'ui'

const MonitorBase = ({ children }: PropsWithChildren) => {
    return (
        <Card
            sx={{
                position: 'relative',
                boxSizing: 'border-box',
                height: '130px',
                p: '20px',
                justifyContent: 'space-between',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <ErrorBoundary>{children}</ErrorBoundary>
        </Card>
    )
}

export default MonitorBase
