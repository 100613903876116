import { type FC } from 'react'

import images from 'assets/images'
import { rotateAnimation } from 'lib'
import { Img } from 'ui/images'
import { BoxContainer } from 'ui/layouts'

const FullPageLoading: FC = () => {
    return (
        <BoxContainer
            sx={{
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Img
                src={images.initialLogo}
                sx={{
                    width: '120px',
                    height: '120px',
                    animation: `${rotateAnimation} 1s linear infinite`,
                }}
            />
        </BoxContainer>
    )
}

export default FullPageLoading
