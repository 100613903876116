import { type FC } from 'react'

import { inject } from 'mobx-react'
import { useShowContext } from 'react-admin'

import Icons from 'assets/icons'
import { type ActionChildren } from 'core/actions'
import { type AuthStore } from 'core/auth'
import { InventoryItemDrawerToggler } from 'pages/Inventory/List/components'
import { type PartModel } from 'resources/parts'

interface Props {
    children: ActionChildren
}

const PartAddToInventoryAction: FC<Props> = inject('auth')(({
    auth,
    children,
}: Props & { auth: AuthStore }) => {
    const shops = auth.user.membership.shops
    const { record } = useShowContext<PartModel>()

    return (
        <InventoryItemDrawerToggler selectedPartId={record.id}>
            {({ onClick }) =>
                children({
                    Icon: Icons.InventoryAdd,
                    title: 'Add to Inventory',
                    disabled: record.inventoryCount >= shops.length,
                    onClick,
                })
            }
        </InventoryItemDrawerToggler>
    )
})
export default PartAddToInventoryAction
