import { type FC } from 'react'

import Icons from 'assets/icons'
import { basePermissions, renderOnPermission } from 'core'
import { PrimaryButton, type ButtonProps } from 'ui'

interface Props extends Partial<ButtonProps> {}

const CreateButtonView: FC<Props> = renderOnPermission((props) => {
    return (
        <PrimaryButton
            startIcon={<Icons.Add />}
            {...props}
        />
    )
}, basePermissions.create)

export default CreateButtonView
