import { type FC } from 'react'

import { ViewHeader } from 'components'

import { useCostReportContext } from '../CostReportContext'

const Header: FC = () => {
    const { selectedDate } = useCostReportContext()

    return <ViewHeader title={`Cost Report ${selectedDate || '- All Time'}`} />
}

export default Header
