import { type FC } from 'react'

import { useShowContext } from 'react-admin'

import {
    PurchaseInvoiceDrawerToggler,
    type PurchaseInvoiceDrawerTogglerProps,
} from 'resources/purchaseInvoices'

interface Props extends Pick<PurchaseInvoiceDrawerTogglerProps, 'children'> {}

const CreateInvoice: FC<Props> = ({ children }) => {
    const { record } = useShowContext()

    return (
        <PurchaseInvoiceDrawerToggler
            children={children}
            defaultValues={{
                term: record?.purchaseOrderData?.vendorData.paymentTerm || '',
            }}
        />
    )
}

export default CreateInvoice
