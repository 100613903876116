import { type ReactElement, type FC } from 'react'

import { inject, observer } from 'mobx-react'

import { type AuthStore } from 'core'

import NoRepairShops from './NoRepairShops'

interface Props {
    children: ReactElement
}

const LayoutWrapper: FC<Props> = inject('auth')(
    observer(({ auth, children }: Props & { auth: AuthStore }) => {
        const membership = auth.getMembership()

        if (!membership.shops.length) {
            return <NoRepairShops />
        }

        return children
    }),
)

export default LayoutWrapper
