import { useFormContext } from 'react-hook-form'

import Icons from 'assets/icons'
import { useUtilityDrawerContext, useUtilityDrawerStepsContext } from 'components'
import { useFinalErrorHandler, api, ActionsMenu, dispatchAction, useConfirm, useNotify } from 'core'
import { poDraftUrl, poReceiptsName } from 'resources/purchaseOrders'

import { type ReceiveItemsExtraData } from './types'

const dispatchPoDraft = () => {
    dispatchAction(poReceiptsName, {
        name: 'draft_receipt',
    })
}

const PoReceiveItemsDrawerOptions = () => {
    const { forceClose } = useUtilityDrawerContext()
    const { getValues } = useFormContext()
    const stepsContext = useUtilityDrawerStepsContext()
    const { extra, steps, parser, step } = stepsContext
    const { poId, hasDraft } = extra as ReceiveItemsExtraData
    const errorHandler = useFinalErrorHandler()
    const notify = useNotify()
    const confirm = useConfirm()

    const draftUrl = poDraftUrl(poId)

    return (
        <ActionsMenu
            disablePortal
            iconBackgroundColor="transparent"
            actions={(_, { children }) => [
                children({
                    Icon: Icons.SaveDraft,
                    title: 'Save Draft',
                    key: 'save-draft',
                    onClick: async () => {
                        const values = getValues()
                        const data: Record<string, any> = {}
                        steps.forEach((_, index) => {
                            const stepData = parser[index]?.(values, {
                                extra: { isDraft: true },
                                context: stepsContext,
                            }).requestData
                            if (stepData) {
                                Object.assign(data, stepData)
                            }
                        })
                        data.step = step
                        try {
                            await api.post(draftUrl, data)
                            dispatchPoDraft()
                            notify('Draft saved successfully')
                            forceClose()
                        } catch (err) {
                            errorHandler(err)
                        }
                    },
                }),
                hasDraft
                    ? children({
                          Icon: Icons.Delete,
                          title: 'Reset Draft & Start Over',
                          key: 'reset-draft',
                          onClick: async () => {
                              confirm({
                                  title: 'Are you sure you want to remove this Draft?',
                                  content:
                                      'Deleting the draft will erase all your progress in receiving items and you need to start over.',
                                  onConfirm: async () => {
                                      try {
                                          await api.delete(draftUrl)
                                          dispatchPoDraft()
                                          notify('Draft successfully deleted')
                                          forceClose()
                                      } catch (err) {
                                          errorHandler(err)
                                      }
                                  },
                              })
                          },
                      })
                    : null,
            ]}
        />
    )
}

export default PoReceiveItemsDrawerOptions
