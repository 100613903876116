import { phoneMaskResolver } from 'components'
import { type MemberModel } from 'resourcesBase'

interface Data extends Pick<MemberModel, 'phone'> {}

const value = (data: Data) => {
    return phoneMaskResolver(data.phone)
}

const source = 'phone' as const satisfies keyof MemberModel

const phone = {
    source,
    label: 'Phone Number',
    value,
}

export default phone
