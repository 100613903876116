import { useRecordContext } from 'react-admin'

import { type DataRecord, type Identifier } from 'appTypes'
import { useCreateResourcePath } from 'core/resource'

import { dispatchAction } from '../actionEmitter'

import useArchive, { type ArchiveParams } from './useArchive'

export interface ArchiveOneParams extends Omit<ArchiveParams, 'path'> {
    id?: Identifier
}

const useArchiveOne = () => {
    const archive = useArchive()
    const createPath = useCreateResourcePath()
    const { id } = useRecordContext() || ({} as DataRecord)

    return ({ ...params }: ArchiveOneParams) => {
        archive(({ resource }) => {
            const finalId = params.id || id
            const finalResource = params.resource || resource

            return {
                ...params,
                path: createPath({
                    resource: finalResource.resource,
                    id: finalId,
                    type: 'edit',
                }),
                onSuccess: (response) => {
                    params.onSuccess?.(response)
                    dispatchAction(finalResource.name, {
                        name: 'archive',
                        payload: finalId,
                    })
                },
            }
        })
    }
}

export default useArchiveOne
