import { type FC } from 'react'

import { inject } from 'mobx-react'

import { AutocompleteInput } from 'components'
import { ReferenceInput } from 'core'
import { type AuthStore } from 'core/auth'

import { costCenterResource } from '../utils'

interface Props {
    disabled?: boolean
    helperText?: string
}

export const CostCenterInput: FC<Props> = inject('auth')(({
    disabled,
    helperText,
    auth,
}: Props & { auth: AuthStore }) => {
    if (!auth.companySettings.hasCostCenters) {
        return null
    }

    return (
        <ReferenceInput
            disabled={disabled}
            reference={costCenterResource.resource}
            source="costCenter"
            perPage={100}
        >
            <AutocompleteInput
                disabled={disabled}
                optionText="name"
                label="Cost Center"
                helperText={helperText}
            />
        </ReferenceInput>
    )
})
