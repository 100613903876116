import { type FC } from 'react'

import { AutocompleteInput, type AutocompleteInputProps } from 'components'
import { ReferenceInput } from 'core'

import ck18Fields from '../fields'
import { ck18Resource } from '../utils'

interface Props extends Pick<AutocompleteInputProps, 'label' | 'disabled' | 'source'> {}

export const CK18Input: FC<Props> = ({
    disabled,
    source = ck18Fields.self.source,
    label = ck18Fields.self.label,
}) => {
    return (
        <ReferenceInput
            source={source}
            reference={ck18Resource.resource}
            disabled={disabled}
            perPage={100}
        >
            <AutocompleteInput
                optionText={ck18Fields.self.value}
                disabled={disabled}
                label={label}
            />
        </ReferenceInput>
    )
}
