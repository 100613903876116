import { Box, type BoxProps, type TypographyProps, Typography } from 'ui'

const DotSeparator = (props: BoxProps & TypographyProps) => (
    <Typography
        component={Box}
        marginX="6px"
        color="text.primary"
        variant="chartListItem"
        {...props}
    >
        •
    </Typography>
)
export default DotSeparator
