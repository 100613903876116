import { useState, type FC } from 'react'

import { useFormContext } from 'react-hook-form'

import Icons from 'assets/icons'
import { requiredValidation } from 'core/form'
import { classes, getRandomColor } from 'lib'
import { listClasses, paperClasses, menuItemClasses, Dialog, Status, TextWithIcon } from 'ui'

import SelectInput from '../SelectInput'

import { CustomColor } from './CustomColor'
import { Option, SelectableOption } from './Option'
import { type ColorOption } from './types'
import { getOptions } from './utils'

interface Props {
    source: string
    required?: boolean
    label?: string
}

// TODO: prevent warning on custom color select
export const ColorInput: FC<Props> = ({ required, source, label = 'Color' }) => {
    const form = useFormContext()
    const [isOpen, setIsOpen] = useState(false)
    const onClose = () => setIsOpen(false)
    const setValue = (value: string) =>
        form.setValue(source, value, {
            shouldDirty: true,
            shouldValidate: true,
            shouldTouch: true,
        })
    const value = form.watch(source)
    const options = getOptions(value)

    return (
        <>
            <SelectInput
                defaultValue={value ? undefined : getRandomColor()}
                validate={required ? requiredValidation : undefined}
                source={source}
                choices={options}
                label={label}
                disableEmptyValue
                optionText={optionText}
                SelectProps={{
                    renderValue,
                    onChange: (event) => {
                        const value = event.target.value as string

                        if (value === 'custom') {
                            setIsOpen(true)
                            return
                        }

                        setValue(value)
                    },
                    MenuProps: {
                        sx: {
                            // TODO: find a way to tell the Menu adjusts its size equal to the input, without css
                            position: 'absolute !important',
                            [`.${paperClasses.root}`]: {
                                top: 'unset !important',
                                bottom: '0 !important',
                                left: '0 !important',
                                right: '0 !important',
                                transform: 'translateY(100%) !important',
                                transformOrigin: 'unset !important',
                                maxHeight: '400px !important',
                                minHeight: 'unset !important',
                                width: '100% !important',
                                maxWidth: 'unset !important',
                                minWidth: 'unset !important',
                            },
                            [`.${listClasses.root}`]: {
                                display: 'flex',
                                flexWrap: 'wrap',
                                px: '4px',
                            },
                            [`.${menuItemClasses.root}`]: {
                                p: '12px',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
                                    borderRadius: '2px',
                                },
                            },
                            [`.${menuItemClasses.root}.${classes.selected}`]: {
                                backgroundColor: 'unset',
                            },
                        },
                        disablePortal: true,
                    },
                }}
            />
            <Dialog
                open={isOpen}
                onClose={onClose}
            >
                <CustomColor
                    initialValue={value}
                    onClose={(color) => {
                        if (color) {
                            setValue(color)
                        }

                        onClose()
                    }}
                />
            </Dialog>
        </>
    )
}

const optionText = (option: ColorOption) => {
    if (option.id === 'custom') {
        return (
            <Option
                sx={{
                    background: (theme) => theme.palette.action.selected,
                }}
            >
                <Icons.Add sx={{ color: (theme) => theme.palette.action.active }} />
            </Option>
        )
    }

    return <SelectableOption option={option} />
}

const renderValue = (option: string) => {
    return (
        <TextWithIcon
            icon={
                <Status
                    size="10px"
                    iconColor={option}
                />
            }
        >
            {option}
        </TextWithIcon>
    )
}
