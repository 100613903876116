import { ViewHeader } from 'components'

const InventoryHistoryHeader = () => {
    return (
        <ViewHeader
            title="Inventory History"
            sx={(theme) => ({
                [theme.breakpoints.down('sm')]: {
                    display: 'none',
                },
            })}
        />
    )
}

export default InventoryHistoryHeader
