import { ActionsMenu } from 'core/actions'
import { type MemberModel } from 'resourcesBase'
import { Chip, Stack, SimpleScrollbar } from 'ui'

import { MemberField } from './MemberField'

interface MembersChipRowProps {
    members: MemberModel[]
}

export const MembersField = ({ members }: MembersChipRowProps) => {
    if (!members.length) {
        return null
    }
    const membersCount = members.length
    return (
        <ActionsMenu
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            renderToggler={(open) => (
                <Chip
                    component="span"
                    onClick={open}
                    label={`${membersCount} User${membersCount === 1 ? '' : 's'}`}
                    variant="filled"
                    size="small"
                />
            )}
            actions={() => [
                <SimpleScrollbar
                    sx={{
                        maxHeight: '268px',
                        width: '350px',
                    }}
                    key="container"
                >
                    <Stack
                        spacing="6px"
                        px="16px"
                        key="tags"
                        alignItems="flex-start"
                    >
                        {members.map((member) => (
                            <MemberField
                                record={member}
                                key={member.id}
                            />
                        ))}
                    </Stack>
                </SimpleScrollbar>,
            ]}
        />
    )
}
