import { type CK18Model } from '../types'

const source = 'complaint' as const
const label = 'Complaint'

interface ParentData {
    complaintData: CK18Model
}

const isParentData = (data: CK18Model | ParentData): data is ParentData =>
    'complaintData' in (data as ParentData)

const value = (data: CK18Model | ParentData) => {
    const record: CK18Model = isParentData(data) ? data.complaintData : data

    return record ? `${record.code} ${record.description}` : ''
}

const self = {
    source,
    label,
    value,
}

export default self
