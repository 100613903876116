import { type FC, type ReactNode } from 'react'

import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import { Monitor } from 'pages/Dashboard/components/Monitor/MonitorComponents'
import { useMonitor } from 'pages/Dashboard/components/Monitor/Monitors/useMonitor'
import { SliderWidgetSkeleton } from 'pages/Dashboard/components/WidgetBase'
import { IssueDrawerToggler } from 'pages/Issues/components'
import { getIssuesCreateConfig } from 'pages/Units/Issues'
import { issueFields, IssueStatusKeys } from 'resources/issues'
import { createReportResource } from 'resources/reports'
import { type UnitModel } from 'resources/units'
import { SvgIcon, StatusText, Typography, BoxContainer } from 'ui'

interface DataType {
    highCount: number
    highInProgressCount: number
    lowCount: number
    lowInProgressCount: number
    mediumCount: number
    mediumInProgressCount: number
}

const IssueStatusText = ({ children, icon }: { children: ReactNode; icon: FC }) => {
    return (
        <BoxContainer gap="4px">
            <SvgIcon
                component={icon}
                inheritViewBox
                color="inherit"
                sx={{ width: '10px', height: '10px' }}
            />
            {children}
        </BoxContainer>
    )
}

const InProgressText = ({ children }: { children: number }) => {
    if (!children) {
        return null
    }
    return (
        <BoxContainer gap="2px">
            <SvgIcon
                component={issueFields.status.getConfig(IssueStatusKeys.IN_PROGRESS).iconComponent}
                inheritViewBox
                color="inherit"
                sx={{ width: '16px', height: '16px' }}
            />
            <Typography variant="chartLabel">{children}</Typography>
        </BoxContainer>
    )
}
export const UnitIssuesWidget = () => {
    const record = useRecordContext<UnitModel>()
    const data = useMonitor<DataType>(
        createReportResource(`issues-by-priority`),
        {
            unit: record?.id,
        },
        Boolean(record?.id),
    )
    if (!data || !record) {
        return <SliderWidgetSkeleton />
    }
    const isIssues = Boolean(data.lowCount + data.mediumCount + data.highCount)
    const createConfig = getIssuesCreateConfig(record)

    const content = isIssues ? (
        <>
            <Monitor.ColumnsContainer>
                <BoxContainer gap="4px">
                    <Monitor.ValueText>{data.highCount || 0}</Monitor.ValueText>
                    <InProgressText>{data.highInProgressCount}</InProgressText>
                </BoxContainer>
                <StatusText statusColor={(theme) => theme.palette.charts.red}>
                    <IssueStatusText icon={Icons.ArrowUpward}>High</IssueStatusText>
                </StatusText>
            </Monitor.ColumnsContainer>
            <Monitor.ColumnsContainer>
                <BoxContainer gap="4px">
                    <Monitor.ValueText>{data.mediumCount || 0}</Monitor.ValueText>
                    <InProgressText>{data.mediumInProgressCount}</InProgressText>
                </BoxContainer>
                <StatusText statusColor={(theme) => theme.palette.charts.orange}>
                    <IssueStatusText icon={Icons.DragHandle}>Medium</IssueStatusText>
                </StatusText>
            </Monitor.ColumnsContainer>
            <Monitor.ColumnsContainer>
                <BoxContainer gap="4px">
                    <Monitor.ValueText>{data.lowCount || 0}</Monitor.ValueText>
                    <InProgressText>{data.lowInProgressCount}</InProgressText>
                </BoxContainer>
                <StatusText statusColor={(theme) => theme.palette.primary.main}>
                    <IssueStatusText icon={Icons.ArrowDownward}>Low</IssueStatusText>
                </StatusText>
            </Monitor.ColumnsContainer>
        </>
    ) : (
        <Monitor.ColumnsContainer>
            <Monitor.ValueText>None</Monitor.ValueText>
            {record.archived ? null : (
                <IssueDrawerToggler {...createConfig}>
                    {(action) => (
                        <Typography
                            component="span"
                            onClick={action}
                            sx={{ cursor: 'pointer ' }}
                            color="primary.main"
                            aria-label="ADD-ISSUE"
                            variant="componentButtonSmall"
                        >
                            ADD ISSUE
                        </Typography>
                    )}
                </IssueDrawerToggler>
            )}
        </Monitor.ColumnsContainer>
    )
    return (
        <Monitor>
            <Monitor.Title statusText="OPEN">Issues by Priority</Monitor.Title>
            <Monitor.Container>{content}</Monitor.Container>
        </Monitor>
    )
}
