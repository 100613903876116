import { type FC } from 'react'

import { useRecordContext } from 'react-admin'

import { Header, InnerHeader, ShowHeaderSkeleton, type TabType } from 'components'
import { type Action, deleteOneAction, exportAction } from 'core'
import {
    type PartModel,
    type VendorPartModel,
    partFields,
    vendorPartsResource,
} from 'resources/parts'

import { deleteVendorPartAction } from '../../config/constants'

const VendorPartShowHeader: FC<{ tabs: TabType[] }> = ({ tabs }) => {
    const record = useRecordContext<PartModel>()

    if (!record) {
        return <ShowHeaderSkeleton />
    }

    return (
        <InnerHeader
            tabs={tabs}
            tabWidth="192px"
            tabMinWidth="176px"
            actions={actions}
        >
            <Header.Info
                avatar={
                    <Header.Avatar
                        imageSrc={record.image}
                        defaultImage={<partFields.avatar.Vendor />}
                    />
                }
            >
                <Header.Content>
                    <Header.Title>{record.number}</Header.Title>
                </Header.Content>
                <Header.Content mobilePlacement="bottom">{record.description}</Header.Content>
            </Header.Info>
        </InnerHeader>
    )
}

export default VendorPartShowHeader

const actions: Action<VendorPartModel> = (record, { children }) => [
    exportAction({
        children,
    }),
    deleteOneAction({
        children,
        redirectTo: {
            resource: vendorPartsResource.resource,
            type: 'list',
        },
        ...deleteVendorPartAction,
    }),
]
