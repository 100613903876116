import { type NoInfer } from 'appTypes'
import { type Column, type ListFilterChoice, type DataListDetails } from 'components'
import { type AuthStore } from 'core'

import { type UnitModel } from '../types'

const source = 'domicile' satisfies keyof UnitModel

const label = 'Domicile'

const tableColumn = <Source extends string = typeof source>({
    id,
    label: labelProp,
    dataToValue,
    auth,
}: {
    id?: Source
    label?: string
    dataToValue: (data) => UnitModel[typeof source]
    auth: AuthStore
}) =>
    auth.companySettings.hasDomiciles
        ? ({
              field: (id || source) as NoInfer<Source>,
              headerName: labelProp || label,
              renderCell: ({ row }) => dataToValue(row),
          } as const satisfies Column)
        : null

const dataCardRow = <Source extends string = typeof source>({
    id,
    dataToValue,
    auth,
}: {
    id?: Source
    dataToValue: (data) => UnitModel[typeof source]
    auth: AuthStore
}) =>
    auth.companySettings.hasDomiciles
        ? ({
              source: (id || source) as NoInfer<Source>,
              label,
              render: (_, data) => dataToValue(data),
          } as const satisfies DataListDetails<any, any>)
        : null

const sort = <Source extends string = typeof source>({ auth }: { auth: AuthStore }) =>
    auth.companySettings.hasDomiciles
        ? ({
              id: source as NoInfer<Source>,
              label,
          } as const)
        : null

const filter = <Source extends string = typeof source>({
    id,
    label: labelProp,
    auth,
}: {
    id?: Source
    label?: string
    auth: AuthStore
}) =>
    auth.companySettings.hasDomiciles
        ? ({
              id: id || source,
              label: labelProp || label,
          } as const satisfies ListFilterChoice)
        : null

export const domicile = {
    source,
    label,
    tableColumn,
    dataCardRow,
    sort,
    filter,
}
