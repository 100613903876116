import { type FC, type ReactNode } from 'react'

import Icons from 'assets/icons'
import { useUtilityDrawerContext, Form, useOpenUtilityDrawer } from 'components'
import { useSubmit, serialize, type Serializer } from 'core'
import {
    type IntegrationConnectDrawerConfig,
    type IntegrationConnectLinkConfig,
    type IntegrationType,
} from 'resources/telematics'
import { Button, type ButtonProps } from 'ui'

import useConnect from './useConnect'

interface ConnectButtonProps {
    integration: IntegrationType
}

const ConnectButtonView: FC<
    ConnectButtonProps & Pick<ButtonProps, 'href'> & { onClick?: () => void }
> = ({ integration, ...props }) => {
    return (
        <Button
            startIcon={<Icons.LinkOutlined />}
            variant="text"
            color="primary"
            size="medium"
            {...props}
        >
            Connect
        </Button>
    )
}

export const ConnectButtonLink: FC<ConnectButtonProps> = (props) => {
    return (
        <ConnectButtonView
            {...props}
            href={(props.integration.connect as IntegrationConnectLinkConfig).url}
        />
    )
}

export const ConnectButtonDrawer: FC<ConnectButtonProps> = ({ integration }) => {
    const open = useOpenUtilityDrawer()

    const connectConfig = integration.connect as IntegrationConnectDrawerConfig

    return (
        <ConnectButtonView
            integration={integration}
            onClick={() => {
                open({
                    drawerArgs: {
                        title: 'Connect integration',
                        renderBottomRight: (render) => render({ label: 'Connect', icon: null }),
                        renderContent: () => <connectConfig.Content />,
                        renderWrapper: (props) => (
                            <Wrapper
                                {...props}
                                serializer={connectConfig.serializer}
                                integration={integration}
                            />
                        ),
                    },
                })
            }}
        />
    )
}

interface WrapperProps {
    children: ReactNode
    integration: IntegrationType
    serializer?: Serializer
}

const Wrapper: FC<WrapperProps> = ({ children, integration, serializer }) => {
    const connect = useConnect()
    const { forceClose } = useUtilityDrawerContext()

    const onSubmit = useSubmit(async (data) => {
        await connect(integration.provider, serialize(data, serializer), (err) => {
            throw err
        })
        forceClose()
    })

    return <Form onSubmit={onSubmit}>{children}</Form>
}
