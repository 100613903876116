import { type FC, type ReactNode } from 'react'

import Icons from 'assets/icons'
import { useCreateResourcePath } from 'core/resource'
import { createReportResource } from 'resources/reports'
import { woResource, woFields, WoPriorityKeys, WoStatusKeys } from 'resources/workOrders'
import { SvgIcon, BoxContainer, StatusText } from 'ui'

import { Monitor } from '../MonitorComponents'

import { useMonitor } from './useMonitor'

interface DataType {
    highPriorityCount: number
    lowPriorityCount: number
    mediumPriorityCount: number
}

const ButtonText = ({ children, icon }: { children: ReactNode; icon: FC }) => {
    return (
        <BoxContainer gap="4px">
            <SvgIcon
                component={icon}
                inheritViewBox
                color="inherit"
                sx={{ width: '10px', height: '10px' }}
            />
            {children}
        </BoxContainer>
    )
}
const MonitorWOPriority: FC = () => {
    const createPath = useCreateResourcePath()
    const data = useMonitor<DataType>(createReportResource('work-orders-by-priority'))

    if (!data) {
        return <Monitor.Skeleton />
    }
    const createLink = (priority: WoPriorityKeys) => {
        return (
            createPath({ resource: woResource.resource, type: 'list' }) +
            '?filter=' +
            encodeURIComponent(
                JSON.stringify({
                    priority: [priority],
                    status: ['OPEN'],
                }),
            )
        )
    }

    return (
        <Monitor>
            <BoxContainer gap="4px">
                <Monitor.Title
                    icon={woFields.avatar.Icon}
                    statusText="OPEN"
                >
                    Work Orders by Priority
                </Monitor.Title>
            </BoxContainer>
            <Monitor.ArrowButton
                navigateTo={
                    createPath({
                        resource: woResource.resource,
                        type: 'list',
                    }) +
                    '?filter=' +
                    encodeURIComponent(JSON.stringify({ status: [WoStatusKeys.OPEN] }))
                }
            />
            <Monitor.Container>
                <Monitor.Button navigateTo={createLink(WoPriorityKeys.HIGH)}>
                    <Monitor.ValueText>{data.highPriorityCount}</Monitor.ValueText>
                    <StatusText statusColor={(theme) => theme.palette.charts.red}>
                        <ButtonText icon={Icons.ArrowUpward}>
                            {woFields.priority.getConfig(WoPriorityKeys.HIGH).text}
                        </ButtonText>
                    </StatusText>
                </Monitor.Button>
                <Monitor.Button navigateTo={createLink(WoPriorityKeys.MEDIUM)}>
                    <Monitor.ValueText>{data.mediumPriorityCount}</Monitor.ValueText>
                    <StatusText statusColor={(theme) => theme.palette.charts.orange}>
                        <ButtonText icon={Icons.DragHandle}>
                            {woFields.priority.getConfig(WoPriorityKeys.MEDIUM).text}
                        </ButtonText>
                    </StatusText>
                </Monitor.Button>
                <Monitor.Button navigateTo={createLink(WoPriorityKeys.LOW)}>
                    <Monitor.ValueText>{data.lowPriorityCount}</Monitor.ValueText>
                    <StatusText statusColor={(theme) => theme.palette.primary.main}>
                        <ButtonText icon={Icons.ArrowDownward}>
                            {woFields.priority.getConfig(WoPriorityKeys.LOW).text}
                        </ButtonText>
                    </StatusText>
                </Monitor.Button>
            </Monitor.Container>
        </Monitor>
    )
}

export default MonitorWOPriority
