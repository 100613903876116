import { observer } from 'mobx-react'

import Icons from 'assets/icons'
import { alpha } from 'lib'
import { type IntegrationType, hasIntegrationActiveTasks } from 'resources/telematics'
import { IconButton, HideOnBreakpoint, Button } from 'ui'

import useIntegrationDrawerToggler from '../../components/useIntegrationDrawerToggler'

export interface ConfigureButtonProps {
    integration: IntegrationType
}

const ConfigureButton = observer(({ integration }: ConfigureButtonProps) => {
    const openDrawer = useIntegrationDrawerToggler()
    const hasTasks = hasIntegrationActiveTasks(integration.provider)

    return (
        <HideOnBreakpoint
            breakpoint="sm"
            replaceWith={
                <IconButton
                    disabled={hasTasks}
                    size="small"
                    sx={{
                        color: (theme) => alpha(theme.palette.text.primary, 0.54),
                    }}
                    onClick={() => openDrawer(integration)}
                >
                    <Icons.SettingsOutlined />
                </IconButton>
            }
        >
            <Button
                disabled={hasTasks}
                size="large"
                startIcon={<Icons.SettingsOutlined />}
                onClick={() => openDrawer(integration)}
            >
                Configure
            </Button>
        </HideOnBreakpoint>
    )
})

export default ConfigureButton
