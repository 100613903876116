import { type CustomerModel } from '../types'

const source: keyof CustomerModel = 'state'

const state = {
    source,
    label: 'State',
}

export default state
