import { type Dispatch, useEffect } from 'react'

import { useFormContext } from 'react-hook-form'

import { preventSubmitError } from 'components/form/utils'
import { useFormInfo } from 'context'
import { formatDate } from 'lib'
import { unitFields } from 'resources/units'

import assignmentFields from '../fields'

const AssignmentTimeInputsHandler = ({
    setEndInputMessage,
}: {
    setEndInputMessage: Dispatch<string>
}) => {
    const { watch, setError, formState, clearErrors } = useFormContext()
    const { submitValidate } = useFormInfo()
    const unit = watch(unitFields.self.source)
    const start = watch(assignmentFields.startDate.source)
    const end = watch(assignmentFields.endDate.source)

    useEffect(() => {
        if (!(formState.touchedFields.start || formState.touchedFields.end)) {
            return
        }

        if ((!start && !end) || !unit) {
            return
        }

        const getError = async () => {
            clearErrors([
                assignmentFields.startDate.source,
                assignmentFields.endDate.source,
                preventSubmitError.name,
            ])
            setEndInputMessage(null)

            const [err, data] = await submitValidate({ unit, start, end })

            if (data?.end) {
                setEndInputMessage(
                    data?.end.code === 'max_date'
                        ? `The end date & time cannot be later than ${formatDate(data.end.message, 'Pp')}`
                        : data.end.message,
                )
            }

            if (!err) {
                return
            }

            setError(preventSubmitError.name, preventSubmitError.error)
            Object.keys(err).forEach((field) => {
                if (field === assignmentFields.startDate.source) {
                    return
                }

                const error = err[field]
                setError(field, error)
            })

            if (err[assignmentFields.startDate.source]) {
                setError(
                    assignmentFields.startDate.source,
                    err[assignmentFields.endDate.source]
                        ? ''
                        : err[assignmentFields.startDate.source],
                )
            }
        }
        getError()
    }, [start, end])

    return null
}

export default AssignmentTimeInputsHandler
