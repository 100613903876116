import { type FC, type ReactNode } from 'react'

import { clsx, globalClassNames, withColor } from 'lib'
import { Typography } from 'ui'

import headerClasses from './config'

interface Props {
    children: ReactNode
}

/**
 * Main title
 */
const HeaderTitle: FC<Props> = ({ children }: Props) => (
    <Typography
        typography={{ xs: 'h6', sm: 'h5' }}
        color={withColor('text.primary')}
        className={clsx(headerClasses.title, globalClassNames.ellipsis)}
    >
        {children}
    </Typography>
)

export default HeaderTitle
