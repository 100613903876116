import { type Identifier } from 'appTypes'
import { type Palette } from 'lib'

import { type UncategorizedJobItems } from '../CostPerCategoryWidget/includeUncategorized'
import { type JobItemsCalculations, type JobItemTotals } from '../components/types'

export type maxTotalReasonsType = {
    code: string
    description: string
    type: string
    id: Identifier
    total: number
    totalPercent: number
} & JobItemTotals

export interface ResponseType extends JobItemsCalculations, UncategorizedJobItems {
    maxTotalReasonsForRepair: maxTotalReasonsType[]
}
export interface QueryResponse
    extends Omit<ResponseType, 'maxTotalReasonsForRepair' | 'uncategorized'> {
    list: listType
}
export interface listType {
    data: maxTotalReasonsType[]
    top3Percentage: number
    top3Total: number
    sumOfExcludedReasons: number
    sumPercentageOfExcludedReasons: number
}

type Top3ReasonsColorProps = keyof Palette['charts']
export const statusColors: Top3ReasonsColorProps[] = ['red', 'yellowBody', 'green']
