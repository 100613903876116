import { type ReactNode } from 'react'

import { styled } from 'lib'
import { Typography } from 'ui'

export const infoCardDetailsGap = '10px'

export const InfoCardRow = styled('div')`
    margin-bottom: ${infoCardDetailsGap};
    &:last-child {
        margin-bottom: 0px;
    }
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 10px;
`
export const InfoCardLabelTypography = styled(Typography)`
    text-transform: uppercase;
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;

    & svg {
        margin-right: 4px;
        width: 16px;
    }
`
export const InfoCardValueTypography = styled(Typography)<{
    component: ReactNode
}>`
    box-orient: vertical;
    min-width: 0;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    & svg {
        vertical-align: middle;
        width: 15px;
    }
`
