import { useClearResource, RaResourceContextProvider } from 'core'

import MembersList from './List/MembersList'

const Members = () => {
    const membersResourceName = 'members'
    useClearResource(membersResourceName)
    return (
        <RaResourceContextProvider
            value={{ resource: membersResourceName, name: membersResourceName }}
        >
            <MembersList />
        </RaResourceContextProvider>
    )
}

export default Members
