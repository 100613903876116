import { useEffect, useState } from 'react'

import { modalClasses } from 'ui'

const useSliderFreeze = (wrapper: React.MutableRefObject<HTMLDivElement>) => {
    const [freeze, setFreeze] = useState(false)

    useEffect(() => {
        const freezeHandle = () => {
            if (freeze) {
                return
            }

            setTimeout(() => {
                const modal = document.querySelector(`body > .${modalClasses.root}`)

                if (modal) {
                    const observer = new MutationObserver(() => {
                        const modal = document.querySelector(`body > .${modalClasses.root}`)
                        if (!modal) {
                            setFreeze(false)
                            observer.disconnect()
                        }
                    })
                    observer.observe(document.body, { childList: true })

                    setFreeze(true)
                }
            }, 0)
        }
        if (wrapper.current) {
            wrapper.current.addEventListener('mouseup', freezeHandle)
            wrapper.current.addEventListener('touchend', freezeHandle)
        }
    }, [])
    return freeze
}
export default useSliderFreeze
