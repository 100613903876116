import { matchPath, useLocation } from 'react-router-dom'

import { pathJoin } from 'utils'

import { type NavItemConfig } from './types'

export const useIsActive = (menu: NavItemConfig[]) => {
    const location = useLocation()
    const activeElements = findActiveKey(location.pathname, menu)
    return activeElements || {}
}

interface ActiveElements {
    [key: string]: boolean
}

function findActiveKey(
    currentPath: string,
    items: NavItemConfig[],
    activeElements = {},
): ActiveElements {
    for (const item of items) {
        const path = item.path
        if (path && matchPath(path === '/' ? path : pathJoin(path, '/*'), currentPath)) {
            activeElements[item.key] = true
            return activeElements
        }

        if (item.items) {
            const active = findActiveKey(currentPath, item.items, activeElements)
            if (active) {
                activeElements[item.key] = true
                return activeElements
            }
        }
    }

    return null
}
