import { type JobItemsCalculations } from '../components/types'

export type UncategorizedJobItems = {
    uncategorized: number
    uncategorizedTax: number
    uncategorizedServices: number
    uncategorizedFees: number
    uncategorizedLabor: number
    uncategorizedParts: number
}

const includeUncategorized = <T extends { id: string | number; total: number }>(
    ratioData: Partial<UncategorizedJobItems & JobItemsCalculations>,
    initialArray: T[],
) => {
    return [
        {
            id: 'uncategorized',
            description: 'Uncategorized',
            code: null,
            total: ratioData.uncategorized || undefined,
            totalPercent: ratioData.total
                ? (ratioData.uncategorized / ratioData.total) * 100
                : null,
            totalFees: ratioData.uncategorizedFees,
            totalTax: ratioData.uncategorizedTax,
            totalServices: ratioData.uncategorizedServices,
            totalLabor: ratioData.uncategorizedLabor,
            totalParts: ratioData.uncategorizedParts,
        },
        ...initialArray,
    ]
        .filter((a) => typeof a.total === 'number')
        .sort((a, b) => b.total - a.total)
}
export default includeUncategorized
