import { type FC } from 'react'

import Icons from 'assets/icons'
import { ViewHeader } from 'components'
import { IconBox, InfoBadge, NonDisplayedInput } from 'ui'

import { MAX_PHOTO_COUNT } from '../utils'

interface Props {
    photosCount: number
    upload?: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>
}

const PhotoTabHeader: FC<Props> = ({ photosCount, upload }) => {
    const maxReached = photosCount === MAX_PHOTO_COUNT

    return (
        <ViewHeader title="Photos">
            <ViewHeader.Content>
                <InfoBadge badgeContent={photosCount || 0} />
            </ViewHeader.Content>

            {upload ? (
                <ViewHeader.Content placement="rightMobile">
                    <label>
                        <NonDisplayedInput
                            type="file"
                            disabled={maxReached}
                            onChange={upload}
                            accept="image/*"
                        />
                        <IconBox
                            title={
                                maxReached
                                    ? 'Maximum image limit (10) reached'
                                    : 'Add up to 10 images, max size 30 MB each'
                            }
                            disabled={maxReached}
                        >
                            <Icons.UploadFileOutlined />
                        </IconBox>
                    </label>
                </ViewHeader.Content>
            ) : null}
        </ViewHeader>
    )
}

export default PhotoTabHeader
