import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import {
    type TabType,
    type HeaderActionsProps,
    Header,
    InnerHeader,
    ShowHeaderSkeleton,
} from 'components'
import { deleteOneAction } from 'core/actions'
import { issueFields, type IssueModel } from 'resources/issues'
import { unitFields } from 'resources/units'
import { AutoGrid } from 'ui'

import { AddToWOButton, ManualResolve } from '../../components'
import { deleteConfirmConfig } from '../../utils'

const actions: HeaderActionsProps<IssueModel>['actions'] = (record, { children }) => {
    return [
        <ManualResolve
            children={(action) =>
                children({
                    onClick: action,
                    Icon: Icons.HowToRegOutlined,
                    title: 'Manual Resolve',
                    disabled: record.status === 'CLOSED',
                    titleOnDisabled: 'The Issue is resolved',
                })
            }
            key="manual-resolve"
        />,
        deleteOneAction({
            children: (params) =>
                children({ ...params, titleOnDisabled: 'Closed Issues cannot be deleted' }),
            confirmConfig: deleteConfirmConfig(record),
            disabled: record.status === 'CLOSED',
        }),
    ]
}

const IssueShowHeader = ({ tabs }: { tabs: TabType[] }) => {
    const record = useRecordContext<IssueModel>()

    if (!record) {
        return <ShowHeaderSkeleton />
    }

    return (
        <InnerHeader
            actions={actions}
            tabs={tabs}
            tabWidth="200px"
            mainAction={<AddToWOButton />}
        >
            <Header.Info avatar={<Header.Avatar defaultImage={<issueFields.avatar.Icon />} />}>
                <Header.Content>
                    <Header.Title>{issueFields.name.value(record, false)}</Header.Title>
                </Header.Content>
                <Header.Content mobilePlacement="bottom">
                    <AutoGrid.Row>
                        <AutoGrid.Column>{issueFields.status.value(record)}</AutoGrid.Column>
                        <AutoGrid.Column>
                            {unitFields.self.linkWithIconValue(record.unitData)}
                        </AutoGrid.Column>
                    </AutoGrid.Row>
                </Header.Content>
            </Header.Info>
        </InnerHeader>
    )
}

export default IssueShowHeader
