import { type FC } from 'react'

import { landingPageUrl } from 'configs'
import { Box, type BoxProps, Typography, Anchor } from 'ui'

export const NoAccountText: FC<BoxProps> = (props) => {
    return (
        <Box
            display="flex"
            justifyContent="center"
            {...props}
        >
            <Typography display="inline">Don't have an account ?&nbsp;</Typography>

            <Anchor
                href={landingPageUrl.contact}
                target="_blank"
                rel="noopener noreferrer"
            >
                Sign up!
            </Anchor>
        </Box>
    )
}
