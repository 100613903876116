import { type ReactNode, useEffect, useId } from 'react'

import { useListContext, type ListControllerProps, type ListControllerResult } from 'react-admin'

import { type DataRecord } from 'appTypes'
import { type DataCardAvatarProps, type DataCardProps } from 'components/DataCard'
import { SkeletonSliderCardContainer } from 'components/Skeletons'
import { type Action, type SingleAction } from 'core/actions'
import { styled, type Theme, type Flags } from 'lib'
import { Stack, GridContainerGrid, GridItemCard, BoxContainer, Skeleton } from 'ui'

import CardListCard from './CardListCard'

export interface CardListConfig<RecordType extends DataRecord = any>
    extends Omit<
        DataCardProps,
        | 'record'
        | 'title'
        | 'subtitle'
        | 'image'
        | 'details'
        | 'defaultImage'
        | 'color'
        | 'id'
        | 'actions'
        | 'actionsDisabled'
        | 'collapsibleContent'
        | 'avatarColor'
    > {
    collapsibleContent?: ReactNode | ((record: RecordType) => ReactNode)
    titleSource: keyof RecordType | ((record: RecordType) => ReactNode)
    titleLink?: string | ((record: RecordType) => string)
    subTitleSource?:
        | keyof RecordType
        | ((
              record: RecordType,
              params: {
                  flags: Flags
                  listContext: ListControllerResult<RecordType>
              },
          ) => ReactNode)
    imageSource?: keyof RecordType | ((record: RecordType) => string)
    defaultImage?:
        | DataCardProps['defaultImage']
        | ((record: RecordType) => DataCardProps['defaultImage'])
    avatarColor?: (theme: Theme, record: RecordType) => DataCardProps['avatarColor']
    avatarOpacity?: DataCardProps['avatarOpacity']
    avatarProps?: (
        record: RecordType,
    ) => Pick<DataCardAvatarProps, 'color' | 'avatarOpacity' | 'defaultImage'>
    actions?: Action<RecordType>
    action?: SingleAction<RecordType>
    actionsDisabled?: (record: RecordType) => boolean
    details:
        | {
              [K in keyof RecordType]: DataListDetails<RecordType, K>
          }[keyof RecordType][]
        | ((record: RecordType) => ReactNode)
}

export interface DataListDetails<RecordType, K extends keyof RecordType> {
    source: K
    label?: (({ record }: { record: RecordType }) => ReactNode) | ReactNode
    render?: (value: RecordType[K], record: RecordType) => ReactNode
}
export interface CardListProps<RecordType extends DataRecord = any> extends ListControllerProps {
    cardsCfg: CardListConfig<RecordType>
    disableSelectRecord?: (record: RecordType) => string
    loading: boolean
}

const StyledGridContainer = styled(GridContainerGrid)`
    &:last-of-type {
        padding-bottom: 24px;
    }
`
const useSetCardsHeight = (id: string, isLoading: boolean) => {
    useEffect(() => {
        const card = document.querySelector(`[id="${id}"] .data-card`)
        if (card) {
            const grid = document.querySelector(`[id="${id}"]`) as HTMLElement
            grid.style.setProperty('--maxHeight', `${card.getBoundingClientRect().height}px`)
        }
    }, [isLoading])
}
export const ListCardSkeleton = ({ height }: { height: `${number}px` }) => {
    return (
        <GridItemCard>
            <SkeletonSliderCardContainer
                sx={{
                    height,
                    gap: '10px',
                }}
            >
                <BoxContainer>
                    <Skeleton
                        height="40px"
                        width="40px"
                        variant="circular"
                        sx={{
                            flexShrink: 0,
                        }}
                    />
                    <Stack
                        marginLeft="16px"
                        spacing="12px"
                        width="100%"
                    >
                        <Skeleton height="8px" />
                        <Skeleton
                            height="8px"
                            sx={{
                                maxWidth: '100px',
                            }}
                        />
                    </Stack>
                </BoxContainer>
                <Skeleton />
            </SkeletonSliderCardContainer>
        </GridItemCard>
    )
}
const cardListSkeleton = (
    <StyledGridContainer>
        {new Array(50).fill(0).map((v, i) => (
            <ListCardSkeleton
                height="272px"
                key={i}
            />
        ))}
    </StyledGridContainer>
)

const CardList = <RecordType extends DataRecord = any>({
    cardsCfg,
    disableSelectRecord,
    loading,
    ...props
}: CardListProps<RecordType>) => {
    const { data, isLoading } = useListContext<RecordType>(props)

    const id = useId()
    useSetCardsHeight(id, isLoading)

    if (loading || isLoading) {
        return cardListSkeleton
    }

    return (
        <StyledGridContainer
            id={id}
            sx={{
                '--maxHeight': '100%',
                position: 'relative',
                zIndex: '1',
                '& .data-card': {
                    maxHeight: 'var(--maxHeight)',
                },
            }}
        >
            {data.map((record, i) => (
                <CardListCard
                    record={record}
                    cardsCfg={cardsCfg}
                    key={record.id}
                    sx={{ zIndex: data.length - i, position: 'relative' }}
                    disableSelectRecord={disableSelectRecord}
                />
            ))}
        </StyledGridContainer>
    )
}

export default CardList
