import { type FC } from 'react'

import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import { TextInput } from 'components'
import {
    type ActionChildren,
    maxLengthValidationText,
    requiredValidation,
    useConfirm,
    useCanAccess,
    useResourcePermissions,
} from 'core'
import { Alert } from 'ui'

import { type WorkOrderModel, WoStatusKeys } from '../../types'
import { woPermissions, woResource } from '../../utils'
import { woOperations } from '../actionPermissions'

import { WoActionKeys } from './types'
import useUpdateWoStatus from './useUpdateStatus'

export const useWoCancelAction = () => {
    const record = useRecordContext<WorkOrderModel>()
    const permissions = useResourcePermissions(woResource)

    return function (render: ActionChildren) {
        if (!record || record.status !== WoStatusKeys.OPEN) {
            return null
        }
        const permission = permissions[woPermissions.cancel]

        if (!permission) {
            return null
        }

        return <StatusAction key="wo-cancel">{render}</StatusAction>
    }
}

const StatusAction: FC<{ children: ActionChildren }> = ({ children }) => {
    const record = useRecordContext<WorkOrderModel>()
    const updateStatus = useUpdateWoStatus()
    const confirm = useConfirm()
    const getCanAccess = useCanAccess()
    const canAccess = getCanAccess(woOperations.cancel)

    return children({
        Icon: Icons.CancelOutlined,
        title: 'Cancel',
        disabled: !canAccess.value,
        titleOnDisabled: canAccess.message,
        onClick: async () => {
            confirm({
                title: 'Are you sure you want to cancel this work order?',
                closeOnError: false,
                content: (
                    <>
                        <Alert
                            severity="warning"
                            sx={{ mb: '22px' }}
                        >
                            Cancelling a work order is an irreversible action and will delete its
                            jobs and items. Any connected PM Intervals, DVIR Defects or Issues will
                            be detached. Issues will be returned to an Open status.
                        </Alert>
                        <TextInput
                            source="reason"
                            validate={descriptionValidation}
                            label="Reason for Cancellation"
                            multiline
                            rows={3}
                        />
                    </>
                ),
                onConfirm: async ({ formValues }) => {
                    await updateStatus(record.id, WoActionKeys.CANCEL, formValues)
                },
                confirmButtonProps: {
                    children: 'Confirm',
                },
                awaitOnConfirm: true,
            })
        },
    })
}

const descriptionValidation = [maxLengthValidationText, requiredValidation]
