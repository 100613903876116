import { inject } from 'mobx-react'

import images from 'assets/images'
import { type AuthStore, useLogout } from 'core'
import { globalClassNames } from 'lib'
import { Card, Anchor, Stack, Button, Typography, BoxContainer, Img } from 'ui'

const NoRepairShops = inject('auth')(({ auth }: { auth?: AuthStore }) => {
    return (
        <BoxContainer
            p="20px"
            justifyContent="center"
            flexGrow={1}
        >
            <Card
                sx={{
                    gap: '24px',
                    width: '335px',
                    maxWidth: '100%',
                    p: { xs: '48px 32px', sm: '32px 24px' },
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    boxSizing: 'border-box',
                }}
            >
                <Img
                    src={images.noShops}
                    sx={{ width: '160px', height: '160px' }}
                />
                <Typography variant="h5">No Assigned Repair Shops</Typography>
                <Typography variant="body1">
                    Assign a repair shop to this user to get started with Fleetpal.
                </Typography>
                <Stack
                    gap="8px"
                    p="14px"
                    borderRadius="4px"
                    bgcolor={(theme) => theme.palette.info.background}
                    width="100%"
                    boxSizing="border-box"
                    overflow="hidden"
                >
                    <Typography variant="subtitle1">Please Contact</Typography>
                    <InfoRow
                        text="First Registered Person"
                        email={auth.currentCompany.firstMemberEmail}
                    />
                    {auth.currentCompany.email ? (
                        <InfoRow
                            text="Company Email"
                            email={auth.currentCompany.email}
                        />
                    ) : null}
                </Stack>
                <LogoutButton />
            </Card>
        </BoxContainer>
    )
})

const InfoRow = ({ text, email }: { text: string; email: string }) => {
    return (
        <div className={globalClassNames.ellipsis}>
            <Typography variant="body1">{text}</Typography>
            <Anchor
                variant="body2"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                href={`mailto:${email}`}
            >
                {email}
            </Anchor>
        </div>
    )
}

export default NoRepairShops

const LogoutButton = () => {
    const logout = useLogout()

    return (
        <Button
            size="large"
            onClick={() => logout()}
        >
            back to login
        </Button>
    )
}
