import { styled } from 'lib'

import Box from './Box'

const BoxContainer = styled(Box)`
    display: flex;
    align-items: center;
    box-sizing: border-box;
`

export default BoxContainer
