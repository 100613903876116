import { type NoInfer } from 'appTypes'
import { type ListFilterChoice, type Column, type DataListDetails } from 'components'

import { type UnitModel } from '../types'

const source = 'serialNumber' satisfies keyof UnitModel

const label = 'Serial Number'

const tableColumn = <Source extends string = typeof source>({
    id,
    dataToValue,
}: {
    id?: Source
    dataToValue: (data) => UnitModel[typeof source]
}) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: label,
        renderCell: ({ row }) => dataToValue(row),
    }) as const satisfies Column

const dataCardRow = <Source extends string = typeof source>({
    id,
    dataToValue,
}: {
    id?: Source
    dataToValue: (data) => UnitModel[typeof source]
}) =>
    ({
        source: (id || source) as NoInfer<Source>,
        label,
        render: (_, data) => dataToValue(data),
    }) as const satisfies DataListDetails<any, any>

const sort = <Source extends string = typeof source>() =>
    ({
        id: source as NoInfer<Source>,
        label,
    }) as const

const filter = () =>
    ({
        id: source,
        label,
    }) as const satisfies ListFilterChoice

export const serialNumber = {
    source,
    label,
    tableColumn,
    dataCardRow,
    sort,
    filter,
}
