import { useMemo } from 'react'

import { ListContext, useRecordContext, type ListControllerResult } from 'react-admin'

import {
    type DatagridColumnsProps,
    datagridEmptyCell,
    formatMoney,
    List,
    ListViewProvider,
} from 'components'
import { perPageLimit } from 'core'
import { ResourcePreferencesProvider } from 'core/context'
import { type UnitModel } from 'resources/units'
import { ck33Fields, CK33Levels, type CK33Model } from 'resources/vmrs'
import { Anchor } from 'ui'

import { useCostReportContext } from '../../CostReportContext'
import { useComponentDrilldown, useCrossDrilldown, usePeriodDrilldown } from '../../components'

import { CardView } from './components'

const ListSection = () => {
    const { data, isLoading, resource, appliedFilter, selectedDate } = useCostReportContext()
    const record = useRecordContext<UnitModel>()
    const periodDrilldown = usePeriodDrilldown()
    const componentDrilldown = useComponentDrilldown()
    const crossDrilldown = useCrossDrilldown()

    const datesColumns: DatagridColumnsProps<CK33Model>['columns'] =
        data?.dates.map((date): DatagridColumnsProps<CK33Model>['columns'][number] => {
            return {
                field: date.field as keyof CK33Model,
                headerName: String(date.date),
                align: 'right',
                pinnable: false,
                renderCell: ({ row }) => {
                    if (row.id === totalCostField) {
                        if (data.dateTotals[date.source] == null) {
                            return formatMoney(0)
                        }
                        return renderTotal(data.dateTotals[date.source], () => {
                            periodDrilldown(date.source)
                        })
                    }

                    if (row.level === CK33Levels.SYSTEM) {
                        return datagridEmptyCell
                    }

                    return renderTotal(data.totals[row.id]?.[date.source], () =>
                        crossDrilldown({ component: row.id, dateCompleted: date.source }),
                    )
                },
            }
        }) || []

    const columnsConfig: DatagridColumnsProps<CK33Model & { grandTotal: number }> = {
        columns: [
            {
                field: 'code',
                headerName: 'Component Code',
                renderCell: ({ row }) => renderCode(row),
            },
            {
                field: ck33Fields.description.source,
                headerName: ck33Fields.description.label,
                renderCell: ({ row }) => (row.id === totalCostField ? datagridEmptyCell : row.text),
            },
            ...datesColumns,
            {
                field: 'grandTotal',
                headerName: 'Total by Component',
                align: 'right',
                renderCell: ({ row }) => {
                    if (row.id === totalCostField) {
                        return formatMoney(data.grandTotal)
                    }

                    if (row.level === CK33Levels.SYSTEM) {
                        return datagridEmptyCell
                    }

                    return renderTotal(data.componentTotals[row.id], () =>
                        componentDrilldown({ component: row.id, dateCompleted: selectedDate }),
                    )
                },
            },
        ],
        hideFooter: true,
        checkboxSelection: false,
        actions: null,
        pinnedColumns: {
            right: ['grandTotal'],
        },
        mainField: 'code',
        disableColumnReorder: true,
    }

    const value = useMemo(() => {
        return {
            resource,
            filter: appliedFilter,
            data: data?.components && [...data.components, { id: totalCostField }],
            isLoading,
            displayedFilters: {},
            page: 1,
            perPage: perPageLimit,
            selectedIds: [],
            total: data?.components.length || 0,
        } as ListControllerResult
    }, [data, isLoading])

    return (
        <ResourcePreferencesProvider resource="report-unit-cost">
            <ListViewProvider>
                <ListContext.Provider value={value}>
                    <List
                        columnsCfg={columnsConfig}
                        hideSearch
                        disableManageColumns
                        listFTUProps={{
                            title: 'No Cost Data Available',
                            linkText: '',
                        }}
                        exportFileName={`${record?.number}-cost-report-${selectedDate || 'complete'}`}
                        renderView={{
                            card: () => <CardView />,
                        }}
                    />
                </ListContext.Provider>
            </ListViewProvider>
        </ResourcePreferencesProvider>
    )
}

export default ListSection

const renderTotal = (total: number | undefined, onClick?: () => void) => {
    if (typeof total !== 'number') {
        return null
    }
    return <Anchor onClick={onClick}>{formatMoney(total)}</Anchor>
}

const renderCode = (data: CK33Model) => {
    if (data.id === totalCostField) {
        return 'Total Cost'
    }

    const code = ck33Fields.self.formatCode(data.code, data.level)

    if (data.level === CK33Levels.SYSTEM) {
        return code
    }

    return (
        <>
            &nbsp;&nbsp;&nbsp;&nbsp;
            {code}
        </>
    )
}

const totalCostField = 'total'
