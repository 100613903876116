import { type FC } from 'react'

import { type Identifier } from 'appTypes'
import { PurchaseInvoiceForm } from 'resources/purchaseInvoices'
import { Alert, Typography } from 'ui'

interface POInvoiceFormProps {
    getSource?: (source: string) => string
    defaultTerm: Identifier
}

const PoInvoiceForm: FC<POInvoiceFormProps> = ({ getSource, defaultTerm }) => {
    return (
        <>
            <Alert
                severity="info"
                sx={{ mb: '20px' }}
            >
                Match the receipt total with the invoice total to close the purchase order.
            </Alert>
            <Typography
                variant="subtitle1"
                mb="20px"
            >
                Add Invoice
            </Typography>
            <PurchaseInvoiceForm
                getSource={getSource}
                defaultValues={{
                    term: defaultTerm,
                }}
            />
        </>
    )
}

export default PoInvoiceForm
