import { ResourceWithClearEffect } from 'core'
import { useDataProviderConfig } from 'core/data'
import { vendorAspects, vendorsResource } from 'resources/vendors'

import VendorsList from './List'
import VendorShow from './Show'

const dpConfig = {
    // TODO: do we need that aspect here?
    queryParams: () => ({ withAspects: [vendorAspects.totals] }),
}

const Vendors = () => {
    useDataProviderConfig(vendorsResource.resource, {
        getOne: dpConfig,
        update: dpConfig,
    })
    return (
        <ResourceWithClearEffect
            name={vendorsResource}
            list={VendorsList}
            edit={VendorShow}
        />
    )
}

export default Vendors
