import { styled } from 'lib'
import { Card as MuiCard, Grid, gridClasses } from 'ui'

export type CardProps = {
    height: string
    width?: string
    heightsm?: string
    transparent?: boolean
    direction?: 'row' | 'column'
    directionsm?: 'row' | 'column'
}
const Card = styled(MuiCard, {
    shouldForwardProp: (prop) => prop !== 'transparent',
})<CardProps>`
    height: ${(props) => props.height};
    box-sizing: border-box;
    border-radius: 8;
    padding: 16px;
    display: flex;
    background-color: ${(props) => (props.transparent ? 'transparent' : '#fff')};
    box-shadow: ${({ theme, ...props }) =>
        props.transparent ? theme.shadows[0] : theme.shadows[1]};
    ${({ theme }) => theme.breakpoints.down('sm')} {
        height: ${(props) => (props.heightsm ? props.heightsm : props.height)};
    }
`
Card.defaultProps = {
    direction: 'column',
}
export const RowCard = styled(Card)`
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: flex-start;
    .content {
        align-items: flex-start;
    }
`

export const ColumnCard = styled(Card)`
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    .content {
        align-items: center;
    }
`
export const ListFtuCardContainer = styled(Grid)`
    height: 100%;
    & > .${gridClasses.root} {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`
