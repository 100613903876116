import { type FC } from 'react'

import { SelectInput } from 'components'

import { paymentMethods } from '../data'
import { type PaymentMethodType } from '../types'

interface Props {
    label?: string
    disabled?: boolean
    defaultValue?: PaymentMethodType
    source: string
}

export const PaymentMethodInput: FC<Props> = ({
    source,
    label = 'Payment Method',
    disabled,
    defaultValue,
}) => {
    return (
        <SelectInput
            source={source}
            label={label}
            disabled={disabled}
            disableEmptyValue
            defaultValue={defaultValue}
            choices={paymentMethods}
        />
    )
}
