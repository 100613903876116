import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import { ActionsMenu, copyAction } from 'core'
import { formatDate, globalClassNames } from 'lib'
import { type UnitModel } from 'resources/units'
import { Popper, IconButton, Divider, Box, ClickAwayListener, Typography } from 'ui'

interface MapInfoBoxProps {
    targetRef: HTMLDivElement
    close: () => void
    isOpen: boolean
}

const MapInfoBox = ({ targetRef, close, isOpen }: MapInfoBoxProps) => {
    const unit = useRecordContext<UnitModel>()
    const location = unit.location
    const hasDescription = Boolean(location.description)
    const locationHref = `https://www.google.com/maps/search/?api=1&query=${location.latitude},${location.longitude}`

    return (
        <Popper
            open={Boolean(isOpen)}
            anchorEl={targetRef}
            placement="top"
            disablePortal
            sx={{
                transform: 'translate(-50%, -50%) !important',
                top: 'calc(50% - 35px) !important',
                left: '50% !important',
                zIndex: 2,
            }}
        >
            <ClickAwayListener onClickAway={close}>
                <Box
                    p="12px"
                    width={hasDescription ? '256px' : '205px'}
                    bgcolor="white"
                    boxShadow={8}
                    borderRadius="4px"
                >
                    <Box
                        display="flex"
                        alignItems="flex-start"
                        gap="10px"
                    >
                        <Box
                            flexGrow="1"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            sx={{
                                'a[target="_blank"]': {
                                    display: 'flex!important',
                                },
                            }}
                        >
                            {hasDescription ? (
                                <Description description={location.description} />
                            ) : (
                                <Typography
                                    variant="menuItem"
                                    gap="16px"
                                    component="a"
                                    href={locationHref}
                                    target="_blank"
                                    rel="noreferrer"
                                    color="text.primary"
                                >
                                    <Icons.GoogleMapPin />
                                    View on Google Maps
                                </Typography>
                            )}
                        </Box>

                        {hasDescription && (
                            <ActionsMenu
                                renderToggler={(open) => (
                                    <IconButton
                                        onClick={open}
                                        size="small"
                                    >
                                        <Icons.Options fontSize="inherit" />
                                    </IconButton>
                                )}
                                iconBackgroundColor="transparent"
                                actions={(_, { children }) => [
                                    <a
                                        href={locationHref}
                                        target="_blank"
                                        rel="noreferrer"
                                        key="open-map"
                                    >
                                        {children({
                                            Icon: Icons.GoogleMapPin,
                                            title: 'View on Google Maps',
                                        })}
                                    </a>,
                                    copyAction({
                                        title: 'Copy Address',
                                        copyMessage: location.description,
                                        children,
                                    }),
                                ]}
                            />
                        )}
                    </Box>
                    <Divider sx={{ my: '8px' }} />
                    <Typography
                        variant="caption"
                        color="text.secondary"
                    >
                        Last Synced {formatDate(location.updated, 'MMM dd yyyy H:mm')}
                    </Typography>
                </Box>
            </ClickAwayListener>
        </Popper>
    )
}

export default MapInfoBox

const Description = ({ description }: { description: string }) => {
    // split description by first comma
    const [address, locationRest] = description.split(/,(.*)/s)

    return (
        <Typography
            variant="chartTitle"
            color="text.primary"
            display="inline"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
            }}
            className={globalClassNames.ellipsis}
        >
            <div>{address}</div>
            <div>{locationRest}</div>
        </Typography>
    )
}
