import { type ReactElement } from 'react'

import { useRecordContext, useResourceContext } from 'react-admin'

import { RaResourceContextProvider, useClearResource } from 'core'

export const ContactResource = ({
    children,
    resourceName,
}: {
    children: ReactElement
    resourceName: string
}) => {
    const { id } = useRecordContext() || {}
    const resource = useResourceContext()

    const contactsResource = `${resource}/${id}/contacts`

    useClearResource(contactsResource)

    return (
        <RaResourceContextProvider
            value={{
                resource: contactsResource,
                name: resourceName,
            }}
        >
            {children}
        </RaResourceContextProvider>
    )
}
