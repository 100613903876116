import { createContext, memo, type ReactNode, useCallback, useContext, useState } from 'react'

import { useFormActionContext } from 'context'

import UtilityDrawer from './UtilityDrawer'
import { type DrawerState } from './types'

export type UtilityDrawerOpen = (state: DrawerState) => void
interface State {
    key: number
    state: DrawerState
    isOpen: boolean
}

export const UtilityDrawersContext = createContext<UtilityDrawerOpen>(() => {
    /* */
})

export const useOpenUtilityDrawer = () => {
    const open = useContext(UtilityDrawersContext)
    const formActions = useFormActionContext()

    return ((params) => {
        open({
            ...params,
            extraArgs: {
                formActions,
                ...params.extraArgs,
            },
        })
    }) satisfies typeof open
}

const Drawer = memo(
    UtilityDrawer,
    (prevProps, nextProps) =>
        prevProps.isOpen === nextProps.isOpen && prevProps.state === nextProps.state,
)

export const UtilityDrawerProvider = ({ children }: { children: ReactNode }) => {
    const [drawers, setDrawers] = useState<State[]>([
        {
            key: 0,
            state: {},
            isOpen: false,
        },
    ])

    const open: UtilityDrawerOpen = useCallback((state) => {
        setDrawers((oldDrawers) => {
            const freeDrawer = oldDrawers.find((drawer) => !drawer.isOpen)!

            freeDrawer.state = state
            freeDrawer.isOpen = true

            const newDrawer: State[] = []

            if (oldDrawers[oldDrawers.length - 1] === freeDrawer) {
                newDrawer.push({
                    key: oldDrawers.length,
                    state: {},
                    isOpen: false,
                })
            }

            return [...oldDrawers, ...newDrawer]
        })
    }, [])

    const close = (key: number) => {
        setDrawers((oldDrawers) =>
            oldDrawers.map((drawer) =>
                key === drawer.key ? { ...drawer, isOpen: false, state: {} } : drawer,
            ),
        )
    }

    return (
        <UtilityDrawersContext.Provider value={open}>
            {children}
            {drawers.map(({ state, key, isOpen }) => (
                <Drawer
                    isOpen={isOpen}
                    state={state}
                    updateState={(newDrawerState: DrawerState) =>
                        setDrawers((prevDrawers) =>
                            prevDrawers.map((drawer) =>
                                drawer.key === key ? { ...drawer, state: newDrawerState } : drawer,
                            ),
                        )
                    }
                    key={key}
                    close={() => close(key)}
                />
            ))}
        </UtilityDrawersContext.Provider>
    )
}

export default UtilityDrawerProvider
