import { type ElementType } from 'react'

import Icons from 'assets/icons'
import { formatMoney, resolveQtyMask } from 'components'
import { generateNotForwardedProps, alpha, styled } from 'lib'
import { inventoryFields } from 'resources/inventory'
import { SvgIcon, type SvgIconProps, type TypographyProps, BoxContainer, Typography } from 'ui'

interface InventoryCardSubTitleProps {
    onHandQty: number
    minQty: number
    averageCost: number
}
const CounterWrapper = (props: TypographyProps) => (
    <Typography
        variant="chartTitle"
        component={BoxContainer}
        gap="4px"
        {...props}
    />
)
interface StyledSvgIconProps extends SvgIconProps {
    component: ElementType
    warning?: boolean
}
const StyledSvgIcon = styled(SvgIcon, {
    shouldForwardProp: generateNotForwardedProps<StyledSvgIconProps>(['warning']),
})<StyledSvgIconProps>`
    width: 18px;
    height: 18px;
    padding: 1px;
    border-radius: 2px;
    ${({ theme, ...props }) => {
        const color = props.warning ? theme.palette.charts.red : theme.palette.text.primary
        return `color:${color};background:${alpha(color, 0.1)};`
    }}
`
const InventoryCardSubTitle = ({ onHandQty, minQty, averageCost }: InventoryCardSubTitleProps) => {
    const warning = minQty && onHandQty < minQty
    return (
        <BoxContainer gap="8px">
            <CounterWrapper
                sx={(theme) => ({
                    color: warning ? theme.palette.charts.red : theme.palette.text.primary,
                })}
            >
                <StyledSvgIcon
                    component={inventoryFields.avatar.Icon}
                    warning={warning}
                    fontSize="small"
                />
                {resolveQtyMask(onHandQty)}
            </CounterWrapper>

            <CounterWrapper>
                <StyledSvgIcon
                    component={Icons.MoneyWithArrows}
                    fontSize="small"
                />
                {formatMoney(averageCost)}
            </CounterWrapper>
        </BoxContainer>
    )
}
export default InventoryCardSubTitle
