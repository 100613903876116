import { useShowContext } from 'react-admin'

import Icons from 'assets/icons'
import { SliderView, ViewHeader } from 'components'
import { AddressCard } from 'resources/address'
import { type CustomerModel, CustomersDrawerToggler } from 'resources/customers'
import { TagsCard } from 'resources/tags'
import { IconBox, Section } from 'ui'

import { BasicDetailsCard } from './BasicDetailsCard'
import { CustomerCardsSkeleton } from './CustomerCardsSkeleton'
import { FinancialInfoCard } from './FinancialInfoCard'

const skeletonSlides = new Array(4).fill(0).map((v, i) => (
    <CustomerCardsSkeleton
        height="161px"
        key={i}
    />
))

export const CustomerDetails = () => {
    const { isLoading, record } = useShowContext<CustomerModel>()
    const slides = [
        <BasicDetailsCard />,
        <CustomersDrawerToggler id={record?.id}>
            {(open) => (
                <TagsCard
                    create={open}
                    tags={record?.tagsData}
                />
            )}
        </CustomersDrawerToggler>,
        <AddressCard />,
        <FinancialInfoCard />,
    ]
    return (
        <Section>
            <ViewHeader
                loading={isLoading}
                title="Company Details"
            >
                <ViewHeader.Content placement="rightMobile">
                    <CustomersDrawerToggler id={record?.id}>
                        {(open) => (
                            <IconBox
                                title="Edit"
                                onClick={open}
                            >
                                <Icons.Edit />
                            </IconBox>
                        )}
                    </CustomersDrawerToggler>
                </ViewHeader.Content>
            </ViewHeader>
            <SliderView
                height="187px"
                items={isLoading ? skeletonSlides : slides}
            />
        </Section>
    )
}
