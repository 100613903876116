import { type ReactElement, type ReactNode, useEffect } from 'react'

import {
    type ListControllerResult,
    useListContext,
    useRemoveFromStore,
    useResourceContext,
} from 'react-admin'

import { type DataRecord } from 'appTypes'
import { type AuthStore } from 'core/auth'

import Datagrid, { ColumnsProvider, type DatagridColumnsProps } from '../Datagrid'

import CardList, { type CardListProps } from './CardList'
import ListSelection, { type ListSelectionProps } from './ListSelection'
import ListSortDrawer from './ListSortDrawer'
import { type ListSortContentProps } from './ListSortDrawerContent'
import ListToolbar, { type ListToolbarProps, ListToolbarSkeleton } from './ListToolbar'
import { useListView, ListViewMode } from './ListViewContext'
import RenderList, { type RenderListProps } from './RenderList'
import ListFilterDrawer from './filter/ListFilterDrawer'
import { type FilterConfig } from './filter/ListFilterForm'
import useFirstListLoad from './useFirstLoad'
import { excludedFilterNames } from './utils'

export interface ListProps<RecordType extends DataRecord = any>
    extends ListSelectionProps,
        Pick<RenderListProps, 'renderNoResults' | 'listFTUProps'> {
    exportFileName?: string
    columnsCfg?: DatagridColumnsProps
    cardsCfg?: CardListProps['cardsCfg']
    filtersCfg?: FilterConfig<RecordType>
    sortCfg?: ListSortContentProps<RecordType>
    renderList?: (params: { listContext: ListControllerResult }) => ReactNode
    disableViewsSwitch?: boolean
    toolbarContent?: Pick<ListToolbarProps, 'contentBeforeViews' | 'contentUnderToolbar'>
    excludeFilterFields?: (keyof RecordType)[]
    disableManageColumns?: boolean
    disableExportButton?: boolean
    hideSearch?: boolean
    disableSelectRecord?: (record: RecordType) => string
    isLoading?: boolean
    renderView?: {
        card?: () => ReactElement
    }
}

const List = ({
    exportFileName,
    columnsCfg,
    cardsCfg,
    filtersCfg,
    listFTUProps,
    sortCfg,
    bulkActions,
    renderList,
    disableViewsSwitch,
    toolbarContent,
    renderNoResults,
    excludeFilterFields,
    disableManageColumns = false,
    disableExportButton,
    hideSearch,
    disableSelectRecord,
    isLoading: loading,
    renderView,
}: ListProps & { auth?: AuthStore }) => {
    const { viewMode } = useListView()
    const listContext = useListContext()
    const firstLoad = useFirstListLoad(loading)

    const excludeFields = [
        ...excludedFilterNames,
        ...((excludeFilterFields as string[]) || []),
        ...(filtersCfg?.excludeFields || []),
    ]

    const { selectedIds } = listContext

    return (
        <>
            <ClearListDataOnUnmount />
            <HandleMultiselectOnListChange />
            <ColumnsProvider
                columnsConfig={columnsCfg}
                disableManageColumns={disableManageColumns}
            >
                {selectedIds.length === 0 ? (
                    firstLoad ? (
                        <ListToolbarSkeleton />
                    ) : (
                        <ListToolbar
                            exportFileName={exportFileName}
                            excludeFields={excludeFields}
                            disableSort={!sortCfg}
                            disableFilter={!filtersCfg}
                            disableViewsSwitch={disableViewsSwitch}
                            disableManageColumns={disableManageColumns}
                            disableExportButton={disableExportButton}
                            hideSearch={hideSearch}
                            {...toolbarContent}
                        />
                    )
                ) : null}
                <ListSelection
                    bulkActions={bulkActions}
                    disableSelectRecord={disableSelectRecord}
                />
                {filtersCfg && (
                    <ListFilterDrawer
                        filtersCfg={filtersCfg}
                        excludeFields={excludeFields}
                    />
                )}
                {sortCfg && <ListSortDrawer {...sortCfg} />}
                <RenderList
                    renderNoResults={renderNoResults}
                    listFTUProps={listFTUProps}
                    excludeFields={excludeFields}
                    loading={loading}
                >
                    <>
                        {viewMode !== ListViewMode.list}

                        {renderList?.({
                            listContext,
                        })}
                        {!renderList &&
                            (viewMode === ListViewMode.list ? (
                                <>
                                    <Datagrid
                                        disableSelectRecord={disableSelectRecord}
                                        {...columnsCfg}
                                        loading={loading}
                                    />
                                </>
                            ) : renderView?.card ? (
                                renderView.card()
                            ) : (
                                <CardList
                                    cardsCfg={cardsCfg}
                                    disableSelectRecord={disableSelectRecord}
                                    loading={loading}
                                />
                            ))}
                    </>
                </RenderList>
            </ColumnsProvider>
        </>
    )
}

export default List

// Clear some data from RA store after leaving the list
const ClearListDataOnUnmount = () => {
    const removeFromStore = useRemoveFromStore()
    const resource = useResourceContext()

    useEffect(() => {
        return () => {
            removeFromStore(`${resource}.selectedIds`)
        }
    }, [])

    return null
}

const HandleMultiselectOnListChange = () => {
    const { data, selectedIds, onSelect } = useListContext()

    useEffect(() => {
        const newSelected = selectedIds.filter((selectedId) =>
            data.some(({ id }) => id === selectedId),
        )
        if (newSelected.length !== selectedIds.length) {
            onSelect(newSelected)
        }
    }, [data, selectedIds])

    return null
}
