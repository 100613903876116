import { useFlags } from 'launchdarkly-react-client-sdk'

import { type Flags } from './types'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useFlags1 = () => {
    return {
        ...useFlags(),
    } as Flags
}

export default useFlags as () => Flags
