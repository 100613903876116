import { type ReactNode } from 'react'

import clsx from 'clsx'

import { styled } from 'lib'
import { Box } from 'ui'

import headerClasses from './config'

interface HeaderMainProps {
    children: ReactNode
    className?: string
}
/**
 * The middle part of the main content
 */
const HeaderMain = styled(({ children, className }: HeaderMainProps) => {
    return <Box className={clsx(className, headerClasses.main)}>{children}</Box>
})<HeaderMainProps>`
    flex-grow: 1;
    overflow: hidden;
`

export default HeaderMain
