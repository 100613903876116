import { type MemberModel } from 'resourcesBase'

interface Data extends Pick<MemberModel, 'name'> {}

const value = (data: Data) => {
    return data.name
}

const source = 'name' as const satisfies keyof MemberModel

const name = {
    source,
    label: 'Name',
    value,
}

export default name
