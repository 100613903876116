import { type MouseEvent, type ReactElement } from 'react'

import { useListContext, useShowContext } from 'react-admin'

import Icons from 'assets/icons'
import { useFileDownload, type ActionChildren, ActionsMenu, perPageLimit } from 'core'
import { poResource, type ReceiptModel, type PurchaseOrderModel } from 'resources/purchaseOrders'
import { Stack, SimpleScrollbar } from 'ui'

import ReceiptsList from '../../LineItems/components/ReceiptsList'

interface POExportPDFProps {
    children: (open: (event: MouseEvent<HTMLElement>) => void) => ReactElement
    record?: PurchaseOrderModel
}

const MenuOptions = ({
    childrenFunction,
    record,
}: {
    childrenFunction: ActionChildren
    record: PurchaseOrderModel
}) => {
    const { data, isLoading } = useListContext<ReceiptModel>()
    const downloadOne = useFileDownload()

    if (isLoading) {
        return null
    }

    return (
        <>
            {data.map((receipt) =>
                childrenFunction({
                    key: receipt.id as string,
                    Icon: Icons.Pdf,
                    title: `Export Receipt ${receipt.number} - ${record.number}`,
                    onClick: () => {
                        downloadOne({
                            url: `${poResource.resource}/${record?.id}/receipts/${receipt.id}/pdf`,
                            filename: `Receipt${receipt.number}-${record.number}`,
                            type: 'pdf',
                        })
                    },
                }),
            )}
        </>
    )
}
const POExportPDF = ({ children, record: recordProp }: POExportPDFProps) => {
    const { record } = useShowContext({ record: recordProp })

    const downloadOne = useFileDownload()

    return (
        <ActionsMenu
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            renderToggler={children}
            actions={(params, { children }) => [
                <SimpleScrollbar
                    key="options"
                    sx={{ width: '291px', maxHeight: '500px' }}
                >
                    <Stack
                        py="6px"
                        spacing="6px"
                        sx={{ cursor: 'pointer' }}
                        width="291px"
                    >
                        {children({
                            Icon: Icons.Pdf,
                            title: `Export ${record.number}`,
                            onClick: () => {
                                downloadOne({
                                    resource: poResource,
                                    id: record.id,
                                    filename: record.number,
                                    type: 'pdf',
                                })
                            },
                        })}
                        {record.type === 'STANDARD' && (
                            <ReceiptsList
                                perPage={perPageLimit}
                                record={record}
                                disableSyncWithLocation
                            >
                                <MenuOptions
                                    childrenFunction={children}
                                    record={record}
                                />
                            </ReceiptsList>
                        )}
                    </Stack>
                </SimpleScrollbar>,
            ]}
        />
    )
}

export default POExportPDF
