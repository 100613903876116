import { createResource } from 'core'

import { type CK48Model } from './types'

export const ck48Resource = createResource({
    name: 'ck48',
    resource: 'vmrs/ck48',
})

export const displayCK48 = (record: CK48Model) => (record ? record.description : '')
