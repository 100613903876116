import { type FC, type ReactElement } from 'react'

import { type Identifier } from 'appTypes'
import { CheckboxInput, useOpenUtilityDrawer, UtilityDrawerForm } from 'components'
import { api, serialize, type Serializer, useSubmit } from 'core'
import { type InvoiceModel, invoicesResource } from 'resources/invoices'
import { isInputDisabledDefault } from 'utils'

interface Props {
    children: (params: () => void) => ReactElement
    readOnly?: boolean
    id: Identifier
    defaultValues: {
        removeFees: boolean
        removeTaxes: boolean
    }
}

const ManageTotalDrawerToggler: FC<Props> = ({ children, readOnly, id, defaultValues }) => {
    const open = useOpenUtilityDrawer()
    const handleSubmit = useSubmit(
        async (values) => {
            await api.post(
                `${invoicesResource.resource}/${id}/remove_fees_and_taxes`,
                serialize<InvoiceModel>(values, serializer),
            )
        },
        {
            successMessage: ({ defaultMessages }) => defaultMessages.updated,
        },
    )
    return children(() => {
        open({
            drawerArgs: {
                title: 'Remove Fees and Taxes',
                renderContent: () => <Content isInputDisabled={() => readOnly} />,
                renderWrapper: (params) => (
                    <UtilityDrawerForm
                        onSubmit={handleSubmit}
                        defaultValues={defaultValues}
                        {...params}
                    />
                ),
                renderBottomRight: (render) => render({ formType: 'edit' }),
            },
        })
    })
}

export default ManageTotalDrawerToggler

interface ContentProps {
    isInputDisabled?: (source: string) => boolean
}

const serializer: Serializer<InvoiceModel> = [
    { name: 'removeFees', parse: 'boolean' },
    { name: 'removeTaxes', parse: 'boolean' },
]
const Content: FC<ContentProps> = ({ isInputDisabled = isInputDisabledDefault }) => {
    return (
        <>
            <CheckboxInput
                source="removeFees"
                label="Remove fees for this invoice"
                disabled={isInputDisabled('removeFees')}
                helperText={false}
            />

            <CheckboxInput
                source="removeTaxes"
                label="Remove taxes for this invoice"
                disabled={isInputDisabled('removeTaxes')}
            />
        </>
    )
}
