import { useRecordContext, ListBase } from 'react-admin'

import { RaResourceContextProvider } from 'core'
import { PaymentsList, PaymentsSection } from 'resources/payments'
import { poResource } from 'resources/purchaseOrders'
import { type WorkOrderModel, useWoActionIsDisabled } from 'resources/workOrders'
import { FlexReverse, PageContent } from 'ui'

import { InvoicesSection, PurchaseOrderSection } from './components'

const invoicesResource = 'invoices'
const paymentsResource = 'payments'

const WorkOrderInvoice = () => {
    const record = useRecordContext<WorkOrderModel>()
    const disabled = useWoActionIsDisabled()

    const invoiceResource = `${poResource.resource}/${record?.purchaseOrderData?.id}/${invoicesResource}`
    const paymentResource = `${poResource.resource}/${record?.purchaseOrderData?.id}/${paymentsResource}`

    return (
        <PageContent>
            <FlexReverse>
                <PurchaseOrderSection />
                <RaResourceContextProvider value={{ resource: invoiceResource, name: 'invoices' }}>
                    <ListBase perPage={1000}>
                        <InvoicesSection />
                    </ListBase>
                </RaResourceContextProvider>
                <PaymentsList resource={paymentResource}>
                    <PaymentsSection
                        readOnly={disabled}
                        createDefaultValues={{
                            method: record?.purchaseOrderData.vendorData.paymentMethod,
                        }}
                    />
                </PaymentsList>
            </FlexReverse>
        </PageContent>
    )
}

export default WorkOrderInvoice
