import { type FC } from 'react'

import { type Identifier } from 'appTypes'
import { type Serializer } from 'core'
import { type UnitModel } from 'resources/units'

export interface TelematicsDataModel {
    error: string
    lastSyncedMeters: string
    telematicsStatus: 'CONNECTED' | 'DISCONNECTED'
    provider: IntegrationProvider
    syncMeters: boolean
    syncDvirs: boolean
    status: 'ACTIVE' | 'DEACTIVATED'
    capabilities: {
        dvirs: Capability

        meters: Capability
    }
}

export interface TelematicsUnitModel
    extends Pick<
        UnitModel,
        'created' | 'licensePlate' | 'model' | 'modelYear' | 'number' | 'id' | 'url' | 'vin'
    > {
    connectionStatus: 'CONNECTED' | 'ERROR' | 'UNSYNCED' | 'NEW'
    equipmentCategory: string
    error: string
    isTrailer: boolean
    lastSyncedMeters: string
    make: string
    sync: boolean
    telematicsId: Identifier
    updated: string
    status: 'ACTIVE' | 'DEACTIVATED'
}

export type IntegrationProvider = 'samsara' | 'motive' | 'geotab' | 'eroad'

interface FleetpalUserDataType {
    email: string
    id: Identifier
}

export enum Capability {
    IN_PROGRESS = 'IN_PROGRESS',
    NOT_SUPPORTED = 'NOT_SUPPORTED',
    SUPPORTED = 'SUPPORTED',
}
export interface Telematics {
    isActive: boolean

    connectionTimestamp: string

    error: string

    errorType: 'invalid' | 'authentication'

    provider: IntegrationProvider

    syncDvirs: boolean

    syncMeters: boolean

    autosaveNewVehicles: boolean

    vehiclesNew: number

    vehiclesNotSynced: number

    vehiclesSynced: number

    vehiclesTotal: number

    vehiclesWithError: number

    fleetpalUserData?: FleetpalUserDataType

    hasUnseenVehicles: boolean

    createsTelematicsUser: boolean

    capabilities: {
        dvirs: Capability

        meters: Capability
    }
}
export type TelematicsType = {
    [key in IntegrationProvider]: IntegrationType
}
export interface TelematicsExtra {
    logo: string
    providerName: string
    connect: IntegrationConnectConfigurations[keyof IntegrationConnectConfigurations]
}

export type IntegrationType = TelematicsExtra & Telematics

export type IntegrationConnectConfigurations = {
    link: IntegrationConnectLinkConfig
    drawer: IntegrationConnectDrawerConfig
}

export interface TelematicsQueryResult {
    results: Telematics[]
}

export type IntegrationConnectDrawerConfig = { Content: FC; serializer?: Serializer }

export type IntegrationConnectLinkConfig = { url: string }
