import { type FC } from 'react'

import { useStore } from 'react-admin'

import { type ConfirmConfig } from '../types'

import ConfirmContent from './ConfirmContent'

export const Confirm: FC = () => {
    const [confirm] = useStore<ConfirmConfig | null>('confirm', null)

    if (!confirm) {
        return null
    }

    return <ConfirmContent />
}
