import { type FC } from 'react'

import { TextInput, costNonNegativeMaskParams } from 'components'

export const LimitForm: FC = () => {
    return (
        <TextInput
            source="amount"
            {...costNonNegativeMaskParams}
            label="Limit"
        />
    )
}
