import ShopsField from 'resources/shops/components/ShopsField'
import { type MemberModel } from 'resourcesBase'

interface Data extends Pick<MemberModel, 'shops'> {}

const value = (data: Data) => {
    return <ShopsField shops={data.shops} />
}

const source = 'shops' as const satisfies keyof MemberModel

const shops = {
    source,
    label: 'Repair Shops',
    value,
}

export default shops
