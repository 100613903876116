import { type Identifier } from 'appTypes'
import { type PartModel } from 'resources/parts'

export interface LineItemsModel {
    id: Identifier
    part: Identifier
    partData: PartModel
    price: number
    quantity: number
    created: string
    total: number
    inventoryItem: Identifier
}

export interface ReceiptItemModel {
    id: Identifier
    partData: PartModel
    price: number
    priceOnOrder: number
    quantity: number
    quantityOnOrder: number
    total: number
    created: string
    type: ReceiptItemStatusKeys
    inventoryItem: Identifier
}

export const enum ReceiptItemStatusKeys {
    RECEIVE = 'RECEIVE',
    CANCEL = 'CANCEL',
    BACK_ORDER = 'BACK_ORDER',
}
