export const sanitizeEmails = (formData) => {
    const data = {}

    const keys = Object.keys(formData)

    for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i]
        const value = formData[key]
        if (value && key.startsWith('email')) {
            data[key] = value
        }
    }

    return data
}
