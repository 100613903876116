import { ViewHeader } from 'components'
import { GridContainerColumns, GridItemLayout, Section } from 'ui'

import { type CustomerModel } from '../types'

import { CustomerCard } from './CustomerCard'

export const CustomersSection = ({
    customer,
    isLoading,
}: {
    customer: CustomerModel
    isLoading: boolean
}) => {
    if (!customer && !isLoading) {
        return null
    }
    return (
        <Section>
            <ViewHeader
                title="Customer"
                loading={isLoading}
            />
            <GridContainerColumns>
                <GridItemLayout>
                    <CustomerCard customer={customer} />
                </GridItemLayout>
            </GridContainerColumns>
        </Section>
    )
}
