import { type FC, type ReactNode } from 'react'

import { clsx, generateUtilityClasses, globalClassNames, styled } from 'lib'

import BoxContainer from './BoxContainer'

interface Props {
    children: ReactNode
}

const classNames = generateUtilityClasses('AutoGrid', ['root', 'row', 'column', 'columnInner'])

const Row: FC<Props> = styled((props: Props & { className: string }) => (
    // Without this parent div with flexbox, the row gets higher than the children
    <div className={clsx(classNames.root, props.className)}>
        <div
            {...props}
            className={clsx(classNames.row, globalClassNames.autoRow, globalClassNames.withGap)}
        />
    </div>
))`
    display: flex;
`

const Column: FC<Props> = (props) => {
    return (
        // Without this parent, columns get same size and this prevents centering vertically
        <BoxContainer
            className={clsx(classNames.column, globalClassNames.ellipsis)}
            sx={{ ':has( > div:empty)': { display: 'none' } }}
        >
            <div
                {...props}
                className={clsx(classNames.columnInner, globalClassNames.ellipsis)}
            />
        </BoxContainer>
    )
}

const AutoGrid = {
    Row,
    Column,
}

export default AutoGrid
