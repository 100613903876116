import { type FC, useEffect } from 'react'

import { useWatch } from 'react-hook-form'

import { type Identifier } from 'appTypes'
import api from 'core/api'
import { useFinalErrorHandler } from 'core/errors'
import { dateTimeParse } from 'lib'
import { getMetersResource } from 'resources/units'

import { type PMLastMetersContextResult, usePMLastMetersContext } from './PMLastMetersContext'

const PMLastMetersFetch: FC<{ unitId: Identifier; archived: string | boolean }> = ({
    unitId,
    archived,
}) => {
    const value = useWatch({ name: 'lastDone' })
    const errorHandler = useFinalErrorHandler()
    const { setMeters } = usePMLastMetersContext()

    useEffect(() => {
        if (archived) {
            return
        }
        ;(async () => {
            try {
                const meters: { currentMeters: PMLastMetersContextResult['meters'] } =
                    await api.get(getMetersResource(unitId).resource + '/current', {
                        now: dateTimeParse(value || new Date()),
                    })
                setMeters(meters.currentMeters)
            } catch (err) {
                errorHandler(err)
            }
        })()
    }, [value])

    return null
}

export default PMLastMetersFetch
