import { styled } from 'lib'

export const SidebarContainer = styled('div')`
    width: ${({ theme }) => theme.props.drawerWidth}px;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    background: ${({ theme }) => theme.palette.white};
    z-index: 10;
`
