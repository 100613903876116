import { Skeleton } from 'ui'

import { WidgetInfoContainer, WidgetInfoContentWrapper } from './styled/InfoComponents'

export interface WidgetInfoProps {
    content: React.ReactNode | string
}
export const WidgetInfoSkeleton = () => {
    return (
        <WidgetInfoContainer
            sx={{
                padding: '16px',
            }}
        >
            <Skeleton />
        </WidgetInfoContainer>
    )
}

export const WidgetInfo = ({ content }: WidgetInfoProps) => {
    return (
        <WidgetInfoContainer>
            <WidgetInfoContentWrapper>{content}</WidgetInfoContentWrapper>
        </WidgetInfoContainer>
    )
}
