import { type FC, type ReactNode } from 'react'

import { type ObjectAny } from 'appTypes'
import Icons from 'assets/icons'
import { Button, type ButtonProps, IconButton, type IconButtonProps } from 'ui'

import { useArrayControllerContext } from './ArrayControllerContext'
import { useArrayControllerElementContext } from './ArrayControllerElements'

interface DeleteButtonControllerProps {
    alwaysVisible?: boolean
}

const DeleteButtonController: FC<DeleteButtonControllerProps & { children?: ReactNode }> = ({
    alwaysVisible,
    children,
}) => {
    const { array } = useArrayControllerContext()

    if (alwaysVisible || array.length > 1) {
        return <>{children}</>
    }

    return null
}

type ArrayControllerDeleteActionProps<P extends ObjectAny = {}> = P & {
    controller?: DeleteButtonControllerProps
}

export interface ArrayControllerDeleteButtonProps
    extends ArrayControllerDeleteActionProps<ButtonProps> {}

export const ArrayControllerDeleteButton = ({
    controller,
    ...props
}: ArrayControllerDeleteButtonProps) => {
    const { remove } = useArrayControllerElementContext()

    return (
        <DeleteButtonController {...controller}>
            <Button
                size="small"
                startIcon={<Icons.Delete />}
                sx={(theme) => ({
                    color: theme.palette.text.primary,
                })}
                {...props}
                onClick={remove}
            >
                Remove
            </Button>
        </DeleteButtonController>
    )
}

export const ArrayControllerDeleteIcon = ({
    controller,
    children,
    ...props
}: ArrayControllerDeleteActionProps<IconButtonProps>) => {
    const { remove } = useArrayControllerElementContext()

    return (
        <DeleteButtonController {...controller}>
            <IconButton
                {...props}
                onClick={remove}
            >
                {children || <Icons.Delete />}
            </IconButton>
        </DeleteButtonController>
    )
}
