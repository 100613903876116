import { type FC } from 'react'

import { formatMoney } from 'components'
import { globalClassNames } from 'lib'
import LineItemsTooltip from 'pages/WorkOrders/Jobs/WorkOrderJobCard/components/LineItemsTooltip'
import { Box, Stack, BoxContainer, Status, Typography } from 'ui'

import { WidgetListContainer } from '../../../WidgetBase'
import { ListItem, WidgetListDescription } from '../../components'
import { type listType, statusColors } from '../types'

interface TopCategoriesProps {
    list: listType
    description: string
}
const TopCategories: FC<TopCategoriesProps> = ({ list, description }) => {
    const { data } = list
    const isValid = data.length > 0 ? data.find((obj) => typeof obj.total === 'number') : false

    if (!isValid) {
        return <WidgetListDescription>{description}</WidgetListDescription>
    }

    const List = data.flatMap(({ id, description, total, code, ...ratioData }, i) => {
        if (total == null) {
            return null
        }
        return (
            <Typography
                component={ListItem}
                variant="chartListItem"
                key={`${description}-${i}`}
            >
                <BoxContainer
                    sx={{
                        pr: '10px',
                    }}
                    className={globalClassNames.ellipsis}
                >
                    <Stack
                        direction="row"
                        className={globalClassNames.ellipsis}
                        sx={{ lineHeight: '150%' }}
                    >
                        <Box marginRight="13px">
                            <Status
                                size="8px"
                                iconColor={(theme) => theme.palette.charts[statusColors[i]]}
                            />
                        </Box>
                        {`${code ? code.substring(0, 3) : ''} ${description}`}
                    </Stack>
                </BoxContainer>

                <LineItemsTooltip data={ratioData}>{formatMoney(total)}</LineItemsTooltip>
            </Typography>
        )
    })
    return <WidgetListContainer>{List}</WidgetListContainer>
}
export default TopCategories
