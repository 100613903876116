import { type JobModel } from './types'

export const jobSourceMap: {
    [key in JobModel['source']]?: string
} = {
    DEFECT: 'DVIR',
    PM_SCHEDULE: 'PM',
    ISSUE: 'Issue',
}

export const woJobItemPermissions = {
    addLabor: 'addLabor',
    addPart: 'addPart',
}
