import { useRef } from 'react'

import { useReferenceInputInfiniteScroll } from 'core/referenceController'

import AutocompleteInputBase, { type AutocompleteInputBaseProps } from './AutocompleteInputBase'
import ListBox, { type ExtendedListBoxProps } from './ListBox'

export interface AutocompleteInputProps extends Omit<AutocompleteInputBaseProps, 'inputText'> {
    ListboxProps?: ExtendedListBoxProps &
        AutocompleteInputBaseProps['ListboxProps'] & {
            ref?: (node: any) => void
        }
    inputText?: (option: any) => string | number
}

const InfiniteAutocompleteInput = (props: AutocompleteInputProps) => {
    const { loadMoreResults, reset, choicesController } = useReferenceInputInfiniteScroll({
        multiple: props.multiple,
    })
    const { filterValues } = choicesController || {}
    const listboxRef = useRef()

    const handleScroll = (event) => {
        const listboxNode = event.currentTarget
        const position = listboxNode.scrollTop + listboxNode.clientHeight

        if (listboxNode.scrollHeight - position <= 5) {
            loadMoreResults()
        }
    }

    return (
        <AutocompleteInputBase
            {...props}
            {...(!props.choices && {
                handleScroll,
                onClose: () => {
                    // the input will reset itself if filter applied
                    if ('q' in filterValues) {
                        return
                    }
                    reset()
                },
            })}
            ListboxComponent={ListBox}
            ListboxProps={{ ref: listboxRef, ...props.ListboxProps }}
            defaultValue={props.defaultValue ?? (props.multiple ? [] : null)}
            // @ts-ignore
            inputText={props.inputText}
        />
    )
}

export default InfiniteAutocompleteInput
