import {
    type DetailedHTMLProps,
    type HTMLAttributes,
    forwardRef,
    createContext,
    useContext,
    useState,
} from 'react'

import { styled } from 'lib'
import { ClickAwayListener } from 'ui'

import useCollapseWidget from '../WidgetBase/useCollapseWidget'

interface GridLayoutItemProps
    extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    id: string
}

export const WidgetContext = createContext(null)

export const useWidgetContext = () => useContext(WidgetContext)

const StyledGridLayoutItem = styled('div')``
const GridLayoutItem = forwardRef((props: GridLayoutItemProps, ref: any) => {
    // Dimensions are used to know when to hide certain widget components
    const dimensions = useCollapseWidget(ref)
    // This is used for widget tooltip, in order to make it
    // disappear when hovering outside
    const [inside, setInside] = useState(false)

    const handleEnter = () => setInside(true)
    const handleLeave = () => setInside(false)
    return (
        <WidgetContext.Provider value={dimensions}>
            <ClickAwayListener
                onClickAway={() => {
                    setInside(false)
                }}
            >
                <StyledGridLayoutItem
                    {...props}
                    ref={ref}
                    key={props.id}
                    onMouseEnter={handleEnter}
                    onMouseLeave={handleLeave}
                    onClick={() => setInside(true)}
                    // Make the tooltip not cut off when user is inside
                    {...(inside ? { sx: { zIndex: 1 } } : {})}
                />
            </ClickAwayListener>
        </WidgetContext.Provider>
    )
})
export default GridLayoutItem
