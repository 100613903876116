import { useListContext } from 'react-admin'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import { useCreateResourcePath } from 'core/resource'
import { useNavigate, alpha, useTheme } from 'lib'
import { DataAvatar, type DataAvatarProps, Tooltip } from 'ui'

export interface DataCardAvatarProps extends Omit<DataAvatarProps, 'id'> {
    id: Identifier
    disableLink?: boolean
    disable?: boolean
    titleOnDisabled?: string
    nonSelectable?: string
    to?: string
}

const DataCardAvatar = ({
    imageSrc,
    defaultImage,
    avatarOpacity,
    id,
    color,
    disableLink,
    disable,
    titleOnDisabled,
    nonSelectable,
    to,
    ...rest
}: DataCardAvatarProps) => {
    const createPath = useCreateResourcePath()
    const navigate = useNavigate()

    const theme = useTheme()
    const { selectedIds, onSelect } = useListContext()
    const isSelectedId = selectedIds.includes(id)

    const cardEditViewPath = createPath({
        id,
        type: 'edit',
    })

    if ((disable || nonSelectable) && selectedIds.length) {
        const content = (
            <DataAvatar
                sx={{ cursor: 'pointer' }}
                imageSrc={imageSrc}
                color={theme.palette.text.primary}
                defaultImage={
                    <Icons.Check
                        sx={(theme) => ({
                            color: `${alpha(theme.palette.text.primary, 0.26)} !important`,
                        })}
                    />
                }
                avatarOpacity={avatarOpacity}
                {...rest}
            />
        )
        if (nonSelectable || titleOnDisabled) {
            return (
                <Tooltip
                    title={nonSelectable || titleOnDisabled}
                    enterTouchDelay={0}
                >
                    {content}
                </Tooltip>
            )
        }
        return <>{content}</>
    }
    return (
        <DataAvatar
            sx={{
                cursor: disableLink ? null : 'pointer',
                display: defaultImage || selectedIds.length ? 'flex' : 'none',
            }}
            onClick={() => {
                if (!disableLink && !selectedIds.length) {
                    navigate(to || cardEditViewPath)
                    return
                }

                const ids = isSelectedId
                    ? selectedIds.filter((ids) => ids !== id)
                    : [...selectedIds, id]
                if (selectedIds.length !== 0) {
                    onSelect(ids)
                }
            }}
            imageSrc={selectedIds.length === 0 ? imageSrc : ''}
            defaultImage={
                selectedIds.length === 0 ? (
                    defaultImage
                ) : (
                    <Icons.Check
                        sx={(theme) => ({
                            color: isSelectedId
                                ? 'white !important'
                                : `${alpha(theme.palette.text.primary, 0.54)} !important`,
                        })}
                    />
                )
            }
            color={
                selectedIds.length === 0
                    ? color
                    : isSelectedId
                      ? theme.palette.primary.main
                      : theme.palette.text.primary
            }
            avatarOpacity={isSelectedId ? 1 : avatarOpacity}
            {...rest}
        />
    )
}
export default DataCardAvatar
