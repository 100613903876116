import { type ReactElement } from 'react'

import { ListTotalBadge, ViewHeader } from 'components'

interface ContactsHeaderProps {
    actions?: ReactElement
}

export const ContactsHeader = ({ actions }: ContactsHeaderProps) => {
    return (
        <ViewHeader title="Contact Persons">
            <ViewHeader.Content>
                <ListTotalBadge />
            </ViewHeader.Content>
            <ViewHeader.Content placement="rightMobile">{actions}</ViewHeader.Content>
        </ViewHeader>
    )
}
