import { type FC } from 'react'

import { type WithChildrenNode } from 'appTypes'
import { useIntegrationStatus } from 'resources/telematics'
import { BoxContainer } from 'ui'

import LogoSection from '../LogoSection'
import { MobileProfile, HeaderShop } from '../Sidebar'

import AppHeaderContainer from './AppHeaderContainer'

const AppHeader: FC = () => {
    return (
        <Container>
            <LogoSection />
            <BoxContainer
                ml="auto"
                gap="16px"
                overflow="hidden"
            >
                <HeaderShop />
                <MobileProfile />
            </BoxContainer>
        </Container>
    )
}

const Container: FC<WithChildrenNode> = ({ children }) => {
    const status = useIntegrationStatus()

    return <AppHeaderContainer status={status}>{children}</AppHeaderContainer>
}

export default AppHeader
