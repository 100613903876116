import { type Identifier } from 'appTypes'
import { settingsUrls } from 'configs'
import { type ResourceConfig } from 'core'
import { pathJoin } from 'utils'

export const shopResource = {
    resource: 'shops',
    name: 'shops',
    invoicingSettings: (id: Identifier) =>
        pathJoin(shopResource.resource, id, 'invoicing_settings'),

    removeMembers: (id: Identifier) => pathJoin(shopResource.resource, id, 'remove_members'),
} satisfies ResourceConfig

export const shopUrls = {
    getOne: (id: Identifier) => pathJoin(settingsUrls.repairShops, id),
}
