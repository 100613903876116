import { ViewHeader } from 'components'
import { Section } from 'ui'

import TotalCard from './TotalCard'

const TotalSection = () => {
    return (
        <Section>
            <ViewHeader title="Invoice Total" />
            <TotalCard />
        </Section>
    )
}
export default TotalSection
