import { type FC } from 'react'

import Icons from 'assets/icons'
import { alpha, globalClassNames } from 'lib'
import { type PmModel } from 'resources/pm'
import { ck33Fields } from 'resources/vmrs'
import { Box, Typography, StyledElement, Card, IconButton } from 'ui'

export const elementHeight = 65

export const PMRepositionElementPosition: FC<{ position: number }> = ({ position }) => {
    return (
        <StyledElement
            sx={{
                height: elementHeight + 'px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}
        >
            <Typography
                bgcolor={(theme) => alpha(theme.palette.text.primary, 0.12)}
                variant="chartTitle"
                color="text.primary"
                borderRadius="4px"
                p="6px 10px"
            >
                Position {position}
            </Typography>
        </StyledElement>
    )
}

const PMRepositionElement: FC<{ record: PmModel }> = ({ record }) => {
    return (
        <Box
            display="flex"
            alignItems="center"
            gap="12px"
            height={elementHeight + 'px'}
        >
            <Card
                sx={{
                    p: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexGrow: 1,
                    height: '100%',
                    boxSizing: 'border-box',
                }}
            >
                <Box overflow="hidden">
                    <Typography
                        variant="subtitle2"
                        color="primary.main"
                        lineHeight="14px"
                        mb="2px"
                    >
                        {record.name}
                    </Typography>
                    <Typography
                        className={globalClassNames.ellipsis}
                        color="text.primary"
                        variant="chartsBody"
                        display="block"
                    >
                        {ck33Fields.self.value(record.componentData)}
                    </Typography>
                </Box>
                <IconButton
                    size="small"
                    className="draggable-area"
                    aria-label={'Drag ' + record.name}
                >
                    <Icons.DragIndicatorOutlined
                        fontSize="inherit"
                        sx={{ color: (theme) => alpha(theme.palette.text.primary, 0.54) }}
                    />
                </IconButton>
            </Card>
        </Box>
    )
}

export default PMRepositionElement
