import { type FC } from 'react'

import images from 'assets/images'
import { infoCardStyles, InfoCard, InfoCardHeader } from 'components'
import { Stack, Typography, BoxContainer, Spacer, Img, InfoBadge, SimpleScrollbar } from 'ui'

import { type TagModel } from '../types'

import { TagField } from './TagField'

interface TagsCardProps extends TagsEmptyProps {
    tags: TagModel[]
}

const TagsCard: FC<TagsCardProps> = ({ create, tags }) => {
    if (tags == null) {
        return null
    }

    const tagsCount = tags.length

    return (
        <InfoCard
            sx={{
                pb: '0px',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                overflow: 'hidden',
            }}
        >
            <InfoCardHeader
                dividerProps={{ sx: { mb: 0 } }}
                title={
                    <Spacer variant="sm">
                        <span>Tags</span>
                        <InfoBadge badgeContent={tagsCount} />
                    </Spacer>
                }
            />
            {tagsCount ? (
                <SimpleScrollbar sx={{ height: '100%', overflow: 'auto' }}>
                    <BoxContainer
                        gap="4px"
                        flexWrap="wrap"
                        pt={`var(${infoCardStyles.headerDividerOffsetBottom})`}
                        pb={`var(${infoCardStyles.p})`}
                    >
                        {tags.map((tag) => (
                            <TagField
                                tag={tag}
                                key={tag.id}
                            />
                        ))}
                    </BoxContainer>
                </SimpleScrollbar>
            ) : (
                <TagsEmpty create={create} />
            )}
        </InfoCard>
    )
}

export default TagsCard

interface TagsEmptyProps {
    create: (() => void) | false
}

const TagsEmpty: FC<TagsEmptyProps> = ({ create }) => {
    return (
        <BoxContainer
            gap="27px"
            flex="1"
        >
            <Img
                src={images.tags}
                sx={{ width: '80px', height: '78px' }}
            />
            <Stack spacing="8px">
                <Typography variant="h5">No Tags</Typography>

                {create === false ? null : (
                    <Typography
                        variant="body1"
                        onClick={create}
                        color={(theme) => theme.palette.primary.main}
                        sx={{ cursor: 'pointer' }}
                    >
                        Add Tag
                    </Typography>
                )}
            </Stack>
        </BoxContainer>
    )
}
