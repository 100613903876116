import { type FC } from 'react'

import { inputPriceMaskParams, TextInput } from 'components'
import { requiredValidation } from 'core'
import { isInputDisabledDefault } from 'utils'

interface Props {
    isInputDisabled?: (source: string) => boolean
}

const ManageFeeForm: FC<Props> = ({ isInputDisabled = isInputDisabledDefault }) => {
    return (
        <>
            <TextInput
                source="name"
                label="Fee Name"
                disabled
            />

            <TextInput
                source="price"
                label="Total Fee Amount"
                validate={requiredValidation}
                disabled={isInputDisabled('price')}
                {...inputPriceMaskParams}
            />
        </>
    )
}

export default ManageFeeForm
