import { useDialogSelectorContext } from 'components/inputs/DialogSelector'
import { styled, useMediaQuery } from 'lib'
import { Anchor, Breadcrumbs, breadcrumbsClasses, buttonBaseClasses, Tooltip } from 'ui'

import ck33Fields from '../../fields'
import { type CK33Model } from '../../types'
import { ck33Names } from '../../utils'

import { type FilterValues, type DisplayedFiltersInfo } from './types'
import { favoritesSource } from './utils'

export const CK33Breadcrumbs = () => {
    const { defaultFilter, control } = useDialogSelectorContext<CK33Model>()
    const { setFilters, filterValues } = control
    const displayedFilters = control.displayedFilters as DisplayedFiltersInfo
    const parents = displayedFilters?.parents || []

    const isExtraSmall = useMediaQuery((theme) =>
        theme.breakpoints.down(theme.props.mobileViewBreakpoint),
    )

    if (filterValues[favoritesSource]) {
        return null
    }

    const set = (choice: CK33Model, index: number) => {
        if (!parents.length || index === parents.length - 1) {
            return
        }
        const _displayedFilters: DisplayedFiltersInfo = {
            ...displayedFilters,
            parents: choice ? parents.slice(0, index + 1) : [],
        }

        const _filterValues: FilterValues = choice
            ? { parent: String(choice.id) }
            : (defaultFilter as {})

        setFilters(_filterValues, _displayedFilters, false)
    }

    return (
        <Breadcrumbs
            maxItems={3}
            aria-label="breadcrumb"
            sx={(theme) => ({
                [`& .${breadcrumbsClasses.ol} .${breadcrumbsClasses.li}:last-of-type a`]: {
                    color: `${theme.palette.text.primary} !important`,
                    cursor: 'default',
                    textDecoration: 'none',
                },
                [`& .${breadcrumbsClasses.ol} li .${buttonBaseClasses.root}`]: {
                    opacity: 0.54,
                },
            })}
        >
            <BreadcrumbLink
                underline="hover"
                onClick={() => set(null, null)}
            >
                Home
            </BreadcrumbLink>
            {parents.map((choice, index) => {
                const content = (
                    <BreadcrumbLink
                        key={choice.code}
                        underline={index === parents.length - 1 ? 'none' : 'hover'}
                        onClick={() => set(choice, index)}
                    >
                        {ck33Names[choice.level]}{' '}
                        {ck33Fields.self.formatCode(choice.code, choice.level)}
                    </BreadcrumbLink>
                )
                if (isExtraSmall) {
                    return content
                }
                return (
                    <Tooltip
                        title={`${ck33Names[choice.level]} ${ck33Fields.self.value(choice)}`}
                        key={choice.code}
                    >
                        {content}
                    </Tooltip>
                )
            })}
        </Breadcrumbs>
    )
}

const BreadcrumbLink = styled(Anchor)(({ theme }) => ({
    color: theme.palette.text.secondary,
}))
