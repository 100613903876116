import { type FC } from 'react'

import { IconButton, type IconButtonProps } from 'ui'

export interface DialogSelectorAppBarButtonProps extends IconButtonProps {}

const DialogSelectorAppBarButton: FC<DialogSelectorAppBarButtonProps> = (props) => {
    return (
        <IconButton
            color="inherit"
            size="large"
            {...props}
        />
    )
}

export default DialogSelectorAppBarButton
