import acquisitionCost from './acquisitionCost'
import costFloor from './costFloor'
import marginPercent from './marginPercent'
import markup from './markup'
import markupPercent from './markupPercent'
import partTier from './partTier'
import price from './price'
import type from './type'

const inventoryPricingFields = {
    partTier,
    type,
    acquisitionCost,
    costFloor,
    markupPercent,
    marginPercent,
    markup,
    price,
}
export default inventoryPricingFields
