import { useListContext, useShowContext } from 'react-admin'

import Icons from 'assets/icons'
import { ViewHeader, ListTotalBadge, SliderView } from 'components'
import { useUnitPMDrawerEditor, type PmModel } from 'resources/pm'
import { type UnitModel, isUnitArchived } from 'resources/units'
import { IconBox, Section } from 'ui'

import { unitPMCardSkeletons } from './UnitPMCard'
import UnitPMCard from './UnitPMCard/UnitPMCard'
import UnitPMEmpty from './UnitPMEmpty'

const UnitPMIndependentSection = () => {
    const { total, data, isLoading } = useListContext<PmModel>()
    const { record: unit } = useShowContext<UnitModel>()
    const isArchived = isUnitArchived(unit)
    const open = useUnitPMDrawerEditor()
    let content

    if (total) {
        content = (
            <SliderView
                height="312px"
                items={data?.map((item) => (
                    <UnitPMCard
                        unitPmRecord={item}
                        key={item.id}
                    />
                ))}
            />
        )
    } else {
        content = <UnitPMEmpty type="independent" />
    }
    if (isLoading) {
        content = (
            <SliderView
                height="312px"
                items={unitPMCardSkeletons}
            />
        )
    }

    return (
        <Section>
            <ViewHeader
                title="Independent"
                loading={isLoading}
            >
                <ListTotalBadge />
                <ViewHeader.Content placement="rightMobile">
                    {!isArchived && (
                        <IconBox
                            title="Add Interval"
                            onClick={() => {
                                open({ type: 'independent' })
                            }}
                        >
                            <Icons.Add />
                        </IconBox>
                    )}
                </ViewHeader.Content>
            </ViewHeader>
            {content}
        </Section>
    )
}

export default UnitPMIndependentSection
