import Icons from 'assets/icons'

const avatar = {
    Icon: Icons.Dvir,
    // TODO: Do they belong to defect?
    ResolvedIcon: Icons.DvirInProgress,
    UnresolvedIcon: Icons.DvirUnresolved,
    InProgressIcon: Icons.DvirInProgress,
}

export default avatar
