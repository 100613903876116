import { type CustomerModel } from '../types'

const source: keyof CustomerModel = 'paymentMethod'

const PaymentMethod = {
    source,
    label: 'Default Payment Method',
}

export default PaymentMethod
