import { LinkTabs } from 'components/router'

import PageHeader from './Header'
import HeaderActions from './HeaderActions'
import HeaderAside from './HeaderAside'
import HeaderAvatar from './HeaderAvatar'
import HeaderBackButton from './HeaderBackButton'
import HeaderBlock from './HeaderBlock'
import HeaderContent from './HeaderContent'
import HeaderDivider from './HeaderDivider'
import HeaderInfo from './HeaderInfo'
import PageHeaderMain from './HeaderMain'
import HeaderMainAction from './HeaderMainAction'
import HeaderTitle from './HeaderTitle'
export * from './FixedTopPart'

export { type HeaderActionsProps } from './HeaderActions'

const Header = Object.assign(PageHeader, {
    Title: HeaderTitle,
    Block: HeaderBlock,
    Main: PageHeaderMain,
    Aside: HeaderAside,
    Actions: HeaderActions,
    Avatar: HeaderAvatar,
    Info: HeaderInfo,
    BackButton: HeaderBackButton,
    Tabs: LinkTabs,
    Content: HeaderContent,
    Divider: HeaderDivider,
    MainAction: HeaderMainAction,
})

export default Header
