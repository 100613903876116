import { type FC } from 'react'

import { inject } from 'mobx-react'

import { AutocompleteInput } from 'components'
import { type AuthStore, ReferenceInput } from 'core'

import { divisionResource } from '../utils'

interface Props {
    disabled?: boolean
    helperText?: string
}

export const DivisionInput: FC<Props> = inject('auth')(({
    disabled,
    helperText,
    auth,
}: Props & { auth: AuthStore }) => {
    if (!auth.companySettings.hasDivisions) {
        return
    }

    return (
        <ReferenceInput
            disabled={disabled}
            reference={divisionResource.resource}
            source="division"
            perPage={100}
        >
            <AutocompleteInput
                disabled={disabled}
                optionText="name"
                label="Division"
                helperText={helperText}
            />
        </ReferenceInput>
    )
})
