import { type ReactElement } from 'react'

import { useRecordContext } from 'react-admin'
import { useFormState } from 'react-hook-form'

import Icons from 'assets/icons'
import {
    ArrayControllerBox,
    ArrayControllerElements,
    TextInput as TextInputBase,
    useArrayControllerContext,
    useArrayControllerElementContext,
    useUtilityDrawerContext,
} from 'components'
import { ActionsMenu, requiredValidation } from 'core'
import { getMeterTypeAdornment, meterTypesConfig, type MeterModel } from 'resources/units'
import { Alert, Tooltip, StyledElement, Button, Typography } from 'ui'

import { type PmModel } from '../../types'

import { type PMIntervalRow } from './UnitPMFormIntervals'
import UnitPMMeterBase from './UnitPMMeterBase'
import UnitPMMeterEngineHours from './UnitPMMeterEngineHours'
import UnitPMMeterTime from './UnitPMMeterTime'
import { type UnitPMDrawerEditorExtraState } from './types'

type MeterTypes = MeterModel['type'] | 'TIME'
const meterTypes = [
    meterTypesConfig.ODOMETER,
    meterTypesConfig.TIME,
    meterTypesConfig.ENGINE_HOURS,
    meterTypesConfig.HUBOMETER,
]
interface MeterData {
    component: ReactElement
    title: string
}

const meters: { [key in MeterTypes]: MeterData } = {
    ODOMETER: {
        component: <UnitPMMeterBase endAdornment={getMeterTypeAdornment('ODOMETER')} />,
        title: 'Odometer',
    },
    ENGINE_HOURS: {
        component: <UnitPMMeterEngineHours />,
        title: 'Engine Hours',
    },
    HUBOMETER: {
        component: <UnitPMMeterBase endAdornment={getMeterTypeAdornment('HUBOMETER')} />,
        title: 'Hubometer',
    },
    TIME: {
        component: <UnitPMMeterTime />,
        title: 'Time Meter',
    },
}

const UnitPMFormMeter = () => {
    const { item, index } = useArrayControllerElementContext<{ valueType: PMIntervalRow }>()
    const type = item.type
    const meterData = meters[type as MeterTypes] || ({} as MeterData)
    const recordContext = useRecordContext<PmModel>()
    const canDelete = !recordContext?.intervals?.[index]

    return (
        <ArrayControllerBox
            renderDeleteButton={
                canDelete
                    ? ({ defaultButton }) => defaultButton({ controller: { alwaysVisible: true } })
                    : () => null
            }
            title={meterData.title}
        >
            {meterData.component}
            <MeterTypeErrorHandler index={index} />
        </ArrayControllerBox>
    )
}

const MeterTypeErrorHandler = ({ index }: { index: number }) => {
    const { errors } = useFormState()
    const error = errors.intervals?.[index]?.meterType

    return error ? (
        <Typography
            variant="helperText"
            color={(theme) => theme.palette.error.main}
        >
            {error.message.message}
        </Typography>
    ) : null
}
const UnitPMFormMeters = () => {
    const { append, array, limit } = useArrayControllerContext<PMIntervalRow>()

    const addButtonDisabled = array.length === limit
    const { extra } = useUtilityDrawerContext()
    const { isArchived } = extra as UnitPMDrawerEditorExtraState

    return (
        <>
            <ActionsMenu
                actions={(args, { children }) =>
                    meterTypes.map(({ name, Icon, id }) =>
                        array?.some((item) => item.type === id)
                            ? null
                            : children({
                                  title: name,
                                  key: id,
                                  Icon,
                                  onClick: () => {
                                      append({ type: id } as PMIntervalRow)
                                  },
                              }),
                    )
                }
                renderToggler={(open) => (
                    <Tooltip
                        title={addButtonDisabled ? 'Maximum of two intervals supported' : ''}
                        arrow
                    >
                        <StyledElement
                            as="span"
                            sx={{ mb: '32px', display: 'inline-block' }}
                        >
                            <Button
                                disabled={addButtonDisabled || isArchived}
                                onClick={open}
                                startIcon={<Icons.Add />}
                                variant="contained"
                                size="large"
                            >
                                Add Meter Type
                            </Button>
                        </StyledElement>
                    </Tooltip>
                )}
            />
            {!array.length && (
                <>
                    <TextInputBase
                        sx={{ display: 'none !important' }}
                        source="hidden"
                        validate={requiredValidation}
                        type="hidden"
                    />
                    <Alert
                        severity="info"
                        sx={{ mt: '7px' }}
                    >
                        Add at least one interval meter to continue
                    </Alert>
                </>
            )}
            <ArrayControllerElements<PMIntervalRow> itemKey={({ item }) => item.id}>
                <UnitPMFormMeter />
            </ArrayControllerElements>
        </>
    )
}

export default UnitPMFormMeters
