import { type FC } from 'react'

import WorkOrderSimpleList from 'pages/WorkOrders/SimpleList/WorkOrderSimpleList'
import { ContactsSection } from 'resources/contacts'
import { type CustomerModel } from 'resources/customers'
import { FlexReverse, PageContent } from 'ui'

import { customerContactsResourceName } from '../config'

import { CustomerDetails } from './components'

export const CustomerOverview: FC = () => {
    return (
        <PageContent>
            <FlexReverse>
                <CustomerDetails />
                <ContactsSection resourceName={customerContactsResourceName} />
                <WorkOrderSimpleList<CustomerModel>
                    createFormInitialValues={(record) => ({})}
                    filter={(record) => ({
                        customer: record?.name,
                    })}
                    createFormConfig={(record) => ({
                        unit: {
                            defaultFilter: {
                                customerId: record?.id,
                            },
                        },
                    })}
                />
            </FlexReverse>
        </PageContent>
    )
}
