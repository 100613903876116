import { type FC } from 'react'

import Icons from 'assets/icons'
import { useDialogSelectorContext, DialogSelectorAppBarButton } from 'components'

import { type CK33Model } from '../../types'

import { type DisplayedFiltersInfo, type FilterValues } from './types'

export const BackButton: FC = () => {
    const { defaultFilter, control } = useDialogSelectorContext<CK33Model>()
    const { setFilters, isFetching } = control
    const displayedFilters = control.displayedFilters as DisplayedFiltersInfo
    const pop = () => {
        const parents = [...displayedFilters.parents]
        parents.pop()
        const choice = parents[parents.length - 1]

        const _filterValues: FilterValues = choice
            ? { parent: String(choice.id) }
            : (defaultFilter as {})

        const _displayedFilters: DisplayedFiltersInfo = {
            ...displayedFilters,
            parents,
        }
        setFilters(_filterValues, _displayedFilters, false)
    }

    return (
        <DialogSelectorAppBarButton
            disabled={!displayedFilters?.parents?.length || isFetching}
            onClick={pop}
            aria-label="Back"
        >
            <Icons.ArrowBackOutlined />
        </DialogSelectorAppBarButton>
    )
}
