import { TextInput as RATextInput, type TextInputProps as RATextInputProps } from 'react-admin'

import { type ObjectAny } from 'appTypes'
import { useCreateInputId } from 'core/form'
import { useInputState } from 'core/inputs'
import { InputAdornment } from 'ui'

export interface TextInputProps<FormType extends ObjectAny = ObjectAny>
    extends Omit<RATextInputProps, 'source'> {
    source: Extract<keyof FormType, string>
}

const TextInput = <FormType extends ObjectAny = ObjectAny>(props: TextInputProps<FormType>) => {
    const createId = useCreateInputId()
    const state = useInputState(props.source, { disabled: props.disabled })
    return (
        <RATextInput
            variant="outlined"
            size="medium"
            {...props}
            {...state}
            id={createId({ source: props.source, id: props.id })}
            InputProps={{
                ...props.InputProps,
                endAdornment: props.InputProps?.endAdornment ? (
                    <InputAdornment
                        position="end"
                        component="div"
                        disableTypography
                    >
                        {props.InputProps?.endAdornment}
                    </InputAdornment>
                ) : undefined,
                startAdornment: props.InputProps?.startAdornment ? (
                    <InputAdornment
                        position="start"
                        component="div"
                        disableTypography
                    >
                        {props.InputProps?.startAdornment}
                    </InputAdornment>
                ) : undefined,
            }}
        />
    )
}

export default TextInput

TextInput.defaultProps = {
    variant: 'outlined',
    size: 'medium',
}
