import { useWatch } from 'react-hook-form'

import { DateInput, TextInput } from 'components'
import { maxLengthValidation, validateMaxLength } from 'core'
import { alpha } from 'lib'
import { CK34Input, CK47Input, CK48Input } from 'resources/vmrs'
import { Box, Collapse, SectionTitleSmall } from 'ui'

import { type UnitModel } from '../types'
import { equipmentHasBodySource } from '../utils'

import EquipmentBodyTitle from './EquipmentBodyTitle'

const UnitFormBodyType = () => {
    const value = useWatch({ name: equipmentHasBodySource })

    return (
        <Collapse in={Boolean(value)}>
            {value ? (
                <Box
                    p="20px 20px 0"
                    mb="24px"
                    borderRadius="5px"
                    bgcolor={(theme) => alpha(theme.palette.grey[900], 0.04)}
                >
                    <SectionTitleSmall
                        variant="subtitle2"
                        frontLine
                    >
                        <EquipmentBodyTitle />
                    </SectionTitleSmall>
                    <CK48Input
                        source={source('vmrsType')}
                        label="Body Type"
                    />
                    <CK47Input
                        label="Body Material"
                        source={source('vmrsMaterial')}
                    />
                    <CK34Input
                        source={source('vmrsManufacturer')}
                        label="Manufacturer/Make"
                    />
                    <TextInput
                        source={source('serialNumber')}
                        label="Serial Number"
                        validate={serialNumberValidation}
                    />
                    <TextInput
                        source={source('model')}
                        label="Model"
                        validate={maxLengthValidation}
                    />
                    <DateInput
                        source={source('modelYear')}
                        label="Model Year"
                        views={['year']}
                    />
                </Box>
            ) : null}
        </Collapse>
    )
}

export default UnitFormBodyType

const equipmentBodySource: keyof UnitModel = 'body'
const source = (name: keyof UnitModel['body']) => `${equipmentBodySource}.${name}`

const serialNumberValidation = validateMaxLength(30)
