import {
    type CreateThemeConfig as CreateBaseThemeConfig,
    type Components,
    createTheme as createBaseTheme,
    themeOption,
} from 'lib'

import { componentStyleOverrides } from './compStyleOverride'

const components = componentStyleOverrides(themeOption) as Components

export const createTheme = (params?: Omit<CreateBaseThemeConfig, 'components'>) =>
    createBaseTheme({ components, ...params })

export const mainTheme = createTheme()

export const getTheme = () => {
    return mainTheme
}
