import { type ReactElement } from 'react'

import { type Identifier, type DataRecord } from 'appTypes'
import SelectInput from 'components/inputs/SelectInput'
import { formHelperTextClasses } from 'ui'

export interface SortBy<RecordType extends DataRecord = any> {
    id: Identifier & keyof RecordType
    label: string | (() => ReactElement)
}

export interface ListSortContentProps<RecordType extends DataRecord = any> {
    sortBy: SortBy<RecordType>[]
}

const orders = [
    { id: 'ASC', label: 'Ascending' },
    { id: 'DESC', label: 'Descending' },
]

const emptyChoices = []

// if label is ReactElement and it is created inside component, this throws error ???
// this is why it needs to be function when we need component
const sourceOptionText = (data) => {
    const label = data.label
    return typeof label === 'function' ? label() : label
}

const ListSortDrawerContent = ({ sortBy = emptyChoices }: ListSortContentProps) => {
    return (
        <>
            <SelectInput
                clearable={false}
                source="field"
                label="Column/Label"
                sx={{
                    marginBottom: '16px',
                    [`& .${formHelperTextClasses.root}`]: {
                        display: 'none',
                    },
                }}
                choices={sortBy}
                optionText={sourceOptionText}
                helperText={false}
                disableEmptyValue
            />

            <SelectInput
                clearable={false}
                source="order"
                label="Sort Order"
                sx={{
                    [`& .${formHelperTextClasses.root}`]: {
                        display: 'none',
                    },
                }}
                choices={orders}
                optionText="label"
                helperText={false}
                disableEmptyValue
            />
        </>
    )
}

export default ListSortDrawerContent
