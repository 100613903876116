import { useFormContext } from 'react-hook-form'

import UtilityDrawerCloseButtonBase, {
    type UtilityDrawerCloseButtonBaseProps,
} from './UtilityDrawerCloseButtonBase'
import { useUtilityDrawerContext } from './UtilityDrawerContext'

export interface UtilityDrawerCancelButtonProps extends UtilityDrawerCloseButtonBaseProps {
    customAction?: () => void
}

const UtilityDrawerCancelButton = ({ customAction, ...props }: UtilityDrawerCancelButtonProps) => {
    const { close } = useUtilityDrawerContext()
    const { formState } = useFormContext() || {}

    return (
        <UtilityDrawerCloseButtonBase
            disabled={formState?.isSubmitting}
            {...props}
            onClick={
                customAction ??
                ((e) => {
                    props.onClick?.(e)
                    close()
                })
            }
            children={props.children ?? 'Cancel'}
        />
    )
}

export default UtilityDrawerCancelButton
