import { useState } from 'react'

import SidebarProfileMenu, { UserAvatar } from '../SidebarProfile'
import Item from '../desktop/Item'

import { CircleHover } from './ItemHover'
import SidebarTooltip from './SidebarTooltip'

const DesktopProfile = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const handleMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <SidebarTooltip title="Profile and Settings">
                <Item
                    py="16px"
                    onClick={handleMenuClick}
                >
                    <CircleHover>
                        <UserAvatar size="32px" />
                    </CircleHover>
                </Item>
            </SidebarTooltip>
            <SidebarProfileMenu
                onClose={handleMenuClose}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
                transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            />
        </>
    )
}

export default DesktopProfile
