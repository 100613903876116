import { type SyncedStateType } from './SyncedStateContext'

export const calculateSyncedUnits = (synced: SyncedStateType, vehiclesSynced: number) => {
    let difference = 0

    for (const key in synced) {
        if (synced[key]) {
            difference += 1
        } else {
            difference -= 1
        }
    }
    return (vehiclesSynced || 0) + difference
}
