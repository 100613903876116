import api from 'core/api'
import { useConfirm, type ConfirmConfig } from 'core/confirmation'
import { useFinalErrorHandler } from 'core/errors'
import { useNotify } from 'core/notifications'
import { useResource, type ResourceType } from 'core/resource'

// TODO: refactor this. Remove path
export interface ArchiveParams {
    path: string

    resource?: ResourceType

    notifyMessage?: string

    onSuccess?: (response: any) => void

    confirmConfig?: Partial<Omit<ConfirmConfig, 'onConfirm'>>

    isArchived: boolean
}

const useArchive = () => {
    const notify = useNotify()
    const confirm = useConfirm()
    const resourceFromContext = useResource()

    const errorHandler = useFinalErrorHandler()

    const archiveResource = (
        params: ArchiveParams | ((nestedParams: { resource: ResourceType }) => ArchiveParams),
    ) => {
        const finalParams =
            typeof params === 'function' ? params({ resource: resourceFromContext }) : params

        const { path, notifyMessage, confirmConfig, onSuccess, isArchived } = finalParams

        const action = isArchived ? 'unarchive' : 'archive'

        const archive = () => {
            api.post(path + '/' + action)
                .then((response) => {
                    onSuccess?.(response)
                    notify(
                        notifyMessage ??
                            (isArchived ? 'Successfully unarchived!' : 'Successfully archived!'),
                    )
                })
                .catch((err) => {
                    errorHandler(err)
                })
        }

        confirm({
            title: `Are you sure you want to ${isArchived ? 'unarchive' : 'archive'}?`,

            confirmType: isArchived ? 'UNARCHIVE' : 'ARCHIVE',
            ...confirmConfig,
            onConfirm: archive,
        })
    }

    return archiveResource
}

export default useArchive
