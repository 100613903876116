import { createContext, useContext } from 'react'

const MultiFormatScannerModalContext = createContext(null)

export const useMultiFormatScannerModalContext = () => useContext(MultiFormatScannerModalContext)
export const MultiFormatScannerModalContextProvider = MultiFormatScannerModalContext.Provider

export interface MultiFormatScannerContextResult {
    streamSettings: MediaTrackConstraints
    activeCameraId: string
    applyConstraints: (constraints: MediaTrackConstraints) => Promise<void>
    setActiveCamera: (camera: string) => void
    cameras: MediaDeviceInfo[]
}

const MultiFormatScannerContext = createContext<MultiFormatScannerContextResult>(null)
export const useMultiFormatScannerContext = () => useContext(MultiFormatScannerContext)
export const MultiFormatScannerContextProvider = MultiFormatScannerContext.Provider
