import { useShowContext } from 'react-admin'

import { ViewHeader } from 'components'
import { PurchaseOrderCardSkeleton } from 'pages/PurchaseOrders/components'
import { type PurchaseOrderModel } from 'resources/purchaseOrders'
import { GridContainerColumns, GridItemLayout, Section } from 'ui'

import PODetailsCard from './PODetailsCard'
import POEditOpenDrawerButton from './POEditOpenDrawerButton'
import ReasonForCancelationCard from './ReasonForCancelationCard'

const PurchaseOrderDetails = () => {
    const { record } = useShowContext<PurchaseOrderModel>()

    return (
        <Section>
            <ViewHeader
                title="Details"
                loading={!record}
            >
                <ViewHeader.Content placement="rightMobile">
                    <POEditOpenDrawerButton record={record} />
                </ViewHeader.Content>
            </ViewHeader>
            <GridContainerColumns>
                <GridItemLayout>
                    <ReasonForCancelationCard />
                    {record ? <PODetailsCard /> : <PurchaseOrderCardSkeleton height="212px" />}
                </GridItemLayout>
            </GridContainerColumns>
        </Section>
    )
}

export default PurchaseOrderDetails
