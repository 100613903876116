import { type FC, type ReactElement } from 'react'

import { type Identifier, type RepeatKeys } from 'appTypes'
import { UtilityDrawerEditor, useOpenUtilityDrawer } from 'components'
import { useResource } from 'core'
import { type DeleteOneParams } from 'core/actions'

import { purchaseInvoiceSerializer } from '../utils'

import { PurchaseInvoiceForm, type PurchaseInvoiceFormProps } from './PurchaseInvoiceForm'

export interface PurchaseInvoiceDrawerTogglerProps
    extends Omit<PurchaseInvoiceFormProps, 'disabledInputs'> {
    id?: Identifier
    children: (render: () => void) => ReactElement
    renderInvoiceForm?: () => ReactElement
    renderAboveFooter?: () => ReactElement
    title?: string
    submitButton?: {
        icon?: ReactElement
        label?: string
    }
    readOnly?: boolean
}

export const PurchaseInvoiceDrawerToggler: FC<PurchaseInvoiceDrawerTogglerProps> = ({
    children,
    id,
    renderInvoiceForm,
    renderAboveFooter,
    title,
    submitButton,
    defaultValues,
    readOnly,
}) => {
    const open = useOpenUtilityDrawer()
    const resource = useResource()

    return children(() => {
        open({
            extraArgs: {
                id,
                resource,
            },
            drawerArgs: {
                title: title ?? (id ? (readOnly ? 'View Invoice' : 'Edit Invoice') : 'Add Invoice'),
                renderWrapper: (params) => (
                    <UtilityDrawerEditor
                        {...params}
                        serializer={purchaseInvoiceSerializer}
                    />
                ),
                renderContent:
                    renderInvoiceForm ||
                    (() => (
                        <PurchaseInvoiceForm
                            defaultValues={defaultValues}
                            disabledInputs={readOnly}
                        />
                    )),
                renderBottomRight: readOnly
                    ? () => null
                    : (render) =>
                          render({
                              label: submitButton?.label ?? (id ? undefined : 'Add Invoice'),
                              icon: submitButton?.icon,
                          }),
                renderTopRight: id && !readOnly ? (render) => render(deleteInvoiceAction()) : null,
                renderAboveFooter,
            },
        })
    })
}

export const deleteInvoiceAction = (): DeleteOneParams => ({
    confirmConfig: {
        title: 'Are you sure you want to delete the selected Invoice?',
    },
})

export type Attachments = RepeatKeys<'attachment', 9, string>
