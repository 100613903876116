import { useShowContext } from 'react-admin'

import { type DrawerState, useOpenUtilityDrawer, UtilityDrawerEditor } from 'components'
import { useResource } from 'core'
import { deleteMeterActionParams, type UnitModel } from 'resources/units'

import { meterSerializer } from '../constants'

import UnitMetersFormContent, {
    type UnitMeterDrawerExtraState,
} from './../components/UnitMetersFormContent'

const useOpenMetersDrawer = () => {
    const open = useOpenUtilityDrawer()
    const resource = useResource()
    const { record } = useShowContext<UnitModel>()

    return ({ extraArgs = {}, drawerArgs }: DrawerState = {}) =>
        open({
            extraArgs: {
                resource,
                ...extraArgs,
            },
            drawerArgs: {
                title: extraArgs.id ? 'Edit Meter' : 'Add Meters',
                renderContent: () => <UnitMetersFormContent />,
                renderBottomRight: (render) =>
                    render({ label: extraArgs.id ? undefined : 'Add Meters' }),
                renderTopRight: extraArgs.id ? (render) => render(deleteMeterActionParams) : null,
                renderWrapper: (params) => (
                    <UtilityDrawerEditor
                        {...params}
                        serializer={meterSerializer}
                    />
                ),
                ...drawerArgs,
            },
            extra: {
                unit: record,
            } as UnitMeterDrawerExtraState,
        })
}

export default useOpenMetersDrawer
