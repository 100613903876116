import { type FC } from 'react'

import Icons from 'assets/icons'
import { DataAvatar, type DataAvatarProps } from 'ui'

import { type ExpirationModel, type StatusKeys } from '../types'

import { status } from './status'

const Avatar: FC<{ status: StatusKeys }> = ({ status: statusProp }) => {
    return <DataAvatar {...getProps(statusProp)} />
}

const getProps = (statusProp: StatusKeys): Pick<DataAvatarProps, 'defaultImage' | 'color'> => {
    const { color } = status.getConfig(statusProp)

    return {
        defaultImage: <Icons.Event />,
        color,
    }
}

const source = 'avatar' as keyof ExpirationModel

export const avatar = {
    source,
    label: 'Avatar',
    Value: Avatar,
    getProps,
}
