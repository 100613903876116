import { type ShopModel } from 'resourcesBase'

const source = 'contact' as const satisfies keyof ShopModel

interface Data extends Pick<ShopModel, 'contact'> {}

const value = (params: Data) => {
    if (!params) {
        return ''
    }
    return params.contact
}

const contactPerson = {
    source,
    label: 'Contact Person',
    value,
}

export default contactPerson
