import { useReferenceInputInfiniteScroll, useReferenceInputTotal } from 'core/referenceController'
import { useWillUnmount } from 'hooks'
import { LoadingButton, Box, Typography } from 'ui'

import { useDialogSelectorContext } from './DialogSelectorContext'

const DialogSelectorLoadMore = () => {
    const { queryMeta } = useDialogSelectorContext()
    const { reset, loadMoreResults, choicesController, loading } = useReferenceInputInfiniteScroll({
        meta: queryMeta,
    })

    const { availableChoices, isFetching } = choicesController

    const total = useReferenceInputTotal()

    const isLoadingProgress = isFetching || loading

    const totalFetchedCount = availableChoices.length
    const allFetched = totalFetchedCount === total

    useWillUnmount(reset)

    if (allFetched) {
        return null
    }

    return (
        <Box
            mt="auto"
            p="10.5px 20px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
                boxShadow: 6,
            }}
        >
            {isLoadingProgress ? (
                <span />
            ) : (
                <Typography
                    variant="caption"
                    color="textPrimary"
                >
                    {totalFetchedCount} / {total} Showing
                </Typography>
            )}

            <LoadingButton
                size="small"
                onClick={() => loadMoreResults()}
                loading={isLoadingProgress}
                disabled={allFetched}
            >
                See More
            </LoadingButton>
        </Box>
    )
}

export default DialogSelectorLoadMore
