import { type FC } from 'react'

import { type WithChildrenNode } from 'appTypes'
import { Box } from 'ui'

export const EllipseHover: FC<WithChildrenNode> = ({ children }) => {
    return (
        <Box
            className={itemHoverClass}
            p="4px 16px"
            borderRadius="16px"
            position="relative"
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            {children}
        </Box>
    )
}

export const CircleHover: FC<WithChildrenNode> = ({ children }) => {
    return (
        <Box
            className={itemHoverClass}
            p="4px"
            borderRadius="50%"
        >
            {children}
        </Box>
    )
}

export const itemHoverClass = 'item-hover-element'
