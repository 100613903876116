import { type FC } from 'react'

import { type SvgIconElement } from 'appTypes'
import Icons from 'assets/icons'
import { formatPercent, formatMoney } from 'components'
import { useTheme } from 'lib'

import { statusColors } from '../../CostPerCategoryWidget/types'
import { WidgetPieChartBase } from '../../components'
import { type listType, type ratioDataType } from '../types'

interface CostPerCategoryChartProps {
    list: listType
    total: ratioDataType['total']
    disabled: boolean
    icon?: SvgIconElement
}
const CostPerVMRSChart: FC<CostPerCategoryChartProps> = ({ list, total, disabled, icon }) => {
    const { palette } = useTheme()
    const listData = list.data.map((obj, index) => {
        return {
            id: `${obj.code.substring(1, 2)} ${obj.description}`,
            value: obj.total,
            color: palette.charts[statusColors[index]],
            total,
            formattedValue: obj.totalPercent,
        }
    })

    return (
        <WidgetPieChartBase
            disabled={disabled}
            icon={icon || Icons.PollOutlined}
            sortByValue={false}
            renderContent={({ id, value, formattedValue }) => [
                id,
                formatPercent(formattedValue),
                formatMoney(value),
            ]}
            data={[
                ...listData,
                {
                    id: 'Other',
                    value: list.sumOfExcludedGroups,
                    color: palette.charts.disable,
                    total,
                    formattedValue: 100 - list.top3Percentage,
                },
            ]}
        />
    )
}
export default CostPerVMRSChart
