import { type TagModel } from '../types'

const source: keyof TagModel = 'color'

const color = {
    source,
    label: 'Color',
}

export default color
