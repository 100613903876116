import { useWatch } from 'react-hook-form'

import { CustomerInput } from './CustomerInput'

export const CustomerPopUpInput = () => {
    const customer = useWatch({ name: 'customer' })
    if (!customer) {
        return null
    }
    return <CustomerInput disabled />
}
