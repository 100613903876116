import { formatPercent } from 'components'

import { type PtFeeModel } from '../types'

const source = 'markupPercent' as const satisfies keyof PtFeeModel

const label = 'Percentage'

const value = (record: Pick<PtFeeModel, 'markupPercent'>) => {
    return formatPercent(record.markupPercent, undefined, true)
}
export const percentage = {
    source,
    label,
    value,
}
