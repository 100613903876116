import { useWatch, useFormContext } from 'react-hook-form'

import Icons from 'assets/icons'
import {
    ArrayControllerContextProvider,
    ArrayControllerDeleteIcon,
    ArrayControllerElements,
    useArrayControllerContext,
} from 'components'
import { useNotify } from 'core'
import { useAttachmentFile } from 'hooks'
import { globalClassNames, alpha } from 'lib'
import {
    MAX_ATTACHMENTS_COUNT,
    attachmentName,
    attachmentsIndexes,
    maxFileSize,
} from 'resources/gallery'
import {
    NonDisplayedInput,
    SvgIcon,
    Stack,
    Box,
    FormHelperText,
    InfoBadge,
    Img,
    Typography,
    BoxContainer,
    Button,
} from 'ui'

interface AttachmentProps {
    index: number
    upload: (event, index: number) => void
    disabled: boolean
}

const Attachment = (props: AttachmentProps) => {
    const { extra } = useArrayControllerContext()
    const { getAttachmentSource } = extra as AttachmentsArrayControllerExtra
    const source = getAttachmentSource(props.index)
    const file: string | File = useWatch({ name: source })

    if (!file) {
        return null
    }

    return (
        <AttachmentContent
            {...props}
            file={file}
        />
    )
}

interface AttachmentContentProps extends AttachmentProps {
    file: string | File
}

const AttachmentContent = ({ file, upload, index, disabled }: AttachmentContentProps) => {
    const img = useAttachmentFile(file)

    return (
        <Box
            display="flex"
            alignItems="center"
            bgcolor={(theme) => alpha(theme.palette.text.primary, 0.04)}
            p="20px"
        >
            <Box
                width="56px"
                height="80px"
                flexShrink={0}
                boxShadow={1}
                bgcolor="white"
                display="flex"
                borderRadius="2px"
                overflow="hidden"
            >
                {img &&
                    (img.url === 'pdf' ? (
                        <SvgIcon
                            component={Icons.Pdf}
                            inheritViewBox
                            sx={{
                                width: '24px',
                                height: '24px',
                                m: 'auto',
                                color: (theme) => alpha(theme.palette.text.main, 0.54),
                            }}
                        />
                    ) : (
                        <Img
                            src={img.url}
                            alt="attachment"
                            sx={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                            }}
                        />
                    ))}
            </Box>

            <Box
                ml="18px"
                overflow="hidden"
                py="3px"
            >
                {img && (
                    <Typography
                        variant="body2"
                        color="text.primary"
                        mb="8px"
                        className={globalClassNames.ellipsis}
                    >
                        {img.name}
                    </Typography>
                )}
                <label htmlFor={img ? undefined : 'unknown-label-for'}>
                    <NonDisplayedInput
                        type="file"
                        disabled={disabled}
                        onChange={(event) => upload(event, index)}
                        accept="image/jpeg, image/png, application/pdf"
                    />
                    <Button
                        component="span"
                        variant="contained"
                        size="small"
                        color="primary"
                        disabled={!img || disabled}
                    >
                        Change File
                    </Button>
                </label>
            </Box>

            <ArrayControllerDeleteIcon
                aria-label={`Delete Attachment ${img?.name || ''}`}
                sx={{ ml: 'auto' }}
                disabled={!img || disabled}
                controller={{ alwaysVisible: true }}
            />
        </Box>
    )
}

interface AttachmentsArrayControllerExtra {
    getAttachmentSource: (index: number) => string
}

const Attachments = ({
    disabled,
    getSource,
}: {
    disabled?: boolean
    getSource?: (source: string) => string
}) => {
    const { getValues } = useFormContext()

    const getAttachmentSource = getSource
        ? (index: number) => getSource(attachmentName(index))
        : attachmentName

    return (
        <ArrayControllerContextProvider
            initialArray={() =>
                attachmentsIndexes.filter((index) => getValues(getAttachmentSource(index)))
            }
            extra={
                {
                    getAttachmentSource,
                } as AttachmentsArrayControllerExtra
            }
        >
            <AttachmentsContent disabled={disabled} />
        </ArrayControllerContextProvider>
    )
}

const AttachmentsContent = ({ disabled }: { disabled?: boolean }) => {
    const notify = useNotify()
    const { setValue } = useFormContext()
    const { array, append, extra } = useArrayControllerContext()
    const { getAttachmentSource } = extra as AttachmentsArrayControllerExtra

    const setFile = (index: number, file: File) => {
        setValue(getAttachmentSource(index), file, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
        })
    }

    const upload = (event, index?: number) => {
        const file = event.target.files?.[0]

        if (!file) {
            return
        }

        const type = file.type

        if (!((type.startsWith('image') && !type.includes('svg')) || type === 'application/pdf')) {
            notify({
                title: 'Invalid file format',
                type: 'error',
            })
        } else if (file.size > maxFileSize.size) {
            notify({
                title: maxFileSize.errorMessage,
                type: 'error',
            })
        } else {
            let freeItem = index
            if (typeof index === 'undefined') {
                freeItem = append()
            }
            setFile(freeItem, file)
        }

        event.target.value = null
    }

    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <BoxContainer gap="8px">
                    <Typography
                        color="text.primary"
                        variant="subtitle1"
                    >
                        Attachments
                    </Typography>
                    <InfoBadge badgeContent={array.length} />
                </BoxContainer>

                {array.length !== MAX_ATTACHMENTS_COUNT && (
                    <label>
                        <NonDisplayedInput
                            disabled={disabled}
                            type="file"
                            onChange={upload}
                            accept="image/jpeg, image/png, application/pdf"
                        />
                        <Button
                            variant="text"
                            size="small"
                            component="div"
                            startIcon={<Icons.UploadFileOutlined />}
                            disabled={disabled}
                        >
                            Upload File
                        </Button>
                    </label>
                )}
            </Box>
            <FormHelperText sx={{ mb: '16px', textAlign: 'end' }}>Max 30 MB each</FormHelperText>
            <Stack
                spacing="20px"
                width="100%"
            >
                <ArrayControllerElements onDelete={({ item }) => setFile(item, null)}>
                    {({ item }) => (
                        <Attachment
                            upload={upload}
                            key={item}
                            index={item}
                            disabled={disabled}
                        />
                    )}
                </ArrayControllerElements>
            </Stack>
        </>
    )
}
export default Attachments
