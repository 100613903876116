import { type FC } from 'react'

import Icons from 'assets/icons'
import { CollapsibleContent } from 'components'
import { globalClassNames } from 'lib'
import { Icon, Stack, Typography, BoxContainer } from 'ui'

interface Props {
    attachments: string[]
}

export const AttachmentsCollapsibleContent: FC<Props> = ({ attachments }) => {
    return (
        <CollapsibleContent name="attachments">
            <Stack>
                {attachments.map((att) => (
                    <BoxContainer
                        key={att}
                        mt="10px"
                        sx={{
                            cursor: 'pointer',
                        }}
                    >
                        <Icon
                            sx={(theme) => ({
                                color: theme.palette.primary.main,
                            })}
                        >
                            <Icons.InsertDriveFileOutlined fontSize="small" />
                        </Icon>
                        <Typography
                            component="a"
                            href={att}
                            target="_blank"
                            rel="noreferrer"
                            variant="body2"
                            ml="10px"
                            color="primary.main"
                            className={globalClassNames.ellipsis}
                        >
                            {att.split('?')[0].split('/').pop()}
                        </Typography>
                    </BoxContainer>
                ))}
            </Stack>
        </CollapsibleContent>
    )
}
