export enum Threshold {
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    YEAR = 'YEAR',
}

interface ThresholdType {
    id: Threshold
    name: string
    toDay: number
}

export const thresholdTypeChoices: ThresholdType[] = [
    {
        id: Threshold.DAY,
        name: 'Day(s)',
        toDay: 1,
    },
    {
        id: Threshold.WEEK,
        name: 'Week(s)',
        toDay: 7,
    },
    {
        id: Threshold.MONTH,
        name: 'Month(s)',
        toDay: 30,
    },
    {
        id: Threshold.YEAR,
        name: 'Year(s)',
        toDay: 360,
    },
]

export const getThresholdTypeConfig = (type: Threshold) =>
    thresholdTypeChoices.find((choice) => choice.id === type)
