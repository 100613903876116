import { renderOnPermission, basePermissions } from 'core/permissions'

import { type ActionChildren } from '../Actions'

import { deleteActionBaseOptions } from './deleteActionCommon'
import useDeleteOne, { type DeleteOneParams } from './useDeleteOne'

interface DeleteOneActionProps extends DeleteOneParams {
    children: ActionChildren
    disabled?: boolean
    title?: string
    titleOnDisabled?: string
}

export const DeleteOneAction = renderOnPermission(
    ({ children, disabled, title, titleOnDisabled, ...rest }: DeleteOneActionProps) => {
        const deleteOne = useDeleteOne()

        return children({
            disabled: disabled ?? false,
            Icon: deleteActionBaseOptions.Icon,
            onClick: () => {
                deleteOne(rest)
            },
            title: title ?? 'Delete',
            titleOnDisabled,
        })
    },
    basePermissions.destroy,
)

export const deleteOneAction = (params: DeleteOneActionProps) => (
    <DeleteOneAction
        {...params}
        key="delete-one"
    />
)
