/* eslint-disable no-console */
// This optional code is used to register a service worker.
// register() is not called by default.

import { Workbox, type WorkboxLifecycleWaitingEvent } from 'workbox-window'

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

export function register() {
    if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
        const wb = new Workbox(`${process.env.PUBLIC_URL}/service-worker.js`)

        const showSkipWaitingPrompt = async (event: WorkboxLifecycleWaitingEvent) => {
            // Assuming the user accepted the update, set up a listener
            // that will reload the page as soon as the previously waiting
            // service worker has taken control.
            wb.addEventListener('controlling', () => {
                // At this point, reloading will ensure that the current
                // tab is loaded under the control of the new service worker.
                // Depending on your web app, you may want to auto-save or
                // persist transient state before triggering the reload.
                window.location.reload()
            })

            // When `event.wasWaitingBeforeRegister` is true, a previously
            // updated service worker is still waiting.
            // You may want to customize the UI prompt accordingly.

            // This code assumes your app has a promptForUpdate() method,
            // which returns true if the user wants to update.
            // Implementing this is app-specific; some examples are:
            // https://open-ui.org/components/alert.research or
            // https://open-ui.org/components/toast.research
            wb.messageSkipWaiting()
        }

        // Add an event listener to detect when the registered
        // service worker has installed but is waiting to activate.
        wb.addEventListener('waiting', (event) => {
            showSkipWaitingPrompt(event)
        })

        // wb.addEventListener("waiting", (event) => {
        //   wb.addEventListener("controlling", (event) => {
        //     console.log("Reloading page for latest content");
        //     window.location.reload();
        //   });
        //   wb.messageSW({ type: "SKIP_WAITING" });
        //   // Old serviceworker message for migration, can be removed in the future
        //   wb.messageSW("SKIP_WAITING");
        // });
        wb.register()
    }
}
