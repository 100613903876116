import Icons from 'assets/icons'
import { IconButton, IconElement } from 'ui'

import { useCollapsibleController } from './CollapsibleContext'

const CollapsibleTogglerAction = ({ name }: { name?: string }) => {
    const { toggleExpand, isExpanded } = useCollapsibleController(name)

    return (
        <IconButton
            aria-label={isExpanded ? 'Collapse' : 'Expand'}
            onClick={toggleExpand}
            size="small"
            sx={{ width: '22px', height: '22px' }}
        >
            {isExpanded ? <Icons.KeyboardArrowUpOutlined /> : <Icons.KeyboardArrowDownOutlined />}
        </IconButton>
    )
}

export default CollapsibleTogglerAction

// TODO: Delete CollapsibleTogglerAction
export const CollapsibleTogglerIconAction = ({ name }: { name?: string }) => {
    const { toggleExpand, isExpanded, disabled } = useCollapsibleController(name)

    return (
        <IconButton
            aria-label={isExpanded ? 'Collapse' : 'Expand'}
            onClick={toggleExpand}
            size="small"
            disabled={disabled}
            color="inherit"
        >
            <IconElement
                component={
                    isExpanded ? Icons.KeyboardArrowUpOutlined : Icons.KeyboardArrowDownOutlined
                }
            />
        </IconButton>
    )
}
