import { type FC } from 'react'

import { inject, observer } from 'mobx-react'
import {
    ListBase,
    RecordContextProvider,
    type ListControllerResult,
    useShowContext,
} from 'react-admin'

import Icons from 'assets/icons'
import {
    InfoCardHeader,
    WithListContext,
    CollapsibleInfoCard,
    CollapsibleIconHolder,
    CollapsibleContent,
    getRecordPhotos,
} from 'components'
import { type AuthStore, type ResourceType } from 'core'
import { ActionsMenu, useCanAccess, type MenuItemActions } from 'core/actions'
import { globalClassNames, styled } from 'lib'
import { NotesInput } from 'resources/notes'
import { ck14Fields, ck33Fields } from 'resources/vmrs'
import {
    type WorkOrderModel,
    type JobModel,
    useWoActionIsDisabled,
    jobSourceMap,
    type JobLaborModel,
    type JobPartModel,
    WoJobDrawerToggler,
    woOperations,
    isWorkOrderBillable,
} from 'resources/workOrders'
import {
    SectionTypography,
    GridItemLayout,
    IconBox,
    Spacer,
    Typography,
    BoxContainer,
    Tooltip,
    SvgIcon,
    Divider,
    cardHeaderClasses,
    Anchor,
} from 'ui'

import WorkOrderJobItemsResource from '../WorkOrderJobItemsResource'

import { CardJobPhotos, JobSourceLabel, WorkOrderJobCardItems } from './components'

export type WorkOrderJobCardActions = MenuItemActions<
    JobModel,
    { listContext: ListControllerResult<JobModel> }
>
export type WorkOrderJobCardItemsActions = MenuItemActions<
    JobModel,
    { listContext: ListControllerResult<JobLaborModel | JobPartModel> }
>

export interface WorkOrderJobCardProps {
    job: JobModel
    resource: ResourceType
    jobsListContext: ListControllerResult<JobModel>
    itemActions: WorkOrderJobCardItemsActions
    actions: WorkOrderJobCardActions
}

const JobCardSectionTypography = styled(Typography)`
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 167%;
`

const WorkOrderJobCard: FC<WorkOrderJobCardProps> = inject('auth')(
    observer(
        ({
            job,
            resource,
            jobsListContext,
            itemActions,
            actions,
            auth,
        }: WorkOrderJobCardProps & { auth: AuthStore }) => {
            const name = job.name
            const { record: woRecord } = useShowContext<WorkOrderModel>()

            const inJobsTab = window.location.toString().includes('/jobs')
            const jobPhotosCount = getRecordPhotos(job).count
            const disabled = useWoActionIsDisabled()

            const getCanAccess = useCanAccess()
            const canAccess = getCanAccess(woOperations.jobItems, woRecord)
            const canAccessNotes = getCanAccess(woOperations.notes, woRecord)

            return (
                <GridItemLayout
                    key={job.id}
                    gridColumns={inJobsTab ? { xs: 12 } : undefined}
                >
                    <RecordContextProvider value={job}>
                        <WorkOrderJobItemsResource>
                            <CollapsibleInfoCard
                                defaultOpen={inJobsTab}
                                disableActiveState={inJobsTab}
                                sameParent
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    minHeight: '176px',
                                    [`& .${cardHeaderClasses.content}`]: {
                                        overflow: 'hidden',
                                    },
                                }}
                            >
                                <ListBase perPage={200}>
                                    <InfoCardHeader
                                        action={
                                            <WithListContext>
                                                {(listContext) => (
                                                    <Spacer>
                                                        {auth.companySettings.hasInvoicing &&
                                                            isWorkOrderBillable(woRecord) &&
                                                            !job.billable && (
                                                                <Tooltip title="Non-Billable">
                                                                    <SvgIcon
                                                                        inheritViewBox
                                                                        sx={(theme) => ({
                                                                            width: '18px',
                                                                            height: '18px',
                                                                            color: theme.palette
                                                                                .warning.main,
                                                                        })}
                                                                        component={
                                                                            Icons.ReceiptCrossOutlined
                                                                        }
                                                                    />
                                                                </Tooltip>
                                                            )}
                                                        {jobSourceMap[job.source] ? (
                                                            <JobSourceLabel
                                                                source={job.source}
                                                                sourceData={getSourceData(job)}
                                                            />
                                                        ) : null}
                                                        <ActionsMenu
                                                            disabled={!canAccess.value}
                                                            record={job}
                                                            actions={itemActions}
                                                            title={canAccess.message}
                                                            icon={Icons.Add}
                                                            actionArgs={{ listContext }}
                                                        />
                                                        {disabled ? (
                                                            <WoJobDrawerToggler
                                                                id={job.id}
                                                                key="edit"
                                                                children={(open) => (
                                                                    <IconBox onClick={open}>
                                                                        <Icons.Options />
                                                                    </IconBox>
                                                                )}
                                                            />
                                                        ) : (
                                                            <ActionsMenu
                                                                record={job}
                                                                actions={actions}
                                                                actionArgs={{
                                                                    listContext: jobsListContext,
                                                                }}
                                                                resource={resource}
                                                            />
                                                        )}
                                                    </Spacer>
                                                )}
                                            </WithListContext>
                                        }
                                        title={
                                            <>
                                                <SectionTypography
                                                    mb="1px"
                                                    variant="inherit"
                                                >
                                                    <WoJobDrawerToggler id={job.id}>
                                                        {(open) => (
                                                            <Anchor
                                                                onClick={open}
                                                                className={
                                                                    globalClassNames.ellipsis
                                                                }
                                                            >
                                                                {name}
                                                            </Anchor>
                                                        )}
                                                    </WoJobDrawerToggler>
                                                </SectionTypography>
                                                <JobCardSectionTypography
                                                    variant="caption"
                                                    mb="0"
                                                >
                                                    {ck33Fields.self.value(job.componentData)}
                                                </JobCardSectionTypography>
                                                <JobCardSectionTypography
                                                    variant="caption"
                                                    mb="12px"
                                                >
                                                    {ck14Fields.self.value(
                                                        job?.reasonForRepairData,
                                                    ) || '-'}
                                                </JobCardSectionTypography>
                                            </>
                                        }
                                        noDivider
                                    />
                                    <WorkOrderJobCardItems itemActions={itemActions} />
                                </ListBase>
                                <>
                                    <Divider
                                        sx={{
                                            margin: '10px 0px',
                                        }}
                                    />
                                    <BoxContainer gap="8px">
                                        <CollapsibleIconHolder
                                            name="notes"
                                            title={
                                                job.notes.length
                                                    ? `Notes (${job.notes.length})`
                                                    : 'No Notes Added'
                                            }
                                            disabled={
                                                woRecord.status === 'CLOSED' && !job.notes.length
                                            }
                                            titleOnDisabled="No Notes Added"
                                        >
                                            <Icons.CommentOutlined fontSize="small" />
                                        </CollapsibleIconHolder>
                                        <CollapsibleIconHolder
                                            name="photos"
                                            disabled={
                                                woRecord.status === 'CLOSED' && !jobPhotosCount
                                            }
                                            titleOnDisabled="No Photos Added"
                                            title={
                                                jobPhotosCount
                                                    ? `Photos (${jobPhotosCount})`
                                                    : 'No Photos Added'
                                            }
                                        >
                                            <Icons.PhotoOutlined fontSize="small" />
                                        </CollapsibleIconHolder>
                                    </BoxContainer>
                                    <CollapsibleContent name="photos">
                                        <CardJobPhotos />
                                    </CollapsibleContent>
                                    <CollapsibleContent name="notes">
                                        <NotesInput
                                            notes={job.notes}
                                            resource={resource.resource}
                                            disabled={!canAccessNotes.value}
                                            titleOnDisabled={canAccessNotes.message}
                                        />
                                    </CollapsibleContent>
                                </>
                            </CollapsibleInfoCard>
                        </WorkOrderJobItemsResource>
                    </RecordContextProvider>
                </GridItemLayout>
            )
        },
    ),
)

export default WorkOrderJobCard

const getSourceData = (job: JobModel): { name: string } => {
    const source = job.source
    if (source === 'DEFECT') {
        return job.defectData
    }
    if (source === 'PM_SCHEDULE' && job.pmSchedule) {
        return { name: '' }
    }
    if (source === 'ISSUE') {
        return job.issueData
    }
    return null
}
