import { type Identifier } from 'appTypes'
import { Switch, BoxContainer, Typography } from 'ui'
import { displayBooleanValue } from 'utils'

import { useSyncedStateContext } from '../SyncedStateContext'

interface ToSyncSwitchProps {
    id: Identifier
    toSync: boolean
}

const ToSyncSwitch = ({ id, toSync }: ToSyncSwitchProps) => {
    const { synced, handleSyncedChange } = useSyncedStateContext()
    const checked = synced[id] ?? toSync
    return (
        <BoxContainer component="span">
            <Switch
                size="small"
                color="success"
                checked={checked}
                onChange={(e) =>
                    handleSyncedChange({
                        [id]: e.target.checked,
                    })
                }
            />
            <Typography
                variant="body2"
                component="span"
            >
                {displayBooleanValue(checked)}
            </Typography>
        </BoxContainer>
    )
}

export default ToSyncSwitch
