import { useContext, useEffect } from 'react'

import { SyncContext } from './SyncContext'

export const useSync = (isActive: boolean = true) => {
    const { subscribeSync } = useContext(SyncContext)

    useEffect(() => {
        if (isActive) {
            const unsubscribe = subscribeSync()
            return unsubscribe
        }
    }, [isActive])
}
