import { HexColorPicker } from 'react-colorful'

import { styled } from 'lib'

export const Palette = styled(HexColorPicker)`
    width: 100% !important;
    gap: 24px;

    .react-colorful__saturation {
        border-radius: 4px;
    }

    .react-colorful__hue {
        border-radius: 4px;
        height: 8px;
    }
`
