import { type Identifier, type BaseModel } from 'appTypes'
import { type CustomerModel } from 'resources/customers'
import { type UnitModel } from 'resources/units'

export const enum AssignmentsStatusKeys {
    ONGOING = 'ONGOING',
    COMPLETED = 'COMPLETED',
}
export interface AssignmentModel extends BaseModel {
    created: string
    end: string
    start: string
    customer: Identifier
    customerData: CustomerModel
    unit: Identifier
    unitData: UnitModel
    status: AssignmentsStatusKeys
    accumulatedCost: number
}

export interface AssignmentAggregates {
    ongoingCount: number
    completedCount: number
}
