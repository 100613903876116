import { type FC } from 'react'

import Icons from 'assets/icons'
import { Link, type To } from 'lib'
import { IconButton } from 'ui'

import HeaderAside from './HeaderAside'

export interface BackButtonProps {
    to?: To
}

const BackButtonBase: FC<BackButtonProps> = ({ to = '..' }) => {
    return (
        <Link
            aria-label="Go back"
            to={to}
        >
            <IconButton sx={{ width: '28px', height: '28px' }}>
                <Icons.ArrowUpwardOutlined sx={{ color: (theme) => theme.palette.text.primary }} />
            </IconButton>
        </Link>
    )
}

const HeaderBackButton: FC<BackButtonProps> = (props) => {
    return (
        <HeaderAside>
            <BackButtonBase {...props} />
        </HeaderAside>
    )
}

export default HeaderBackButton
