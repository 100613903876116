import { type ReactNode } from 'react'

import { ListContextProvider } from 'react-admin'

import { useCreateResourcePath, useListController } from 'core'
import { type PartModel } from 'resources/parts'

const CrossReferenceList = ({
    record,
    children,
}: {
    record: PartModel | undefined
    children: ReactNode
}) => {
    const createPath = useCreateResourcePath()

    const path =
        createPath({
            id: record?.id,
            type: 'edit',
        }) + '/cross-references'

    const listController = useListController({
        resource: path,
        perPage: 3,
        queryOptions: {
            enabled: Boolean(record?.id),
        },
    })

    return <ListContextProvider value={listController}>{children}</ListContextProvider>
}
export default CrossReferenceList
