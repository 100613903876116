import { ResourceWithClearEffect } from 'core'
import { invoicesResource } from 'resources/invoices'

import InvoicesList from './List'
import InvoiceShow from './Show'

const Invoices = () => {
    // useCustomResponse(r)
    // useCustomResponse(getOne)

    return (
        <ResourceWithClearEffect
            name={invoicesResource}
            list={InvoicesList}
            edit={InvoiceShow}
        />
    )
}

export default Invoices

// const data: Partial<InvoiceModel> = {
//     id: 1,
//     number: 'test',
//     balanceDue: 10,
//     closedDate: new Date().toDateString(),
//     created: new Date().toDateString(),
//     customer: 1,
//     customerPo: 'customerPo',
//     poNumber: '112',
//     total: 123,
//     status: InvoiceStatusKeys.CANCELED,
//     statusUpdatedBy: {
//         name: 'test',
//         email: 'test@test.test',
//     } as any,
//     statusUpdated: new Date().toDateString(),
//     cancellationReason: 'test',
// }

// const r: CustomResponse = {
//     path: 'invoices',
//     return: {
//         results: [data],
//         count: 1,
//     },
// }

// const getOne: CustomResponse = {
//     path: 'invoices/1',
//     return: data,
// }
