import { type FC, useState } from 'react'

import { Snackbar as SnackbarBase } from 'ui'

import { Alert } from './Alert'
import { type NotificationConfig } from './types'

interface Props {
    notification: NotificationConfig
    close: () => void
}

const Snackbar: FC<Props> = ({ notification, close }) => {
    const [isOpen, setOsOpen] = useState(true)

    const handleRequestClose = () => {
        setOsOpen(false)
    }

    return (
        <SnackbarBase
            open={isOpen}
            autoHideDuration={3000}
            onClose={handleRequestClose}
            // close on animation end
            TransitionProps={{ onExited: close }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            sx={{
                bottom: (theme) => ({
                    xs: '70px !important',
                    [theme.props.mobileViewBreakpoint]: '20px !important',
                }),
                maxWidth: (theme) => ({
                    xs: 'calc(100% - 20px)',
                    [theme.props.mobileViewBreakpoint]: 'calc(100% - 40px)',
                }),
                boxSizing: 'border-box',
                left: '50%',
                right: 'unset',
                transform: 'translateX(-50%)',
            }}
        >
            <div>
                <Alert
                    severity={notification.type || 'success'}
                    onClose={handleRequestClose}
                >
                    <div>{notification.title}</div>
                    {notification.message ? <div>{notification.message}</div> : null}
                </Alert>
            </div>
        </SnackbarBase>
    )
}

export default Snackbar
