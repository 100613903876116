import { type FC } from 'react'

import { useListContext } from 'react-admin'

import { SliderView } from 'components'
import { Section } from 'ui'

import { type PaymentModel } from '../types'

import PaymentCard from './PaymentCard'
import PaymentsHeader from './PaymentsHeader'
import PaymentsSectionEmpty from './PaymentsSectionEmpty'
import { type CreateDefaultValues } from './types'

interface Props extends CreateDefaultValues {
    readOnly?: boolean
}

const PaymentsSection: FC<Props> = ({ readOnly, createDefaultValues }) => {
    const { isLoading, total } = useListContext<PaymentModel>()

    if (isLoading) {
        return null
    }

    return (
        <Section>
            <PaymentsHeader
                readOnly={readOnly}
                createDefaultValues={createDefaultValues}
            />
            {total ? (
                <PaymentsSlider readOnly={readOnly} />
            ) : (
                <PaymentsSectionEmpty
                    createDefaultValues={createDefaultValues}
                    readOnly={readOnly}
                />
            )}
        </Section>
    )
}

export default PaymentsSection

const PaymentsSlider: FC<{ readOnly: boolean }> = ({ readOnly }) => {
    const { data } = useListContext<PaymentModel>()

    return (
        <SliderView
            items={data.map((payment, i) => (
                <PaymentCard
                    readOnly={readOnly}
                    payment={payment}
                    index={i + 1}
                />
            ))}
        />
    )
}
