export const basePermissions = {
    retrieve: 'retrieve',
    destroy: 'destroy',
    destroyBulk: 'destroyBulk',
    create: 'create',
    createBulk: 'createBulk',
    update: 'update',
    archive: 'archive',
    archiveBulk: 'archiveBulk',
    unarchive: 'unarchive',
    unarchiveBulk: 'unarchiveBulk',
    pdf: 'pdf',
}

// true or undefined => true, false => false
export const hasPermission = (hasPermission: boolean | undefined) => hasPermission !== false
