import { type FC } from 'react'

import { Anchor } from 'ui'

import { type PaymentModel } from '../types'

import PaymentDrawerToggler from './PaymentDrawerToggler'

interface Props {
    payment: PaymentModel
    index: number
    readOnly: boolean
}

const PaymentCardTitle: FC<Props> = ({ payment, index, readOnly }) => {
    return (
        <PaymentDrawerToggler
            id={payment.id}
            readOnly={readOnly}
        >
            {(open) => (
                <Anchor
                    onClick={open}
                    variant="subtitle2"
                >
                    Payment {index}
                </Anchor>
            )}
        </PaymentDrawerToggler>
    )
}

export default PaymentCardTitle
