import { styled } from 'lib'
import { Box, type BoxProps } from 'ui'

import { itemHoverClass } from './ItemHover'

interface Props extends BoxProps {
    active?: boolean
}

const Item = styled(Box, {
    shouldForwardProp: (prop: keyof Props) => prop !== 'active',
})<Props>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    ${({ active, theme }) =>
        active
            ? {
                  [`& .${itemHoverClass}`]: {
                      background: theme.palette.action.activeSelected,
                  },
              }
            : {
                  '&:hover': {
                      [`& .${itemHoverClass}`]: {
                          background: theme.palette.action.hovered,
                      },
                  },
              }}
`

export default Item
