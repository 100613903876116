import { stringify } from 'query-string'

import api from 'core/api'
import { useQuery } from 'lib'

export const useMonitor = <Response = unknown,>(
    resource: string,
    queryData?: any,
    enabled?: boolean,
) => {
    const query = useQuery<Response>(
        queryData ? [resource, queryData] : [resource],
        async () => {
            const data: Response = await api.get(
                resource +
                    (queryData
                        ? '?' +
                          stringify(queryData, {
                              skipNull: true,
                          })
                        : ''),
            )
            return data
        },
        { enabled },
    )
    return query.data
}
