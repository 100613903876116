import { useShowContext } from 'react-admin'

import images from 'assets/images'
import { NoResultsCard } from 'components'
import { type PurchaseOrderModel } from 'resources/purchaseOrders'
import { Typography } from 'ui'

import { LineItemsDrawerToggler } from '../../actions'

const NoLineItemsCard = () => {
    const { resource, record } = useShowContext<PurchaseOrderModel>()
    const disabled = record.status !== 'OPEN'
    return (
        <NoResultsCard
            direction="row"
            height="180px"
            title="No Items Added"
            imageSrc={images.noLineItems}
            imageProps={{
                width: '106px',
                height: '106px',
                mr: '28px',
                ml: '4px',
            }}
            subtitle={
                <LineItemsDrawerToggler
                    poRecord={record}
                    resource={`${resource}/${record.id}/items`}
                >
                    {({ onClick }) => (
                        <Typography
                            variant="body1"
                            sx={{
                                cursor: disabled ? 'default' : 'pointer',
                            }}
                            color={disabled ? 'text.disabled' : 'primary.main'}
                            onClick={disabled ? undefined : onClick}
                        >
                            Add Item
                        </Typography>
                    )}
                </LineItemsDrawerToggler>
            }
        />
    )
}
export default NoLineItemsCard
