import { type NoInfer } from 'appTypes'
import {
    type Column,
    type DataListDetails,
    type ListFilterChoice,
    ListFilterValueInput,
    type SortBy,
} from 'components'
import { Tooltip } from 'ui'

import { type UOM } from '../types'

const source = 'unitOfMeasure' as const
const label = 'UOM'

const value = (record: UOM) => {
    if (!record) {
        return null
    }

    return (
        <Tooltip title={record.name}>
            <span>{record.abbr}</span>
        </Tooltip>
    )
}

const filter = <Source extends string = typeof source>({ id }: { id?: Source } = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label,
        renderComponent: (params) => <ListFilterValueInput {...params} />,
    }) as const satisfies ListFilterChoice

const sort = <Source extends string = typeof source>({ id }: { id?: Source } = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label,
    }) as const satisfies SortBy

const tableColumn = <Source extends string = typeof source>({
    id,
    dataToRecord,
}: {
    id?: Source
    dataToRecord: (data) => UOM
}) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: label,
        renderCell: ({ row }) => value(dataToRecord(row)),
    }) as const satisfies Column

const dataCardRow = <Source extends string = typeof source>({
    id,
    dataToRecord,
}: {
    id?: Source
    dataToRecord: (data) => UOM
}) =>
    ({
        source: (id || source) as NoInfer<Source>,
        label,
        render: (_, data) => value(dataToRecord(data)),
    }) as const satisfies DataListDetails<any, any>

const self = {
    source,
    label,
    value,
    filter,
    sort,
    tableColumn,
    dataCardRow,
}

export default self
