import { type FC } from 'react'

import { TagsField } from 'resources/tags'

import { type CustomerModel } from '../types'

interface Props {
    record: Pick<CustomerModel, 'tagsData'>
}

const Value: FC<Props> = ({ record }) => {
    return <TagsField tags={record.tagsData} />
}

const source: keyof CustomerModel = 'tags'

const Tags = {
    source,
    label: 'Tags',
    Value,
}

export default Tags
