import { useContext, useRef, createContext, type ReactNode, type FC } from 'react'

// eslint-disable-next-line camelcase
import { type History } from 'history'
import { UNSAFE_NavigationContext as UNSAFENavigationContext } from 'react-router-dom'

import { makeid } from 'utils'

import {
    type BlockerContextValue,
    type BlockerNonBlockedLocationState,
    type BlockerConfig,
    type BlockersOptions,
} from './types'

export const BlockersContext = createContext<BlockerContextValue>(null as any)

export const useBlockerContext = () => {
    return useContext(BlockersContext)
}

interface Props {
    children: ReactNode
}

const optionsDefaultValue: BlockersOptions = {
    unloadListener: () => {
        /* */
    },
    unblock: () => {
        /* */
    },
}

export const BlockersProvider: FC<Props> = ({ children }) => {
    const blockers = useRef<{ blocker: BlockerConfig; key: string }[]>([])
    const navigator = useContext(UNSAFENavigationContext).navigator as History

    const options = useRef<BlockersOptions>(optionsDefaultValue)

    const add: BlockerContextValue = (p) => {
        if (p.reset) {
            options.current.unblock()

            options.current = optionsDefaultValue

            blockers.current = []
            return
        }
        const key = makeid()
        blockers.current.push({ blocker: p, key })
        if (blockers.current.length === 1) {
            options.current.unloadListener = () => {
                if (blockers.current.filter(({ blocker }) => blocker.preventNavigate).length) {
                    return
                }
                options.current.unblock()
            }

            window.addEventListener('beforeunload', options.current.unloadListener)

            const block = () => {
                return navigator.block(({ action, location, retry }) => {
                    if (
                        (location.state as BlockerNonBlockedLocationState)?.forceRedirect &&
                        action !== 'POP'
                    ) {
                        blockers.current.forEach(({ blocker }) => {
                            blocker.close?.()
                        })
                        options.current.unblock()
                        retry()
                        return
                    }
                    const lastBlocker = blockers.current[blockers.current.length - 1]!.blocker

                    if (
                        lastBlocker.allowSamePageNavigate &&
                        location.pathname === window.location.pathname
                    ) {
                        options.current.unblock()
                        retry()
                        setTimeout(() => {
                            options.current.unblock = block()
                        }, 100)
                        return
                    }
                    if (lastBlocker.preventNavigate) {
                        lastBlocker.preventNavigate(action, {
                            resume: () => {
                                options.current.unblock()
                                retry()
                            },
                        })
                        return
                    }
                    if (action === 'POP') {
                        lastBlocker.close?.()
                    }
                })
            }

            options.current.unblock = block()
        }

        return () => {
            blockers.current = blockers.current.filter((blocker) => blocker.key !== key)
            if (!blockers.current.length) {
                options.current.unblock()
                window.removeEventListener('beforeunload', options.current.unloadListener)
            }
        }
    }

    return <BlockersContext.Provider value={add}>{children}</BlockersContext.Provider>
}
