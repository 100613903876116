import { type DependencyList, type EffectCallback, useEffect, useRef } from 'react'

const useDidUpdate = (effect: EffectCallback, deps?: DependencyList) => {
    const didUpdate = useRef(false)

    useEffect(() => {
        if (didUpdate.current) {
            return effect()
        }
        didUpdate.current = true
    }, deps)
}

export default useDidUpdate
