import { type WorkOrderModel } from '../types'

export const unaccessibleUnitActionMessage = 'This action is not permitted due to Domicile settings'

export const getWoActionDisableMessage = (workOrder: WorkOrderModel, defaultMessage: string) => {
    if (!workOrder) {
        return defaultMessage
    }
    if (!workOrder.unitData.isAccessible) {
        return unaccessibleUnitActionMessage
    }
    return defaultMessage
}
