import Icons from 'assets/icons'
import { HideOnBreakpoint, Typography, BoxContainer, SvgIcon, IconButton, Button } from 'ui'

import { useListSortDrawerContext } from './ListSortDrawerContext'

const SortButton = () => {
    const { open, isDefaultSorted, reset } = useListSortDrawerContext()

    const Icon = isDefaultSorted ? Icons.Sort : Icons.SortActive

    return (
        <BoxContainer>
            <Typography
                component="div"
                color="text"
            >
                <HideOnBreakpoint
                    replaceWith={
                        <IconButton
                            aria-label="Open Sort"
                            size="small"
                            color="inherit"
                            onClick={open}
                        >
                            <SvgIcon
                                sx={{
                                    width: 20,
                                    height: 20,
                                }}
                                inheritViewBox
                                component={Icon}
                            />
                        </IconButton>
                    }
                >
                    <Button
                        variant="text"
                        color="inherit"
                        onClick={open}
                        startIcon={
                            <SvgIcon
                                inheritViewBox
                                component={Icon}
                            />
                        }
                    >
                        Sort By
                    </Button>
                    {!isDefaultSorted && (
                        <IconButton
                            aria-label="Clear Sort"
                            size="small"
                            onClick={reset}
                        >
                            <Icons.Close />
                        </IconButton>
                    )}
                </HideOnBreakpoint>
            </Typography>
        </BoxContainer>
    )
}

export default SortButton
