import { type FC } from 'react'

import { phoneMaskResolver } from 'components'

import { type CustomerModel } from '../types'

interface Props {
    record: Pick<CustomerModel, 'phone'>
}

const Value: FC<Props> = ({ record }) => {
    return <>{phoneMaskResolver(record.phone)}</>
}

const source: keyof CustomerModel = 'phone'

const Phone = {
    source,
    label: 'Company Phone',
    Value,
}

export default Phone
