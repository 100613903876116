import laborRateName from './laborRateName'
import { rate } from './rate'
import self from './self'

const ptLaborFields = {
    laborRateName,
    rate,
    self,
}

export default ptLaborFields
