import { type FC, type ReactElement } from 'react'

import { FixedOnMobile } from 'ui'

interface Props {
    children: ReactElement
}

const HeaderMainAction: FC<Props> = ({ children }) => {
    return <FixedOnMobile>{children}</FixedOnMobile>
}

export default HeaderMainAction
