import avatar from './avatar'
import { cost } from './cost'
import { description } from './description'
import { manufacturerPartNumber } from './manufacturerPartNumber'
import { number } from './number'
import { photos } from './photos'
import { positionApplicable } from './positionApplicable'
import { serializedPart } from './serializedPart'
import { upc } from './universalProductCode'

const partFields = {
    avatar,
    description,
    manufacturerPartNumber,
    upc,
    positionApplicable,
    serializedPart,
    cost,
    number,
    photos,
}

export default partFields
