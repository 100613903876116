import { type ReactNode } from 'react'

import { ListFtuCardContainer, NoResultsCard } from 'components'
import { Typography } from 'ui'

interface DVIRFTUPageBaseProps {
    title: string
    subtitle: ReactNode
    image: string
    action?: ReactNode
}

const DVIRPageBase = ({ image, title, subtitle, action }: DVIRFTUPageBaseProps) => {
    return (
        <ListFtuCardContainer
            container
            sx={{ flex: 1 }}
        >
            <NoResultsCard
                transparent
                height="auto"
                title={title}
                subtitle={<Typography color="inherit">{subtitle}</Typography>}
                imageSrc={image}
                imageProps={{
                    mb: {
                        sm: '32px',
                        xs: '16px',
                    },
                    width: '180px',
                }}
                action={action}
            />
        </ListFtuCardContainer>
    )
}

export default DVIRPageBase
