import { type FC, type PropsWithChildren } from 'react'

import { Card } from 'ui'

const WidgetsContainer: FC<PropsWithChildren> = ({ children }) => {
    return (
        <Card
            sx={{
                position: 'relative',
                boxSizing: 'border-box',
                height: '265px',
                p: '20px',
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
            }}
        >
            {children}
        </Card>
    )
}

export default WidgetsContainer
