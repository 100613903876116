import { withErrorBoundary, styled, cssVariables } from 'lib'

import Box from './Box'

export const PageContentSection = styled(Box)`
    box-sizing: border-box;
    ${cssVariables.pageContentPadding}: 20px;
    ${({ theme }) => `
        ${theme.breakpoints.up('sm')} {
            ${cssVariables.pageContentPadding}: 28px;
        }
    `}
`

const PageContent = styled(PageContentSection)`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: var(${cssVariables.pageContentPadding});
`

export default withErrorBoundary(PageContent, {})
