import { reportsUrls, createReportResource } from 'resources/reports'
import { type UnitDowntime } from 'resources/units'

import WidgetBase, { WidgetSkeleton } from '../../WidgetBase/WidgetBase'
import { useCreateDashboardWidgetLink, useDashboardWidget } from '../../WidgetsForm'

import { DowntimeChart, Clarification, Distribution, Label } from './components'

const description = `Maintenance Downtime refers to the time,
 in absolute and relative terms, in which a unit is not available due to repair services.`
let validData = false
const DowntimeWidget = () => {
    const createLink = useCreateDashboardWidgetLink()
    const data = useDashboardWidget<UnitDowntime>({
        resource: createReportResource('downtime'),
        sideEffect: (data) => {
            validData = data.percentDowntimeOfPeriod > 0
            return data
        },
    })
    if (!data) {
        return <WidgetSkeleton />
    }
    const { totalDowntimeSeconds, percentDowntimeOfPeriod, downtimePerPriorityClass } = data
    return (
        <WidgetBase
            headerProps={{
                title: 'Downtime by Repair Class',
                label: (
                    <Label
                        label={percentDowntimeOfPeriod}
                        validData={validData}
                    />
                ),
                tooltipText: description,
                clarification: (
                    <Clarification
                        totalDowntimeSeconds={totalDowntimeSeconds}
                        validData={validData}
                    />
                ),
                chart: (
                    <DowntimeChart
                        {...downtimePerPriorityClass}
                        disabled={!validData}
                    />
                ),
            }}
            infoProps={{
                content: (
                    <Distribution
                        data={downtimePerPriorityClass}
                        description={description}
                        validData={validData}
                    />
                ),
            }}
            separator="Distribution"
            link={createLink(reportsUrls.downtime)}
        />
    )
}
export default DowntimeWidget
