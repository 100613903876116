import { formatDate } from 'lib'

import { type ExpirationModel } from '../types'

const source: keyof ExpirationModel = 'expirationDate'

export const date = {
    source,
    label: 'Expiration Date',
    value: (value: string) => formatDate(value, (format) => format.shortenedDate),
}
