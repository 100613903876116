import { type FC } from 'react'

import { Link } from 'react-router-dom'

import Icons from 'assets/icons'
import { urls } from 'configs'
import { withColor } from 'lib'
import { Stack, Status, Typography } from 'ui'

import { useSidebar, useSidebarFunctions } from '../SidebarContext'
import { DrawerNav, DrawerItem } from '../common'
import { type NavItemConfig } from '../types'

import MobileSidebarContainer from './MobileSidebarContainer'

const MobileSidebar: FC = () => {
    const [snap] = useSidebar()

    let content = null

    if (snap.content === true) {
        content = <Content />
    } else if (typeof snap.content === 'function') {
        content = snap.content()
    }

    return <MobileSidebarContainer>{content}</MobileSidebarContainer>
}

export default MobileSidebar

const Content = () => {
    const [snap] = useSidebar()

    return (
        <Stack
            justifyContent="space-between"
            height="100%"
        >
            <Stack>
                {snap.menu.map((item) => (
                    <Element
                        key={item.key}
                        item={item}
                    />
                ))}
            </Stack>
            <Link to={urls.settings}>
                <DrawerItem startIcon={Icons.SettingsOutlined}>Account Settings</DrawerItem>
            </Link>
        </Stack>
    )
}

const Element: FC<{ item: NavItemConfig }> = ({ item }) => {
    const [snap, functions] = useSidebar()
    const { open } = functions
    const active = snap.activeElements[item.key]
    const clickHandler = item.items ? () => open(() => <NestedMenu item={item} />) : undefined

    const content = (
        <DrawerItem
            onClick={clickHandler}
            startIcon={active && item.ActiveIcon ? item.ActiveIcon : item.Icon}
            endIcon={Boolean(item.items)}
            selected={snap.activeElements[item.key]}
        >
            {item.text}
            {item.status?.((color) => (
                <Status
                    color={color}
                    sx={{ ml: '10px' }}
                />
            ))}
        </DrawerItem>
    )

    if (item.path) {
        return <Link to={item.path}>{content}</Link>
    }

    return content
}

const NestedMenu: FC<{ item: NavItemConfig }> = ({ item }) => {
    const { open } = useSidebarFunctions()

    return (
        <>
            <DrawerItem
                onClick={() => open()}
                startIcon={Icons.ArrowBackOutlined}
            >
                Main Menu
            </DrawerItem>
            <Stack
                gap="2px"
                pl="24px"
            >
                <Typography
                    variant="subtitle2"
                    color={withColor('text.primary')}
                    p="17px 16px"
                >
                    {item.text}
                </Typography>
                <Stack>
                    <DrawerNav items={item.items} />
                </Stack>
            </Stack>
        </>
    )
}
