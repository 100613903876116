import { type FC } from 'react'

import { ViewHeader } from 'components'
import { Section } from 'ui'

import { type CustomerModel } from '../types'

import { CustomerCard } from './CustomerCard'

interface Props {
    customer: CustomerModel
}

const CustomerSection: FC<Props> = ({ customer }) => {
    return (
        <Section>
            <ViewHeader title="Customer" />
            <CustomerCard customer={customer} />
        </Section>
    )
}

export default CustomerSection
