import { ResourceWithClearEffect } from 'core'
import { useDataProviderConfig } from 'core/data'
import { unitAspects, unitResource } from 'resources/units'

import { UnitsList } from './List'
import UnitShow from './Show'

const dpConfig = {
    queryParams: () => ({
        withAspects: [unitAspects.costPerMeter, unitAspects.downtime, unitAspects.totals],
    }),
}

const Units = () => {
    useDataProviderConfig(unitResource.resource, {
        getOne: dpConfig,
        update: dpConfig,
    })

    return (
        <ResourceWithClearEffect
            name={unitResource}
            list={UnitsList}
            edit={UnitShow}
        />
    )
}

export default Units
