import { useEffect } from 'react'

import { inject } from 'mobx-react'

import { type AuthStore } from 'core/auth'
import { useFlags } from 'lib'

const MobxSyncFlags = inject('auth')(({ auth }: { auth?: AuthStore }) => {
    const flags = useFlags()

    useEffect(() => {
        auth.updateFlags(flags)
    }, [flags])

    return null
})

export default MobxSyncFlags
