import { type NoInfer } from 'appTypes'
import { type Column } from 'components'
import { formatDate } from 'lib'

import { type UnitModel } from '../types'

const source = 'archived' satisfies keyof UnitModel

const label = 'Archived On'

const tableColumn = <Source extends string = typeof source>({
    id,
    dataToValue,
}: {
    id?: Source
    dataToValue: (data) => UnitModel[typeof source]
}) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: label,
        renderCell: ({ row }) =>
            formatDate(dataToValue(row), (dateFormats) => dateFormats.shortenedDateTime),
    }) as const satisfies Column

const sort = <Source extends string = typeof source>() =>
    ({
        id: source as NoInfer<Source>,
        label,
    }) as const

export const archived = {
    source,
    label,
    tableColumn,
    sort,
}
