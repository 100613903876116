import { useWidgetContext } from 'pages/Dashboard/components/GridLayout'
import { checkWidgetDimensions } from 'pages/Dashboard/components/WidgetBase/useCollapseWidget'
import { Stack, BoxContainer, Typography } from 'ui'

import { Arrow } from '../../components'
import { type ratioDataType } from '../types'

interface ClarificationProps {
    changeForPeriod: ratioDataType['changeInPercentsForPeriod']
    isPositiveColor?: boolean
}

const Clarification = ({ changeForPeriod, isPositiveColor }: ClarificationProps) => {
    const positiveChangeForPeriod = changeForPeriod < 0
    const { dimensions } = useWidgetContext()

    return (
        <Stack
            alignItems="flex-start"
            marginTop="7px"
        >
            <BoxContainer marginBottom="7px">
                {changeForPeriod ? (
                    <>
                        <BoxContainer marginRight="8px">
                            <Arrow
                                direction={positiveChangeForPeriod ? 'down' : 'up'}
                                sx={(theme) => ({
                                    width: '16px',
                                    height: '16px',
                                    color: theme.palette.charts[
                                        isPositiveColor ?? positiveChangeForPeriod
                                            ? 'greenBody'
                                            : 'red'
                                    ],
                                })}
                            />
                        </BoxContainer>
                        <Typography
                            color="text.secondary"
                            variant="chartListItem"
                        >
                            {Math.abs(changeForPeriod)}%{' '}
                            {positiveChangeForPeriod ? 'decrease' : 'increase'}
                        </Typography>
                    </>
                ) : (
                    '-'
                )}
            </BoxContainer>
            <BoxContainer gap="6px">
                <Typography
                    color="text.disabled"
                    variant="chartLabel"
                    fontSize={checkWidgetDimensions(dimensions, ['sm', 'mdY']) ? '10px' : '12px'}
                >
                    for the selected period
                </Typography>
            </BoxContainer>
        </Stack>
    )
}
export default Clarification
