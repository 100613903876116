import { type NoInfer } from 'appTypes'
import { type Column, type ListFilterChoice, ListFilterDateRangeValueInput } from 'components'
import { formatDate } from 'lib'

import { type InvoiceModel } from '../types'

const source = 'workOrderCompleted' as const
const label = 'WO Completed on'

interface Data extends Pick<InvoiceModel, 'workOrderData'> {}

const value = (record: Data) =>
    record.workOrderData
        ? formatDate(record.workOrderData.completed, (format) => format.shortenedDateTime)
        : null

const filter = () =>
    ({
        id: source,
        label,
        filterType: 'range',
        renderComponent: (props) => <ListFilterDateRangeValueInput {...props} />,
    }) as const satisfies ListFilterChoice

const tableColumn = <Source extends string = typeof source>() =>
    ({
        field: source as NoInfer<Source>,
        headerName: label,
        renderCell: (cell) => value(cell.row),
    }) as const satisfies Column

const sort = <Source extends string = typeof source>() =>
    ({
        id: source as NoInfer<Source>,
        label,
    }) as const

const woCompletedDate = {
    source,
    label,
    value,
    filter,
    sort,
    tableColumn,
}

export default woCompletedDate
