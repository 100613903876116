import { type Optional } from 'appTypes'
import Icons from 'assets/icons'

import IconButton from './IconButton'
import InputAdornment, { type InputAdornmentProps } from './InputAdornment'

const ClearAdornment = (props: Optional<InputAdornmentProps, 'position'>) => {
    return (
        <InputAdornment
            position="end"
            {...props}
        >
            <IconButton
                size="small"
                title="Clear value"
            >
                <Icons.Clear />
            </IconButton>
        </InputAdornment>
    )
}

export default ClearAdornment
