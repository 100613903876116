import { type NoInfer } from 'appTypes'
import { type Column, type SortBy, type ListFilterChoice } from 'components'

import { TagsField } from '../components/TagsField'
import { type TagModel } from '../types'

const source = 'tags'
const label = 'Tags'

const sort = () => ({ id: source, label }) as const satisfies SortBy

const column = <Source extends string = typeof source>({
    dataToRecord,
    id,
}: {
    id?: Source
    dataToRecord: (data) => TagModel[]
}) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: label,
        renderCell: ({ row }) => <TagsField tags={dataToRecord(row)} />,
    }) as const satisfies Column

const filter = <Source extends string = typeof source>({ id }: { id?: Source } = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label,
    }) as const satisfies ListFilterChoice

const self = {
    filter,
    sort,
    column,
}

export default self
