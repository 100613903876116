import { useListContext } from 'react-admin'

import { useDidUpdate } from 'hooks'

import { useListView, ListViewMode } from './ListViewContext'

const ListPurify = ({ perPage }: { perPage?: number }) => {
    const { viewMode } = useListView()
    const { setPerPage, setPage } = useListContext()

    useDidUpdate(() => {
        if (viewMode === ListViewMode.grid) {
            setPage(1)
            setPerPage(100)
        }
        if (viewMode === ListViewMode.list) {
            setPerPage(perPage ? perPage : 10)
        }
    }, [viewMode])

    return null
}
export default ListPurify
