import { formatMoney } from 'components'

import { type PtFeeModel } from '../types'

const source: keyof PtFeeModel = 'price'

const label = 'Flat Fee'

const value = (record: Pick<PtFeeModel, 'price'>) => {
    return <>{formatMoney(record.price)}</>
}
export const flatFee = {
    source,
    label,
    value,
}
