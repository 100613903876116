import { type Column, type DataListDetails, type SortBy } from 'components'

import { type InventoryPricingModel } from '../types'

const source = 'partTier' as const satisfies keyof InventoryPricingModel

const label = 'Customer Price Tier'

const value = (record: InventoryPricingModel) =>
    record.partTierData.isDefault
        ? `${record.partTierData.name} (Default)`
        : record.partTierData.name

const tableColumn = () =>
    ({
        field: source,
        headerName: label,
        renderCell: ({ row }) => value(row),
    }) as const satisfies Column

const dataCardRow = () =>
    ({
        source,
        label,
        render: (_, data) => value(data),
    }) as const satisfies DataListDetails<any, any>

const sort = () =>
    ({
        id: source,
        label,
    }) as const satisfies SortBy

const partTier = {
    source,
    label,
    value,
    dataCardRow,
    tableColumn,
    sort,
}

export default partTier
