import { useState } from 'react'

import { type GridColDef } from '@mui/x-data-grid'

import { useResourcePreferences } from 'core/context'

const useSizes = (disableColumnResize: boolean) => {
    const preferences = useResourcePreferences()
    const [sizes, setSizes] = useState(preferences.value.columnSizes || {})

    const setSize = (colDef: GridColDef) => {
        const field = colDef.field
        const width = colDef.width

        const newSizes = preferences.value.columnSizes
            ? { ...preferences.value.columnSizes, [field]: width }
            : { [field]: width }

        setSizes(newSizes)
        preferences.updateLocal('columnSizes', newSizes)
        preferences.syncLocal()
    }

    return { sizes: disableColumnResize ? {} : sizes, setSize }
}

export default useSizes
