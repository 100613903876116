import { type ComponentType, type FC } from 'react'

import { type FlagName, type Flags } from './types'
import useFlags from './useFlags'

/**
 ** hides component body if selected flag is false
 */
export const renderOnFlag = <P = any,>(
    isFlag: FlagName | ((flags: Flags) => boolean),
    Component: ComponentType<P>,
): FC<P> => {
    return function (props: P) {
        const flags = useFlags()

        if (typeof isFlag === 'function') {
            if (!isFlag(flags)) {
                return null
            }
        } else if (!flags[isFlag]) {
            return null
        }

        return <Component {...props} />
    }
}
