import { forwardRef } from 'react'

import { type AnyMaskedOptions } from 'imask'
import { IMaskInput as IMaskInputBase } from 'react-imask'

export type IMaskInputProps = AnyMaskedOptions & {
    name: string
    onChange: (params: { target: { name: string; value: string } }) => void
    onBlur?: (e) => void
    value: string | number
    changeValueOnBlur?: (value: string) => string | undefined
    calculatedProps?: (params: { value: string }) => Partial<AnyMaskedOptions>
}

const IMaskInput = forwardRef<any, any & IMaskInputProps>((props: IMaskInputProps, ref) => {
    const { onChange, value, changeValueOnBlur, calculatedProps, ...rest } = props

    const stringValue = String(value)

    return (
        <IMaskInputBase
            unmask
            // @ts-ignore
            inputRef={ref}
            value={stringValue}
            mask={String}
            onAccept={(value: string, maskParams, event) => {
                if (event) {
                    // XXX this may break something that depends of change Event
                    onChange({ target: { name: props.name, value } })
                }
            }}
            {...rest}
            {...calculatedProps?.({ value: stringValue })}
            onBlur={(e) => {
                props.onBlur?.(e)
                const valueOnBlur = changeValueOnBlur?.(stringValue)
                if (typeof valueOnBlur !== 'undefined') {
                    onChange({ target: { name: props.name, value: '' } })
                }
            }}
        />
    )
})

export default IMaskInput
