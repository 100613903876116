import { forwardRef } from 'react'

import { styled, globalClassNames, withColor } from 'lib'
import {
    Switch,
    switchClasses,
    FormControlLabel,
    Box,
    Divider,
    Paper,
    Stack,
    Typography,
    Button,
} from 'ui'

import { useColumnsContext } from './controls'
import { type Column } from './types'
import { checkboxField } from './utils'

const PanelWrapper = styled(Paper)`
    display: flex;
    max-height: 450px;
    max-width: 300px;
`
const PanelContent = styled(Stack)`
    overflow: auto;
    flex: 1 1 0%;
    max-height: 400px;
`
const GridColumnsPanelRoot = styled('div', {
    name: 'MuiDataGrid',
    slot: 'ColumnsPanel',
    overridesResolver: (props, styles) => styles.columnsPanel,
})(() => ({
    padding: '8px 16px',
}))
const GridColumnsPanelRowRoot = styled('div', {
    name: 'MuiDataGrid',
    slot: 'ColumnsPanelRow',
    overridesResolver: (props, styles) => styles.columnsPanelRow,
})(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1px 8px 1px 7px',
    [`& .${switchClasses.root}`]: {
        marginRight: theme.spacing(0.5),
    },
}))

const GridColumnsPanel = forwardRef<HTMLDivElement, {}>((props, ref) => {
    const { columns, setColumnVisibility, setVisibilityModel, visibilityModel } =
        useColumnsContext()

    const toggleColumn = (event) => {
        const name = event.target.name
        const checked = event.target.checked

        setColumnVisibility(name, checked)
    }

    const toggleAllColumns = (isVisible: boolean) => {
        setVisibilityModel(
            columns.reduce((acc, col) => {
                if (col.hideable !== false) {
                    acc[col.field as string] = isVisible
                }
                return acc
            }, {}),
        )
    }

    return (
        <PanelWrapper
            // passed from click away listener
            {...props}
            ref={ref}
        >
            <Stack
                flex="1 1 0%"
                overflow="hidden"
            >
                <Box
                    sx={{
                        p: '14px 12px 0',
                    }}
                >
                    <Typography
                        variant="subtitle1"
                        color={withColor('text.secondary')}
                        mb="12px"
                    >
                        Current Arrangement
                    </Typography>
                    <Divider />
                </Box>
                <PanelContent>
                    <GridColumnsPanelRoot>
                        {columns.map((column: Column) =>
                            column.hideable && column.field !== checkboxField ? (
                                <GridColumnsPanelRowRoot key={column.field as string}>
                                    <FormControlLabel
                                        sx={{
                                            overflow: 'hidden',
                                            marginRight: 0,
                                        }}
                                        componentsProps={{
                                            typography: {
                                                overflow: 'hidden',
                                            },
                                        }}
                                        label={
                                            <div className={globalClassNames.ellipsis}>
                                                {column.headerName || (column.field as string)}
                                            </div>
                                        }
                                        control={
                                            <Switch
                                                checked={
                                                    visibilityModel[column.field as string] !==
                                                    false
                                                }
                                                onChange={toggleColumn}
                                                name={column.field as string}
                                                size="small"
                                            />
                                        }
                                    />
                                </GridColumnsPanelRowRoot>
                            ) : null,
                        )}
                    </GridColumnsPanelRoot>
                </PanelContent>
                <Box
                    sx={{
                        p: '6px 16px 18px',
                        justifyContent: 'flex-start',
                    }}
                >
                    <Button
                        size="small"
                        onClick={() => toggleAllColumns(true)}
                    >
                        Show all
                    </Button>

                    <Button
                        sx={{ ml: '8px' }}
                        size="small"
                        onClick={() => toggleAllColumns(false)}
                    >
                        Hide all
                    </Button>
                </Box>
            </Stack>
        </PanelWrapper>
    )
})

export default GridColumnsPanel
