import { ListTotalBadge, ViewHeader } from 'components'

const ListHeader = () => {
    return (
        <ViewHeader title="Pricing">
            <ViewHeader.Content>
                <ListTotalBadge />
            </ViewHeader.Content>
        </ViewHeader>
    )
}

export default ListHeader
