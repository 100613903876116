import { type ReactNode, type FC, type ReactElement } from 'react'

import { type LinkTabsProps } from 'components'

import Header, { type HeaderActionsProps } from '../header'

interface Props extends LinkTabsProps {
    children: ReactNode
    mainAction?: ReactElement
    actions?: HeaderActionsProps<any>['actions']
    backTo?: string | boolean
    isStatic?: boolean
    rightAside?: ReactNode
}

const InnerHeader: FC<Props> = ({
    children,
    actions,
    mainAction,
    tabs,
    tabWidth,
    tabMinWidth,
    backTo,
    isStatic = false,
    rightAside,
}) => {
    return (
        <Header fixed={!isStatic}>
            <Header.Block>
                {backTo !== false && (
                    <Header.BackButton to={typeof backTo === 'string' ? backTo : undefined} />
                )}
                <Header.Main>{children}</Header.Main>
                <Header.Aside>
                    {actions && <Header.Actions actions={actions} />}
                    {mainAction && <Header.MainAction>{mainAction}</Header.MainAction>}
                    {rightAside}
                </Header.Aside>
            </Header.Block>
            <Header.Divider />
            <Header.Tabs
                tabs={tabs}
                tabWidth={tabWidth}
                tabMinWidth={tabMinWidth}
            />
        </Header>
    )
}

export default InnerHeader
