import images from 'assets/images'
import { landingPageUrl, supportEmail } from 'configs'
import { styled } from 'lib'
import { Box, Anchor, Typography, Button, CenterText } from 'ui'

const Main = styled('div')`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 335px;
`

const Logout = () => {
    return (
        <Main>
            <Box
                alignItems="center"
                display="flex"
                justifyContent="center"
            >
                <img
                    src={images.deactivate}
                    alt="deactivated"
                />
            </Box>
            <CenterText>
                <Typography
                    variant="h5"
                    mt="32px"
                >
                    Your company account has <br />
                    been deactivated!
                </Typography>
                <Typography
                    variant="body1"
                    mt="16px"
                >
                    To reactivate your company account, <br /> please contact us at
                    <Anchor href={`mailto:${supportEmail}`}> {supportEmail}</Anchor>.
                </Typography>
            </CenterText>
            <CenterText>
                <Button
                    sx={{ mt: '24px' }}
                    variant="contained"
                    href={landingPageUrl.root}
                >
                    GO TO SITE
                </Button>
            </CenterText>
        </Main>
    )
}

export default Logout
