import { useState, useEffect, type FC } from 'react'

import { observer } from 'mobx-react'

import Icons from 'assets/icons'
import { useUserTaskSync } from 'core'
import { useQueryClient } from 'lib'
import unitResource from 'resources/units/unitResource'
import { Alert, Button, alertClasses, LoadingSpinner, Typography } from 'ui'

const barMap = {
    info: {
        icon: <LoadingSpinner />,
        text: 'Integrations data syncing in the background',
    },
    success: {
        icon: <Icons.CheckCircleOutline sx={{ color: (theme) => theme.palette.success.main }} />,
        text: 'Integrations data has synchronized successfully',
    },
}

export const TelematicsSyncBar: FC = observer(() => {
    const shouldSync = useUserTaskSync()
    const [prevSync, setPrevSync] = useState(shouldSync)
    const queryClient = useQueryClient()
    useEffect(() => {
        if (shouldSync) {
            setPrevSync(shouldSync)
        } else {
            const timer = setTimeout(() => {
                setPrevSync(false)
            }, 5000)
            queryClient.removeQueries([unitResource.resource])

            return () => {
                clearTimeout(timer)
            }
        }
    }, [shouldSync])

    const sync = (shouldSync && 'info') || (prevSync && 'success')

    if (!sync) {
        return null
    }

    return (
        <Alert
            sx={{
                height: '60px',
                alignItems: 'center',
                padding: '6px 22px',
                [`& .${alertClasses.action}`]: {
                    marginRight: 0,
                },
            }}
            severity={sync}
            icon={barMap[sync].icon}
            action={
                sync === 'success' && (
                    <Button
                        size="small"
                        color="inherit"
                        sx={{ minWidth: 'fit-content' }}
                        onClick={() => setPrevSync(false)}
                    >
                        Close
                    </Button>
                )
            }
        >
            <Typography
                color="inherit"
                variant="body2"
            >
                {barMap[sync].text}
            </Typography>
        </Alert>
    )
})
