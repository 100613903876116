import { inject, observer } from 'mobx-react'

import { UploadImage, SaveFixedOnMobile, Form, TextInput, EmailInput, TelInput } from 'components'
import { serialize, type Serializer, maxLengthValidation, useSubmit } from 'core'
import { type AuthStore } from 'core/auth'
import { UserIcon } from 'resources/user'
import { type UserModel } from 'resourcesBase'
import { GridItemLayout, SectionTitle, GridBreak, GridContainerColumns, PageContent } from 'ui'

const userProfileSerializer: Serializer<UserModel> = [
    'name',
    { name: 'avatar', parse: 'file' },
    'phone',
]

const PersonalInfo = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => {
        const user = auth.user

        const submitHandler = useSubmit<UserModel>(
            async (formData) => {
                await auth.updateUser({
                    data: serialize(formData, userProfileSerializer),
                })
            },
            {
                successMessage: ({ defaultMessages }) => defaultMessages.updated,
            },
        )

        return (
            <PageContent>
                <SectionTitle>Basic Information</SectionTitle>
                <Form
                    onSubmit={submitHandler}
                    formRootPathname="personal-info"
                    record={user}
                >
                    <GridContainerColumns>
                        <GridItemLayout>
                            <EmailInput disabled />

                            <UploadImage
                                source="avatar"
                                defaultIcon={<UserIcon />}
                            />
                        </GridItemLayout>
                        <GridBreak />
                        <GridItemLayout>
                            <TextInput
                                source="name"
                                label="Full Name"
                                validate={maxLengthValidation}
                            />
                        </GridItemLayout>
                        <GridItemLayout>
                            <TelInput label="Phone Number" />
                        </GridItemLayout>
                    </GridContainerColumns>
                    <SaveFixedOnMobile />
                </Form>
            </PageContent>
        )
    }),
)

export default PersonalInfo
