import { type DeleteOneParams } from 'core'

export const deleteUnitAction = (hasWO: boolean): DeleteOneParams => {
    if (hasWO) {
        return {
            confirmConfig: {
                title: `This Unit cannot be deleted, because a related Work Order exists.`,
                confirmButtonProps: null,
                cancelButtonProps: { children: 'Close' },
            },
        }
    }
    return {
        confirmConfig: {
            title: `Are you sure you want to delete the selected Unit(s)?`,
        },
    }
}
