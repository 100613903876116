import Icons from 'assets/icons'
import { type Action, deleteOneAction } from 'core'

import { ContactDrawerToggler } from './components/ContactDrawerToggler'
import { type ContactModel } from './types'

export const contactActions: Action<ContactModel> = (record, { children }) => [
    <ContactDrawerToggler
        key="edit"
        id={record.id}
        children={({ onClick }) => children({ title: 'View/Edit', Icon: Icons.Edit, onClick })}
    />,
    deleteOneAction({
        children,
        id: record.id,
        confirmConfig: {
            title: 'Are you sure you want to delete this Contact Person?',
        },
    }),
]
