import { type ReactElement, useEffect, useMemo, useState } from 'react'

import {
    ListContextProvider,
    useReferenceInputController,
    ChoicesContextProvider,
    useInput,
    TextInput,
    required,
    type UseReferenceInputControllerParams,
    type ChoicesContextValue,
    type UseInputValue,
    ResettableTextFieldClasses,
} from 'react-admin'

import { type DataRecord, type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import { drawerWidth } from 'components/utils'
import { FormActionProvider } from 'context'
import { useCreateInputId, extendRecord, useSetBlocker, type ApiClientConfig } from 'core'
import { alpha, useMediaQuery } from 'lib'
import {
    drawerClasses,
    SwipeableDrawer,
    type DrawerProps,
    BoxContainer,
    Box,
    type TextFieldProps,
} from 'ui'
import { isEqual } from 'utils/helpers'

import DialogSelectorAppBar, { type DialogSelectorAppBarProps } from './DialogSelectorAppBar'
import { DialogSelectorProvider } from './DialogSelectorContext'
import DialogSelectorFilter, { type DialogSelectorFilterProps } from './DialogSelectorFilter'
import DialogSelectorList, { type DialogSelectorListProps } from './DialogSelectorList'
import DialogSelectorLoadMore from './DialogSelectorLoadMore'
import DialogSelectorResultCount from './DialogSelectorResultCount'

import type { ObjectAny, ExtendRecordType } from 'appTypes'

export interface DialogSelectorUniversalParam<OptionType extends DataRecord = any> {
    value: string | number
    selected: OptionType
    control: ChoicesContextValue<OptionType>
    input: UseInputValue
    onSelect: (id: Identifier) => void
    defaultFilter: any
    handleOpen: (event?: React.MouseEvent<any, MouseEvent>) => void
    queryMeta?: any
}
export interface DialogSelectorProps<OptionType extends DataRecord = any>
    extends Omit<DialogSelectorListProps<OptionType>, 'selectItem'> {
    source: string
    required?: boolean
    titleSource?: ExtendRecordType<OptionType, string | number>
    reference: string
    searchPlaceholder?: string
    defaultSelectorProps?: TextFieldProps
    defaultSelectorValueSource?: ExtendRecordType<
        DialogSelectorUniversalParam<OptionType>,
        string | number
    >
    defaultSelectorHelperText?: (params: DialogSelectorUniversalParam<OptionType>) => string | null
    filter?: DialogSelectorFilterProps
    referenceFilter?: ObjectAny
    onSelectedChange?: (params: DialogSelectorUniversalParam<OptionType>) => void
    defaultFilter?: any
    renderAboveList?: (params: DialogSelectorUniversalParam<OptionType>) => ReactElement
    renderTop?: (params: DialogSelectorUniversalParam<OptionType>) => ReactElement
    renderNextToResultCount?: (data: DialogSelectorUniversalParam<OptionType>) => ReactElement
    disabled?: boolean
    appBar?: Pick<DialogSelectorAppBarProps, 'leftButton' | 'paddingX'>
    renderToggler?: (params: DialogSelectorUniversalParam<OptionType>) => ReactElement
    onChange?: (control: ChoicesContextValue<OptionType>, id?: Identifier) => void
    queryOptions?: (
        open: boolean,
    ) => Omit<UseReferenceInputControllerParams<OptionType>['queryOptions'], 'enabled'>
    refetchOnOpen?: boolean
    perPage?: number
    resettable?: boolean
    contextType?: string
    contextId?: Identifier
}

const requiredField = required('Required field')

const DialogSelector = <OptionType extends DataRecord = any>({
    defaultFilter,
    itemPrimary,
    itemSecondary,
    renderListItem,
    source,
    defaultSelectorProps,
    titleSource,
    reference,
    required,
    referenceFilter,
    defaultSelectorValueSource,
    defaultSelectorHelperText,
    filter,
    renderNoResults,
    onSelectedChange,
    renderAboveList,
    renderTop,
    disabled,
    renderNextToResultCount,
    appBar,
    noResults,
    renderToggler,
    onChange,
    queryOptions,
    refetchOnOpen,
    perPage,
    resettable,
    renderItem,
    contextType,
    contextId,
}: DialogSelectorProps<OptionType>) => {
    const [open, setOpen] = useState(false)

    const handleOpen = (e: React.MouseEvent<any, MouseEvent>) => {
        const target = e?.target as HTMLElement
        // Don't open if RA clear button clicked
        if (disabled || isElementRaClearButton(target)) {
            return null
        }
        if (refetchOnOpen) {
            controllerProps.refetch()
        }
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    useSetBlocker(
        {
            close: handleClose,
        },
        {
            isOpen: open,
        },
    )

    const isExtraSmall = useMediaQuery((theme) =>
        theme.breakpoints.down(theme.props.mobileViewBreakpoint),
    )
    const anchor: DrawerProps['anchor'] = isExtraSmall ? 'bottom' : 'right'

    const _queryOptions = queryOptions?.(open)

    const queryMeta = {
        ..._queryOptions?.meta,
        referenceInput: true,
    }
    const filters = useMemo(
        () => ({ ...defaultFilter, ...referenceFilter, contextType, contextId }),
        [referenceFilter, defaultFilter, contextType, contextId],
    )
    const controllerProps = useReferenceInputController<OptionType>({
        filter: { ...referenceFilter, contextType, contextId },
        reference,
        source,
        perPage: perPage ?? 300,
        queryOptions: {
            ..._queryOptions,
            meta: queryMeta,
        },
        ...(disabled && { enableGetChoices: () => false }),
    })

    const createId = useCreateInputId()

    const input = useInput({
        source,
        id: createId(source),
    })

    const { field } = input

    const selected = controllerProps.selectedChoices?.[0]

    const onSelect: DialogSelectorUniversalParam['onSelect'] = (id) => {
        if (onChange) {
            onChange(controllerProps, id)
        } else {
            field.onChange(id)
        }
        handleClose()
    }

    const data: DialogSelectorUniversalParam<OptionType> = {
        value: field.value,
        selected,
        control: controllerProps,
        input,
        onSelect,
        defaultFilter: defaultFilter || {},
        handleOpen,
        queryMeta,
    }

    useEffect(() => {
        if (!open && !isEqual(filters, controllerProps.filter)) {
            controllerProps.setFilters(filters, {}, false)
        }
    }, [open, filters])

    useEffect(() => {
        // trigger onSelectedChange after fetch
        // track isTouched instead of isDirty because isDirty resets after selecting the initial value
        if (input.fieldState.isTouched && !controllerProps.isFetching) {
            onSelectedChange?.(data)
        }
    }, [selected?.id, controllerProps.isFetching])

    return (
        <FormActionProvider value={formActionProviderValue}>
            <DialogSelectorProvider value={data}>
                <ListContextProvider value={controllerProps as any}>
                    <ChoicesContextProvider value={controllerProps}>
                        <div>
                            {renderToggler ? (
                                renderToggler(data)
                            ) : (
                                // @ts-ignore
                                <TextInput
                                    id={input.id}
                                    {...defaultSelectorProps}
                                    sx={{
                                        input: {
                                            cursor: disabled ? null : 'pointer !important',
                                            textOverflow: 'ellipsis',
                                        },
                                        label: {
                                            // TODO: find better way to overflow before end adornment
                                            maxWidth: selected
                                                ? null
                                                : 'calc(100% - var(--label-space, 60px))!important',
                                        },
                                    }}
                                    {...(required && { validate: requiredField })}
                                    {...((resettable ?? !required) && { resettable: true })}
                                    variant="outlined"
                                    size="medium"
                                    focused={false}
                                    onClick={handleOpen}
                                    source={source}
                                    disabled={disabled}
                                    InputProps={
                                        disabled
                                            ? null
                                            : {
                                                  endAdornment: (
                                                      <Icons.ArrowForward
                                                          onClick={handleOpen}
                                                          sx={{
                                                              color: (theme) =>
                                                                  alpha(
                                                                      theme.palette.grey[900],
                                                                      0.54,
                                                                  ),
                                                              cursor: 'pointer !important',
                                                          }}
                                                      />
                                                  ),
                                              }
                                    }
                                    inputProps={{
                                        onFocus: (e) => e.target.blur(),
                                        readOnly: true,
                                        value:
                                            (defaultSelectorValueSource
                                                ? extendRecord(data, defaultSelectorValueSource)
                                                : field.value) || '',
                                    }}
                                    helperText={defaultSelectorHelperText?.(data)}
                                />
                            )}
                            <SwipeableDrawer
                                PaperProps={{
                                    sx: {
                                        height: '100%',
                                        [`&.${drawerClasses.paperAnchorRight}`]: {
                                            maxWidth: '100%',
                                            width: drawerWidth,
                                        },
                                    },
                                }}
                                anchor={anchor}
                                open={open}
                                onClose={handleClose}
                                onOpen={handleOpen}
                            >
                                <DialogSelectorAppBar
                                    {...appBar}
                                    onClose={handleClose}
                                    selected={selected}
                                    titleSource={titleSource}
                                />
                                {/* HACK: for FilterLiveSearch */}
                                <DialogSelectorFilter {...filter} />
                                <Box
                                    overflow="auto"
                                    height="100%"
                                    display="flex"
                                    flexDirection="column"
                                >
                                    <Box p="20px">
                                        {renderTop?.(data)}
                                        <BoxContainer justifyContent="space-between">
                                            {renderNextToResultCount?.(data)}
                                            <DialogSelectorResultCount />
                                        </BoxContainer>
                                        {renderAboveList?.(data)}
                                    </Box>
                                    <DialogSelectorList
                                        renderNoResults={renderNoResults}
                                        noResults={noResults}
                                        itemPrimary={itemPrimary}
                                        itemSecondary={itemSecondary}
                                        selectItem={onSelect}
                                        renderListItem={renderListItem}
                                        renderItem={renderItem}
                                    />
                                </Box>
                                <DialogSelectorLoadMore />
                            </SwipeableDrawer>
                        </div>
                    </ChoicesContextProvider>
                </ListContextProvider>
            </DialogSelectorProvider>
        </FormActionProvider>
    )
}

export default DialogSelector

const formActionProviderValue = {
    queryParams: {
        clientConfig: { preventCacheReset: true } satisfies ApiClientConfig,
    },
}

// it can be button, svg or path
const isElementRaClearButton = (target: HTMLElement | SVGElement) => {
    if (!target) {
        return false
    }

    if (
        target.classList.contains(ResettableTextFieldClasses.clearButton) ||
        target.classList.contains(ResettableTextFieldClasses.clearIcon)
    ) {
        return true
    }

    if (
        target.tagName.toLowerCase() === 'path' &&
        (target.parentNode as HTMLElement).classList.contains(ResettableTextFieldClasses.clearIcon)
    ) {
        return true
    }

    return false
}
