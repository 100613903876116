import { type FC } from 'react'

import { useShowContext } from 'react-admin'

import Icons from 'assets/icons'
import { AddButton, CollapsibleInfoCard, InfoCardRowBase, InfoCardTitle } from 'components'
import { type InvoiceModel } from 'resources/invoices'
import { ItemsTotal } from 'resources/lineItems'
import { Box, Button, GridContainerColumns, GridItemLayout } from 'ui'

import { ItemsAddMenu, ItemsContent } from '../../components'

const DirectlyAddedCard: FC = () => {
    const { record, isLoading } = useShowContext<InvoiceModel>()

    if (isLoading) {
        return null
    }

    return (
        <GridContainerColumns>
            <GridItemLayout height={record.directlyAdded.length ? '140px' : null}>
                <CollapsibleInfoCard>
                    <InfoCardRowBase label={<InfoCardTitle>Directly Added</InfoCardTitle>}>
                        <ItemsAddMenu renderToggler={(open) => <AddButton onClick={open} />} />
                    </InfoCardRowBase>
                    {record.directlyAdded.length ? (
                        <ItemsContent invoiceItems={record.directlyAdded} />
                    ) : (
                        <>
                            <Box py="20px">
                                <ItemsAddMenu
                                    renderToggler={(open) => (
                                        <Button
                                            size="large"
                                            startIcon={<Icons.Add />}
                                            onClick={open}
                                        >
                                            add line item
                                        </Button>
                                    )}
                                />
                            </Box>
                            <ItemsTotal total={0} />
                        </>
                    )}
                </CollapsibleInfoCard>
            </GridItemLayout>
        </GridContainerColumns>
    )
}

export default DirectlyAddedCard
