import { type FC } from 'react'

import Icons from 'assets/icons'
import { ListTotalBadge, ViewHeader, formatMoney } from 'components'
import { getListData } from 'core'
import { IconBox } from 'ui'

import { type PaymentAggregates } from '../types'

import PaymentDrawerToggler from './PaymentDrawerToggler'
import { type CreateDefaultValues } from './types'

interface Props extends CreateDefaultValues {
    readOnly?: boolean
}

const PaymentsHeader: FC<Props> = ({ readOnly, createDefaultValues }) => {
    return (
        <ViewHeader title="Payments">
            <ViewHeader.Content>
                <ListTotalBadge
                    renderContent={(listContext) => {
                        const total = listContext.total

                        if (!total) {
                            return 0
                        }

                        const { total: totalPrice } = getListData<PaymentAggregates>(listContext)

                        return (
                            <>
                                {total} • {formatMoney(totalPrice)} Total
                            </>
                        )
                    }}
                />
            </ViewHeader.Content>

            {readOnly ? null : (
                <ViewHeader.Content placement="rightMobile">
                    <PaymentDrawerToggler
                        defaultValues={createDefaultValues}
                        readOnly={readOnly}
                    >
                        {(open) => (
                            <IconBox
                                title="Create"
                                onClick={open}
                            >
                                <Icons.Add />
                            </IconBox>
                        )}
                    </PaymentDrawerToggler>
                </ViewHeader.Content>
            )}
        </ViewHeader>
    )
}

export default PaymentsHeader
