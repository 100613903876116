import { Link } from 'react-router-dom'

import { blockerForceRedirectState, useCreateResourcePath } from 'core'
import { Anchor } from 'ui'

import { type WorkOrderModel } from '../types'
import { woResource } from '../utils'

import { WOLockedLink } from './WOLockedLink'

const WorkOrderLinkButton = ({ workOrder }: { workOrder: WorkOrderModel }) => {
    const createPath = useCreateResourcePath()

    if (!workOrder) {
        return null
    }

    if (!workOrder.isAccessible) {
        return <WOLockedLink shopName={workOrder.shopData.name}>{workOrder.number}</WOLockedLink>
    }

    return (
        <Anchor
            component={Link}
            to={createPath({
                resource: woResource.resource,
                id: workOrder.id,
                type: 'edit',
            })}
            variant="inherit"
            state={blockerForceRedirectState}
        >
            {workOrder.number}
        </Anchor>
    )
}

export default WorkOrderLinkButton
