import { type PropsWithChildren, type FC } from 'react'

import { SvgIcon, BoxContainer, Typography } from 'ui'

const MonitorCounter = ({ children, image }: { image: FC } & PropsWithChildren) => {
    if (children) {
        return (
            <BoxContainer gap="2px">
                <SvgIcon
                    component={image}
                    sx={{ width: '16px', height: '16px', color: 'text.primary' }}
                />
                <Typography
                    color="text.primary"
                    variant="chartLabel"
                >
                    {children}
                </Typography>
            </BoxContainer>
        )
    }
    return null
}
export default MonitorCounter
