import { useOpenUtilityDrawer } from 'components'
import { Capability, type IntegrationType } from 'resources/telematics'

import IntegrationActivateForm from './IntegrationActivateForm'
import IntegrationDrawerWrapper from './IntegrationDrawerWrapper'
import { type IntegrationDrawerTogglerExtraProps } from './types'

const useIntegrationDrawerToggler = () => {
    const open = useOpenUtilityDrawer()

    return (integration: IntegrationType) => {
        return open({
            drawerArgs: {
                title: integration.isActive ? 'Configure' : 'Connect Integration',
                renderWrapper: (props) => (
                    <IntegrationDrawerWrapper
                        {...props}
                        defaultValues={{
                            syncMeters:
                                integration.capabilities.meters === Capability.SUPPORTED
                                    ? integration.syncMeters
                                    : false,
                            syncDvirs:
                                integration.capabilities.dvirs === Capability.SUPPORTED
                                    ? integration.syncDvirs
                                    : false,
                            autosaveNewVehicles: integration.autosaveNewVehicles,
                        }}
                    />
                ),
                renderContent: () => <IntegrationActivateForm />,
                renderBottomRight: (render) =>
                    render({
                        icon: integration.isActive ? undefined : null,
                        label: integration.isActive ? undefined : 'Connect',
                    }),
            },
            extra: {
                integration,
            } satisfies IntegrationDrawerTogglerExtraProps,
        })
    }
}

export default useIntegrationDrawerToggler
