import { type ReactNode, type FC, type ReactElement } from 'react'

import Header from '../header'

interface Props {
    children: ReactNode
    mainAction?: ReactElement
}

const MainHeader: FC<Props> = ({ children, mainAction }) => {
    return (
        <Header>
            <Header.Block>
                <Header.Main>{children}</Header.Main>
                {mainAction ? (
                    <Header.Aside>
                        <Header.MainAction>{mainAction}</Header.MainAction>
                    </Header.Aside>
                ) : null}
            </Header.Block>
        </Header>
    )
}

export default MainHeader
