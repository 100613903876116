import { type FC } from 'react'

import { type Size } from 'appTypes'
import Icons from 'assets/icons'
import { getContrastColor } from 'lib'
import { type ShopModel } from 'resourcesBase'
import {
    type TypographyProps,
    BoxContainer,
    type DataAvatarProps,
    SvgIcon,
    Typography,
    IconElement,
} from 'ui'
import { getInitials } from 'utils'

const source = 'logo' as const satisfies keyof ShopModel

const avatarColorSource = 'avatarColor' as const satisfies keyof ShopModel

interface Props extends Pick<TypographyProps, 'variant'> {
    record: Pick<ShopModel, 'name' | 'avatarColor'>
    fontWeight?: number
    fontSize?: Size
    size?: Size
}

const DefaultShopAvatar: FC<Props> = ({ record, fontWeight, variant, fontSize, size }) => {
    if (!record) {
        return <Icons.Shop />
    }
    const initials = getInitials(record.name)

    if (!initials) {
        return (
            <SvgIcon
                sx={{
                    color: `${getContrastColor(record.avatarColor)} !important`,
                    fontSize: size,
                }}
                component={Icons.LocationOn}
            />
        )
    }
    return (
        <Typography
            component={BoxContainer}
            justifyContent="center"
            alignContent="center"
            color={getContrastColor(record.avatarColor)}
            lineHeight="100%"
            fontWeight={fontWeight || 500}
            variant={variant}
            fontSize={fontSize}
        >
            {initials}
        </Typography>
    )
}

const avatarProps = (
    record: Pick<ShopModel, 'logo' | 'avatarColor' | 'name'>,
    params?: {
        initials?: Pick<Props, 'fontSize' | 'fontWeight' | 'variant'>
        avatar?: Pick<Props, 'size'>
    },
): DataAvatarProps => ({
    imageSrc: record?.logo,
    defaultImage: record ? (
        <DefaultShopAvatar
            record={record}
            {...params?.avatar}
            {...params?.initials}
        />
    ) : (
        <IconElement
            component={Icons.Shop}
            color={(theme) => theme.palette.white + ' !important'}
            size={params?.avatar?.size}
        />
    ),
    color: record ? record.avatarColor : (theme) => theme.palette.text.primary,
    avatarOpacity: 1,
})

const avatar = {
    source,
    avatarColorSource,
    label: 'Avatar',
    avatarColorLabel: 'Avatar Color',
    Icon: DefaultShopAvatar,
    props: avatarProps,
}

export default avatar
