import { type SaveButtonProps } from 'react-admin'

import Icons from 'assets/icons'
import SubmitButton from 'components/form/SubmitButton'

import { useUtilityDrawerContext } from './UtilityDrawerContext'

export interface UtilityDrawerSaveButtonProps extends SaveButtonProps {
    formType?: 'create' | 'edit'
}

const UtilityDrawerSaveButton = ({ formType, ...props }: UtilityDrawerSaveButtonProps) => {
    const { extraArgs = {} } = useUtilityDrawerContext()
    const isEditMode = Boolean(
        (formType && formType === 'edit') ?? (extraArgs.id || extraArgs.type === 'edit'),
    )
    const Icon = isEditMode ? Icons.Save : Icons.Add

    return (
        <SubmitButton
            size="large"
            variant="contained"
            icon={<Icon fontSize="large" />}
            type="submit"
            label={isEditMode ? 'SAVE' : 'CREATE'}
            {...props}
        />
    )
}

export default UtilityDrawerSaveButton
