import { type Identifier, type BaseModel } from 'appTypes'

export interface InventoryPricingModel extends BaseModel {
    acquisitionCost: number
    costFloor: number
    created: string
    marginPercent: number
    markup: number
    markupPercent: number
    partTier: Identifier
    price: number
    type: InventoryPricingTypeKeys
    partTierData: {
        shop: Identifier
        created: string
        isDefault: boolean
        name: string
    }
}

export enum InventoryPricingTypeKeys {
    PERCENTAGE = 'PERCENTAGE',
    FLAT_MARKUP = 'FLAT_MARKUP',
    SELLING_PRICE = 'SELLING_PRICE',
}
