import { type ComponentProps, useEffect, useRef } from 'react'

import { Form as RAForm } from 'react-admin'
import {
    useFormState,
    useFormContext,
    type UseFormReturn,
    type UseFormClearErrors,
    type UseFormReset,
    useWatch,
} from 'react-hook-form'
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed'

import { type SubmitValidate } from 'components/resource/common'
import { FormInfo } from 'context'
import { nonFieldErrors, useServerErrorHandler } from 'core/errors'
import { formDefaultValuesKey } from 'core/form'
import { useNotify } from 'core/notifications'
import { type ResourceType, useResource } from 'core/resource'
import { useOptimizedRef } from 'hooks'
import { classes } from 'lib'
import { StyledElement } from 'ui'

import FormMakeSubmittable from './FormMakeSubmittable'
import FormWarnWhenUnsavedChanges from './FormWarnWhenUnsavedChanges'
import { resourceToFormId, resourceToFormName } from './utils'

interface FormExtraActionsProps {
    formOnError?: (params: {
        errors: UseFormReturn['formState']['errors']
        defaultOnError: () => void
        notify: ReturnType<typeof useNotify>
        clearErrors: UseFormClearErrors<any>
        reset: UseFormReset<any>
    }) => void
    serverErrorHandler: ReturnType<typeof useServerErrorHandler>
}

const FormExtraActions = ({ formOnError, serverErrorHandler }: FormExtraActionsProps) => {
    const { formState, clearErrors, reset } = useFormContext()
    const { errors, isSubmitting } = formState
    const notify = useNotify()

    const formChildRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const errorNames = Object.keys(errors)
        if (errorNames.length > 0 && !isSubmitting) {
            for (const errorName of errorNames) {
                if (errorName === nonFieldErrors) {
                    continue
                }

                const formElement = formChildRef.current.closest('form')
                // mui error class is added to the parent element, but .focus() still works
                const elementWithError = formElement.querySelector(
                    `.${classes.error}`,
                ) as HTMLInputElement

                if (elementWithError) {
                    smoothScrollIntoView(elementWithError, {
                        scrollMode: 'always',
                    }).then(() => {
                        elementWithError.focus()
                    })
                }
                break
            }

            const defaultOnError = () => {
                serverErrorHandler(errors as any, { fallbackError: '' })
            }

            if (formOnError) {
                formOnError({
                    errors,
                    defaultOnError,
                    notify,
                    clearErrors,
                    reset,
                })
            } else {
                defaultOnError()
            }
        }
    }, [errors, isSubmitting])

    return (
        <StyledElement
            sx={{ display: 'none !important' }}
            ref={formChildRef}
        />
    )
}

const ResetOnSubmitSuccess = () => {
    const { reset, formState } = useFormContext()
    const { isSubmitSuccessful } = formState
    useEffect(() => {
        if (isSubmitSuccessful) {
            reset()
        }
    }, [isSubmitSuccessful])

    return null
}

export interface FormProps
    extends Omit<ComponentProps<typeof RAForm>, 'resource'>,
        Omit<FormExtraActionsProps, 'notify' | 'serverErrorHandler' | 'formId'> {
    disableSuccessRedirect?: boolean
    resource?: ResourceType
    resetOnSuccess?: boolean
    // Dry run validation
    submitValidate?: SubmitValidate
}

const Form = ({
    warnWhenUnsavedChanges = true,
    formOnError,
    defaultValues,
    resource: resourceProp,
    resetOnSuccess,
    submitValidate,
    ...props
}: FormProps) => {
    const resource = useResource(resourceProp)

    const { current: formDefaultValuesFromQuery } = useOptimizedRef(() => {
        const queryParams = new URLSearchParams(window.location.search).get(formDefaultValuesKey)
        try {
            if (queryParams) {
                return JSON.parse(queryParams)
            }
        } catch {
            return null
        }

        return null
    })

    const formDefaultValues =
        (formDefaultValuesFromQuery || defaultValues) &&
        ((record) => {
            let values = defaultValues
            if (typeof defaultValues === 'function') {
                values = defaultValues(record)
            }
            return {
                ...values,
                ...formDefaultValuesFromQuery,
            }
        })

    const serverErrorHandler = useServerErrorHandler()

    const formName = props.formRootPathname || resourceToFormName(resource)

    const formId = resourceToFormId(resource)

    return (
        <FormInfo
            name={formName}
            submitValidate={submitValidate}
        >
            <RAForm
                noValidate
                id={formId}
                mode="onChange"
                {...props}
                warnWhenUnsavedChanges={false}
                defaultValues={formDefaultValues}
                resource={resource?.resource}
            >
                <FormExtraActions
                    serverErrorHandler={serverErrorHandler}
                    formOnError={formOnError}
                />
                {resetOnSuccess && <ResetOnSubmitSuccess />}
                {warnWhenUnsavedChanges ? <FormWarnWhenUnsavedChanges /> : null}
                {props.children}
                <FormMakeSubmittable />
                <FormInitialValidation formId={formId} />
            </RAForm>
        </FormInfo>
    )
}

export default Form

/**
 * Used for initial validation of the form.
 * It will trigger validation for all VISIBLE fields.
 * It will make sure that all fields with errors are touched, so that the error message is displayed.
 * react-admin checks for touched fields to display the error message.
 * Simple solution, does not work for nested fields
 * It triggers required validation as well, this is why it should be used only on EDIT
 */
const FormInitialValidation = ({ formId }: { formId: string }) => {
    const id = useWatch({ name: 'id' })

    if (!id) {
        return null
    }

    return <FormInitialValidationBase formId={formId} />
}

const FormInitialValidationBase = ({ formId }: { formId: string }) => {
    const { trigger, setValue, getValues } = useFormContext()
    const { errors, touchedFields, isDirty } = useFormState()

    useEffect(() => {
        trigger(null, {
            shouldFocus: false,
        })
    }, [])

    useEffect(() => {
        const errorNames = Object.keys(errors)
        if (!isDirty && errorNames.length) {
            errorNames.forEach((name) => {
                if (
                    touchedFields[name] ||
                    // Don't validate if input is disabled. TODO: find better way to get input.disabled
                    (
                        document.querySelector(
                            `${formId ? '#' + formId : ''} [name="${name}"]`,
                        ) as HTMLInputElement
                    )?.disabled
                ) {
                    return
                }
                setValue(name, getValues(name), {
                    shouldDirty: true,
                    shouldTouch: true,
                    shouldValidate: true,
                })
            })
        }
    }, [isDirty, errors])

    return null
}
