import { useState } from 'react'

import { type Validator } from 'react-admin'

import { CheckboxInput } from 'components'
import { requiredValidation } from 'core'
import { classes } from 'lib'
import { Anchor, formHelperTextClasses, Typography, StyledElement, Stack } from 'ui'

import TosDialog from './TosDialog'

const tosValidation: Validator[] = [
    (value) => (value ? undefined : 'To open an account, you must agree with Terms & Conditions'),
    requiredValidation,
]

const TosButton = () => {
    const [showTos, setShowTos] = useState(false)

    return (
        <>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={0}
                ml="12px"
                mt="-10px"
            >
                <CheckboxInput
                    disableLabelClick
                    label={
                        <StyledElement sx={{ display: 'flex', gap: '5px' }}>
                            <Typography
                                variant="body1"
                                sx={{ cursor: 'default' }}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setShowTos(showTos)
                                }}
                            >
                                Agree with
                            </Typography>
                            <Anchor
                                onClick={(e) => {
                                    e.preventDefault()
                                    setShowTos(true)
                                }}
                            >
                                Terms & Conditions
                            </Anchor>
                        </StyledElement>
                    }
                    source="tosAccepted"
                    validate={tosValidation}
                    sx={{
                        [`&  .${formHelperTextClasses.root}.${classes.error}`]: {
                            mb: '20px',
                            ml: '-12px',
                        },
                    }}
                />
            </Stack>
            <TosDialog
                open={showTos}
                onClose={() => setShowTos(false)}
            />
        </>
    )
}

export default TosButton
