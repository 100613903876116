import Value from './Value'
import { nameLabel, nameSource } from './name'

const laborRateName = {
    source: nameSource,
    label: nameLabel,
    Value,
}

export default laborRateName
