import { useRecordContext } from 'react-admin'

import { type PurchaseOrderModel } from 'resources/purchaseOrders'

import { LineItemsList } from '../LineItems/components'

import ReturnItemsClosedInfo from './components/ReturnItemsClosedInfo'

const ReturnItems = () => {
    const record = useRecordContext<PurchaseOrderModel>()

    return (
        <>
            {record.status === 'CLOSED' && <ReturnItemsClosedInfo />}
            <LineItemsList />
        </>
    )
}

export default ReturnItems
