import { type FC } from 'react'

import { type Identifier } from 'appTypes'
import { SliderView } from 'components'

import { type ComponentDate, useCostReportContext } from '../../../CostReportContext'

import ComponentCard from './ComponentCard'

interface ComponentsSliderProps {
    date: ComponentDate
    onComponentClick: (component: Identifier) => void
}

const ComponentsSlider: FC<ComponentsSliderProps> = ({ date, onComponentClick }) => {
    const { data } = useCostReportContext()

    const items = data.components.map((component) => {
        const total = date
            ? data.totals[component.id]?.[date.source]
            : data.componentTotals[component.id]

        if (total == null) {
            return null
        }

        return (
            <ComponentCard
                key={component.id}
                record={component}
                total={total}
                onComponentClick={onComponentClick}
            />
        )
    })

    return <SliderView items={items.filter(Boolean)} />
}

export default ComponentsSlider
