// Make sure you import Link and LinkProps

import { type ReactNode, type FC } from 'react'

import { type UseCreateResourcePathParams, useCreateResourcePath } from 'core/resource'
import { LinkButton } from 'ui'

interface Props {
    to: string | UseCreateResourcePathParams
    children: ReactNode
}

const ResourceLinkButton: FC<Props> = (props) => {
    const createPath = useCreateResourcePath()

    return (
        <LinkButton
            {...props}
            to={typeof props.to === 'string' ? props.to : createPath(props.to)}
        />
    )
}

export default ResourceLinkButton
