import { useRecordContext } from 'react-admin'

import { InfoCard, InfoCardHeader } from 'components'
import { type IssueModel } from 'resources/issues'
import { woFields } from 'resources/workOrders'
import { Typography, Section } from 'ui'

const ReasonForResolveCard = () => {
    const record = useRecordContext<IssueModel>()

    if (record?.status !== 'CLOSED') {
        return null
    }

    return (
        <Section>
            <InfoCard>
                <InfoCardHeader title="Reason for Resolve" />
                <Typography
                    variant="body2"
                    color="text.primary"
                >
                    {record.workOrderData ? (
                        <>{woFields.self.value(record.workOrderData)} closed.</>
                    ) : (
                        record.reasonClosed
                    )}
                </Typography>
            </InfoCard>
        </Section>
    )
}

export default ReasonForResolveCard
