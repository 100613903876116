import { createContext, type FC, type ReactNode, useContext, useState } from 'react'

interface ListFilterDrawerContextValue {
    isOpen: boolean
    open: () => void
    close: () => void
    setOpen: (toggler: boolean | ((open: boolean) => boolean)) => void
}

const ListFilterDrawerContext = createContext<ListFilterDrawerContextValue>(
    {} as ListFilterDrawerContextValue,
)

export const useListFilterDrawerContext = (): ListFilterDrawerContextValue =>
    useContext(ListFilterDrawerContext)

export const ListFilterDrawerProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [isOpen, setOpen] = useState(false)

    const open = () => setOpen(true)

    const close = () => setOpen(false)

    return (
        <ListFilterDrawerContext.Provider
            value={{
                isOpen,
                setOpen,
                open,
                close,
            }}
        >
            {children}
        </ListFilterDrawerContext.Provider>
    )
}
export default ListFilterDrawerProvider
