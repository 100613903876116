import { type FC, type ReactElement } from 'react'

import { inject, observer } from 'mobx-react'

import { type AuthStore } from 'core/auth'

interface Props {
    children: ReactElement
}

const RenderOnInventory = inject('auth')(
    observer(({ auth, children }: Props & { auth: AuthStore }) => {
        if (!auth.companySettings.hasInventory) {
            return null
        }

        return children
    }),
) as FC<Props>

export default RenderOnInventory
