import {
    useUtilityDrawerContext,
    DateInput,
    TextInput,
    costNonNegativeMaskParams,
    inputPaymentCheckMaskParams,
} from 'components'
import { requiredValidation } from 'core'
import { PaymentMethodInput } from 'resources/paymentMethods'

import { type PaymentDrawerTogglerExtra } from './types'

const PaymentForm = () => {
    const { extra } = useUtilityDrawerContext()
    const { disabledFields } = extra as PaymentDrawerTogglerExtra

    return (
        <>
            <TextInput
                source="amount"
                label="Amount Paid"
                disabled={disabledFields?.('amount')}
                validate={requiredValidation}
                {...costNonNegativeMaskParams}
            />

            <DateInput
                source="date"
                label="Payment Date"
                disabled={disabledFields?.('date')}
                defaultValue={new Date()}
                validate={requiredValidation}
            />

            <PaymentMethodInput
                source="method"
                disabled={disabledFields?.('method')}
                defaultValue={extra.paymentMethod}
            />

            <TextInput
                source="number"
                disabled={disabledFields?.('number')}
                label="Payment/Check Number"
                {...inputPaymentCheckMaskParams}
            />
        </>
    )
}
export default PaymentForm
