import { styled } from 'lib'

export const MainContent = styled('main')`
    flex: 1;
    margin-left: auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    ${({ theme }) => theme.breakpoints.up(theme.props.mobileViewBreakpoint)} {
        width: calc(100% - ${({ theme }) => theme.props.drawerWidth}px);
    }
`

// sidebar + main content
export const AppContent = styled('div')`
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    overflow-x: hidden;
`
