import { type FC, type ReactNode, createContext, useContext, useState } from 'react'

export const ArchivedContext = createContext<{
    isArchived: boolean
    setIsArchived: (toggle: boolean) => void
}>({
    isArchived: false,
    setIsArchived: () => {
        //
    },
})

export const useArchivedContext = () => {
    return useContext(ArchivedContext)
}

interface Props {
    children: ReactNode
}

export const ArchivedContextProvider: FC<Props> = ({ children }) => {
    const [isArchived, setIsArchived] = useState(false)
    return (
        <ArchivedContext.Provider value={{ isArchived, setIsArchived }}>
            {children}
        </ArchivedContext.Provider>
    )
}
