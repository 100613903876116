import { createContext, useContext } from 'react'

interface FormActionContextValue {
    queryParams?: any
}

const FormActionContext = createContext<FormActionContextValue>(null)

export const FormActionProvider = FormActionContext.Provider

export const useFormActionContext = () => useContext(FormActionContext)
