import { type FC } from 'react'

import Icons from 'assets/icons'
import { costMaskParams, costMaskParamsDiscount, TextInput, type TextInputProps } from 'components'
import { SvgIcon, Stack, DataAvatar, Typography, BoxContainer } from 'ui'

import { ItemWrapper } from './PoVerifyItems'
import { type ReceiptFormData } from './types'

interface FeeProps {
    icon: FC
    title: string
    source: string
    inputProps?: Partial<TextInputProps>
}

const taxesName: keyof ReceiptFormData = 'taxes'

const Fee = ({ icon, title, source, inputProps }: FeeProps) => {
    return (
        <ItemWrapper>
            <BoxContainer gap="12px">
                <DataAvatar defaultImage={<SvgIcon component={icon} />} />
                <Typography
                    variant="h6"
                    lineHeight="20px"
                >
                    {title}
                </Typography>
            </BoxContainer>
            <TextInput
                label="Amount"
                source={taxesName + '.' + source}
                {...costMaskParams}
                {...inputProps}
            />
        </ItemWrapper>
    )
}

const PoAdditionalFees = () => {
    return (
        <>
            <Typography
                variant="subtitle1"
                component={BoxContainer}
                gap="8px"
                mb="20px"
            >
                Additional Fees (Optional)
            </Typography>

            <Stack spacing="12px">
                <Fee
                    title="Shipping"
                    icon={Icons.Shipping}
                    source="shippingTax"
                />
                <Fee
                    title="Miscellaneous"
                    icon={Icons.Add}
                    source="miscellaneousTax"
                />
                <Fee
                    title="Discount"
                    icon={Icons.PercentOutlined}
                    source="discount"
                    inputProps={costMaskParamsDiscount}
                />
                <Fee
                    title="Sales Tax"
                    icon={Icons.AttachMoney}
                    source="salesTax"
                />
            </Stack>
        </>
    )
}
export default PoAdditionalFees
