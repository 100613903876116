import { type MergeUnions } from 'appTypes'
import {
    type DeleteOneParams,
    serialize,
    type Serializer,
    type singleSerializer,
    createResource,
} from 'core'

import {
    type PMIntervalIntersection,
    type PMIntervalMeterType,
    type PMIntervalTimeType,
    PMIntervalTimeTypeKeys,
    type PmModel,
} from './types'

export const pmResource = createResource({
    name: 'pm-schedules',
    resource: 'pm-schedules',
})

interface MeterType {
    id: PMIntervalTimeTypeKeys
    name: string
    toDay: number
}

export const pmTypeChoices: MeterType[] = [
    {
        id: PMIntervalTimeTypeKeys.DAY,
        name: 'Day(s)',
        toDay: 1,
    },
    {
        id: PMIntervalTimeTypeKeys.WEEK,
        name: 'Week(s)',
        toDay: 7,
    },
    {
        id: PMIntervalTimeTypeKeys.MONTH,
        name: 'Month(s)',
        toDay: 30,
    },
    {
        id: PMIntervalTimeTypeKeys.YEAR,
        name: 'Year(s)',
        toDay: 360,
    },
]

export const getPmTypeChoice = (type: PMIntervalTimeTypeKeys) =>
    pmTypeChoices.find((choice) => choice.id === type)

export const thresholdHelperText = 'Trigger Point for Status Change'

export const unitPMIntervalSerializator: Serializer<PmModel> = [
    'name',
    { name: 'component', parse: 'emptyToNull' },
    { name: 'reasonForRepair', parse: 'emptyToNull' },
    'description',
    'notes',
    { name: 'lastDone', parse: ['dateTime', 'disableOnEdit'] },
    {
        name: 'intervals',
        parse: (value) => {
            const thresholdParse: singleSerializer<PMIntervalIntersection> = {
                name: 'threshold',
                parse: 'number',
            }
            const serializer: Serializer<PMIntervalIntersection> = [
                { name: 'type', parse: 'emptyToNull' },
                { name: 'value', parse: 'number' },
            ]

            return value.map((interval) => {
                let intervalSerializer = [] as Serializer<PMIntervalIntersection>
                if (interval.type === 'TIME') {
                    ;(intervalSerializer as Serializer<PMIntervalTimeType>) = [
                        { name: 'valueType', parse: 'emptyToNull' },
                    ]
                    if (interval.threshold && interval.thresholdType) {
                        ;(intervalSerializer as Serializer<PMIntervalTimeType>).push(
                            {
                                name: 'thresholdType',
                                parse: 'emptyToNull',
                            },
                            thresholdParse as singleSerializer<PMIntervalTimeType>,
                        )
                    }
                } else {
                    ;(intervalSerializer as Serializer<PMIntervalMeterType>) = [
                        { name: 'lastDoneMeterValue', parse: ['number', 'disableOnEdit'] },
                        thresholdParse as singleSerializer<PMIntervalMeterType>,
                    ]
                }

                return serialize(interval as MergeUnions<(typeof value)[0]>, [
                    ...serializer,
                    ...intervalSerializer,
                ])
            })
        },
    },
]

export const deleteUnitPMIntervalConfig: DeleteOneParams = {
    confirmConfig: {
        title: 'Are you sure you want to delete this interval?',
    },
}
