import Icons from 'assets/icons'
import { ActionsMenu } from 'core/actions'
import { Button } from 'ui'

import { useMultiFormatScannerContext } from './MultiFormatScannerContext'

const MultiFormatScannerChangeCameraButton = () => {
    const { setActiveCamera, activeCameraId, cameras } = useMultiFormatScannerContext()

    return (
        <ActionsMenu
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            renderToggler={(open) => (
                <Button
                    variant="contained"
                    onClick={open}
                    startIcon={<Icons.CameraswitchOutlined />}
                >
                    Change Camera
                </Button>
            )}
            actions={(params, { children }) =>
                cameras.map((camera) =>
                    children({
                        key: camera.deviceId,
                        title: camera.label,
                        onClick: () => {
                            setActiveCamera(camera.deviceId)
                        },
                        Icon:
                            activeCameraId === camera.deviceId
                                ? Icons.RadioButtonCheckedOutlined
                                : Icons.RadioButtonUncheckedOutlined,
                    }),
                )
            }
        />
    )
}

export default MultiFormatScannerChangeCameraButton
