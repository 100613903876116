import Icons from 'assets/icons'
import { ViewHeader } from 'components'
import { useInventoryContext } from 'resources/inventory'
import { IconBox, InfoBadge, NonDisplayedInput } from 'ui'

import { MAX_PHOTO_COUNT } from './utils'

const PhotoTabHeader = ({
    photosCount,
    upload,
}: {
    photosCount: number
    upload: (event: any) => Promise<void>
}) => {
    const inventoryView = useInventoryContext()

    const maxReached = photosCount === MAX_PHOTO_COUNT
    return (
        <ViewHeader title="Photos">
            <ViewHeader.Content>
                <InfoBadge badgeContent={photosCount || 0} />
            </ViewHeader.Content>

            {inventoryView ? null : (
                <ViewHeader.Content placement="rightMobile">
                    <label>
                        <NonDisplayedInput
                            type="file"
                            disabled={maxReached}
                            onChange={upload}
                            accept="image/*"
                        />
                        <IconBox
                            title={
                                maxReached
                                    ? 'Maximum image limit (10) reached'
                                    : 'Add up to 10 images, max size 30 MB each'
                            }
                            disabled={maxReached}
                        >
                            <Icons.UploadFileOutlined />
                        </IconBox>
                    </label>
                </ViewHeader.Content>
            )}
        </ViewHeader>
    )
}

export default PhotoTabHeader
