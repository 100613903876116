import { formatPercent } from 'components'
import { reportsUrls, createReportResource } from 'resources/reports'

import WidgetBase, { WidgetSkeleton } from '../../WidgetBase/WidgetBase'
import { useCreateDashboardWidgetLink, useDashboardWidget } from '../../WidgetsForm'
import { CostPerCategoryClarification } from '../CostPerCategoryWidget/components'
import { roundTop3WidgetData, roundWidgetJobsData } from '../utils'

import { CostPerRepairChart, CostPerRepairList } from './components'
import { type listType, type QueryResponse, type ResponseType } from './types'

const description = `Monitor closely the reasons for repair that lead to high maintenance costs.
Explore seasonality and trend dynamics by comparing reporting periods.`

let hasData = false
let validData = false
const CostPerCategoryWidget = () => {
    const createLink = useCreateDashboardWidgetLink()
    const data = useDashboardWidget<QueryResponse>({
        resource: createReportResource('cost-per-reason-for-repair'),
        sideEffect: (data) => {
            const finalData = roundWidgetJobsData(data)

            const { maxTotalReasonsForRepair, uncategorized, ...ratioData } =
                finalData as ResponseType
            const { listData, positiveDataTotal, ...listExtras } = roundTop3WidgetData(
                maxTotalReasonsForRepair,
                { total: uncategorized },
            )
            hasData = typeof ratioData.total === 'number'
            validData = ratioData.total > 0 && listExtras.top3Percentage > 0
            const sumOfExcludedReasons = positiveDataTotal - listExtras.top3Total
            const sumPercentageOfExcludedReasons = 100 - listExtras.top3Percentage
            const list: listType = {
                data: listData,
                ...listExtras,
                sumOfExcludedReasons,
                sumPercentageOfExcludedReasons,
            }
            return {
                ...ratioData,
                list,
            }
        },
    })

    if (!data) {
        return <WidgetSkeleton />
    }
    const { list, ...ratioData } = data

    return (
        <WidgetBase
            headerProps={{
                title: 'Top 3 Reasons for Repair Account for',
                label: hasData ? (validData ? formatPercent(list.top3Percentage) : 'N/A') : null,
                tooltipText: description,
                clarification: (
                    <CostPerCategoryClarification>
                        of all Maintenance Costs
                    </CostPerCategoryClarification>
                ),
                chart: (
                    <CostPerRepairChart
                        list={list}
                        disabled={!validData}
                        total={ratioData.positiveTotal}
                    />
                ),
            }}
            infoProps={{
                content: (
                    <CostPerRepairList
                        list={list}
                        isValid={hasData && validData}
                        description={description}
                    />
                ),
            }}
            separator="Top 3 Categories"
            link={createLink(reportsUrls.costPerReasonForRepair)}
        />
    )
}
export default CostPerCategoryWidget
