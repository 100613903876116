import { createContext, type FC, useContext, useState, type ReactNode } from 'react'

import { type ListControllerProps, type ListControllerResult, useListContext } from 'react-admin'

import { isEqualWith } from 'utils'

interface ListSortDrawerContextValue {
    isOpen: boolean
    open: () => void
    close: () => void
    setOpen: (toggler: boolean | ((open: boolean) => boolean)) => void
    defaultSort: ListControllerProps['sort']
    isDefaultSorted: boolean
    checkIsDefaultSorted: (params: ListControllerProps['sort']) => boolean
    listContext: ListControllerResult
    reset: () => void
}

const ListSortDrawerContext = createContext({} as ListSortDrawerContextValue)

export const useListSortDrawerContext = (): ListSortDrawerContextValue =>
    useContext(ListSortDrawerContext)

export interface ListSortDrawerProviderProps
    extends Pick<ListSortDrawerContextValue, 'defaultSort'> {
    children: ReactNode
}

export const ListSortDrawerProvider: FC<ListSortDrawerProviderProps> = ({
    children,
    defaultSort,
}) => {
    const [isOpen, setOpen] = useState(false)

    const listContext = useListContext()
    const { sort, setSort } = listContext

    let isDefaultSorted = true

    const checkIsDefaultSorted: ListSortDrawerContextValue['checkIsDefaultSorted'] = (
        otherSort,
    ) => {
        if (!otherSort || !defaultSort) {
            return true
        }
        return isEqualWith(otherSort, defaultSort)
    }

    if (defaultSort) {
        isDefaultSorted = checkIsDefaultSorted(sort)
    }

    const reset = () => {
        setSort(defaultSort)
    }

    const open = () => setOpen(true)

    const close = () => setOpen(false)

    const value: ListSortDrawerContextValue = {
        isOpen,
        setOpen,
        open,
        close,
        defaultSort,
        isDefaultSorted,
        checkIsDefaultSorted,
        listContext,
        reset,
    }

    return <ListSortDrawerContext.Provider value={value}>{children}</ListSortDrawerContext.Provider>
}

export default ListSortDrawerProvider
