import { useOpenFullPageLoader } from 'components'
import api from 'core/api'
import { useFinalErrorHandler } from 'core/errors'
import { useQueryClient } from 'lib'
import {
    type IntegrationProvider,
    type Telematics,
    telematicsExtras,
    type TelematicsQueryResult,
} from 'resources/telematics'
import { Typography } from 'ui'

import useIntegrationDrawerToggler from './useIntegrationDrawerToggler'

const useConnect = () => {
    const openDrawer = useIntegrationDrawerToggler()
    const fullPageLoading = useOpenFullPageLoader()
    const handleError = useFinalErrorHandler()
    const queryClient = useQueryClient()

    return async (provider: IntegrationProvider, data: any, onError?: (err: any) => void) => {
        try {
            fullPageLoading({
                text: (
                    <Typography
                        variant="inherit"
                        color="inherit"
                    >
                        This process may take a little longer,
                        <br />
                        thank you for your patience.
                    </Typography>
                ),
            })
            await api.post('telematics/connect_' + provider, data).then((response: Telematics) => {
                const integration = {
                    ...response,
                    ...telematicsExtras[response.provider],
                }
                queryClient.setQueryData(
                    'telematics',
                    ({ results: oldData }: TelematicsQueryResult) => {
                        if (Array.isArray(oldData)) {
                            const existingIndex = oldData.findIndex(
                                (data) => data.provider === response.provider,
                            )

                            if (existingIndex !== -1) {
                                const newData = [...oldData]
                                newData[existingIndex] = response
                                return { results: newData }
                            }

                            return { results: [...oldData, response] }
                        }
                        return { results: [response] }
                    },
                )
                openDrawer(integration)
            })
        } catch (err) {
            onError?.(err)
            handleError(err, {
                fallbackError: 'Connection unsuccessful',
            })
        } finally {
            fullPageLoading(false)
        }
    }
}

export default useConnect
