import { type FC } from 'react'

import { CreateButtonView, Header, ListTotalBadge, MainHeader } from 'components'
import { InvoiceDrawerToggler } from 'resources/invoices'

const InvoicesListHeader: FC = () => {
    return (
        <MainHeader
            mainAction={
                <InvoiceDrawerToggler>
                    {(open) => <CreateButtonView onClick={open}>CREATE Invoice</CreateButtonView>}
                </InvoiceDrawerToggler>
            }
        >
            <Header.Info>
                <Header.Content>
                    <Header.Title>Invoices</Header.Title>
                </Header.Content>
                <Header.Content mobilePlacement="bottom">
                    <ListTotalBadge />
                </Header.Content>
            </Header.Info>
        </MainHeader>
    )
}

export default InvoicesListHeader
