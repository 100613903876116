import { type RaRecord, useRecordContext } from 'react-admin'

import { useCanAccessContext } from './CanAccessContext'

const useCanAccess = () => {
    const contextRecord = useRecordContext()
    const getCanAccess = useCanAccessContext()

    return (action: string, record?: RaRecord) => getCanAccess(record || contextRecord, action)
}

export default useCanAccess
