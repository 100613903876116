import { type FC } from 'react'

import Icons from 'assets/icons'
import { IconBox } from 'ui'

interface Props {
    onClick?: () => void
    disabled?: boolean
}

const EditButton: FC<Props> = ({ onClick, disabled }) => {
    return (
        <IconBox
            title="View/Edit"
            disabled={disabled}
            onClick={onClick}
        >
            <Icons.Edit />
        </IconBox>
    )
}

export default EditButton
