import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import { type AuthStore } from 'core'
import { shopFields } from 'resources/shops'
import { DataAvatar } from 'ui'

import { SidebarShopContent } from '../common'

import Item from './Item'
import { CircleHover } from './ItemHover'
import { type WithControls } from './types'

const ShopMenuItem: FC<WithControls> = inject('auth')(
    observer(({ auth, open }: WithControls & { auth: AuthStore }) => {
        const _open = () => {
            open(() => <SidebarShopContent />, 'shop')
        }
        return (
            <Item
                py="16px"
                onMouseEnter={_open}
            >
                <CircleHover>
                    <DataAvatar
                        sx={{
                            width: 32,
                            height: 32,
                        }}
                        {...shopFields.avatar.props(auth.shop, {
                            initials: { variant: 'body2' },
                        })}
                    />
                </CircleHover>
            </Item>
        )
    }),
)

export default ShopMenuItem
