import { styled, alpha } from 'lib'

import { useWidgetContext } from '../../GridLayout'
import { type WidgetHeaderProps } from '../WidgetHeader'
import { InfoTitle } from '../styled'
import { checkWidgetDimensions } from '../useCollapseWidget'
export const Separator = styled('div')`
    position: absolute;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, 0%);
    width: calc(100% - 15px);
    height: 1px;
    background: ${({ theme }) => alpha(theme.palette.text.main, 0.12)};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 15px;
`

const WidgetBodySeparator = ({ title }: { title?: WidgetHeaderProps['separator'] }) => {
    const dimension = useWidgetContext()
    if (!title || checkWidgetDimensions(dimension, ['sm', 'mdX'])) {
        return null
    }
    return (
        <Separator>
            <InfoTitle
                variant="chartLabel"
                color="text.secondary"
                className="WidgetBaseInfoTitle"
            >
                {title}
            </InfoTitle>
        </Separator>
    )
}
export default WidgetBodySeparator
