import { type FC } from 'react'

import { TextInput } from 'components'
import { maxLengthValidation } from 'core'

import { addressFields } from '../fields'

interface Props {
    disabled?: boolean
}

const CityInput: FC<Props> = ({ disabled }) => {
    return (
        <TextInput
            source={addressFields.city.source}
            label={addressFields.city.label}
            validate={maxLengthValidation}
            disabled={disabled}
        />
    )
}

export default CityInput
