import { type FC } from 'react'

import { useArchivedContext } from 'context'
import { removeKey, useResource } from 'core'
import { Switch, FormControlLabel } from 'ui'

const ArchivedSwitcher: FC = () => {
    const { setIsArchived, isArchived } = useArchivedContext()
    const resource = useResource()

    return (
        <FormControlLabel
            sx={{ m: '0px' }}
            control={<Switch />}
            label="Archived"
            checked={isArchived}
            onChange={(e) => {
                removeKey(resource.resource)
                setIsArchived((e.target as HTMLInputElement).checked)
            }}
        />
    )
}

export default ArchivedSwitcher
