import { type FC } from 'react'

import { qtyMaskResolver } from 'components'
import { Typography } from 'ui'

import { type InventoryItem } from '../types'

const source = 'qtyThreshold' as keyof InventoryItem
const label = 'QTY Threshold'

const Value: FC<{ record: Pick<InventoryItem, 'minQuantity' | 'maxQuantity'> }> = ({ record }) => {
    const min = record.minQuantity
    const max = record.maxQuantity

    return (
        <Typography
            variant="inherit"
            color="inherit"
            component="span"
            sx={{
                whiteSpace: 'pre !important',
            }}
        >
            Min {min ? qtyMaskResolver(min) : '-'}
            {`   `}Max {max ? qtyMaskResolver(max) : '-'}
        </Typography>
    )
}

export const qtyThreshold = {
    source,
    label,
    Value,
}
