import { type FC } from 'react'

import { alpha, styled } from 'lib'
import {
    Tooltip,
    type TooltipProps,
    type BoxProps,
    Stack,
    BoxContainer,
    StyledElement,
    Typography,
} from 'ui'

const StyledTooltipBox = styled(BoxContainer)`
    border-radius: 4px;
    background: ${({ theme }) => alpha(theme.palette.text.primary, 0.9)};
    padding: 4px 8px;
    width: max-content;
    max-width: 180px;
    ${({ theme }) => `
        ${theme.breakpoints.up('md')} {
        max-width: 195px;
    `}
    ${({ theme }) => `
        ${theme.breakpoints.up('lg')} {
        max-width: 215px;
    `}
    ${({ theme }) => `
        ${theme.breakpoints.up('xl')} {
        max-width: none;
    `}
`
interface TooltipBoxProps extends Omit<BoxProps, 'id' | 'className'> {}
export const TooltipBox = (props: TooltipBoxProps) => {
    setTimeout(() => {
        const tooltip = document.getElementById('pie-tooltip')
        if (tooltip) {
            tooltip.classList.remove('hide')
        }
    }, 100)
    return (
        <StyledElement
            sx={{
                '& .hide': {
                    opacity: '0',
                    padding: 0,
                },
            }}
        >
            <StyledTooltipBox
                id="pie-tooltip"
                className="hide"
                {...props}
            />
        </StyledElement>
    )
}
// To-Do: Make generic for all widgets
interface WidgetPieTooltipProps extends Omit<TooltipProps, 'title'> {
    title: TooltipProps['title'] | (string | number)[]
    containerProps?: BoxProps
}

interface CustomTooltipTitleProps {
    title: WidgetPieTooltipProps['title']
    containerProps?: WidgetPieTooltipProps['containerProps']
}

const CustomTooltipTitle: FC<CustomTooltipTitleProps> = ({ title, containerProps }) => {
    if (!Array.isArray(title)) {
        return (
            <Typography
                variant="tooltip"
                color="white"
            >
                {title}
            </Typography>
        )
    }
    return (
        <Stack {...containerProps}>
            {title.map((str, i) => (
                <Typography
                    variant="tooltip"
                    color="white"
                    key={i}
                >
                    {str}
                </Typography>
            ))}
        </Stack>
    )
}

const WidgetTooltip: FC<WidgetPieTooltipProps> = ({
    className,
    title,
    containerProps,
    ...props
}) => {
    return (
        <Tooltip
            {...props}
            title={
                <CustomTooltipTitle
                    title={title}
                    containerProps={containerProps}
                />
            }
        />
    )
}
// backgroundColor: alpha(theme.palette.text.main, 0.9),
export default WidgetTooltip
