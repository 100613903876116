import { type DataRecord } from 'appTypes'
import { Header, InnerHeader, ListTotalBadge, type ListTotalBadgeProps } from 'components'

interface ReportsHeaderProps<RecordType extends DataRecord = DataRecord> {
    children: string
    renderTotal?: ListTotalBadgeProps<RecordType>['renderContent']
}

const ReportsHeader = <RecordType extends DataRecord = DataRecord>({
    children,
    renderTotal,
}: ReportsHeaderProps<RecordType>) => {
    return (
        <InnerHeader>
            <Header.Info>
                <Header.Content>
                    <Header.Title>{children}</Header.Title>
                </Header.Content>
                <Header.Content mobilePlacement="bottom">
                    <ListTotalBadge renderContent={renderTotal} />
                </Header.Content>
            </Header.Info>
        </InnerHeader>
    )
}

export default ReportsHeader
