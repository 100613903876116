import { styled } from 'lib'

export const CropperCardWrapper = styled('div')`
    width: 432px;
    min-height: 424px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    background: white;
`
export const CropperCroppingArea = styled('div')`
    position: relative;
    height: 300px;
    width: 100%;
    margin-bottom: 6px;
`
