import { type FC, type ReactElement, type ReactNode } from 'react'

import { useMediaQuery, type Breakpoint } from 'lib'

interface HideOnBreakpointProps {
    direction: 'up' | 'down'
    breakpoint: Breakpoint
    children: ReactNode
    replaceWith: ReactElement
    render: (match: boolean) => ReactElement
}

const HideOnBreakpoint: FC<Partial<HideOnBreakpointProps>> = ({
    direction = 'up',
    breakpoint = 'sm',
    children,
    replaceWith = null,
    render,
}) => {
    const match: boolean = useMediaQuery((theme) => theme.breakpoints[direction!](breakpoint))

    if (render) {
        return render(match)
    }

    if (!match) {
        return replaceWith
    }

    return <>{children}</>
}

export default HideOnBreakpoint
