import { formatDate } from 'lib'
import { type MemberModel } from 'resourcesBase'

interface Data extends Pick<MemberModel, 'lastLogin'> {}

const value = (data: Data) => {
    return formatDate(data.lastLogin, (dateFormats) => dateFormats.shortenedDateTime)
}

const source = 'lastLogin' as const satisfies keyof MemberModel

const lastLogin = {
    source,
    label: 'Last Login',
    value,
}

export default lastLogin
