import { type FC, type ReactElement } from 'react'

import { useListContext } from 'react-admin'

import ListFTU, { type ListFTUProps } from './ListFTU'
import ListFilterNoResults from './filter/ListFilterNoResults'
import { excludedFilterNames } from './utils'

export interface RenderListProps {
    listFTUProps?: ListFTUProps
    children: ReactElement
    excludeFields?: string[]
    disableFTUScreen?: boolean
    renderNoResults?: () => ReactElement
    loading?: boolean
}

const RenderList: FC<RenderListProps> = ({
    listFTUProps,
    children,
    excludeFields,
    renderNoResults,
    loading,
}) => {
    const { filterValues, total, isLoading } = useListContext()

    if (isLoading || total || loading) {
        return children
    }

    if (renderNoResults) {
        return renderNoResults()
    }

    const appliedFiltersCount = filterValues ? Object.keys(filterValues).length : 0

    if (appliedFiltersCount) {
        const appliedExcludedFilters = (excludeFields ? excludeFields : excludedFilterNames).filter(
            (source) => filterValues[source],
        )
        return (
            <ListFilterNoResults
                disableFilter={appliedExcludedFilters.length === appliedFiltersCount}
            />
        )
    }

    return <ListFTU {...listFTUProps} />
}

export default RenderList
