import { type ReactNode, type FC } from 'react'

import { ListContextProvider, ResourceContextProvider, type ListControllerProps } from 'react-admin'

import { type DataRecord } from 'appTypes'
import { useResourcePreferences } from 'core/context'
import { useListController } from 'core/controllers'

import ListPurify from './ListPurify'
import { useListView, ListViewMode } from './ListViewContext'

interface Props extends ListControllerProps {
    isLoading?: boolean
    children: ReactNode
}

const ListBaseViewMode: FC<Props> = ({ children, isLoading, queryOptions, ...rest }: Props) => {
    const { viewMode } = useListView()
    const preferences = useResourcePreferences()
    const listPerPage = preferences.value.perPage || 10

    return (
        <ListBase
            {...rest}
            queryOptions={{
                enabled: !isLoading,
                ...queryOptions,
            }}
            {...(viewMode === ListViewMode.list
                ? { perPage: listPerPage }
                : { page: 1, perPage: 100 })}
        >
            {children}
            <ListPurify perPage={listPerPage} />
        </ListBase>
    )
}

export default ListBaseViewMode

const ListBase = <RecordType extends DataRecord = any>({
    children,
    ...props
}: ListControllerProps<RecordType> & { children: ReactNode }) => (
    <ResourceContextProvider value={props.resource}>
        <ListContextProvider value={useListController<RecordType>(props)}>
            {children}
        </ListContextProvider>
    </ResourceContextProvider>
)
