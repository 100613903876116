import images from 'assets/images'

import DVIRPageBase from './DVIRPageBase'

const DVIRInProgress = ({ providerName }: { providerName: string }) => {
    return (
        <DVIRPageBase
            image={images.image2}
            title="Work in Progress"
            subtitle={
                <>
                    {providerName} DVIR integration coming
                    <br />
                    in the near future
                </>
            }
        />
    )
}

export default DVIRInProgress
