import { type DataRecord } from 'appTypes'
import { type CreateCustomProps } from 'components/resource/Create'

import { useUtilityDrawerContext } from './UtilityDrawerContext'
import UtilityDrawerCreate, { type UtilityDrawerCreateCustomProps } from './UtilityDrawerCreate'
import UtilityDrawerEdit, { type UtilityDrawerEditCustomProps } from './UtilityDrawerEdit'

export interface UtilityDrawerExtraProps {
    redirect?: CreateCustomProps['redirect']
}

export type UtilityDrawerEditorProps<RecordType extends DataRecord = any> = (
    | UtilityDrawerEditCustomProps<RecordType>
    | UtilityDrawerCreateCustomProps<RecordType>
) &
    UtilityDrawerExtraProps & {
        editorType?: 'edit' | 'create'
    }

const UtilityDrawerEditor = <RecordType extends DataRecord = any>({
    editorType,
    ...props
}: UtilityDrawerEditorProps<RecordType>) => {
    const { extraArgs = {} } = useUtilityDrawerContext()
    const Component =
        (editorType && editorType === 'edit') ?? extraArgs.id
            ? UtilityDrawerEdit
            : UtilityDrawerCreate

    return <Component {...props} />
}

export default UtilityDrawerEditor
