import { type FC } from 'react'

import { formatMoney } from 'components'
import { globalClassNames } from 'lib'
import { WidgetListContainer } from 'pages/Dashboard/components/WidgetBase/styled'
import LineItemsTooltip from 'pages/WorkOrders/Jobs/WorkOrderJobCard/components/LineItemsTooltip'
import { Box, BoxContainer, Status, Typography } from 'ui'

import { statusColors } from '../../CostPerCategoryWidget/types'
import { ListItem, WidgetListDescription } from '../../components'
import { type listType } from '../types'

interface TopCategoriesProps {
    list: listType
    description: string
}
const TopCategories: FC<TopCategoriesProps> = ({ list, description }) => {
    const { data } = list
    const isValid = data.length > 0 ? data.find((obj) => typeof obj.total === 'number') : false

    if (!isValid) {
        return <WidgetListDescription>{description}</WidgetListDescription>
    }

    const List = data.flatMap(({ id, description, total, code, ...ratioData }, i) => {
        if (total == null) {
            return null
        }
        return (
            <Typography
                component={ListItem}
                variant="chartListItem"
                key={`${description}-${i}`}
            >
                <BoxContainer
                    sx={{
                        pr: '10px',
                    }}
                    className={globalClassNames.ellipsis}
                >
                    <Box marginRight="13px">
                        <Status
                            size="8px"
                            iconColor={(theme) => theme.palette.charts[statusColors[i]]}
                        />
                    </Box>
                    <Box
                        component="span"
                        className={globalClassNames.ellipsis}
                        sx={{ lineHeight: '150%' }}
                    >
                        {`${code.substring(1, 2)} ${description}`}
                    </Box>
                </BoxContainer>

                <LineItemsTooltip data={ratioData}>{formatMoney(total)}</LineItemsTooltip>
            </Typography>
        )
    })
    return <WidgetListContainer>{List}</WidgetListContainer>
}
export default TopCategories
