import { Typography } from 'ui'

const EquipmentBodyTitle = () => {
    return (
        <Typography
            variant="inherit"
            fontWeight="inherit"
            fontSize="inherit"
            color="inherit"
            component="span"
        >
            Body
            <Typography
                variant="inherit"
                color="text.secondary"
                fontSize="inherit"
                ml="5px"
                fontWeight="400"
                component="span"
            >
                • Equipment-specific
            </Typography>
        </Typography>
    )
}

export default EquipmentBodyTitle
