import { createContext, type FC, useContext, useMemo } from 'react'

import { inject } from 'mobx-react'

import { type WithChildrenNode } from 'appTypes'
import { type AuthStore, useResourceData } from 'core'
import { type UserPreferences } from 'resourcesBase'

interface ResourcePreferencesContextValue {
    value: UserPreferences['resources']['']
    updateLocal: <
        Key extends keyof UserPreferences['resources'][''],
        Value extends UserPreferences['resources'][''][Key],
    >(
        key: Key,
        value: Value,
    ) => void
    syncLocal: () => void
    update: (data: Partial<UserPreferences['resources']['']>) => void
}

const ResourcePreferencesContext = createContext<ResourcePreferencesContextValue>(null)

interface Props extends WithChildrenNode {
    resource?: string
}

export const ResourcePreferencesProvider: FC<Props> = inject('auth')(({
    resource,
    children,
    auth,
}: Props & { auth: AuthStore }) => {
    const { name } = useResourceData()
    const resourceName = resource || name

    const value = useMemo<ResourcePreferencesContextValue>(() => {
        return {
            updateLocal: (key, value) =>
                auth.updateLocalUserPreferencesByResource(resourceName, key, value),
            syncLocal: () => auth.syncPreferences(),
            update: (data) => auth.updatePreferences({ resources: { [resourceName]: data } }),
            get value() {
                return auth.getUserPreferencesByResource(resourceName)
            },
        }
    }, [resourceName])

    return (
        <ResourcePreferencesContext.Provider value={value}>
            {children}
        </ResourcePreferencesContext.Provider>
    )
})

export const useResourcePreferences = () => {
    return useContext(ResourcePreferencesContext)
}
