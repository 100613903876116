import { type FC, useEffect } from 'react'

import { useFormContext } from 'react-hook-form'

import { type Identifier } from 'appTypes'
import { costMaskParams, TextareaInput, TextInput, useUtilityDrawerContext } from 'components'
import { requiredValidation, validateMaxLength } from 'core'
import { type InvoicePartModel } from 'resources/invoices'
import { LineItemTypeKeys } from 'resources/lineItems'
import { PartInput } from 'resources/parts'
import { CK33Input, CK34Input } from 'resources/vmrs'
import { GridContainerColumns, GridItem, SectionTitleSmall } from 'ui'

import { getSuggestedPrice } from '../utils'

import { type InvoicePartExtra } from './InvoicePartDrawer'

export interface PartFormProps {
    isDisabled?: (source: keyof (InvoicePartModel & { markup: number })) => boolean
    isHidden?: (source: keyof InvoicePartModel) => boolean
}

const defaultDisabled = () => false

const qtyAndCostValidator = [validateMaxLength(10, 'Invalid value'), requiredValidation]
const markupValidation = [validateMaxLength(10, 'Invalid value')]

const PartForm = ({ isDisabled = defaultDisabled, isHidden }: PartFormProps) => {
    const { setValue } = useFormContext()
    const { extra } = useUtilityDrawerContext()
    const { invoice } = extra as InvoicePartExtra

    return (
        <>
            <SectionTitleSmall>Basic Details</SectionTitleSmall>

            <PartInput
                contextType="invoice-items"
                contextId={invoice.id}
                type="inventory"
                disabled={isDisabled('part')}
                onSelectedChange={({ selected }) => {
                    setValue('description', selected?.description || '')
                    setValue('cost', selected?.cost ?? 0)
                    setValue('unitOfMeasure', selected?.unitOfMeasure || '')
                    setValue('manufacturer', selected?.manufacturer || '')
                    setValue('component', selected?.component || '')
                }}
            />

            <TextareaInput
                source="description"
                label="Description"
                disabled
            />

            <CK33Input
                source="component"
                disabled
            />

            <CK34Input
                label="Manufacturer"
                disabled
                source="manufacturer"
            />

            <GridContainerColumns>
                <GridItem xs={6}>
                    <TextInput
                        source="unitOfMeasure"
                        label="UOM"
                        disabled
                    />
                </GridItem>
            </GridContainerColumns>

            <TextInput
                source="cost"
                label="Average Cost"
                {...costMaskParams}
                disabled
            />

            <PriceInput disabled={isDisabled('price')} />

            <MarkupInput disabled={isDisabled('markup')} />

            {isHidden?.('orderQuantity') ? null : (
                <TextInput
                    source="orderQuantity"
                    label="Work Order Quantity"
                    disabled
                />
            )}

            <TextInput
                source="quantity"
                label="Quantity"
                disabled={isDisabled('quantity')}
                validate={qtyAndCostValidator}
            />

            <DefaultPriceHandler id={invoice.id} />
        </>
    )
}

export default PartForm

const DefaultPriceHandler: FC<{ id: Identifier }> = ({ id }) => {
    const { watch, getValues, setValue, formState } = useFormContext()
    const part = getValues('part') || watch('part')

    useEffect(() => {
        if (!formState.touchedFields.part) {
            return
        }

        const setPrice = async () => {
            const price = await getSuggestedPrice(id, {
                type: LineItemTypeKeys.PART,
                part,
            })
            setValue('price', price.sellingPrice)
        }
        if (part) {
            setPrice()
        }
    }, [part])
    return null
}

const MarkupInput = ({ disabled }: { disabled: boolean }) => {
    const { watch, getValues, setValue } = useFormContext()
    const cost = watch('partData.cost') || getValues('partData.cost')
    const price = watch('price') || getValues('price')
    return (
        <TextInput
            source="markup"
            label="Markup/Margin"
            {...costMaskParams}
            disabled={disabled}
            defaultValue={Number(price) + Number(cost || 0)}
            onChange={(event) => {
                if (cost) {
                    setValue('price', Number(event.target.value) + Number(cost))
                }
            }}
            validate={markupValidation}
        />
    )
}

const PriceInput = ({ disabled }: { disabled: boolean }) => {
    const { watch, getValues, setValue } = useFormContext()
    const cost = watch('partData.cost') || getValues('partData.cost')

    return (
        <TextInput
            source="price"
            label="Selling Price"
            {...costMaskParams}
            disabled={disabled}
            onChange={(event) => {
                if (cost) {
                    setValue('markup', Number(event.target.value) - Number(cost))
                }
            }}
            validate={qtyAndCostValidator}
        />
    )
}
