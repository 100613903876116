import { ResourceWithClearEffect } from 'core'
import { woResource } from 'resources/workOrders'

import WorkOrdersList from './List'
import WorkOrderShow from './Show'

const WorkOrders = () => {
    return (
        <ResourceWithClearEffect
            name={woResource}
            list={WorkOrdersList}
            edit={WorkOrderShow}
        />
    )
}

export default WorkOrders
