import { type NoInfer } from 'appTypes'
import {
    ListFilterRangeInput,
    costMaskParams,
    formatMoney,
    type Column,
    type ListFilterChoice,
} from 'components'

import { type UnitModel } from '../types'

const source = 'total' satisfies keyof UnitModel

const label = 'Maintenance Cost'

const tableColumn = <Source extends string = typeof source>({
    id,
    dataToValue,
}: {
    id?: Source
    dataToValue: (data) => UnitModel[typeof source]
}) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: label,
        renderCell: ({ row }) => formatMoney(dataToValue(row)),
        align: 'right',
    }) as const satisfies Column

const sort = <Source extends string = typeof source>({ id }: { id?: Source } = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label,
    }) as const

const filter = <Source extends string = typeof source>({ id }: { id?: Source } = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label,
        filterType: 'range',
        renderComponent: (props) => (
            <ListFilterRangeInput
                inputProps={costMaskParams}
                {...props}
            />
        ),
    }) as const satisfies ListFilterChoice

export const total = {
    source,
    label,
    tableColumn,
    sort,
    filter,
}
