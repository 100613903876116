import { type NoInfer } from 'appTypes'
import { type Column, type DataListDetails, type ListFilterChoice, type SortBy } from 'components'
import { type CountryModel } from 'resourcesBase'

import { CountryRow } from '../component/CountryRow'

const source = 'country' as const
const label = 'Country'

const value = (record: CountryModel) => {
    if (!record) {
        return null
    }

    return <CountryRow record={record} />
}

const filter = <Source extends string = typeof source>({ id }: { id?: Source } = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label,
    }) as const satisfies ListFilterChoice

const sort = <Source extends string = typeof source>({ id }: { id?: Source } = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label,
    }) as const satisfies SortBy

const tableColumn = <Source extends string = typeof source>({
    id,
    dataToRecord,
}: {
    id?: Source
    dataToRecord: (data) => CountryModel
}) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: label,
        renderCell: ({ row }) => value(dataToRecord(row)),
    }) as const satisfies Column

const dataCardRow = <Source extends string = typeof source>({
    id,
    dataToRecord,
}: {
    id?: Source
    dataToRecord: (data) => CountryModel
}) =>
    ({
        source: (id || source) as NoInfer<Source>,
        label,
        render: (_, data) => value(dataToRecord(data)),
    }) as const satisfies DataListDetails<any, any>

const self = {
    source,
    label,
    value,
    filter,
    sort,
    tableColumn,
    dataCardRow,
}

export default self
