import { InfoCard, InfoCardDetails, InfoCardHeader } from 'components'
import { customerFields, type CustomerModel } from 'resources/customers'
import { displayPaymentMethod } from 'resources/paymentMethods'
import { displayPaymentTerm } from 'resources/paymentTerms'

export const FinancialInfoCard = () => {
    return (
        <InfoCard>
            <InfoCardHeader title="Financial Information" />
            <InfoCardDetails<CustomerModel>
                details={[
                    {
                        label: customerFields.taxId.label,
                        source: customerFields.taxId.source,
                    },
                    {
                        label: customerFields.paymentMethod.label,
                        source: (record) => displayPaymentMethod(record.paymentMethod),
                    },
                    {
                        label: customerFields.paymentTerm.label,
                        source: (record) => displayPaymentTerm(record.paymentTermData),
                    },
                ]}
            />
        </InfoCard>
    )
}
