import { useRef } from 'react'

import { useListContext } from 'react-admin'

const useFirstListLoad = (loading: boolean) => {
    const isLoading = useListContext().isLoading
    const firstLoad = useRef(isLoading)
    const finalLoading = loading || isLoading

    if (!finalLoading) {
        firstLoad.current = false
    }

    return firstLoad.current
}

export default useFirstListLoad
