import { type FC, type ReactNode } from 'react'

import { Typography } from 'ui'

interface Props {
    children: ReactNode
}

const InfoCardTitleContainer: FC<Props> = ({ children }) => {
    return (
        <Typography
            color="inherit"
            variant="subtitle2"
            display="flex"
            alignItems="center"
            component="div"
            gap="3px"
        >
            {children}
        </Typography>
    )
}

export default InfoCardTitleContainer
