import { type FieldArrayWithId, useFieldArray, useFormContext } from 'react-hook-form'

import { ArrayControllerContextProviderBase } from 'components/ArrayController/ArrayControllerBase'

import { type PmModel } from '../../types'

import UnitPMFormMeters from './UnitPMFormMeters'

export type PMIntervalRow = FieldArrayWithId<Omit<PmModel, 'workOrderData'>, 'intervals', 'id'>

const UnitPMFormIntervals = () => {
    const { control } = useFormContext<Omit<PmModel, 'workOrderData'>>()

    const fields = useFieldArray({
        control,
        name: 'intervals',
    })

    return (
        <ArrayControllerContextProviderBase<PMIntervalRow>
            value={{
                array: fields.fields,
                append: (item) => {
                    fields.append(item, { shouldFocus: false })
                    return item
                },
                remove: ({ index }) => {
                    fields.remove(index)
                },
                setArray: () => {
                    //
                },
                limit: 2,
            }}
        >
            <UnitPMFormMeters />
        </ArrayControllerContextProviderBase>
    )
}

export default UnitPMFormIntervals
