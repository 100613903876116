import { type FC } from 'react'

import Icons from 'assets/icons'
import { SelectorOption, DialogSelector } from 'components'
import { AddressSelectOption } from 'resources/address'
import { TagsInSelector } from 'resources/tags'
import { Button } from 'ui'

import customerFields from '../dataConfig'
import { type CustomerModel } from '../types'
import { customersResource } from '../utils'

import { CustomersDrawerToggler } from './CustomersDrawerToggler'

interface CustomerInputProps {
    disabled?: boolean
    required?: boolean
    contextId?: string
    source?: string
    label?: string
}

export const CustomerInput: FC<CustomerInputProps> = ({
    source = 'customer',
    label = 'Customer',
    disabled,
    required,
    contextId,
}) => {
    return (
        <>
            <DialogSelector<CustomerModel>
                reference={customersResource.resource}
                source={source}
                defaultSelectorProps={{
                    label,
                }}
                disabled={disabled}
                required={required}
                contextId={contextId}
                defaultSelectorValueSource={({ selected }) => selected?.name}
                titleSource={() => 'Customers'}
                renderItem={renderOption}
                perPage={100}
                renderNextToResultCount={({ control, onSelect }) => (
                    <CustomersDrawerToggler
                        onSuccess={(record) => {
                            onSelect(record.id)
                            control.refetch()
                        }}
                        shortSuccessMessage
                    >
                        {(open) =>
                            control.isFetching ? null : (
                                <Button
                                    startIcon={<Icons.Add />}
                                    variant="contained"
                                    onClick={open}
                                >
                                    Create Customer
                                </Button>
                            )
                        }
                    </CustomersDrawerToggler>
                )}
                noResults={({ searchValue }) => {
                    if (!searchValue) {
                        return {
                            title: 'No Customers Added',
                            text: '',
                            image: (images) => images.listEmpty,
                            imageWidth: '180px',
                        }
                    }
                }}
            />
        </>
    )
}

const renderOption = (record: CustomerModel) => (
    <SelectorOption>
        <SelectorOption.Title avatar={<customerFields.avatar.Value record={record} />}>
            {record.name}
        </SelectorOption.Title>
        <SelectorOption.Details>
            <AddressSelectOption record={record} />
        </SelectorOption.Details>
        <TagsInSelector tags={record.tagsData} />
    </SelectorOption>
)
