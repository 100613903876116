import { inject, observer } from 'mobx-react'
import { useRecordContext } from 'react-admin'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import { SliderView, ViewHeader } from 'components'
import { type AuthStore } from 'core'
import { useInventoryContext } from 'resources/inventory'
import {
    PartDrawerToggler,
    PartDetailsCard,
    type PartModel,
    partCardDetails,
    PartSliderSkeleton,
} from 'resources/parts'
import { TagsCard } from 'resources/tags'
import { IconBox, Section } from 'ui'

const PartEditOpenDrawerButton = ({ id }: { id?: Identifier }) => {
    return (
        <PartDrawerToggler
            id={id}
            children={({ onClick }) => (
                <IconBox
                    title="Edit"
                    onClick={onClick}
                >
                    <Icons.Edit />
                </IconBox>
            )}
        />
    )
}
const skeletonSlides = new Array(2).fill(0).map((v, i) => <PartSliderSkeleton key={i} />)
const PartDetails = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => {
        const record = useRecordContext<PartModel>()
        const inventoryView = useInventoryContext()
        const slides = record
            ? [
                  <PartDetailsCard
                      details={[
                          partCardDetails.componentData,
                          partCardDetails.manufacturerData,
                          partCardDetails.manufacturerPartNumber,
                          partCardDetails.unitOfMeasure,
                          partCardDetails.universalProductCode,
                          partCardDetails.positionApplicable,
                          partCardDetails.serializedPart,
                          auth.companySettings.hasInventory ? null : partCardDetails.cost,
                      ]}
                  />,
                  <PartDrawerToggler
                      id={record.id}
                      children={({ onClick }) => (
                          <TagsCard
                              create={inventoryView ? false : onClick}
                              tags={record.tagsData}
                          />
                      )}
                  />,
              ]
            : skeletonSlides
        return (
            <Section>
                <ViewHeader
                    title="Details"
                    loading={!record}
                >
                    {inventoryView ? null : (
                        <ViewHeader.Content placement="rightMobile">
                            <PartEditOpenDrawerButton id={record?.id} />
                        </ViewHeader.Content>
                    )}
                </ViewHeader>
                <SliderView items={slides} />
            </Section>
        )
    }),
)

export default PartDetails
