import { type FC, type ReactNode } from 'react'

import { cssVariables, globalClassNames, withColor } from 'lib'
import { textWithIconClasses, Typography } from 'ui'

interface Props {
    value: string | ReactNode
}

const DataCardSubheader: FC<Props> = ({ value }) => {
    return (
        <Typography
            variant="body2"
            component="div"
            color={withColor('text.primary')}
            sx={{
                [cssVariables.gap]: '8px',
                [`.${textWithIconClasses.root}`]: {
                    [cssVariables.gap]: '4px',
                },
            }}
            className={globalClassNames.ellipsis}
        >
            {value}
        </Typography>
    )
}

export default DataCardSubheader
