import { type FC, useState } from 'react'

import Icons from 'assets/icons'
import { getContrastColor, withColor } from 'lib'
import { Typography, Avatar, Box, Button, Stack } from 'ui'

import { Palette } from './Palette'

interface Props {
    onClose: (color?: string) => void
    initialValue?: string
}

export const CustomColor: FC<Props> = ({ onClose, initialValue }) => {
    const [color, setColor] = useState(initialValue || '#000000')

    return (
        <Box
            maxWidth="100%"
            width="335px"
        >
            <Box
                p="24px"
                boxSizing="border-box"
            >
                <Typography
                    variant="subtitle1"
                    mb="12px"
                >
                    Choose Custom Color
                </Typography>
                <Palette
                    color={color}
                    onChange={(value) => setColor(value.toUpperCase())}
                />
                <Stack
                    gap="17px"
                    direction="row"
                    mt="24px"
                    mb="12px"
                    alignItems="center"
                >
                    <Avatar sx={{ bgcolor: color, color: getContrastColor(color) }}>A</Avatar>
                    <Typography
                        variant="body2"
                        color={withColor('text.secondary')}
                    >
                        Hex
                    </Typography>
                    <Typography
                        variant="body2"
                        color={withColor('text.primary')}
                    >
                        {color}
                    </Typography>
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                >
                    <Button
                        size="large"
                        onClick={() => onClose()}
                    >
                        Cancel
                    </Button>

                    <Button
                        variant="contained"
                        startIcon={<Icons.Save />}
                        size="large"
                        onClick={() => onClose(color)}
                    >
                        Save
                    </Button>
                </Stack>
            </Box>
        </Box>
    )
}
