import { type FC } from 'react'

import {
    ReferenceInput as RaReferenceInput,
    ReferenceArrayInput as RaReferenceArrayInput,
    type ReferenceInputProps,
} from 'react-admin'

import { type Identifier } from 'appTypes'
import { useInputState } from 'core/inputs'

import { ReferenceInputMetaContextProvider } from './ReferenceInputMetaContext'

interface Props
    extends Pick<
        ReferenceInputProps,
        | 'reference'
        | 'source'
        | 'filter'
        | 'enableGetChoices'
        | 'perPage'
        | 'sort'
        | 'children'
        | 'queryOptions'
    > {
    disabled?: boolean
    contextType?: string
    contextId?: Identifier
}

export const ReferenceInput: FC<Props> = (props) => {
    const { source, disabled } = useInputState(props.source, { disabled: props.disabled })
    const meta = { ...props.queryOptions?.meta, referenceInput: true }
    return (
        <ReferenceInputMetaContextProvider value={{ meta }}>
            <RaReferenceInput
                {...props}
                source={source}
                filter={{
                    ...props.filter,
                    contextType: props.contextType,
                    contextId: props.contextId,
                }}
                enableGetChoices={(...args) => {
                    if (disabled) {
                        return false
                    }

                    return props.enableGetChoices?.(...args)
                }}
                queryOptions={{
                    ...props.queryOptions,
                    meta,
                }}
            />
        </ReferenceInputMetaContextProvider>
    )
}

export const ReferenceArrayInput: FC<Props> = (props) => {
    const meta = { ...props.queryOptions?.meta, referenceInput: true }
    return (
        <ReferenceInputMetaContextProvider value={{ meta }}>
            <RaReferenceArrayInput
                {...props}
                filter={{
                    ...props.filter,
                    contextType: props.contextType,
                    contextId: props.contextId,
                }}
                queryOptions={{
                    ...props.queryOptions,
                    meta,
                }}
            />
        </ReferenceInputMetaContextProvider>
    )
}
