import { type FC } from 'react'

import { useDistanceLabel } from 'components'
import { reportsUrls, createReportResource } from 'resources/reports'

import WidgetBase, { WidgetSkeleton } from '../../WidgetBase/WidgetBase'
import { useCreateDashboardWidgetLink, useDashboardWidget } from '../../WidgetsForm'
import { Clarification } from '../FleetCostPerDistanceWidget/components'
import { WidgetLineChartBase, WidgetLineChartTooltip } from '../components'

import { DataList } from './components'
import { type URMEResponse } from './types'
import { formatValue } from './utils'

let isValid = false
const URMEWidget: FC = () => {
    const createLink = useCreateDashboardWidgetLink()
    const data = useDashboardWidget<URMEResponse>({
        resource: createReportResource('urme'),
        sideEffect: (data) => {
            isValid = typeof data.fleetAverageUrme === 'number'
            return data
        },
    })

    const distanceLabel = useDistanceLabel({ plural: true })

    if (!data) {
        return <WidgetSkeleton />
    }

    const description = `The URME widget calculates the average ${distanceLabel} run between
        roadside repairs for your entire fleet. It tracks the ${distanceLabel} logged between these events,
        displaying both average and trend. The trendline shows whether fleet
        reliability is improving (${distanceLabel} between events increasing) or declining.`

    const { fleetAverageUrme, changeInPercentsForPeriod, fleetUrmePoints, worstValueUnits } = data

    return (
        <WidgetBase
            headerProps={{
                title: 'Unscheduled Roadside Maintenance Event',
                label: isValid ? formatValue(fleetAverageUrme) : null,
                tooltipText: description,
                clarification: (
                    <Clarification
                        changeForPeriod={changeInPercentsForPeriod}
                        isPositiveColor={changeInPercentsForPeriod > 0}
                    />
                ),
                chart: (
                    <WidgetLineChartBase
                        data={fleetUrmePoints}
                        isValid={isValid}
                        tooltip={Tooltip}
                    />
                ),
            }}
            infoProps={{
                content: (
                    <DataList
                        data={worstValueUnits}
                        description={description}
                    />
                ),
            }}
            separator="Critical Attention Vehicles"
            link={createLink(reportsUrls.urme)}
        />
    )
}
export default URMEWidget

const Tooltip = (props) => {
    return (
        <WidgetLineChartTooltip
            {...props}
            dataFormat={formatValue}
        />
    )
}
