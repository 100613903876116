import { useEffect, useRef, useState } from 'react'

import { useStoreContext } from 'react-admin'
import {
    Gallery as PhsGallery,
    type GalleryProps as PhsGalleryProps,
    useGallery,
} from 'react-photoswipe-gallery'

import { styled } from 'lib'
import { Modal, Portal, Box } from 'ui'

import GalleryDefaultToolbar, { type GalleryToolbarProps } from './GalleryToolbar'

import type PhotoSwipeLightboxStub from 'react-photoswipe-gallery/dist/lightbox-stub'

type GalleryContext = PhotoSwipeLightboxStub['pswp']

const SetOpener = ({
    getOpener,
}: {
    getOpener: (opener: { open: (i: number) => void }) => void
}) => {
    const gallery = useGallery()
    useEffect(() => {
        getOpener(gallery)
    }, [])
    return null
}
const FakeModal = ({ open }: { open: boolean }) => {
    return (
        <Modal
            open={open}
            hideBackdrop
            sx={{
                zIndex: -999,
                width: 0,
                height: 0,
                visibility: 'hidden',
                opacity: 0,
            }}
        >
            <></>
        </Modal>
    )
}
export interface GalleryProps extends PhsGalleryProps, Pick<GalleryToolbarProps, 'onImageDelete'> {
    getOpener?: (opener: { open: (i: number) => void }) => void
    onGalleryStart?: () => void
    onGalleryClose?: () => void
}

const Gallery = ({
    getOpener,
    children,
    options,
    onGalleryClose,
    onGalleryStart,
    onImageDelete,
    ...props
}: GalleryProps) => {
    const galleryContext = useRef<GalleryContext>(null)

    const [open, setOpen] = useState(false)
    const { getItem } = useStoreContext()

    const handleKeyDown = (e) => {
        const modal = getItem('confirm')
        const event = e.originalEvent ? e.originalEvent : e
        if (event.key === 'Escape') {
            if (!modal) {
                galleryContext.current.close()
            }
        }
    }

    return (
        <PhsGallery
            {...props}
            options={{
                clickToCloseNonZoomable: false,
                close: false,
                closeTitle: '',
                zoom: false,
                initialZoomLevel: 'fit',
                maxZoomLevel: 0,
                secondaryZoomLevel: 2,
                escKey: false,
                zoomAnimationDuration: 600,
                ...options,
            }}
            uiElements={[
                {
                    order: 12,
                    ariaLabel: 'Toolbar',
                    appendTo: 'bar',
                    html: '<div id="ps-toolbar" />',
                },
            ]}
            onOpen={(phCtx) => {
                setOpen(true)
                onGalleryStart?.()
                phCtx.on('destroy', () => {
                    setOpen(false)
                    onGalleryClose?.()
                })
                phCtx.on('keydown', handleKeyDown)
                phCtx.element.setAttribute('tabIndex', '1')
                galleryContext.current = phCtx
            }}
        >
            {getOpener ? <SetOpener getOpener={getOpener} /> : null}
            <FakeModal open={open} />
            {open && galleryContext.current ? (
                <Portal containerId="ps-toolbar">
                    <GalleryDefaultToolbar
                        onImageDelete={onImageDelete}
                        galleryContext={galleryContext.current}
                    />
                </Portal>
            ) : null}
            {children}
        </PhsGallery>
    )
}
export const GalleryItemGrid = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'gridHeight',
})<{
    gridHeight?: `${string}px`
}>`
    --auto-grid-min-size: 16rem;
    display: grid;
    grid-template-columns: ${({ gridHeight = '89px' }, ...theme) =>
        `repeat(auto-fill, minmax(${gridHeight}, 1fr))`};
    overflow: hidden;
`
export default Gallery
