import { ResourceWithClearEffect } from 'core'
import { vendorPartsResource } from 'resources/parts'

import VendorPartsList from './List'
import VendorPartShow from './Show'

const VendorParts = () => {
    return (
        <ResourceWithClearEffect
            name={vendorPartsResource}
            list={<VendorPartsList />}
            edit={<VendorPartShow />}
        />
    )
}

export default VendorParts
