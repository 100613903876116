import { type FC } from 'react'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import { formatMoney } from 'components'
import { createResource } from 'core'

import { type PurchaseOrderModel } from './types'

export const poResource = createResource({
    resource: 'purchase-orders',
    name: 'purchase-orders',
})

export const poDraftUrl = (id: Identifier) => `/purchase-orders/${id}/receipts/draft_receipt`

export const poReceiptsName = 'purchase-order-receipts'

const poIcons: { [key in PurchaseOrderModel['type']]: FC } = {
    CREDIT: Icons.CreditPO,
    STANDARD: Icons.ReceiptLongOutlined,
    WORK_ORDER: Icons.WOPurchaseOrder,
}

export const getPOIcon = (type: PurchaseOrderModel['type']) => {
    return poIcons[type || 'STANDARD']
}

export const formatPOTotal = (total: number, featured = true) =>
    formatMoney({ value: total, negativeFormat: 'accounting', featured })
