import images from 'assets/images'
import { formatMoney, InfoCard, InfoCardHeader } from 'components'
import { Link } from 'lib'
import { Stack, Status, Typography, BoxContainer, Img } from 'ui'

const OnOrderCard = ({ total }: { total: number }) => {
    return (
        <InfoCard sx={{ height: '270px', display: 'flex', flexDirection: 'column' }}>
            <InfoCardHeader
                title={
                    <BoxContainer gap="4px">
                        <Status
                            size="8px"
                            iconColor={(theme) => theme.palette.primary.main}
                            sx={{ mx: 0 }}
                        />
                        <Typography
                            color="primary.main"
                            variant="subtitle2"
                            component={Link}
                            to="line-items"
                        >
                            On Order
                        </Typography>
                    </BoxContainer>
                }
            />

            <BoxContainer
                gap="22px"
                height="100%"
            >
                <Img
                    src={images.onOrderCardIcon}
                    sx={{ height: '106px', width: '106px' }}
                />
                <Stack spacing="4px">
                    <Typography
                        variant="h5"
                        color="text.primary"
                    >
                        {formatMoney(total || 0)}
                    </Typography>
                    <Typography
                        variant="body1"
                        color="text.secondary"
                    >
                        Estimated Total
                    </Typography>
                </Stack>
            </BoxContainer>
        </InfoCard>
    )
}
export default OnOrderCard
