import { resolveIntegerSpacedMask } from 'components'
import { formatDate } from 'lib'
import { type IntervalsType, type PmModel } from 'resources/pm'
import { getMeterTypeAdornment } from 'resources/units'
import { Typography, BoxContainer, StyledElement } from 'ui'

interface ServiceSectionProps {
    selectedPM: IntervalsType
    unitPmRecord: PmModel
}

const ServiceSection = ({ selectedPM, unitPmRecord }: ServiceSectionProps) => {
    return (
        <StyledElement sx={{ paddingTop: '12px' }}>
            <BoxContainer justifyContent="space-between">
                <Typography
                    variant="tooltip"
                    color="text.disabled"
                >
                    LAST SERVICE
                </Typography>
                <Typography
                    variant="tooltip"
                    color="text.disabled"
                >
                    NEXT SERVICE
                </Typography>
            </BoxContainer>
            <BoxContainer justifyContent="space-between">
                <Typography
                    variant="body2"
                    color="text.primary"
                >
                    {selectedPM.type === 'TIME' ? (
                        formatDate(
                            unitPmRecord.lastDone,
                            (dateFormats) => dateFormats.shortenedDate,
                        )
                    ) : (
                        <>
                            {resolveIntegerSpacedMask(selectedPM.lastDoneMeterValue)}{' '}
                            {getMeterTypeAdornment(selectedPM.type)}
                        </>
                    )}
                </Typography>
                <Typography
                    variant="body2"
                    color="text.primary"
                >
                    {selectedPM.type === 'TIME' ? (
                        formatDate(selectedPM.overdue, (dateFormats) => dateFormats.shortenedDate)
                    ) : (
                        <>
                            {resolveIntegerSpacedMask(selectedPM.overdue)}{' '}
                            {getMeterTypeAdornment(selectedPM.type)}
                        </>
                    )}
                </Typography>
            </BoxContainer>
        </StyledElement>
    )
}
export default ServiceSection
