import { type FC } from 'react'

import { useCreatePath, useShowContext, useUpdate } from 'react-admin'

import { getRecordPhotos, type PageTabConfig } from 'components'
import { useFinalErrorHandler, useResource, useNotify, updatedMessage } from 'core'
import { GridContainer, PageContent } from 'ui'

import { NoPhotosCard, PhotoTabHeader, PhotoGallery } from './components'
import { maxFileSize } from './utils'

interface Props {
    disableActions?: boolean
}

const GalleryPage: FC<Props> = ({ disableActions }) => {
    const resource = useResource()
    const { record, isFetching } = useShowContext()
    const notify = useNotify()
    const [update] = useUpdate()

    const errorHandler = useFinalErrorHandler()
    const photoErrorHandler = (error: any, id: string) => {
        if (error?.[id]) {
            notify({
                title: error?.[id].message,
                type: 'error',
            })
        } else {
            errorHandler(error)
        }
    }
    const createPath = useCreatePath()
    if (!record && isFetching) {
        return null
    }

    const { files, count } = getRecordPhotos(record)
    const upload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]
        if (!file) {
            return
        }
        const freePhoto = Object.keys(files).find((photoIndex) => {
            return !files[photoIndex]
        })
        const type = file.type
        if (!type.startsWith('image') || type.includes('svg')) {
            notify({ title: 'Invalid file format', type: 'error' })
        } else if (file.size > maxFileSize.size) {
            notify({
                title: maxFileSize.errorMessage,
                type: 'error',
            })
        } else {
            try {
                await update(
                    createPath({ resource: resource.resource, id: record.id, type: 'edit' }),
                    { data: { [freePhoto]: file } },
                    { returnPromise: true },
                ).then(() => {
                    notify(updatedMessage)
                })
            } catch (error) {
                photoErrorHandler(error, freePhoto)
            }
        }
        event.target.value = null
    }
    const deletePhoto = async (id: string) => {
        try {
            await update(
                createPath({ resource: resource.resource, id: record.id, type: 'edit' }),
                { data: { [id]: null } },
                { returnPromise: true },
            )
        } catch (e) {
            photoErrorHandler(e, id)
        }
    }
    return (
        <PageContent>
            <PhotoTabHeader
                photosCount={count}
                upload={disableActions ? undefined : upload}
            />
            <GridContainer>
                {count ? (
                    <PhotoGallery
                        photos={files}
                        deletePhoto={disableActions ? undefined : deletePhoto}
                    />
                ) : (
                    <NoPhotosCard upload={disableActions ? undefined : upload} />
                )}
            </GridContainer>
        </PageContent>
    )
}

export const photosTab = (params?: { elementProps: Props }): PageTabConfig => ({
    value: 'photos',
    label: 'Photos',
    element: <GalleryPage {...params?.elementProps} />,
})
