import { type FC } from 'react'

import images from 'assets/images'
import { NoResultsCard } from 'components'
import { Box, Typography } from 'ui'

import PaymentDrawerToggler from './PaymentDrawerToggler'
import { type CreateDefaultValues } from './types'

interface Props extends CreateDefaultValues {
    readOnly?: boolean
}

const PaymentsSectionEmpty: FC<Props> = ({ readOnly, createDefaultValues }) => {
    return (
        <NoResultsCard
            title="No Items"
            direction="row"
            height="180px"
            heightsm="280px"
            directionsm="column"
            imageSrc={images.noPayments}
            imageProps={{
                alt: 'No Items',
                width: {
                    xs: '122px',
                    sm: '107px',
                },
                mr: {
                    xs: 0,
                    sm: '23px',
                },
            }}
            action={
                readOnly ? null : (
                    <PaymentDrawerToggler defaultValues={createDefaultValues}>
                        {(open) => (
                            <Typography
                                variant="body1"
                                color="primary"
                                component={Box}
                                onClick={open}
                                sx={{ cursor: 'pointer' }}
                            >
                                Add Payment
                            </Typography>
                        )}
                    </PaymentDrawerToggler>
                )
            }
        />
    )
}

export default PaymentsSectionEmpty
