import { type FC } from 'react'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import { InfoCard, InfoCardDetails, InfoCardHeader, formatMoney } from 'components'
import { type Action, deleteOneAction } from 'core'
import { formatDate } from 'lib'
import { createdField } from 'resources/base'
import { displayPaymentMethod } from 'resources/paymentMethods'

import { deletePaymentAction } from '../actions'
import { type PaymentModel } from '../types'

import PaymentCardTitle from './PaymentCardTitle'
import PaymentDrawerToggler from './PaymentDrawerToggler'

interface Props {
    payment: PaymentModel
    index: number
    readOnly?: boolean
}

const PaymentCard: FC<Props> = ({ payment, index, readOnly }) => {
    return (
        <InfoCard>
            <InfoCardHeader
                actions={(_, args) => paymentActions({ id: payment.id, disabled: readOnly }, args)}
                title={
                    <PaymentCardTitle
                        payment={payment}
                        index={index}
                        readOnly={readOnly}
                    />
                }
                titleTypographyProps={{
                    color: 'primary.main',
                }}
            />
            <InfoCardDetails<PaymentModel>
                details={[
                    {
                        label: 'amount paid',
                        source: ({ amount }) => formatMoney(amount),
                    },
                    {
                        label: 'added on',
                        source: (record) => createdField.value(record.created),
                    },
                    {
                        label: 'payment date',
                        source: (record) =>
                            formatDate(record.date, (dateFormats) => dateFormats.shortenedDate),
                    },
                    {
                        label: 'payment method',
                        source: (record) => displayPaymentMethod(record.method),
                    },
                    {
                        label: 'payment number',
                        source: 'number',
                    },
                ]}
                propRecord={payment}
            />
        </InfoCard>
    )
}

export default PaymentCard

interface PaymentActionsProps {
    id: Identifier
    disabled: boolean
}

const paymentActions: Action = ({ id, disabled }: PaymentActionsProps, { children }) => [
    <PaymentDrawerToggler
        key="edit"
        id={id}
        children={(open) => children({ title: 'View/Edit', Icon: Icons.Edit, onClick: open })}
        readOnly={disabled}
    />,
    deleteOneAction({
        children,
        disabled,
        id,
        ...deletePaymentAction(),
    }),
]
