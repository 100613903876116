import { type Identifier } from 'appTypes'
import { AutocompleteInput, enhancedOptionText, useArrayControllerElementContext } from 'components'
import { ReferenceInput, ReferenceInputCreateLayer } from 'core'

import { type ContactModel } from '../types'

import { ContactDrawerToggler } from './ContactDrawerToggler'

const EmailField = ({ resource, id }: { resource: string; id: Identifier }) => {
    const { item } = useArrayControllerElementContext()

    return (
        <ReferenceInput
            source={`email${item}`}
            perPage={20}
            filter={{ email: true }}
            reference={`${resource}/${id}/contacts`}
        >
            <ReferenceInputCreateLayer emptyChoiceIdentifier="email">
                {({ setValue }) => {
                    return (
                        <AutocompleteInput
                            renderCreateOption={(renderOption) => (
                                <ContactDrawerToggler
                                    contactFormProps={{
                                        showAlert: true,
                                    }}
                                    onSuccess={(record: ContactModel) => {
                                        setValue(record.email)
                                    }}
                                    children={({ onClick }) =>
                                        renderOption({
                                            children: '+ Add Contact Person',
                                            onClick,
                                        })
                                    }
                                />
                            )}
                            optionValue="email"
                            optionText={optionText}
                            inputText={inputText}
                            label="Select"
                        />
                    )
                }}
            </ReferenceInputCreateLayer>
        </ReferenceInput>
    )
}

export default EmailField

const optionText = enhancedOptionText<ContactModel>('email', ({ name }) =>
    name ? name : 'Company Email',
)
const inputText = ({ email, name }: ContactModel) => email || name
