import { type ReactElement } from 'react'

import {
    TablePagination,
    Pagination as MuiPagination,
    type TablePaginationProps,
    alpha,
    tablePaginationClasses,
    paginationItemClasses,
} from '@mui/material'
import { type TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions'

import { classes } from 'lib'
import { BoxContainer } from 'ui'

interface SanitizedPaginationProps
    extends Pick<
        TablePaginationActionsProps,
        | 'backIconButtonProps'
        | 'nextIconButtonProps'
        | 'count'
        | 'className'
        | 'getItemAriaLabel'
        | 'onPageChange'
        | 'page'
        | 'rowsPerPage'
        | 'showFirstButton'
        | 'showLastButton'
    > {
    selectProps: object
}
const SanitizedPagination = (props: SanitizedPaginationProps) => {
    const {
        backIconButtonProps,
        nextIconButtonProps,
        onPageChange,
        rowsPerPage,
        selectProps,
        count,
        page,
        ...rest
    } = props

    return (
        <MuiPagination
            page={page + 1}
            sx={{
                [`& .${paginationItemClasses.root}`]: {
                    [`&.${classes.selected}`]: {
                        background: (theme) => alpha(theme.palette.text.main, 0.12),
                    },
                },
            }}
            count={Math.ceil(count / rowsPerPage)}
            {...rest}
            onChange={onPageChange}
        />
    )
}

type ExtendedPaginationProps = TablePaginationProps & {
    leftSide: ReactElement
}

const Pagination = ({ leftSide, ...rest }: ExtendedPaginationProps) => {
    return (
        <BoxContainer
            sx={{
                display: 'flex',
                marginLeft: 'auto',
                [`& .${tablePaginationClasses.select}`]: {
                    paddingBottom: '4px',
                },
            }}
        >
            {leftSide}
            <TablePagination
                {...rest}
                ActionsComponent={SanitizedPagination}
            />
        </BoxContainer>
    )
}
export default Pagination
