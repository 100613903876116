import { useRef, type FC, type ReactNode, useEffect } from 'react'

import { inject, observer } from 'mobx-react'
import { useChoicesContext } from 'react-admin'
import { useFormContext } from 'react-hook-form'

import { AutocompleteInput, type AutocompleteInputProps } from 'components'
import { ReferenceInput, requiredValidation } from 'core'
import { type AuthStore } from 'core/auth'

import ck14Fields from '../fields'
import { ck14Resource } from '../utils'

import type CK14Model from '../types'

interface Props extends Pick<AutocompleteInputProps, 'disabled'> {
    label?: string
    source?: string
    children?: ReactNode
    defaultValueByCode?: string
}

export const CK14Input: FC<Props> = inject('auth')(
    observer(
        ({
            children,
            disabled,
            source = ck14Fields.self.source,
            label = 'Reason for Repair',
            defaultValueByCode,
            auth,
        }: Props & { auth: AuthStore }) => {
            return (
                <ReferenceInput
                    source={source}
                    reference={ck14Resource.resource}
                    disabled={disabled && !defaultValueByCode}
                    filter={
                        disabled && defaultValueByCode ? { code: defaultValueByCode } : undefined
                    }
                    perPage={100}
                >
                    <>
                        <AutocompleteInput
                            optionText={ck14Fields.self.value}
                            groupBy={(option: CK14Model) => option.type}
                            disabled={disabled}
                            label={label}
                            validate={
                                auth.companySettings.ck14Required ? requiredValidation : undefined
                            }
                        />
                        {children}
                        {defaultValueByCode && (
                            <DefaultValueByCode
                                source={source}
                                code={defaultValueByCode}
                            />
                        )}
                    </>
                </ReferenceInput>
            )
        },
    ),
)

const DefaultValueByCode: FC<{ code: string; source: string }> = ({ code, source }) => {
    const choices = useChoicesContext<CK14Model>()
    const didInit = useRef(false)
    const { setValue } = useFormContext()

    useEffect(() => {
        if (!choices.isFetching && !didInit.current) {
            didInit.current = true
            const reasonForRepairValue: CK14Model = choices.allChoices.find(
                (choice: CK14Model) => choice.code === code,
            )
            if (reasonForRepairValue) {
                setValue(source, reasonForRepairValue.id)
            }
        }
    }, [choices.isFetching])

    return null
}
