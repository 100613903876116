import { useCallback, useLayoutEffect, useMemo, useState } from 'react'

const useResizeObserver = (matchPoint: number) => {
    const [match, setMatch] = useState<boolean>(true)
    const observer = useMemo(
        () =>
            new ResizeObserver((entries) => {
                setMatch(entries[0].contentRect.width < matchPoint)
            }),
        [],
    )
    const ref = useCallback((node) => {
        if (node) {
            observer.observe(node)
        }
    }, [])
    useLayoutEffect(() => {
        return () => {
            observer.disconnect()
        }
    }, [])
    return { ref, match }
}
export default useResizeObserver
