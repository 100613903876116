interface AddressDetails {
    address: string
    address2: string
    city: string
    stateData: { name?: string }
    zipCode: string
}

const row1 = (shop: Pick<AddressDetails, 'address' | 'address2'>): string =>
    [shop.address, shop.address2].filter(Boolean).join(', ')

const row2 = (shop: Pick<AddressDetails, 'city' | 'zipCode' | 'stateData'>): string =>
    [shop.city, shop.stateData?.name, shop.zipCode].filter(Boolean).join(', ')

const self = {
    row1,
    row2,
}

export default self
