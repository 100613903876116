import { type Identifier } from 'appTypes'
import { type CK33Model } from 'resources/vmrs'

import { type DateHelpers } from '../../CostReportContext'

import { type ResponseType } from './types'

interface GroupBy<Group> {
    group: Group
    values: ResponseType[]
}

export const groupByDate = (jobs: ResponseType[], dateHelpers: DateHelpers) => {
    const dates = new Map<string, GroupBy<string>>()

    jobs.forEach((job) => {
        const dateSource = dateHelpers.toSource(job.workOrderData.completed)
        if (!dates.has(dateSource)) {
            dates.set(dateSource, { group: dateHelpers.format(dateSource), values: [] })
        }

        dates.get(dateSource).values.push(job)
    })

    return Array.from(dates, ([, values]) => values)
}

export const groupByComponent = (jobs: ResponseType[]) => {
    const components = new Map<Identifier, GroupBy<CK33Model>>()

    jobs.forEach((job) => {
        const component = job.component
        if (!components.has(component)) {
            components.set(component, { group: job.componentData, values: [] })
        }

        components.get(component).values.push(job)
    })

    return Array.from(components, ([key, values]) => values)
}
