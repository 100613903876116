import { type ThemeOptionsConfig } from './types'

const themeTypography = (theme: ThemeOptionsConfig) => ({
    // TODO: fix font family
    fontFamily: 'Roboto',
    subtitle1: {
        fontSize: '16px',
        lineHeight: '1.5',
        letterSpacing: '0.15px',
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
    },
    subtitle2: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '157%',
        letterSpacing: '0.1px',
    },
    tooltip: {
        fontSize: '10px',
        fontWeight: '500',
        lineHeight: 'normal',
        fontFamily: 'Roboto',
    },
    body2: {
        letterSpacing: '0.15px',
        lineHeight: '1.4',
        fontSize: '14px',
        color: theme.palette.grey[400],
    },
    body1: {
        fontSize: '16px',
        lineHeight: '1.5',
        letterSpacing: '0.15px',
        color: theme.palette.text.main,
    },
    caption: {
        fontSize: '12px',
        letterSpacing: '0.4px',
        lineHeight: '1.7',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
    },
    h1: {
        fontSize: '96px',
        lineHeight: '1.2',
        letterSpacing: '-1.5px',
        fontWeight: '300',
        fontStretch: 'normal',
        fontStyle: 'normal',
    },
    h2: {
        fontSize: '60px',
        fontWeight: '300',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.2',
        letterSpacing: '-0.5px',
    },
    h3: {
        fontSize: '48px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.2',
        letterSpacing: 'normal',
    },
    h4: {
        fontSize: '34px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.25px',
    },
    h5: {
        fontSize: '24px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.3',
        letterSpacing: 'normal',
    },
    h6: {
        fontSize: '20px',
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.6',
        letterSpacing: '0.15px',
    },
    'avatar-initials': {
        fontSize: '20px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '20px',
        letterSpacing: '0.14px',
        fontFamily: 'Roboto',
    },
    alertTitle: {
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        color: theme.palette.text.main,
        fontWeight: '500',
        fontFamily: 'roboto',
    },
    inputText: {
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        color: theme.palette.secondary.main,
        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    },
    inputLabel: {
        fontSize: '12px',
        color: theme.palette.secondary.main,
        fontFamily: 'roboto',
        letterSpacing: '0.15px',
        lineHeight: '12px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
    },
    menuItem: {
        fontSize: '16px',
        fontFamily: 'roboto',
        letterSpacing: '0.15px',
        lineHeight: '24px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
    },
    chartTitle: {
        fontSize: '12px',
        fontFamily: 'roboto',
        letterSpacing: '0.1px',
        lineHeight: '100%',
        fontWeight: '500',
        fontStyle: 'normal',
    },
    chartLabel: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '10px',
        lineHeight: '100%',
        letterSpacing: '0.4px',
    },
    chartListItem: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '100%',
        letterSpacing: '0.4px',
    },
    chartListDescription: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '140%',
        letterSpacing: '0.4px',
    },
    componentButtonSmall: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '13px',
        lineHeight: '22px',
        letterSpacing: '0.46px',
    },
    componentButtonMedium: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.4px',
    },
    chartsBody: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '140%',
        letterSpacing: '0.4px',
    },
    helperText: {
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0.4px',
    },
})

export interface ITypography extends ReturnType<typeof themeTypography> {}

export interface TypographyPropOverrides extends Record<keyof ITypography, true> {}

export default themeTypography
