import { type FC } from 'react'

import { useFormState } from 'react-hook-form'

import { Button, type ButtonProps } from 'ui'

export const CancelButton: FC<ButtonProps> = (props) => {
    const { isSubmitting } = useFormState()

    return (
        <Button
            size="medium"
            disabled={isSubmitting}
            type="button"
            children="Cancel"
            {...props}
        />
    )
}
