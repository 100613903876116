import { type FC, useEffect } from 'react'

import { inject, observer } from 'mobx-react'

import { type AuthStore, useLogout } from 'core'
import { Navigate, useParams } from 'lib'

import { type InvitationParams } from './types'

const LogoutInvitation: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const { token } = useParams<InvitationParams>()
        const logout = useLogout()

        useEffect(() => {
            if (auth.user) {
                logout(null)
            }
        }, [])

        if (auth.user) {
            return null
        }

        return (
            <Navigate
                to={{
                    pathname: `/invitations/accept/${token}`,
                }}
            />
        )
    }),
)

export default LogoutInvitation
