import Icons from 'assets/icons'
import { Link } from 'lib'
import { IconBox, StyledElement } from 'ui'

const MonitorArrowButton = ({ navigateTo }: { navigateTo: string }) => {
    const navigateIcon = (
        <IconBox
            sx={{
                width: '16px',
                height: '16px',
                position: 'relative',
            }}
            disabled={!navigateTo}
        >
            <Icons.ArrowForward />
        </IconBox>
    )
    return (
        <StyledElement sx={{ position: 'absolute', right: '20px' }}>
            {navigateTo ? <Link to={navigateTo}>{navigateIcon}</Link> : navigateIcon}
        </StyledElement>
    )
}

export default MonitorArrowButton
