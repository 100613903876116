import Icons from 'assets/icons'
import { Link } from 'lib'
import { Button } from 'ui'
const ConnectButton = ({ navigateTo }: { navigateTo: string }) => {
    return (
        <Button
            startIcon={<Icons.Add />}
            size="small"
            component={Link}
            to={navigateTo}
        >
            Connect
        </Button>
    )
}
export default ConnectButton
