import images from 'assets/images'

import DVIRPageBase from './DVIRPageBase'

const DVIRNotSupported = ({ providerName }: { providerName: string }) => {
    return (
        <DVIRPageBase
            image={images.image2}
            title="Not Supported"
            subtitle={
                <>
                    {providerName} does not support DVIR
                    <br />
                    integrations at this time
                </>
            }
        />
    )
}

export default DVIRNotSupported
