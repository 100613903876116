import { useCreateResourcePath } from 'core/resource'
import { createReportResource } from 'resources/reports'
import { unitResource, unitFields, UnitStatusKeys } from 'resources/units'
import { StatusText } from 'ui'

import { Monitor } from '../MonitorComponents'

import { useMonitor } from './useMonitor'

interface dataType {
    inServiceCount: number
    outOfServiceCount: number
}

const MonitorUnits = () => {
    const createPath = useCreateResourcePath()
    const data = useMonitor<dataType>(createReportResource('units-by-status'))
    const createLink = (status: UnitStatusKeys) => {
        return (
            createPath({ resource: unitResource.resource, type: 'list' }) +
            '?filter=' +
            encodeURIComponent(JSON.stringify({ status: [status] }))
        )
    }
    if (!data) {
        return <Monitor.Skeleton />
    }

    const { inServiceCount, outOfServiceCount } = data
    return (
        <Monitor>
            <Monitor.Title icon={unitFields.avatar.Icon}>Units</Monitor.Title>

            <Monitor.ArrowButton
                navigateTo={createPath({ resource: unitResource.resource, type: 'list' })}
            />
            <Monitor.Container>
                <Monitor.Button navigateTo={createLink(UnitStatusKeys.OUT_OF_SERVICE)}>
                    <Monitor.ValueText>{outOfServiceCount}</Monitor.ValueText>
                    <StatusText statusColor={(theme) => theme.palette.charts.red}>
                        Out Of Service
                    </StatusText>
                </Monitor.Button>
                <Monitor.Button navigateTo={createLink(UnitStatusKeys.IN_SERVICE)}>
                    <Monitor.ValueText>{inServiceCount}</Monitor.ValueText>
                    <StatusText statusColor={(theme) => theme.palette.charts.greenBody}>
                        In Service
                    </StatusText>
                </Monitor.Button>
            </Monitor.Container>
        </Monitor>
    )
}
export default MonitorUnits
