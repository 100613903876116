import { useState } from 'react'

import { useFormContext, useWatch } from 'react-hook-form'

import Icons from 'assets/icons'
import {
    TextInput,
    TextareaInput,
    costMaskParams,
    inputQtyMaskParams,
    useUtilityDrawerContext,
} from 'components'
import { maxLengthValidationText, requiredValidation, validateMaxLength } from 'core'
import { alpha } from 'lib'
import JobLaborPartFormTotal from 'pages/WorkOrders/Jobs/Labor/JobLaborPartFormTotal'
import { UOMInput } from 'resources/unitsOfMeasure'
import { CK33Input, CK34Input, CK79Input } from 'resources/vmrs'
import { type JobPartModel } from 'resources/workOrders'
import {
    BoxContainer,
    IconBox,
    GridItem,
    GridContainerColumns,
    GridBreak,
    SectionTitleSmall,
    Divider,
    Collapse,
} from 'ui'

import { type PartWithoutNumberFormExtra } from './drawerTogglers'

const qtyAndCostValidator = [validateMaxLength(10, 'Invalid value'), requiredValidation]
const descriptionValidation = [maxLengthValidationText]

const MoreDetails = ({
    disabled,
    defaultValues,
}: {
    disabled: boolean
    defaultValues: {
        manufacturerPartNumber: number
    }
}) => {
    const [open, setOpen] = useState(false)

    return (
        <GridItem>
            <Divider sx={{ mb: '28px' }} />

            <BoxContainer
                justifyContent="space-between"
                alignItems="center"
                mb="28px"
            >
                <SectionTitleSmall mb="0px">More Details</SectionTitleSmall>
                <IconBox
                    backgroundColor="transparent"
                    iconColor={(theme) => alpha(theme.palette.text.primary, 0.54)}
                    onClick={() => setOpen((prev) => !prev)}
                >
                    {open ? <Icons.KeyboardArrowUp /> : <Icons.KeyboardArrowDown />}
                </IconBox>
            </BoxContainer>

            <Collapse in={open}>
                <TextInput
                    source="partNumber"
                    label="Part No"
                    disabled={disabled}
                />

                <CK34Input disabled={disabled} />

                <UOMInput disabled={disabled} />

                <TextInput
                    source="manufacturerPartNumber"
                    label="Manufacturer Part Number"
                    defaultValue={defaultValues.manufacturerPartNumber}
                    disabled={disabled}
                />
            </Collapse>

            <Divider sx={{ mb: '28px' }} />
        </GridItem>
    )
}

const DescriptionInput = ({ disabled }: { disabled: boolean }) => {
    const component = useWatch({ name: 'component' })
    return (
        <GridItem>
            <TextareaInput<JobPartModel>
                source="description"
                label="Description"
                disabled={disabled || !component}
                validate={descriptionValidation}
            />
        </GridItem>
    )
}
const PartWithoutNumberDrawerForm = () => {
    const { setValue } = useFormContext()
    const { extra } = useUtilityDrawerContext()
    const { disabledFields: disabled, record } = extra as PartWithoutNumberFormExtra

    return (
        <>
            <GridContainerColumns>
                <GridItem>
                    <CK33Input
                        disabled={disabled}
                        required
                        onSelectedChange={({ selected }) => {
                            setValue('description', selected?.text || '')
                        }}
                    />
                </GridItem>

                <DescriptionInput disabled={disabled} />

                <MoreDetails
                    disabled={disabled}
                    defaultValues={{
                        manufacturerPartNumber: record
                            ? record?.partData?.manufacturerPartNumber
                            : null,
                    }}
                />

                <GridBreak />
                <GridItem xs={6}>
                    <TextInput
                        source="quantity"
                        label="Quantity"
                        validate={qtyAndCostValidator}
                        {...inputQtyMaskParams}
                        disabled={disabled}
                    />
                </GridItem>
                <GridItem xs={6}>
                    <TextInput
                        source="price"
                        label="Cost"
                        validate={qtyAndCostValidator}
                        {...costMaskParams}
                        disabled={disabled}
                    />
                </GridItem>
                <JobLaborPartFormTotal
                    title="Part Total"
                    inputOne="quantity"
                    inputTwo="price"
                />
                <GridItem>
                    <CK79Input disabled={disabled} />
                </GridItem>
            </GridContainerColumns>
        </>
    )
}

export default PartWithoutNumberDrawerForm
