import {
    type CardListConfig,
    type DatagridColumnsProps,
    useDistanceLabel,
    DistanceLabel,
    integerMaskResolver,
    resolveIntegerSpacedMask,
    LinkArrowButton,
} from 'components'
import { DatagridLink } from 'components/Datagrid'
import { type ListSortContentProps } from 'components/list/ListSortDrawerContent'
import { type FilterConfig } from 'components/list/filter/ListFilterForm'
import ListFilterRangeInput from 'components/list/filter/ListFilterRangeInput'
import { urls } from 'configs'
import { ResourceContextProviderWithClearEffect, type SortPayload } from 'core'
import { getListData } from 'core/data'
import { globalClassNames } from 'lib'
import { tagFields } from 'resources/tags'
import {
    ArchivedUnitBadge,
    type UnitAggregates,
    type UnitModel,
    unitResource,
} from 'resources/units'
import unitFields from 'resources/units/fields'
import { ck2Fields, ck34Fields } from 'resources/vmrs'
import { PageContent, BoxContainer, StyledElement } from 'ui'

import { UnitCardTitleLink } from '../Downtime/components'
import { ListBaseWithPeriod, ListWithPeriodSelector, ReportsHeader } from '../components'

export interface URMEModel extends UnitModel {
    distance: number
    engineHours: number
    costPerMeterTotal: number
    urme: number
    urmeDistance: number
    urmeFailures: number
}
const defaultSort: SortPayload<URMEModel> = {
    field: 'urme',
    order: 'ASC',
}

export const navigateToUnit = ({ id }: Pick<UnitModel, 'id'>) => `${urls.units}/${id}`

const URME = () => {
    const distanceLabel = useDistanceLabel({ plural: true, textCase: 'capital' })

    const urmeDistanceSource: keyof URMEModel = 'urmeDistance'
    const urmeDistanceLabel = `${distanceLabel} Traveled`

    const urmeLabel = `URME (${distanceLabel})`

    const columnsCfg: DatagridColumnsProps<URMEModel> = {
        resetColumns: {
            licensePlate: false,
            vin: false,
            vmrsEquipmentCategory: false,
            model: false,
            modelYear: false,
            color: false,
            status: false,
        },
        mainField: unitFields.number.source,
        pinnedColumns: {
            right: ['urme'],
        },
        constantColumns: {
            urme: true,
        },
        checkboxSelection: false,
        columns: [
            {
                field: unitFields.number.source,
                headerName: unitFields.number.label,
                renderCell: (params) => {
                    const { value, ...restParams } = params
                    return (
                        <DatagridLink
                            {...restParams}
                            resource={unitResource.resource}
                            value={
                                <BoxContainer>
                                    <StyledElement className={globalClassNames.ellipsis}>
                                        {value}
                                    </StyledElement>
                                    {params.row.archived && <ArchivedUnitBadge />}
                                </BoxContainer>
                            }
                        />
                    )
                },
            },
            unitFields.name.tableColumn({
                headerName: unitFields.name.longLabel,
                dataToValue: (record) => record.name,
            }),
            {
                field: urmeDistanceSource,
                headerName: urmeDistanceLabel,
                valueFormatter: ({ value }) => integerMaskResolver(value),
            },
            {
                field: 'urmeFailures',
                headerName: 'Maintenance Events',
                valueFormatter: ({ value }) => integerMaskResolver(value),
            },
            {
                field: 'urme',
                headerName: urmeLabel,
                valueFormatter: ({ value }) => integerMaskResolver(value),
            },
            unitFields.licensePlate.tableColumn({
                dataToValue: (record) => record.licensePlate,
            }),
            unitFields.vin.tableColumn({
                dataToValue: (record) => record.vin,
            }),
            {
                field: 'vmrsEquipmentCategory',
                headerName: 'Equipment Category',
                renderCell: ({ row }) => row.vmrsEquipmentCategoryData?.description,
            },
            unitFields.model.tableColumn({
                dataToValue: (record) => record.model,
            }),
            unitFields.modelYear.tableColumn({
                dataToValue: (record) => record.modelYear,
            }),
            unitFields.color.tableColumn({
                dataToValue: (record) => record.color,
            }),
            unitFields.status.tableColumn({ label: unitFields.status.longLabel }),
        ],
        actions: null,
    }

    const cardsCfg: CardListConfig<URMEModel> = {
        titleSource: (record) => <UnitCardTitleLink record={record} />,
        disableTitleLink: true,
        defaultImage: null,
        details: [
            unitFields.name.dataCardRow({
                headerName: unitFields.name.longLabel,
                dataToValue: (record) => record.name,
            }),
            {
                source: urmeDistanceSource,
                label: urmeDistanceLabel,
                render: integerMaskResolver,
            },
            {
                source: 'urmeFailures',
                label: 'Maintenance Events',
                render: integerMaskResolver,
            },
            {
                source: 'urme',
                label: urmeLabel,
                render: integerMaskResolver,
            },
        ],
        action: (record) => <LinkArrowButton path={navigateToUnit(record)} />,
    }
    const sortCfg: ListSortContentProps<URMEModel> = {
        sortBy: [
            {
                id: urmeDistanceSource,
                label: urmeDistanceLabel,
            },
            {
                id: 'urmeFailures',
                label: 'Maintenance Events',
            },
            {
                id: 'urme',
                label: urmeLabel,
            },

            unitFields.number.sort(),
            unitFields.name.sort({ label: unitFields.name.longLabel }),
            unitFields.licensePlate.sort(),
            unitFields.vin.sort(),
            ck2Fields.self.sort({ id: 'vmrsEquipmentCategory' }),
            unitFields.model.sort(),
            unitFields.modelYear.sort(),
            unitFields.color.sort(),
            unitFields.status.sort({ label: unitFields.status.longLabel }),
        ],
    }

    const filtersCfg: FilterConfig<URMEModel> = {
        filters: [
            {
                id: urmeDistanceSource,
                label: urmeDistanceLabel,
                filterType: 'range',
                renderComponent: (props) => (
                    <ListFilterRangeInput
                        integerValuesInput
                        {...props}
                    />
                ),
            },
            {
                id: 'urmeFailures',
                label: 'Maintenance Events',
                filterType: 'range',
                renderComponent: (props) => (
                    <ListFilterRangeInput
                        integerValuesInput
                        {...props}
                    />
                ),
            },
            {
                id: 'urme',
                label: urmeLabel,
                filterType: 'range',
                renderComponent: (props) => (
                    <ListFilterRangeInput
                        integerValuesInput
                        {...props}
                    />
                ),
            },

            unitFields.number.filter(),
            unitFields.name.filter({ label: unitFields.name.longLabel }),
            unitFields.vin.filter(),
            unitFields.licensePlate.filter(),
            unitFields.status.filter({ label: unitFields.status.longLabel }),
            ck2Fields.self.filter({ id: 'vmrsEquipmentCategory', label: 'Equipment Category' }),
            ck34Fields.self.filter({ id: 'vmrsManufacturer', label: 'Manufacturer/Make' }),
            unitFields.model.filter(),
            unitFields.modelYear.filter(),
            ck34Fields.self.filter({ id: 'engineVmrsManufacturer', label: 'Engine Make' }),
            unitFields.engineModel.filter(),
            unitFields.engineHp.filter(),
            ck34Fields.self.filter({
                id: 'transmissionVmrsManufacturer',
                label: 'Transmission Make',
            }),
            unitFields.transmissionModel.filter(),
            unitFields.transmissionGears.filter(),
            unitFields.color.filter(),
            unitFields.tireSize.filter(),
            tagFields.self.filter(),
            { id: 'archived', label: 'Archived Unit' },
        ],
    }

    return (
        <ResourceContextProviderWithClearEffect value={unitResource}>
            <ListBaseWithPeriod
                preferencesName="unscheduled-roadside-maintenance-event"
                sort={defaultSort}
                filter={{ withAspects: ['urme'] }}
            >
                <ReportsHeader<UnitModel>
                    renderTotal={(list) => {
                        const listData = getListData<UnitAggregates>(list)
                        return (
                            <>
                                {resolveIntegerSpacedMask(list.total || 0)} |{' '}
                                {resolveIntegerSpacedMask(listData.urme || 0)}{' '}
                                <DistanceLabel variant="abbr" />
                            </>
                        )
                    }}
                >
                    Unscheduled Roadside Maintenance Event
                </ReportsHeader>
                <PageContent>
                    <ListWithPeriodSelector
                        exportFileName="unscheduled-roadside-maintenance-event"
                        filtersCfg={filtersCfg}
                        sortCfg={sortCfg}
                        columnsCfg={columnsCfg}
                        cardsCfg={cardsCfg}
                    />
                </PageContent>
            </ListBaseWithPeriod>
        </ResourceContextProviderWithClearEffect>
    )
}

export default URME
