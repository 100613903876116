import { type NoInfer } from 'appTypes'
import { ListFilterDateRangeValueInput, type Column, type ListFilterChoice } from 'components'
import { formatDate } from 'lib'

import { type WorkOrderModel } from '../types'

const source = 'completed' satisfies keyof WorkOrderModel

const label = 'Completed on'

const tableColumn = <Source extends string = typeof source>({ id }: { id?: Source }) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: label,
        renderCell: ({ value }) => {
            return formatDate(value, (dateFormats) => dateFormats.shortenedDateTime)
        },
    }) as const satisfies Column

const sort = <Source extends string = typeof source>() =>
    ({
        id: source as NoInfer<Source>,
        label,
    }) as const

const filter = () =>
    ({
        id: source,
        label,
        filterType: 'range',
        renderComponent: (props) => <ListFilterDateRangeValueInput {...props} />,
    }) as const satisfies ListFilterChoice

export const completed = {
    source,
    label,
    tableColumn,
    sort,
    filter,
}
