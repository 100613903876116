import Value, { valueText } from './Value'
import { nameLabel, nameSource } from './name'

const name = {
    source: nameSource,
    label: nameLabel,
    Value,
    valueText,
}

export default name
