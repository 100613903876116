import { useRef } from 'react'

import { useStoreContext } from 'react-admin'

import { removeKey } from 'core/store'
import { useWillUnmount } from 'hooks'

export const useClearResource = (name: string) => {
    const { removeItem } = useStoreContext()

    // removeItem finishes after the next page render
    // this causes the store to be shared (like filters) between pages that uses same resource
    // this is why delete it before the first render
    const didMount = useRef(false)
    if (!didMount.current) {
        didMount.current = true
        removeKey(name)
    }

    useWillUnmount(() => removeItem(name), [name])
}

export const useClearResourceAction = () => {
    const { removeItem } = useStoreContext()

    return (name: string) => removeItem(name)
}
