import { type ReactElement } from 'react'

import { settingsUrls } from 'configs'
import { type ShopModel } from 'resourcesBase'
import { LinkButton } from 'ui'
import { pathJoin } from 'utils'

const source: keyof ShopModel = 'name'
const label = 'Repair Shop Name'

interface Data extends Pick<ShopModel, 'name'> {}

const valueWithLink = (params: Data & Pick<ShopModel, 'id'>): ReactElement => {
    if (!params) {
        return null
    }

    return <LinkButton to={pathJoin(settingsUrls.repairShops, params.id)}>{params.name}</LinkButton>
}

const value = (params: Data): string => {
    if (!params) {
        return ''
    }

    return params.name
}

const name = {
    source,
    label,
    valueWithLink,
    value,
}

export default name
