import { useListContext, useShowContext } from 'react-admin'

import { type DataRecord, type Identifier } from 'appTypes'
import { useOpenUtilityDrawer } from 'components'
import { useResource } from 'core'
import { type UnitModel } from 'resources/units'
import { type JobModel, type WorkOrderModel } from 'resources/workOrders'

import StepsContextProvider from './StepsContextProvider'
import UseWOBackButton from './UseWOBackButton'
import UseWOFormContent from './UseWOFormContent'
import UseWONextButton from './UseWONextButton'
import UseWOTitle from './UseWOTitle'

export interface UseWOParams<ParentType extends DataRecord = any> {
    workOrder?: WorkOrderModel
    parentRecord: ParentType
    options?: {
        jobDisabledFields?: { [key in keyof JobModel]?: boolean }
        jobDefaultValues?: Partial<JobModel>

        woDefaultValues?: Partial<
            WorkOrderModel & {
                vendorWorkOrderSwitch: boolean
                customer: Identifier
                domicile: Identifier
            }
        >
        woDisabledFields?: {
            [key in keyof (WorkOrderModel & {
                vendorWorkOrderSwitch: boolean
                vendorInput: string
            })]?: boolean
        }
    }
}

export interface UseWOExtraState<ParentType extends DataRecord = any> {
    unit: UnitModel
    workOrder?: WorkOrderModel
    parentRecord: ParentType
    options: UseWOParams['options']
}

const useAttachWO = (unitData?: UnitModel) => {
    const open = useOpenUtilityDrawer()
    const { record } = useShowContext<UnitModel>()
    const resource = useResource()
    const listContext = useListContext()

    return ({ workOrder, parentRecord, options }: UseWOParams) => {
        const extra: UseWOExtraState = {
            unit: unitData || record,
            workOrder,
            parentRecord,
            options,
        }

        open({
            drawerArgs: {
                title: <UseWOTitle />,
                renderBottomLeft: () => <UseWOBackButton />,
                renderBottomRight: () => <UseWONextButton />,
                renderContent: () => <UseWOFormContent />,
                renderWrapper: (props) => <StepsContextProvider {...props} />,
            },
            extraArgs: {
                id: workOrder?.id,
                resource,
                listContext,
                type: workOrder ? 'edit' : 'create',
            },
            extra,
        })
    }
}

export default useAttachWO
