import { useState } from 'react'

import Icons from 'assets/icons'
import images from 'assets/images'
import { Card, IconButton, Img, Typography, StyledElement } from 'ui'

const PMRepositionExplanation = () => {
    const [isHidden, setIsHidden] = useState(false)

    if (isHidden) {
        return null
    }

    return (
        <Card
            sx={{ display: 'flex', alignItems: 'flex-start', gap: '20px', p: '20px', mb: '20px' }}
        >
            <Img
                src={images.reposition}
                alt="reposition"
                sx={{
                    width: '48px',
                    height: '132px',
                    mt: '5px',
                }}
            />
            <div>
                <StyledElement
                    sx={{
                        mb: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography variant="subtitle1">What are Dependencies?</Typography>
                    <IconButton
                        aria-label="Close description"
                        onClick={() => setIsHidden(true)}
                        size="small"
                    >
                        <Icons.Close />
                    </IconButton>
                </StyledElement>
                <Typography
                    variant="body2"
                    color="text.primary"
                >
                    Dependencies are connected intervals that rely on each other to be completed.
                    <br />
                    For instance, resetting Dependency on Position 4 will reset all other
                    Dependencies below it (3, 2, 1)
                </Typography>
            </div>
        </Card>
    )
}

export default PMRepositionExplanation
