import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import { SliderView, ViewHeader } from 'components'
import { AddressCard } from 'resources/address'
import { TagsCard } from 'resources/tags'
import { VendorSliderSkeleton, type VendorModel, VendorDrawerToggler } from 'resources/vendors'
import { IconBox, Section } from 'ui'

import { BasicDetails, FinancialInformation } from './components'

const skeletonSlides = new Array(4).fill(<VendorSliderSkeleton />)
const vendorFormEditProps = (record: VendorModel) => ({
    typeInputProps: {
        helperText: 'Open orders may disable some options',
        choices: (choices) =>
            choices.map((choice) => {
                if (choice.id === 'REPAIR_SERVICES' && record?.hasOpenWorkOrders) {
                    return {
                        ...choice,
                        disabled: true,
                    }
                }
                if (choice.id === 'PARTS_SUPPLY' && record?.hasOpenPurchaseOrders) {
                    return {
                        ...choice,
                        disabled: true,
                    }
                }
                return choice
            }),
    },
})
const VendorDetails = () => {
    const record = useRecordContext<VendorModel>()
    const slides = record
        ? [
              <BasicDetails />,
              <VendorDrawerToggler
                  record={record}
                  vendorFormProps={vendorFormEditProps(record)}
              >
                  {(open) => (
                      <TagsCard
                          create={open}
                          tags={record.tagsData}
                      />
                  )}
              </VendorDrawerToggler>,
              <AddressCard />,
              <FinancialInformation />,
          ]
        : skeletonSlides

    return (
        <Section>
            <ViewHeader
                title="Company Details"
                loading={!record}
            >
                <ViewHeader.Content placement="rightMobile">
                    <VendorDrawerToggler
                        record={record}
                        vendorFormProps={vendorFormEditProps(record)}
                    >
                        {(open) => (
                            <IconBox
                                title="Edit"
                                onClick={open}
                            >
                                <Icons.Edit />
                            </IconBox>
                        )}
                    </VendorDrawerToggler>
                </ViewHeader.Content>
            </ViewHeader>
            <SliderView
                height="187px"
                items={slides}
            />
        </Section>
    )
}

export default VendorDetails
