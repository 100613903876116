import { type FC } from 'react'

import { formatDate } from 'lib'

import { type CustomerModel } from '../types'

interface Props {
    record: Pick<CustomerModel, 'created'>
}

const Value: FC<Props> = ({ record }) => {
    return <>{formatDate(record.created, (dateFormats) => dateFormats.shortenedDateTime)}</>
}

const source: keyof CustomerModel = 'created'

const Created = {
    source,
    label: 'Created on',
    Value,
}

export default Created
