import { isValidElement } from 'react'

import { useListContext, useShowContext, useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import { CollapsibleContent, UtilityDrawerAction, WithListContext, formatMoney } from 'components'
import { useResource } from 'core'
import { ActionsMenu } from 'core/actions'
import { basePermissions } from 'core/permissions'
import { type JobItem, useWoActionIsDisabled, type JobModel } from 'resources/workOrders'
import { Typography, BoxContainer, Button, Divider, Stack } from 'ui'

import { type WorkOrderJobCardProps } from '../../WorkOrderJobCard'
import useJobItemTranslate, { iconSxProps } from '../../useJobItemTranslate'
import WorkOrderJobCardSummary from '../WorkOrderJobCardSummary'

import { AddButtonWrapper, ItemRow, ItemSection, ItemSubtitle } from './styled'

interface WorkOrderJobCardItemsProps {
    itemActions: WorkOrderJobCardProps['itemActions']
}

const WorkOrderJobCardItems = ({ itemActions }: WorkOrderJobCardItemsProps) => {
    const itemsTranslate = useJobItemTranslate()
    let jobTotal = 0
    const { data = [], isLoading } = useListContext<JobItem>()

    const { record: workOrder } = useShowContext()
    const job = useRecordContext<JobModel>()
    const disabled = useWoActionIsDisabled({ workOrder })

    const resource = useResource()

    if (!data.length) {
        if (isLoading) {
            return null
        }
        return (
            <AddButtonWrapper>
                <WithListContext>
                    {(listContext) => (
                        <ActionsMenu
                            record={job}
                            actions={itemActions}
                            actionArgs={{ listContext }}
                            renderToggler={(open) => (
                                <Button
                                    onClick={open}
                                    startIcon={<Icons.Add />}
                                    disabled={disabled}
                                    sx={{
                                        marginBottom: '11px',
                                        marginTop: '11px',
                                    }}
                                >
                                    ADD LINE ITEM
                                </Button>
                            )}
                        />
                    )}
                </WithListContext>
            </AddButtonWrapper>
        )
    }

    return (
        <>
            <WorkOrderJobCardSummary data={data} />
            <CollapsibleContent>
                <Divider sx={{ my: '10px' }} />
                <ItemSection>
                    {data.map((item) => {
                        const {
                            total,
                            totalCaption,
                            subtitle: itemSubtitle,
                            icon: ItemIcon,
                            action: itemAction,
                        } = itemsTranslate(item)
                        const itemIcon = isValidElement(ItemIcon) ? (
                            ItemIcon
                        ) : (
                            // @ts-ignore
                            <ItemIcon
                                fontSize="small"
                                color="primary"
                                sx={iconSxProps}
                            />
                        )
                        const itemTotal = typeof total === 'function' ? total() : total
                        jobTotal = jobTotal + itemTotal
                        const itemTotalCaption =
                            typeof totalCaption === 'function' ? totalCaption() : totalCaption
                        return (
                            <ItemRow key={item.id}>
                                <Stack>
                                    <BoxContainer>
                                        <div className="colName rowData">
                                            <UtilityDrawerAction
                                                resource={resource}
                                                permission={basePermissions.update}
                                                renderOnNoPermission
                                            >
                                                {(open) => (
                                                    <div onClick={() => itemAction(open)}>
                                                        {itemIcon}
                                                        <Typography
                                                            variant="caption"
                                                            color="primary"
                                                            sx={{
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            {item.description}
                                                        </Typography>
                                                    </div>
                                                )}
                                            </UtilityDrawerAction>
                                        </div>
                                        <div className="colQtyXCost rowData">
                                            <Typography
                                                variant="caption"
                                                color="text.primary"
                                            >
                                                {itemTotalCaption || ''}
                                            </Typography>
                                        </div>
                                        <div className="colPrice rowData">
                                            <Typography
                                                variant="body2"
                                                color="text.primary"
                                                whiteSpace="nowrap"
                                            >
                                                {formatMoney(itemTotal)}
                                            </Typography>
                                        </div>
                                    </BoxContainer>
                                    <ItemSubtitle>
                                        <Typography
                                            variant="chartLabel"
                                            color="text.secondary"
                                        >
                                            {itemSubtitle}
                                        </Typography>
                                    </ItemSubtitle>
                                </Stack>
                            </ItemRow>
                        )
                    })}
                </ItemSection>
            </CollapsibleContent>

            <Divider sx={{ marginBottom: '10px', marginTop: '12px' }} />
            <ItemSection>
                <div className="total">
                    <BoxContainer>
                        <div className="colName rowData">
                            <Icons.MonetizationOnOutlined
                                fontSize="small"
                                sx={(theme) => ({
                                    ...iconSxProps,
                                    color: theme.palette.text.primary,
                                })}
                            />
                            <Typography
                                variant="caption"
                                color="text.primary"
                            >
                                Total
                            </Typography>
                        </div>
                        <div className="rowData">
                            <Typography
                                variant="body2"
                                color="text.primary"
                                whiteSpace="nowrap"
                            >
                                {formatMoney(jobTotal)}
                            </Typography>
                        </div>
                    </BoxContainer>
                </div>
            </ItemSection>
        </>
    )
}

export default WorkOrderJobCardItems
