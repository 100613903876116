import { type FC, type ReactElement } from 'react'

import { useOpenUtilityDrawer } from 'components'
import UtilityDrawerForm from 'components/Drawer/UtilityDrawerForm'
import { useSubmit, type Serializer, serialize } from 'core'
import api from 'core/api'
import { type InventoryItem, inventoryItemsResource } from 'resources/inventory'

import AdjustItemForm from './AdjustItemForm'

interface AdjustItemDrawerTogglerProps {
    children: (params: { onClick: () => void }) => ReactElement
    record: Omit<InventoryItem, 'partData'>
}

export const AdjustItemDrawerToggler: FC<AdjustItemDrawerTogglerProps> = ({ record, children }) => {
    const open = useOpenUtilityDrawer()

    const handleSubmit = useSubmit(
        async (values) => {
            await api.post(
                `${inventoryItemsResource.resource}/${record.id}/adjust`,
                serialize(values, adjustPartSerializer),
            )
        },
        {
            successMessage: 'Part adjusted successfully',
        },
    )

    return children({
        onClick: () => {
            open({
                drawerArgs: {
                    title: 'Adjust Part',
                    renderWrapper: (params) => (
                        <UtilityDrawerForm
                            {...params}
                            onSubmit={handleSubmit}
                            defaultValues={{
                                shop: record.shopData.id,
                                expectedQty: record.quantityOnHand,
                            }}
                        />
                    ),
                    renderContent: () => <AdjustItemForm />,
                },
                extraArgs: {
                    id: record.id,
                    resource: inventoryItemsResource,
                },
            })
        },
    })
}

const adjustPartSerializer: Serializer = [
    { name: 'quantityAdjustment', parse: 'number' },
    {
        name: 'quantityAdjustmentReason',
        parse: (value, data) =>
            data.quantityAdjustment || typeof data.quantityAdjustment === 'number' ? value : null,
    },
    { name: 'acquisitionCost', parse: 'number' },
    'comment',
]
