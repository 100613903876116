import { type ReactElement } from 'react'

import { type ListControllerResult, useListContext } from 'react-admin'

import { type ResourceType, useResource } from 'core/resource'

const WithListContext = (props: {
    resource?: string
    children: (context: ListControllerResult, resource: ResourceType) => ReactElement
}) => {
    const context = useListContext(props)
    const resource = useResource()
    return props.children(context, resource)
}

export default WithListContext
