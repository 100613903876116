import { type FC } from 'react'

import { AutocompleteInput } from 'components'
import { ReferenceInput } from 'core'

import ck47Fields from '../fields'
import { ck47Resource } from '../utils'

interface Props {
    label: string
    source: string
}

export const CK47Input: FC<Props> = ({ source, label }) => {
    return (
        <ReferenceInput
            source={source}
            reference={ck47Resource.resource}
            perPage={100}
        >
            <AutocompleteInput
                optionText={ck47Fields.self.value}
                label={label}
            />
        </ReferenceInput>
    )
}
