import avatar from './avatar'
import email from './email'
import invitationCreated from './invitationCreated'
import invitationStatus from './invitationStatus'
import lastLogin from './lastLogin'
import name from './name'
import phone from './phone'
import role from './role'
import shops from './shops'
import viewUnitsWithoutDomicile from './viewUnitsWithoutDomicile'

const MembersDataConfig = {
    avatar,
    name,
    role,
    phone,
    email,
    lastLogin,
    invitationCreated,
    invitationStatus,
    shops,
    viewUnitsWithoutDomicile,
}

export default MembersDataConfig
