import { type FC } from 'react'

import { useRecordContext } from 'react-admin'

import { AutoGrid } from 'ui'

import { type UnitModel } from '../types'

import { UnitDeactivatedBadge } from './UnitDeactivatedBadge'
import { UnitIntegrationsStatusPopper } from './UnitIntegrationsStatusPopper'

const UnitIntegrationStatus: FC = () => {
    const record = useRecordContext<UnitModel>()

    if (!record?.telematicsData) {
        return null
    }

    return (
        <>
            {(record.telematicsData.telematicsStatus === 'CONNECTED' ||
                record.telematicsData.error) && (
                <AutoGrid.Column>
                    <UnitIntegrationsStatusPopper telematicData={record.telematicsData} />
                </AutoGrid.Column>
            )}
            {record.telematicsData.status === 'DEACTIVATED' &&
                record.telematicsData?.telematicsStatus === 'CONNECTED' && (
                    <AutoGrid.Column>
                        <UnitDeactivatedBadge provider={record.telematicsData.provider} />
                    </AutoGrid.Column>
                )}
        </>
    )
}

export default UnitIntegrationStatus
