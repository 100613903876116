import { type ComponentProps } from 'react'

import { styled } from 'lib'

const Arrow = styled(
    ({ onClick, ...props }: ComponentProps<'button'>) => {
        const onc = (e) => {
            onClick(e)
        }
        return (
            <>
                <button
                    {...props}
                    onClick={onc}
                />
                <div className="slick-shadow" />
            </>
        )
    },
    {
        shouldForwardProp: (prop) => prop !== 'currentSlide' && prop !== 'slideCount',
    },
)`
    z-index: 2;
    background: ${({ theme }) => theme.palette.white} !important;
    color: ${({ theme }) => theme.palette.text.primary} !important;
    box-shadow: ${({ theme }) => theme.shadows[3]};
    width: 24px;
    height: 24px;
    border-radius: 4px;

    &.slick-disabled {
        display: none !important;

        & + .slick-shadow {
            opacity: 0;
        }
    }

    &::before {
        content: unset;
    }

    &.slick-next {
        transform: translate(-40px, -50%);
    }

    &.slick-prev {
        transform: translate(40px, -50%);
    }

    ${({ theme }) => `

        ${theme.breakpoints.up('sm')} {
            & + .slick-shadow {
                width: 0px;
                height: 100%;
                position: absolute;
                top: 0;
                box-shadow: 0px 0 38px 35px ${theme.palette.darkGray};
                z-index: 1;
                transition: opacity 0.5s;
            }

            &.slick-next + .slick-shadow {
                right: -10px;
            }

            &.slick-prev + .slick-shadow {
                left: -10px;
                transform: rotate(180deg);
            }
        }
    `}
`

export default Arrow
