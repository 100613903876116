import { type Identifier } from 'appTypes'
import { type UseFileDownloadParams } from 'core'

import { type WorkOrderModel } from '../types'

export const pdfOneWOArgs = (
    record: WorkOrderModel,
): UseFileDownloadParams & { id: Identifier } => ({
    id: record.id,
    filename: record.number,
    type: 'pdf',
})
