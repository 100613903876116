import { type FC } from 'react'

import { CountryInput } from 'resources/country'
import { StateInput } from 'resources/state'
import { SectionTitleSmall } from 'ui'

import Address1Input from './Address1Input'
import Address2Input from './Address2Input'
import CityInput from './CityInput'
import ZipInput from './ZipInput'

interface Props {
    disabled?: boolean
    country?: {
        disabled?: boolean
    }
}

const AddressFormSection: FC<Props> = ({ country, disabled }) => {
    return (
        <>
            <SectionTitleSmall frontLine>Address</SectionTitleSmall>

            <Address1Input disabled={disabled} />

            <Address2Input disabled={disabled} />

            <CountryInput
                disabled={disabled}
                {...country}
            />

            <StateInput disabled={disabled} />

            <CityInput disabled={disabled} />

            <ZipInput disabled={disabled} />
        </>
    )
}

export default AddressFormSection
