import { defaultStatuses, options, type StatusConfig } from 'resources/common'

import { type WorkOrderModel, WoStatusKeys } from '../types'

const source = 'status' as const satisfies keyof WorkOrderModel

const getConfig = options.createConfig<StatusConfig<WoStatusKeys>>([
    { ...defaultStatuses.open, id: WoStatusKeys.OPEN },
    { ...defaultStatuses.closed, id: WoStatusKeys.CLOSED },
    { ...defaultStatuses.canceled, id: WoStatusKeys.CANCELED },
])

const value = options.makeIconValue(getConfig)

const status = {
    source,
    label: 'Status',
    getConfig,
    value: (data: Pick<WorkOrderModel, 'status'>) => value(data?.status),
}

export default status
