import { type FC } from 'react'

import { type BlockerConfig } from './types'
import { useSetBlocker } from './useSetBlocker'

export const SetBlocker: FC<BlockerConfig> = (props) => {
    useSetBlocker(props)

    return null
}
