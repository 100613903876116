import { type FC } from 'react'

import Icons from 'assets/icons'
import { DataAvatar } from 'ui'

import { type CustomerModel } from '../types'

const source: keyof CustomerModel = 'logo'

interface Props {
    record: Pick<CustomerModel, 'logo'>
}

const Value: FC<Props> = ({ record }) => (
    <DataAvatar
        imageSrc={record.logo}
        defaultImage={<Icons.Customers />}
    />
)

const Avatar = {
    source,
    label: 'Avatar',
    Icon: Icons.Customers,
    Value,
}

export default Avatar
