import { useDidUpdate } from 'hooks'

import useLocation, { type Location } from './useLocation'

const useLocationChange = (callback: (l: Location) => void) => {
    const location = useLocation()

    useDidUpdate(() => {
        callback(location)
    }, [location])
}

export default useLocationChange
