import { type ReactNode } from 'react'

import { Stack, type TypographyProps, StatusText, Tooltip, Typography } from 'ui'

interface PTListTitleProps extends Pick<TypographyProps, 'onClick'> {
    children: ReactNode
    showStatus?: boolean
    tooltip?: ReactNode
}

const PtTitle = ({ children, showStatus, tooltip, onClick }: PTListTitleProps) => {
    return (
        <Stack
            flexDirection="row"
            alignItems="center"
            gap={2}
            mr="4px"
        >
            <Typography
                variant="inherit"
                onClick={onClick}
                sx={{
                    cursor: 'pointer',
                    color: (theme) => theme.palette.primary.main,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'hidden',
                }}
            >
                {children}
            </Typography>
            {showStatus && (
                <Tooltip title={tooltip}>
                    <StatusText
                        height="fit-content"
                        color={(theme) => theme.palette.primary.main}
                        bgcolor={(theme) => theme.palette.primary.main}
                    >
                        Default
                    </StatusText>
                </Tooltip>
            )}
        </Stack>
    )
}

export default PtTitle
