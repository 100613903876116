import { FixedOnMobile } from 'ui'

import SubmitButton from './SubmitButton'

const SaveFixedOnMobile = () => (
    <FixedOnMobile>
        <SubmitButton />
    </FixedOnMobile>
)

export default SaveFixedOnMobile
