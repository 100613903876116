import { PageContent } from 'ui'

import { VendorPartDetails } from './components'

const VendorPartOverview = () => {
    return (
        <PageContent>
            <VendorPartDetails />
        </PageContent>
    )
}

export default VendorPartOverview
