import { useRecordContext } from 'react-admin'

import { type InPageTabProps, InPageTabs } from 'components'
import { useCreateResourcePath } from 'core'
import { type UnitModel, unitResource } from 'resources/units'

import { useCostReportContext } from '../CostReportContext/CostReportContext'

const DateSelector = () => {
    const createPath = useCreateResourcePath()
    const record = useRecordContext<UnitModel>()
    const { data, isLoading } = useCostReportContext()

    if (!record || isLoading) {
        return null
    }

    const basePath =
        createPath({
            resource: unitResource.resource,
            id: record.id,
            type: 'edit',
        }) + '/cost-report'

    const dataTabs: InPageTabProps[] = data.years.map((item) => ({
        value: item,
        label: item,
    }))

    const tabs: InPageTabProps[] = [firstTab, ...dataTabs]

    return (
        <InPageTabs
            tabs={tabs}
            tabMinWidth="92px"
            basePath={basePath}
        />
    )
}

export default DateSelector

const firstTab: InPageTabProps = {
    value: '',
    label: 'All',
}
