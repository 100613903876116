import { defaultStatuses, options, type StatusConfig } from 'resources/common'
import { Typography } from 'ui'

import { PoStatusKeys, type PurchaseOrderModel } from '../types'

const source = 'status' as const satisfies keyof PurchaseOrderModel

const getConfig = options.createConfig<StatusConfig<PoStatusKeys>>([
    { ...defaultStatuses.open, id: PoStatusKeys.OPEN },
    {
        ...defaultStatuses.inProgress,
        id: PoStatusKeys.DRAFT,
        text: (
            <>
                Open
                <Typography
                    color="text.disabled"
                    component="span"
                    variant="inherit"
                >
                    &nbsp;• Draft
                </Typography>
            </>
        ),
    },
    { ...defaultStatuses.closed, id: PoStatusKeys.CLOSED },
    { ...defaultStatuses.canceled, id: PoStatusKeys.CANCELED },
])

const value = options.makeIconValue(getConfig)

const status = {
    source,
    label: 'Status',
    getConfig,
    value: (data: Pick<PurchaseOrderModel, 'status'>) => value(data?.status),
}

export default status
