import { type FC } from 'react'

import { SelectInput } from 'components'
import { ReferenceInput } from 'core'

import { uomResource } from '../utils'

interface Props {
    disabled?: boolean
}

export const UOMInput: FC<Props> = ({ disabled }) => {
    return (
        <ReferenceInput
            reference={uomResource.resource}
            disabled={disabled}
            source="unitOfMeasure"
        >
            <SelectInput
                label="UOM"
                source="unitOfMeasure"
                disableEmptyValue
                disabled={disabled}
            />
        </ReferenceInput>
    )
}
