import { type Identifier } from 'appTypes'
import { createResource } from 'core'

export const woResource = createResource({
    name: 'work-orders',
    resource: 'work-orders',
})

export const woPermissions = {
    reopen: 'reopen',
    close: 'close',
    cancel: 'cancel',
}

export interface WoTypeChoice {
    id: Identifier
    name: string
}

export const woTypeChoices: WoTypeChoice[] = [
    { id: 'internal', name: 'Internal' },
    { id: 'vendor', name: 'Vendor' },
]
