import { type FC } from 'react'

import { type Validator } from 'react-admin'
import { useFormContext } from 'react-hook-form'

import { DateTimeInput, TextareaInput, TextInput } from 'components'
import { maxLengthValidationText, requiredValidation, validateMaxLength } from 'core'
import { PriorityInput } from 'resources/common'
import { CustomerPopUpInput } from 'resources/customers'
import { PhotosInput, photoSource } from 'resources/gallery'
import { issueFields, IssuePriorityKeys, issuesResource, type IssueModel } from 'resources/issues'
import { maxJobNameLength } from 'resources/jobs'
import { NotesInput } from 'resources/notes'
import { unitFields, UnitInput } from 'resources/units'
import {
    CK33InputWithoutParts,
    CK14Input,
    CK18Input,
    ck18Fields,
    ck33Fields,
    ck14Fields,
} from 'resources/vmrs'
import { SectionTitleSmall } from 'ui'

const nameValidation: Validator[] = [requiredValidation, validateMaxLength(maxJobNameLength)]

export interface IssueFormProps {
    isInputDisabled?: (source: keyof (IssueModel & { [photoSource]: string })) => boolean
}

const defaultDisabled = () => false

const IssuesForm: FC<IssueFormProps> = ({ isInputDisabled = defaultDisabled }) => {
    const { setValue } = useFormContext()

    return (
        <>
            <SectionTitleSmall>Basic Details</SectionTitleSmall>

            <TextInput<IssueModel>
                source={issueFields.name.source}
                validate={nameValidation}
                label={issueFields.name.label}
                disabled={isInputDisabled(issueFields.name.source)}
            />

            <UnitInput
                required
                disabled={isInputDisabled(unitFields.self.source)}
                source="unit"
                label={unitFields.number.label}
                contextType={issuesResource.name}
                onSelectedChange={({ selected }) => {
                    setValue('customer', selected?.customer)
                }}
            />

            <CustomerPopUpInput />

            <PriorityInput
                choices={issueFields.priority.choices}
                optionText={issueFields.priority.inputValue}
                isRequired
                defaultValue={IssuePriorityKeys.LOW}
                disabled={isInputDisabled(issueFields.priority.source)}
            />

            <DateTimeInput
                source={issueFields.reportedDate.source}
                label={issueFields.reportedDate.label}
                validate={requiredValidation}
                disabled={isInputDisabled(issueFields.reportedDate.source)}
                defaultValue={new Date()}
            />

            <CK33InputWithoutParts
                required
                disabled={isInputDisabled(ck33Fields.self.source)}
            />

            <CK14Input disabled={isInputDisabled(ck14Fields.self.source)} />

            <CK18Input disabled={isInputDisabled(ck18Fields.self.source)} />

            <TextareaInput<IssueModel>
                source={issueFields.description.source}
                label={issueFields.description.label}
                validate={maxLengthValidationText}
                disabled={isInputDisabled(issueFields.description.source)}
            />

            <NotesInput disabled={isInputDisabled('notes')} />

            <PhotosInput disabled={isInputDisabled(photoSource)} />
        </>
    )
}

export default IssuesForm
