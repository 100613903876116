import { type FC } from 'react'

import { type WithChildrenNode } from 'appTypes'
import { type PaletteMainColors, styled, useMediaQuery } from 'lib'
import { Badge, badgeClasses, BoxContainer } from 'ui'

import MenuButton from './MenuButton'

interface Props extends WithChildrenNode {
    status?: PaletteMainColors
}

const AppHeaderContainer: FC<Props> = ({ children, status }) => {
    const isVisible = useMediaQuery((theme) =>
        theme.breakpoints.down(theme.props.mobileViewBreakpoint),
    )
    if (!isVisible) {
        return null
    }

    let btn = <MenuButton />

    if (status) {
        btn = (
            <Badge
                color={status}
                variant="dot"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                sx={{
                    [`& .${badgeClasses.badge}`]: {
                        mb: '5px',
                    },
                }}
            >
                {btn}
            </Badge>
        )
    }

    return (
        <Content>
            <BoxContainer
                gap="16px"
                flexGrow={1}
                overflow="hidden"
            >
                {children}
            </BoxContainer>
            {btn}
        </Content>
    )
}

export default AppHeaderContainer

const Content = styled('div')`
    padding: 0 20px;
    display: flex;
    align-items: center;
    gap: 16px;
    height: 56px;
    background-color: ${({ theme }) => theme.palette.white};
    border-bottom: ${({ theme }) => '1px solid ' + theme.palette.other.divider};
    width: 100%;
    box-sizing: border-box;
`
