import { type FC } from 'react'

import { formatMoney } from 'components'
import { getListData } from 'core/data'
import { type CK33Aggregates, type CK33Aspects, ck33Aspects, ck33Fields } from 'resources/vmrs'

import { CostPerCK33 } from '../components'

const aspects = [ck33Aspects.totalsGroup] satisfies CK33Aspects[]

const CostPerVMRSGroup: FC = () => {
    return (
        <CostPerCK33
            title="Cost per VMRS Group"
            preferencesName="cost-per-vmrs-group"
            aspects={aspects}
            codeLabel="VMRS Group"
            descriptionLabel="Group Description"
            renderTotal={(list) => {
                const listData = getListData<CK33Aggregates>(list)
                return (
                    <>
                        {list.total} | {formatMoney(listData.total || 0)} total
                    </>
                )
            }}
            codeValue={(record) =>
                record.code ? ck33Fields.self.formatCode(record.code, record.level) : record.code
            }
            exportFileName="cost-per-vmrs-group"
        />
    )
}

export default CostPerVMRSGroup
