import { type ReactElement } from 'react'

import { type DataRecord, type ExtendRecordType } from 'appTypes'
import { extendRecord } from 'core/data'
import { withColor } from 'lib'
import { Typography, BoxContainer } from 'ui'

export type t = <T extends DataRecord>(
    val1: ExtendRecordType<T, string>,
    val2: ExtendRecordType<T, string>,
) => (record: T) => ReactElement
const enhancedOptionText: t = (val1, val2) =>
    function (record) {
        return (
            <BoxContainer whiteSpace="pre">
                <Typography color={withColor('text.primary')}>
                    {extendRecord(record, val1)}{' '}
                </Typography>
                <Typography
                    noWrap
                    color={withColor('text.disabled')}
                >
                    {extendRecord(record, val2)}
                </Typography>
            </BoxContainer>
        )
    }
export default enhancedOptionText
