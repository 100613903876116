import { type NoInfer } from 'appTypes'
import { type Column, type ListFilterChoice, type DataListDetails } from 'components'

import { type WorkOrderModel } from '../types'

const source = 'customer' as keyof WorkOrderModel

const label = 'Customer'

const tableColumn = <Source extends string = typeof source>({ id }: { id?: Source }) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: label,
        renderCell: ({ row }) => row.unitData.customerData?.name,
    }) as const satisfies Column

const dataCardRow = <Source extends string = typeof source>({ id }: { id?: Source }) =>
    ({
        source: (id || source) as NoInfer<Source>,
        label,
        render: (_, data) => data.unitData.customerData?.name,
    }) as const satisfies DataListDetails<any, any>

const sort = <Source extends string = typeof source>() =>
    ({
        id: source as NoInfer<Source>,
        label,
    }) as const

const filter = () =>
    ({
        id: source,
        label,
    }) as const satisfies ListFilterChoice

export const customer = {
    source,
    label,
    tableColumn,
    dataCardRow,
    sort,
    filter,
}
