import { ActionsMenu, type MenuActionProps } from 'core/actions'

import { type CK33Model } from '../../types'

import { Actions, type ActionsProps } from './Actions'

interface OptionMenuProps
    extends Pick<MenuActionProps, 'renderToggler'>,
        Pick<ActionsProps, 'canBeSelected'> {
    choice: CK33Model
    levels: number[]
}

export const OptionMenu = ({ renderToggler, choice, levels, canBeSelected }: OptionMenuProps) => {
    return (
        <ActionsMenu
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            sx={{ position: 'inherit', height: 'fit-content' }}
            PaperProps={{
                sx: {
                    position: 'absolute',
                    top: '100% !important',
                    left: '0 !important',
                    width: '100%',
                    maxWidth: '100%',
                    maxHeight: 'fit-content',
                    zIndex: '1300',
                },
            }}
            disablePortal
            renderToggler={renderToggler}
            actions={(params, { children }) => [
                <Actions
                    canBeSelected={canBeSelected}
                    key="actions"
                    choice={choice}
                    children={children}
                    levels={levels}
                />,
            ]}
        />
    )
}
