import { baseOperations } from 'core'
import woFields from 'resources/workOrders/fields'
import { WoStatusKeys, type WorkOrderModel } from 'resources/workOrders/types'

import { unaccessibleUnitActionMessage } from '../utils'

import { woOperations } from './utils'

const getWoCanAccess = (record: WorkOrderModel, action: string) => {
    if (!record) {
        return null
    }

    if (!record.unitData.isAccessible) {
        return unaccessibleUnitActionMessage
    }

    if (record.status !== WoStatusKeys.OPEN) {
        if (action === woOperations.jobItems) {
            return 'Reopen the WO to add line items'
        }

        if (action === woOperations.jobs) {
            return false
        }

        if (action === woOperations.open && record.salesOrderData?.invoiceData) {
            return 'Invoiced WOs cannot be reopened.'
        }

        if (action === baseOperations.edit) {
            return false
        }

        if (action === woOperations.notes) {
            return 'Reopen the WO to add notes'
        }
    }

    if (record.status === WoStatusKeys.CLOSED) {
        if (action === woOperations.photos) {
            return 'Reopen the WO to add photo'
        }
        if (action === woOperations.editVendor) {
            return false
        }
    }

    if (action === baseOperations.delete && record.status !== WoStatusKeys.OPEN) {
        return `${woFields.status.getConfig(record.status).text} WOs cannot be deleted`
    }

    if (action === woOperations.editVendor && record.jobItemsCount) {
        return 'Vendor editing is disabled due to existing WO job items. Clear them first.'
    }

    if (action === woOperations.invoice && record.salesOrderData?.invoiceData) {
        return 'Already invoiced'
    }

    return null
}
export default getWoCanAccess
