import { type ArchiveOneParams } from 'core'

export const archiveUnitActionParams = (
    isArchived: boolean,
    synced: boolean,
    hasWO: boolean,
    hasOngoingAssignments: boolean,
): ArchiveOneParams => {
    if (hasWO) {
        return {
            confirmConfig: {
                title: `This Unit cannot be archived, because it has an open Work Order.`,
                confirmButtonProps: null,
                cancelButtonProps: { children: 'Close' },
            },
            isArchived,
        }
    }

    return {
        confirmConfig: {
            title: `Are you sure you want to ${
                isArchived ? 'unarchive' : 'archive'
            } the selected Unit(s)?`,
            content: (
                <>
                    {synced && !isArchived ? (
                        <>
                            Archived units won't sync new data until reactivated. And your last
                            reported defects won't be synced with Fleetpal.{' '}
                            {hasOngoingAssignments && (
                                <>
                                    <br />
                                    <br />
                                </>
                            )}
                        </>
                    ) : undefined}
                    {hasOngoingAssignments && !isArchived
                        ? `There are ongoing unit assignments that will be marked as completed upon archiving.`
                        : undefined}
                </>
            ),
        },
        isArchived,
    }
}
