import { type FC } from 'react'

import { inject } from 'mobx-react'

import { type AuthStore } from 'core/auth'
import { MemberIcon } from 'resources/members'
import { type TypographyProps } from 'ui'

interface DefaultUserIconProps extends Pick<TypographyProps, 'fontSize'> {}

const UserIcon: FC<DefaultUserIconProps> = inject('auth')(({
    auth,
    fontSize,
}: DefaultUserIconProps & { auth: AuthStore }) => {
    return (
        <MemberIcon
            record={auth.user}
            fontSize={fontSize}
        />
    )
})

export default UserIcon
