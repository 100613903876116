import images from 'assets/images'
import NoResultsCard from 'components/NoResultsCard'
import { Typography } from 'ui'

const NotesEmptyCard = ({
    action,
    removeAddButton,
}: {
    action: () => void
    removeAddButton?: boolean
}) => {
    return (
        <NoResultsCard
            title="No Notes Added"
            direction="row"
            height="180px"
            imageSrc={images.notes}
            imageProps={{
                alt: 'No Notes',
                width: '106px',
                mt: '20px',
                mr: '28px',
            }}
            action={
                removeAddButton ? null : (
                    <Typography
                        variant="body1"
                        color="primary"
                        onClick={action}
                        sx={{ cursor: 'pointer' }}
                    >
                        Add Notes
                    </Typography>
                )
            }
        />
    )
}

export default NotesEmptyCard
