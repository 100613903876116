import { type FC } from 'react'

import { styled, withColor } from 'lib'
import { Typography } from 'ui'

const Section = styled('div')`
    margin-bottom: 32px;
`

const Header = styled('div')`
    margin-bottom: 16px;
`

const Title: FC<{ children: string }> = ({ children }) => {
    return (
        <Typography
            variant="subtitle1"
            color={withColor('text.primary')}
            mb="4px"
        >
            {children}
        </Typography>
    )
}

const Text: FC<{ children: string }> = ({ children }) => {
    return (
        <Typography
            variant="body2"
            color={withColor('text.secondary')}
        >
            {children}
        </Typography>
    )
}

const Cards = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 12px;
`

export default Object.assign(Section, { Header, Title, Text, Cards })
