import { useFormContext } from 'react-hook-form'

import Icons from 'assets/icons'
import { Button } from 'ui'

import { type SyncedStateType } from '../SyncedStateContext'
import { calculateSyncedUnits } from '../utils'

const UnitsToImportButton = ({ onClick, children }: { onClick: () => void; children: number }) => {
    const { watch } = useFormContext()
    const synced: SyncedStateType = watch('vehiclesSyncStatus', {})
    const syncedUnits = calculateSyncedUnits(synced, children)
    return (
        <Button
            color="primary"
            size="small"
            sx={{
                color: (theme) => `${theme.palette.primary.main} !important`,
            }}
            endIcon={<Icons.ArrowForward />}
            onClick={onClick}
        >
            Syncing {syncedUnits} Units
        </Button>
    )
}
export default UnitsToImportButton
