import { type NoInfer } from 'appTypes'
import {
    type ListFilterChoice,
    ListFilterDateRangeValueInput,
    type Column,
    type DataListDetails,
} from 'components'
import { formatDate } from 'lib'

import { type InvoiceModel } from '../types'

const source = 'invoiceDate' as const satisfies keyof InvoiceModel
const label = 'Invoice Date'

const value = (record: Pick<InvoiceModel, 'invoiceDate'>) =>
    formatDate(record.invoiceDate, (format) => format.shortenedDate)

const tableColumn = <Source extends string = typeof source>() =>
    ({
        field: source as NoInfer<Source>,
        headerName: label,
        renderCell: (cell) => value(cell.row),
    }) as const satisfies Column

const sort = <Source extends string = typeof source>() =>
    ({
        id: source as NoInfer<Source>,
        label,
    }) as const

const dataCardRow = <Source extends string = typeof source>() =>
    ({
        source: source as NoInfer<Source>,
        label,
        render: (_, data) => value(data),
    }) as const satisfies DataListDetails<any, any>

const filter = () =>
    ({
        id: source,
        label,
        filterType: 'range',
        renderComponent: (props) => <ListFilterDateRangeValueInput {...props} />,
    }) as const satisfies ListFilterChoice

const invoiceDate = {
    source,
    label,
    value,
    tableColumn,
    sort,
    dataCardRow,
    filter,
}

export default invoiceDate
