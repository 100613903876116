import { type FieldErrors, type FieldError } from 'react-hook-form'

import { type ServerError } from 'core/errors'

import { type FormErrors } from '../types'

export const formDefaultValuesKey = 'form-default-values'

export const formDefaultValuesQuery = <RecordType>(
    params: Partial<RecordType>,
): { [formDefaultValuesKey]: string } => {
    return {
        [formDefaultValuesKey]: JSON.stringify(params),
    }
}

export const formErrors = <FormData extends object = {}>(
    errors: FieldErrors,
): FormErrors<FormData> => {
    return errors as unknown as FormErrors<FormData>
}

export const formErrorToString = (
    err: FormErrors<{ field: string }>['field'] | FieldError,
    nest: keyof ServerError = 'message',
): string | null => {
    const error = err as FormErrors<{ field: string }>['field']
    if (!error) {
        return null
    }
    if (typeof error === 'string') {
        return error
    }
    const nestedError = error[nest]
    if (!nestedError) {
        return null
    }
    if (typeof nestedError === 'object') {
        const message = nestedError.message
        if (!message) {
            return null
        }

        return message
    }

    return nestedError
}
