import { type FC } from 'react'

import { AutocompleteInput } from 'react-admin'

import { type AutocompleteInputProps } from 'components'
import { ReferenceInput, requiredValidation } from 'core'

import { type WorkOrderModel } from '../types'
import { woResource } from '../utils'

interface Props extends Pick<AutocompleteInputProps, 'defaultValue'> {
    source?: string
    label?: string
    required?: boolean
    disabled?: boolean
}

const WorkOrderInput: FC<Props> = ({
    source = 'workOrder',
    label = 'WO Number',
    required,
    disabled,
    defaultValue,
}) => {
    return (
        <ReferenceInput
            reference={woResource.resource}
            source={source}
            disabled={disabled}
            perPage={100}
        >
            <AutocompleteInput
                disabled={disabled}
                defaultValue={defaultValue}
                label={label}
                optionText={optionText}
                inputText={inputText}
                validate={required ? requiredValidation : undefined}
            />
        </ReferenceInput>
    )
}

export default WorkOrderInput

const optionText = (record: WorkOrderModel) => {
    return record.number
}

const inputText = (record: WorkOrderModel) => {
    return record.number
}
