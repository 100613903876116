import { CreateButtonView, Header, ListTotalBadge, MainHeader } from 'components'

import { InviteMembersToggler } from '../../Invite'

const MembersListHeader = () => {
    return (
        <MainHeader
            mainAction={
                <InviteMembersToggler>
                    {(open) => <CreateButtonView onClick={open}>Invite User</CreateButtonView>}
                </InviteMembersToggler>
            }
        >
            <Header.Info>
                <Header.Content>
                    <Header.Title>Users</Header.Title>
                </Header.Content>
                <Header.Content mobilePlacement="bottom">
                    <ListTotalBadge />
                </Header.Content>
            </Header.Info>
        </MainHeader>
    )
}

export default MembersListHeader
