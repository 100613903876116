import { type FC } from 'react'

import { VendorCard } from 'resources/vendors'
import { type WorkOrderModel } from 'resources/workOrders'
import { GridContainerColumns, GridItemLayout } from 'ui'

interface WorkOrderVendorCardProps {
    data: WorkOrderModel
    canceled?: boolean
}

const WorkOrderVendorCard: FC<WorkOrderVendorCardProps> = ({ data, canceled = false }) => {
    const {
        purchaseOrderData: { vendorData },
    } = data

    return canceled ? (
        <VendorCard vendorData={vendorData} />
    ) : (
        <GridContainerColumns>
            <GridItemLayout>
                <VendorCard vendorData={vendorData} />
            </GridItemLayout>
        </GridContainerColumns>
    )
}

export default WorkOrderVendorCard
