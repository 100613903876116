import { type BaseCompany, type Identifier } from 'appTypes'
import {
    CollapsibleContent,
    CollapsibleInfoCard,
    CollapsibleTogglerAction,
    InfoCardDetails,
    infoCardDetailsGap,
    InfoCardHeader,
} from 'components'
import { countryFields } from 'resources/country'
import { stateFields } from 'resources/state'

import { addressFields } from '../fields'

export const AddressCard = () => {
    return (
        <CollapsibleInfoCard>
            <InfoCardHeader
                title="Address"
                action={<CollapsibleTogglerAction />}
            />
            <InfoCardDetails<
                BaseCompany & {
                    id: Identifier
                }
            >
                details={[
                    addressFields.address1.dataCardRow({}),
                    addressFields.address2.dataCardRow({}),
                    {
                        label: stateFields.self.label,
                        source: (record) => stateFields.self.value(record.stateData),
                    },
                    addressFields.city.dataCardRow({}),
                ]}
            />
            <CollapsibleContent>
                <InfoCardDetails<BaseCompany & { id: Identifier }>
                    mt={infoCardDetailsGap}
                    details={[
                        addressFields.zipCode.dataCardRow({}),
                        {
                            label: countryFields.self.label,
                            source: (record) => countryFields.self.value(record.countryData),
                        },
                    ]}
                />
            </CollapsibleContent>
        </CollapsibleInfoCard>
    )
}
