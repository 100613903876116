import { styled } from 'lib'

import Box from './Box'

const FixedOnMobile = styled(Box)(
    ({ theme }) => `
        ${theme.breakpoints.down('md')} {
            z-index: 4;
            position: fixed;
            bottom: 20px;
            right: 20px;
        }
`,
)

export default FixedOnMobile
