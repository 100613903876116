import { type FC } from 'react'

import { type Size } from 'appTypes'
import { Skeleton } from 'ui'

import SkeletonSliderCardContainer from './SkeletonSliderCardContainer'

interface Props {
    height?: string
    width?: Size | boolean
}

const CardDefaultSkeleton: FC<Props> = ({ height = '280px', width }) => {
    return (
        <SkeletonSliderCardContainer
            sx={{
                height,
                gap: '10px',
                width: (width === true ? '350px' : width) || undefined,
            }}
        >
            <Skeleton
                height="8px"
                width="120px"
                sx={{
                    my: '20px',
                }}
            />
            <Skeleton />
        </SkeletonSliderCardContainer>
    )
}

export default CardDefaultSkeleton
