import { timeLeftFormat } from 'lib'

import { type ExpirationModel } from '../types'

const valueSource: keyof ExpirationModel = 'thresholdValue'
const typeSource: keyof ExpirationModel = 'thresholdType'
const leftSource = 'left' as keyof ExpirationModel

export const threshold = {
    valueSource,
    typeSource,
    leftSource,
    leftLabel: 'Time Left',
    leftValue: timeLeftFormat,
}
