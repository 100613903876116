import { type FC } from 'react'

import { formatMoney } from 'components'
import { globalClassNames } from 'lib'
import { WidgetListContainer } from 'pages/Dashboard/components/WidgetBase/styled'
import LineItemsTooltip from 'pages/WorkOrders/Jobs/WorkOrderJobCard/components/LineItemsTooltip'
import { Box, BoxContainer, Status, Typography } from 'ui'

import { ListItem, WidgetListDescription } from '../../components'
import { type listType, statusColors } from '../types'

interface CostPerEquipmentListProps {
    list: listType
    description: string
}
const CostPerEquipmentList: FC<CostPerEquipmentListProps> = ({ list, description }) => {
    const { data } = list

    const isValid = data.length > 0 ? data.some((obj) => typeof obj.total === 'number') : false

    if (!isValid) {
        return <WidgetListDescription>{description}</WidgetListDescription>
    }

    const List = data.flatMap(({ id, description, total, code, ...ratioData }, i) => {
        if (typeof total !== 'number') {
            return []
        }
        // TODO: Unify top3 lists

        return (
            <Typography
                component={ListItem}
                variant="chartListItem"
                key={`${description}-${i}`}
            >
                <BoxContainer
                    sx={{
                        pr: '10px',
                    }}
                    className={globalClassNames.ellipsis}
                >
                    <Box marginRight="13px">
                        <Status
                            size="8px"
                            iconColor={(theme) => theme.palette.charts[statusColors[i]]}
                        />
                    </Box>
                    <Box
                        component="span"
                        className={globalClassNames.ellipsis}
                        sx={{ lineHeight: '150%' }}
                    >
                        {description}
                    </Box>
                </BoxContainer>

                <LineItemsTooltip data={{ ...ratioData, id }}>
                    {formatMoney(total)}
                </LineItemsTooltip>
            </Typography>
        )
    })
    return <WidgetListContainer>{List}</WidgetListContainer>
}
export default CostPerEquipmentList
