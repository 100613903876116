import { type FC } from 'react'

import { RadioButtonGroupInput } from 'react-admin'

import { TextareaInput } from 'components'
import { maxLengthValidationText } from 'core'
import { PoTypeKeys, poFields, poResource, type PurchaseOrderModel } from 'resources/purchaseOrders'
import { ShopInput } from 'resources/shops'
import { VendorInput } from 'resources/vendors'
import { SectionTitleSmall } from 'ui'

type PurchaseOrderFormProps = {
    inputsProps?: {
        [key in keyof PurchaseOrderModel]?: {
            disabled?: boolean
        }
    }
    disabled?: boolean
}

const typeChoices: { id: PoTypeKeys; name: string }[] = [
    { id: PoTypeKeys.STANDARD, name: poFields.type.config[PoTypeKeys.STANDARD] },
    { id: PoTypeKeys.CREDIT, name: `${poFields.type.config[PoTypeKeys.CREDIT]} (Return items)` },
]

const PurchaseOrderForm: FC<PurchaseOrderFormProps> = ({ inputsProps = {}, disabled }) => {
    const { shop = {}, type = {}, vendor = {}, description = {} } = inputsProps || {}

    return (
        <>
            <SectionTitleSmall>Repair Shop Details</SectionTitleSmall>
            <ShopInput
                contextType={poResource.resource}
                disabled={disabled}
                {...shop}
                required={!(shop.disabled || disabled)}
            />

            <SectionTitleSmall>Basic Details</SectionTitleSmall>
            <RadioButtonGroupInput
                defaultValue={PoTypeKeys.STANDARD}
                sx={{ flexDirection: 'column' }}
                label={false}
                source="type"
                choices={typeChoices}
                {...type}
            />

            <VendorInput
                disabled={vendor.disabled ?? disabled}
                vendorType="PARTS_SUPPLY"
                required
            />

            <TextareaInput
                source="description"
                label="Description"
                validate={maxLengthValidationText}
                disabled={disabled}
                {...description}
            />
        </>
    )
}

export default PurchaseOrderForm
