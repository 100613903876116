import { useWidgetContext } from 'pages/Dashboard/components/GridLayout'
import { checkWidgetDimensions } from 'pages/Dashboard/components/WidgetBase/useCollapseWidget'
import { Typography, type TypographyProps } from 'ui'

const CostPerCategoryClarification = (props: TypographyProps) => {
    const { dimensions } = useWidgetContext()
    const matches = checkWidgetDimensions(dimensions, ['sm', 'mdY'])
    return (
        <Typography
            sx={{ mt: matches ? '10px' : '20px' }}
            color="text.secondary"
            variant="chartListItem"
            fontSize={matches ? '10px' : '12px'}
            {...props}
        />
    )
}
export default CostPerCategoryClarification
