import Icons from 'assets/icons'
import { buttonClasses } from 'ui'

import UtilityDrawerSaveButton from '../UtilityDrawerSaveButton'

import { useUtilityDrawerStepsContext } from './UtilityDrawerStepsContext'

const SubmitButton = UtilityDrawerSaveButton

const UtilityDrawerNextButton = () => {
    const { isLastStep } = useUtilityDrawerStepsContext()

    if (isLastStep) {
        return (
            <SubmitButton
                label="Finish"
                icon={<Icons.Check />}
            />
        )
    }

    return (
        <SubmitButton
            label="Next"
            endIcon={<Icons.ChevronRight />}
            icon={null}
            sx={{
                [`& .${buttonClasses.endIcon} svg`]: {
                    marginRight: 0,
                },
            }}
        />
    )
}

export default UtilityDrawerNextButton
