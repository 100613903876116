import { type ReactNode } from 'react'

import { type EditProps, EditBase, useUpdate } from 'react-admin'

import { type DataRecord } from 'appTypes'
import { Form, type FormProps } from 'components/form'
import { type DataProviderMeta } from 'core/data'
import { type UseSubmitExtraArgs, useSubmit } from 'core/form'
import { type ResourceType, useResource } from 'core/resource'

import { makeSubmit, useSubmitValidate, type makeSubmitConfig } from './common'

export interface EditCustomProps<RecordType extends DataRecord = any>
    extends Omit<EditProps<RecordType>, 'resource' | 'mutationOptions'>,
        makeSubmitConfig<RecordType>,
        Pick<FormProps, 'formOnError' | 'validate'>,
        Pick<UseSubmitExtraArgs, 'successMessage'> {
    children: ReactNode
    className?: string
    warnWhenUnsavedChanges?: boolean
    resource?: ResourceType
    meta?: DataProviderMeta
    defaultValues?: Partial<RecordType> | ((record: RecordType) => Partial<RecordType>)
}

const Edit = <RecordType extends DataRecord = any>({
    children,
    className,
    onSuccess,
    serializer,
    warnWhenUnsavedChanges,
    formOnError,
    resource: resourceProp,
    meta,
    defaultValues,
    validate,
    successMessage,
    mutationOptions,
    ...rest
}: EditCustomProps<RecordType>) => {
    const resource = useResource(resourceProp)
    const [edit] = useUpdate()

    const submitValidate = useSubmitValidate(resource.resource, 'update', {
        params: { id: rest.id },
        meta,
    })

    const submit = makeSubmit(edit, {
        resource,
        onSuccess: (...args) => {
            onSuccess?.(...args)
        },
        serializer,
        meta,
        mutationOptions,
    })

    const onSubmit = useSubmit(submit, {
        successMessage: successMessage ?? (({ defaultMessages }) => defaultMessages.updated),
    })

    return (
        <EditBase
            {...rest}
            // TODO: Remove after RA
            id={rest.id as any}
        >
            <Form
                submitValidate={submitValidate}
                validate={validate}
                formOnError={formOnError}
                onSubmit={onSubmit}
                className={className}
                defaultValues={defaultValues}
                warnWhenUnsavedChanges={warnWhenUnsavedChanges}
            >
                {children}
            </Form>
        </EditBase>
    )
}

export default Edit
