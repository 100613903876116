import { Link } from 'react-router-dom'

import images from 'assets/images'
import { urls } from 'configs'

import Item from './Item'

const Logo = () => {
    return (
        <Link to={urls.root}>
            <Item py="24px">
                <img
                    alt="Fleetpal"
                    src={images.initialLogo}
                />
            </Item>
        </Link>
    )
}

export default Logo
