import { type FC } from 'react'

import { InfoCard, InfoCardDetails, InfoCardHeader, phoneMaskResolver } from 'components'
import { urls } from 'configs'
import { Link, globalClassNames } from 'lib'
import { addressFields } from 'resources/address'
import { stateFields } from 'resources/state'
import { cardHeaderClasses, DataAvatar, Typography } from 'ui'
import { pathJoin } from 'utils'

import vendorFields from '../fields'
import { formatVendorTypes } from '../utils'

import type VendorModel from '../types'

interface Props {
    vendorData: VendorModel
}

export const VendorCard: FC<Props> = ({ vendorData }) => {
    if (!vendorData) {
        return null
    }

    const path = pathJoin(urls.vendors, vendorData.id)

    return (
        <InfoCard>
            <InfoCardHeader
                sx={{
                    p: 0,
                    [`& .${cardHeaderClasses.action}`]: {
                        m: 0,
                    },
                    [`& .${cardHeaderClasses.content}`]: {
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: 'calc(100% - 56px)',
                    },
                }}
                avatar={
                    <Link to={path}>
                        <DataAvatar
                            defaultImage={<vendorFields.avatar.Icon />}
                            sx={{ marginBottom: '6px' }}
                            imageSrc={vendorData.logo}
                        />
                    </Link>
                }
                title={
                    <Typography
                        variant="avatar-initials"
                        color="primary.main"
                        to={path}
                        fontWeight="500"
                        component={Link}
                        className={globalClassNames.ellipsis}
                    >
                        {vendorData.name}
                    </Typography>
                }
                subheader={formatVendorTypes(vendorData.type)}
                subheaderTypographyProps={{
                    variant: 'inputLabel',
                    color: 'text.primary',
                    marginTop: '3px',
                    fontWeight: '500',
                }}
            />
            <InfoCardDetails<VendorModel>
                details={[
                    { label: 'Company Phone', source: (record) => phoneMaskResolver(record.phone) },
                    {
                        label: 'Company Email',
                        source: 'email',
                    },
                    addressFields.address1.dataCardRow({}),
                    addressFields.address2.dataCardRow({}),
                    {
                        label: stateFields.self.label,
                        source: (record) => stateFields.self.value(record.stateData),
                    },
                    addressFields.city.dataCardRow({}),
                    addressFields.zipCode.dataCardRow({}),
                    { label: 'TAX ID', source: 'taxId' },
                ]}
                propRecord={vendorData}
            />
        </InfoCard>
    )
}
