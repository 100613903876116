import { useEffect } from 'react'

import { useFinalErrorHandler, api, useNotify } from 'core'
import { useLocation, useNavigate } from 'lib'
import { type IntegrationProvider, useTelematics, startProviderTask } from 'resources/telematics'
import { GridContainerGrid, PageContent } from 'ui'

import { IntegrationCard, IntegrationsHeader, useConnect } from './components'

const AllIntegrations = () => {
    const { search } = useLocation()
    const navigate = useNavigate()
    const { telematics, redirectUri, list } = useTelematics()
    const notify = useNotify()
    const handleError = useFinalErrorHandler()
    const connect = useConnect()

    useEffect(() => {
        const searchParams = new URLSearchParams(search)
        if (searchParams) {
            navigate('', { replace: true })
        }
        const provider = searchParams.get('oauth2') as IntegrationProvider
        if (provider) {
            const code = searchParams.get('code')
            connect(provider, {
                code: decodeURIComponent(code),
                redirectUri: redirectUri + '?oauth2=' + provider,
            })
        }
    }, [])

    const data = [telematics.samsara, telematics.motive, telematics.geotab, telematics.eroad]

    const onDisconnect = async (provider) => {
        try {
            await api.delete('telematics/' + provider)
            list.refetch()
            notify('Successfully disconnected')
        } catch (e) {
            handleError(e, {
                fallbackError: 'Operation unsuccessful',
            })
        }
    }

    const onManualSync = async (provider) => {
        try {
            await api.post('telematics/' + provider + '/sync')
            startProviderTask(provider)
            list.refetch()
        } catch (e) {
            handleError(e, {
                fallbackError: 'Operation unsuccessful',
            })
        }
    }
    return (
        <>
            <IntegrationsHeader />
            <PageContent>
                <GridContainerGrid>
                    {data.map((integration) => (
                        <IntegrationCard
                            key={integration.provider}
                            onDisconnect={() => onDisconnect(integration.provider)}
                            onManualSync={() => onManualSync(integration.provider)}
                            integration={integration}
                        />
                    ))}
                </GridContainerGrid>
            </PageContent>
        </>
    )
}

export default AllIntegrations
