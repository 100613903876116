import { formatDuration } from 'lib'
import { useWidgetContext } from 'pages/Dashboard/components/GridLayout'
import { checkWidgetDimensions } from 'pages/Dashboard/components/WidgetBase/useCollapseWidget'
import { type DowntimeRatioData } from 'resources/units'
import { Stack, Typography } from 'ui'

interface ClarificationProps {
    totalDowntimeSeconds: DowntimeRatioData['totalDowntimeSeconds']
    validData: boolean
}
const Clarification = ({ totalDowntimeSeconds, validData }: ClarificationProps) => {
    const duration = validData ? formatDuration(totalDowntimeSeconds) : '-'
    const context = useWidgetContext()

    return (
        <Stack
            alignItems="flex-start"
            marginTop="20px"
        >
            <Typography
                component="span"
                variant="chartListItem"
                color="text.secondary"
                fontSize={
                    checkWidgetDimensions(context?.dimensions, ['sm', 'mdY']) ? '10px' : '12px'
                }
            >
                {duration}
            </Typography>
        </Stack>
    )
}
export default Clarification
