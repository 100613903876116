import { type ListControllerResult } from 'react-admin'

import Icons from 'assets/icons'
import { type ResourceType } from 'core'
import { ActionsMenu, type MenuActionProps, useActionsMenuContext } from 'core/actions'
import { LineItemTypeKeys } from 'resources/lineItems'
import { partFields } from 'resources/parts'
import { type JobLaborModel, type JobPartModel } from 'resources/workOrders'

import {
    PartCreateDrawerToggler,
    PartCreateWithoutNumberDrawerToggler,
} from '../WorkOrderJobCard/components/WorkOrderJobCardItems/drawers/drawerTogglers'

const AddVendorPartMenu = ({
    renderToggler,
    resource,
    listContext,
}: Pick<MenuActionProps, 'renderToggler'> & {
    resource: ResourceType
    listContext: ListControllerResult<JobPartModel | JobLaborModel>
}) => {
    const { close } = useActionsMenuContext()

    return (
        <ActionsMenu
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            renderToggler={renderToggler}
            actions={(params, { children }) => [
                <PartCreateWithoutNumberDrawerToggler
                    key="part-without-number"
                    resource={resource}
                    listContext={listContext}
                    children={({ onClick }) =>
                        children({
                            title: 'Without Part Number',
                            Icon: Icons.AddPart,
                            onClick: () => {
                                close()
                                onClick()
                            },
                        })
                    }
                />,
                <PartCreateDrawerToggler
                    title="Add Part (From Vendor Parts)"
                    hideInputs={{ upc: true }}
                    key={LineItemTypeKeys.PART}
                    resource={resource}
                    listContext={listContext}
                    children={({ onClick }) =>
                        children({
                            title: 'From Vendor Parts',
                            Icon: partFields.avatar.Vendor,
                            onClick: () => {
                                close()
                                onClick()
                            },
                        })
                    }
                />,
            ]}
        />
    )
}
export default AddVendorPartMenu
