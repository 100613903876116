import { type FC } from 'react'

import { ActionsMenu, ActionsOption } from 'core/actions'
import { getFileExtensionData } from 'core/files'
import { Anchor } from 'ui'
import { getFileName } from 'utils'

import { type ExpirationModel } from '../types'

const source: keyof ExpirationModel = 'filesCount'

const Value: FC<{ record: ExpirationModel }> = ({ record }) => {
    if (!record.filesCount) {
        return null
    }

    return (
        <ActionsMenu
            actions={() => {
                return getFiles(record).map((file) => (
                    <Row
                        file={file}
                        key={file}
                    />
                ))
            }}
            renderToggler={(open) => <Anchor onClick={open}>{record.filesCount}</Anchor>}
        />
    )
}

const Row: FC<{ file: string }> = ({ file }) => {
    const { Icon, color } = getFileExtensionData(file)
    const name = getFileName(file)

    return (
        <a
            href={file}
            target="_blank"
            rel="noreferrer"
        >
            <ActionsOption
                Icon={Icon}
                iconColor={color}
                title={name}
                isOverflow
            />
        </a>
    )
}

const arr = Array(10).fill(0)

const getFiles = (record: ExpirationModel): string[] => {
    const files: string[] = arr.flatMap((v, index) => record[`file${index}`] || [])

    return files
}

const getIndexes = (record: ExpirationModel): number[] => {
    const indexes: number[] = arr.flatMap((v, index) => (record[`file${index}`] ? [index] : []))

    return indexes
}

export const files = {
    source,
    label: 'Documents',
    Value,
    get: getFiles,
    indexes: getIndexes,
}
