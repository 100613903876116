import EventEmitter from 'eventemitter3'

const actionEmitter = new EventEmitter()

type ActionType = {
    name: string
    type?: string
    payload?: any
}

export const dispatchAction = (source: string, action: ActionType) => {
    actionEmitter.emit('action.' + source, action)
}

export const onAction = (source: string, cb: (action: ActionType) => void) => {
    actionEmitter.on('action.' + source, cb)
    return () => {
        actionEmitter.removeListener('action.' + source, cb)
    }
}
