import { type ListFilterChoice, ListFilterValueInput } from 'components'

import { paymentMethods } from './data'
import { type PaymentMethod, type PaymentMethodType } from './types'

const paymentMethodsObject = paymentMethods.reduce(
    (obj, type) => {
        obj[type.id] = type
        return obj
    },
    {} as {
        [key in PaymentMethodType]: PaymentMethod
    },
)

export const displayPaymentMethod = (type: PaymentMethodType): string => {
    const method = paymentMethodsObject[type]

    if (!method) {
        return ''
    }

    return method.name
}

export const paymentMethodFilter = ({ label }: { label?: string }) =>
    ({
        id: 'paymentMethod',
        label: label || 'Payment Method',
        renderComponent: (params) => (
            <ListFilterValueInput
                {...params}
                makeItemLabel={(option) => displayPaymentMethod(option.id as PaymentMethodType)}
            />
        ),
    }) as const satisfies ListFilterChoice
