import { useEffect } from 'react'

import { StyledElement } from 'ui'

import { useUtilityDrawerContext } from '../UtilityDrawerContext'

import { useUtilityDrawerStepsContext } from './UtilityDrawerStepsContext'

const UtilityDrawerStepsContent = () => {
    const { currentStepConfig, step } = useUtilityDrawerStepsContext()
    const { scrollTop } = useUtilityDrawerContext()

    useEffect(scrollTop, [step])

    const Component = currentStepConfig.Content

    return (
        <>
            <Component />
            {/* offset bottom */}
            <StyledElement sx={{ height: '10px' }} />
        </>
    )
}

export default UtilityDrawerStepsContent
