import { type FC } from 'react'

import { urls } from 'configs'
import { useAuthProvider } from 'core'
import { Navigate, useLocation } from 'lib'
import { Alert, AlertTitle, Button, Typography } from 'ui'

import { AuthBody } from '../components'

export interface ResetPasswordState {
    validEmail?: string
}

const ResetPasswordDone: FC = () => {
    const authProvider = useAuthProvider()
    const location = useLocation()

    const email: string = (location.state as ResetPasswordState)?.validEmail || ''

    if (!email) {
        return <Navigate to={urls.passwordReset} />
    }

    const handleResend = async () => {
        try {
            await authProvider.resetPassword(email)
        } catch (error) {
            // TODO: maybe error handling here
            return null
        }
    }

    return (
        <AuthBody>
            <Typography
                variant="h6"
                mb="20px"
            >
                Password Reset
            </Typography>
            <Typography
                variant="subtitle1"
                mb="24px"
            >
                Please Check your Email
            </Typography>
            <Alert
                variant="standard"
                severity="success"
                sx={{ mb: '24px', pb: '12px' }}
            >
                <AlertTitle>
                    An email with Reset link has <br />
                    been sent to&nbsp;
                    <Typography
                        color="inherit"
                        variant="inherit"
                        component="span"
                        fontWeight="500"
                    >
                        {email}
                    </Typography>
                </AlertTitle>
                <Typography
                    variant="body2"
                    color="inherit"
                >
                    In case you have not received an email in less than 1 min, please check your
                    spam folder or re-send a new link.
                </Typography>
            </Alert>
            <Button
                onClick={handleResend}
                fullWidth
                size="large"
                variant="contained"
                color="primary"
            >
                Resend Link
            </Button>
        </AuthBody>
    )
}

export default ResetPasswordDone
