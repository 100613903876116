export const emptyNullParse = (value: any) => {
    return value || null
}
export const selfValue = (value: any) => value
export const parseBoolean = (value: any) => Boolean(value)

export const parseNumber = (value: string | number) => {
    if (
        (typeof value === 'string' && value.trim() === '') ||
        value === undefined ||
        value === null
    ) {
        return null
    }
    const number = Number(value)
    if (isNaN(number)) {
        return value
    }
    return number
}
export const parseEmail = (value: string) => (value ? value.trim() : '')

export const parseString = (value: string | number) => {
    if (value === null || typeof value === 'undefined') {
        return ''
    }
    return String(value)
}

export const parseFile = (file: { image: string; rawFile: File } | File | string) => {
    if (typeof file === 'string') {
        return undefined
    }
    if (!file) {
        return null
    }
    return file
}

export const parseYear = (date: Date) => {
    if (date) {
        const year = (date instanceof Date ? date : new Date(String(date))).getUTCFullYear()
        if (!isNaN(year)) {
            return year
        }
    }
    return null
}
