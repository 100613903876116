import { type BaseModel, type Identifier } from 'appTypes'
import { type PartModel } from 'resources/parts'
import { type WithUOM } from 'resources/unitsOfMeasure'
import { type CK15Model, type CK33Model, type CK34Model } from 'resources/vmrs'

export enum LineItemTypeKeys {
    LABOR = 'LABOR',
    PART = 'PART',
    FEE = 'FEE',
    TAX = 'TAX',
    SERVICE = 'SERVICE',
}

export type LineItemTypesUnion = (typeof LineItemTypeKeys)[keyof typeof LineItemTypeKeys]

export type BaseLineItemModels = {
    [LineItemTypeKeys.LABOR]: LineItemLaborModel
    [LineItemTypeKeys.PART]: LineItemPartModel
    [LineItemTypeKeys.FEE]: LineItemFeeModel
    [LineItemTypeKeys.TAX]: LineItemTaxModel
    [LineItemTypeKeys.SERVICE]: LineItemServiceModel
}

export type LineItemKeysUnion = keyof BaseLineItemModels

export type BaseLineItem = BaseLineItemModels[keyof BaseLineItemModels]

type LineItemType<T extends LineItemKeysUnion> = { type: T }

export interface LineItemLaborModel extends BaseModel, LineItemType<typeof LineItemTypeKeys.LABOR> {
    description: string
    price: number
    quantity: number
    cause: Identifier
    correction: Identifier
    componentData: CK33Model
    correctionData: CK15Model
    component: Identifier
}

export interface LineItemPartModel
    extends BaseModel,
        LineItemType<typeof LineItemTypeKeys.PART>,
        WithUOM {
    positionApplicable: boolean
    partNumber: 'string'
    description: string
    price: number
    quantity: number
    component: number
    componentData: CK33Model
    part: Identifier
    partData: PartModel
    partType: PartModel['type']
    manufacturer: number
    manufacturerData: CK34Model
    position: number
}

interface ItemBase<T extends LineItemTypesUnion> extends BaseModel, LineItemType<T> {
    description: string
    price: number
}

export interface LineItemTaxModel extends ItemBase<typeof LineItemTypeKeys.TAX> {}
export interface LineItemFeeModel extends ItemBase<typeof LineItemTypeKeys.FEE> {}
export interface LineItemServiceModel extends ItemBase<typeof LineItemTypeKeys.SERVICE> {}
