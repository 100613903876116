import { useRecordContext } from 'react-admin'

import Icons from 'assets/icons'
import { InfoCard, SkeletonSliderCardContainer } from 'components'
import { useCanAccess } from 'core'
import { alpha } from 'lib'
import { vendorFields } from 'resources/vendors'
import { woOperations, type WorkOrderModel } from 'resources/workOrders'
import {
    DataAvatar,
    GridContainerColumns,
    GridItemLayout,
    Skeleton,
    Typography,
    BoxContainer,
    Button,
    Tooltip,
} from 'ui'

import AddVendorDrawerToggler from './AddVendorDrawerToggler'

const DisabledButton = () => {
    const record = useRecordContext<WorkOrderModel>()
    const getIsAllowed = useCanAccess()
    const { message } = getIsAllowed(woOperations.editVendor)

    const content = (
        <BoxContainer gap="4px">
            <Icons.Add
                sx={(theme) => ({
                    color: theme.palette.text.disabled,
                })}
            />
            <Typography
                sx={(theme) => ({
                    color: theme.palette.text.disabled,
                    cursor: 'default',
                })}
                variant="button"
            >
                add vendor
            </Typography>
        </BoxContainer>
    )

    return record?.completed ? <>{content}</> : <Tooltip title={message}>{content}</Tooltip>
}
const WoAddVendorCard = () => {
    const record = useRecordContext<WorkOrderModel>()
    if (!record) {
        return (
            <GridContainerColumns>
                <GridItemLayout>
                    <SkeletonSliderCardContainer
                        sx={{
                            height: '74px',
                        }}
                    >
                        <Skeleton />
                    </SkeletonSliderCardContainer>
                </GridItemLayout>
            </GridContainerColumns>
        )
    }
    return (
        <GridContainerColumns>
            <GridItemLayout>
                <InfoCard>
                    <BoxContainer justifyContent="space-between">
                        <DataAvatar
                            defaultImage={<vendorFields.avatar.Icon />}
                            color={({ palette }) => alpha(palette.text.primary, 0.54)}
                        />
                        <AddVendorDrawerToggler id={record.id}>
                            {({ onClick }) =>
                                record.jobItemsCount ||
                                record.completed ||
                                !record.unitData.isAccessible ? (
                                    <DisabledButton />
                                ) : (
                                    <Button
                                        startIcon={<Icons.Add />}
                                        size="medium"
                                        variant="text"
                                        onClick={onClick}
                                    >
                                        add vendor
                                    </Button>
                                )
                            }
                        </AddVendorDrawerToggler>
                    </BoxContainer>
                </InfoCard>
            </GridItemLayout>
        </GridContainerColumns>
    )
}

export default WoAddVendorCard
