import {
    type Dispatch,
    type FC,
    type ReactNode,
    type SetStateAction,
    createContext,
    useContext,
    useMemo,
    useState,
} from 'react'

import { useFormContext } from 'react-hook-form'

interface SyncedStateContextValue {
    synced: SyncedStateType
    setSynced: Dispatch<SetStateAction<SyncedStateType>>
    handleSyncedChange: (newState: SyncedStateType) => void
    reset: () => void
}
export interface SyncedStateType {
    [id: string]: boolean
}
const SyncedStateContext = createContext<SyncedStateContextValue>(null)

export const useSyncedStateContext = (): SyncedStateContextValue => useContext(SyncedStateContext)

export const SyncedStateProvider: FC<{ children: ReactNode; dialog: boolean }> = ({
    children,
    dialog,
}) => {
    const form = useFormContext()
    const [synced, setSynced] = useState<SyncedStateType>(form?.watch('vehiclesSyncStatus') || {})
    const handleSyncedChange = (newState: SyncedStateType) => {
        setSynced((prevState) => {
            const newObject = { ...prevState, ...newState }
            if (dialog) {
                Object.keys(newState).forEach((key) => {
                    if (prevState[key] !== undefined && prevState[key] !== newState[key]) {
                        delete newObject[key]
                    }
                })
            }

            return newObject
        })
    }

    const reset = () => {
        setSynced({})
    }

    const value: SyncedStateContextValue = useMemo(
        () => ({
            synced,
            setSynced,
            handleSyncedChange,
            reset,
        }),
        [synced],
    )
    return <SyncedStateContext.Provider value={value}>{children}</SyncedStateContext.Provider>
}

export default SyncedStateContext
