import InfoCard from 'components/InfoCard/InfoCard'
import { styled } from 'lib'

const SkeletonSliderCardContainer = styled(InfoCard)`
    height: 100%;
    display: flex;
    flex-direction: column;
`

export default SkeletonSliderCardContainer
