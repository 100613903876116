import avatar from './avatar'
import component from './component'
import description from './description'
import name from './name'
import photos from './photos'
import priority from './priority'
import reportedDate from './reportedDate'
import status from './status'
import unit from './unit'

const issueFields = {
    avatar,
    description,
    photos,
    reportedDate,
    status,
    priority,
    name,
    unit,
    component,
}

export default issueFields
