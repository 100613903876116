import images from 'assets/images'
import { NoResultsCard } from 'components'
import { useWoActionIsDisabled, WoJobDrawerToggler } from 'resources/workOrders'
import { Typography } from 'ui'

const WorkOrderJobsEmpty = () => {
    const disabled = useWoActionIsDisabled()

    return (
        <NoResultsCard
            title="No Jobs"
            direction="row"
            height="160px"
            heightsm="280px"
            directionsm="column"
            imageSrc={images.noJobs}
            imageProps={{
                alt: 'No Jobs',
                width: {
                    xs: '122px',
                    sm: '107px',
                },
                mr: {
                    xs: 0,
                    sm: '32px',
                },
            }}
            action={
                disabled ? undefined : (
                    <WoJobDrawerToggler>
                        {(open) => (
                            <Typography
                                variant="body1"
                                color="primary"
                                sx={{ cursor: 'pointer' }}
                                onClick={open}
                            >
                                Create Job
                            </Typography>
                        )}
                    </WoJobDrawerToggler>
                )
            }
        />
    )
}

export default WorkOrderJobsEmpty
