const source = 'poNumber' as const
const label = 'Customer PO Number'

const value = (data: { poNumber: string }) => data?.poNumber

const poNumber = {
    source,
    label,
    value,
}

export default poNumber
