import { type ReactElement } from 'react'

import { HiddenEmailInput } from 'components'
import { Alert, Img, Spacer, Typography } from 'ui'

interface ConnectTopSectionProps {
    logo: string
    alertText: string | ReactElement
    title: string
}
const ConnectTopSection = ({ logo, title, alertText }: ConnectTopSectionProps) => {
    return (
        <>
            <Alert
                severity="info"
                sx={{ mb: '20px' }}
            >
                {alertText}
            </Alert>

            <Spacer sx={{ mb: '32px' }}>
                <Img
                    src={logo}
                    sx={{ width: '32px', height: '32px' }}
                />
                <Typography variant="subtitle1">{title}</Typography>
            </Spacer>

            <HiddenEmailInput />
        </>
    )
}

export default ConnectTopSection
