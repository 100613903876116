import { type Serializer } from 'core'
import { type WorkOrderModel } from 'resources/workOrders'

export const workOrderSerializer: Serializer<WorkOrderModel & { poNumber: string }> = [
    { name: 'unit', parse: ['emptyToNull', 'disableOnEdit'] },
    'priority',
    'repairPriorityClass',
    {
        name: 'started',
        parse: 'dateTime',
    },
    'description',
    { name: 'meterType', parse: 'emptyToNull' },
    { name: 'meterValue', parse: 'number' },
    { name: 'shop', parse: 'emptyToNull' },
    {
        name: 'vendor',
        parse: (value) => {
            if (value) {
                return value
            }
            return undefined
        },
    },
    'poNumber',
]
