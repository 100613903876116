import Icons from 'assets/icons'
import { BoxContainer } from 'ui'
import { displayBooleanValue } from 'utils'

const ListBooleanRow = ({ children }: { children: boolean }) => {
    if (!children) {
        return null
    }
    return (
        <BoxContainer
            component="span"
            gap="4px"
        >
            <Icons.Check color="success" />
            <span>{displayBooleanValue(children)}</span>
        </BoxContainer>
    )
}

export default ListBooleanRow
