import { useState, useEffect, type FC, type ReactNode } from 'react'

import { Elements, useStripe, useElements, CardNumberElement } from '@stripe/react-stripe-js'
import { inject } from 'mobx-react'
import { useNavigate } from 'react-router-dom'

import { useToggleFullPageLoader, UtilityDrawerForm, useUtilityDrawerContext } from 'components'
import { config } from 'configs'
import { useNotify } from 'core'
import { type AuthStore } from 'core/auth'
import { blockerForceRedirectState } from 'core/blocker'
import { useFinalErrorHandler } from 'core/errors'

import { type PaymentFormContextValue } from './PaymetFormContext'

const stripePromise = config.STRIPE_PUBLIC_KEY
    ? import('@stripe/stripe-js').then(({ loadStripe }) => loadStripe(config.STRIPE_PUBLIC_KEY))
    : null

interface FormProps {
    type: 'upgrade' | 'change'
    children: ReactNode
}

const paymentCallbackDefault = async (paymentMethodId: string) => {
    //
}

const CheckoutForm: FC<FormProps & { auth?: AuthStore }> = inject('auth')(({
    children,
    auth,
    type,
}) => {
    const stripe = useStripe()
    const elements = useElements()
    const notify = useNotify()
    const [loading, setLoading] = useState<boolean>(false)
    const { controller } = useUtilityDrawerContext()
    const navigate = useNavigate()
    const errorHandler = useFinalErrorHandler()

    let paymentCallback = paymentCallbackDefault
    let successMsg = ''
    if (type === 'upgrade') {
        paymentCallback = auth.upgradeBillingToProPlan.bind(auth)
        successMsg = 'Successfully upgraded'
    } else {
        paymentCallback = auth.changePaymentMethod.bind(auth)
        successMsg = 'Successfully changed payment method'
    }

    useEffect(() => {
        if (loading) {
            const unblock = controller.block()

            return unblock
        }
    }, [loading])

    useToggleFullPageLoader({ isOpen: loading })

    const handleSubmit = async () => {
        if (!elements) {
            return
        }

        setLoading(true)

        const payload = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardNumberElement),
        })

        if (payload.error) {
            notify({
                title: payload.error.message,
                type: 'error',
            })
            setLoading(false)
            return {} // this prevents drawer from closing
        }

        await paymentCallback(payload.paymentMethod.id)
            .then(() => {
                navigate('urls.company', {
                    state: blockerForceRedirectState,
                })
                notify(successMsg)
            })
            .catch((error) => {
                errorHandler(error)
            })
    }

    const record: PaymentFormContextValue = {
        loading,
    }

    return (
        <UtilityDrawerForm
            record={record}
            onSubmit={handleSubmit}
            warnWhenUnsavedChanges={false}
        >
            {children}
        </UtilityDrawerForm>
    )
})

export const FormWrapper: FC<FormProps> = ({ type, children }) => {
    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm type={type}>{children}</CheckoutForm>
        </Elements>
    )
}
