import { type Theme } from '@mui/material'

import { type NestedKeys, type ObjectAny } from 'appTypes'
import { arrayOfStringToObject } from 'utils'

import { type IPalette } from './palette'

export const generateNotForwardedProps = <T extends ObjectAny = ObjectAny>(params: (keyof T)[]) => {
    const obj = arrayOfStringToObject(params, true)

    return (prop: keyof T) => !obj[prop]
}

export type ThemeColorType = string | ((themeParam: Theme) => string)

export const themeColor = (color: ThemeColorType, theme: Theme) => {
    if (typeof color === 'function') {
        return color(theme)
    }
    return color
}

const rgbToHex = (r: number, g: number, b: number) => {
    return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).toUpperCase().slice(1)
}

export const getRandomColor = () => {
    const r = Math.floor(Math.random() * 256)
    const g = Math.floor(Math.random() * 256)
    const b = Math.floor(Math.random() * 256)

    const hexColor = rgbToHex(r, g, b)

    return hexColor
}

export const getContrastColor = (hex: string) => {
    if (!hex) {
        return '#FFFFFF'
    }
    const r = parseInt(hex.substring(1, 3), 16)
    const g = parseInt(hex.substring(3, 5), 16)
    const b = parseInt(hex.substring(5, 7), 16)

    const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255

    return luminance > 0.6 ? '#000000' : '#FFFFFF'
}

export const withColor = (value: NestedKeys<IPalette>) => value

export { styled, alpha, generateUtilityClasses, darken, lighten } from '@mui/material'
export { clsx } from 'clsx'
