import { type FC } from 'react'

import { ThemeProvider as BaseThemeProvider } from '@mui/material'

import { type WithChildrenNode } from 'appTypes'
import { urls } from 'configs'
import { type BreakpointsValue, matchPath, themeBreakpoints, useLocation } from 'lib'
import { pathJoin } from 'utils'

import { createTheme, mainTheme } from './theme'

const settingsBreakpoints = Object.keys(themeBreakpoints).reduce((acc, key) => {
    const value = themeBreakpoints[key]
    acc[key] = value ? value + 160 : 0
    return acc
}, {} as BreakpointsValue)

const settingsTheme = createTheme({
    props: {
        drawerWidth: 240,
    },
    breakpoints: settingsBreakpoints,
})

const ThemeProvider: FC<WithChildrenNode> = ({ children }) => {
    const location = useLocation()
    const isSettings = matchPath(pathJoin(urls.settings, '/*'), location.pathname)
    const theme = isSettings ? settingsTheme : mainTheme

    return <BaseThemeProvider theme={theme}>{children}</BaseThemeProvider>
}

export default ThemeProvider
