import { type PropsWithChildren, type FC } from 'react'

import Icons from 'assets/icons'
import { formatPercent } from 'components'
import { type ThemeColorType } from 'lib'
import { IconElement, TextWithIcon, Typography } from 'ui'

interface ComparedToPrevYearLabelProps {
    value: number
}
const ComparedToPrevYearLabel: FC<ComparedToPrevYearLabelProps> = ({ value }) => {
    if (!value) {
        return null
    }

    if (value === 0) {
        return (
            <Component
                component={Icons.RemoveOutlined}
                color={(theme) => theme.palette.text.disabled}
            >
                Unchanged compared to previous year
            </Component>
        )
    }

    return (
        <Typography variant="inputLabel">
            <TextWithIcon
                icon={
                    <IconElement
                        component={value < 0 ? Icons.ArrowDownward : Icons.ArrowUpward}
                        color={(theme) =>
                            value < 0 ? theme.palette.success.main : theme.palette.charts.red
                        }
                    />
                }
            >
                {formatPercent(Math.abs(value), undefined, true)}{' '}
                {value < 0 ? 'decrease' : 'increase'} compared to previous year
            </TextWithIcon>
        </Typography>
    )
}

const Component: FC<PropsWithChildren & { component: FC; color: ThemeColorType }> = ({
    children,
    ...props
}) => {
    return (
        <Typography variant="inputLabel">
            <TextWithIcon icon={<IconElement {...props} />}>{children}</TextWithIcon>
        </Typography>
    )
}

export default ComparedToPrevYearLabel
