import { type Identifier } from 'appTypes'
import { type ResourceType } from 'core'

import { getPricingTierResource, PricingTierTypes } from '../utils'

export const ptFeeResourceName = 'pricing-tiers-fees'

export const getPtFeeResource = (id: Identifier): ResourceType => ({
    resource: getPricingTierResource(id, PricingTierTypes.FEES),
    name: ptFeeResourceName,
})
