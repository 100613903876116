import { createContext, useContext } from 'react'

import { type ObjectAny } from 'appTypes'

type ModificationContextValue = (name: string) => any

const ModificationContext = createContext<ModificationContextValue>(() => null)

export const ModificationProvider = ModificationContext.Provider
export const useModificationContext = <Config extends ObjectAny>() =>
    useContext(ModificationContext) as <Name extends keyof Config>(name: Name) => Config[Name]

export const createModifications =
    <Config extends ObjectAny>(modifications: Config) =>
    <Name extends keyof Config>(name: Name) =>
        modifications[name] as Config[Name]
