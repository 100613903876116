import { type FC, type ReactElement } from 'react'

import { type Identifier } from 'appTypes'
import { UtilityDrawerEdit, useOpenUtilityDrawer } from 'components'
import { type Serializer } from 'core/form'
import { basePermissions, useResourcePermissions } from 'core/permissions'
import { type ShopModel } from 'resourcesBase'

import { shopResource } from '../utils'

import ShopForm from './ShopForm'

interface ViewShopDrawerTogglerProps {
    children: (params: { onClick: () => void }) => ReactElement
    id: Identifier
}

const shopSerializer: Serializer<ShopModel> = [
    'name',
    {
        name: 'logo',
        parse: 'file',
    },
    'phone',
    'contact',
    { name: 'email', parse: 'email' },
    { name: 'website', parse: 'lowerCase' },
    'address',
    'address2',
    { name: 'country', parse: 'emptyToNull' },
    'city',
    'state',
    'zipCode',
    'website',
    'avatarColor',
]
export const ShopDrawerToggler: FC<ViewShopDrawerTogglerProps> = ({ children, id }) => {
    const permissions = useResourcePermissions()

    const disabled = !permissions[basePermissions.update]
    const open = useOpenUtilityDrawer()
    return children({
        onClick: () => {
            open({
                drawerArgs: {
                    title: 'Edit Details',
                    renderWrapper: (params) => (
                        <UtilityDrawerEdit
                            serializer={shopSerializer}
                            {...params}
                        />
                    ),
                    renderContent: () => <ShopForm disabled={disabled} />,
                },
                extraArgs: {
                    id,
                    resource: shopResource,
                },
            })
        },
    })
}
