import { type Identifier } from 'appTypes'
import { type ResourceType } from 'core'

import { getPricingTierResource, PricingTierTypes } from '../utils'

export const ptTaxResourceName = 'pricing-tiers-tax'

export const getPtTaxResource = (id: Identifier): ResourceType => ({
    resource: getPricingTierResource(id, PricingTierTypes.TAXES),
    name: ptTaxResourceName,
})
