import { useState, type FC } from 'react'

import { inject, observer } from 'mobx-react'

import { type Identifier } from 'appTypes'
import { costMaskParams, DateInput, TextInput } from 'components'
import { type AuthStore, requiredValidation } from 'core'
import { PaymentTermInput } from 'resources/paymentTerms'

import Attachments from './Attachments'
import PurchaseInvoiceNumberHandler from './PurchaseInvoiceNumberHandler'

export interface PurchaseInvoiceFormProps {
    getSource?: (source: string) => string
    disabledInputs?: boolean
    defaultValues?: {
        term?: Identifier
    }
}

export const PurchaseInvoiceForm: FC<PurchaseInvoiceFormProps> = inject('auth')(
    observer(
        ({
            getSource = getSourceDefault,
            disabledInputs: disabled,
            defaultValues,
            auth,
        }: PurchaseInvoiceFormProps & { auth: AuthStore }) => {
            const [numberInputMessage, setNumberInputMessage] = useState(null)
            return (
                <>
                    <TextInput
                        source={getSource('number')}
                        disabled={disabled}
                        label="Invoice Number"
                        validate={requiredValidation}
                        helperText={numberInputMessage}
                    />

                    <TextInput
                        disabled={disabled}
                        source={getSource('amount')}
                        label="Amount"
                        validate={requiredValidation}
                        {...costMaskParams}
                    />

                    <DateInput
                        disabled={disabled}
                        source={getSource('date')}
                        label="Date"
                        defaultValue={new Date()}
                        validate={requiredValidation}
                    />

                    <PaymentTermInput
                        source={getSource('paymentTerm')}
                        label="Payment Term"
                        disabled={disabled}
                        defaultValue={defaultValues?.term}
                    />
                    <Attachments
                        disabled={disabled}
                        getSource={getSource}
                    />
                    {auth.companyPreferences.allowDuplicatePoInvoiceNumbers ? (
                        <PurchaseInvoiceNumberHandler
                            numberSource={getSource('number')}
                            setNumberMessage={setNumberInputMessage}
                        />
                    ) : null}
                </>
            )
        },
    ),
)

const getSourceDefault = (source: string) => source
