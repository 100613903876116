import Icons from 'assets/icons'
import { type Column, type DataListDetails, type SortBy } from 'components'
import { BoxContainer } from 'ui'

import { InventoryPricingTypeKeys, type InventoryPricingModel } from '../types'

const source = 'type' as const satisfies keyof InventoryPricingModel

const config: { [key in InventoryPricingTypeKeys]: string } = {
    [InventoryPricingTypeKeys.PERCENTAGE]: 'Percentage',
    [InventoryPricingTypeKeys.FLAT_MARKUP]: 'Flat Markup/Margin',
    [InventoryPricingTypeKeys.SELLING_PRICE]: 'Flat Selling Price',
}

const label = 'Calculation Basis'

const value = (record: InventoryPricingModel) => {
    if (!record.type) {
        return (
            <BoxContainer sx={(theme) => ({ color: theme.palette.error.main })}>
                <Icons.ErrorOutline sx={{ marginRight: '0.25rem' }} /> <span>Unset</span>
            </BoxContainer>
        )
    }

    return config[record.type]
}

const tableColumn = () =>
    ({
        field: source,
        headerName: label,
        renderCell: ({ row }) => value(row),
    }) as const satisfies Column

const dataCardRow = () =>
    ({
        source,
        label,
        render: (_, data) => value(data),
    }) as const satisfies DataListDetails<any, any>

const sort = () =>
    ({
        id: source,
        label,
    }) as const satisfies SortBy

const type = {
    source,
    label,
    value,
    dataCardRow,
    tableColumn,
    sort,
}

export default type
