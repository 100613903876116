import { type TagModel } from '../types'

const source: keyof TagModel = 'name'

const name = {
    source,
    label: 'Name',
}

export default name
