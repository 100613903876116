import { type FC } from 'react'

import { AutocompleteInput } from 'components'
import { ReferenceInput } from 'core'

import { ck48Resource, displayCK48 } from '../utils'

interface Props {
    label: string
    source: string
}

export const CK48Input: FC<Props> = ({ source, label }) => {
    return (
        <ReferenceInput
            source={source}
            reference={ck48Resource.resource}
            perPage={100}
        >
            <AutocompleteInput
                optionText={displayCK48}
                label={label}
            />
        </ReferenceInput>
    )
}
