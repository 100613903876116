import { type ReactNode, type FC, type ReactElement } from 'react'

import { type Size } from 'appTypes'
import { clsx, cssVariables, generateUtilityClasses, globalClassNames, styled } from 'lib'
import { Typography } from 'ui/text'

interface Props {
    icon: ReactElement
    children: ReactNode
}

interface CssProps {
    gap?: Size
}

export const textWithIconClasses = generateUtilityClasses('TextWithIcon', ['root', 'text', 'icon'])

const TextWithIcon: FC<Props> = styled(
    ({ className, icon, children }: Props & { className: string }) => {
        return (
            <div className={clsx(textWithIconClasses.root, className, globalClassNames.ellipsis)}>
                <div className={textWithIconClasses.icon}>{icon}</div>
                <div className={globalClassNames.ellipsis}>
                    <Typography
                        className={clsx(textWithIconClasses.text, globalClassNames.ellipsis)}
                        variant="inherit"
                        color="inherit"
                    >
                        {children}
                    </Typography>
                </div>
            </div>
        )
    },
    {
        shouldForwardProp: (prop: keyof CssProps) => prop !== 'gap',
    },
)<Props & CssProps>`
    display: flex;
    align-items: center;
    gap: ${({ gap }) => gap || `var(${cssVariables.gap}, 4px)`};

    & .${textWithIconClasses.icon} {
        display: flex;
        align-items: center;
    }
`

export default TextWithIcon
