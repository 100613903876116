import { styled, getContrastColor } from 'lib'
import { Chip, type ChipProps, chipClasses } from 'ui'

import { type TagModel } from '../types'

interface TagFieldProps extends ChipProps {
    tag: TagModel
}

export const TagField = styled(({ tag, ...props }: TagFieldProps) => {
    return (
        <Chip
            label={tag.name}
            variant="filled"
            size="small"
            {...props}
        />
    )
})<TagFieldProps>(({ tag }) => {
    const color = tag.color

    return {
        backgroundColor: color,
        color: getContrastColor(color),

        [`.${chipClasses.deleteIcon}`]: {
            color: 'inherit',
        },

        [`.${chipClasses.label}`]: {
            lineHeight: '1',
            paddingTop: '2px',
            paddingBottom: '2px',
        },
    }
})
