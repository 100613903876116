import { type ReactElement } from 'react'

import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-premium'

import { type DataRecord } from 'appTypes'

import {
    type MassColProps,
    type Column,
    type ExportColumnType,
    type ColumnMeta,
    type DatagridColumnsProps,
} from './types'

export const actionsField = 'actions'
export const checkboxField = GRID_CHECKBOX_SELECTION_COL_DEF.field

export const getVisibleColumns = (columns: Column[]) => {
    return columns.reduce((cols, col) => {
        const appendColumn = (column: { field: string; headerName?: string }) => {
            cols.push([column.field, column.headerName || column.field])
        }
        if (col.toExport) {
            if (col.toExport.separate) {
                col.toExport.separate.forEach(appendColumn)
            }
        } else {
            appendColumn(col as any)
        }
        return cols
    }, [] as ExportColumnType[])
}

export { gridClasses as datagridClasses } from '@mui/x-data-grid-premium'

export const defaultMassColProps: MassColProps = {
    sortable: false,
    filterable: false,
    groupable: false,
    hideable: true,
    resizable: true,
    width: 200,
}

interface AvatarColumnParams<RecordType extends DataRecord> {
    field: keyof RecordType
    headerName?: string
    avatar: (record: RecordType) => ReactElement
}

export const datagridAvatarColumn = <RecordType extends DataRecord>({
    field,
    headerName = 'Avatar',
    avatar,
}: AvatarColumnParams<RecordType>): Column<RecordType> => {
    return {
        field,
        headerName,
        width: 72,
        renderCell: (cell) => avatar(cell.row),
        resizable: false,
    }
}

export const columnMinWidth = 90
export const pinnedColumnMaxWidth = 450

export const columnGetMeta = (column: Column): ColumnMeta => {
    // @ts-ignore
    return column.meta || {}
}

export const columnSetMeta = (column: Column, meta: ColumnMeta): void => {
    // @ts-ignore
    column.meta = meta
}

export const simpleTable: Pick<
    DatagridColumnsProps<any>,
    'disableColumnPinning' | 'disableColumnReorder' | 'disableColumnResize'
> = {
    disableColumnPinning: true,
    disableColumnReorder: true,
    disableColumnResize: true,
}
