import { Capability } from 'resources/telematics'
import { capitalizeWithLowerCase } from 'utils'

import { DVIRInProgress, DVIRNotSupported, DVIRSupported } from './components'

// Handle Inspection Types words that are semi-fused and come from the API in capitalized snake case.
// For example, the API returns 'PRE_TRIP,' but it needs to be 'Pre-trip.
export const formatDvirInspectionType = (type: string): string => {
    if (!type) {
        return ''
    }
    if (type.endsWith('TRIP')) {
        return capitalizeWithLowerCase(type.replaceAll('_', '-'))
    }
    return capitalizeWithLowerCase(type)
}

export const getDvirPage = (capability: Capability, providerName: string) => {
    if (capability === Capability.SUPPORTED) {
        return <DVIRSupported />
    }

    if (capability === Capability.IN_PROGRESS) {
        return <DVIRInProgress providerName={providerName} />
    }

    return <DVIRNotSupported providerName={providerName} />
}
