import { SetBlocker } from 'core/blocker'
import { styled } from 'lib'
import { Modal } from 'ui'

import MultiFormatScannerContent, {
    type MultiFormatScannerContentProps,
} from './MultiFormatScannerContent'
import { MultiFormatScannerModalContextProvider } from './MultiFormatScannerContext'

interface MultiFormatScannerProps extends MultiFormatScannerContentProps {
    isOpen: boolean
    close: () => void
    upcOnly?: boolean
}

const MultiFormatScannerModalBody = styled('div')`
    height: 100%;
    position: relative;

    video {
        width: 100% !important;
    }
`

const MultiFormatScanner = ({ isOpen, close, onDecode, upcOnly }: MultiFormatScannerProps) => {
    return (
        <Modal
            open={isOpen}
            onClose={close}
        >
            <MultiFormatScannerModalBody>
                <MultiFormatScannerModalContextProvider value={{ close }}>
                    <SetBlocker close={close} />
                    <MultiFormatScannerContent
                        onDecode={onDecode}
                        upcOnly={upcOnly}
                    />
                </MultiFormatScannerModalContextProvider>
            </MultiFormatScannerModalBody>
        </Modal>
    )
}

export default MultiFormatScanner
