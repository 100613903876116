import { type ReactNode, type FC } from 'react'

import { ActionsMenu } from 'core/actions'
import { type PurchaseInvoiceModel } from 'resources/purchaseInvoices'
import { Chip as ChipBase, type ChipProps as ChipBaseProps, Stack } from 'ui'

interface ChipProps extends Pick<ChipBaseProps, 'onClick' | 'size'> {
    children: ReactNode
}

const Chip: FC<ChipProps> = ({ children, onClick, size = 'small' }) => {
    return (
        <ChipBase
            component="span"
            label={children}
            variant="filled"
            size={size}
            onClick={onClick}
        />
    )
}

interface Props {
    invoices: PurchaseInvoiceModel[]
    togglerSize?: ChipBaseProps['size']
}

const InvoiceField: FC<Props> = ({ invoices, togglerSize }) => {
    const invoiceCount = invoices.length

    return (
        <ActionsMenu
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            renderToggler={(open) => (
                <Chip
                    onClick={open}
                    size={togglerSize}
                >
                    {invoiceCount} {invoiceCount > 1 ? 'Invoices' : 'Invoice'}
                </Chip>
            )}
            actions={() => [
                <Stack
                    spacing="12px"
                    px="16px"
                    key="tags"
                    alignItems="flex-start"
                >
                    {invoices.map(({ id, number }) => (
                        <Chip key={id}>{number}</Chip>
                    ))}
                </Stack>,
            ]}
        />
    )
}

export default InvoiceField
