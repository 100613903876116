export const focusedElementloseFocus = () => {
    const el = document.activeElement as HTMLElement

    if (el) {
        el.blur()
    }
}

export function findClosestParent(
    element: HTMLElement,
    selector: (element: HTMLElement) => boolean,
    maxLevels = 5,
) {
    let currentElement = element
    let level = 0

    while (currentElement && level < maxLevels) {
        if (selector(currentElement)) {
            return currentElement
        }

        currentElement = currentElement.parentElement
        level += 1
    }

    return null
}
