import { type FC, type ReactElement } from 'react'

import { Routes, Route, Navigate } from 'lib'

interface Props {
    element: ReactElement
    path: `:${string}`
}

const DynamicRoutes: FC<Props> = ({ element, path }) => {
    return (
        <Routes>
            <Route
                element={element}
                path="/"
            />
            <Route
                element={element}
                path={path}
            />
            <Route
                element={
                    <Navigate
                        to=""
                        replace
                    />
                }
                path="*"
            />
        </Routes>
    )
}

export default DynamicRoutes
