import {
    CollapsibleContent,
    InfoCardDetails,
    infoCardDetailsGap,
    InfoCardHeader,
    CollapsibleInfoCard,
    CollapsibleTogglerAction,
    type InfoCardDetailType,
} from 'components'
import { EquipmentBodyTitle, type UnitModel } from 'resources/units'
import { ck34Fields, ck47Fields } from 'resources/vmrs'

import { getDetails } from './utils'

const details: InfoCardDetailType<UnitModel>['details'] = [
    {
        label: 'Body Type',
        source: ({ body }) => ck34Fields.self.value(body.vmrsTypeData),
    },
    {
        label: 'Body Material',
        source: ({ body }) => ck47Fields.self.value(body.vmrsMaterialData),
    },
    {
        label: 'Manufacturer/Make',
        source: ({ body }) => ck34Fields.self.value(body.vmrsManufacturerData),
    },
    {
        label: 'Serial number',
        source: ({ body }) => body.serialNumber,
    },
    {
        label: 'Model',
        source: ({ body }) => body.model,
    },
    {
        label: 'Model Year',
        source: ({ body }) => body.modelYear,
    },
]
const Body = ({ maxVisibleColumns }: { maxVisibleColumns: number }) => {
    const { visibleDetails, collapsibleDetails } = getDetails(details, maxVisibleColumns)

    return (
        <CollapsibleInfoCard>
            <InfoCardHeader
                title={<EquipmentBodyTitle />}
                action={collapsibleDetails.length ? <CollapsibleTogglerAction /> : undefined}
            />
            <InfoCardDetails<UnitModel> details={visibleDetails} />
            {collapsibleDetails.length ? (
                <CollapsibleContent>
                    <InfoCardDetails<UnitModel>
                        mt={infoCardDetailsGap}
                        details={collapsibleDetails}
                    />
                </CollapsibleContent>
            ) : null}
        </CollapsibleInfoCard>
    )
}

export default Body
