import avatar from './avatar'
import contactPerson from './contactPerson'
import emailAddress from './emailAddress'
import hourlyLaborRate from './hourlyLaborRate'
import members from './members'
import name from './name'
import phoneNumber from './phoneNumber'
import self from './self'
import shopUseLaborHourlyRate from './shopUseLaborHourlyRate'
import website from './website'

const shopFields = {
    self,
    avatar,
    website,
    name,
    phoneNumber,
    contactPerson,
    emailAddress,
    hourlyLaborRate,
    useLaborHourlyRate: shopUseLaborHourlyRate,
    members,
}

export default shopFields
