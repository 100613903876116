import { type FC } from 'react'

import { useNotificationContext, useNotify } from './NotificationContext'
import Snackbar from './Snackbar'
import { type NotificationConfig } from './types'
import { isNotifyPayloadConfig } from './utils'

export const Toast: FC = () => {
    const notification = useNotificationContext()
    const setNotification = useNotify()

    if (!notification) {
        return null
    }

    const config: NotificationConfig = isNotifyPayloadConfig(notification)
        ? notification
        : { title: notification }

    if (!config.title && !config.message) {
        return null
    }

    return (
        <Snackbar
            close={() => {
                setNotification(null)
            }}
            notification={config}
        />
    )
}
