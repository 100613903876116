import { useRecordContext } from 'react-admin'

import { formatMoney } from 'components'
import { Arrow } from 'pages/Dashboard/components/Widgets/components'
import { type UnitModel } from 'resources/units'
import { BoxContainer, Typography } from 'ui'

const Clarification = () => {
    const record = useRecordContext<UnitModel>()

    const changePeriod = record.costPerDistanceFleetAvg - (record.costPerDistance || 0)
    const isPositiveChangeForPeriod = changePeriod > 0

    return (
        <BoxContainer marginTop="22px">
            {record.costPerDistance === null ? (
                '-'
            ) : (
                <>
                    <BoxContainer marginRight="8px">
                        <Arrow
                            direction={isPositiveChangeForPeriod ? 'down' : 'up'}
                            sx={(theme) => ({
                                width: '16px',
                                height: '16px',
                                color: theme.palette.charts[
                                    isPositiveChangeForPeriod ? 'greenBody' : 'red'
                                ],
                            })}
                        />
                    </BoxContainer>
                    <Typography
                        color="text.secondary"
                        variant="chartListItem"
                        whiteSpace="nowrap"
                    >
                        {formatMoney(Math.abs(changePeriod))}
                        {isPositiveChangeForPeriod ? ' below' : ' above'} fleet average
                    </Typography>
                </>
            )}
        </BoxContainer>
    )
}
export default Clarification
