import { type ReactNode, type FC } from 'react'

import { AutocompleteInput, type AutocompleteInputProps } from 'components'
import { ReferenceInput } from 'core'

import ck2Fields from '../fields'
import { ck2Resource } from '../utils'

interface Props extends Pick<AutocompleteInputProps, 'disabled' | 'onChange'> {
    label?: string
    source?: string
    referenceInputChildren?: ReactNode
}

export const CK2Input: FC<Props> = ({
    disabled,
    source = 'vmrsEquipmentCategory',
    label = 'Equipment',
    referenceInputChildren,
}) => {
    return (
        <ReferenceInput
            source={source}
            reference={ck2Resource.resource}
            disabled={disabled}
            perPage={100}
        >
            <>
                <AutocompleteInput
                    optionText={ck2Fields.self.value}
                    disabled={disabled}
                    label={label}
                />
                {referenceInputChildren}
            </>
        </ReferenceInput>
    )
}
