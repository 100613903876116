import { type Identifier } from 'appTypes'
import { parseNumber, type Serializer } from 'core'
import { type PartModel } from 'resources/parts'
import { type JobPartModel } from 'resources/workOrders'

export const jobPartWithoutNumberSerializer: Serializer<
    JobPartModel & Pick<PartModel, 'manufacturerPartNumber'>
> = [
    { name: 'component', parse: 'emptyToNull' },
    'description',
    { name: 'partNumber', parse: 'emptyToNull' },
    'manufacturer',
    { name: 'unitOfMeasure', parse: 'emptyToNull' },
    'manufacturerPartNumber',
    { name: 'quantity', parse: 'number' },
    'price',
    { name: 'position', parse: 'emptyToNull' },
    'type',
]

export const jobPartSerializer: Serializer<
    JobPartModel & { formConfig: { inventoryMode: boolean } }
> = [
    { name: 'part', parse: 'emptyToNull' },
    { name: 'quantity', parse: 'number' },
    {
        name: 'price',
        parse: (value, data) => {
            if (data.formConfig.inventoryMode) {
                return undefined
            }
            return parseNumber(value)
        },
    },
    { name: 'position', parse: 'emptyToNull' },
    'type',
]

export const jobPartApiContext = (jobId: Identifier) => {
    return {
        contextType: 'work-order-jobs',
        contextId: jobId,
    }
}
